import {FC} from "react";
import {useTranslation} from "react-i18next";

import {PacketCountStats} from "@app/Branch/model/Stats/PacketCountStats";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {NumberFormatter} from "@common/components/NumberFormatter/NumberFormatter";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export type PacketCountProps = {
    packetCount: PacketCountStats;
}

export const PacketCount: FC<PacketCountProps> = ({packetCount}) => {
    const {t} = useTranslation();

    const renderValue = (value: number|null) => dashWhenEmpty(value && <NumberFormatter numeric={value} />);

    return <div className="branch-stats-page__packet-count">
        <h4>{t('viewBranchStats:packetCount.header')}</h4>
        <GridTable>
            <tbody>
                <tr>
                    <th>{t('viewBranchStats:packetCount.pickupReady.label')}</th>
                    <td>{renderValue(packetCount.pickupReady)}</td>
                </tr>
                <tr>
                    <th>{t('viewBranchStats:packetCount.delivered.label')}</th>
                    <td>{renderValue(packetCount.delivered)}</td>
                </tr>
                <tr>
                    <th>{t('viewBranchStats:packetCount.return.label')}</th>
                    <td>{renderValue(packetCount.return)}</td>
                </tr>
            </tbody>
        </GridTable>
        <h4>{t('viewBranchStats:consignedPackets.header')}</h4>
        <GridTable>
            <tbody>
                <tr>
                    <td>{t('viewBranchStats:packetCount.consignedClaim.label')}</td>
                    <td>{renderValue(packetCount.consignedClaim)}</td>
                </tr>
                <tr>
                    <td>{t('viewBranchStats:packetCount.consignedC2C.label')}</td>
                    <td>{renderValue(packetCount.consignedC2C)}</td>
                </tr>
                <tr>
                    <th>{t('viewBranchStats:packetCount.consignedTotal.label')}</th>
                    <td>{renderValue(packetCount.consignedTotal)}</td>
                </tr>
            </tbody>
        </GridTable>
    </div>
}
