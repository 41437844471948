import {TFunction} from "i18next";
import {useCallback} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {createTransaction, getTransactionId} from "@app/CardPayment/api/cardTerminalApi";
import {useCheckTransaction} from "@app/CardPayment/hooks/useCheckTransaction";
import {useSetTransactionStatus} from "@app/CardPayment/hooks/useSetTransactionStatus";
import {useTransactionCallback} from "@app/CardPayment/hooks/useTransactionCallback";
import {CardPaymentState, CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransaction, CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";
import {BarcodeTransactionInfo, MerchantTransactionInfo} from "@app/CardPayment/model/CardTransaction";
import {CardTransactionType} from "@app/CardPayment/model/CardTransactionType";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useErrorToMessage} from "@common/hooks/useErrorToMessage";
import {useLogError} from "@common/hooks/useLogError";
import {isKnownError} from "@common/utils/api/isKnownError";

export type Pay = (amount: string, currency: string, transactionInfo: BarcodeTransactionInfo|MerchantTransactionInfo) => Promise<void>;

export type UsePayReturn = {
    pay: Pay;
    stopPayment: () => void;
}

export const usePay = (
    cardPaymentState: CardPaymentState,
    setCardPaymentState: (newState: CardPaymentState) => void,
    onTransactionSuccess: (transaction: CardPaymentTransaction) => void,
    onRunning: (running: boolean) => void,
    toastsInModal: boolean,
    t: TFunction,
): UsePayReturn => {
    const appContext = useAppContext();

    const setTransactionStatus = useSetTransactionStatus(cardPaymentState, setCardPaymentState);

    const logError = useLogError();
    const errorToMessage = useErrorToMessage('cardPayment:error', 'cardPayment:error.payment');

    const pay = useCallback(async (amount: string, currency: string, transactionInfo: BarcodeTransactionInfo|MerchantTransactionInfo) => {
        onRunning(true);

        try {
            const {cardTransaction} = await getTransactionId(appContext.api);

            try {
                await createTransaction({
                    id: cardTransaction.id,
                    type: CardTransactionType.SALE,
                    amount,
                    currency,
                    ...transactionInfo,
                }, appContext.api);

                setCardPaymentState({
                    status: CardPaymentStatus.PAYING,
                    transaction: {id: cardTransaction.id, status: CardPaymentTransactionStatus.CREATED},
                });
            } catch (error: unknown) {
                onRunning(false);
                const errorMessage = errorToMessage(error as Error);

                if (isKnownError(error as Error)) {
                    setCardPaymentState({
                        status: CardPaymentStatus.PAYING,
                        transaction: {
                            id: cardTransaction.id,
                            status: CardPaymentTransactionStatus.ERROR,
                            message: errorMessage,
                        },
                    });
                } else {
                    void logError(error as Error)
                        .then((errorIdentifier) => {
                            setCardPaymentState({
                                status: CardPaymentStatus.PAYING,
                                transaction: {
                                    id: cardTransaction.id,
                                    status: CardPaymentTransactionStatus.ERROR,
                                    message: errorMessage,
                                    errorIdentifier,
                                },
                            });
                        });
                }
            }
        } catch (error: unknown) {
            onRunning(false);
            (toastsInModal ? modalToast : appToast).error(t('cardPayment:error.transactionIdCreationFailed'));
        }
    }, [appContext.api, errorToMessage, logError, onRunning, setCardPaymentState, t, toastsInModal]);

    const onTransactionFinished = useTransactionCallback(cardPaymentState, (transaction, success: boolean, isTimeout?: boolean) => {
        if (success) {
            setTransactionStatus(CardPaymentTransactionStatus.SUCCESS);
            onTransactionSuccess(transaction);
        } else {
            if (isTimeout) {
                setTransactionStatus(CardPaymentTransactionStatus.ERROR, t('cardPayment:error.transactionTimeout'));
            } else {
                if (cardPaymentState.status === CardPaymentStatus.PAYING) {
                    // Finished payment without success is considered cancelled
                    setCardPaymentState({
                        status: CardPaymentStatus.CANCELLING,
                        transaction: {
                            ...transaction,
                            status: CardPaymentTransactionStatus.SUCCESS
                        }
                    });
                } else {
                    setTransactionStatus(CardPaymentTransactionStatus.ERROR);
                }
            }
        }
        onRunning(false);
    }, [onRunning, setTransactionStatus, onTransactionSuccess, t, cardPaymentState.status, setCardPaymentState]);

    const stopPayment = useCheckTransaction(
        CardPaymentStatus.PAYING,
        cardPaymentState,
        setCardPaymentState,
        onTransactionFinished,
    );

    return {
        pay,
        stopPayment,
    }
}
