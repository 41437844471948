import {FC, Fragment} from "react";
import {Dropdown} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {useTranslation} from "react-i18next";

import {Img} from "@common/components/Img/Img";
import {useAppConfig} from "@common/hooks/useAppConfig";

import './languageSelect.scss';

const LanguageLabels: {[language: string]: string} = {
    'cs-CZ': 'Čeština',
    'en-GB': 'English',
    'hu-HU': 'Magyar',
    'ro-RO': 'Română',
    'sk-SK': 'Slovenčina',
    'vi-VN': 'Tiếng Việt',
}

export const LanguageSelect: FC = () => {
    const {i18n} = useTranslation();
    const appConfig = useAppConfig();

    const label = (language: string): string => {
        if (language in LanguageLabels) {
            return LanguageLabels[language];
        }

        return language;
    }

    const languageItem = (language: string) => {
        return <Fragment>
            <span>
                <Img
                    src={`/images/icons/flags/flag-${language.substr(0, 2)}.png`}
                    alt={label(language)}
                />
            </span>
            <span>{label(language)}</span>
        </Fragment>
    }

    return <Dropdown className="language-select">
        <DropdownToggle variant="secondary">
            {languageItem(i18n.language)}
        </DropdownToggle>
        <DropdownMenu>
            {appConfig.languages.map((language ) => {
                return <DropdownItem key={language} onClick={() => i18n.changeLanguage(language)}>
                    {languageItem(language)}
                </DropdownItem>
            })}
        </DropdownMenu>
    </Dropdown>
}
