import classNames from "classnames";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {ReceivedMessagesRoute} from "@app/Messaging/MessagingRoutes";
import {Message, MessageType, sanitizedMessageContent} from "@app/Messaging/model/Message";
import {Img} from "@common/components/Img/Img";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {Truncate} from "@common/components/Truncate/Truncate";

import './dashboardMessagesList.scss';

export type DashboardMessagesListProps = {
    messages: Message[];
}

export const DashboardMessagesList: FC<DashboardMessagesListProps> = ({messages}) => {
    const {t} = useTranslation();
    const {dashboardMessages} = useAppContext();

    return <div className="dashboard-messages-list">
        {messages.map((message, index) => (
            <div className={classNames('dashboard-messages-list__message', {
                'dashboard-messages-list__message_system': message.type === MessageType.SYSTEM_MESSAGE,
                'dashboard-messages-list__message_urgent': message.type === MessageType.URGENT_MESSAGE,
            })} key={index} role="alert">
                {message.type === MessageType.GENERAL_MESSAGE && <Img src="/images/icons/ico/ico-i-gray.svg" alt="info" />}
                {message.type === MessageType.SYSTEM_MESSAGE && <Img src="/images/icons/ico/ico-exclamation-2.svg" alt="exclamation" />}
                {message.type === MessageType.URGENT_MESSAGE && <Img src="/images/icons/ico/ico-exclamation-red.svg" alt="exclamation" />}
                <div className="dashboard-messages-list__message-content">
                    <Truncate text={sanitizedMessageContent(message.content, t, false)} length={150} />{' '}
                    <AppLink
                        to={ReceivedMessagesRoute}
                        title={t('branchEvent:goToMessages')}
                        className="dashboard-messages-list__detail-link"
                    >{t('branchEvent:goToMessages')}</AppLink>
                </div>
            </div>
        ))}
        {messages.length !== 0 && dashboardMessages.unreadCount !== messages.length && <div className="dashboard-messages-list__messages-link">
            {t('branchEvent:moreMessages.text')}{' '}
            <AppLink to={ReceivedMessagesRoute} variant="secondary">
                {t('branchEvent:moreMessages.link')}
            </AppLink>
        </div>}
    </div>
}
