import {useTranslation} from "react-i18next";

import {BranchEvent} from "@app/BranchEvent/BranchEvent";
import {useBranchEventTranslationKey} from "@app/BranchEvent/hooks/useBranchEventTranslationKey";
import {
    BranchEvent as BranchEventModel,
    CashRegisterBalanceConfirmationRequiredBranchEvent,
    InventoryRequiredBranchEvent, PacketsPresenceReviewRequiredBranchEvent,
    PasswordChangeRequiredBranchEvent
} from "@app/BranchEvent/model/BranchEvent";
import {ModalBranchEventComponent} from "@app/BranchEvent/model/ModalBranchEventComponent";
import {Button} from "@common/components/Button/Button";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

export const BranchEventModal: ModalBranchEventComponent<Exclude<BranchEventModel,
    CashRegisterBalanceConfirmationRequiredBranchEvent
    | PacketsPresenceReviewRequiredBranchEvent
    | PasswordChangeRequiredBranchEvent
    | InventoryRequiredBranchEvent
>> = ({event, onClose}) => {
    const {t} = useTranslation();

    const translationKey = useBranchEventTranslationKey(event);

    return <BaseModal
        show={true}
        onHide={onClose}
        header={t(`${translationKey}Title`)}
        footer={<Button onClick={() => onClose()} variant="primary">{t('branchEvent:close')}</Button>}
    >
        <BranchEvent event={event} />
    </BaseModal>

}
