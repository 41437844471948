export enum SecuredAppModules {
    BRANCH = 'branch',
    COMMISSION = 'commission',
    INVENTORY = 'inventory',
    MANUALS = 'manuals',
    MESSAGING = 'messaging',
    MICRODEPOT = 'micro-depot',
    OPENING_HOURS = 'opening-hours',
    PACKET = 'packet',
    PARCEL = 'parcel',
    PRINT = 'print',
    TOOLS = 'tools',
    TRANSACTION = 'transaction',
    USER = 'user',
}
