import {DependencyList, useCallback} from "react";

import {getTransaction, CardPaymentState} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransaction} from "@app/CardPayment/model/CardPaymentTransaction";

type TransactionCallback<P extends Array<any>> = (transaction: CardPaymentTransaction, ...params: P) => void;

export const useTransactionCallback = <P extends Array<any>>(
    cardPaymentState: CardPaymentState,
    callback: TransactionCallback<P>,
    deps: DependencyList
): (...args: P) => void => {

    const transaction = getTransaction(cardPaymentState);

    return useCallback((...args: P): void => {
        if (!transaction) {
            return;
        }

        return callback(transaction, ...args);

        // eslint-disable-next-line react-hooks/exhaustive-deps,@typescript-eslint/no-unsafe-assignment
    }, [transaction, ...deps]);
}
