import {useEffect} from "react";

import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";
import {useLocalStorageState} from "@common/hooks/useLocalStorageState";
import {RequestWithSort, SortPropertyOfRequest} from "@common/model/requests/SortRequestProperty";

export const usePaginatedGridSorter = <ItemType, RequestType extends RequestWithSort, ExtendedResponseType>(
    manager: SortablePaginatedDataManager<ItemType, RequestType, ExtendedResponseType>,
    storageKey: string,
    initialValue: SortPropertyOfRequest<RequestType>|null,
) => {

    const [storedSorting, setStoredSorting] = useLocalStorageState<SortPropertyOfRequest<RequestType>|null>(
        storageKey,
        initialValue
    );

    useEffect(() => {
        if (!manager.hasSorting) {
            manager.sort(storedSorting);
        }
    }, [storedSorting, manager]);

    return setStoredSorting;
}
