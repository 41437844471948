import {observer} from "mobx-react-lite";
import {FC, Fragment, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {Outlet} from "react-router-dom";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {ChangePasswordRoute} from "@app/User/UserRoutes";
import {Loading} from "@common/components/Loading/Loading";
import {ToastContainer} from "@common/components/Toast/ToastContainer/ToastContainer";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {Footer} from "@common/layouts/SecuredLayout/Footer/Footer";
import {Header} from "@common/layouts/SecuredLayout/Header/Header";
import {PriorityEventsAndMessages} from "@common/layouts/SecuredLayout/PriorityEventsAndMessages/PriorityEventsAndMessages";
import {To} from "@common/model/ReactRoute";

import './securedLayout.scss';

export type SecuredLayoutProps = {
    toWhenUnauthorized: To;
}

const PASSWORD_CHANGE_REQUESTED_STORAGE_KEY = 'ui-passwordChangeRequested';

export const SecuredLayout: FC<SecuredLayoutProps> = observer(({toWhenUnauthorized}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const navigate = useAppNavigate();
    const containerRef = useRef<HTMLDivElement>(null);

    const passwordChangeRequested = appContext.userSpecificUI.create<boolean>(
        PASSWORD_CHANGE_REQUESTED_STORAGE_KEY,
        false
    );

    useEffect(() => {
        if (appContext.user.checked && !appContext.user.isLoggedIn) {
            navigate(toWhenUnauthorized);
        } else if (!passwordChangeRequested && appContext.branchEvents.hasPasswordChangeEvent) {
            appContext.userSpecificUI.persist(PASSWORD_CHANGE_REQUESTED_STORAGE_KEY, true);
            appToast.warning(t('branchEvent:passwordChangeRequired'))
            navigate(ChangePasswordRoute);
        }
    }, [appContext.branchEvents.hasPasswordChangeEvent, appContext.user.checked, appContext.user.isLoggedIn, appContext.userSpecificUI, navigate, passwordChangeRequested, t, toWhenUnauthorized]);

    return <Loading active={!appContext.user.checked} className="secured-layout">
        {appContext.user.isLoggedIn && <Fragment>
            <Header />
            <ToastContainer />
            <div className="secured-layout__container" ref={containerRef}>
                <Outlet />
            </div>
            <Footer containerRef={containerRef} />
            <PriorityEventsAndMessages />
        </Fragment>}
    </Loading>
})
