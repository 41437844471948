import {FC, useCallback} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    PacketIdVerificationForm
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PacketIdVerificationForm/PacketIdVerificationForm";
import {
    PasswordVerificationForm
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PasswordVerificationForm/PasswordVerificationForm";
import {
    PasswordVerificationFormData
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PasswordVerificationForm/usePasswordVerificationForm";
import {
    ReasonVerificationForm
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/ReasonVerificationForm/ReasonVerificationForm";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";
import {PacketIdVerification} from "@app/Packet/components/PacketDelivery/model/PacketIdVerification";
import {ReasonVerification} from "@app/Packet/components/PacketDelivery/model/ReasonVerification";
import {PacketDeliveryType} from "@app/Packet/model/PacketDeliveryType";

import './packetVerificationForms.scss';

export type PacketVerificationFormsProps = {
    packets: DeliveryPacketsCollection;
    deliveryType: PacketDeliveryType;
    setDeliveryType: (newDeliveryType: PacketDeliveryType) => void;
    packetIdVerificationForm: UseFormReturn<PacketIdVerification>;
    packetIdVerificationFormSubmit: (verification: PacketIdVerification) => void;
    passwordVerificationForm: UseFormReturn<PasswordVerificationFormData>;
    passwordVerificationFormSubmit: (data: PasswordVerificationFormData) => void;
    reasonVerificationForm: UseFormReturn<ReasonVerification>;
    reasonVerificationFormSubmit: (verification: ReasonVerification) => void;
}

export const PacketVerificationForms: FC<PacketVerificationFormsProps> = ({
    deliveryType,
    packetIdVerificationForm,
    packetIdVerificationFormSubmit,
    packets,
    passwordVerificationForm,
    passwordVerificationFormSubmit,
    reasonVerificationForm,
    reasonVerificationFormSubmit,
    setDeliveryType,
}) => {
    const {t} = useTranslation();

    const selectTab = useCallback((newDeliveryType: string|null) => {
        setDeliveryType(newDeliveryType as PacketDeliveryType || PacketDeliveryType.PERSONAL_ID);
    }, [setDeliveryType]);

    if (packets.all.length === 0) {
        return null;
    }

    const packet = packets.all[0];

    return <div className="packet-verification-forms">
        {packet.requiresIdVerification && <PacketIdVerificationForm
            form={packetIdVerificationForm}
            onSubmit={packetIdVerificationFormSubmit}
            packet={packet}
            focus={true}
        />}
        {!packet.requiresIdVerification && <Tabs
            activeKey={deliveryType}
            onSelect={selectTab}
            data-xid="packet-verification-forms-tabs"
        >
            <Tab eventKey={PacketDeliveryType.PERSONAL_ID} title={t('viewPacketDelivery:packetVerificationForms.tabs.personal')}>
                <PacketIdVerificationForm
                    form={packetIdVerificationForm}
                    onSubmit={packetIdVerificationFormSubmit}
                    packet={packet}
                    focus={deliveryType === PacketDeliveryType.PERSONAL_ID}
                />
            </Tab>
            <Tab eventKey={PacketDeliveryType.PASSWORD} title={t('viewPacketDelivery:packetVerificationForms.tabs.password')}>
                <PasswordVerificationForm
                    form={passwordVerificationForm}
                    onSubmit={passwordVerificationFormSubmit}
                    focus={deliveryType === PacketDeliveryType.PASSWORD}
                />
            </Tab>
            <Tab eventKey={PacketDeliveryType.REASON} title={t('viewPacketDelivery:packetVerificationForms.tabs.other')}>
                <ReasonVerificationForm form={reasonVerificationForm} onSubmit={reasonVerificationFormSubmit} />
            </Tab>
        </Tabs>}
    </div>
}
