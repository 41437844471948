import {SimpleReactRoute} from "@common/model/ReactRoute";

export const ReprintsRoute: SimpleReactRoute = {
    path: '',
}

export const PrintRoutes = [
    ReprintsRoute,
];

