import {ApiErrorItem, ApiErrorItemWithVariables, isApiErrorItemWithVariables} from "@common/model/errors/ApiErrorItem";

// canNotCreateInvoice error code

export type CanNotCreateInvoiceErrorItemVariables = {
    allowedUntilDayOfMonth: number;
}

export type CanNotCreateInvoiceErrorItem = ApiErrorItemWithVariables<CanNotCreateInvoiceErrorItemVariables>;

export const isCanNotCreateInvoiceApiErrorItem = (item: ApiErrorItem): item is CanNotCreateInvoiceErrorItem => {
    return isApiErrorItemWithVariables<CanNotCreateInvoiceErrorItemVariables>(item) && 'allowedUntilDayOfMonth' in item.variables;
}

// invalidInvoicedCurrency error code

export type InvalidInvoicedCurrencyErrorItemVariables = {
    currency: string;
    invoicedAmount: string;
    invoicingCurrency: string;
}

export type InvalidInvoicedCurrencyErrorItem = ApiErrorItemWithVariables<InvalidInvoicedCurrencyErrorItemVariables>;

export const isInvalidInvoicedCurrencyApiErrorItem = (item: ApiErrorItem): item is InvalidInvoicedCurrencyErrorItem => {
    return isApiErrorItemWithVariables<InvalidInvoicedCurrencyErrorItemVariables>(item)
        && 'currency' in item.variables
        && 'invoicedAmount' in item.variables
        && 'invoicingCurrency' in item.variables;
}

// invalidInvoicedAmount error code

export type InvalidInvoicedAmountErrorItemVariables = {
    currency: string;
    invoicedAmount: string;
    unpaidCommissionSum: string;
}

export type InvalidInvoicedAmountErrorItem = ApiErrorItemWithVariables<InvalidInvoicedAmountErrorItemVariables>;

export const isInvalidInvoicedAmountApiErrorItem = (item: ApiErrorItem): item is InvalidInvoicedAmountErrorItem => {
    return isApiErrorItemWithVariables<InvalidInvoicedAmountErrorItemVariables>(item)
        && 'currency' in item.variables
        && 'invoicedAmount' in item.variables
        && 'unpaidCommissionSum' in item.variables;
}

// issueDateOutOfRange error code

export type IssueDateOutOfRangeErrorItemVariables = {
    allowedFrom: string;
    allowedUntil: string;
    given: string;
}

export type IssueDateOutOfRangeErrorItem = ApiErrorItemWithVariables<IssueDateOutOfRangeErrorItemVariables>;

export const isIssueDateOutOfRangeApiErrorItem = (item: ApiErrorItem): item is IssueDateOutOfRangeErrorItem => {
    return isApiErrorItemWithVariables<IssueDateOutOfRangeErrorItemVariables>(item)
        && 'allowedFrom' in item.variables
        && 'allowedUntil' in item.variables
        && 'given' in item.variables;
}
