import {add, differenceInCalendarDays, isSameDay} from "date-fns";
import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {createBranchHoursExceptions} from "@app/OpeningHours/api/branchHoursApi";
import {useOpeningHoursLimits} from "@app/OpeningHours/components/ExceptionActions/useOpeningHoursLimits";
import {useOpeningHours} from "@app/OpeningHours/context/useOpeningHours";
import {BranchHoursException} from "@app/OpeningHours/model/OpeningHoursRequestsAndResponses";
import {DatePicker} from "@common/components/forms/DatePicker/DatePicker";
import {BaseModal, BaseModalMandatoryProps} from "@common/components/modals/BaseModal/BaseModal";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {DateFormatType} from "@common/hooks/useDateFormatter";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './multiDayBranchClosingModal.scss';

export type MultiDayBranchClosingModalProps = BaseModalMandatoryProps & {
    startDate?: Date;
    endDate?: Date;
}

type MultiDayBranchClosingModalState = {
    start?: Date;
    end?: Date;
}

export const MultiDayBranchClosingModal: FC<MultiDayBranchClosingModalProps> = observer(({endDate, onHide, show, startDate}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [state, setState] = useState<MultiDayBranchClosingModalState>({
        start: startDate,
        end: endDate,
    });
    const [openEndDate, setOpenEndDate] = useState<boolean>(false);
    const {firstSelectableDate, maxExceptionDate, maxExceptionEndDate, minExceptionStartDate} = useOpeningHoursLimits();

    const setStartDate = useCallback((start: Date|undefined): void => {
        setState({start, end: state.end});
        if (!state.end) {
            setOpenEndDate(true);
        }
    }, [state.end]);

    const {branchHours} = useOpeningHours();

    const setEndDate = useCallback((end: Date|undefined): void => {
        setOpenEndDate(false);
        setState({start: state.start, end});
    }, [state.start]);

    const close = useCallback(() => {
        setState({});
        onHide();
    }, [onHide]);

    const showErrorToast = useShowErrorToast(modalToast, 'openingHours:error', 'errors:4xx.description');

    const submit = useCallback((): void => {
        const {end, start} = state;

        if (!start) {
            modalToast.error(t('openingHours:multiDayBranchClosingModal.error.startDateMandatory'));
            return;
        }
        if (!end) {
            modalToast.error(t('openingHours:multiDayBranchClosingModal.error.endDateMandatory'));
            return;
        }

        let date: Date = start;
        const closedBranchHours: BranchHoursException[] = [];
        [...Array(differenceInCalendarDays(end, start) + 1).keys()]
            .forEach(() => {
                closedBranchHours.push({date, action: 'create', hours: []});
                date = add(date, {days: 1});
            });

        if (startDate && endDate && (!isSameDay(startDate, start) || (!isSameDay(endDate, end)))) {
            date = startDate;
            [...Array(differenceInCalendarDays(endDate, startDate) + 1).keys()]
                .forEach(() => {
                    closedBranchHours.push({date, action: 'delete'});
                    date = add(date, {days: 1});
                });
        }

        createBranchHoursExceptions({branchHours: closedBranchHours}, appContext.api)
            .then(() => {
                close();
                branchHours.invalidate();
                appToast.success(t(`openingHours:multiDayBranchClosingModal.success.${startDate !== undefined ? 'edit' : 'create'}`));
            })
            .catch((error: Error) => {
                showErrorToast(error);
            });
    }, [state, startDate, endDate, appContext.api, t, close, branchHours, showErrorToast]);

    const minStartDate = state.end ? minExceptionStartDate(state.end) : firstSelectableDate;
    const maxStartDate = state.end || maxExceptionDate;
    const maxEndDate = state.start ? maxExceptionEndDate(state.start) : maxExceptionDate;
    const minEndDate = state.start || firstSelectableDate;

    return <BaseModal
        className="multi-day-branch-closing-modal"
        size="lg"
        show={show}
        onHide={close}
        header={t('openingHours:multiDayBranchClosingModal.title')}
        footer={<Fragment>
            <Button onClick={close} variant="secondary">
                {t('openingHours:multiDayBranchClosingModal.action.cancel')}
            </Button>
            <Button disabled={!state.start || !state.end} onClick={submit} variant="success" data-xid="save-exception">
                {t('openingHours:multiDayBranchClosingModal.action.save')}
            </Button>
        </Fragment>}
        height="auto"
        disableOutsideClose={true}
        xid="multi-day-branch-closing-modal"
    >
        <div>{t('openingHours:multiDayBranchClosingModal.text')}</div>
        <div className="multi-day-branch-closing-modal-date-pickers">
            <DatePicker
                dayPickerProps={{
                    mode: 'range',
                    selected: {from: state.start, to: state.end},
                    disabled: [{before: minStartDate}, {after: maxStartDate}],
                    defaultMonth: minStartDate,
                    fromMonth: minStartDate,
                    toMonth: maxStartDate,
                    modifiers: state,
                }}
                dateFormat={DateFormatType.DATE_FORMAT_WITH_DOW}
                name="startDate"
                formId="multiDayBranchClosingModalForm"
                onDateSelect={setStartDate}
                open={startDate === undefined}
                placeholder={t('openingHours:multiDayBranchClosingModal.startDate.placeholder')}
                value={state.start}
                xid="multi-day-branch-closing-start-date"
            />
            <DatePicker
                dayPickerProps={{
                    mode: 'range',
                    selected: {from: state.start, to: state.end},
                    disabled: [{before: minEndDate}, {after: maxEndDate}],
                    defaultMonth: minEndDate,
                    fromMonth: minEndDate,
                    toMonth: maxEndDate,
                    modifiers: state,
                }}
                dateFormat={DateFormatType.DATE_FORMAT_WITH_DOW}
                name="endDate"
                formId="multiDayBranchClosingModalForm"
                onDateSelect={setEndDate}
                open={openEndDate}
                placeholder={t('openingHours:multiDayBranchClosingModal.endDate.placeholder')}
                value={state.end}
                xid="multi-day-branch-closing-end-date"
            />
        </div>
    </BaseModal>
})
