import {useContext} from "react";

import {OpeningHoursContext} from "@app/OpeningHours/context/openingHoursContext";
import {OpeningHoursManager} from "@app/OpeningHours/context/OpeningHoursManager";

export const useOpeningHours = (): OpeningHoursManager => {
    const openingHours = useContext(OpeningHoursContext);

    if (!openingHours) {
        throw new Error('Trying to use the OpeningHoursManager outside the OpeningHoursContext!');
    }

    return openingHours;
}
