import {DefaultTFuncReturn} from "i18next";
import {DetailedHTMLProps, FC, InputHTMLAttributes, ReactElement} from 'react';

import {buttonClassName, ButtonSize, ButtonVariant} from "@common/components/Button/buttonClassName";

export type SubmitFormGroupProps = {
    name: string;
    value: string;
    formId: string;
    inputOptions?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    label?: DefaultTFuncReturn|ReactElement|ReactElement[];
    variant?: ButtonVariant;
    size?: ButtonSize;
}

export const SubmitFormGroup: FC<SubmitFormGroupProps> = ({
    formId,
    inputOptions,
    label,
    name,
    size,
    value,
    variant= 'primary',
}) => {

    return <div className="form-group form-group__submit">
        {label && <div className="form-group-label"><label>{label}</label></div>}
        <input
            name={name}
            type="submit"
            value={value}
            data-xid={`${formId}-${name}`}
            className={buttonClassName(undefined, variant, size)}
            {...inputOptions}
        />
    </div>
}
