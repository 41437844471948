import {parseISO} from "date-fns";

export const parseDate = (date: Date|string|undefined): Date => {
    if (date instanceof Date) {
        return date;
    }

    if (date === undefined) {
        return new Date();
    }

    return parseISO(date);
}
