import {createContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";

import {FailurePageRoute} from "@app/App/pages/FailurePage/FailurePageRoute";
import {AppContext} from "@app/AppContext/AppContext";
import {
    ApiRequestFailureWatcher
} from "@app/AppContext/classes/Api/ApiRequestFailureWatcher";
import {appConfig} from "@app/config";
import {useOnLanguageChanged} from "@common/hooks/useOnLanguageChanged";
import {FCWithChildren} from "@common/model/FCWithChildren";

export const AppContextReactContext = createContext<AppContext|undefined>(undefined);

export const AppContextProvider: FCWithChildren = ({children}) => {
    const {i18n} = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();

    const [appContext, setAppContext] = useState<AppContext|undefined>(undefined);
    useEffect(() => {
        setAppContext(new AppContext(appConfig, i18n));
    }, [i18n]);

    useEffect(() => {
        if (!appContext || location.pathname.startsWith(FailurePageRoute.path)) {
            return;
        }

        const currentAppContext = appContext;

        const failureWatcher = new ApiRequestFailureWatcher(() => {
            if (!location.pathname.startsWith(FailurePageRoute.path)) {
                navigate(currentAppContext.router.path(FailurePageRoute));
            }
        });

        currentAppContext.api.registerWatcher(failureWatcher);

        return () => {
            currentAppContext.api.unregisterWatcher(failureWatcher);
        }
    }, [appContext, location.pathname, navigate]);

    useOnLanguageChanged(() => {
        if (!appContext) {
            return;
        }

        appContext.catalogues.load();
    }, [appContext]);

    if (!appContext) {
        return null;
    }

    return <AppContextReactContext.Provider value={appContext}>
        {children}
    </AppContextReactContext.Provider>
}
