export enum DeliveryReason {
    MANUAL = 'manual',
    ASSISTANCE = 'assistance',
    OTHER = 'other',
}

export type PredefinedReasonVerification = {
    reason: DeliveryReason.MANUAL|DeliveryReason.ASSISTANCE;
}

export type OtherReasonVerification = {
    reason: DeliveryReason.OTHER;
    otherReasonDescription: string;
}

export type ReasonVerification = PredefinedReasonVerification|OtherReasonVerification;
