import {FC} from "react";
import {useTranslation} from "react-i18next";

import {IncomingParcelListManager} from "@app/Parcel/pages/BranchParcelListPage/IncomingParcelListManager";
import {PaginatedGridSorter} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/PaginatedGridSorter";
import {
    usePaginatedGridSorter
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/usePaginatedGridSorter";

export const INCOMING_PARCEL_GRID_SORTING_STORAGE_KEY = 'ui-incomingParcelGrid-sorting';

export type IncomingParcelGridHeaderProps = {
    manager: IncomingParcelListManager;
}

export const IncomingParcelGridHeader: FC<IncomingParcelGridHeaderProps> = ({manager}) => {
    const {t} = useTranslation();

    const setStoredSorting = usePaginatedGridSorter(
        manager,
        INCOMING_PARCEL_GRID_SORTING_STORAGE_KEY,
        {key: 'sentAt', desc: true},
    );

    if (!manager.hasItems) {
        return null;
    }

    return <thead>
        <tr>
            <th>
                <PaginatedGridSorter manager={manager} onSort={setStoredSorting} sortBy="barcode">
                    {t('parcel:property.number.label')}
                </PaginatedGridSorter>
                <span className="branch-parcel-list__parcel-id-count-status">
                    {' / '}{t('parcel:property.packetCountShort')}
                    {' / '}{t('parcel:property.status')}
                </span>
            </th>
            <th className="branch-parcel-list__status">
                <span className="branch-parcel-list__status-count">
                    {t('parcel:property.packetCountShort')}{' / '}
                </span>
                {t('parcel:property.status')}
            </th>
            <th className="branch-parcel-list__date">
                {t('parcel:property.receivedAt')}
            </th>
            <th className="branch-parcel-list__date">
                <PaginatedGridSorter manager={manager} onSort={setStoredSorting} sortBy="sentAt">
                    {t('parcel:property.sentAt')}
                </PaginatedGridSorter>
            </th>
            <th className="branch-parcel-list__count">{t('parcel:property.packetCountShort')}</th>
            <th />
        </tr>
    </thead>

}
