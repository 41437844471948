import {FC} from "react";
import {useTranslation} from "react-i18next";

import {BranchQualityCustomerFeedback} from "@app/Branch/model/BranchQuality/BranchQualityCustomerFeedback";
import {BranchQualityCustomerFeedbackItems} from "@app/Branch/model/BranchQuality/BranchQualityCustomerFeedbackItems";
import {
    CustomerFeedbackItem
} from "@app/Branch/pages/BranchQualityPage/CustomerFeedback/CustomerFeedbackItem/CustomerFeedbackItem";
import {PercentRating} from "@app/Branch/pages/BranchQualityPage/CustomerFeedback/PercentRating/PercentRating";
import {StarRating} from "@app/Branch/pages/BranchQualityPage/CustomerFeedback/StarRating/StarRating";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";

export type CustomerFeedbackProps = {
    customerFeedback: BranchQualityCustomerFeedback;
}

export const CustomerFeedback: FC<CustomerFeedbackProps> = ({customerFeedback}) => {
    const {t} = useTranslation();

    const dateFormatter = useDateFormatter();

    return <div className="branch-quality-page__box">
        <h5>{t('viewBranchQuality:customerFeedback.header')}</h5>
        <p>{t('viewBranchQuality:dateRange', {
            dateFrom: dateFormatter(customerFeedback.dateRange.since, DateFormatType.DATE_FORMAT),
            dateTo: dateFormatter(customerFeedback.dateRange.to, DateFormatType.DATE_FORMAT),
        })}</p>
        <div className="branch-quality-page__data">
            <div>
                <CustomerFeedbackItem itemType={BranchQualityCustomerFeedbackItems.RECOMMEND}>
                    <PercentRating qualityItem={customerFeedback.items[BranchQualityCustomerFeedbackItems.RECOMMEND]} />
                </CustomerFeedbackItem>
                <CustomerFeedbackItem itemType={BranchQualityCustomerFeedbackItems.OVERALL_EXPERIENCE}>
                    <StarRating qualityItem={customerFeedback.items[BranchQualityCustomerFeedbackItems.OVERALL_EXPERIENCE]} />
                </CustomerFeedbackItem>
                <CustomerFeedbackItem itemType={BranchQualityCustomerFeedbackItems.OVERALL_SATISFACTION}>
                    <StarRating qualityItem={customerFeedback.items[BranchQualityCustomerFeedbackItems.OVERALL_SATISFACTION]} />
                </CustomerFeedbackItem>
            </div>
            <div>
                <CustomerFeedbackItem itemType={BranchQualityCustomerFeedbackItems.BRANCH_WELL_MARKED_AVERAGE}>
                    <StarRating qualityItem={customerFeedback.items[BranchQualityCustomerFeedbackItems.BRANCH_WELL_MARKED_AVERAGE]} />
                </CustomerFeedbackItem>
                <CustomerFeedbackItem itemType={BranchQualityCustomerFeedbackItems.STAFF_SATISFACTION_AVERAGE}>
                    <StarRating qualityItem={customerFeedback.items[BranchQualityCustomerFeedbackItems.STAFF_SATISFACTION_AVERAGE]} />
                </CustomerFeedbackItem>
                <CustomerFeedbackItem itemType={BranchQualityCustomerFeedbackItems.SPEED_SATISFACTION_AVERAGE}>
                    <StarRating qualityItem={customerFeedback.items[BranchQualityCustomerFeedbackItems.SPEED_SATISFACTION_AVERAGE]} />
                </CustomerFeedbackItem>
            </div>
        </div>
    </div>

}
