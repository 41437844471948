import {Locale} from "date-fns";
import {cs, enGB, hu, ro, sk, vi} from "date-fns/locale";
import {useMemo} from "react";
import {useTranslation} from "react-i18next";

export const useDateFnsLocale = (): Locale => {
    const {i18n} = useTranslation();

    return useMemo(() => {
        switch (i18n.language) {
            case 'cs-CZ': return cs;
            case 'hu-HU': return hu;
            case 'ro-RO': return ro;
            case 'sk-SK': return sk;
            case 'vi-VN': return vi;
            default: return enGB;
        }
    }, [i18n]);
}
