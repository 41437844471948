import {NewMessageRoute} from "@app/Messaging/MessagingRoutes";
import {MessageTopic} from "@app/Messaging/model/MessageTopic";
import {AppLink, AppLinkProps} from "@common/components/routing/AppLink/AppLink";
import {FCWithChildren} from "@common/model/FCWithChildren";

export type NewMessageButtonProps = Omit<AppLinkProps, 'to'> & {
    topic?: MessageTopic;
}

export const DEFAULT_TOPIC_QUERY_PARAM = 'defaultTopic';

export const NewMessageButton: FCWithChildren<NewMessageButtonProps> = ({children, topic, ...buttonProps}) => {
    return <AppLink to={{
        route:  NewMessageRoute,
        search: topic ? {[DEFAULT_TOPIC_QUERY_PARAM]: topic} : undefined,
    }} {...buttonProps}>{children}</AppLink>
}
