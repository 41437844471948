import {useWindowWidth} from "@react-hook/window-size";
import {Fragment} from "react";

import {FCWithChildren} from "@common/model/FCWithChildren";

export const RESPONSIVE_BREAKPOINTS = {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: 1400,
    xxl: Infinity,
};

export type ResponsiveRenderProps = {
    up?: keyof typeof RESPONSIVE_BREAKPOINTS;
    down?: keyof typeof RESPONSIVE_BREAKPOINTS;
}

export const ResponsiveRender: FCWithChildren<ResponsiveRenderProps> = ({children, down, up}) => {
    const windowWidth = useWindowWidth();

    if ((up && windowWidth < RESPONSIVE_BREAKPOINTS[up]) || (down && windowWidth >= RESPONSIVE_BREAKPOINTS[down])) {
        return null;
    }

    return <Fragment>{children}</Fragment>
}
