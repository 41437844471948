import {add} from "date-fns";

import {Api} from "@app/AppContext/classes/Api/Api";
import {barcodePacketList} from "@app/Packet/api/packetListApi";
import {TypedPacketListRequest} from "@app/Packet/components/PacketGrid/callPacketListApi";
import {PacketListManager} from "@app/Packet/model/PacketListManager";
import {PacketListResponse} from "@app/Packet/model/PacketListRequestsAndResponses";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {extractEmbedded} from "@common/utils/extractEmbedded";
import {isLocalStorageProblematic, readLocalStorage, writeLocalStorage} from "@common/utils/localStorage";
import {mergeMaps} from "@common/utils/mergeMaps";
import {notEqual} from "@common/utils/notEqual";

export type CachedPacketList = {
    request: TypedPacketListRequest;
    response: PacketListResponse;
}

const PACKET_LIST_CACHE_MAX_AGE: Duration = {minutes: 1};

export class DashboardGridManager extends PacketListManager {
    protected static readonly CACHE_KEY = 'dashboardGrid-packets';

    public constructor(branchId: string, api: Api) {
        super(branchId, api, true);
    }

    public async fetchData(): Promise<void> {
        const cachedPacketList = readLocalStorage<CachedPacketList>(DashboardGridManager.CACHE_KEY);
        if (!cachedPacketList) {
            return super.fetchData();
        }

        if (add(new Date(cachedPacketList.response.timestamp), PACKET_LIST_CACHE_MAX_AGE) < new Date()) {
            this.cache(null);
            return super.fetchData();
        }

        const currentRequest = this.assembleRequest();
        if (!currentRequest) {
            return;
        }

        if (notEqual<TypedPacketListRequest>(cachedPacketList.request, currentRequest)) {
            return super.fetchData();
        }

        this.setData(cachedPacketList.response);
    }

    public setData(response: PacketListResponse | null): void {
        if (!response) {
            return super.setData(response);
        }

        const request = this.assembleRequest();
        if (request) {
            this.cache({request, response});
        }

        return super.setData(response);
    }

    public async refreshPackets(barcodes: string[]): Promise<void> {
        const barcodesToUpdate = this.items
            .filter((packet) => barcodes.includes(packet.barcode))
            .map((packet) => packet.barcode);

        if (barcodesToUpdate.length === 0) {
            return;
        }

        try {
            const response = await barcodePacketList({barcode: barcodesToUpdate, embed: this.request.embed}, this.api);
            this.branches = mergeMaps(this.branches, extractEmbedded(response, Embeddable.BRANCH));
            this.senders = mergeMaps(this.senders, extractEmbedded(response, Embeddable.SENDER));

            response.items.forEach((freshPacket) => this.updatePacket(freshPacket));
        } catch (error: unknown) {
            // refresh failed, do nothing, just invalidate
        }

        this.invalidate();
    }

    public invalidate(): void {
        this.cache(null);
    }

    public async reload(): Promise<void> {
        this.invalidate();
        return super.reload();
    }

    public cache(data: CachedPacketList|null): void {
        if (!data || !isLocalStorageProblematic()) {
            writeLocalStorage<CachedPacketList>(DashboardGridManager.CACHE_KEY, data);
        }
    }
}
