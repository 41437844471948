import {Api} from "@app/AppContext/classes/Api/Api";
import {commissionList} from "@app/Commission/api/commissionApi";
import {Commission} from "@app/Commission/model/Commission";
import {CommissionListRequest, CommissionListResponse} from "@app/Commission/model/CommissionRequestsAndResponses";
import {actualDateRange, SelectedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";
import {SearchModifier} from "@common/model/requests/SearchRequestProperty";
import {dateRangeToFilterRequestParameter} from "@common/utils/api/dateRangeToFilterRequestParameter";

export type BranchCommissionListFilter = {
    typeId?: string;
    createdAt?: SelectedDateRange;
    barcode?: string;
}

export class BranchCommissionListManager extends SortablePaginatedDataManager<
    Commission, CommissionListRequest, Pick<CommissionListResponse, 'sum'>
> {
    public sum?: string;

    constructor(public branchId: string, api: Api, pageSize?: number) {
        super((request) => commissionList(request, api), {branchId}, pageSize);

        this.processResponse.push((response) => {
            this.sum = response ? response.sum : undefined;
        });
    }

    public setFilter = (filter: BranchCommissionListFilter): void => {
        const newFilteringRequest: Partial<CommissionListRequest> = {
            typeId: filter.typeId ? {value: filter.typeId, modifier: SearchModifier.EQ} : undefined,
        };

        if (filter.createdAt) {
            const actualRange = actualDateRange(filter.createdAt);

            if (actualRange.from && actualRange.to) {
                newFilteringRequest.createdAt = dateRangeToFilterRequestParameter(actualRange);
            }
        }

        if (filter.barcode && filter.barcode !== '') {
            newFilteringRequest.barcode = {value: filter.barcode, modifier: SearchModifier.EQ};
        }

        this.setFilteringRequest(newFilteringRequest);
    }
}
