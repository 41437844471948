import {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {PacketReceiptPrint} from "@app/Packet/components/PacketReceiptPrint/PacketReceiptPrint";
import {ReceiveReturnShipmentResponse} from "@app/Packet/model/PacketRequestsAndResponses";
import {PacketDetailRoute} from "@app/Packet/PacketRoutes";
import {
    PacketAtDestination
} from "@app/Packet/pages/DashboardPage/SearchForm/PacketAtDestination/PacketAtDestination";
import {PasswordHandlingComponent} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {
    SurpriseCodeDepotLabelPrint
} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/SurpriseCodeModal/SurpriseCodeDepotLabelPrint/SurpriseCodeDepotLabelPrint";
import {useSurpriseCodeModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/SurpriseCodeModal/useSurpriseCodeModal";
import {Button} from "@common/components/Button/Button";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {Img} from "@common/components/Img/Img";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {TranslationWithHtmlParam} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {PrintResult, PrintResultType} from "@common/model/PrintResult";

import './surpriseCodeModal.scss';

export const SurpriseCodeModal: PasswordHandlingComponent = ({onError, onFinish, onVerified, password}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [isSubmitting, onSubmitting] = useState<boolean>(false);
    const [depotLabelPrint, setDepotLabelPrint] = useState<PrintResult|undefined>(undefined);
    const [consignmentReceiptPrint, setConsignmentReceiptPrint] = useState<PrintResult|undefined>(undefined);
    const [barcode, setBarcode] = useState<string|null>(null);
    const [isAtDestination, setIsAtDestination] = useState<boolean>(false);

    const onResponseReceived = (response: ReceiveReturnShipmentResponse) => {
        setDepotLabelPrint(
            response.prints.find((printResult) => printResult.type === PrintResultType.DEPOT_LABEL)
        );
        setConsignmentReceiptPrint(
            response.prints.find((printResult) => printResult.type === PrintResultType.CONSIGNMENT_RECEIPT)
        );
        setBarcode(response.barcode);
        setIsAtDestination(response.isAtDestination);
    }

    const {close, createNew, form, receive, state} = useSurpriseCodeModal(
        {password, onFinish, onError, onVerified, onSubmitting, onResponseReceived}
    );
    const dateFormatter = useDateFormatter();

    return <BaseModal
        show={state.modalOpened}
        onHide={close}
        loading={isSubmitting}
        size="lg"
        height="auto"
        disableOutsideClose={true}
        className="surprise-code-modal"
        header={t('viewSurpriseCode:title')}
        footer={<Fragment>
            {barcode && <Fragment>
                <SurpriseCodeDepotLabelPrint
                    barcode={barcode}
                    printResult={depotLabelPrint}
                    onPrinted={setDepotLabelPrint}
                    onSubmitting={onSubmitting}
                />
                <PacketReceiptPrint
                    barcode={barcode}
                    printResult={consignmentReceiptPrint}
                    onPrinted={setConsignmentReceiptPrint}
                    onSubmitting={onSubmitting}
                />
            </Fragment>}
            <Button onClick={close}>{t(`viewSurpriseCode:action.${barcode ? 'close' : 'cancel'}`)}</Button>
            {!barcode && !state.usedCode && <Button variant="success" onClick={form.handleSubmit(receive)}>
                {t('viewSurpriseCode:action.receive')}
            </Button>}
        </Fragment>}
        xid="surprise-code-modal"
    >
        {!barcode && <Fragment>
            {state.usedCode !== undefined && <div className="alert alert-danger surprise-code-modal__repeated-use-alert">
                <div>
                    <p>
                        <strong>
                            {t('viewSurpriseCode:message.repeatedEshopCodeFirstPart', {
                                time: dateFormatter(state.usedCode.usedAt, DateFormatType.TIME_NO_DATE_FORMAT),
                            })}{' '}
                            {state.usedCode.barcode && <FormattedPacketBarcode barcode={state.usedCode.barcode} withoutLink={true} />}{'.'}
                        </strong>
                    </p>
                    <div className="surprise-code-modal__repeated-use-alert-with-button">
                        <p>
                            <strong>
                                {t('viewSurpriseCode:message.repeatedEshopCodeSecondPartBold')}{' '}
                                {state.usedCode.barcode &&
                                    <FormattedPacketBarcode barcode={state.usedCode.barcode} withoutLink={true}/>}{'.'}
                            </strong>{' '}
                            {t('viewSurpriseCode:message.repeatedEshopCodeSecondPart')}
                        </p>
                        {!barcode && state.usedCode && state.usedCode.barcode && <SurpriseCodeDepotLabelPrint
                            barcode={state.usedCode.barcode}
                            reprintText={true}
                            onPrinted={close}
                            onSubmitting={onSubmitting}
                            toastInModal={false}
                        />}
                    </div>
                    <div className="surprise-code-modal__repeated-use-alert-with-button">
                        <p>
                            <strong>
                                {t('viewSurpriseCode:message.repeatedEshopCodeThirdPartBold')}
                            </strong>{' '}
                            {t('viewSurpriseCode:message.repeatedEshopCodeThirdPart')}
                        </p>
                        {!barcode && state.usedCode && state.usedCode.barcode && <Button
                            variant="success"
                            onClick={createNew}
                            data-xid="create-new"
                        >
                            {t('viewSurpriseCode:action.createNew')}
                        </Button>}
                    </div>
                </div>
            </div>}
            {state.usedCode === undefined && <Fragment>
                <div className="surprise-code-modal__info">
                    <p>{t('viewSurpriseCode:action.confirmReceivalFor')}</p>
                    <p className="surprise-code-modal__eshop-name">{state.eshopName}</p>
                    <p>{t('viewSurpriseCode:action.askCustomerForNameConfirmation')}</p>
                    {!state.clientCanPostPackets && <p className="surprise-code-modal__warning">
                        {t('viewSurpriseCode:action.clientCanNotPostPacketsWarning')}
                    </p>}
                </div>
                <form onSubmit={form.handleSubmit(receive)}>
                    <FormGroup
                        name="customerName"
                        form={form}
                        formId="surpriseCodeModalForm"
                        label={t('viewSurpriseCode:action.enterCustomerName')}
                        registerOptions={{
                            required: t<string>('viewSurpriseCode:action.enterCustomerName'),
                            pattern: {
                                value: /^[\p{L}\p{N} ,.'\-&()]+$/ui,
                                message: t<string>('viewSurpriseCode:error.notAllowedCharacters'),
                            }
                        }}
                        inputOptions={{placeholder: t('viewSurpriseCode:action.enterCustomerNameHint')}}
                    />
                </form>
            </Fragment>}
        </Fragment>}
        {barcode && <Fragment>
            <TranslationWithHtmlParam
                paramName="barcode"
                translationKey={'viewSurpriseCode:messageWithBarcode'}
                t={t}
                className="surprise-code-modal__barcode-message"
            >
                <FormattedPacketBarcode
                    barcode={barcode}
                    withHighlight={true}
                    linkHref={appContext.router.href({route: PacketDetailRoute, params: {barcode}})}
                />
            </TranslationWithHtmlParam>
            {consignmentReceiptPrint && <p>{t('viewSurpriseCode:message.nowPrinting')}</p>}
            <div className="surprise-code-modal__prints" data-xid="surprise-code-modal-prints">
                <div>
                    {!consignmentReceiptPrint && <p>{t('viewSurpriseCode:message.nowPrinting')}</p>}
                    <p>{t('viewSurpriseCode:label.name')}</p>
                    <Img src="/images/label-icon.jpg" alt={t('viewSurpriseCode:label.name')} />
                    <p className="surprise-code-modal__print-hint">{t('viewSurpriseCode:label.hint')}</p>
                </div>
                <div>
                    {consignmentReceiptPrint && <p>{t('viewSurpriseCode:receipt.name')}</p>}
                    {!consignmentReceiptPrint && <p><PacketReceiptPrint
                        barcode={barcode}
                        printResult={consignmentReceiptPrint}
                        onPrinted={setConsignmentReceiptPrint}
                        onSubmitting={onSubmitting}
                    /></p>}
                    <Img src="/images/receipt-icon.jpg" alt={t('viewSurpriseCode:receipt.name')} />
                    <p className="surprise-code-modal__print-hint">{t('viewSurpriseCode:receipt.hint')}</p>
                </div>
            </div>
            {isAtDestination && <PacketAtDestination barcode={barcode} />}
        </Fragment>}
    </BaseModal>
}
