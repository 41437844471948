import {FC} from "react";
import {useTranslation} from "react-i18next";

import {PASSWORD_MIN_LENGTH} from "@app/User/hooks/useChangePasswordValidation";

export type ChangePasswordRequirementsProps = {
    differFromCurrent: boolean;
}

export const ChangePasswordRequirements: FC<ChangePasswordRequirementsProps> = ({differFromCurrent}) => {
    const {t} = useTranslation();

    return <div className="change-password-requirements">
        <h4>{t('viewChangePassword:passwordRequirements.title')}</h4>
        <ol>
            <li>{t('viewChangePassword:passwordRequirements.requirements.length', {length: PASSWORD_MIN_LENGTH})}</li>
            <li>{t('viewChangePassword:passwordRequirements.requirements.number')}</li>
            <li>{t('viewChangePassword:passwordRequirements.requirements.uppercase')}</li>
            <li>{t('viewChangePassword:passwordRequirements.requirements.hasToDifferFromEmailPart')}</li>
            {differFromCurrent && <li>{t('viewChangePassword:passwordRequirements.requirements.hasToDifferFromCurrent')}</li>}
        </ol>
    </div>
}
