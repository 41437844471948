import {PropsWithChildren, ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";

export type MoneyFormatterProps = {
    amount: number|string;
    currencyCode?: string;
    dressed?: boolean;
}

export const MoneyFormatter = ({amount, currencyCode, dressed = true}: PropsWithChildren<MoneyFormatterProps>): ReactElement<any, string>|null => {
    const {t} = useTranslation();

    const currency = currencyCode || t<string>('core:money.defaultCurrency');

    const decimalSeparator = t('core:money.decimalSeparator');
    const thousandsSeparator = t('core:money.thousandsSeparator').replace(' ', " ");
    const decimalScale = parseInt(t(`core:money.parameters.${currency}.decimals`), 10);
    const currencySymbol = t(`core:money.parameters.${currency}.symbol`);
    const position = t(`core:money.parameters.${currency}.position`);
    const useSeparator = t(`core:money.parameters.${currency}.useSeparator`) === 'true';
    const roundTo = parseInt(t(`core:money.parameters.${currency}.roundTo`), 10);

    if (typeof amount === 'string') {
        amount = parseFloat(amount);
    }

    if (roundTo > 1) {
        amount = (Math.round(amount / roundTo)) * roundTo;
    }

    // eslint-disable-next-line id-blacklist
    const currencySeparator = useSeparator ? " " : '';

    return <NumericFormat
        value={amount}
        thousandSeparator={dressed && thousandsSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        fixedDecimalScale={true}
        displayType="text"
        prefix={dressed && position === 'before' ? `${currencySymbol}${currencySeparator}` : undefined}
        suffix={dressed && position === 'after' ? `${currencySeparator}${currencySymbol}` : undefined}
    />
}
