import {compareDesc} from "date-fns";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {PacketTrackingItem} from "@app/Packet/model/PacketTrackingItem";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";

export type PacketTrackingGridProps = {
    trackingItems: PacketTrackingItem[];
}

export const PacketTrackingGrid: FC<PacketTrackingGridProps> = ({trackingItems}) => {
    const {t} = useTranslation();

    trackingItems.sort((itemA, itemB) => compareDesc(itemA.timestamp, itemB.timestamp));

    return <GridTable>
        <thead>
            <tr>
                <th>{t('viewPacketDetail:stateTrace.date')}</th>
                <th>{t('packet:property.status')}</th>
            </tr>
        </thead>
        <tbody>
            {trackingItems.map((item) => (<tr key={`${item.timestamp.toString()}-${item.status}`}>
                <td><DateFormatter date={item.timestamp} showTime={true} /></td>
                <td dangerouslySetInnerHTML={{__html: item.status}} />
            </tr>))}
        </tbody>
    </GridTable>
}
