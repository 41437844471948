
import {PropsWithChildren, ReactElement, useCallback} from "react";

import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";
import {SortingCaret} from "@common/components/grids/SortingCaret/SortingCaret";
import {RequestWithSort, SortKeysOfRequest, SortPropertyOfRequest} from "@common/model/requests/SortRequestProperty";

import './paginatedGridSorter.scss';

export type PaginatedGridSorterProps<ItemType, RequestType extends RequestWithSort, ExtendedResponseType> = {
    manager: SortablePaginatedDataManager<ItemType, RequestType, ExtendedResponseType>;
    onSort: (newSort: SortPropertyOfRequest<RequestType>|null) => void;
    sortBy: SortKeysOfRequest<RequestType>;
}

export const PaginatedGridSorter = <ItemType, RequestType extends RequestWithSort, ExtendedResponseType>({
    children,
    manager,
    onSort,
    sortBy,
}: PropsWithChildren<PaginatedGridSorterProps<ItemType, RequestType, ExtendedResponseType>>): ReactElement|null => {
    const sort = useCallback(() => {
        const isCurrentSorting = manager.sorting && manager.sorting.key === sortBy;
        const newSort = isCurrentSorting && manager.sorting && manager.sorting.desc ? null : {
            key: sortBy,
            desc: !!isCurrentSorting,
        };
        manager.sort(newSort);
        onSort(newSort);
    }, [manager, onSort, sortBy]);

    return <a onClick={sort} className="paginated-grid-sorter">
        {children}
        {manager.sorting && manager.sorting.key === sortBy && <SortingCaret desc={manager.sorting.desc} />}
    </a>
}
