import classNames from "classnames";
import {FC, Fragment, ReactElement} from "react";
import {useTranslation} from "react-i18next";

import {GridOpeningHours} from "@app/OpeningHours/components/EditableOpeningHoursGrid/GridOpeningHours";
import {
    BlockEditCell
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGridRow/BlockEditCell/BlockEditCell";
import {CenteredCell} from "@common/components/grids/CenteredCell/CenteredCell";

export type BlocksEditProps = {
    editing?: boolean;
    highlightExceptions?: boolean;
    mobile?: boolean;
    openingHours: GridOpeningHours;
    rowIndex: number;
}

export const BlocksEdit: FC<BlocksEditProps> = ({
    editing = false,
    highlightExceptions = false,
    mobile = false,
    openingHours,
    rowIndex
}) => {
    const {t} = useTranslation();

    const renderMobileEditCell = (blockIndex: number): ReactElement =>
        <BlockEditCell
            className="mobile-edit-cell-edit"
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            highlightExceptions={highlightExceptions}
            hoursBlock={openingHours.hours[blockIndex]}
            regularHoursBlock={openingHours.regularHours && openingHours.regularHours[blockIndex]}
        />

    const renderDesktopEditCell = (blockIndex: number): ReactElement =>
        <BlockEditCell
            className="desktop-edit-cell"
            rowIndex={rowIndex}
            blockIndex={blockIndex}
            highlightExceptions={highlightExceptions}
            hoursBlock={openingHours.hours[blockIndex]}
            regularHoursBlock={openingHours.regularHours && openingHours.regularHours[blockIndex]}
        />

    if (mobile) {
        return <Fragment>
            <tr className={classNames('mobile-edit-row', {'mobile-edit-row--editing': editing})}>
                <CenteredCell className="mobile-edit-cell-title">{t('openingHours:grid.block1')}</CenteredCell>
                {renderMobileEditCell(0)}
            </tr>
            <tr className={classNames('mobile-edit-row', {'mobile-edit-row--editing': editing})}>
                <CenteredCell className="break-cell" colSpan={2}>{t('openingHours:grid.break')}</CenteredCell>
            </tr>
            <tr className={classNames('mobile-edit-row', {'mobile-edit-row--editing': editing})}>
                <CenteredCell className="mobile-edit-cell-title">{t('openingHours:grid.block2')}</CenteredCell>
                {renderMobileEditCell(1)}
            </tr>
            <tr className={classNames('mobile-edit-row', {'mobile-edit-row--editing': editing})}>
                <CenteredCell className="break-cell" colSpan={2}>{t('openingHours:grid.break')}</CenteredCell>
            </tr>
            <tr className={classNames('mobile-edit-row', {'mobile-edit-row--editing': editing})}>
                <CenteredCell className="mobile-edit-cell-title">{t('openingHours:grid.block3')}</CenteredCell>
                {renderMobileEditCell(2)}
            </tr>
        </Fragment>
    }

    return <Fragment>
        {renderDesktopEditCell(0)}
        <CenteredCell className="break-cell">{t('openingHours:grid.break')}</CenteredCell>
        {renderDesktopEditCell(1)}
        <CenteredCell className="break-cell">{t('openingHours:grid.break')}</CenteredCell>
        {renderDesktopEditCell(2)}
    </Fragment>
}
