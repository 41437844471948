import {FC} from "react";
import {Route, Routes} from "react-router-dom";

import {
    BillingInfoRoute, BranchCommissionRoute, CommissionTariffRoute,
    InvoiceListRoute, ManagedCommissionRoute, NewInvoiceRoute
} from "@app/Commission/CommissionRoutes";
import {
    BillingInformationPage
} from "@app/Commission/pages/BillingInformationPage/BillingInformationPage";
import {BranchCommissionPage} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionPage";
import {CommissionTariffPage} from "@app/Commission/pages/CommissionTariffPage/CommissionTariffPage";
import {InvoiceListPage} from "@app/Commission/pages/InvoiceListPage/InvoiceListPage";
import {ManagedCommissionPage} from "@app/Commission/pages/ManagedCommissionPage/ManagedCommissionPage";
import {NewInvoicePage} from "@app/Commission/pages/NewInvoicePage/NewInvoicePage";
import {RouteParamsProvider} from "@common/components/routing/RouteParamsProvider/RouteParamsProvider";

export const Commission: FC = () => {
    return <Routes>
        <Route path={ManagedCommissionRoute.path} element={<ManagedCommissionPage />} />
        <Route path={CommissionTariffRoute.path} element={<CommissionTariffPage />} />
        <Route path={BillingInfoRoute.path} element={<BillingInformationPage />} />
        <Route path={NewInvoiceRoute.path} element={<NewInvoicePage />} />
        <Route path={InvoiceListRoute.path} element={<InvoiceListPage />} />
        <Route path={BranchCommissionRoute.path} element={<RouteParamsProvider
                path={BranchCommissionRoute}
                render={({branchId}) => (<BranchCommissionPage branchId={branchId} />)}
            />}
        />
    </Routes>
}
