import {useEffect, useState} from "react";

import {GoogleTagManager} from "@app/App/components/AppWrapper/GtmProvider/GoogleTagManager";
import {GtmContext} from "@app/App/components/AppWrapper/GtmProvider/GtmContext";
import {useTrackPageView} from "@app/App/hooks/useTrackPageView";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {FCWithChildren} from "@common/model/FCWithChildren";

export const GtmProvider: FCWithChildren = ({children}) => {
    const {config} = useAppContext();

    const [googleTagManager, setGoogleTagManager] = useState<GoogleTagManager|undefined>(undefined);
    useEffect(() => {
        setGoogleTagManager(new GoogleTagManager(config.gtmId));
    }, [config.gtmId]);

    useTrackPageView(googleTagManager);

    if (!googleTagManager) {
        return null;
    }

    return <GtmContext.Provider value={googleTagManager}>
        {children}
    </GtmContext.Provider>
}
