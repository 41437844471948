import {useFlags} from "launchdarkly-react-client-sdk";

import {DataSource} from "@app/App/components/AppWrapper/DataSourceProvider/model/DataSource";

export type FeatureFlags = {
    packetDeliveryIsAtUsersBranchCheck?: boolean;
    packetParcelLists?: boolean;
    benefitsPage?: boolean;
    branchEventsSource?: DataSource;
    dashboardMessagesSource?: DataSource;
}

type NotEmptyFeatureFlag<FlagName extends keyof FeatureFlags> = Required<FeatureFlags>[FlagName];

export const useFeatureFlag = <FlagType extends keyof FeatureFlags>(flag: FlagType, defaultValue: NotEmptyFeatureFlag<FlagType>): NotEmptyFeatureFlag<FlagType> => {
    const flags = useFlags<FeatureFlags>();

    const flagValue = flags[flag];
    return flagValue !== undefined ? flagValue as NotEmptyFeatureFlag<FlagType> : defaultValue;
}
