import {add} from "date-fns";

import {ApiBackend} from "@app/AppContext/classes/Api/model/ApiBackend";
import {ApiRequestWatcher, RunningRequest} from "@app/AppContext/classes/Api/model/ApiRequestWatcher";
import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {ApiError} from "@common/model/errors/ApiError";
import {HttpStatusCode} from "@common/model/HttpStatusCode";
import {checkConnection} from "@common/utils/api/checkConnection";
import {readLocalStorage, writeLocalStorage} from "@common/utils/localStorage";

const SLOW_REQUEST_TIMEOUT = 5 * 1000; // 5 seconds timeout
const ISSUES_CACHE_KEY = 'apiHealth-issues';

const MAX_SLOW_REQUESTS_TO_LIVE = 15;
const MAX_ERRORS_TO_LIVE = 5;
const API_HEALTH_TIME_WINDOW = 300; // 5m

export const SLOW_API_HEALTH_ISSUE = 'slow';
type ApiHealthIssueType = HttpStatusCode.INTERNAL_SERVER_ERROR|HttpStatusCode.SERVICE_UNAVAILABLE|typeof SLOW_API_HEALTH_ISSUE;

type ApiHealthIssue = {
    date: Date;
    endpoint: Endpoint;
    issueType: ApiHealthIssueType;
}

export class ApiRequestFailureWatcher extends ApiRequestWatcher {
    private requestTimeouts: Map<RunningRequest, number> = new Map();
    private issues: ApiHealthIssue[];

    public constructor(private onFailure: () => void) {
        super();

        this.issues = readLocalStorage(ISSUES_CACHE_KEY) || [];
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    public async onRequestStart(request: RunningRequest): Promise<void> {
        if (request.endpoint.ignoreFailures) {
            return;
        }

        this.requestTimeouts.set(request, window.setTimeout(() => {
            this.addIssue({
                date: new Date(),
                endpoint: request.endpoint,
                issueType: SLOW_API_HEALTH_ISSUE,
            });
        }, SLOW_REQUEST_TIMEOUT));
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    public async onRequestSuccess(request: RunningRequest): Promise<void> {
        this.clearTimeout(request);
    }

    public async onRequestError(request: RunningRequest, error: unknown): Promise<void> {
        this.clearTimeout(request);

        if (error instanceof ApiError && error.errors.length === 0) {
            if (error.code === HttpStatusCode.SERVICE_UNAVAILABLE || error.code === HttpStatusCode.INTERNAL_SERVER_ERROR) {
                this.addIssue({
                    date: new Date(),
                    endpoint: request.endpoint,
                    issueType: error.code,
                });
            }
        }

        if (error instanceof TypeError && await checkConnection()) {
            this.addIssue({
                date: new Date(),
                endpoint: request.endpoint,
                issueType: HttpStatusCode.SERVICE_UNAVAILABLE,
            });
        }
    }

    public requiresWatch(request: RunningRequest): boolean {
        return ! request.endpoint.ignoreFailures
            && (request.endpoint.backend === undefined || request.endpoint.backend === ApiBackend.AZAPI);
    }

    public recover(): void {
        this.issues = [];
        writeLocalStorage(ISSUES_CACHE_KEY, this.issues);
    }

    public onUnregister() {
        for (const [_request, timeout] of this.requestTimeouts) {
            window.clearTimeout(timeout);
        }
    }

    private addIssue(issue: ApiHealthIssue) {
        this.issues.push(issue);

        if (this.isInFailureState()) {
            this.onFailure();
        }
    }

    private filterOldRecords() {
        const threshold = add(new Date(), {seconds: -1 * API_HEALTH_TIME_WINDOW});
        this.issues = this.issues.filter((issue) => issue.date > threshold);
        writeLocalStorage(ISSUES_CACHE_KEY, this.issues);
    }

    private isInFailureState(): boolean {
        this.filterOldRecords();

        const slowRequestIssuesCount = this.issues
            .filter((issue) => issue.issueType === SLOW_API_HEALTH_ISSUE)
            .length;
        const errorIssuesCount = this.issues.length - slowRequestIssuesCount;

        return errorIssuesCount > MAX_ERRORS_TO_LIVE || slowRequestIssuesCount > MAX_SLOW_REQUESTS_TO_LIVE;
    }

    private clearTimeout(request: RunningRequest): void {
        const timeout = this.requestTimeouts.get(request);
        if (timeout) {
            window.clearTimeout(timeout);
            this.requestTimeouts.delete(request);
        }
    }
}
