
import {Api} from "@app/AppContext/classes/Api/Api";
import {microDepotEndpoint, microDepotRouteFinalizationEndpoint, microDepotRouteSummaryEndpoint} from "@app/MicroDepot/api/microDepotEndpoints";
import {FinalizeRouteRequest, MicroDepotResponse, MicroDepotRouteResponse} from "@app/MicroDepot/model/MicroDepotRequestsAndResponses";
import {ResponseWithPrintables} from "@common/model/responses/ResponseWithPrintables";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const route = async (password: string, api: Api): Promise<MicroDepotRouteResponse> => {
    return api.call(endpointWithRouteParams(microDepotRouteSummaryEndpoint, {password}));
}

export const finalize = async (request: FinalizeRouteRequest, api: Api): Promise<ResponseWithPrintables> => {
    return api.call({endpoint: microDepotRouteFinalizationEndpoint, payload: request});
}

export const microDepot = async (api: Api): Promise<MicroDepotResponse> => {
    return api.call(microDepotEndpoint);
}
