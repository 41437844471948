
import {sub} from "date-fns";
import {FC, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {
    TRANSACTION_MAX_RANGE
} from "@app/Transaction/components/TransactionGrid/TransactionGridFilter/TransactionGridFilter";
import {
    useManagedCashRegisterExport
} from "@app/Transaction/pages/ManagedCashRegisterPage/ManagedCashRegisterExportForm/useManagedCashRegisterExport";
import {Button} from "@common/components/Button/Button";
import {DateRangePicker} from "@common/components/forms/DateRangePicker/DateRangePicker";
import {SelectedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {
    useGeneralDateRangePickerRanges
} from "@common/components/forms/DateRangePicker/useGeneralDateRangePickerRanges";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './managedCashRegisterExportForm.scss';

export const ManagedCashRegisterExportForm: FC = () => {
    const {t} = useTranslation();
    const namedRanges = useGeneralDateRangePickerRanges();
    const [dateRange, setDateRange] = useState<SelectedDateRange|undefined>(undefined);

    const showAppErrorToast = useShowErrorToast(appToast);
    const managedCashRegisterExport = useManagedCashRegisterExport();
    const onExport = useCallback(async () => {
        try {
            await managedCashRegisterExport(dateRange);
        } catch (error: unknown) {
            showAppErrorToast(error as Error);
        }
    }, [dateRange, managedCashRegisterExport, showAppErrorToast]);

    return <div className="managed-cash-register-export-form">
        <h4>{t('viewTransaction:managedCashRegister.export.header')}</h4>
        <p>{t('viewTransaction:managedCashRegister.export.description')}</p>
        <div className="managed-cash-register-export-form__form">
            <DateRangePicker
                label={t('viewTransaction:managedCashRegister.export.dateRange')}
                selectedRange={dateRange}
                clearOption={true}
                formId="managedCashRegisterExportForm"
                namedRanges={namedRanges}
                minStartDate={sub(new Date(), TRANSACTION_MAX_RANGE)}
                maxEndDate={new Date()}
                onChange={setDateRange}
            />
            <div>
                <Button onClick={onExport}>
                    {t('viewTransaction:managedCashRegister.export.button')}
                </Button>
            </div>
        </div>
    </div>

}
