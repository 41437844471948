import {FC} from "react";

import {ManualsCategory} from "@app/Manuals/components/ManualsCategories/ManualsCategory/ManualsCategory";
import {ManualCategoryTreeNode} from "@app/Manuals/model/ManualCategoryTreeNode";
import {ManualPost} from "@app/Manuals/model/ManualPost";

import './manualsCategories.scss';

export type ManualsCategoriesProps = {
    categoryTreeNodes: ManualCategoryTreeNode[];
    onSelect: (post: ManualPost) => void;
}

export const ManualsCategories: FC<ManualsCategoriesProps> = ({categoryTreeNodes, onSelect}) => {

    if (categoryTreeNodes.length === 0) {
        return null;
    }

    return <div className="manuals-categories">
        {categoryTreeNodes
            .map((categoryTreeNode) => <ManualsCategory
                categoryTreeNode={categoryTreeNode}
                onSelect={onSelect}
                key={categoryTreeNode.category.id}
            />)}
    </div>
}
