import classNames from "classnames";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {getVariable} from "@app/Devices/components/ReaderModal/utils/getVariable";
import {jobResultTitle} from "@app/Devices/components/ReaderModal/utils/jobResultTitle";
import {ReaderJobTitleType} from "@app/Devices/components/ReaderModal/utils/resolveSubSection";
import {ReaderItemResult} from "@app/Devices/model/ReaderItemResult";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {useDateFormatter} from "@common/hooks/useDateFormatter";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './readerJobItemInfo.scss';

export type ReaderJobItemInfoProps = {
    item: ReaderItemResult;
    job: ReaderJob;
}

export const ReaderJobItemInfo: FC<ReaderJobItemInfoProps> = ({item, job}) => {
    const {t} = useTranslation();
    const dateFormatter = useDateFormatter();

    const firstItemResult = item.results && item.results.length > 0 ? item.results[0] : null;
    const resultTitle = firstItemResult
        ? jobResultTitle(firstItemResult, job, ReaderJobTitleType.PARCEL, dateFormatter, t)
        : null;

    return <GridTable className="reader-job-item-info">
        <thead>
            <tr>
                <th>{t('parcel:property.number.helper')}</th>
                <th>{t('parcel:property.direction')}</th>
                <th>{t('parcel:property.packetCount.titleLong')}</th>
                <th>{t('parcel:property.status')}</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>{dashWhenEmpty(item.parcelData && getVariable(item.parcelData, 'parcelBarcode'))}</td>
                <td>{dashWhenEmpty(firstItemResult && getVariable(firstItemResult.variables, 'direction'))}</td>
                <td>{dashWhenEmpty(firstItemResult && getVariable(firstItemResult.variables, 'packetCount'))}</td>
                <td>
                    {dashWhenEmpty(firstItemResult && <span
                        className={classNames('reader-job-results__result', `reader-job-results__result-${firstItemResult.type}`)}
                    >{resultTitle}</span>)}
                </td>
            </tr>
        </tbody>
    </GridTable>
}
