import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    ParcelBranchQualityDetailItem
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/ParcelBranchQualityDetail";
import {
    ProcessPenaltyDetailGridHeader
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/components/ProcessPenaltyDetailGridHeader/ProcessPenaltyDetailGridHeader";
import {
    useProcessPenaltyDetailGridSorter
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/hooks/useProcessPenaltyDetailGridSorter";
import {ParcelDetailRoute} from "@app/Parcel/ParcelRoutes";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {AppLink} from "@common/components/routing/AppLink/AppLink";

export type ParcelProcessPenaltyDetailGridProps = {
    items: ParcelBranchQualityDetailItem[];
}

export const ParcelProcessPenaltyDetailGrid: FC<ParcelProcessPenaltyDetailGridProps> = ({items}) => {
    const {t} = useTranslation();

    const sorter = useProcessPenaltyDetailGridSorter();
    items.sort(sorter.sort);

    return <GridTable>
        <ProcessPenaltyDetailGridHeader sorter={sorter}>
            <th>{t('branchQuality:processPenalty.detail.parcel')}</th>
            <th>{t('branchQuality:processPenalty.detail.missingPackets')}</th>
        </ProcessPenaltyDetailGridHeader>
        <tbody>
        {items.map((item) => (<tr key={item.date}>
                <td><DateFormatter date={item.date}/></td>
                <td><strong>{item.penaltyPoints}</strong></td>
                <td>
                    <AppLink
                        to={{route: ParcelDetailRoute, params: {parcelId: item.parcelId}}}
                        title={t('parcel:action.goToDetail')}
                    >
                        {item.parcelBarcode}
                    </AppLink>
                </td>
                <td>{item.missingPackets}</td>
            </tr>))}
        </tbody>
    </GridTable>
}
