export enum SearchModifier {
    EQ = 'eq',
    NEQ = 'neq',
    LT = 'lt',
    GT = 'gt',
    LTE = 'lte',
    GTE = 'gte',
}

export type SearchRequestProperty<ValueType, AllowedModifiers extends SearchModifier = SearchModifier> = {
    value: ValueType;
    modifier: AllowedModifiers;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const isSearchRequestProperty = (value: any): value is SearchRequestProperty<any> => {
    return typeof value === 'object' && 'value' in value && 'modifier' in value;
}

export const isSearchRequestArrayProperty = (value: any): value is Array<SearchRequestProperty<any>> => {
   return value instanceof Array && value.length > 0 && isSearchRequestProperty(value[0]);
}
