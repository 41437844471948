import {FC, Fragment, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {signTransaction} from "@app/CardPayment/api/cardTerminalApi";
import {CancelPayment} from "@app/CardPayment/hooks/useCancelPayment";
import {usePrintCardReceipt} from "@app/CardPayment/hooks/usePrintCardReceipt";
import {useTransactionCallback} from "@app/CardPayment/hooks/useTransactionCallback";
import {useTransactionStatusEffect} from "@app/CardPayment/hooks/useTransactionStatusEffect";
import {CardPaymentState} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";
import {PrintCardReceiptRequestType} from "@app/CardPayment/model/CardTerminalRequestsAndResponses";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {ReprintButton} from "@common/components/ReprintButton/ReprintButton";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {PrintResult} from "@common/model/PrintResult";

import './signatureVerificationModal.scss';

export type SignatureVerificationModalProps = {
    cardPaymentState: CardPaymentState;
    onSignatureConfirmed: () => void;
    onSignatureRejected: CancelPayment;
}

export const SignatureVerificationModal: FC<SignatureVerificationModalProps> = ({
    cardPaymentState,
    onSignatureConfirmed,
    onSignatureRejected,
}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const [receiptPrintedSuccessfully, setReceiptPrintedSuccessfully] = useState<boolean>(false);
    const [receiptPrintResult, setReceiptPrintResult] = useState<PrintResult|null>(null);

    const toastContainerId = 'signatureVerificationModal';

    const showErrorToast = useShowErrorToast(modalToast, 'viewPacketPrint:error', 'global:print.error.general', {containerId: toastContainerId});
    const print = usePrintCardReceipt(true, {containerId: toastContainerId});

    const printVerificationReceipt = useTransactionCallback(cardPaymentState, (transaction) => {
        print({
            transactionId: transaction.id,
            receiptType: PrintCardReceiptRequestType.MERCHANT_RECEIPT,
            forcePrint: true,
        })
            .then((response) => {
                setReceiptPrintedSuccessfully(true);

                if (response.receipts.merchantReceipt && response.receipts.merchantReceipt.prints && response.receipts.merchantReceipt.prints.length > 0) {
                    setReceiptPrintResult(response.receipts.merchantReceipt.prints[0]);
                }
            })
            .catch((error: Error) => {
                showErrorToast(error);
            });
    }, [print, showErrorToast, t]);

    const confirmSignature = useTransactionCallback(cardPaymentState, (transaction) => {
        signTransaction(transaction.id, appContext.api)
            .then(() => {
                onSignatureConfirmed();
            })
            .catch((error: Error) => {
                modalToast.error(t(error.message), {containerId: toastContainerId});
            });
    }, [appContext.api, onSignatureConfirmed, t]);

    useTransactionStatusEffect(cardPaymentState, (transactionStatus) => {
        if (transactionStatus === CardPaymentTransactionStatus.AWAITING_SIGNATURE_VERIFICATION) {
            printVerificationReceipt();
            setModalOpened(true);
        } else {
            setModalOpened(false);
        }
    });

    return <BaseModal
        show={modalOpened}
        onHide={onSignatureRejected}
        className="signature-verification-modal"
        disableOutsideClose={true}
        closeButton={false}
        size="lg"
        header={t('cardPayment:signatureVerificationRequired')}
        footer={<Fragment>
            <Button variant="secondary" onClick={onSignatureRejected}>
                {t('cardPayment:action.cancel')}
            </Button>
            {!receiptPrintResult && <Button onClick={printVerificationReceipt} variant="secondary">
                {t('cardPayment:action.reprintForSigning')}
            </Button>}
            {receiptPrintResult && <ReprintButton printResult={receiptPrintResult} toastInModal={true} variant="secondary">
                {t('cardPayment:action.reprintForSigning')}
            </ReprintButton>}
        </Fragment>}
        toastContainerId={toastContainerId}
    >
        <div data-xid="card-payment-sign-required">
            <p>{t('cardPayment:signatureVerificationRequiredHelper')}</p>
            <div className="signature-verification-modal-buttons">
                <Button onClick={onSignatureRejected} disabled={!receiptPrintedSuccessfully} variant="danger"  data-xid="signature-does-not-match">
                    {t('cardPayment:action.rejectSignatureValidity')}
                </Button>
                <Button onClick={confirmSignature} disabled={!receiptPrintedSuccessfully} variant="success" data-xid="signature-matches">
                    {t('cardPayment:action.confirmSignatureValidity')}
                </Button>
            </div>
        </div>
    </BaseModal>
}
