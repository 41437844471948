import {FC} from "react";
import {useTranslation} from "react-i18next";

import {OutgoingParcelListManager} from "@app/Parcel/pages/BranchParcelListPage/OutgoingParcelListManager";
import {PaginatedGridSorter} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/PaginatedGridSorter";
import {
    usePaginatedGridSorter
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/usePaginatedGridSorter";

export const OUTGOING_PARCEL_GRID_SORTING_STORAGE_KEY = 'ui-outgoingParcelGrid-sorting';

export type OutgoingParcelGridHeaderProps = {
    manager: OutgoingParcelListManager;
}

export const OutgoingParcelGridHeader: FC<OutgoingParcelGridHeaderProps> = ({manager}) => {
    const {t} = useTranslation();

    const setStoredSorting = usePaginatedGridSorter(
        manager,
        OUTGOING_PARCEL_GRID_SORTING_STORAGE_KEY,
        {key: 'modifiedAt', desc: true},
    );

    if (!manager.hasItems) {
        return null;
    }

    return <thead>
    <tr>
        <th>
            <PaginatedGridSorter manager={manager} onSort={setStoredSorting} sortBy="barcode">
                {t('parcel:property.number.label')}
            </PaginatedGridSorter>
            <span className="branch-parcel-list__parcel-id-count-status">
                    {' / '}{t('parcel:property.packetCountShort')}
                {' / '}{t('parcel:property.status')}
                </span>
        </th>
        <th className="branch-parcel-list__status">
                <span className="branch-parcel-list__status-count">
                    {t('parcel:property.packetCountShort')}{' / '}
                </span>
            {t('parcel:property.status')}
        </th>
        <th className="branch-parcel-list__date">
            <PaginatedGridSorter manager={manager} onSort={setStoredSorting} sortBy="createdAt">
                {t('parcel:property.createdAt')}
            </PaginatedGridSorter>
        </th>
        <th className="branch-parcel-list__date">
            <PaginatedGridSorter manager={manager} onSort={setStoredSorting} sortBy="modifiedAt">
                {t('parcel:property.modifiedAt')}
            </PaginatedGridSorter>
        </th>
        <th className="branch-parcel-list__count">{t('parcel:property.packetCountShort')}</th>
        <th/>
    </tr>
    </thead>

}
