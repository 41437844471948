import {observer} from "mobx-react-lite";
import {Fragment, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {ManualPostLink} from "@app/BranchEvent/components/ManualPostLink/ManualPostLink";
import {
    PacketsNotReceivedYesterdayBranchEventModal
} from "@app/BranchEvent/components/PacketsNotReceivedYesterdayEvent/PacketsNotReceivedYesterdayBranchEventModal";
import {
    PacketsNotReceivedYesterdayBranchEvent
} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventComponent} from "@app/BranchEvent/model/BranchEventComponent";
import {Button} from "@common/components/Button/Button";

export const PacketsNotReceivedYesterdayBranchEventContent: BranchEventComponent<PacketsNotReceivedYesterdayBranchEvent> = observer(({event}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [modalOpened, setModalOpened] = useState(false);

    const branchId = appContext.user.branchId;
    if (!branchId) {
        return null;
    }

    return <Fragment>
        <Button onClick={() => setModalOpened(true)} variant="link">
            {t('branchEvent:packetsNotReceivedYesterday')}
        </Button>
        <ManualPostLink postIdTranslationKey="support:whatToDoArticle.packetsNotReceivedYesterday">
            {t('branchEvent:whatToDo')}
        </ManualPostLink>
        {modalOpened && <PacketsNotReceivedYesterdayBranchEventModal
            event={event}
            onClose={() => setModalOpened(false)}
        />}
    </Fragment>
})
