import {observer} from "mobx-react-lite";
import {FC} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    HolidayBranchHoursConfirmationRequiredBranchEventContent
} from "@app/BranchEvent/components/HolidayBranchHoursConfirmationRequiredBranchEvent/HolidayBranchHoursConfirmationRequiredBranchEventContent";
import {HolidayBranchHoursConfirmationRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";
import {Alert} from "@common/components/Alert/Alert";

import './publicHolidayConfirmBanner.scss';

export const PublicHolidayConfirmBanner: FC = observer(() => {
    const appContext = useAppContext();

    const publicHolidayEvent = appContext.branchEvents.events.find(
        (branchEvent): branchEvent is HolidayBranchHoursConfirmationRequiredBranchEvent => {
        return branchEvent.code === BranchEventCode.HOLIDAY_BRANCH_HOURS_CONFIRMATION_REQUIRED;
    });

    if (!publicHolidayEvent) {
        return null;
    }

    return <div className="public-holiday-confirm-banner">
        <Alert type="info" alertId="public-holiday-confirm-banner">
            <HolidayBranchHoursConfirmationRequiredBranchEventContent event={publicHolidayEvent} />
        </Alert>
    </div>
});
