import {FC} from "react";
import {Route, Routes} from "react-router-dom";

import {
    InventoryDetailRoute,
    BranchInventoryListRoute,
    ManagedInventoryRoute
} from "@app/Inventory/InventoryRoutes";
import {BranchInventoryPage} from "@app/Inventory/pages/BranchInventoryPage/BranchInventoryPage";
import {InventoryDetailPage} from "@app/Inventory/pages/InventoryDetailPage/InventoryDetailPage";
import {ManagedInventoryPage} from "@app/Inventory/pages/ManagedInventoryPage/ManagedInventoryPage";
import {RouteParamsProvider} from "@common/components/routing/RouteParamsProvider/RouteParamsProvider";

export const Inventory: FC = () => {
    return <Routes>
        <Route path={ManagedInventoryRoute.path} element={<ManagedInventoryPage />} />
        <Route path={BranchInventoryListRoute.path} element={<RouteParamsProvider
                path={BranchInventoryListRoute}
                render={({branchId}) => (<BranchInventoryPage branchId={branchId} />)}
            />}
        />
        <Route path={InventoryDetailRoute.path} element={<InventoryDetailPage />} />
    </Routes>
}
