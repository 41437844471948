import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {TransactionGrid} from "@app/Transaction/components/TransactionGrid/TransactionGrid";
import {
    TransactionListType
} from "@app/Transaction/components/TransactionGrid/TransactionListManager";
import {
    BranchCardTransactionRoute,
    ManagedCardTransactionRoute
} from "@app/Transaction/TransactionModuleRoutes";
import {BranchIdChecker} from "@common/components/BranchIdChecker/BranchIdChecker";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";

export type BranchCardTransactionPageProps = {
    branchId: string;
}

export const BranchCardTransactionPage: FC<BranchCardTransactionPageProps> = observer(({branchId}) => {
    const {t} = useTranslation();
    const {dashboardInfo} = useAppContext();

    return <BranchIdChecker branchId={branchId} detailRoute={BranchCardTransactionRoute} fallbackRoute={DashboardRoute}>
        <PageContent className="branch-card-transaction-page">
            {dashboardInfo.hasManagedBranches && <ReturnLink
                label={t('viewTransaction:managedCardTransaction.back')}
                to={ManagedCardTransactionRoute}
            />}
            <PageHeader branchId={branchId}>{t('viewTransaction:branchCardTransaction.header')}</PageHeader>
            <TransactionGrid branchId={branchId} listType={TransactionListType.CARD} />
        </PageContent>
    </BranchIdChecker>
})
