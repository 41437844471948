/// <reference types="user-agent-data-types" />
import {format} from "date-fns";
import platform from "platform";

import {AuthOptionsTech} from "@app/AppContext/classes/User/model/AuthOptionsTech";

export const getAuthOptionsTech = async (): Promise<AuthOptionsTech> => {
    const tech: AuthOptionsTech = {
        clientName: platform.name,
        clientVersion: platform.version,
        resolutionWidth: (window.screen.width * window.devicePixelRatio).toString(10),
        resolutionHeight: (window.screen.height * window.devicePixelRatio).toString(10),
        timeZone: parseInt(format(new Date(), 'xxx'), 10),
    };

    const operatingSystem = platform.os;
    if (operatingSystem) {
        const isWindows = operatingSystem.family && operatingSystem.family.startsWith('Windows');
        tech.osType =  isWindows ? 'Windows' : operatingSystem.family;
        tech.osVersion = isWindows && await resolveWindowsVersion() || operatingSystem.version;
        tech.osPlatform = operatingSystem.architecture === 32 ? 'x86' : 'x64';
    }

    return tech;
}

const resolveWindowsVersion = async (): Promise<string|undefined> => {
    try {
        const userAgentData = window.navigator.userAgentData;
        if (!userAgentData) {
            return;
        }

        const uaDataValues = await userAgentData.getHighEntropyValues(["platformVersion"]);
        if (uaDataValues.platform !== "Windows" || !uaDataValues.platformVersion) {
            return;
        }

        const majorPlatformVersion = parseInt(uaDataValues.platformVersion.split('.')[0], 10);
        if (majorPlatformVersion >= 13) {
            return '11';
        } else if (majorPlatformVersion > 0) {
            return '10';
        }
    } catch (error: unknown) {
        return;
    }
}
