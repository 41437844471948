import {observer} from "mobx-react-lite";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    ToDispatchPacketsGrid
} from "@app/BranchEvent/components/PacketExpeditionRequiredBranchEvent/ToDispatchPacketsGrid/ToDispatchPacketsGrid";
import {PacketExpeditionRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {ModalBranchEventComponent} from "@app/BranchEvent/model/ModalBranchEventComponent";
import {Button} from "@common/components/Button/Button";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

export const PacketExpeditionRequiredBranchEventModal: ModalBranchEventComponent<PacketExpeditionRequiredBranchEvent> = observer(({
    onClose,
}) => {
    const {t} = useTranslation();
    const {user} = useAppContext();

    const branchId = user.branchId;
    if (!branchId) {
        return null;
    }

    return <BaseModal
        show={true}
        onHide={onClose}
        header={t('viewPacketToDispatchPopup:title')}
        footerText={t('viewPacketToDispatchPopup:warningInfo')}
        footer={<Button onClick={onClose} variant="primary">{t('viewPacketToDispatchPopup:action.cancel')}</Button>}
        height="extended"
    >
        <ToDispatchPacketsGrid branchId={branchId} />
    </BaseModal>
})
