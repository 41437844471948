import {CardPaymentState, CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";

export const cardPaymentStateForCancelling = (transactionId: string): CardPaymentState => {
    return {
        status: CardPaymentStatus.PAYING,
        transaction: {
            status: CardPaymentTransactionStatus.SUCCESS,
            id: transactionId,
        }
    }
}
