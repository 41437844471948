import {TFunction} from "i18next";
import {Children, Fragment} from "react";

import {FCWithChildren} from "@common/model/FCWithChildren";
import {renderComponentToString} from "@common/utils/renderComponentToString";

export type TranslationWithHtmlParamProps = {
    asSpan?: boolean;
    className?: string;
    paramName: string|string[];
    translationKey: string;
    t: TFunction;
}

export const TranslationWithHtmlParam: FCWithChildren<TranslationWithHtmlParamProps> = ({asSpan, children, className, paramName, t, translationKey}) => {
    const translationParameters: Record<string, string> = {};

    if (typeof paramName === 'string') {
        translationParameters[paramName] = renderComponentToString(<Fragment>{children}</Fragment>);
    } else {
        const childrenArray = Children.toArray(children);
        paramName.forEach((name, index) => {
            translationParameters[name] = renderComponentToString(<Fragment>{childrenArray[index] || null}</Fragment>);
        });
    }

    const translatedText = t(translationKey, translationParameters);

    if (asSpan) {
        return <span dangerouslySetInnerHTML={{__html: translatedText}} className={className} />
    }

    return <div dangerouslySetInnerHTML={{__html: translatedText}} className={className} />
}
