import {DependencyList, useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";

export const useOnLanguageChanged = (onLanguageChanged: () => void, dependencies: DependencyList): void => {
    const {i18n} = useTranslation();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const onLanguageChangedCallback = useCallback(onLanguageChanged, dependencies);

    useEffect(() => {
        i18n.on('languageChanged', onLanguageChangedCallback);

        return () => {
            i18n.off('languageChanged', onLanguageChangedCallback);
        }
    }, [i18n, onLanguageChangedCallback]);

}
