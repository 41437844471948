import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {receiveClaimFromCustomer} from "@app/Packet/api/packetApi";
import {PacketReceiptPrint} from "@app/Packet/components/PacketReceiptPrint/PacketReceiptPrint";
import {PasswordHandlingComponent} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {
    ReprintablePasswordModal,
    ReprintablePasswordModalState
} from "@app/Packet/pages/DashboardPage/SearchForm/ReprintablePasswordModal/ReprintablePasswordModal";
import {SearchFormResult} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";
import {Img} from "@common/components/Img/Img";
import {PrintResult} from "@common/model/PrintResult";

import './claimFromCustomerPasswordModal.scss';

export const ClaimFromCustomerPasswordModal: PasswordHandlingComponent = ({onError, onFinish, onVerified, password}) => {
    const {t} = useTranslation();

    const [isSubmitting, onSubmitting] = useState<boolean>(false);
    const [consignmentReceiptPrint, setConsignmentReceiptPrint] = useState<PrintResult|undefined>(undefined);

    const printConfirmation = useCallback((state: ReprintablePasswordModalState) => {
        if (!state.barcode || state.isAtDestination || consignmentReceiptPrint) {
            return null;
        }

        return <div className="claim-from-customer-password-modal__prints" >
            <div>
                <p>
                    <PacketReceiptPrint
                        barcode={state.barcode}
                        printResult={consignmentReceiptPrint}
                        onPrinted={setConsignmentReceiptPrint}
                        onSubmitting={onSubmitting}
                    />
                </p>
                <Img src="/images/receipt-icon.jpg" alt={t('viewSurpriseCode:receipt.name')} />
                <p className="claim-from-customer-password-modal__print-hint">{t('viewSurpriseCode:receipt.hint')}</p>
            </div>
        </div>
    }, [consignmentReceiptPrint, t]);

    return <ReprintablePasswordModal
        apiMethod={receiveClaimFromCustomer}
        password={password}
        translationDomain="viewClaimFromCustomer"
        onFinish={() => onFinish(SearchFormResult.RETURN_PACKET)}
        onError={onError}
        onVerified={onVerified}
        renderChildren={printConfirmation}
        loading={isSubmitting}
        className="claim-from-customer-password-modal"
    />
}
