import {FC, MutableRefObject} from "react";
import {useTranslation} from "react-i18next";

import {BaseUser} from "@app/AppContext/classes/User/model/User";
import {InventoryDetail} from "@app/Inventory/model/InventoryDetail";
import {InventoryStatusId} from "@app/Inventory/model/InventoryStatus";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {DetailInfoBox, DetailInfoItem} from "@common/components/DetailInfoBox/DetailInfoBox";
import {Img} from "@common/components/Img/Img";

export type InventoryDetailInfoProps = {
    detail: InventoryDetail;
    usersMap: MutableRefObject<Map<string, BaseUser>>;
}

export const InventoryDetailInfo: FC<InventoryDetailInfoProps> = ({detail, usersMap}) => {
    const {t} = useTranslation();

    const info: DetailInfoItem[] = [{
        label: t('inventory:property.createdAt'),
        content: <DateFormatter date={detail.createdAt} showTime={true} />,
    }];

    const createdBy = usersMap.current.get(detail.createdBy);
    if (createdBy) {
        info.push({label: t('inventory:property.createdBy'), content: createdBy.name});
    }

    info.push({label: t('inventory:property.branchName'), content: detail.branchName});
    info.push({
        label: t('inventory:property.packetCount.title'),
        content: <div>
            <span dangerouslySetInnerHTML={{__html: t<string>('inventory:property.packetCount.loaded', {readerLoadedCount: detail.readerLoadedCount})}} />{' '}
            <span dangerouslySetInnerHTML={{__html: t<string>('inventory:property.packetCount.total', {shouldBeAtBranchCount: detail.shouldBeAtBranchCount})}} />
            {detail.shouldBeInParcelsCount === 0 ? '. ' : ', '}
            {detail.shouldBeInParcelsCount > 0 && <span
                dangerouslySetInnerHTML={{__html: t<string>('inventory:property.packetCount.inParcel', {shouldBeInParcelsCount: detail.shouldBeInParcelsCount})}}
            />}
            {detail.missingPacketsCount > 0 && <span className="packet-count-missing">
                {' '}{t('inventory:property.packetCount.missing', {missingPacketsCount: detail.missingPacketsCount})}{' '}
                <Img
                    src="/images/icons/ico/ico-exclamation-red.svg"
                    alt={t('inventory:property.packetCount.missing', {missingPacketsCount: detail.missingPacketsCount})}
                />
            </span>}
        </div>
    });

    const modifiedBy = detail.modifiedBy ? usersMap.current.get(detail.modifiedBy) : null;
    if (modifiedBy) {
        info.push({label: t('inventory:property.modifiedBy'), content: modifiedBy.name});
    }

    const solvedBy = detail.solvedBy ? usersMap.current.get(detail.solvedBy) : null;
    if (solvedBy) {
        info.push({label: t('inventory:property.solvedBy'), content: solvedBy.name});
    }

    if (detail.hasBarcodeRepetitions) {
        info.push({
            label: t('inventory:property.note'),
            content: <div>
                <Img src="/images/icons/ico/ico-exclamation-red.svg" alt={t('inventory:text.hasBarcodeRepetitions')} />
                <span>{' '}{t('inventory:text.hasBarcodeRepetitions')}</span>
            </div>
        });
    }

    if (detail.status.id === InventoryStatusId.CANCELLED) {
        info.push({
            label: t('inventory:property.status'),
            content: <strong>{t('inventory:text.canceled')}</strong>,
        });
    }

    return <DetailInfoBox info={info} />
}
