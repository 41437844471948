import {ApiError} from "@common/model/errors/ApiError";
import {ApiErrorItem} from "@common/model/errors/ApiErrorItem";
import {getFirstErrorItem} from "@common/utils/api/getFirstErrorItem";

export const getApiErrorCode = <ItemType extends ApiErrorItem = ApiErrorItem>(error: Error): string|null => {
    if (error instanceof ApiError) {
        const firstErrorItem = getFirstErrorItem(error as ApiError<ItemType>);
        if (firstErrorItem) {
            return firstErrorItem.code;
        }
    }

    return null;
}
