import {
    CashRegisterBranchQualityDetail, cashRegisterBranchQualityDetailTypes
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/CashRegisterBranchQualityDetail";
import {
    GeneralBranchQualityDetail, generalBranchQualityDetailTypes
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/GeneralBranchQualityDetail";
import {
    PacketsBranchQualityDetail, packetsBranchQualityDetailTypes
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/PacketsBranchQualityDetail";
import {
    ParcelBranchQualityDetail, parcelBranchQualityDetailTypes
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/ParcelBranchQualityDetail";

export type BranchQualityDetail =
    GeneralBranchQualityDetail | CashRegisterBranchQualityDetail | PacketsBranchQualityDetail | ParcelBranchQualityDetail;

export type BranchQualityDetailType = BranchQualityDetail['type'];

export const isGeneralBranchQualityDetail = (detail: BranchQualityDetail): detail is GeneralBranchQualityDetail => {
    return generalBranchQualityDetailTypes.includes(detail.type);
}

export const isCashRegisterBranchQualityDetail = (detail: BranchQualityDetail): detail is CashRegisterBranchQualityDetail => {
    return cashRegisterBranchQualityDetailTypes.includes(detail.type);
}

export const isPacketsBranchQualityDetail = (detail: BranchQualityDetail): detail is PacketsBranchQualityDetail => {
    return packetsBranchQualityDetailTypes.includes(detail.type);
}

export const isParcelBranchQualityDetail = (detail: BranchQualityDetail): detail is ParcelBranchQualityDetail => {
    return parcelBranchQualityDetailTypes.includes(detail.type);
}
