import {PrintResult} from "@common/model/PrintResult";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";

export type ResponseWithPrintables = GeneralSuccessResponseContent & {
    prints: PrintResult[];
}

export const isResponseWithPrintables = (response: GeneralSuccessResponseContent): response is ResponseWithPrintables => {
    return 'prints' in response;
}
