import {Api} from "@app/AppContext/classes/Api/Api";
import {
    cashRegisterTransactionListEndpoint,
    cashRegisterTransactionTypeListEndpoint,
    confirmBalanceEndpoint,
    confirmTransferEndpoint,
    getBalanceEndpoint,
    getTransferEndpoint,
    managedCashRegisterListEndpoint,
    manualDepositEndpoint
} from "@app/Transaction/api/cashRegisterEndpoints";
import {
    CashRegisterBalanceRequest,
    CashRegisterBalanceResponse,
    CashRegisterTransferResponse,
    ConfirmBalanceRequest,
    ConfirmTransferRequest,
    ManagedCashRegisterResponse,
    ManualDepositRequest,
    TransactionListRequest,
    TransactionListResponse,
    TransactionTypeListResponse
} from "@app/Transaction/model/TransactionsRequestsAndResponses";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";
import {ResponseWithPrintables} from "@common/model/responses/ResponseWithPrintables";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const cashRegisterBalance = async (request: CashRegisterBalanceRequest, api: Api): Promise<CashRegisterBalanceResponse> => {
    const {branchId, ...cashRegisterBalanceRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(getBalanceEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(cashRegisterBalanceRequest),
    });
}

export const cashRegisterTransactionList = async (request: TransactionListRequest, api: Api): Promise<TransactionListResponse> => {
    const {branchId, ...cashRegisterTransactionListRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(cashRegisterTransactionListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(cashRegisterTransactionListRequest),
    });
}

export const cashRegisterTransactionTypeList = async (api: Api): Promise<TransactionTypeListResponse> => {
    return api.call(cashRegisterTransactionTypeListEndpoint);
}

export const confirmBalance = async (request: ConfirmBalanceRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: confirmBalanceEndpoint,
        payload: request,
    });
}

export const getTransfer = async (password: string, api: Api): Promise<CashRegisterTransferResponse> => {
    return api.call(endpointWithRouteParams(getTransferEndpoint, {password}));
}

export const confirmTransfer = async (request: ConfirmTransferRequest, api: Api): Promise<ResponseWithPrintables> => {
    return api.call({
        endpoint: confirmTransferEndpoint,
        payload: request,
    });
}

export const managedCashRegisterList = async (api: Api): Promise<ManagedCashRegisterResponse> => {
    return api.call(managedCashRegisterListEndpoint);
}

export const manualDeposit = async (request: ManualDepositRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: manualDepositEndpoint,
        payload: request,
    })
}

