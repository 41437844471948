import classNames from "classnames";
import {FC, Fragment, ReactElement, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {GridOpeningHours} from "@app/OpeningHours/components/EditableOpeningHoursGrid/GridOpeningHours";
import {
    BlocksEdit
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGridRow/BlocksEdit/BlocksEdit";
import {
    RowHeaderCell
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGridRow/TitleCell/RowHeaderCell";
import {
    ViewCell
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGridRow/ViewCell/ViewCell";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {ResponsiveRender} from "@common/components/ResponsiveRender/ResponsiveRender";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {ucFirst} from "@common/utils/ucFirst";

export type EditableOpeningHoursGridRowProps = {
    collapsibleMobile?: boolean;
    customHeader?: (date: string) => string|ReactElement|null;
    hasError?: boolean;
    highlightExceptions?: boolean;
    openingHours: GridOpeningHours;
    rowIndex: number;
    title?: string;
}

export const EditableOpeningHoursGridRow: FC<EditableOpeningHoursGridRowProps> = ({
    collapsibleMobile = true,
    customHeader,
    hasError = false,
    highlightExceptions = false,
    openingHours,
    rowIndex,
    title
}) => {
    const {t} = useTranslation();
    const [editing, setEditing] = useState<boolean>(!collapsibleMobile);
    const dateFormatter = useDateFormatter();

    useEffect(() => {
        if (hasError) {
            setEditing(true);
        }
    }, [hasError]);

    const header = customHeader ? customHeader(openingHours.date) : <DateFormatter date={openingHours.date} />;
    const subHeader = !customHeader ? ucFirst(dateFormatter(openingHours.date, DateFormatType.DAY_OF_WEEK)) : null;

    return <Fragment>
        <tr
            className={classNames('opening-hours-grid-row', 'opening-hours-grid-row--editable', {
                'opening-hours-grid-row--with-error': hasError,
                'opening-hours-grid-row--holiday': openingHours.isPublicHoliday
            })}
            title={title}
            data-xid={`opening-hours-grid-row-${rowIndex}`}
        >
            <ResponsiveRender up="lg">
                <RowHeaderCell header={header} subHeader={subHeader} />
            </ResponsiveRender>
            <ResponsiveRender down="lg">
                <RowHeaderCell
                    hasError={hasError}
                    header={header}
                    subHeader={subHeader}
                    colSpan={editing ? 2 : undefined}
                    title={title}
                >
                    {collapsibleMobile && editing && <div className="row-header-cell-ok-button">
                        <Button onClick={() => setEditing(false)} variant="success">{t('openingHours:grid.ok')}</Button>
                    </div>}
                </RowHeaderCell>
            </ResponsiveRender>
            {!editing && <ViewCell blocks={openingHours.hours}>
                {!editing && <div className="mobile-edit-cell-edit-button">
                    <Button onClick={() => setEditing(true)} variant="secondary">{t('openingHours:grid.edit')}</Button>
                </div>}
            </ViewCell>}
            <ResponsiveRender up="lg">
                <BlocksEdit openingHours={openingHours} rowIndex={rowIndex} highlightExceptions={highlightExceptions} />
            </ResponsiveRender>
        </tr>
        <ResponsiveRender down="lg">
            <BlocksEdit editing={editing} mobile={true} openingHours={openingHours} rowIndex={rowIndex} highlightExceptions={highlightExceptions} />
        </ResponsiveRender>
    </Fragment>
}
