import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Img} from "@common/components/Img/Img";

export type DevicesTestModalDeviceProps = {
    available: boolean;
    device: 'reader'|'printer',
}

export const DevicesTestModalDevice: FC<DevicesTestModalDeviceProps> = ({available, device}) => {
    const {t} = useTranslation();

    const label = t(`viewDevicesTestPopup:${!available ? 'dis' : ''}connected`);

    return <div className="devices-test-modal__device">
        <h4>{t(`viewDevicesTestPopup:device.${device}`)}</h4>
        <div>
            {label}{' '}
            <Img src={`/images/icons/ico/ico-${available ? 'check' : 'exclamation-red'}.svg`} alt={label} />
        </div>
    </div>
}
