import {PrintResultType} from "@common/model/PrintResult";

export enum PrintableTypes {
    DEPOT_LABEL = PrintResultType.DEPOT_LABEL,
    DEPOT_RETURN_LABEL = PrintResultType.DEPOT_RETURN_LABEL,
    DELIVERY_RECEIPT = PrintResultType.DELIVERY_RECEIPT,
    CONSIGNMENT_RECEIPT = PrintResultType.CONSIGNMENT_RECEIPT,
    COD_RECEIPT = PrintResultType.COD_RECEIPT,
    SHELF = PrintResultType.SHELF,
}

export type PrintReturnPacketListRequest = {
    password: string;
    barcodes: string[];
}
export type PrintPacketLabelsRequest = {
    barcodes: string[];
    type: PrintableTypes;
    reason?: string;
}
export type PrintShelfBarcodesRequest = {
    prefix: string;
    startNumber: number;
    endNumber: number;
}
export type ReprintRequest = {
    reprintId: string;
}
export type PrintInventoryBadPacketsRequest = {
    inventoryId: string;
}

export type ParcelPrintRequest = {
    parcelId: string;
}
