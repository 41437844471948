import {add} from "date-fns";
import {action, computed, makeObservable, observable, toJS} from "mobx";

import {ready} from "@app/App/api/probeApi";
import {Api} from "@app/AppContext/classes/Api/Api";
import {readLocalStorage, writeLocalStorage} from "@common/utils/localStorage";

const LAST_PROBE_CHECK_CACHE_KEY = 'apiHealth-lastProbeCheck';
const RECOVERY_CHECK_INTERVAL = 1000; // check the recovery status every second
const FAILED_PROBE_CHECK_INTERVAL = 60; // call the probe API every 60 seconds when dead
const PROBE_RECHECK_INTERVAL = 10; // call the probe API every 10 seconds when alive
const PROBE_RECHECK_COUNT = 3; // probe needs to succeed 3 times to redirect back

type RecoveryCheck = {
    date: Date;
    successes: number;
}

export class RecoveryChecker {
    public lastCheck: RecoveryCheck|null;

    private nextCheckTimeout: number|null = null;

    public constructor(private api: Api) {
        this.lastCheck = readLocalStorage<RecoveryCheck>(LAST_PROBE_CHECK_CACHE_KEY);

        makeObservable(this, {
            lastCheck: observable,
            recovered: computed,
            updateLastCheck: action,
        });

        void this.check();
    }

    public get recovered(): boolean {
        const recoverySuccessful = this.lastCheck !== null && this.lastCheck.successes >= PROBE_RECHECK_COUNT;
        if (recoverySuccessful) {
            if (this.nextCheckTimeout !== null) {
                window.clearTimeout(this.nextCheckTimeout);
            }

            writeLocalStorage(LAST_PROBE_CHECK_CACHE_KEY, null);
        }

        return recoverySuccessful;
    }

    public check = async () => {
        if (this.nextCheckTimeout !== null) {
            window.clearTimeout(this.nextCheckTimeout);
        }

        if (this.shouldCheck()) {
            try {
                if (await ready(this.api)) {
                    this.updateLastCheck({
                        successes: this.lastCheck ? this.lastCheck.successes + 1 : 1,
                        date: new Date(),
                    });
                } else {
                    this.updateLastCheck({
                        successes: 0,
                        date: new Date(),
                    });
                }

            } catch (error: unknown) {
                this.updateLastCheck({
                    successes: 0,
                    date: new Date(),
                });
            }

            if (!this.recovered) {
                writeLocalStorage(LAST_PROBE_CHECK_CACHE_KEY, toJS(this.lastCheck));
            }
        }

        this.nextCheckTimeout = window.setTimeout(this.check, RECOVERY_CHECK_INTERVAL);
    }

    public updateLastCheck = (lastCheck: RecoveryCheck): void => {
        this.lastCheck = lastCheck;
    }

    private shouldCheck = (): boolean => {
        if (!this.lastCheck) {
            return true;
        }

        if (this.recovered) {
            return false;
        }

        return add(
            this.lastCheck.date,
            {seconds: this.lastCheck.successes > 0 ? PROBE_RECHECK_INTERVAL : FAILED_PROBE_CHECK_INTERVAL}
        ) < new Date();
    }
}
