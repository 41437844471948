
import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";
import {HttpStatusCode} from "@common/model/HttpStatusCode";

export const abortTransactionEndpoint: Endpoint<'transactionId'> = {
    method: HttpMethod.POST,
    url: '/card-terminal/:transactionId/abort',
    routeParamNames: ['transactionId'],
}

export const cancellationStrategyEndpoint: Endpoint<'transactionId'> = {
    method: HttpMethod.GET,
    url: '/card-terminal/:transactionId/cancellation-strategy',
    routeParamNames: ['transactionId'],
}

const createTransactionErrorCodes = {
    noCardTerminal: 'noCardTerminal' as const,
    notAllowedToInitiateCardTransaction: 'notAllowedToInitiateCardTransaction' as const,
    packetAlreadyPaid: 'packetAlreadyPaid' as const,
    terminalBusy: {errorCode: 'terminalBusy' as const, statusCode: HttpStatusCode.CONFLICT},
    packetPendingTransaction: {errorCode: 'packetPendingTransaction' as const, statusCode: HttpStatusCode.CONFLICT},
}

export const createTransactionEndpoint: Endpoint<string, keyof typeof createTransactionErrorCodes> = {
    method: HttpMethod.POST,
    url: '/card-terminal',
    knownErrorCodes: createTransactionErrorCodes,
}

const printCardReceiptErrorCodes = {
    webPaymentReceipt: 'webPaymentReceipt' as const,
};

export const printCardReceiptEndpoint: Endpoint<'transactionId', keyof typeof printCardReceiptErrorCodes> = {
    method: HttpMethod.POST,
    url: '/card-terminal/:transactionId/receipt',
    routeParamNames: ['transactionId'],
    knownErrorCodes: printCardReceiptErrorCodes,
    notFoundErrorResponse: true,
}

export const refundTransactionEndpoint: Endpoint<'transactionId'> = {
    method: HttpMethod.POST,
    url: '/card-terminal/:transactionId/refund',
    routeParamNames: ['transactionId'],
}

export const signTransactionEndpoint: Endpoint<'transactionId'> = {
    method: HttpMethod.POST,
    url: '/card-terminal/:transactionId/sign',
    routeParamNames: ['transactionId'],
}

export const transactionDetailEndpoint: Endpoint<'transactionId'> = {
    method: HttpMethod.GET,
    url: '/card-terminal/:transactionId/detail',
    routeParamNames: ['transactionId'],
    ignoreFailures: true,
}

export const revertTransactionEndpoint: Endpoint<'transactionId'> = {
    method: HttpMethod.POST,
    url: '/card-terminal/:transactionId/reversal',
    routeParamNames: ['transactionId'],
}

export const transactionIdEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/card-terminal/id',
}
