import {observer} from "mobx-react-lite";
import {FC, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    InvoicedAmountCell
} from "@app/Commission/pages/BranchCommissionPage/MonthlyCommissionGrid/InvoicedAmountCell/InvoicedAmountCell";
import {
    MonthlyCommissionListManager
} from "@app/Commission/pages/BranchCommissionPage/MonthlyCommissionGrid/MonthlyCommissionListManager";
import {Button} from "@common/components/Button/Button";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {SortingCaret} from "@common/components/grids/SortingCaret/SortingCaret";
import {Img} from "@common/components/Img/Img";
import {Loading} from "@common/components/Loading/Loading";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";

import './monthlyCommissionGrid.scss';

export type MonthlyCommissionGridProps = {
    branchId: string;
}

export const MonthlyCommissionGrid: FC<MonthlyCommissionGridProps> = observer(({branchId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const monthlyCommissionList = useMemo<MonthlyCommissionListManager>(
        () => new MonthlyCommissionListManager(branchId, appContext.api),
        [appContext.api, branchId]
    );

    return <div className="monthly-commission-grid">
        <h4>{t('viewCommission:monthlyCommissionGrid.header')}</h4>
        <Loading active={monthlyCommissionList.loading}>
            {monthlyCommissionList.loaded && monthlyCommissionList.items.length === 0 && <p className="packets-list--empty">
                {t('global:ui.noItemsFound')}
            </p>}
            {monthlyCommissionList.items.length > 0 && <GridTable>
                <thead>
                <tr>
                    <th className="monthly-commission-grid__year">
                        {t('commission:property.year')} <SortingCaret desc={true} />
                        <div className="monthly-commission-grid__year-month">
                            {t('commission:property.month')} <SortingCaret desc={true}/>
                        </div>
                    </th>
                    <th className="monthly-commission-grid__month">
                        {t('commission:property.month')} <SortingCaret desc={true} />
                    </th>
                    <th className="monthly-commission-grid__amount-packet">{t('commission:property.amountPacket')}</th>
                    <th className="monthly-commission-grid__amount-cod">{t('commission:property.amountCod')}</th>
                    <th className="monthly-commission-grid__amount-others">{t('commission:property.amountOthers')}</th>
                    <th className="monthly-commission-grid__amount-sum">{t('commission:property.amountSum')}</th>
                    <th className="monthly-commission-grid__amount-till-end-of-month">{t('commission:property.amountTillEndOfMonth')}</th>
                    <Can I={Action.CREATE} a={Subject.COMMISSION_INVOICE}>
                        <th className="monthly-commission-grid__invoiced-amount">{t('commission:property.invoicedAmount')}</th>
                    </Can>
                </tr>
                </thead>
                <tbody>
                {monthlyCommissionList.items.map((item) => {
                    return <tr key={`${item.year}-${item.month}`}>
                        <td>
                            {item.year}
                            <div className="monthly-commission-grid__year-month">
                                <strong>{t(`core:monthName.${item.month}`)}</strong>
                            </div>
                        </td>
                        <td className="monthly-commission-grid__month"><strong>{t(`core:monthName.${item.month}`)}</strong></td>
                        <td className="monthly-commission-grid__amount-packet"><MoneyFormatter amount={item.amountPacket} currencyCode={item.currency} /></td>
                        <td className="monthly-commission-grid__amount-cod"><MoneyFormatter amount={item.amountCod} currencyCode={item.currency} /></td>
                        <td className="monthly-commission-grid__amount-others"><MoneyFormatter amount={item.amountOthers} currencyCode={item.currency} /></td>
                        <td className="monthly-commission-grid__amount-sum"><MoneyFormatter amount={item.amountSum} currencyCode={item.currency} /></td>
                        <td className="monthly-commission-grid__amount-till-end-of-month">
                            <strong>
                                <MoneyFormatter amount={item.amountTillEndOfMonth} currencyCode={item.currency} />
                            </strong>
                        </td>
                        <Can I={Action.CREATE} a={Subject.COMMISSION_INVOICE}>
                            <InvoicedAmountCell commissionMonthly={item} />
                        </Can>
                    </tr>
                })}
                </tbody>
            </GridTable>}
        </Loading>
        {monthlyCommissionList.canLoadNextPage && <Button variant="secondary" onClick={() => monthlyCommissionList.loadNextPage()}>
            <Img src="/images/icons/ico/ic_add_24px.svg" alt="add" /> {t('commission:action.showOlderMonths')}
        </Button>}
    </div>
})
