import {ErrorMessage} from '@hookform/error-message';
import classNames from "classnames";
import {DefaultTFuncReturn} from "i18next";
import {
    DetailedHTMLProps,
    Fragment,
    InputHTMLAttributes,
    MutableRefObject,
    PropsWithChildren,
    ReactElement,
} from 'react';
import {FieldErrors, FieldValues, Path, RegisterOptions, UseFormReturn, useFormState} from "react-hook-form";

import './formGroup.scss';

export type FormGroupProps<FormDataType extends FieldValues> = {
    form: UseFormReturn<FormDataType>;
    formId?: string;
    inputId?: string;
    inputOptions?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    inputRef?: MutableRefObject<HTMLInputElement|null>;
    label?: DefaultTFuncReturn|ReactElement|ReactElement[];
    name: Path<FormDataType>;
    registerOptions?: RegisterOptions;
    showErrors?: boolean;
    type?: string;
}

export const FormGroup = <FormDataType extends FieldValues, >({
    children,
    form,
    formId,
    inputId,
    inputOptions,
    inputRef,
    label,
    name,
    registerOptions,
    showErrors = true,
    type = 'text',
}: PropsWithChildren<FormGroupProps<FormDataType>>): ReactElement|null => {
    const formState = useFormState({control: form.control});
    const {ref: registerRef, ...rest} = form.register(name, registerOptions);

    inputId = inputId || (formId ? `${formId}-${name}` : name);

    return <Fragment>
        <div className={classNames('form-group', `form-group--${type}`, {required: registerOptions && registerOptions.required})}>
            <div className="form-group-label">{label && <label htmlFor={inputId}>{label}</label>}</div>
            <div className="form-group-input">
                <input
                    {...inputOptions}
                    ref={(e) => {
                        if (inputRef) {
                            inputRef.current = e;
                        }
                        registerRef(e);
                    }}
                    {...rest}
                    type={type}
                    id={inputId}
                    className="form-control"
                />
                {children}
            </div>
        </div>
        {showErrors && <div className="form-group-errors">
            <ErrorMessage
                name={name}
                errors={formState.errors as FieldErrors}
                as={<div className="alert alert-danger" data-xid={`${inputId}-alert`} />}
            />
        </div>}
    </Fragment>
}
