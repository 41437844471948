import {OauthData} from "@app/AppContext/classes/User/model/OauthData";

export enum UserState {
    ANONYMOUS = 'anonymous',
    AUTHENTICATED = 'authenticated',
}

export type AnonymousUserData = {
    state: UserState.ANONYMOUS,
    logoutReason?: string;
}

export type AuthenticatedUserData = {
    state: UserState.AUTHENTICATED,
    data: OauthData;
    loggedInAt: Date;
    expiresAt: Date;
}

export type UserData = AnonymousUserData | AuthenticatedUserData;
