import {FC} from "react";
import {Route, Routes} from "react-router-dom";

import {BenefitsRoute, ManualPostRoute, ManualsIndexRoute, TermsAndConditionsRoute} from "@app/Manuals/ManualsRoutes";
import {BenefitsPage} from "@app/Manuals/pages/BenefitsPage/BenefitsPage";
import {ManualsIndexPage} from "@app/Manuals/pages/ManualsIndexPage/ManualsIndexPage";
import {ManualsPostDetailPage} from "@app/Manuals/pages/ManualsPostDetailPage/ManualsPostDetailPage";
import {TermsAndConditionsPage} from "@app/Manuals/pages/TermsAndConditionsPage/TermsAndConditionsPage";

export const Manuals: FC = () => {
    return <Routes>
        <Route path={ManualsIndexRoute.path} element={<ManualsIndexPage />} />
        <Route path={TermsAndConditionsRoute.path} element={<TermsAndConditionsPage />} />
        <Route path={ManualPostRoute.path} element={<ManualsPostDetailPage />} />
        <Route path={BenefitsRoute.path} element={<BenefitsPage />} />
    </Routes>
}
