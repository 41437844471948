import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {BranchQualityDetailType} from "@app/Branch/model/BranchQuality/BranchQualityDetail";
import {PenaltyBranchQualityItem} from "@app/Branch/model/BranchQuality/BranchQualityItem";
import {BranchQualityProcessPenalty} from "@app/Branch/model/BranchQuality/BranchQualityProcessPenalty";
import {
    ProcessPenaltyItem
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyItem";
import {Img} from "@common/components/Img/Img";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {recordToArray} from "@common/utils/recordToArray";

export type ProcessPenaltyProps = {
    processPenalty: BranchQualityProcessPenalty;
}

export const ProcessPenalty: FC<ProcessPenaltyProps> = ({processPenalty}) => {
    const {t} = useTranslation();
    const dateFormatter = useDateFormatter();

    let totalCount = 0;

    const penaltyItems = recordToArray(processPenalty.items)
        .filter((item): item is [BranchQualityDetailType, PenaltyBranchQualityItem] => {
            totalCount += item[1] ? Number(item[1].value) : 0;
            return item[1] !== null && Number(item[1].value) > 0;
        });

    const half = Math.ceil(penaltyItems.length / 2);

    return <div className="branch-quality-page__box">
        <h5>{t('viewBranchQuality:processPenalty.header')}</h5>
        <p>{t('viewBranchQuality:dateRange', {
            dateFrom: dateFormatter(processPenalty.dateRange.since, DateFormatType.DATE_FORMAT),
            dateTo: dateFormatter(processPenalty.dateRange.to, DateFormatType.DATE_FORMAT),
        })}</p>
        {penaltyItems.length === 0 && <p className="branch-quality-page__no-penalty">
            <Img src="/images/icons/ico/ico-award-green.svg" alt="award"/>
            {t('viewBranchQuality:processPenalty.noPenalty')}
            <Img src="/images/icons/ico/ico-award-green.svg" alt="award"/>
        </p>}
        {penaltyItems.length > 0 && <Fragment>
            <p className="branch-quality-page__total-count">
                {t('viewBranchQuality:processPenalty.totalCount', {totalCount})}
            </p>
            <div className="branch-quality-page__data">
                <div>
                    {penaltyItems.slice(0, half).map(([penaltyType, penaltyItem]) => (
                        <ProcessPenaltyItem key={penaltyType} penaltyType={penaltyType} penaltyItem={penaltyItem}/>
                    ))}
                </div>
                <div>
                    {penaltyItems.slice(half).map(([penaltyType, penaltyItem]) => (
                        <ProcessPenaltyItem key={penaltyType} penaltyType={penaltyType} penaltyItem={penaltyItem}/>
                    ))}
                </div>
            </div>
        </Fragment>}
    </div>
}
