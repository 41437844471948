import {ReactElement, useCallback, useRef, useState} from "react";
import {useForm} from "react-hook-form";
import {useDebouncedCallback} from "use-debounce";

import {useGtm} from "@app/App/hooks/useGtm";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {searchType} from "@app/Packet/api/searchTypeApi";
import {getSearchTypeHandleElement} from "@app/Packet/pages/DashboardPage/SearchForm/getSearchTypeHandleElement";
import {
    OnPasswordHandlingError
} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {
    MIN_PASSWORD_LENGTH,
    PASSWORD_SEARCH_DEBOUNCE_DELAY
} from "@app/Packet/pages/DashboardPage/SearchForm/searchFormConstants";
import {
    SearchFormResult,
    SearchFormState,
    UseSearchFormType
} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";
import {SearchInputData} from "@common/components/forms/SearchInput/SearchInput";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export const useSearchForm = (): UseSearchFormType  => {
    const appContext = useAppContext();
    const gtm = useGtm();

    const form = useForm<SearchInputData>({
        mode: 'onChange',
        defaultValues: {query: ''},
    });

    const [searchFormState, setSearchFormState] = useState<SearchFormState>(SearchFormState.DEFAULT);
    const [handleElement, setHandleElement] = useState<ReactElement|null>(null);

    const abortController = useRef<AbortController|null>(null);
    const isCorrectButUsed = useRef<boolean>(false);
    const usedQueries = useRef<string[]>([]);
    const queryInHandle = useRef<string|null>(null);

    const onVerified = useCallback((password: string) => {
        if (password === queryInHandle.current) {
            setSearchFormState(SearchFormState.HANDLING);
        }
    }, []);

    const onFinish = useCallback((result: SearchFormResult) => {
        gtm.tag('passBox', {
            'passBox.Action':'Result',
            'passBox.Timestamp': (new Date()).getTime(),
            'passBox.Result': result,
        });
        usedQueries.current = [];
        isCorrectButUsed.current = false;
        queryInHandle.current = null;
        setSearchFormState(SearchFormState.DEFAULT);
        setHandleElement(null);
        form.setFocus('query');
        form.reset({query: ''});
    }, [form, gtm]);

    const onError: OnPasswordHandlingError = useCallback((password, onErrorCallback, correctButUsed) => {
        if (password === queryInHandle.current) {
            isCorrectButUsed.current = correctButUsed === true;
            queryInHandle.current = null;
            setSearchFormState(SearchFormState.PASSWORD_INVALID);
            setHandleElement(null);
            form.setFocus('query');
            if (onErrorCallback) {
                onErrorCallback();
            }
        }
    }, [form]);

    const showAppErrorToast = useShowErrorToast(appToast);

    const doSearch = useDebouncedCallback(async (query: string) => {
        if (!form.formState.isDirty) {
            gtm.tag('passBox', {
                'passBox.Action':'Start',
                'passBox.Timestamp': (new Date()).getTime(),
            });
        }

        query = query.trim();
        if (query.replace(/[^0-9a-zA-Z]/gi, '').length !== query.length) {
            setSearchFormState(SearchFormState.PASSWORD_INVALID);
            return;
        }

        if (usedQueries.current.includes(query)) {
            return;
        }

        if (abortController.current) {
            abortController.current.abort();
        }

        if (query.length < MIN_PASSWORD_LENGTH) {
            if (query.length > 0) {
                setSearchFormState(SearchFormState.PASSWORD_INVALID);
            } else {
                setSearchFormState(SearchFormState.DEFAULT);
                usedQueries.current = [];
            }
            return;
        }

        usedQueries.current.push(query);
        setSearchFormState(SearchFormState.SEARCHING);

        try {
            queryInHandle.current = query;
            abortController.current = new AbortController();
            const querySearchType = await searchType({subject: query}, abortController.current, appContext.api)

            setHandleElement(getSearchTypeHandleElement(
                query,
                querySearchType,
                onVerified,
                onFinish,
                onError,
            ));
        } catch (error: unknown) {
            if (error instanceof Error && error.name !== 'AbortError' && query === queryInHandle.current) {
                showAppErrorToast(error);
                queryInHandle.current = null;
                setSearchFormState(SearchFormState.PASSWORD_INVALID);
                form.setFocus('query');
            }
        }
    }, PASSWORD_SEARCH_DEBOUNCE_DELAY);

    return {
        searchFormState,
        isCorrectButUsed: isCorrectButUsed.current,
        handleElement,
        doSearch,
        form,
    }
}
