import {FC, Fragment} from "react";

import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {PacketDetailButton} from "@app/Packet/components/PacketDetailButton/PacketDetailButton";
import {buttonClassName} from "@common/components/Button/buttonClassName";
import {isBarcode} from "@common/utils/isBarcode";

import './formattedPacketBarcode.scss';

export type FormattedPacketBarcodeProps = {
    barcode: string;
    withHighlight?: boolean;
    withoutLink?: boolean;
    linkHref?: string;
}

export const FormattedPacketBarcode: FC<FormattedPacketBarcodeProps> = (
    {barcode, linkHref, withHighlight = false, withoutLink = false}
) => {

    const formattedPacketBarcode = isBarcode(barcode) ? formatPacketBarcode(barcode) : barcode;

    const formattedBarcodeWithHighlight = <Fragment>
        {formattedPacketBarcode.substring(0, 10)}{' '}
        <span className="formatted-packet-barcode__highlight">
            {formattedPacketBarcode.substring(11)}
        </span>
    </Fragment>

    if (withoutLink) {
        return <span className="formatted-packet-barcode">
            {withHighlight ? formattedBarcodeWithHighlight : formattedPacketBarcode}
        </span>
    }

    if (linkHref) {
        return <a
            href={linkHref}
            className={buttonClassName('formatted-packet-barcode', 'link')}
        >
            {withHighlight ? formattedBarcodeWithHighlight : formattedPacketBarcode}
        </a>
    }

    return <PacketDetailButton barcode={barcode} variant="link" className="formatted-packet-barcode">
        {withHighlight ? formattedBarcodeWithHighlight : formattedPacketBarcode}
    </PacketDetailButton>
}
