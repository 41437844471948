import {isSameDay} from "date-fns";
import {TFunction} from "i18next";
import {useCallback, useEffect, useRef, useState} from "react";
import {useForm, UseFormReturn, useWatch} from "react-hook-form";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    branchHoursRange, createBranchHoursExceptions,
} from "@app/OpeningHours/api/branchHoursApi";
import {branchToGridOpeningHours} from "@app/OpeningHours/components/EditableOpeningHoursGrid/branchToGridOpeningHours";
import {useOpeningHours} from "@app/OpeningHours/context/useOpeningHours";
import {BranchHoursException} from "@app/OpeningHours/model/OpeningHoursRequestsAndResponses";
import {BaseOpeningHoursGridForm} from "@app/OpeningHours/OpeningHoursFormTypes";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";

export type OneDayExceptionFormExceptionType = 'closed'|'exception';

export type OneDayExceptionForm = BaseOpeningHoursGridForm & {
    type: OneDayExceptionFormExceptionType;
}

export type UseOneDayExceptionModal = {
    apiError?: ApiError;
    close: () => void;
    exceptionType: OneDayExceptionFormExceptionType,
    form: UseFormReturn<OneDayExceptionForm>;
    isLoading: boolean;
    submit: (values: OneDayExceptionForm) => void;
    selectedDay: Date|undefined;
    setSelectedDay: (date: Date|undefined) => void;
}

export const useOneDayExceptionModal = (editDate: Date|undefined, onHide: () => void, t: TFunction): UseOneDayExceptionModal => {
    const appContext = useAppContext();

    const [selectedDay, setSelectedDay] = useState<Date|undefined>(editDate);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [apiError, setApiError] = useState<ApiError|undefined>(undefined);

    const form = useForm<OneDayExceptionForm>({
        defaultValues: {
            type: 'exception',
            openingHours: [],
        },
        shouldUnregister: true,
    })
    const {branchHours} = useOpeningHours();

    const exceptionType = useWatch<OneDayExceptionForm, 'type'>({control: form.control, name: 'type', defaultValue: 'exception'});

    const openingHoursLoadedForDay = useRef<Date|null>(null);
    const isSelectedDayLoaded = useCallback(() => {
        return selectedDay && openingHoursLoadedForDay.current && isSameDay(openingHoursLoadedForDay.current, selectedDay);
    }, [selectedDay]);

    useEffect(() => {
        if (selectedDay && exceptionType === 'exception' && !isSelectedDayLoaded())  {
            setIsLoading(true);
            branchHoursRange(selectedDay, selectedDay, appContext.api)
                .then((response) => {
                    const gridHours = branchToGridOpeningHours(response.openingHours)
                    openingHoursLoadedForDay.current = selectedDay;
                    form.setValue('openingHours', gridHours);
                })
                .catch((error: Error) => {
                    modalToast.error(t(error.message));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [appContext.api, exceptionType, form, isSelectedDayLoaded, selectedDay, t]);

    const close = useCallback(() => {
        onHide();
        setSelectedDay(undefined);
        openingHoursLoadedForDay.current = null;
        form.reset({type: 'exception', openingHours: []});
        setApiError(undefined);
    }, [form, onHide]);

    const showModalErrorToast = useShowErrorToast(modalToast, 'openingHours:error', 'errors:4xx.description');

    const submit = useCallback((values: OneDayExceptionForm) => {
        if (!selectedDay) {
            return;
        }

        const branchHoursExceptions: BranchHoursException[] = values.openingHours.map((editedHour) => {
            return {
                date: selectedDay,
                action: 'create',
                hours: values.type === 'closed' ? [] : editedHour.hours.filter((block) => {
                    return block.open !== '' && block.close !== '';
                }),
            }
        });
        if (editDate && !isSameDay(editDate, selectedDay)) {
            branchHoursExceptions.push({
                date: editDate,
                action: 'delete',
            });
        }

        createBranchHoursExceptions({branchHours: branchHoursExceptions}, appContext.api)
            .then(() => {
                close();
                branchHours.invalidate();
                appToast.success(t(`openingHours:oneDayExceptionModal.success.${editDate !== undefined ? 'edit' : 'create'}`));
            })
            .catch((error: Error) => {
                if (values.type === 'exception' && error instanceof ApiError) {
                    setApiError(error);
                } else {
                    showModalErrorToast(error);
                }
            });
    }, [selectedDay, editDate, appContext.api, close, branchHours, t, showModalErrorToast]);

    return {
        apiError,
        close,
        exceptionType,
        form,
        isLoading,
        submit,
        selectedDay,
        setSelectedDay
    }
}
