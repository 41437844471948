import {FC, Fragment, useCallback, useState} from "react";
import {Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {reportIssue} from "@app/Packet/api/packetApi";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {Img} from "@common/components/Img/Img";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

type ReportIssueFormData = {
   issue: string;
}

export type ReportIssueProps = {
    barcode: string;
}

export const ReportIssue: FC<ReportIssueProps> = ({barcode}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const form = useForm<ReportIssueFormData>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const showDeliverErrorToast = useShowErrorToast(modalToast);

    const onSubmit = useCallback(({issue}: {issue: string}) => {
        setIsSubmitting(true);
        reportIssue({barcode, issue}, appContext.api)
            .then(() => {
                setModalOpened(false);
                appToast.success(t('viewPacketReportIssue:message.success'));
            })
            .catch((error: Error) => {
                showDeliverErrorToast(error);
            })
            .finally(() => {
                setIsSubmitting(false);
            })
    }, [appContext.api, barcode, showDeliverErrorToast, t]);

    return <Fragment>
        <Button
            onClick={() => setModalOpened(true)}
            title={t('viewDashboard:action.packetReportIssue')}
            variant="secondary"
        >
            <Img src="/images/icons/ico/ico-packet-damage.svg" alt={t('viewDashboard:action.packetReportIssue')} />
        </Button>
        <BaseModal
            show={modalOpened}
            onHide={() => setModalOpened(false)}
            header={t('viewDashboard:action.packetReportIssue')}
            footer={<Fragment>
                <Button onClick={form.handleSubmit(onSubmit)} variant="success">{t('viewPacketReportIssue:form.submit')}</Button>
                <Button onClick={() => setModalOpened(false)}>{t('viewPacketReportIssue:action.close')}</Button>
            </Fragment>}
            loading={isSubmitting}
        >
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormGroup
                    name="issue"
                    label={t('viewPacketReportIssue:form.issue')}
                    registerOptions={{required: t<string>('viewPacketReportIssue:error.issueRequired')}}
                    inputOptions={{
                        placeholder: t('viewPacketReportIssue:form.placeholder'),
                    }}
                    form={form}
                />
            </form>
        </BaseModal>
    </Fragment>
}
