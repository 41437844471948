import {Fragment} from "react";
import {useTranslation} from "react-i18next";

import {ManualPostLink} from "@app/BranchEvent/components/ManualPostLink/ManualPostLink";
import {useBranchEventMessage} from "@app/BranchEvent/hooks/useBranchEventMessage";
import {InventoryRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventComponent} from "@app/BranchEvent/model/BranchEventComponent";

export const InventoryRequiredBranchEventContent: BranchEventComponent<InventoryRequiredBranchEvent> = ({event}) => {
    const {t} = useTranslation();

    const message = useBranchEventMessage(event);

    return <Fragment>
        <p>{message}</p>
        <ManualPostLink postIdTranslationKey="support:whatToDoArticle.inventoryRequired">
            {t('branchEvent:timeForInventoryWhatToDo')}
        </ManualPostLink>
    </Fragment>
}
