import {observer} from "mobx-react-lite";
import {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {
    TransactionListManager, TransactionListType
} from "@app/Transaction/components/TransactionGrid/TransactionListManager";
import {moneyToExportString} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/moneyToExportString";
import {PaginatedGridExport} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/PaginatedGridExport";
import {
    usePaginatedGridExport
} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/usePaginatedGridExport";
import {LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export type TransactionGridExportProps = {
    listType: TransactionListType;
    manager: TransactionListManager;
}

const TRANSACTION_LIST_EXPORT_PAGE_SIZE = 200;
const TRANSACTION_LIST_MAX_EXPORT_ITEMS = 3000;

export const TransactionGridExport: FC<TransactionGridExportProps> = observer(({listType, manager}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const paginatedGridExport = usePaginatedGridExport(manager, TRANSACTION_LIST_MAX_EXPORT_ITEMS);

    const dateFormatter = useDateFormatter();

    const doExport: LoadingButtonOnClick = useCallback(async (stopLoading) => {
        const downloadManager = new TransactionListManager(
            manager.branchId,
            listType,
            appContext.api,
            TRANSACTION_LIST_EXPORT_PAGE_SIZE
        );

        await paginatedGridExport(
            downloadManager,
            t('viewTransaction:transactionGrid.csvFilename'),
            (transaction) => {
                const result = {
                    [t('transaction:property.createdAt')]: dateFormatter(transaction.createdAt, DateFormatType.TIME_FORMAT),
                    [t('transaction:property.packetBarcode')]: dashWhenEmpty(transaction.packetBarcode && formatPacketBarcode(transaction.packetBarcode)),
                    [t('transaction:property.amount')]: moneyToExportString(transaction),
                    [t('transaction:property.currency')]: t(`core:money.parameters.${transaction.currency}.symbol`),
                    [t('transaction:property.transactionType')]: transaction.type.name,
                };

                if (listType === TransactionListType.CASH_REGISTER) {
                    const createdBy = downloadManager.users.get(transaction.createdByUserId);
                    result[t('transaction:property.createdBy')] = dashWhenEmpty(createdBy && createdBy.name);
                }

                result[t('transaction:property.note')] = dashWhenEmpty(transaction.note);
                result[t('transaction:property.result')] = dashWhenEmpty(t(`transaction:value.result.${transaction.authState}`));

                return result;
            }
        );

        stopLoading();
    }, [appContext.api, dateFormatter, listType, manager.branchId, paginatedGridExport, t]);

    if (!manager.hasItems) {
        return null;
    }

    return <PaginatedGridExport doExport={doExport} />
})
