import {observer} from "mobx-react-lite";
import {FC} from "react";
import {Col, Row} from "react-bootstrap";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {IncomingMessageForm} from "@app/Messaging/components/NewMessage/IncomingMessageForm/IncomingMessageForm";
import {OutgoingMessageForm} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/OutgoingMessageForm";

import './newMessage.scss';

export type NewMessageProps = {
    isInsideModal?: boolean;
    onFormStored?: () => void;
}

export const NewMessage: FC<NewMessageProps> = observer(({isInsideModal, onFormStored}) => {
    return <Row className="new-message">
        <Col xl={8}>
            <Can I={Action.SEND} a={Subject.OUTGOING_MESSAGE}>
                <OutgoingMessageForm />
            </Can>
            <Can not I={Action.SEND} a={Subject.OUTGOING_MESSAGE}>
                <Can I={Action.SEND} a={Subject.INCOMING_MESSAGE}>
                    <IncomingMessageForm
                        isInsideModal={isInsideModal}
                        onFormStored={onFormStored}
                    />
                </Can>
            </Can>
        </Col>
    </Row>
})
