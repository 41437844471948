import {WebSocketMessage} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketMessage";
import {
    isWebSocketResultMessage,
    WebSocketResultMessage
} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketResultMessage";

export enum WebSocketErrorMessageCode {
    TOO_MANY_CONNECTIONS = 'TooManyConnections',
    INVALID_KEEP_ALIVE_MESSAGE = 'InvalidKeepAliveMessage',
    INVALID_ACCESS_TOKEN = 'InvalidAccessToken',
    INVALID_MESSAGE_STRUCTURE = 'InvalidMessageStructure',
}

export type WebSocketErrorMessage = WebSocketResultMessage & {
    errorCode: WebSocketErrorMessageCode;
}

export const isWebSocketErrorMessage = (message: WebSocketMessage): message is WebSocketErrorMessage => {
    return isWebSocketResultMessage(message) && 'errorCode' in message;
}

export const RecoverableWebSocketErrorMessageCodes = [
    WebSocketErrorMessageCode.TOO_MANY_CONNECTIONS,
]
