
import {FC, Fragment, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {CallJobAction, RemoveJob} from "@app/Devices/components/ReaderModal/hooks/useReaderJobs";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobAction} from "@app/Devices/model/ReaderJobAction";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";
import {Button} from "@common/components/Button/Button";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export type CancelJobModalProps = {
    callJobAction: CallJobAction;
    job: ReaderJob;
    removeJob: RemoveJob;
    setJobForCancelling: (job: ReaderJob|null) => void;
}

export const CancelJobModal: FC<CancelJobModalProps> = ({
    callJobAction,
    job,
    removeJob,
    setJobForCancelling,
}) => {
    const {t} = useTranslation();

    const showModalErrorToast = useShowErrorToast(modalToast, 'reader:error', 'reader:error.general');

    const onPostpone = useCallback(() => {
        removeJob(job, 'reader:message.jobWasPostponed');
        setJobForCancelling(null);
    }, [job, removeJob, setJobForCancelling]);

    const onCancelCompletely = useCallback(async () => {
        try {
            await callJobAction(job, ReaderJobAction.CANCEL, undefined, 'reader:message.jobWasCancelled');
            setJobForCancelling(null);
        } catch (error: unknown) {
            showModalErrorToast(error as Error);
        }
    }, [callJobAction, job, setJobForCancelling, showModalErrorToast]);

    return <BaseModal
        show={true}
        onHide={() => false}
        header={t('reader:cancellingJob.title')}
        footer={<Fragment>
            <Button onClick={onPostpone} variant="dark">
                {t('reader:cancellingJob.postpone')}
            </Button>
            <Button onClick={onCancelCompletely} variant="outline-danger">
                {t('reader:cancellingJob.cancel')}
            </Button>
        </Fragment>}
        disableOutsideClose={true}
        closeButton={false}
        size="sm"
    >
        {job.type === ReaderJobType.RECEIVE_PACKETS && <p>
            {t('reader:cancellingJob.youHaventFinishedPacketReceival')},{' '}
            <strong>{t('reader:cancellingJob.packetsAreNotReceived')}!</strong>{' '}
            {t('reader:cancellingJob.pressProcessReaderAgain')}
        </p>}
        <p>{t('reader:cancellingJob.question')}</p>
    </BaseModal>
}
