import {
    CodDeliveryPacket,
    DeliveryPacket,
    NoCodDeliveryPacket
} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {PacketDetailed, packetHasCod} from "@app/Packet/model/PacketDetailed";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {Sender} from "@app/Packet/model/Sender";

export const createDeliveryPacket = (
    packet: PacketDetailed,
    senders: Map<string, Sender>,
    needsIdVerification: boolean = false,
): DeliveryPacket => {

    const sender = packet.senderId !== null ? senders.get(packet.senderId) : undefined;
    const isDelivered = packet.status.id === PacketStatusId.DELIVERED;

    const deliveryPacket = packetHasCod(packet)
        ? new CodDeliveryPacket(packet, packet.isPaidByCard, sender, isDelivered)
        : new NoCodDeliveryPacket(packet, sender, isDelivered);

    if (needsIdVerification) {
        deliveryPacket.requireIdVerification();
    }

    return deliveryPacket;
}
