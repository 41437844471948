import classNames from "classnames";
import {PropsWithChildren, ReactElement, useCallback} from "react";

import {Sorter} from "@common/components/grids/GridSorter/Sorter";
import {SortingCaret} from "@common/components/grids/SortingCaret/SortingCaret";

import './gridSorter.scss';

export type GridSorterProps<ItemType, SortId extends string> = {
    className?: string;
    sortId: SortId;
    sorter: Sorter<ItemType, SortId>;
}

export const GridSorter = <ItemType, SortId extends string, >(
    {children, className, sortId, sorter}: PropsWithChildren<GridSorterProps<ItemType, SortId>>
): ReactElement|null => {

    const sort = useCallback(() => {
        if (sorter.sorting && sorter.sorting.id === sortId && sorter.sorting.desc) {
            sorter.setSorting(null);
        } else {
            sorter.setSorting({
                id: sortId,
                desc: sorter.sorting !== null ? sorter.sorting.id === sortId : false,
            });
        }
    }, [sortId, sorter]);

    return <a onClick={sort} className={classNames('grid-sorter', className)}>
        {children}
        {sorter.sorting && sorter.sorting.id === sortId && <SortingCaret desc={sorter.sorting.desc} />}
    </a>

}
