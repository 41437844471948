import {ExportToCsv} from "export-to-csv-fix-source-map";
import {useCallback} from "react";

import {PaginatedDataManager} from "@common/components/grids/PaginatedDataManager";

export const useCsvDownload = () => {
    return useCallback(async <ItemType, RequestType, ExtendedResponseType>(
        filename: string,
        manager: PaginatedDataManager<ItemType, RequestType, ExtendedResponseType>,
        rowDataGenerator: (item: ItemType) => Record<string, string>,
    ) => {
        const csvData: Record<string, string>[] = [];

        let page = 1;
        do {
            await manager.loadPage(page);
            manager.items.forEach((item) => {
                csvData.push(rowDataGenerator(item));
            });
            ++page;
        } while (page <= manager.maxPage);

        if (csvData.length === 0) {
            throw new Error('Trying to export an empty set');
        }

        const csvExporter = new ExportToCsv({
            fieldSeparator: ';',
            filename: filename.endsWith('.csv') ? filename.substring(0, filename.length - 4) : filename,
            showLabels: true,
            useKeysAsHeaders: true,
        });

        csvExporter.generateCsv(csvData);
    }, []);
}
