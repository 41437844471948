import {CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";
import {
    DeliveryAlertType
} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/DeliveryAlert/DeliveryAlert";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {PacketDeliveryMetaReturnAcceptance} from "@app/Packet/model/PacketDeliveryMeta";

export const hasPacketThisAlert = (packet: DeliveryPacket, alertType: DeliveryAlertType): boolean => {
    switch (alertType) {
        case DeliveryAlertType.NO_UNPACKED_RETURN:
            return packet.info.deliveryMeta.returnAcceptance === PacketDeliveryMetaReturnAcceptance.UNPACKED;
        case DeliveryAlertType.ADULT_CONTENT:
            return packet.info.adultContent;
        case DeliveryAlertType.CARD_PAYMENT_FAILED: {
            if (!packet.isCod || packet.paid || packet.cardPaymentState === undefined) {
                return false;
            }

            const isPaymentError = packet.cardPaymentState.status === CardPaymentStatus.PAYING
                && packet.cardPaymentState.transaction.status === CardPaymentTransactionStatus.ERROR;
            const isCancelledPayment = packet.cardPaymentState.status === CardPaymentStatus.CANCELLING
                && packet.cardPaymentState.transaction.status === CardPaymentTransactionStatus.SUCCESS;

            return isPaymentError || isCancelledPayment;
        }
    }
}
