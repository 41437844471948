import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {EmbeddedTypeByEmbeddable, ResponseWithEmbedded} from "@common/model/responses/ResponseWithEmbedded";

export const extractEmbedded = <EmbeddableType extends Embeddable>(
    response: ResponseWithEmbedded|null,
    embeddable: EmbeddableType
): Map<string, EmbeddedTypeByEmbeddable[EmbeddableType]> => {

    const embeddedMap = new Map<string, EmbeddedTypeByEmbeddable[EmbeddableType]>();

    if (response && response.embedded) {
        switch (embeddable) {
            case Embeddable.BRANCH: {
                if (response.embedded.branch) {
                    response.embedded.branch.forEach((branch) => {
                        embeddedMap.set(branch.id, branch as EmbeddedTypeByEmbeddable[EmbeddableType]);
                    });
                }
                break;
            }
            case Embeddable.USER: {
                if (response && response.embedded && response.embedded.user) {
                    response.embedded.user.forEach((user) => {
                        embeddedMap.set(user.id, user as EmbeddedTypeByEmbeddable[EmbeddableType]);
                    });
                }
                break;
            }
            case Embeddable.SENDER: {
                if (response && response.embedded && response.embedded.sender) {
                    response.embedded.sender.forEach((sender) => {
                        embeddedMap.set(sender.id, sender as EmbeddedTypeByEmbeddable[EmbeddableType]);
                    });
                }
                break;
            }
        }
    }

    return embeddedMap;
}
