import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {DashboardGridManager} from "@app/AppContext/classes/DashboardGridManager";
import {
    PacketGridSetStoredSorting
} from "@app/Packet/components/PacketGrid/PacketGridHeader/PacketGridHeader";
import {PaginatedGridSorter} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/PaginatedGridSorter";

export type DashboardGridExtraHeadersProps = {
    manager: DashboardGridManager;
    setStoredSorting: PacketGridSetStoredSorting;
}

export const DashboardGridExtraHeaders: FC<DashboardGridExtraHeadersProps> = ({manager, setStoredSorting}) => {
    const {t} = useTranslation();

    return <Fragment>
        <th className="dashboard-grid__delivery-date">
            <PaginatedGridSorter manager={manager} sortBy="deliveryDate" onSort={setStoredSorting}>
                {t('packet:property.deliveryDate')}
            </PaginatedGridSorter>
        </th>
        <th>{t('packet:property.shelf.label')}</th>
        <th className="dashboard-grid__cod">{t('packet:property.hasCashOnDelivery.title')}</th>
        <th />
    </Fragment>

}
