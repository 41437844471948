import {setISODay} from "date-fns";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {ucFirst} from "@common/utils/ucFirst";

export type UseHeaderForDayOfWeekType = (dayOfWeekString: string) => string;

export const useHeaderForDayOfWeek = (): UseHeaderForDayOfWeekType => {
    const {t} = useTranslation();
    const dateFormatter = useDateFormatter();

    return useCallback((dayOfWeekString) => {
        const dayOfWeek: number = parseInt(dayOfWeekString, 10);
        if (dayOfWeek <= 7) {
            return ucFirst(dateFormatter(setISODay(new Date(), dayOfWeek), DateFormatType.DAY_OF_WEEK));
        }

        return t('openingHours:page.regularOpeningHours.publicHolidays');
    }, [dateFormatter, t]);
}
