import {IdCardType} from "@app/Packet/components/PacketDelivery/model/PacketIdVerification";

export const idCardTypeLabel = (idCardType: IdCardType): string => {
    switch (idCardType) {
        case IdCardType.IDENTITY_CARD:
            return 'viewPacketDelivery:packetIdVerification.form.idCardType.options.identityCard';
        case IdCardType.DRIVER_LICENSE:
            return 'viewPacketDelivery:packetIdVerification.form.idCardType.options.driverLicense';
        case IdCardType.PASSPORT:
            return 'viewPacketDelivery:packetIdVerification.form.idCardType.options.passport';
    }
}
