export enum IdCardType {
   IDENTITY_CARD = 'id',
   DRIVER_LICENSE = 'dl',
   PASSPORT = 'pp',
}

export type PacketIdVerification = {
    idCardType: IdCardType;
    name: string;
    idCardNumber: string;
}
