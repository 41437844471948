import {observer} from "mobx-react-lite";
import {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    PartnerCardTransactionListManager
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionListManager";
import {moneyToExportString} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/moneyToExportString";
import {PaginatedGridExport} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/PaginatedGridExport";
import {
    usePaginatedGridExport
} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/usePaginatedGridExport";
import {LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export type PartnerCardTransactionGridProps = {
    manager: PartnerCardTransactionListManager;
}

const PARTNER_CARD_TRANSACTION_LIST_EXPORT_PAGE_SIZE = 200;
const PARTNER_CARD_TRANSACTION_LIST_MAX_EXPORT_ITEMS = 3000;

export const PartnerCardTransactionGridExport: FC<PartnerCardTransactionGridProps> = observer(({manager}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const paginatedGridExport = usePaginatedGridExport(manager, PARTNER_CARD_TRANSACTION_LIST_MAX_EXPORT_ITEMS);

    const dateFormatter = useDateFormatter();

    const doExport: LoadingButtonOnClick = useCallback(async (stopLoading) => {
        const downloadManager = new PartnerCardTransactionListManager(appContext.api, manager.branchId, PARTNER_CARD_TRANSACTION_LIST_EXPORT_PAGE_SIZE);

        await paginatedGridExport(
            downloadManager,
            t('viewPartnerCardTransaction:csvFilename'),
            (transaction) => {
                return {
                    [t('transaction:property.transactionType')]: t(
                        `viewPartnerCardTransaction:property.transactionType.${parseInt(transaction.amount, 10) > 0 ? 'sale' : 'cancellation'}`
                    ),
                    [t('transaction:property.createdAt')]: dateFormatter(transaction.createdAt, DateFormatType.TIME_FORMAT),
                    [t('transaction:property.amount')]: moneyToExportString(transaction),
                    [t('transaction:property.currency')]: t(`core:money.parameters.${transaction.currency}.symbol`),
                    [t('viewPartnerCardTransaction:property.reference')]: dashWhenEmpty(transaction.reference),
                    [t('transaction:property.result')]: dashWhenEmpty(t(`transaction:value.result.${transaction.authState}`)),
                };
            }
        );

        stopLoading();
    }, [appContext.api, dateFormatter, manager.branchId, paginatedGridExport, t]);

    if (!manager.hasItems) {
        return null;
    }

    return <PaginatedGridExport doExport={doExport} />
})
