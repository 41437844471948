import {FC, Fragment, useContext} from "react";
import {Col, Row, ColProps} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {ReceivedMessagesIcon} from "@app/Messaging/components/ReceivedMessagesIcon/ReceivedMessagesIcon";
import {
    NewMessageRoute,
    ReceivedMessagesRoute,
    SentMessagesRoute
} from "@app/Messaging/MessagingRoutes";
import {Img} from "@common/components/Img/Img";
import {AppLink} from "@common/components/routing/AppLink/AppLink";

export const MessagingNavigation: FC<ColProps> = (props) => {
    const {t} = useTranslation();

    const ability = useContext(AbilityContext);
    const canSend = ability.can(Action.SEND, Subject.OUTGOING_MESSAGE) || ability.can(Action.SEND, Subject.INCOMING_MESSAGE);

    return <Col className="messaging--navigation nav nav-tabs flex-column" {...props}>
        <NavLink
            end
            to={ReceivedMessagesRoute.path}
            className={({isActive}) =>
                `nav-link text-decoration-none border-top border-bottom-0 p-3${isActive ? ' active' : ''}`
            }
        >
            <Row>
                <Col xs={{span: 2, offset: 2}} sm={{span: 3, offset: 0}}><ReceivedMessagesIcon /></Col>
                <Col xs={8} sm={9}>{t('message:tabs.received')}</Col>
            </Row>
        </NavLink>
        <NavLink
            to={SentMessagesRoute.path}
            className={({isActive}) =>
                `nav-link text-decoration-none border-top border-bottom p-3${isActive ? ' active' : ''}`}
        >
            <Row>
                <Col xs={{span: 2, offset: 2}} sm={{span: 3, offset: 0}}>
                    <Img className="icon" src="/images/icons/ico/ico-mail-sent.svg" alt={t('message:tabs.sent')} />
                </Col>
                <Col xs={8} sm={9}>{t('message:tabs.sent')}</Col>
            </Row>
        </NavLink>
        {canSend && <Fragment>
            <AppLink className="new-message btn btn-dark" to={NewMessageRoute}>
                {'+ '}{t('message:tabs.new.title')}
            </AppLink>
            <div className="new-message--helper small text-muted">{t('message:tabs.new.helper')}</div>
        </Fragment>}
    </Col>
}
