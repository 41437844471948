import {FC} from "react";

import './manualContent.scss';

export type ManualContentProps = {
    content: {rendered: string};
}

export const ManualContent: FC<ManualContentProps> = ({content}) => {
    return <div className="manual-content" dangerouslySetInnerHTML={{__html: content.rendered}} />
}
