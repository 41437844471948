import {decode} from "html-entities";
import {TFunction} from "i18next";
import striptags from "striptags";

import {MessageListAttachment} from "@app/Messaging/model/MessageListAttachment";

export enum MessageType {
    GENERAL_MESSAGE = 0,
    SYSTEM_MESSAGE = 1,
    URGENT_MESSAGE = 2,
}

export type BaseMessage = {
    id: number;
    type: MessageType;
    subject: string;
    content: string;
    createdAt: string;
    attachment: MessageListAttachment|null;
}

export type IncomingMessageNonR = BaseMessage & {
    senderBranchId: string;
    topic: number;
    isRead: boolean;
}

export type OutgoingMessageRecipient = {
    branchId: string;
    isRead: boolean;
}

export type OutgoingMessageNonR = BaseMessage & {
    senderUserId: string;
    recipients: OutgoingMessageRecipient[];
}

export type IncomingMessage = IncomingMessageNonR & {
    reply: OutgoingMessageNonR|null;
}

export type OutgoingMessage = OutgoingMessageNonR & {
   repliesTo: IncomingMessageNonR|null;
}

export type Message = IncomingMessage | OutgoingMessage;

export const isIncoming = (message: Message): message is IncomingMessage => {
    return 'senderBranchId' in message;
}

export const isOutgoing = (message: Message): message is OutgoingMessage => {
    return 'senderUserId' in message;
}

export const isRead = (message: Message): boolean => {
    if (isIncoming(message)) {
        return message.isRead;
    }
    if (isOutgoing(message)) {
        return message.recipients.some(recipient => recipient.isRead);
    }

    return false;
}

export const sanitizedMessageContent = (message: string, t?: TFunction, formatted = true): string => {
    const strippedMessageText = decode(striptags(message, formatted ? ['a', 'b', 'br', 'p'] : [], ' '));

    if (!strippedMessageText && t) {
        return t('branchEvent:nonDisplayableUnreadMessage');
    }

    return strippedMessageText;
}
