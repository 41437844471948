import classNames from "classnames";

import {FCWithChildren} from "@common/model/FCWithChildren";

export type PageContentProps = {
    className?: string;
}

export const PageContent: FCWithChildren<PageContentProps> = ({children, className}) => {
    return <div className={classNames('page-content', className)}>
        {children}
    </div>
}
