export class GoogleTagManager {
    public constructor(gtmId?: string) {
        if (!gtmId) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        this.tag('gtm.js', {'gtm.start': new Date().getTime()});

        const gtmUrl = new URL('https://www.googletagmanager.com/gtm.js');
        gtmUrl.searchParams.append('id', gtmId);

        const gtmScript = window.document.createElement('script');
        gtmScript.async = true;
        gtmScript.src = gtmUrl.toString();

        document.head.insertBefore(gtmScript, document.head.firstChild);
    }

    public tag(event: string, params: Record<string, any>) {
        if (window.dataLayer) {
            window.dataLayer.push({
                event,
                ...params,
            });
        }
    }
}
