import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const dashboardInfoEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/dashboard',
}

export const caslEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/casl',
}

export const cataloguesEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/catalogues',
}
