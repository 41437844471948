import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {PacketGridRow} from "@app/Packet/components/PacketGrid/PacketGridRow/PacketGridRow";
import {ParcelPacket, ParcelPacketDifference} from "@app/Packet/model/ParcelPacket";
import {ParcelDetailed} from "@app/Parcel/model/ParcelDetailed";
import {
    ParcelPacketListHeader
} from "@app/Parcel/pages/ParcelDetailPage/ParcelPacketList/ParcelPacketListHeader/ParcelPacketListHeader";
import {useParcelPacketList} from "@app/Parcel/pages/ParcelDetailPage/ParcelPacketList/useParcelPacketList";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {useSorter} from "@common/components/grids/GridSorter/useSorter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {Img} from "@common/components/Img/Img";
import {Loading} from "@common/components/Loading/Loading";
import {LoadingButton} from "@common/components/Loading/LoadingButton/LoadingButton";
import {TranslationWithHtmlParam} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './parcelPacketList.scss';

export type ParcelPacketListProps = {
    parcel: ParcelDetailed;
}

export const ParcelPacketList: FC<ParcelPacketListProps> = observer(({parcel}) => {
    const {t} = useTranslation();

    const {branchesMap, onPrint, onPrintPdf, parcelPackets, sendersMap} = useParcelPacketList(parcel, t);

    const sorter = useSorter<ParcelPacket, 'dateStored'>(
        'parcelPacketList',
        {id: 'dateStored'},
        {dateStored: (parcelPacket) => dashWhenEmpty(parcelPacket.dateStored)}
    );

    if (parcelPackets) {
        parcelPackets.sort(sorter.sort);
    }

    return <div className="parcel-packet-list">
        <div className="parcel-packet-list__header">
            <h4>{t('viewParcel:packetsInParcel')}</h4>
            <div>
                <LoadingButton variant="secondary" onLoadingClick={onPrintPdf}>{t('parcel:action.printPdf')}</LoadingButton>
                <LoadingButton variant="success" onLoadingClick={onPrint}>{t('parcel:action.print')}</LoadingButton>
            </div>
        </div>
        <Loading active={parcelPackets === null} className="packet-grid">
            {parcelPackets && <GridTable totalCount={parcelPackets.length}>
                <ParcelPacketListHeader sorter={sorter} />
                <tbody>
                {parcelPackets.map((parcelPacket) => {
                    const receivedOrConfirmedAt = parcel.receivedAt || parcel.contentConfirmedAt;
                    return <PacketGridRow
                        key={parcelPacket.barcode}
                        packet={parcelPacket}
                        sender={parcelPacket.senderId ? sendersMap.current.get(parcelPacket.senderId) : undefined}
                        branch={parcelPacket.addressId ? branchesMap.current.get(parcelPacket.addressId) : undefined}
                        showAddress={true}
                        className={classNames('parcel-packet-list__row', {
                            [`parcel-packet-list__row_${parcelPacket.differenceFlag || ''}`]: parcelPacket.differenceFlag,
                        })}
                    >
                        <td className="parcel-packet-list__note">
                            {parcelPacket.badBranch && <div>{t('packet:property.noteText.badBranch')}</div>}
                            {parcelPacket.isDamaged && <div>{t('packet:property.noteText.isDamaged')}</div>}
                            {parcelPacket.differenceFlag && <div
                                className={`parcel-packet-list__difference-flag parcel-packet-list__difference-flag_${parcelPacket.differenceFlag}`}
                            >
                                <Img
                                    src={`/images/icons/ico/ico-exclamation-${
                                        parcelPacket.differenceFlag === ParcelPacketDifference.MISSING ? 'red' : '2'
                                    }.svg`}
                                    alt="!"
                                />
                                <div>
                                    {t(`packet:property.noteText.difference.${parcelPacket.differenceFlag}`)}
                                    {receivedOrConfirmedAt && <div className="parcel-packet-list__note-state-at">
                                        <TranslationWithHtmlParam
                                            paramName="date"
                                            translationKey="packet:property.noteText.stateAt" t={t}
                                        >
                                            <DateFormatter date={receivedOrConfirmedAt} showTime={true} />
                                        </TranslationWithHtmlParam>
                                    </div>}
                                </div>
                            </div>}
                        </td>
                        <td className="parcel-packet-list__received">
                            <Img
                                src={`/images/icons/ico/ico-${parcelPacket.isReceived ? 'check' : 'exclamation-red'}.svg`}
                                alt={t(`packet:property.isReceived.helper.${parcelPacket.isReceived ? 'received' : 'notReceived'}`)}
                                title={t(`packet:property.isReceived.helper.${parcelPacket.isReceived ? 'received' : 'notReceived'}`)}
                            />
                        </td>
                    </PacketGridRow>
                })}
                </tbody>
            </GridTable>}
        </Loading>
    </div>
})
