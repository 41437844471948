import classNames from "classnames";
import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {NewMessageButton} from "@app/Messaging/components/NewMessageButton/NewMessageButton";
import {MessageTopic} from "@app/Messaging/model/MessageTopic";
import {Img} from "@common/components/Img/Img";

import './badge.scss';

export type BadgeProps = {
    value: number;
}

export enum BadgeState {
    SUCCESS,
    WARNING,
    DANGER
}

export const Badge: FC<BadgeProps> = ({value}) => {
    const {t} = useTranslation();

    const WARNING_MIN = 0.70;
    const DANGER_MIN = 0.90;

    const state: BadgeState = value < WARNING_MIN
        ? BadgeState.SUCCESS
        : (value < DANGER_MIN ? BadgeState.WARNING : BadgeState.DANGER);

    const iconMap = {
        [BadgeState.SUCCESS]: 'ico-check.svg',
        [BadgeState.WARNING]: 'ico-exclamation-2.svg',
        [BadgeState.DANGER]: 'ico-danger-red.svg',
    }

    return <Fragment>
        <span className={classNames('badge badge-pill', {
            "badge-success": state === BadgeState.SUCCESS,
            "badge-warning": state === BadgeState.WARNING,
            "badge-danger": state === BadgeState.DANGER,
        })}>
            {Math.round(value * 100)}{' '}%
        </span>
        <Img src={`/images/icons/ico/${iconMap[state]}`} alt={iconMap[state]} className="badge-icon" />
        {state !== BadgeState.SUCCESS && <span>
            <NewMessageButton
                topic={MessageTopic.BRANCH_CAPACITY}
                variant="link"
                className="badge__capacity-helper"
                title={t('global:footer.capacityHelper')}
            >
                {t('global:footer.capacityHelper')}
            </NewMessageButton>
        </span>}
    </Fragment>
}
