import {ReactElement} from "react";
import {renderToString} from "react-dom/server";

export const renderComponentToString = (component: ReactElement|null): string => {
    if (component) {
        return renderToString(component);
    }

    return '';
}
