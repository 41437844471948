import {observer} from "mobx-react-lite";
import {FC, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {BranchCommissionRoute, ManagedCommissionRoute} from "@app/Commission/CommissionRoutes";
import {InvoiceGrid} from "@app/Commission/components/InvoiceGrid/InvoiceGrid";
import {InvoiceListManager} from "@app/Commission/components/InvoiceGrid/InvoiceListManager";
import {AccountingDocuments} from "@app/Commission/pages/InvoiceListPage/AccountingDocuments/AccountingDocuments";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";
import {useCaslGuard} from "@common/hooks/useCaslGuard";
import {useSingleBranchTo} from "@common/hooks/useSingleBranchTo";

export const InvoiceListPage: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const branchDetailTo = useSingleBranchTo();

    const invoiceListManager = useMemo<InvoiceListManager>(
        () => new InvoiceListManager(appContext.api),
        [appContext.api]
    );

    const canShow = useCaslGuard(Action.SHOW, Subject.COMMISSION_INVOICE, ManagedCommissionRoute);
    if (!canShow) {
        return null;
    }

    return <PageContent>
        <ReturnLink
            label={t('invoice:action.goBackToCommission')}
            to={branchDetailTo(BranchCommissionRoute, ManagedCommissionRoute)}
        />
        <PageHeader>{t('viewCommission:invoiceList.header')}</PageHeader>
        <InvoiceGrid manager={invoiceListManager} />
        <AccountingDocuments />
    </PageContent>
})
