import {ReactElement, RefObject} from "react";
import {Modal} from "react-bootstrap";

import {Img} from "@common/components/Img/Img";
import {Loading} from "@common/components/Loading/Loading";
import {ToastContainer} from "@common/components/Toast/ToastContainer/ToastContainer";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './baseModal.scss';

export type BaseModalMandatoryProps = {
    show: boolean;
    onHide: () => void;
}

export type BaseModalProps = BaseModalMandatoryProps & {
    bodyRef?: RefObject<HTMLDivElement>;
    header?: string|null;
    footer?: ReactElement|string|null;
    footerText?: string|null;
    className?: string;
    loading?: boolean;
    size?: 'sm'|'lg'|'xl';
    height?: 'normal'|'extended'|'auto';
    closeButton?: boolean;
    disableOutsideClose?: boolean;
    toastContainerId?: string;
    xid?: string;
}

export const BaseModal: FCWithChildren<BaseModalProps> = ({
    bodyRef,
    children,
    className,
    closeButton = true,
    disableOutsideClose = false,
    footer,
    footerText,
    header,
    height = 'normal',
    loading,
    onHide,
    show,
    size = 'xl',
    toastContainerId,
    xid,
}) => {
    return <Modal
        show={show}
        onHide={onHide}
        size={size}
        backdrop={disableOutsideClose ? 'static' : true}
        keyboard={!disableOutsideClose}
        className={className}
        centered
        data-xid={xid}
    >
        <Loading active={loading}>
            <Modal.Header closeButton={closeButton}>
                <span className="modal-header__logo"><Img src="/images/icons/ico/ico-baliky-white.svg" alt="package" /></span>
                <Modal.Title>{header}</Modal.Title>
            </Modal.Header>
                <Modal.Body ref={bodyRef} className={`modal-body--${height}`}>
                    <ToastContainer containerId={toastContainerId || 'modal'} />
                    {children}
                </Modal.Body>
            <Modal.Footer>
                <span className="modal-footer-text">{footerText}</span>
                {footer && <div className="modal-footer-content">
                    {footer}
                </div>}
            </Modal.Footer>
        </Loading>
      </Modal>
}
