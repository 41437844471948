import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {
    useLoadDeliveryPacket
} from "@app/Packet/components/PacketActions/hooks/useLoadDeliveryPacket";
import {
    useAssertPacketCanBeDelivered
} from "@app/Packet/components/PacketDelivery/hooks/useAssertPacketCanBeDelivered";
import {useDeliver} from "@app/Packet/components/PacketDelivery/hooks/useDeliver";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {
    PacketNeedsIdVerificationError
} from "@app/Packet/components/PacketDelivery/model/PacketNeedsIdVerificationError";
import {Packet} from "@app/Packet/model/Packet";
import {printPacketLabels} from "@app/Print/api/printApi";
import {PrintableTypes} from "@app/Print/model/PrintRequestsAndResponses";
import {Img} from "@common/components/Img/Img";
import {appToast} from "@common/components/Toast/toastOpener";
import {PacketDeliveryModal} from "@packetModal/components/PacketDeliveryModal/PacketDeliveryModal";

export type DeliverPacketProps = {
    packet: Packet;
    onSuccess?: () => void;
}

export const DeliverPacket: FC<DeliverPacketProps> = observer(({onSuccess, packet}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [canBeDelivered, setCanBeDelivered] = useState<boolean|null>(null);
    const assertPacketCanBeDelivered = useAssertPacketCanBeDelivered();

    const onDeliveryPacketLoaded = useCallback(async (loadedDeliveryPacket: DeliveryPacket) => {
        try {
            assertPacketCanBeDelivered(loadedDeliveryPacket.info);
        } catch (error: unknown) {
            if (error instanceof PacketNeedsIdVerificationError) {
                loadedDeliveryPacket.requireIdVerification();
            } else {
                throw error;
            }
        }

        await printPacketLabels({
            barcodes: [loadedDeliveryPacket.info.barcode],
            type: PrintableTypes.DELIVERY_RECEIPT,
        }, appContext.api);
    }, [appContext.api, assertPacketCanBeDelivered]);

    const {
        closeModal,
        openModal,
        packets,
    } = useLoadDeliveryPacket(packet, onSuccess, onDeliveryPacketLoaded);

    const onDelivered = useCallback(() => {
        appToast.success(t(
            'viewPacketDelivery:packetDelivery.message.packetDeliveredBarcode',
            {barcode: formatPacketBarcode(packet.barcode)}
        ));
        closeModal();
    }, [closeModal, packet, t]);

    const deliver = useDeliver(onDelivered);

    useEffect(() => {
        try {
            assertPacketCanBeDelivered(packet);
            setCanBeDelivered(true);
        } catch (error: unknown) {
            setCanBeDelivered(error instanceof PacketNeedsIdVerificationError);
        }
    }, [assertPacketCanBeDelivered, packet]);

    if (!canBeDelivered) {
        return null;
    }

    return <Fragment>
        <Button
            onClick={openModal}
            title={t('packet:action.deliver.single')}
            variant="success"
            data-xid={`deliver-packet-${packet.barcode}`}
        >
            <Img src="/images/icons/ico/ico-table-man-in.svg" alt={t('packet:action.deliver.single')} />
        </Button>
        <PacketDeliveryModal
            deliver={deliver}
            packets={packets}
            packetIdVerification={null}
            onDeliveryComplete={() => closeModal()}
        />
    </Fragment>
})
