import {observer} from "mobx-react-lite";
import {FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {PasswordRecoveryRoute} from "@app/PasswordRecovery/PasswordRecoveryRoutes";
import {TimeChecker} from "@app/Sign/pages/SignInPage/TimeChecker/TimeChecker";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {Loading} from "@common/components/Loading/Loading";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {CenteredLayout} from "@common/layouts/CenteredLayout/CenteredLayout";

import './signInPage.scss';

type SignInFormData = {
    email: string;
    password: string;
}

export const SignInPage: FC = observer(() => {
    const {t} = useTranslation();
    const form = useForm<SignInFormData>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const appContext = useAppContext();
    const navigate = useAppNavigate();

    useEffect(() => {
        if (appContext.user.isLoggedIn) {
            navigate(DashboardRoute);
        } else {
            const logoutReason = appContext.user.logoutReason;
            if (logoutReason) {
                appToast.info(t(logoutReason));
            }
            form.setFocus('email');
        }
    }, [appContext.user.isLoggedIn, appContext.user.logoutReason, form, navigate, t]);

    const showAppErrorToast = useShowErrorToast(appToast, 'viewSignForm:error', 'viewSignForm:error.api');

    const onSubmit = useBlockingCallback((unblock, {email, password}: SignInFormData) => {
        setIsSubmitting(true);
        appContext.authorizator.login(email, password)
            .then(() => {
                navigate(DashboardRoute);
            })
            .catch((error: unknown) => {
                showAppErrorToast(error as Error);
                setIsSubmitting(false);
            })
            .finally(unblock);
    }, [appContext.authorizator, navigate, showAppErrorToast]);

    const formId = 'loginForm';

    if (appContext.user.isLoggedIn) {
        return null;
    }

    return <CenteredLayout className="sign-in-page">
        <TimeChecker />
        <Loading active={isSubmitting}>
            <form id={formId} onSubmit={form.handleSubmit(onSubmit)} className="login-form">
                <FormGroup
                    name="email"
                    label={t('viewSignForm:email')}
                    formId={formId}
                    registerOptions={{required: t<string>('viewSignForm:error.email')}}
                    form={form}
                />
                <FormGroup
                    name="password"
                    label={t('viewSignForm:password')}
                    type="password"
                    formId={formId}
                    registerOptions={{required: t<string>('viewSignForm:error.password')}}
                    form={form}
                />
                <SubmitFormGroup name="send" value={t('viewSignForm:send')} formId={formId} />
            </form>
            <div className="sign-in-page__renew-password">
                <AppLink to={PasswordRecoveryRoute} title={t('viewSignForm:action.renewPassword.helper')}>
                    {t('viewSignForm:action.renewPassword.label')}
                </AppLink>
            </div>
        </Loading>
    </CenteredLayout>
})
