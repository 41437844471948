import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";
import {wait} from "@common/utils/wait";

const CONNECTIVITY_CHECK_ENDPOINT = 'https://connectivitycheck.gstatic.com/generate_204';
const CONNECTIVITY_CHECK_TIMEOUT = 5 * 1000; // 5 seconds timeout

export const checkConnection = async (): Promise<boolean> => {
    return Promise.race([
        fetch(CONNECTIVITY_CHECK_ENDPOINT, {method: HttpMethod.HEAD, mode: 'no-cors'})
            .then(() => true)
            .catch(() => false),
        wait(CONNECTIVITY_CHECK_TIMEOUT).then(() => false),
    ]);
}
