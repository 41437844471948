import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect} from "react";
import {UseFormReturn, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    PacketUndeliveryFormData
} from "@app/Packet/components/PacketDelivery/components/PacketUndeliveryModal/PacketUndeliveryForm/PacketUndeliveryFormData";
import {undeliveryReasonToString} from "@app/Packet/components/PacketDelivery/components/PacketUndeliveryModal/undeliveryReasonToString";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {
    PacketUndeliveryReason,
    PacketUndeliveryReturnToSender
} from "@app/Packet/components/PacketDelivery/model/PacketUndelivery";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";

import './packetUndeliveryForm.scss';

export type PacketUndeliveryFormProps = {
    form: UseFormReturn<PacketUndeliveryFormData>;
    packet: DeliveryPacket;
}

const OTHER_UNDELIVERY_REASON_MIN_LENGTH = 3;

export const PacketUndeliveryForm: FC<PacketUndeliveryFormProps> = observer(({form, packet}) => {
    const {t} = useTranslation();

    const selectedReason = useWatch({control: form.control, name: 'reason'});

    useEffect(() => {
        if (selectedReason === PacketUndeliveryReason.OTHER) {
            form.setFocus('otherReason');
        }
        if (selectedReason === PacketUndeliveryReason.NO_MONEY) {
            form.setValue('returnToSender', PacketUndeliveryReturnToSender.NO);
        }
    }, [form, selectedReason]);

    const isOtherReasonRequired = selectedReason === PacketUndeliveryReason.OTHER;
    const isReturnToSenderRequired = selectedReason && selectedReason !== PacketUndeliveryReason.NO_MONEY;

    const validateOtherReason = useCallback((value: string) => {
        return !isOtherReasonRequired
            || value.trim().length >= OTHER_UNDELIVERY_REASON_MIN_LENGTH
            || t<string>('viewPacketDelivery:undelivery.otherReason.error.required');
    }, [isOtherReasonRequired, t]);

    const validateReturnToSender = useCallback((value: PacketUndeliveryReturnToSender|null) => {
        return !isReturnToSenderRequired || value !== null || t<string>('viewPacketDelivery:undelivery.returnToSender.error.required');
    }, [isReturnToSenderRequired, t]);

    const renderReasonOption = (reason: PacketUndeliveryReason, showErrors?: boolean) => (
        <FormGroup
            name="reason"
            type="radio"
            label={undeliveryReasonToString(reason, t)}
            inputId={`packetUndeliveryForm-reason-${reason}`}
            inputOptions={{value: reason}}
            showErrors={showErrors === true}
            registerOptions={showErrors ? {required: t<string>('viewPacketDelivery:undelivery.reason.error.required')} : undefined}
            form={form}
        />
    )

    const formId = 'packetUndeliveryForm';

    return <div className="packet-undelivery-form" data-xid="packet-undelivery-form">
        <div>
            <div><label>{t('viewPacketDelivery:undelivery.reason.label')}</label></div>
            {packet.isCod && !packet.paid && renderReasonOption(PacketUndeliveryReason.NO_MONEY)}
            {renderReasonOption(PacketUndeliveryReason.PACKET_REFUSED)}
            {renderReasonOption(PacketUndeliveryReason.DELIVERY_ERROR)}
            {renderReasonOption(PacketUndeliveryReason.OTHER, true)}
            {isOtherReasonRequired && <div className="packet-undelivery-form-other-reason">
                <FormGroup
                    name="otherReason"
                    form={form}
                    inputOptions={{placeholder: t('viewPacketDelivery:undelivery.otherReason.placeholder')}}
                    registerOptions={{validate: validateOtherReason}}
                    formId={formId}
                />
            </div>}
        </div>
        {isReturnToSenderRequired && <div>
            <div><label>{t('viewPacketDelivery:undelivery.returnToSender.label')}</label></div>
            <FormGroup
                name="returnToSender"
                type="radio"
                label={t('core:boolean.yes')}
                inputId={`packetUndeliveryForm-returnToSender-yes`}
                inputOptions={{value: PacketUndeliveryReturnToSender.YES}}
                showErrors={false}
                form={form}
            />
            <FormGroup
                name="returnToSender"
                type="radio"
                label={t('core:boolean.no')}
                inputId={`packetUndeliveryForm-returnToSender-no`}
                inputOptions={{value: PacketUndeliveryReturnToSender.NO}}
                registerOptions={{validate: validateReturnToSender}}
                form={form}
            />
        </div>}
    </div>
})
