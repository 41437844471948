
import {useTranslation} from "react-i18next";

import {PaginatedDataManager} from "@common/components/grids/PaginatedDataManager";
import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";
import {appToast} from "@common/components/Toast/toastOpener";
import {useCsvDownload} from "@common/hooks/useCsvDownload";

export const usePaginatedGridExport = <ItemType, RequestType, ExtendedResponseType>(
    manager: PaginatedDataManager<ItemType, RequestType, ExtendedResponseType>,
    maxExportItems: number,
) => {
    const {t} = useTranslation();
    const csvDownload = useCsvDownload();

    return async (
        downloadManager: PaginatedDataManager<ItemType, RequestType, ExtendedResponseType>,
        filename: string,
        rowDataGenerator: (item: ItemType) => Record<string, string>,
    ): Promise<void> => {

        if (manager.totalCount > maxExportItems) {
            appToast.error(t(
                'global:grid.export.maxItemsCount',
                {count: manager.totalCount, maxCount: maxExportItems}
            ));
            return;
        }

        downloadManager.filteringRequest = manager.filteringRequest;
        if (downloadManager instanceof SortablePaginatedDataManager && manager instanceof SortablePaginatedDataManager) {
            downloadManager.sorting = manager.sorting;
        }

        try {
            await csvDownload(filename, downloadManager, rowDataGenerator);
        } catch (error: unknown) {
            appToast.error(t('global:grid.export.genericError'));
        }
    }
}
