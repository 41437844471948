import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {createCommissionKnownErrorCodes} from "@app/Commission/api/commissionEndpoints";
import {
    isCanNotCreateInvoiceApiErrorItem, isInvalidInvoicedAmountApiErrorItem,
    isInvalidInvoicedCurrencyApiErrorItem, isIssueDateOutOfRangeApiErrorItem
} from "@app/Commission/model/CreateCommissionInvoiceApiErrorItems";
import {moneyToString} from "@common/components/MoneyFormatter/moneyToString";
import {appToast} from "@common/components/Toast/toastOpener";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {getFirstErrorItem} from "@common/utils/api/getFirstErrorItem";

export const useHandleCreateCommissionInvoiceError = () => {
    const {t} = useTranslation();
    const showAppErrorToast = useShowErrorToast(appToast, 'invoice:error', 'invoice:error.general');
    const dateFormatter = useDateFormatter();

    return useCallback((error: ApiError) => {
        const firstError = getFirstErrorItem(error);
        if (firstError) {
            switch (firstError.code) {
                case createCommissionKnownErrorCodes.canNotCreateInvoice:
                    showAppErrorToast(error, {
                        allowedUntilDayOfMonth: isCanNotCreateInvoiceApiErrorItem(firstError)
                            ? firstError.variables.allowedUntilDayOfMonth : 15,
                    });
                    return;
                case createCommissionKnownErrorCodes.invalidInvoicedCurrency:
                    showAppErrorToast(error, {
                        invoicedAmount: isInvalidInvoicedCurrencyApiErrorItem(firstError)
                            ? moneyToString({
                                amount: firstError.variables.invoicedAmount,
                                currency: firstError.variables.currency,
                            })
                            : undefined,
                        invoicingBranchCurrency: isInvalidInvoicedCurrencyApiErrorItem(firstError)
                            ? firstError.variables.invoicingCurrency : undefined,
                    });
                    return;
                case createCommissionKnownErrorCodes.invalidInvoicedAmount:
                    showAppErrorToast(error, {
                        invoicedAmount: isInvalidInvoicedAmountApiErrorItem(firstError)
                            ? moneyToString({
                                amount: firstError.variables.invoicedAmount,
                                currency: firstError.variables.currency,
                            })
                            : undefined,
                        unpaidCommissionSum: isInvalidInvoicedAmountApiErrorItem(firstError)
                            ? moneyToString({
                                amount: firstError.variables.unpaidCommissionSum,
                                currency: firstError.variables.currency,
                            })
                            : undefined,
                    });
                    return;
                case createCommissionKnownErrorCodes.issuedDateOutOfRange:
                    if (isIssueDateOutOfRangeApiErrorItem(firstError)) {
                        appToast.error(t('invoice:error.badDateWithRange', {
                            allowedFrom: dateFormatter(firstError.variables.allowedFrom, DateFormatType.DATE_FORMAT),
                            allowedUntil: dateFormatter(firstError.variables.allowedUntil, DateFormatType.DATE_FORMAT),
                            given: dateFormatter(firstError.variables.given, DateFormatType.DATE_FORMAT),
                        }));
                    } else {
                        appToast.error(t('invoice:error.badDate'));
                    }
                    return;
                case createCommissionKnownErrorCodes.invalidArgument: {
                    const invalidField = firstError.field;
                    if (invalidField === 'issuedDate') {
                        appToast.error(t('invoice:error.badDate'));
                    } else if (invalidField && ['invoiceNumber', 'variableSymbol'].includes(invalidField)) {
                        appToast.error(t('invoice:error.invalidFieldValue', {
                            fieldName: t(`invoice:property.invoiceData.${invalidField}`),
                        }));
                    } else {
                        appToast.error(t('invoice:error.invalidFieldValue', {
                            fieldName: invalidField || 'non-identified',
                        }));
                    }
                    return;
                }
                default:
                    showAppErrorToast(error);
            }
        } else {
            showAppErrorToast(error);
        }
    }, [dateFormatter, showAppErrorToast, t]);
}
