import {Fragment} from "react";
import {useTranslation} from "react-i18next";

import {
    InventoryRequiredBranchEventContent
} from "@app/BranchEvent/components/InventoryRequiredBranchEvent/InventoryRequiredBranchEventContent";
import {InventoryRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {ModalBranchEventComponent} from "@app/BranchEvent/model/ModalBranchEventComponent";
import {ManagedInventoryRoute} from "@app/Inventory/InventoryRoutes";
import {Button} from "@common/components/Button/Button";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {AppLink} from "@common/components/routing/AppLink/AppLink";

export const InventoryRequiredBranchEventModal: ModalBranchEventComponent<InventoryRequiredBranchEvent>= ({event, onClose}) => {
    const {t} = useTranslation();

    return <BaseModal
        show={true}
        onHide={onClose}
        header={t('branchEvent:timeForInventoryTitle')}
        footer={<Fragment>
            <AppLink variant="secondary" to={ManagedInventoryRoute}>{t('branchEvent:goToInventory')}</AppLink>
            <Button onClick={onClose} variant="primary">{t('branchEvent:close')}</Button>
        </Fragment>}
    >
        <InventoryRequiredBranchEventContent event={event} />
    </BaseModal>
}
