import {ErrorMessage} from "@hookform/error-message";
import classNames from "classnames";
import {DefaultTFuncReturn} from "i18next";
import {Fragment, KeyboardEventHandler, MouseEventHandler, ReactElement, useCallback, useRef, useState} from "react";
import ContentEditable from "react-contenteditable";
import {FieldErrors, FieldPath, FieldValues, Path, UseControllerReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Button} from "@common/components/Button/Button";
import {InsertLink, InsertLinkModal} from "@common/components/forms/WysiwygInput/InsertLinkModal/InsertLinkModal";
import {WysiwygInputCustomControl} from "@common/components/forms/WysiwygInput/WysiwygInputCustomControl";
import {useSelection} from "@common/hooks/useSelection";

import './wysiwygInput.scss';

export type WysiwygInputProps<FormDataType extends FieldValues, TName extends FieldPath<FormDataType> = FieldPath<FormDataType>> = {
    allowLinks?: boolean;
    className?: string;
    customControls?: WysiwygInputCustomControl[];
    disabled?: boolean;
    formController: UseControllerReturn<FormDataType, TName>;
    formId?: string;
    inputId?: string;
    label?: DefaultTFuncReturn|ReactElement|ReactElement[];
    name: Path<FormDataType>;
}

export const WysiwygInput = <FormDataType extends FieldValues, TName extends FieldPath<FormDataType> = FieldPath<FormDataType>>({
    allowLinks = true,
    className,
    customControls,
    disabled = false,
    formController,
    formId,
    inputId,
    label,
    name,
}: WysiwygInputProps<FormDataType, TName>): ReactElement|null => {
    const {t} = useTranslation();
    const {field, formState} = formController;

    const [storeSelection, restoreSelection] = useSelection();
    const [insertLinkModalOpened, setInsertLinkModalOpened] = useState<boolean>(false);
    const innerRef = useRef<HTMLDivElement|null>(null);

    const onSelect = useCallback(() => {
        storeSelection();
    }, [storeSelection]);

    const restoreSelectionWrapper = useCallback(() => {
        return restoreSelection(innerRef.current);
    }, [restoreSelection]);

    const boldText: MouseEventHandler<HTMLElement> = useCallback((event) => {
        event.preventDefault();
        restoreSelectionWrapper();
        document.execCommand('bold', false);
    }, [restoreSelectionWrapper]);

    const italicText: MouseEventHandler<HTMLElement> = useCallback((event) => {
        event.preventDefault();
        restoreSelectionWrapper();
        document.execCommand('italic', false);
    }, [restoreSelectionWrapper]);

    const openInsertLinkModal: MouseEventHandler<HTMLElement> = useCallback((event) => {
        event.preventDefault();
        setInsertLinkModalOpened(true);
    }, []);

    const closeInsertLinkModal = useCallback(() => {
        setInsertLinkModalOpened(false);
        restoreSelectionWrapper();
    }, [restoreSelectionWrapper]);

    const insertLink = useCallback((link: InsertLink) => {
        setInsertLinkModalOpened(false);
        const range = restoreSelectionWrapper();
        if (range && range.commonAncestorContainer && range.commonAncestorContainer.parentNode && range.commonAncestorContainer.parentNode.nodeName === 'A') {
            range.selectNode(range.commonAncestorContainer.parentNode);
            document.execCommand('unlink');
        }
        document.execCommand(
            'insertHTML',
            false,
            `<a href="${encodeURI(link.url)}">${link.text}</a>`
        );
    }, [restoreSelectionWrapper]);

    const onKeyPress: KeyboardEventHandler<HTMLDivElement> = useCallback( (event) => {
        if (event.key === 'Enter') {
            document.execCommand('formatBlock', false, 'p');
        }
    }, []);

    const renderedCustomControls = customControls ? customControls.map((CustomControl) => {
        return <CustomControl key={CustomControl.displayName} restoreSelection={restoreSelectionWrapper} />
    }) : null;

    inputId = inputId || (formId ? `${formId}-${name}` : name);

    return <Fragment>
        <div className={classNames('form-group', 'wysiwyg-input', className)}>
            <div className="form-group-label">{label && <label htmlFor={inputId}>{label}</label>}</div>
            <div>
                <Button variant="secondary" onClick={boldText} disabled={disabled}>{t('wysiwyg:toolbar.bold')}</Button>
                <Button variant="secondary" onClick={italicText} disabled={disabled}>{t('wysiwyg:toolbar.italic')}</Button>
                {allowLinks && <Button variant="secondary" onClick={openInsertLinkModal} disabled={disabled}>
                    {t('wysiwyg:toolbar.link')}
                </Button>}
                {renderedCustomControls}
                <ContentEditable
                    html={field.value}
                    innerRef={innerRef}
                    className="form-control wysiwyg-input_editable"
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    onSelect={onSelect}
                    onKeyPress={onKeyPress}
                    id={inputId}
                    disabled={disabled}
                />
            </div>
        </div>
        <div className="form-group-errors">
            <ErrorMessage
                name={field.name}
                errors={formState.errors as FieldErrors}
                as={<div className="alert alert-danger"/>}
            />
        </div>
        <InsertLinkModal
            show={insertLinkModalOpened}
            onInsert={insertLink}
            onClose={closeInsertLinkModal}
        />
    </Fragment>
}
