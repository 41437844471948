import {TFunction} from "i18next";

import {PacketUndeliveryReason} from "@app/Packet/components/PacketDelivery/model/PacketUndelivery";

export const undeliveryReasonToString = (reason: PacketUndeliveryReason, t: TFunction, otherReason?: string): string => {
    switch (reason) {
        case PacketUndeliveryReason.NO_MONEY:
            return t('viewPacketDelivery:undelivery.reason.options.noMoney');
        case PacketUndeliveryReason.PACKET_REFUSED:
            return t('viewPacketDelivery:undelivery.reason.options.packetRefused');
        case PacketUndeliveryReason.DELIVERY_ERROR:
            return t('viewPacketDelivery:undelivery.reason.options.deliveryError');
        case PacketUndeliveryReason.OTHER:
            return otherReason || t('viewPacketDelivery:undelivery.reason.options.other');
    }
}
