import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {BranchInventoryListRoute} from "@app/Inventory/InventoryRoutes";
import {managedCashRegisterList} from "@app/Transaction/api/cashRegisterApi";
import {ManagedCashRegister} from "@app/Transaction/model/CashRegister/ManagedCashRegister";
import {BranchCardTransactionRoute} from "@app/Transaction/TransactionModuleRoutes";
import {GridSorter} from "@common/components/grids/GridSorter/GridSorter";
import {useSorter} from "@common/components/grids/GridSorter/useSorter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {useSingleBranchId} from "@common/hooks/useSingleBranchId";

import './managedCardTransactionPage.scss';

export const ManagedCardTransactionPage: FC = () => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const branchId = useSingleBranchId();
    const navigate = useAppNavigate();
    const [managedCashRegisters, setManagedCashRegisters] = useState<ManagedCashRegister[]|null|false>(null);
    const showAppErrorToast = useShowErrorToast(appToast);

    useEffect(() => {
        if (branchId) {
            navigate({route: BranchInventoryListRoute, params: {branchId}});
        } else if (managedCashRegisters === null) {
            managedCashRegisterList(appContext.api)
                .then((response) => {
                    setManagedCashRegisters(response.items);
                })
                .catch((error: Error) => {
                    showAppErrorToast(error);
                    setManagedCashRegisters(false);
                });
        }
    }, [branchId, appContext.api, managedCashRegisters, navigate, showAppErrorToast]);

    const sorter = useSorter<ManagedCashRegister, 'branchName'>(
        'managedCardTransactionList',
        {id: 'branchName'},
        {branchName: (managedCashRegister) => managedCashRegister.branch.name},
    );

    if (branchId) {
        return null;
    }

    if (managedCashRegisters) {
        managedCashRegisters.sort(sorter.sort);
    }

    return <PageContent className="managed-card-transaction-page">
        <PageHeader subTitle={t('viewTransaction:managedCardTransaction.subTitle')}>
            {t('viewTransaction:managedCardTransaction.header')}
        </PageHeader>
        <Loading active={managedCashRegisters === null}>
            {managedCashRegisters && <GridTable totalCount={managedCashRegisters.length}>
                <thead>
                    <tr>
                        <th><GridSorter sortId="branchName" sorter={sorter}>
                            {t('cashRegister:property.branchName')}
                        </GridSorter></th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {managedCashRegisters.map((cashRegister) => (<tr key={cashRegister.branch.id}>
                        <td className="managed-card-transaction-page__branch-name">
                            <AppLink to={{route: BranchCardTransactionRoute, params: {branchId: cashRegister.branch.id}}}>
                                {cashRegister.branch.name}
                            </AppLink>
                        </td>
                        <td className="managed-card-transaction-page__show-detail">
                            <AppLink to={{route: BranchCardTransactionRoute, params: {branchId: cashRegister.branch.id}}}>
                                {t('viewTransaction:managedCardTransaction.grid.showDetail')}
                            </AppLink>
                        </td>
                    </tr>))}
                </tbody>
            </GridTable>}
        </Loading>
    </PageContent>
}
