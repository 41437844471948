import {observer} from "mobx-react-lite";
import {FC, useContext, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {
    MultiDayBranchClosingModal
} from "@app/OpeningHours/components/ExceptionActions/MultiDayBranchClosingModal/MultiDayBranchClosingModal";
import {
    OneDayExceptionModal
} from "@app/OpeningHours/components/ExceptionActions/OneDayExceptionModal/OneDayExceptionModal";
import {useOpeningHoursLimits} from "@app/OpeningHours/components/ExceptionActions/useOpeningHoursLimits";
import {
    RegularOpeningHoursEditModal
} from "@app/OpeningHours/components/RegularOpeningHours/RegularOpeningHoursEditModal/RegularOpeningHoursEditModal";
import {PlannedChange} from "@app/OpeningHours/model/PlannedChange";

export type PlannedChangeEditProps = {
    plannedChange: PlannedChange;
}

export const PlannedChangeEdit: FC<PlannedChangeEditProps> = observer(({plannedChange}) => {
    const {t} = useTranslation();
    const ability = useContext(AbilityContext);
    const [oneDayExceptionModalOpened, setOneDayExceptionModalOpened] = useState<boolean>(false);
    const [multiDayBranchClosingModalOpened, setMultiDayBranchClosingModalOpened] = useState<boolean>(false);
    const [regularOpeningHoursEditModalOpened, setRegularOpeningHoursEditModalOpened] = useState<boolean>(false);

    const {firstSelectableDate} = useOpeningHoursLimits();

    if (!ability.can(Action.MANAGE, Subject.BRANCH_HOURS)) {
        return null;
    }

    if (plannedChange.date < firstSelectableDate) {
        return <td>{t('openingHours:page.plannedChanges.edit.readOnly')}</td>
    }

    switch (plannedChange.type) {
        case 'exception': return <td>
            <Button variant="link" onClick={() => setOneDayExceptionModalOpened(true)} data-xid="planned-change-edit">
                {t('openingHours:page.plannedChanges.edit.button')}
            </Button>
            {oneDayExceptionModalOpened && <OneDayExceptionModal
                editDate={plannedChange.date}
                show={oneDayExceptionModalOpened}
                onHide={() => setOneDayExceptionModalOpened(false)}
            />}
        </td>
        case 'closed': return <td>
            <Button variant="link" onClick={() => setMultiDayBranchClosingModalOpened(true)} data-xid="planned-change-edit">
                {t('openingHours:page.plannedChanges.edit.button')}
            </Button>
            {multiDayBranchClosingModalOpened && <MultiDayBranchClosingModal
                startDate={plannedChange.date}
                endDate={plannedChange.endDate}
                show={multiDayBranchClosingModalOpened}
                onHide={() => setMultiDayBranchClosingModalOpened(false)}
            />}
        </td>
        case 'regularUpdate': return <td>
            <Button variant="link" onClick={() => setRegularOpeningHoursEditModalOpened(true)} data-xid="planned-change-edit">
                {t('openingHours:page.plannedChanges.edit.button')}
            </Button>
            {regularOpeningHoursEditModalOpened && <RegularOpeningHoursEditModal
                changeDate={plannedChange.date}
                show={regularOpeningHoursEditModalOpened}
                onHide={() => setRegularOpeningHoursEditModalOpened(false)}
            />}
        </td>
    }
})
