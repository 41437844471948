import {observer} from "mobx-react-lite";
import {useEffect, useRef, useState} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {detail} from "@app/Branch/api/branchApi";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useSingleBranchTo} from "@common/hooks/useSingleBranchTo";
import {ApiError} from "@common/model/errors/ApiError";
import {FCWithChildren} from "@common/model/FCWithChildren";
import {HttpStatusCode} from "@common/model/HttpStatusCode";
import {ParametricReactRoute, SimpleReactRoute} from "@common/model/ReactRoute";

export type BranchIdCheckerProps = {
    branchId: string;
    detailRoute: ParametricReactRoute<'branchId'>;
    fallbackRoute: SimpleReactRoute;
}

enum BranchIdState {
    NOT_CHECKED,
    CHECKED_OK,
    CHECKED_NOT_OK,
}

export const BranchIdChecker: FCWithChildren<BranchIdCheckerProps> = observer(({branchId, children, detailRoute, fallbackRoute}) => {
    const {api, user} = useAppContext();
    const navigate = useAppNavigate();
    const branchDetailTo = useSingleBranchTo();

    const checking = useRef<boolean>(false);
    const lastCheckedBranchId = useRef<string|null>(branchId);
    const [state, setState] = useState<BranchIdState>(
        branchId === user.branchId ? BranchIdState.CHECKED_OK : BranchIdState.NOT_CHECKED
    );

    useEffect(() => {
        if (lastCheckedBranchId.current !== branchId) {
            setState(BranchIdState.NOT_CHECKED);
        }
    }, [branchId]);

    useEffect(() => {
        if (state === BranchIdState.NOT_CHECKED) {
            checking.current = true;
            detail(branchId, api)
                .then(() => {
                    setState(BranchIdState.CHECKED_OK);
                })
                .catch((error: ApiError) => {
                    setState(BranchIdState.CHECKED_NOT_OK);
                    if (error.code === HttpStatusCode.GONE) {
                        navigate(branchDetailTo(detailRoute, fallbackRoute));
                    } else {
                        navigate(fallbackRoute);
                    }
                })
                .finally(() => {
                    checking.current = false;
                    lastCheckedBranchId.current = branchId;
                });
        }
    }, [api, branchDetailTo, branchId, detailRoute, fallbackRoute, navigate, state, user.branchId]);

    if (state !== BranchIdState.CHECKED_OK) {
        return null;
    }

    return children;
});

BranchIdChecker.displayName = 'BranchIdChecker';
