import classNames from "classnames";
import {ReactElement} from "react";

import {FCWithChildren} from "@common/model/FCWithChildren";

import './loading.scss';

export type LoadingProps = {
    active?: boolean;
    className?: string;
    emptyWhenActive?: boolean;
    light?: boolean;
    title?: string;
}

export const Loading: FCWithChildren<LoadingProps, ReactElement|Iterable<ReactElement|boolean|null>|boolean|null> = ({
    active,
    children,
    className,
    emptyWhenActive = false,
    light = false,
    title,
}) => {
    return <div className={classNames('loading', className, {'loading--active': active})} title={title}>
        {active && <div className="loading-overlay">
            <div className={`spinner-border text-${light ? 'white' : 'danger'}`} role="status" />
        </div>}
        {(!emptyWhenActive || !active) && children}
    </div>
}
