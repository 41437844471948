import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {CardPaymentError} from "@app/CardPayment/components/CardPaymentError/CardPaymentError";
import {
    CardPaymentReceipts
} from "@app/CardPayment/components/CardPaymentReceipts/CardPaymentReceipts";
import {
    SignatureVerificationModal
} from "@app/CardPayment/components/SignatureVerificationModal/SignatureVerificationModal";
import {useCardPayment} from "@app/CardPayment/hooks/useCardPayment";
import {CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {
    PacketAlert
} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/DeliveryAlert/PacketAlert/PacketAlert";
import {SinglePacketInfo} from "@app/Packet/components/PacketDelivery/components/SinglePacketInfo/SinglePacketInfo";
import {useLastTransactionId} from "@app/Packet/components/PacketDelivery/hooks/useLastTransactionId";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {modalToast} from "@common/components/Toast/toastOpener";

import './moneyRefundModal.scss';

export type MoneyRefundModalProps = {
    packet: DeliveryPacket|undefined;
    onClose: (error?: Error) => void;
}

export const MoneyRefundModal: FC<MoneyRefundModalProps> = observer(({onClose, packet}) => {
    const {t} = useTranslation();

    const [lastTransactionId, setLastTransactionId] = useState<string|null|undefined>(undefined);
    const [running, setRunning] = useState<boolean>(false);

    useLastTransactionId(packet, setLastTransactionId, onClose, setRunning);

    const onCancellingSuccess = useCallback(() => {
        if (packet && packet.isCod) {
            packet.unpay();
        }
        modalToast.success(t('viewPacketDelivery:moneyRefund.success.finished'), {toastId: 'moneyRefundSuccessful'});
    }, [packet, t]);

    const {
        cancelPayment,
        cardPaymentState,
        confirmSignature,
        resetPaymentState,
    } = useCardPayment(() => false, onCancellingSuccess, setRunning, packet, lastTransactionId);

    useEffect(() => {
        if (!packet) {
            resetPaymentState();
            setRunning(false);
            setLastTransactionId(undefined);
        }
    }, [packet, resetPaymentState]);

    if (!packet) {
        return null;
    }

    return <BaseModal
        show={true}
        onHide={() => false}
        header={t('viewPacketDelivery:moneyRefund.header')}
        footer={<Fragment>
            {lastTransactionId !== undefined && cardPaymentState.status === CardPaymentStatus.CANCELLING && <Fragment>
                <CardPaymentReceipts cardPaymentState={cardPaymentState} checkInitialTransactionStatus={false} toastInModal={true} />
                <CardPaymentError
                    cardPaymentState={cardPaymentState}
                    cancelPayment={cancelPayment}
                    showCancelledPayment={false}
                    toastInModal={true}
                />
            </Fragment>}
            {lastTransactionId !== undefined && cardPaymentState.status !== CardPaymentStatus.CANCELLING && <Button
                onClick={cancelPayment}
                variant="success"
                disabled={running}
                data-xid="refund-payment"
            >
                {t('viewPacketDelivery:moneyRefund.action.refund')}
            </Button>}
            <Button onClick={() => onClose()} variant="outline-danger" disabled={running}>
                {t('viewPacketDelivery:moneyRefund.action.cancel')}
            </Button>
        </Fragment>}
        loading={running}
        height="auto"
        disableOutsideClose={true}
        closeButton={false}
        className="money-refund-modal"
        xid="money-refund-modal"
    >
        <SinglePacketInfo packet={packet} isDelivering={false} />
        {lastTransactionId !== undefined && <PacketAlert
            title={t('viewPacketDelivery:moneyRefund.alert.title')}
            description={t<string>('viewPacketDelivery:moneyRefund.alert.description')}
        />}
        <SignatureVerificationModal
            cardPaymentState={cardPaymentState}
            onSignatureConfirmed={confirmSignature}
            onSignatureRejected={cancelPayment}
        />
    </BaseModal>

})
