import {observer} from "mobx-react-lite";
import {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardBranchCountry} from "@app/AppContext/model/DashboardBranchCountry";
import {TERM_AND_CONDITIONS_MANUAL_PAGES} from "@app/config";
import {pageDetail} from "@app/Manuals/api/manualsApi";
import {ManualContent} from "@app/Manuals/components/ManualContent/ManualContent";
import {ManualPage} from "@app/Manuals/model/ManualPage";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {appToast} from "@common/components/Toast/toastOpener";

export const TermsAndConditionsPage: FC = observer(() => {
    const {t} = useTranslation();
    const {api, dashboardInfo} = useAppContext();

    const [manualPage, setManualPage] = useState<ManualPage|null|false>(null);

    useEffect(() => {
        if (manualPage === null) {
            const branchCountry = dashboardInfo.branch ? dashboardInfo.branch.country : DashboardBranchCountry.CZ;
            const manualPageId = TERM_AND_CONDITIONS_MANUAL_PAGES[branchCountry];
            pageDetail(manualPageId, api)
                .then(setManualPage)
                .catch((error: Error) => {
                    appToast.error(t(error.message));
                    setManualPage(false);
                });
        }
    }, [api, t, manualPage, dashboardInfo.branch]);

    if (manualPage === false) {
        return null;
    }

    return <PageContent className="terms-and-conditions">
        <Loading active={manualPage === null}>
            {manualPage && <Fragment>
                <PageHeader>{manualPage.title.rendered}</PageHeader>
                <ManualContent content={manualPage.content} />
            </Fragment>}
        </Loading>
    </PageContent>

})
