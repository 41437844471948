import {useMemo} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {OpeningHoursContext} from "@app/OpeningHours/context/openingHoursContext";
import {OpeningHoursManager} from "@app/OpeningHours/context/OpeningHoursManager";
import {FCWithChildren} from "@common/model/FCWithChildren";

export const OpeningHoursContextProvider: FCWithChildren = ({children}) => {
    const appContext = useAppContext();

    const openingHours = useMemo(
        () => new OpeningHoursManager(appContext.api),
        [appContext.api]
    );

    return <OpeningHoursContext.Provider value={openingHours}>
        {children}
    </OpeningHoursContext.Provider>
}
