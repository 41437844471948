import {useCallback, useEffect, useMemo, useState} from "react";
import {useForm} from "react-hook-form";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {getReturnShipment, receiveReturnShipment} from "@app/Packet/api/packetApi";
import {
    SurpriseCodeModalState,
    SurpriseModalFormData,
    UsedCode,
    UseSurpriseModal
} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/SurpriseCodeModal/SurpriseCodeModalTypes";
import {SearchFormResult} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useLocalStorageRef} from "@common/hooks/useLocalStorageRef";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

const REPEATED_USE_INTERVAL = 3 * 60; // 3 minutes

export const useSurpriseCodeModal: UseSurpriseModal = ({onError, onFinish, onResponseReceived, onSubmitting, onVerified, password}) => {
    const appContext = useAppContext();

    const initialState = useMemo<SurpriseCodeModalState>(() => ({
        modalOpened: false,
        eshopName: null,
        clientCanPostPackets: true,
        usedCode: undefined,
    }), []);
    const [state, setState] = useState<SurpriseCodeModalState>(initialState);
    const [usedCodes, persistUsedCodes] = useLocalStorageRef<UsedCode[]>('ui-surpriseCodeModal-usedCodes', []);
    const form = useForm<SurpriseModalFormData>({defaultValues: {customerName: ''}});

    const showAppErrorToast = useShowErrorToast(appToast, 'viewSurpriseCode:error');
    const showModalErrorToast = useShowErrorToast(modalToast, 'viewSurpriseCode:error');

    useEffect(() => {
        const expires = new Date();
        expires.setSeconds(expires.getSeconds() - REPEATED_USE_INTERVAL);
        usedCodes.current = usedCodes.current.filter((usedCode) => usedCode.usedAt >= expires);
        persistUsedCodes();

        getReturnShipment({eshopCode: password}, appContext.api)
            .then((response) => {
                onVerified(password);
                setState({
                    modalOpened: true,
                    eshopName: response.eshopName,
                    clientCanPostPackets: response.clientCanPostPackets,
                    usedCode: usedCodes.current.find((usedCode) => usedCode.code === password),
                });
            })
            .catch((error: Error) => {
                onError(password, () => {
                    showAppErrorToast(error);
                });
            })
    }, [appContext.api, onError, onVerified, password, persistUsedCodes, showAppErrorToast, usedCodes]);

    useEffect(() => {
        if (state.modalOpened) {
            window.setTimeout(() => {
                form.setFocus('customerName');
            }, 0);
        }
    }, [form, state.modalOpened]);

    const receive = useBlockingCallback((unblock, {customerName}: SurpriseModalFormData) => {
        onSubmitting(true);
        receiveReturnShipment({
            eshopCode: password,
            customerName,
        }, appContext.api)
            .then((response) => {
                usedCodes.current.unshift({code: password, usedAt: new Date(), barcode: response.barcode});
                persistUsedCodes();
                onResponseReceived(response);
            })
            .catch((error: Error) => {
                showModalErrorToast(error);
            })
            .finally(() => {
                onSubmitting(false);
                unblock();
            })
    }, [appContext.api, onResponseReceived, onSubmitting, password, persistUsedCodes, showModalErrorToast, usedCodes]);

    const close = useCallback(() => {
        setState(initialState);
        onFinish(SearchFormResult.RETURN_SHIPMENT);
    }, [initialState, onFinish]);

    const createNew = useCallback(() => {
        setState({
            ...state,
            usedCode: undefined,
        });
    }, [state]);

    return {
        state,
        receive,
        close,
        form,
        createNew,
    }
}
