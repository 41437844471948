import {ParametricReactRoute, SimpleReactRoute} from "@common/model/ReactRoute";

export const BranchIndexRoute: SimpleReactRoute = {
    // Reserved for future use
    path: '',
}

export const BranchSettingsRoute: SimpleReactRoute = {
    path: 'settings',
}

export const ManagedStatsRoute: SimpleReactRoute = {
    path: 'stats',
}

export const BranchStatsRoute: ParametricReactRoute<'branchId'> = {
    path: 'stats/:branchId',
    paramNames: ['branchId'],
}

export const BranchQualityRoute: SimpleReactRoute = {
    path: 'rating',
}

export const BranchRoutes = [
    BranchIndexRoute,
    ManagedStatsRoute,
    BranchStatsRoute,
    BranchSettingsRoute,
    BranchQualityRoute,
];
