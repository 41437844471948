import {Api} from "@app/AppContext/classes/Api/Api";
import {
    cancelPacketDeliveryEndpoint, codInfoEndpoint, confirmPresenceEndpoint, deliverEndpoint, deliveryDetailEndpoint,
    detailEndpoint, getProlongInfoEndpoint, getReturnShipmentEndpoint, notifyEndpoint, prolongEndpoint, receiveB2CEndpoint,
    receiveC2CEndpoint, receiveClaimFromCustomerEndpoint, receiveEndpoint, receiveReturnShipmentEndpoint, reportIssueEndpoint,
    setPacketShelfEndpoint, trackingEndpoint, webPaymentLinkEndpoint
} from "@app/Packet/api/packetEndpoints";
import {
    CancelPacketDeliveryRequest, ConfirmPresenceRequest, GetPacketProlongInfoResponse, GetProlongInfoRequest,
    GetReturnShipmentRequest, GetReturnShipmentResponse, NotifyPacketRequest, PacketCodInfoResponse, PacketDeliverRequest,
    PacketDetailRequest, PacketDetailResponse, PacketNotifyResponse, PacketReceiveResponse, PacketReportIssueRequest,
    PacketTrackingRequest, PacketTrackingResponse, PacketWebPaymentLinkRequest, ProlongPacketRequest, ReceivePacketRequest,
    ReceiveReturnShipmentRequest, ReceiveReturnShipmentResponse, ReceiveWithPasswordRequest, SetPacketShelfRequest,
    WebPaymentLinkResponse
} from "@app/Packet/model/PacketRequestsAndResponses";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const confirmPresence = async (request: ConfirmPresenceRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({endpoint: confirmPresenceEndpoint, payload: request});
}

export const deliver = async (request: PacketDeliverRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({endpoint: deliverEndpoint, payload: request});
}

export const detail = async (request: PacketDetailRequest, api: Api): Promise<PacketDetailResponse> => {
    const {searchStr, ...packetRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(detailEndpoint, {searchStr}),
        queryParams: createQueryParamsFromRequest(packetRequest),
    });
}

export const deliveryDetail = async (request: PacketDetailRequest, abortController: AbortController|undefined, api: Api): Promise<PacketDetailResponse> => {
    const {searchStr, ...packetRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(deliveryDetailEndpoint, {searchStr}),
        queryParams: createQueryParamsFromRequest(packetRequest),
        abortController,
    });
}

export const getCodInfo = async (barcode: string, api: Api): Promise<PacketCodInfoResponse> => {
    return api.call(endpointWithRouteParams(codInfoEndpoint, {barcode}));
}

export const getProlongInfo = async (request: GetProlongInfoRequest, api: Api): Promise<GetPacketProlongInfoResponse> => {
    return api.call({
        endpoint: getProlongInfoEndpoint,
        queryParams: createQueryParamsFromRequest<GetProlongInfoRequest>(request),
    });
}

export const getReturnShipment = async (request: GetReturnShipmentRequest, api: Api): Promise<GetReturnShipmentResponse> => {
    return api.call({
        endpoint: getReturnShipmentEndpoint,
        queryParams: createQueryParamsFromRequest<GetReturnShipmentRequest>(request),
    });
}

export const notify = async (request: NotifyPacketRequest, api: Api): Promise<PacketNotifyResponse> => {
    const {dryRun, ...notifyRequest} = request;

    return api.call({
        endpoint: notifyEndpoint,
        payload: notifyRequest,
        queryParams: createQueryParamsFromRequest({dryRun}),
    });
}

export const prolong = async (request: ProlongPacketRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({endpoint: prolongEndpoint, payload: request})
}

export const receive = async (request: ReceivePacketRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({endpoint: receiveEndpoint, payload: request})
}

export const reportIssue = async (request: PacketReportIssueRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({endpoint: reportIssueEndpoint, payload: request});
}

export const receiveClaimFromCustomer = async (request: ReceiveWithPasswordRequest, api: Api): Promise<PacketReceiveResponse> => {
    return api.call({endpoint: receiveClaimFromCustomerEndpoint, payload: request});
}

export const receiveB2C = async (request: ReceiveWithPasswordRequest, api: Api): Promise<PacketReceiveResponse> => {
    return api.call({endpoint: receiveB2CEndpoint, payload: request});
}

export const receiveC2C = async (request: ReceiveWithPasswordRequest, api: Api): Promise<PacketReceiveResponse> => {
    return api.call({endpoint: receiveC2CEndpoint, payload: request});
}

export const receiveReturnShipment = async (request: ReceiveReturnShipmentRequest, api: Api): Promise<ReceiveReturnShipmentResponse> => {
    return api.call({endpoint: receiveReturnShipmentEndpoint, payload: request});
}

export const setPacketShelf = async (request: SetPacketShelfRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({endpoint: setPacketShelfEndpoint, payload: request});
}

export const tracking = async (request: PacketTrackingRequest, api: Api): Promise<PacketTrackingResponse> => {
    return api.call(endpointWithRouteParams(trackingEndpoint, request));
}

export const cancelPacketDelivery = async (request: CancelPacketDeliveryRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: cancelPacketDeliveryEndpoint,
        payload: request,
    });
}

export const webPaymentLink = async (request: PacketWebPaymentLinkRequest, api: Api): Promise<WebPaymentLinkResponse> => {
    return api.call({
        endpoint: webPaymentLinkEndpoint,
        payload: request,
    });
}
