import {FC, Fragment, useCallback, useRef} from "react";
import {Button} from "react-bootstrap";
import {UseFormReturn, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {NewMessageFormFields, OutgoingMessageFormData} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {Img} from "@common/components/Img/Img";
import {appToast} from "@common/components/Toast/toastOpener";

export type CSVRecipientsUploadProps = {
    form: UseFormReturn<OutgoingMessageFormData>;
}

export const CSVRecipientsUpload: FC<CSVRecipientsUploadProps> = ({form}) => {
    const {t} = useTranslation();
    const inputRef = useRef<HTMLInputElement|null>(null);

    const {register, setValue} = form;
    const watchCSVRecipients = useWatch({control: form.control, name: NewMessageFormFields.csvRecipients});
    const {ref, ...rest} = register(NewMessageFormFields.csvRecipients);

    const open = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, []);

    const clear = useCallback(() => {
        setValue(NewMessageFormFields.csvRecipients, null);
    }, [setValue]);

    const uploadedFile = watchCSVRecipients && watchCSVRecipients.item(0);

    if (uploadedFile) {
        const suffix = uploadedFile.name.split('.').pop();
        if (!suffix || suffix !== 'csv') {
            setValue(NewMessageFormFields.csvRecipients, null);
            appToast.error(t('message:tabs.content.new.recipientFileNotSupported'));
        }
    }

    return <div className="csv-recipients-upload">
        {!uploadedFile && <Button onClick={open}>{t('message:tabs.content.new.csvRecipients.upload')}</Button>}
        {uploadedFile && <Fragment>
            <span className="csv-recipients-filename">{uploadedFile.name}</span>
            <Img
                className="csv-recipients-clear"
                src="/images/icons/ico/ico-delete.svg"
                alt={t('message:tabs.content.new.attachment.clear')}
                title={t('message:tabs.content.new.attachment.clear')}
                onClick={clear}
            />
        </Fragment>}
        <input
            ref={(e) => {
                inputRef.current = e;
                ref(e);
            }}
            {...rest}
            type="file"
            name={NewMessageFormFields.csvRecipients}
        />
    </div>
}
