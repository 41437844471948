import {observer} from "mobx-react-lite";
import {FC, useCallback, useContext} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {removeCommissionInvoice} from "@app/Commission/api/commissionApi";
import {InvoiceGridHeader} from "@app/Commission/components/InvoiceGrid/InvoiceGridHeader/InvoiceGridHeader";
import {InvoiceListManager} from "@app/Commission/components/InvoiceGrid/InvoiceListManager";
import {Confirm} from "@common/components/Confirm/Confirm";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";
import {Img} from "@common/components/Img/Img";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {NumberFormatter} from "@common/components/NumberFormatter/NumberFormatter";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './invoiceGrid.scss';

export type InvoiceGridProps = {
    manager: InvoiceListManager;
    onRemove?: () => Promise<void>;
}

export const InvoiceGrid: FC<InvoiceGridProps> = observer(({manager, onRemove}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const ability = useContext(AbilityContext);

    const showVariableSymbol = !appContext.dashboardInfo.isRoBranch;
    const showDownloadColumn = ability.can(Action.DOWNLOAD, Subject.COMMISSION_INVOICE);
    const showAppErrorToast = useShowErrorToast(appToast);

    const doRemove = useCallback(async (invoiceToken: string) => {
        try {
            await removeCommissionInvoice(invoiceToken, appContext.api);
            if (onRemove) {
                await onRemove();
            }
            appToast.success(t("invoice:success.remove"))
        } catch (error: unknown) {
            showAppErrorToast(error as Error);
        }
        await manager.reload();
    }, [appContext.api, manager, onRemove, showAppErrorToast, t]);

    return <PaginatedGrid manager={manager} className="invoice-grid">
        <InvoiceGridHeader
            manager={manager}
            showDownloadColumn={showDownloadColumn}
            showVariableSymbol={showVariableSymbol}
        />
        <tbody>
            {manager.items.map((invoice, index) => (<tr key={`${invoice.makeDate}-${index}`}>
                <td>
                    <DateFormatter date={invoice.makeDate} />
                    <div className="invoice-grid__make-date-due-date">
                        <DateFormatter date={invoice.dueDate}/>
                    </div>
                </td>
                <td className="invoice-grid__type">{t(`invoice:value.type.${invoice.type}`)}</td>
                <td>
                    <NumberFormatter numeric={invoice.number} decimalScale={0} />
                    {showVariableSymbol && <div className="invoice-grid__number-variable-symbol">
                        <NumberFormatter numeric={invoice.variableSymbol} decimalScale={0} />
                    </div>}
                </td>
                {showVariableSymbol && <td className="invoice-grid__variable-symbol">
                    <NumberFormatter numeric={invoice.variableSymbol} decimalScale={0} />
                </td>}
                <td>
                    <MoneyFormatter amount={invoice.total} currencyCode={invoice.currency} />
                    <div className="invoice-grid__total-payment-status">
                        {invoice.paymentStatus}
                    </div>
                </td>
                <td className="invoice-grid__payment-status">{invoice.paymentStatus}</td>
                <td className="invoice-grid__due-date"><DateFormatter date={invoice.dueDate} /></td>
                {showDownloadColumn && <td>
                    <span className="invoice-grid__invoice-actions">
                        <Button href={invoice.urlPdf} title={t('invoice:action.urlPdfShow')} target="_blank" variant="secondary">
                            <Img src="/images/icons/ico/ico-attachment.svg" alt={t('invoice:action.urlPdfShow')} />
                        </Button>
                        <Can I={Action.DELETE} a={Subject.COMMISSION_INVOICE}>
                            {invoice.isRemovable && <Confirm
                                message={t('invoice:confirm.remove.message')}
                                header={t('invoice:confirm.remove.header')}
                                onSuccess={() => doRemove(invoice.token)}
                                title={t('invoice:action.remove')}
                                variant="danger"
                            ><Img src="/images/icons/ico/ico-trash-white.svg" alt={t('invoice:action.remove')} /></Confirm>}
                        </Can>
                    </span>
                </td>}
            </tr>))}
        </tbody>
    </PaginatedGrid>
})
