import {TFunction} from "i18next";

import {getStringVariable} from "@app/Devices/components/ReaderModal/utils/getVariable";
import {ReaderJobTitleType, resolveSubSection} from "@app/Devices/components/ReaderModal/utils/resolveSubSection";
import {ReaderItemResult} from "@app/Devices/model/ReaderItemResult";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";

export const jobItemTitle = (item: ReaderItemResult, job: ReaderJob, t: TFunction): string|null => {
    const titleVariable = getStringVariable(item.variables, 'title');

    if (titleVariable) {
        let title: string;

        switch (job.type) {
            case ReaderJobType.BRANCH_INVENTORY:
                title = 'inventory'; break;
            case ReaderJobType.RECEIVE_PACKETS:
                title = 'receivePackets'; break;
            case ReaderJobType.RECEIVE_PARCELS:
                title = 'receiveParcels'; break;
            case ReaderJobType.PACKETS_TO_PARCELS:
                title = 'packetsToParcels'; break;
            default:
                title = 'unableToProcess'; break;
        }

        return t(
            `reader:${title}.${resolveSubSection(ReaderJobTitleType.PARCEL, job)}.${titleVariable}`,
            {...item.variables}
        );
    }

    return null;
}
