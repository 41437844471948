import {format as dateFnsFormatter, Locale} from "date-fns";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useDateFnsLocale} from "@common/hooks/useDateFnsLocale";
import {parseDate} from "@common/utils/parseDate";

export enum DateFormatType {
    DATE_FORMAT,
    DATE_FORMAT_WITHOUT_YEAR,
    TIME_FORMAT,
    TIME_NO_DATE_FORMAT,
    TIME_DATE_IN_PARENTHESES_FORMAT,
    DAY_OF_WEEK,
    DATE_FORMAT_WITH_DOW,
}

export type DateFormatter = (date: Date|string|undefined, format: DateFormatType|string) => string;

export const useDateFormatter = (): DateFormatter => {
    const {t} = useTranslation();

    const formatString = useCallback((format: DateFormatType|string): string => {
        if (format in DateFormatType) {
            const formatKey = formatTypeTranslationKey(format as DateFormatType);

            switch (format) {
                case DateFormatType.DATE_FORMAT:
                    return t(formatKey, {defaultValue: 'dd. MM. yyyy'});
                case DateFormatType.DATE_FORMAT_WITHOUT_YEAR:
                    return t(formatKey, {defaultValue: 'dd. MM.'});
                case DateFormatType.TIME_FORMAT:
                    return t(formatKey, {defaultValue: 'dd. MM. yyyy, HH:mm'});
                case DateFormatType.TIME_NO_DATE_FORMAT:
                    return t(formatKey, {defaultValue: 'HH:mm'});
                case DateFormatType.TIME_DATE_IN_PARENTHESES_FORMAT:
                    return t(formatKey, {defaultValue: 'HH:mm\u00A0\\h (d. M. yyyy)'});
                case DateFormatType.DAY_OF_WEEK:
                    return 'eeee';
                case DateFormatType.DATE_FORMAT_WITH_DOW:
                    return `${t(formatKey, {defaultValue: 'dd. MM. yyyy'})} (eeee)`;
            }
        }

        return format as string;
    }, [t]);

    const locale: Locale = useDateFnsLocale();

    return useCallback((date, format) => {
        return dateFnsFormatter(
            parseDate(date),
            formatString(format),
            {locale}
        );
    }, [formatString, locale]);
}

export const formatTypeTranslationKey = (format: DateFormatType): string => {
    switch (format) {
        case DateFormatType.TIME_FORMAT:
            return 'core:dateTime.timeFormat';
        case DateFormatType.TIME_NO_DATE_FORMAT:
            return 'core:dateTime.timeNoDateFormat';
        case DateFormatType.TIME_DATE_IN_PARENTHESES_FORMAT:
            return 'core:dateTime.timeDateInParenthesesFormat';
        case DateFormatType.DATE_FORMAT_WITHOUT_YEAR:
            return 'core:dateTime.dateFormatWithoutYear';
        case DateFormatType.DATE_FORMAT:
        case DateFormatType.DATE_FORMAT_WITH_DOW:
        default:
            return 'core:dateTime.dateFormat';
    }
}
