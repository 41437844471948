import {Fragment} from "react";

import {useBranchEventMessage} from "@app/BranchEvent/hooks/useBranchEventMessage";
import {HolidayBranchHoursConfirmationRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventComponent} from "@app/BranchEvent/model/BranchEventComponent";
import {
    PublicHolidayConfirmModal
} from "@app/OpeningHours/components/PublicHolidayConfirmModal/PublicHolidayConfirmModal";

export const HolidayBranchHoursConfirmationRequiredBranchEventContent: BranchEventComponent<HolidayBranchHoursConfirmationRequiredBranchEvent> = ({event}) => {
    const message = useBranchEventMessage(event);

    return <Fragment>
        {message}{' '}
        <PublicHolidayConfirmModal startDate={event.variables.startDate} endDate={event.variables.endDate} />
    </Fragment>
}
