import {BranchQualityDateRange} from "@app/Branch/model/BranchQuality/BranchQualityDateRange";
import {
    GeneralBranchQualityDetailItem
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/GeneralBranchQualityDetail";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";

export type PacketsBranchQualityDetailItem = GeneralBranchQualityDetailItem & {
    packetBarcodes: string[]|null;
}

export type PacketsBranchQualityDetail = {
    dateRange: BranchQualityDateRange;
    type: BranchEventCode.PACKET_EXPEDITION_MISSING;
    items: PacketsBranchQualityDetailItem[];
}

export const packetsBranchQualityDetailTypes = [
    BranchEventCode.PACKET_EXPEDITION_MISSING,
];
