import {Api} from "@app/AppContext/classes/Api/Api";
import {DeviceGatewayResolver} from "@app/AppContext/classes/DeviceGatewayResolver";
import {UserState} from "@app/AppContext/classes/User/model/UserData";
import {ImpersonatedUserIdentity} from "@app/AppContext/classes/User/model/UserIdentity";
import {User} from "@app/AppContext/classes/User/User";
import {expiresAtFromResponse} from "@app/AppContext/utils/expiresAtFromResponse";
import {authenticate, authenticateAs, refresh} from "@app/Sign/api/oauthApi";
import {getAuthOptionsTech} from "@common/utils/api/getAuthOptionsTech";

export class Authorizator {
    public constructor(
        private user: User,
        private api: Api,
        private deviceGatewayResolver: DeviceGatewayResolver,
    ) {}

    public login = async (email: string, password: string): Promise<void> => {
        const deviceGateway = await this.deviceGatewayResolver.resolve();

        const response = await authenticate({
            email,
            password,
            options: {...deviceGateway, tech: await getAuthOptionsTech()}
        }, this.api);

        this.user.setData({
            state: UserState.AUTHENTICATED,
            data: {identity: response.identity, token: response.token},
            loggedInAt: new Date(),
            expiresAt: expiresAtFromResponse(response),
        });
    }

    public logout = (userAction: boolean = false): void => {
        this.user.setData({state: UserState.ANONYMOUS, logoutReason: userAction ? 'global:logoutSuccessful' : undefined});
    }

    public impersonate = async (token: string): Promise<void> => {
        const response = await authenticateAs({token}, this.api);

        const newIdentity: ImpersonatedUserIdentity = {
            ...response.identity,
            impersonationToken: token,
            originalRefreshToken: this.user.refreshToken(true),
        }

        this.logout();

        this.user.setData({
            state: UserState.AUTHENTICATED,
            data: {identity: newIdentity, token: response.token},
            loggedInAt: this.user.loggedInAt || new Date(),
            expiresAt: expiresAtFromResponse(response),
        });
    }

    public stopImpersonating = async (): Promise<boolean> => {
        const refreshToken = this.user.refreshToken(true);
        this.logout();

        if (refreshToken) {
            try {
                const response = await refresh({grantType: 'refresh_token', refreshToken}, this.api);
                this.user.setData({
                    state: UserState.AUTHENTICATED,
                    data: {identity: response.identity, token: response.token},
                    loggedInAt: this.user.loggedInAt || new Date(),
                    expiresAt: expiresAtFromResponse(response),
                });
                return true;
            } catch (error: unknown) {}
        }

        return false;
    }
}
