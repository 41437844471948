import {FC} from "react";

import {
    DeliveryAlert,
    DeliveryAlertType
} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/DeliveryAlert/DeliveryAlert";
import {
    hasPacketThisAlert
} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/hasPacketThisAlert";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";

export type PacketFlagsProps = {
    packet: DeliveryPacket;
}

export const PacketFlags: FC<PacketFlagsProps> = ({packet}) => {
    return <div className="packet-flags">
        {hasPacketThisAlert(packet, DeliveryAlertType.NO_UNPACKED_RETURN) && <DeliveryAlert
            type={DeliveryAlertType.NO_UNPACKED_RETURN}
            iconOnly={true}
        />}
        {hasPacketThisAlert(packet, DeliveryAlertType.ADULT_CONTENT) && <DeliveryAlert
            type={DeliveryAlertType.ADULT_CONTENT}
            iconOnly={true}
        />}
        {hasPacketThisAlert(packet, DeliveryAlertType.CARD_PAYMENT_FAILED) && <DeliveryAlert
            type={DeliveryAlertType.CARD_PAYMENT_FAILED}
            iconOnly={true}
        />}
    </div>

}
