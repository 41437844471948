
import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {pageDetail} from "@app/Manuals/api/manualsApi";
import {ManualContent} from "@app/Manuals/components/ManualContent/ManualContent";
import {useShowBenefitsPage} from "@app/Manuals/hooks/useShowBenefitsPage";
import {ManualPage} from "@app/Manuals/model/ManualPage";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {Button} from "@common/components/Button/Button";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";

export const BenefitsPage: FC = observer(() => {
    const {t} = useTranslation();
    const {api} = useAppContext();
    const navigate = useAppNavigate();

    const showBenefitsPage = useShowBenefitsPage();
    const [consentGiven, setConsentGiven] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [benefitsPage, setBenefitsPage] = useState<ManualPage|null|false>(null);

    useEffect(() => {
        if (!showBenefitsPage) {
            appToast.error(t('viewManual:benefits.error.notAvailable'));
            navigate(DashboardRoute);
            return;
        }
    }, [navigate, showBenefitsPage, t]);

    const giveConsent = useCallback(() => {
        setLoading(true);
        const benefitsPageId = t('support:benefitsPageId');
        pageDetail(benefitsPageId, api)
            .then((response) => {
                setBenefitsPage(response);
                setConsentGiven(true);
            })
            .catch((error: Error) => {
                appToast.error(t(error.message));
                setBenefitsPage(false);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [api, t]);

    if (!showBenefitsPage) {
        return null;
    }

    return <PageContent>
        <Loading active={loading}>
            {!consentGiven && <Fragment>
                <PageHeader subTitle={t('viewManual:benefits.subTitle')}>{t('viewManual:benefits.title')}</PageHeader>
                <p><strong>{t('viewManual:benefits.consent.description')}</strong></p>
                <Button onClick={giveConsent} variant="success">{t('viewManual:benefits.consent.button')}</Button>
            </Fragment>}
            {consentGiven && benefitsPage && <Fragment>
                <PageHeader>{benefitsPage.title.rendered}</PageHeader>
                <ManualContent content={benefitsPage.content} />
            </Fragment>}
        </Loading>
    </PageContent>
})
