import classNames from "classnames";
import {FC, Fragment, ReactElement, useCallback, useEffect, useRef, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {Api} from "@app/AppContext/classes/Api/Api";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {correctButUsedReceiveKnownErrorCodes} from "@app/Packet/api/packetEndpoints";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {isAlreadyReceivedApiErrorItem} from "@app/Packet/model/AlreadyReceivedApiErrorItem";
import {PacketReceiveResponse, ReceiveWithPasswordRequest} from "@app/Packet/model/PacketRequestsAndResponses";
import {PacketDetailRoute} from "@app/Packet/PacketRoutes";
import {
    PacketAtDestination
} from "@app/Packet/pages/DashboardPage/SearchForm/PacketAtDestination/PacketAtDestination";
import {
    OnPasswordHandlingError, OnPasswordHandlingVerified
} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {ReprintButton} from "@common/components/ReprintButton/ReprintButton";
import {appToast} from "@common/components/Toast/toastOpener";
import {TranslationWithHtmlParam} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {PrintResult, PrintResultType} from "@common/model/PrintResult";
import {getFirstErrorItem} from "@common/utils/api/getFirstErrorItem";
import {getPrintResultByType} from "@common/utils/api/getPrintResultByType";
import {renderComponentToString} from "@common/utils/renderComponentToString";

import './reprintablePasswordModal.scss';

export type ReprintablePasswordModalState = {
    modalOpened: boolean;
    printResult: PrintResult|null;
    barcode: string|null;
    isAtDestination: boolean;
}

export type ReprintablePasswordModalProps = {
    apiMethod: (request: ReceiveWithPasswordRequest, api: Api) => Promise<PacketReceiveResponse>,
    password: string;
    translationDomain: string;
    onFinish: () => void;
    onError: OnPasswordHandlingError;
    onVerified: OnPasswordHandlingVerified;
    renderChildren?: (state: ReprintablePasswordModalState) => ReactElement|null;
    loading?: boolean;
    className?: string;
};

export const ReprintablePasswordModal: FC<ReprintablePasswordModalProps> = ({
    apiMethod,
    className,
    loading,
    onError,
    onFinish,
    onVerified,
    password,
    renderChildren,
    translationDomain,
}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [state, setState] = useState<ReprintablePasswordModalState>({
        modalOpened: false,
        printResult: null,
        barcode: null,
        isAtDestination: false,
    });

    const showAppErrorToast = useShowErrorToast(appToast, `${translationDomain}:error`);

    useEffect(() => {
        apiMethod({password}, appContext.api)
            .then((response) => {
                onVerified(password);
                setState({
                    modalOpened: true,
                    printResult: getPrintResultByType(response, PrintResultType.DEPOT_LABEL),
                    barcode: response.barcode,
                    isAtDestination: response.isAtDestination,
                });
            })
            .catch((error: ApiError) => {
                const firstError = getFirstErrorItem(error);
                onError(
                    password,
                    () => {
                        const barcode = firstError && isAlreadyReceivedApiErrorItem(firstError)
                            ? firstError.variables.barcode : null;

                        if (barcode) {
                            const linkHref = appContext.router.href({route: PacketDetailRoute, params: {barcode}});
                            appToast.error(renderComponentToString(<TranslationWithHtmlParam
                                paramName="packetLink"
                                translationKey={`${translationDomain}:error.alreadyReceived`}
                                t={t}
                            >
                                <FormattedPacketBarcode barcode={barcode} linkHref={linkHref} />
                            </TranslationWithHtmlParam>), undefined, true);
                        } else {
                            showAppErrorToast(error);
                        }
                    },
                    firstError ? Object.keys(correctButUsedReceiveKnownErrorCodes).includes(firstError.code) : false
                );
            });

    }, [appContext.api, apiMethod, onError, onVerified, password, t, translationDomain, appContext.router, showAppErrorToast]);

    const buttonRef = useRef<HTMLButtonElement>(null);
    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.focus();
        }
    });

    const close = useCallback(() => {
        setState({
            modalOpened: false,
            printResult: null,
            barcode: null,
            isAtDestination: false,
        });
        onFinish();
    }, [onFinish]);

    return <BaseModal
        show={state.modalOpened}
        onHide={close}
        loading={loading}
        header={t(`${translationDomain}:title`)}
        footer={<Fragment>
            {state.printResult && <ReprintButton printResult={state.printResult} toastInModal={true}>
                {t(`${translationDomain}:action.reprint`)}
            </ReprintButton>}
            <Button ref={buttonRef} onClick={close}>{t(`${translationDomain}:action.close`)}</Button>
        </Fragment>}
        className={classNames('reprintable-password-modal', className)}
        xid="reprintable-password-modal"
    >
        {state.barcode && <TranslationWithHtmlParam
            paramName="barcode"
            translationKey={`${translationDomain}:messageWithBarcode`}
            t={t}
            className="reprintable-password-modal__barcode-message"
        >
            <FormattedPacketBarcode
                barcode={state.barcode}
                withHighlight={true}
                linkHref={appContext.router.href({route: PacketDetailRoute, params: {barcode: state.barcode}})}
            />
        </TranslationWithHtmlParam>}
        {!state.barcode && t(`${translationDomain}:message`)}
        {renderChildren && renderChildren(state)}
        {state.isAtDestination && state.barcode && <PacketAtDestination barcode={state.barcode} />}
    </BaseModal>
}
