import {action, makeObservable, observable} from "mobx";

import {CardPaymentState, CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {DeliveryPacketVerification} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketVerification";
import {PacketDeliveryType} from "@app/Packet/model/PacketDeliveryType";
import {PacketDetailed} from "@app/Packet/model/PacketDetailed";
import {Sender} from "@app/Packet/model/Sender";

class BaseDeliveryPacket {
    public info: PacketDetailed;
    public sender?: Sender;

    public isDelivered: boolean;
    public requiresIdVerification = false;
    public requiresUndelivery = false;
    public deliveryOrder = -1;

    public verification?: DeliveryPacketVerification;

    constructor(
        packet: PacketDetailed,
        sender?: Sender,
        isDelivered: boolean = false,
    ) {
        this.info = packet;
        this.sender = sender;
        this.isDelivered = isDelivered;

        makeObservable(this, {
            verification: observable.ref,
            requiresIdVerification: observable,
            requiresUndelivery: observable,
            isDelivered: observable,
            deliver: action,
            undeliver: action,
            requireIdVerification: action,
            cancelIdVerificationRequest: action,
            requireUndelivery: action,
            cancelUndeliveryRequest: action,
        });
    }

    public deliver(verification?: DeliveryPacketVerification) {
        this.isDelivered = true;
        if (verification) {
            this.verification = verification;
            if (this.requiresIdVerification && verification.type === PacketDeliveryType.PERSONAL_ID) {
                this.requiresIdVerification = false;
            }
        }
    }

    public undeliver() {
        this.verification = undefined;
        this.isDelivered = false;
        this.requiresUndelivery = false;
    }

    public requireIdVerification() {
        this.requiresIdVerification = true;
    }

    public cancelIdVerificationRequest() {
        this.requiresIdVerification = false;
    }

    public requireUndelivery() {
        this.requiresUndelivery = true;
    }

    public cancelUndeliveryRequest() {
        this.requiresUndelivery = false;
    }
}

export class NoCodDeliveryPacket extends BaseDeliveryPacket{
    public readonly isCod = false;
}

export class CodDeliveryPacket extends BaseDeliveryPacket {
    public readonly isCod = true;
    public paid: boolean;
    public cardPaymentState?: CardPaymentState;

    constructor(
        packet: PacketDetailed,
        paid: boolean,
        sender?: Sender,
        isDelivered: boolean = false,
    ) {
        super(packet, sender, isDelivered);
        this.paid = paid;

        makeObservable(this, {
            paid: observable,
            cardPaymentState: observable,
            pay: action,
            unpay: action,
            updateCardPaymentState: action,
        });
    }

    get cardPaymentAllowed(): boolean {
        return this.sender !== undefined && this.sender.cardPaymentAllowed;
    }

    get codCashAmount(): string|undefined {
        return this.info.deliveryMeta.codCashAmount;
    }

    public pay() {
        this.paid = true;
    }

    public unpay() {
        this.paid = false;
    }

    public undeliver() {
        super.undeliver();
        this.unpay();
        this.cardPaymentState = {status: CardPaymentStatus.READY};
    }

    public updateCardPaymentState(newCardPaymentState: CardPaymentState) {
        this.cardPaymentState = newCardPaymentState;
    }
}

export type DeliveryPacket = NoCodDeliveryPacket | CodDeliveryPacket;

export const isDeliveryPacket = (packet: any): packet is DeliveryPacket => {
    return 'info' in packet;
}
