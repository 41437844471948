import {useTranslation} from "react-i18next";

import {TranslationWithHtmlParam} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {FCWithChildren} from "@common/model/FCWithChildren";

export type GridTableTotalCountProps = {
    count: number;
}

export const GridTableTotalCount: FCWithChildren<GridTableTotalCountProps> = ({children, count}) => {
    const {t} = useTranslation();

    if (count === 0) {
        return null;
    }

    return <div className="grid-table__total-count">
        <TranslationWithHtmlParam
            paramName="totalCount"
            translationKey="global:grid.totalCount"
            t={t}
        >
            <strong>{count}</strong>
        </TranslationWithHtmlParam>
        {children}
    </div>
}
