import classNames from "classnames";
import {FC} from "react";

import {Img} from "@common/components/Img/Img";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {To} from "@common/model/ReactRoute";

import './returnLink.scss';

export type ReturnLinkProps = {
    className?: string;
    label: string;
    to?: To;
    onClick?: () => void;
}

export const ReturnLink: FC<ReturnLinkProps> = ({className, label, onClick, to}) => {
    return <div className={classNames('return-link', className)}>
        <Img src="/images/icons/arr/arr-9-black-left.svg" alt={label} />{' '}
        {onClick && <a onClick={onClick}>{label}</a>}
        {to && <AppLink to={to}>{label}</AppLink>}
    </div>
}
