import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {PacketCardPaymentIcon} from "@app/Packet/components/PacketCardPaymentIcon/PacketCardPaymentIcon";
import {PacketDetailed, packetHasCod} from "@app/Packet/model/PacketDetailed";
import {Sender} from "@app/Packet/model/Sender";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";

import './packetDetailInfoCod.scss';

export type PacketDetailInfoCodProps = {
    packet: PacketDetailed;
    sender?: Sender;
}

export const PacketDetailInfoCod: FC<PacketDetailInfoCodProps> = ({packet, sender}) => {
    const {t} = useTranslation();
    const isCodPacket = packetHasCod(packet);

    return <div className="packet-detail-info-cod">
        {isCodPacket && <PacketCardPaymentIcon cardPaymentAllowed={sender !== undefined && sender.cardPaymentAllowed} />}
        <strong>
            {isCodPacket ? <Fragment>
                <MoneyFormatter amount={packet.cod} currencyCode={packet.currency} />
                {packet.isPaidByCard && <Fragment>
                    {' – '}{t('packet:property.hasCashOnDelivery.paidByCard')}
                </Fragment>}
            </Fragment> : t('packet:property.hasCashOnDelivery.noCashOnDelivery')}
        </strong>
    </div>
}
