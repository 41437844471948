import {observer} from "mobx-react-lite";
import {FC} from "react";
import {Badge} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {Img} from "@common/components/Img/Img";

import './receivedMessagesIcon.scss';

export const ReceivedMessagesIcon: FC = observer(() => {
    const {t} = useTranslation();
    const {dashboardMessages} = useAppContext();

    return <span
        className="icon icon--received-messages"
        title={t('message:tabs.received') + (dashboardMessages.unreadCount ? ` (${dashboardMessages.unreadCount})` : '')}
    >
        <Img src="/images/icons/ico/ico-mail.svg" alt={t('message:tabs.received')} />
        {dashboardMessages.unreadCount !== 0 && <Badge variant="danger" pill data-xid="unread-messages-count">
            {dashboardMessages.unreadCount}
        </Badge>}
    </span>
})
