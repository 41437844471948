export type SortRequestPropertyItem<AvailableSortKeys extends string> = {
    key: AvailableSortKeys;
    desc?: boolean;
}

export type SortRequestProperty<AvailableSortKeys extends string> = Array<SortRequestPropertyItem<AvailableSortKeys>>;

export type RequestWithSort<AvailableSortKeys extends string = string> = {
    sort?: SortRequestProperty<AvailableSortKeys>;
}

export const isRequestWithSort = (request: any): request is RequestWithSort => {
    return 'sort' in request;
}

export type SortKeysOfRequest<Request extends RequestWithSort> =
    Request extends RequestWithSort<infer AvailableSortKeys>
    ? AvailableSortKeys
    : unknown;

export type SortPropertyOfRequest<Request extends RequestWithSort> = SortRequestPropertyItem<SortKeysOfRequest<Request>>;
