import useResizeObserver from "@react-hook/resize-observer";
import useWindowScroll from "@react-hook/window-scroll";
import {useWindowHeight} from "@react-hook/window-size";
import {observer} from "mobx-react-lite";
import {FC, Fragment, RefObject, useCallback, useEffect, useRef, useState} from "react";

import {Capacity} from "@common/layouts/SecuredLayout/Footer/Capacity/Capacity";
import {Contacts} from "@common/layouts/SecuredLayout/Footer/Contacts/Contacts";

import "./footer.scss";

const MIN_WINDOW_HEIGHT = 768;
const FULL_FOOTER_MARGIN = 54;

export type FooterProps = {
    containerRef: RefObject<HTMLElement>;
}

export const Footer: FC<FooterProps> = observer(({containerRef}) => {
    const windowHeight = useWindowHeight();
    const scrollY = useWindowScroll();

    const footerRef = useRef<HTMLDivElement>(null);
    const [showFixed, setShowFixed] = useState<boolean>(false);

    const updateShowFixed = useCallback(() => {
        if (footerRef.current) {
            setShowFixed(windowHeight > MIN_WINDOW_HEIGHT && scrollY + windowHeight < footerRef.current.offsetTop + FULL_FOOTER_MARGIN);
        }
    }, [scrollY, windowHeight]);

    useEffect(() => {
        updateShowFixed();
    }, [updateShowFixed]);

    useResizeObserver(containerRef, updateShowFixed);

    return <Fragment>
        {showFixed && <div className="footer footer-fixed">
            <Capacity full={false} />
            <Contacts full={false} />
        </div>}
        <footer className="footer footer-full" ref={footerRef}>
            <Capacity full={true} />
            <Contacts full={true} />
        </footer>
    </Fragment>
})
