export const enumOptions = <EnumType extends {[key: number]: string}>(sourceEnum: EnumType): string[] => {
    const options: (string)[] = [];

    for (const option in sourceEnum) {
        if (Object.prototype.hasOwnProperty.call(sourceEnum, option)) {
            options.push(sourceEnum[option]);
        }
    }

    return options;
}
