import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {unpaidCommissionsSum} from "@app/Commission/api/commissionApi";
import {UnpaidCommissionsSumResponse} from "@app/Commission/model/CommissionRequestsAndResponses";
import {Button} from "@common/components/Button/Button";
import {DatePicker} from "@common/components/forms/DatePicker/DatePicker";
import {Loading} from "@common/components/Loading/Loading";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {appToast} from "@common/components/Toast/toastOpener";
import {DateFormatType} from "@common/hooks/useDateFormatter";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {SearchModifier} from "@common/model/requests/SearchRequestProperty";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './unpaidCommissionBox.scss';

export type UnpaidCommissionBoxProps = {
    branchId: string;
}

export const UnpaidCommissionBox: FC<UnpaidCommissionBoxProps> = ({branchId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [unpaidCommission, setUnpaidCommission] = useState<UnpaidCommissionsSumResponse|null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [upTo, setUpTo] = useState<Date>(new Date());

    const showAppErrorToast = useShowErrorToast(appToast);

    useEffect(() => {
        setLoading(true);
        unpaidCommissionsSum({branchId, upTo: [{value: upTo, modifier: SearchModifier.LTE}]}, appContext.api)
            .then(setUnpaidCommission)
            .catch((error: Error) => {
                showAppErrorToast(error);
                setUnpaidCommission(null);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [appContext.api, branchId, showAppErrorToast, upTo]);

    return <Loading active={loading} className="unpaid-commission-box">
        <div>
            <div className="unpaid-commission-box__label">
                {t('viewCommission:unpaidCommission.state')}
            </div>
            <div className="unpaid-commission-box__amount">
                {dashWhenEmpty(unpaidCommission && <MoneyFormatter
                    amount={unpaidCommission.sum.amount}
                    currencyCode={unpaidCommission.sum.currency}
                />)}
            </div>
            {unpaidCommission && unpaidCommission.seasonalBonus && <div className="unpaid-commission-box__bonus">
                <div className="unpaid-commission-box__label">
                    {t('viewCommission:unpaidCommission.bonus')}
                </div>
                <div className="unpaid-commission-box__amount">
                    <MoneyFormatter
                        amount={unpaidCommission.seasonalBonus.amount}
                        currencyCode={unpaidCommission.seasonalBonus.currency}
                    />
                </div>
            </div>}
        </div>
        <div className="unpaid-commission-box__controls">
            <DatePicker
                label={t('viewCommission:unpaidCommission.date')}
                dayPickerProps={{
                    mode: 'single',
                    selected: upTo,
                    disabled: [{after: new Date()}],
                    toMonth: new Date(),
                }}
                dateFormat={DateFormatType.TIME_FORMAT}
                name="date"
                formId="unpaidCommissionBoxForm"
                value={upTo}
                onDateSelect={setUpTo}
                showTimeSelect={true}
             />
            <div>
                <Button variant="secondary" onClick={() => setUpTo(new Date())}>
                    {t('viewCommission:action.reset')}
                </Button>
            </div>
        </div>
    </Loading>
}
