import {useContext} from "react";

import {GtmContext} from "@app/App/components/AppWrapper/GtmProvider/GtmContext";

export const useGtm = () => {
    const gtm = useContext(GtmContext);

    if (!gtm) {
        throw new Error('Trying to use an uninstantiated GTM');
    }

    return gtm;
}
