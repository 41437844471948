import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";

export enum ReaderJobTitleType {
    PARCEL,
    PACKET,
}

export const resolveSubSection = (titleType: ReaderJobTitleType, job: ReaderJob): string => {
    switch (titleType) {
        case ReaderJobTitleType.PARCEL:
            return job.type === ReaderJobType.RECEIVE_PACKETS ? 'parcelTitle' : 'parcelResult';
        case ReaderJobTitleType.PACKET:
            return 'packetResult'
    }
}
