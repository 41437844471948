import {FC} from "react";
import {useController, UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {NewMessageFormFields, OutgoingMessageFormData} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {DatePickerField} from "@common/components/forms/DatePicker/DatePickerField/DatePickerField";
import {DateFormatType} from "@common/hooks/useDateFormatter";

export type MessageDatesProps = {
    form: UseFormReturn<OutgoingMessageFormData>;
    formId: string;
}

export const MessageDates: FC<MessageDatesProps> = ({form, formId}) => {
    const {t} = useTranslation();

    const publishAtController = useController({
        control: form.control,
        name: NewMessageFormFields.publishAt,
        shouldUnregister: true,
        rules: {
            validate: (value, formValues) => {
                if (value && value <= new Date()) {
                    return t<string>('message:tabs.content.new.publishAt.validation.future');
                }
                if (value && formValues.hideAt && value >= formValues.hideAt) {
                    return t<string>('message:tabs.content.new.publishAt.validation.earlierThanHideAt');
                }
                return true;
            }
        }
    });

    const hideAtController = useController({
        control: form.control,
        name: NewMessageFormFields.hideAt,
        shouldUnregister: true,
        rules: {
            validate: (value, formValues) => {
                if (value && value <= new Date()) {
                    return t<string>('message:tabs.content.new.hideAt.validation.future');
                }
                if (value && formValues.publishAt && value <= formValues.publishAt) {
                    return t<string>('message:tabs.content.new.hideAt.validation.laterThanPublishAt');
                }
                return true;
            }
        }
    });

    return <div className="message-dates">
        <div>
            <DatePickerField
                formController={publishAtController}
                formId={formId}
                label={t('message:tabs.content.new.publishAt.label')}
                placeholder={t('message:tabs.content.new.publishAt.placeholder')}
                dayPickerProps={{
                    disabled: [{before: new Date()}],
                    fromMonth: new Date(),
                }}
                dateFormat={DateFormatType.TIME_FORMAT}
                name={NewMessageFormFields.publishAt}
                showTimeSelect={true}
            />
        </div>
        <div>
            <DatePickerField
                formController={hideAtController}
                formId={formId}
                label={t('message:tabs.content.new.hideAt.label')}
                placeholder={t('message:tabs.content.new.hideAt.placeholder')}
                dayPickerProps={{
                    disabled: [{before: new Date()}],
                    fromMonth: new Date(),
                }}
                dateFormat={DateFormatType.TIME_FORMAT}
                name={NewMessageFormFields.hideAt}
                showTimeSelect={true}
            />
        </div>
    </div>
}
