export enum CardPaymentTransactionStatus {
    CREATED,
    RUNNING,
    SUCCESS,
    ERROR,
    AWAITING_SIGNATURE_VERIFICATION,
}

export type CardPaymentTransaction = {
    status: CardPaymentTransactionStatus;
    id: string;
    message?: string;
    errorIdentifier?: string;
    signatureVerified?: boolean;
}
