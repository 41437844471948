import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    DeliveryAlertType
} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/DeliveryAlert/DeliveryAlert";
import {
    hasPacketThisAlert
} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/hasPacketThisAlert";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";
import {PacketIdVerification} from "@app/Packet/components/PacketDelivery/model/PacketIdVerification";
import {idCardTypeLabel} from "@packetModal/components/PacketIdVerificationModal/idCardTypeLabel";

import './packetIdVerificationView.scss';

export type PacketIdVerificationViewProps = {
    packetIdVerification: PacketIdVerification|null;
    packets: DeliveryPacketsCollection;
}

export const PacketIdVerificationView: FC<PacketIdVerificationViewProps> = observer(({packetIdVerification, packets}) => {
    const {t} = useTranslation();

    if (!packetIdVerification) {
        return null;
    }

    const hasPacketsRequiringIdVerification = packets.deliveryReady.filter(
        (packet) => hasPacketThisAlert(packet, DeliveryAlertType.ADULT_CONTENT)
    ).length > 0;

    if (!hasPacketsRequiringIdVerification) {
        return null;
    }

    return <div className="packet-id-verification-view">
        <div>
            <div className="packet-id-verification-view-label">{t('viewPacketDelivery:packetIdVerification.form.idCardType.label')}</div>
            <div>{t(idCardTypeLabel(packetIdVerification.idCardType))}</div>
        </div>
        <div>
            <div className="packet-id-verification-view-label">{t('viewPacketDelivery:packetIdVerification.form.name.label')}</div>
            <div>{packetIdVerification.name}</div>
        </div>
        <div>
            <div className="packet-id-verification-view-label">{t('viewPacketDelivery:packetIdVerification.form.idCardNumber.label')}</div>
            <div>{packetIdVerification.idCardNumber}</div>
        </div>
    </div>
})
