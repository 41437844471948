import {useCallback, useEffect, useRef, useState} from "react";

import {useActiveDataRefresh} from "@app/App/components/AppWrapper/DataSourceProvider/hooks/useActiveDataRefresh";
import {DataSource} from "@app/App/components/AppWrapper/DataSourceProvider/model/DataSource";
import {DataSourceHook} from "@app/App/components/AppWrapper/DataSourceProvider/model/DataSourceHook";
import {
    WebSocketDataMessageType
} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketDataMessageType";
import {
    WebSocketMessage
} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketMessage";
import {isWebSocketResultMessage} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketResultMessage";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {eventList} from "@app/Branch/api/branchApi";
import {useFeatureFlag} from "@common/hooks/useFeatureFlag";
import {wait} from "@common/utils/wait";

export const useBranchEventsDataSource = (): DataSourceHook => {
    const {api, branchEvents, user} = useAppContext();

    const branchEventsSourceFlag = useFeatureFlag('branchEventsSource', DataSource.AZAPI);

    const [branchEventsDataSource, setBranchEventsDataSource] = useState<DataSource>(
        user.isInternalUser ? DataSource.AZAPI : branchEventsSourceFlag
    );

    const recoverableWSFailure = useRef<boolean>(false);
    const restoreDataSource = useRef<boolean>(false);

    const onRefresh = useCallback(async () => {
        try {
            const response = await eventList(api);
            branchEvents.setEvents(response.items);
        } catch (error: unknown) {
            branchEvents.setEvents([]);
        }

        if (restoreDataSource.current) {
            setBranchEventsDataSource(DataSource.ADMIN_VM_BACKEND_WS);
            restoreDataSource.current = false;
        }

        if (recoverableWSFailure.current) {
            recoverableWSFailure.current = false;
            restoreDataSource.current = true;
        }
    }, [api, branchEvents]);

    useActiveDataRefresh(
        !user.isInternalUser && branchEventsDataSource === DataSource.AZAPI,
        onRefresh,
        {minutes: 10},
    );

    useEffect(() => {
        if (branchEvents.reloadRequired && branchEventsDataSource === DataSource.AZAPI) {
            // postpone reload by 1s
            void wait(1000).then(onRefresh);
        }
    }, [branchEvents.reloadRequired, branchEventsDataSource, onRefresh]);

    useEffect(() => {
        setBranchEventsDataSource(user.isInternalUser ? DataSource.AZAPI : branchEventsSourceFlag);
    }, [branchEventsSourceFlag, user.isInternalUser]);

    useEffect(() => {
        if (!user.isLoggedIn) {
            branchEvents.reset();
        }

        return () => {
            branchEvents.reset();
        }
    }, [branchEvents, user.isLoggedIn]);

    const onMessage = useCallback((message: WebSocketMessage) => {
        if (
            branchEventsDataSource !== DataSource.ADMIN_VM_BACKEND_WS
            || isWebSocketResultMessage(message)
            || message.type !== WebSocketDataMessageType.BRANCH_EVENT
        ) {
            return;
        }

        if (message.events.branch) {
            branchEvents.setBranchEvents(message.events.branch);
        } else if (message.events.user) {
            branchEvents.setUserEvents(message.events.user);
        }
    }, [branchEvents, branchEventsDataSource]);

    const onConnectionFailed = useCallback((isRecoverable: boolean) => {
        if (isRecoverable) {
            recoverableWSFailure.current = true;
        }
        setBranchEventsDataSource(DataSource.AZAPI);
    }, []);

    return [
        onMessage,
        onConnectionFailed,
        branchEventsDataSource === DataSource.ADMIN_VM_BACKEND_WS,
    ]
}
