import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {Badge} from "@common/layouts/SecuredLayout/Footer/Capacity/Badge/Badge";
import {CommissionBonusView} from "@common/layouts/SecuredLayout/Footer/Capacity/CommissionBonusView/CommissionBonusView";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './capacity.scss';

export type CapacityProps = {
    full: boolean;
}

export const Capacity: FC<CapacityProps> = observer(({full}) => {
    const {t} = useTranslation();
    const {dashboardInfo, user} = useAppContext();

    return <div className={classNames('capacity', full && 'capacity-full')}>
        <div className="capacity__header">
            {full && dashboardInfo.branch && <Fragment>
                {dashboardInfo.branch && dashboardInfo.branch.webUrl ? <a
                        href={dashboardInfo.branch.webUrl}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {dashboardInfo.branch.name}
                    </a> : dashboardInfo.branch.name
                }
            </Fragment>}
            {!full && <Fragment>
                <div>{t('global:footer.capacityUtilization')}</div>
                <div>
                    <Badge value={dashboardInfo.packetsUtilization || 0} />
                </div>
            </Fragment>}
        </div>
        {full && <Fragment>
            <div className="capacity__info">
                <div>
                    <div>{t('global:footer.id')}</div>
                    <div>{t('global:footer.capacityUtilization')}</div>
                </div>
                <div>
                    <div>{dashWhenEmpty(user.supportBranchId)}</div>
                    <div><Badge value={dashboardInfo.packetsUtilization || 0}/></div>
                </div>
            </div>
            {dashboardInfo.commissionBonus && <div className="capacity__commission-bonus">
                <CommissionBonusView commissionBonus={dashboardInfo.commissionBonus} />
            </div>}
        </Fragment>}
    </div>
})
