import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC, useCallback, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    InventoryPacketsListHeader
} from "@app/Inventory/pages/InventoryDetailPage/InventoryPacketsList/InventoryPacketsListHeader/InventoryPacketsListHeader";
import {
    InventoryPacketsListManager
} from "@app/Inventory/pages/InventoryDetailPage/InventoryPacketsList/InventoryPacketsListManager";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {printInventoryBadPackets, printInventoryBadPacketsPDF} from "@app/Print/api/printApi";
import {PacketStatusBadge} from "@common/components/Badges/PacketStatusBadge/PacketStatusBadge";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";
import {LoadingButton, LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {appToast} from "@common/components/Toast/toastOpener";
import {usePrint} from "@common/hooks/usePrint";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {downloadEncodedPdf} from "@common/utils/api/downloadEncodedPdf";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";
import {packetWholeName} from "@common/utils/packetWholeName";

import './inventoryPacketsList.scss';

export type InventoryPacketsListProps = {
    inventoryId: string;
}

export const InventoryPacketsList: FC<InventoryPacketsListProps> = observer(({inventoryId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const inventoryPacketsList = useMemo<InventoryPacketsListManager>(
        () => new InventoryPacketsListManager(inventoryId, appContext.api),
        [appContext.api, inventoryId]
    );
    const showAppErrorToast = useShowErrorToast(appToast, 'global:print.error', 'global:print.error.general');
    const handlePrint = usePrint();

    const onPrintPdf = useCallback<LoadingButtonOnClick>((stopLoading) => {
        printInventoryBadPacketsPDF({inventoryId}, appContext.api)
            .then((response) => {
                downloadEncodedPdf(response, t('viewInventory:detail.pdfFileName'));
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
            })
            .finally(stopLoading);
    }, [appContext.api, inventoryId, showAppErrorToast, t]);

    const onPrint = useCallback<LoadingButtonOnClick>((stopLoading) => {
        void handlePrint(printInventoryBadPackets({inventoryId}, appContext.api))
            .finally(stopLoading);
    }, [handlePrint, inventoryId, appContext.api]);

    return <div className="inventory-packets-list packet-grid">
        <div className="inventory-packets-list__header">
            <h4>{t('viewInventory:detail.gridHeader')}</h4>
            <div>
                <LoadingButton variant="secondary" onLoadingClick={onPrintPdf}>{t('parcel:action.printPdf')}</LoadingButton>
                <LoadingButton variant="secondary" onLoadingClick={onPrint}>{t('parcel:action.print')}</LoadingButton>
            </div>
        </div>
        <PaginatedGrid manager={inventoryPacketsList} className="packet-grid">
            <InventoryPacketsListHeader manager={inventoryPacketsList} />
            <tbody>
                {inventoryPacketsList.items.map((inventoryPacket) => {
                    return <tr key={inventoryPacket.barcode} className={classNames({
                        'inventory-packets-list__row-with-errors': inventoryPacket.hasError
                    })}>
                        <td className="packet-grid__barcode">
                            <FormattedPacketBarcode barcode={inventoryPacket.barcode} withHighlight={true}/>
                            <span className="packet-grid__barcode-status">
                                {inventoryPacket.status && <PacketStatusBadge status={inventoryPacket.status}/>}
                            </span>
                        </td>
                        <td className="inventory-packets-list__consigned-date">
                            {inventoryPacket.consignedDate && <DateFormatter
                                date={inventoryPacket.consignedDate}
                                showTime={true}
                            />}
                        </td>
                        <td className="packet-grid__sender">{inventoryPacket.senderName}</td>
                        <td className="packet-grid__recipient">
                            {packetWholeName(inventoryPacket)}
                            <div className=" packet-grid__sender packet-grid__recipient-sender">
                                {inventoryPacket.senderName}
                            </div>
                        </td>
                        <td className="packet-grid__address">{dashWhenEmpty(inventoryPacket.nameStreet)}</td>
                        <td className="packet-grid__status">
                            {dashWhenEmpty(inventoryPacket.status &&
                                <PacketStatusBadge status={inventoryPacket.status}/>)}
                        </td>
                        <td className="inventory-packets-list__shelf">{inventoryPacket.shelf}</td>
                        <td className="inventory-packets-list__result">
                            {dashWhenEmpty(inventoryPacket.result.code && t(
                                `reader:inventory.packetResult.${inventoryPacket.result.code}`,
                                inventoryPacket.result.variables
                            ))}
                        </td>
                    </tr>
                })}
            </tbody>
        </PaginatedGrid>
    </div>
})
