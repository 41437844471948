import {Api} from "@app/AppContext/classes/Api/Api";
import {
    branchInventoryListEndpoint, cancelInventoryEndpoint, confirmInventoryEndpoint,
    inventoryDetailEndpoint, inventoryPacketsListEndpoint, managedBranchInventoryListEndpoint
} from "@app/Inventory/api/inventoryEndpoints";
import {
    BranchInventoryListRequest, BranchInventoryListResponse, CancelOrConfirmInventoryRequest,
    InventoryDetailRequest, InventoryDetailResponse, InventoryPacketsListRequest,
    InventoryPacketsListResponse, ManagedBranchInventoryListResponse
} from "@app/Inventory/model/InventoryRequestsAndResponses";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const branchInventoryList = async (request: BranchInventoryListRequest, api: Api): Promise<BranchInventoryListResponse> => {
    const {branchId, ...branchInventoryListRequest} = request;

    return api.call({
        endpoint: endpointWithRouteParams(branchInventoryListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(branchInventoryListRequest),
    });
}

export const cancelInventory = async (request: CancelOrConfirmInventoryRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: cancelInventoryEndpoint,
        payload: request,
    });
}

export const confirmInventory = async (request: CancelOrConfirmInventoryRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: confirmInventoryEndpoint,
        payload: request,
    });
}

export const inventoryDetail = async (request: InventoryDetailRequest, api: Api): Promise<InventoryDetailResponse> => {
    const {inventoryId, ...inventoryDetailRequest} = request;

    return api.call({
        endpoint: endpointWithRouteParams(inventoryDetailEndpoint, {inventoryId}),
        queryParams: createQueryParamsFromRequest(inventoryDetailRequest),
    });
}

export const inventoryPacketsList = async (request: InventoryPacketsListRequest, api: Api): Promise<InventoryPacketsListResponse> => {
    const {inventoryId, ...inventoryPacketsListRequest} = request;

    return api.call({
        endpoint: endpointWithRouteParams(inventoryPacketsListEndpoint, {inventoryId}),
        queryParams: createQueryParamsFromRequest(inventoryPacketsListRequest),
    });
}

export const managedBranchInventoryList = async (api: Api): Promise<ManagedBranchInventoryListResponse> => {
    return api.call(managedBranchInventoryListEndpoint);
}
