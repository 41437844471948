import {observer} from "mobx-react-lite";
import {FC, ReactElement, useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {BranchCommissionRoute} from "@app/Commission/CommissionRoutes";
import {CommissionNavigation} from "@app/Commission/components/CommissionNavigation/CommissionNavigation";
import {
    CommissionBranchesManager
} from "@app/Commission/components/ManagedCommissionGrid/CommissionBranchesManager";
import {
    ManagedCommissionGrid,
    ManagedCommissionGridAction
} from "@app/Commission/components/ManagedCommissionGrid/ManagedCommissionGrid";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {useSingleBranchId} from "@common/hooks/useSingleBranchId";

import './managedCommissionPage.scss';

export const ManagedCommissionPage: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const branchId = useSingleBranchId();
    const navigate = useAppNavigate();
    const showAppErrorToast = useShowErrorToast(appToast);

    const commissionBranchesManager = useMemo<CommissionBranchesManager>(
        () => new CommissionBranchesManager(appContext.api),
        [appContext.api]
    );

    useEffect(() => {
        if (branchId) {
            navigate({route: BranchCommissionRoute, params: {branchId}});
        }
    }, [branchId, navigate, showAppErrorToast]);

    const renderAction = useCallback<ManagedCommissionGridAction>(({commissionBranch}): ReactElement|null => {
        if (!commissionBranch) {
            return null;
        }

        return <AppLink to={{route: BranchCommissionRoute, params: {branchId: commissionBranch.branchId}}}>
            {t('commission:action.viewDetail')}
        </AppLink>
    }, [t]);

    if (branchId) {
        return null;
    }

    return <PageContent className="managed-commission-page">
        <PageHeader branchId={appContext.user.branchId}>{t('viewCommission:managed.header')}</PageHeader>
        <CommissionNavigation />
        <ManagedCommissionGrid manager={commissionBranchesManager} renderAction={renderAction} />
    </PageContent>
})
