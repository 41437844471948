import {KnownErrorCodes} from "@app/AppContext/classes/Api/model/KnownErrorCode";

import {ApiBackend} from "./ApiBackend";
import {HttpMethod} from "./HttpMethod";

export type Endpoint<RouteParamNames extends string = string, ErrorCodes extends string = string> = {
    backend?: ApiBackend;
    method: HttpMethod;
    url: string;
    authenticate?: boolean;
    routeParamNames?: RouteParamNames[];
    knownErrorCodes?: KnownErrorCodes<ErrorCodes>;
    notFoundErrorResponse?: boolean;
    ignoreFailures?: boolean;
}

export const isEndpoint = (endpoint: any): endpoint is Endpoint => {
    return 'method' in endpoint && 'url' in endpoint;
}

export const NOT_FOUND_ERROR_CODE = 'notFound';
