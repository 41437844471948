import {FC} from "react";
import {Route, Routes} from "react-router-dom";

import {MicroDepotOperationalInfoRoute} from "@app/MicroDepot/MicroDepotRoutes";
import {
    MicroDepotOperationalInfoPage
} from "@app/MicroDepot/pages/MicroDepotOperationalInfoPage/MicroDepotOperationalInfoPage";

export const MicroDepot: FC = () => {
    return <Routes>
        <Route path={MicroDepotOperationalInfoRoute.path} element={<MicroDepotOperationalInfoPage />} />
    </Routes>
}
