import {FC, MutableRefObject, useCallback, useEffect, useState} from "react";
import {useForm, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {parseAmount} from "@common/utils/parseAmount";

import './cashCalculator.scss';

export type CashCalculatorProps = {
    amountToPay: string;
    currency: string;
    focus: boolean;
    focused: MutableRefObject<boolean>;
    saveAmountPaid: (newAmountPaid: string) => void;
    savedAmountPaid: string;
}

type CashCalculatorFormData = {
    amountPaid: string;
}

const AMOUNT_PAID_MAX_LENGTH = 6;

export const CashCalculator: FC<CashCalculatorProps> = ({
    amountToPay,
    currency,
    focus,
    focused,
    saveAmountPaid,
    savedAmountPaid,
}) => {
    const {t} = useTranslation();
    const [amountToReturn, setAmountToReturn] = useState<number|null>(null);

    const form = useForm<CashCalculatorFormData>({
        mode: 'onChange',
        defaultValues: {amountPaid: savedAmountPaid},
    });

    const amountPaid = useWatch({control: form.control, name: 'amountPaid'});

    useEffect(() => {
        const parsedAmountPaid = parseAmount(amountPaid);
        setAmountToReturn(parsedAmountPaid ? parsedAmountPaid - parseAmount(amountToPay) : null);
        saveAmountPaid(amountPaid);
    }, [amountPaid, amountToPay, saveAmountPaid]);

    useEffect(() => {
        if (focus && !focused.current) {
            form.setFocus('amountPaid');
        }
    }, [focus, focused, form]);

    const onFocus = useCallback(() => {
        focused.current = true;
    }, [focused]);

    const onBlur = useCallback(() => {
        focused.current = false;
    }, [focused]);

    return <div className="cash-calculator">
        <FormGroup
            form={form}
            name='amountPaid'
            label={t('viewPacketDelivery:cashCalculator.collectedCash.label')}
            formId="cashCalculator"
            inputOptions={{
                placeholder: t('viewPacketDelivery:cashCalculator.collectedCash.placeholder'),
                autoComplete: 'off',
                maxLength: AMOUNT_PAID_MAX_LENGTH,
                onFocus,
                onBlur,
            }}
        />
        <div>
            <div className="total-payment-label">{t('viewPacketDelivery:cashCalculator.amountToReturn')}</div>
            {amountToReturn !== null && <div className="cash-calculator-value" data-xid="cash-calculator-value">
                {amountToReturn < 0 && t('viewPacketDelivery:cashCalculator.notEnoughCash')}
                {amountToReturn >= 0 && <MoneyFormatter amount={amountToReturn} currencyCode={currency} />}
            </div>}
        </div>
    </div>

}
