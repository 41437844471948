export enum MessageTopic {
    OPENING_HOURS = 1,
    PACKET_PROBLEM = 2,
    HARDWARE_PROBLEM = 3,
    DRIVER_OR_COLLECTION = 4,
    COMMISSIONS_OR_BILLING_INFORMATION = 5,
    INVENTORY = 6,
    CASH_REGISTER_OR_TRANSFER = 8,
    BRANCH_CAPACITY = 10,
    Z_POINT_MOBILE_APP = 11,
    CONTRACTUAL_MATTERS = 12,
    OTHER = 0,
}
