import {FC, Fragment, useCallback, useState} from "react";
import {Dropdown} from "react-bootstrap";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {useTranslation} from "react-i18next";

import {DashboardGridManager} from "@app/AppContext/classes/DashboardGridManager";
import {PacketActions} from "@app/Packet/components/PacketActions/PacketActions";
import {PacketCardPaymentIcon} from "@app/Packet/components/PacketCardPaymentIcon/PacketCardPaymentIcon";
import {PacketDetailButton} from "@app/Packet/components/PacketDetailButton/PacketDetailButton";
import {Packet} from "@app/Packet/model/Packet";
import {EditableShelf} from "@app/Packet/pages/DashboardPage/DashboardGrid/EditableShelf/EditableShelf";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {Img} from "@common/components/Img/Img";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export type DashboardGridExtraColumnsProps = {
    manager: DashboardGridManager;
    packet: Packet;
}

export const DashboardGridExtraColumns: FC<DashboardGridExtraColumnsProps> = ({manager, packet}) => {
    const {t} = useTranslation();
    const sender = packet.senderId ? manager.senders.get(packet.senderId) : undefined;
    const cardPaymentAllowed = sender ? sender.cardPaymentAllowed : false;

    const [showDropdown, setShowDropdown] = useState<boolean>(false);

    const toggleDropdown = useCallback((show: boolean) => {
        setShowDropdown(show);
    }, []);

    const onActionSuccess = useCallback(async () => {
        setShowDropdown(false);
        await manager.refreshPackets([packet.barcode]);
    }, [manager, packet.barcode]);

    const packetActions = <PacketActions packet={packet} sender={sender} onSuccess={onActionSuccess}>
        <PacketDetailButton
            barcode={packet.barcode}
            title={t('viewDashboard:action.packetDetail')}
            variant="secondary"
        >
            <Img src="/images/icons/ico/ico-i-gray.svg" alt={t('viewDashboard:action.packetDetail')} />
        </PacketDetailButton>
    </PacketActions>

    return <Fragment>
        <td className="dashboard-grid__delivery-date">
            {dashWhenEmpty(packet.deliveryDate && <DateFormatter date={packet.deliveryDate} />)}
        </td>
        <td className="dashboard-grid__shelf">
            <EditableShelf packet={packet} onEdited={onActionSuccess} />
        </td>
        <td className="dashboard-grid__cod">
            {packet.hasCod && <PacketCardPaymentIcon cardPaymentAllowed={cardPaymentAllowed} />}
        </td>
        <td className="dashboard-grid__actions">
            <Dropdown show={showDropdown} onToggle={toggleDropdown} className="dashboard-grid__actions-dropdown">
                <DropdownToggle variant="success">&hellip;</DropdownToggle>
                <DropdownMenu>
                    {packetActions}
                </DropdownMenu>
            </Dropdown>
            <div className="dashboard-grid__actions-direct">{packetActions}</div>
        </td>
    </Fragment>
}
