export enum PacketStatusId {
    DATA_RECEIVED = 1,
    EN_ROUTE = 31,
    PICKUP_READY = 2,
    DELIVERED = 3,
    CANCELLED = 4,
    RETURNED = 5,
    RETURN = 20,
    RETURN_IN_STORAGE = 24,
    PROBLEM = 21,
    PROBLEM_CAPTURE = 30,
    PROBLEM_FINISHED = 22,
    COURIER_SENT = 23,
    COURIER_PREPARED = 25,
    TO_DISPOSE = 33,
}

export type PacketStatus = {
    id: PacketStatusId;
    name: string;
}
