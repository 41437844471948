import {FC, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {
    MultiDayBranchClosingModal
} from "@app/OpeningHours/components/ExceptionActions/MultiDayBranchClosingModal/MultiDayBranchClosingModal";
import {
    OneDayExceptionModal
} from "@app/OpeningHours/components/ExceptionActions/OneDayExceptionModal/OneDayExceptionModal";

import './exceptionActions.scss';

export const ExceptionActions: FC = () => {
    const {t} = useTranslation();
    const [oneDayExceptionModalOpened, setOneDayExceptionModalOpened] = useState<boolean>(false);
    const [multiDayBranchClosingModalOpened, setMultiDayBranchClosingModalOpened] = useState<boolean>(false);

    return <Can I={Action.MANAGE} a={Subject.BRANCH_HOURS}>
        <div className="exception-actions">
            <Button size="lg" onClick={() => setOneDayExceptionModalOpened(true)} data-xid="one-day-exception">
                {t('openingHours:oneDayExceptionModal.button')}
            </Button>
            <Button variant="light" size="lg" onClick={() => setMultiDayBranchClosingModalOpened(true)} data-xid="multi-day-branch-closing">
                {t('openingHours:multiDayBranchClosingModal.button')}
            </Button>
            <OneDayExceptionModal
                show={oneDayExceptionModalOpened}
                onHide={() => setOneDayExceptionModalOpened(false)}
            />
            <MultiDayBranchClosingModal
                show={multiDayBranchClosingModalOpened}
                onHide={() => setMultiDayBranchClosingModalOpened(false)}
            />
        </div>
    </Can>
}
