import {useCallback} from "react";
import {useTranslation} from "react-i18next";
import {ToastOptions} from "react-toastify";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {printCardReceipt} from "@app/CardPayment/api/cardTerminalApi";
import {
    PrintCardReceiptRequest,
    PrintCardReceiptResponse
} from "@app/CardPayment/model/CardTerminalRequestsAndResponses";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {usePrint} from "@common/hooks/usePrint";
import {TranslatableError} from "@common/model/errors/TranslatableError";

export const usePrintCardReceipt = (
    toastInModal: boolean = false,
    toastOptions?: ToastOptions,
) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const toastOpener = toastInModal ? modalToast : appToast;
    const handlePrint = usePrint(toastInModal, toastOptions);

    return useCallback(async (
        request: PrintCardReceiptRequest,
        showSuccessToast: boolean = true
    ): Promise<PrintCardReceiptResponse> => {
        const response = await printCardReceipt(request, appContext.api);

        const customerReceipt = response.receipts.customerReceipt;
        if (customerReceipt) {
            if (customerReceipt.sentByEmail) {
                if (showSuccessToast) {
                    toastOpener.success(t('cardPayment:receiptSentToCustomer'), toastOptions);
                }
            } else if (customerReceipt.prints) {
                await handlePrint(Promise.resolve({
                    success: response.success,
                    timestamp: response.timestamp,
                    prints: customerReceipt.prints
                }));
            }
        }

        const merchantReceipt = response.receipts.merchantReceipt;
        if (merchantReceipt && merchantReceipt.prints) {
            await handlePrint(Promise.resolve({success: response.success, timestamp: response.timestamp, prints: merchantReceipt.prints}));
        }

        if (customerReceipt || merchantReceipt) {
            return response;
        }

        throw new TranslatableError('cardPayment:error.receiptNotFound');
    }, [appContext.api, handlePrint, t, toastOpener, toastOptions])
}
