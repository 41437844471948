import {ApiErrorHandler} from "@app/AppContext/classes/Api/apiErrorHandlers/ApiErrorHandler";
import {ApiError} from "@common/model/errors/ApiError";
import {TranslatableError} from "@common/model/errors/TranslatableError";
import {getApiErrorCode} from "@common/utils/api/getApiErrorCode";

const BRANCH_CLOSED_ERROR_CODE = 'branchClosed';

// eslint-disable-next-line @typescript-eslint/require-await
export const branchClosedAzApiErrorHandler: ApiErrorHandler = async (error) => {
    if (error instanceof ApiError) {
        if (getApiErrorCode(error) === BRANCH_CLOSED_ERROR_CODE) {
            throw new TranslatableError('api:branchClosed');
        }
    }
}
