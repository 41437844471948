import {Ability} from "@casl/ability";
import {add} from "date-fns";
import {action, computed, makeObservable, observable} from "mobx";

import {casl} from "@app/AppContext/api/appContextApi";
import {Api} from "@app/AppContext/classes/Api/Api";
import {CaslRule} from "@app/AppContext/classes/Casl/model/CaslRule";
import {RefreshingDataManager} from "@app/AppContext/model/RefreshingDataManager";

export class CaslManager extends RefreshingDataManager {
    public rules: CaslRule[]|null = null;
    public loaded = false;

    protected readonly MAX_AGE: Duration = {hours: 24};

    public constructor(private api: Api) {
        super();

        makeObservable(this, {
            rules: observable.shallow,
            loaded: observable,
            ability: computed,
            setRules: action,
        });
    }

    public get ready(): boolean {
        return this.loaded && this.rules !== null;
    }

    public get ability(): Ability {
        return new Ability(this.loaded && this.rules ? this.rules : []);
    }

    public setRules = (rules: CaslRule[]|null): void => {
        this.rules = rules;
        this.loaded = rules !== null;
    }

    public stop(): void {
        this.setRules(null);
        super.stop();
    }

    public fetchData = (): void => {
        casl(this.api)
            .then((response) => {
                this.setRules(response.rules);
                this.expiresAt = add(new Date(), this.MAX_AGE);
            })
            .catch(() => {
                this.setRules(null);
            });
    };
}
