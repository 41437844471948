import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

const createMessageErrorCodes = {
    createMessageFailed: 'createMessageFailed' as const,
    invalidRecipientIds: 'invalidRecipientIds' as const,
    noRecipients: 'noRecipients' as const,
    badRepliesTo: 'badRepliesTo' as const,
    invalidAttachmentType: 'invalidAttachmentType' as const,
    multipleRecipientsNotAllowed: 'multipleRecipientsNotAllowed' as const,
    messageNotFound: 'messageNotFound' as const,
}

export const createMessageEndpoint: Endpoint<string, keyof typeof createMessageErrorCodes> = {
    method: HttpMethod.POST,
    url: '/messaging/message',
    knownErrorCodes: createMessageErrorCodes,
}

export const deleteEndpoint: Endpoint<'messageId'> = {
    method: HttpMethod.DELETE,
    url: '/messaging/message/:messageId',
    routeParamNames: ['messageId'],
}

export const detailEndpoint: Endpoint<'messageId'> = {
    method: HttpMethod.GET,
    url: '/messaging/message/:messageId',
    routeParamNames: ['messageId'],
}

export const downloadAttachmentEndpoint: Endpoint<'messageId'> = {
    method: HttpMethod.GET,
    url: '/messaging/message/:messageId/attachment',
    routeParamNames: ['messageId'],
}

export const receivedEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/messaging/received/list',
}

export const sentEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/messaging/sent/list',
}

export const readEnpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/messaging/message/read',
}

export const unreadEnpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/messaging/message/unread',
}
