import {LDMultiKindContext} from "launchdarkly-js-sdk-common";
import {ProviderConfig, withLDProvider} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import {Fragment} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {FCWithChildren} from "@common/model/FCWithChildren";

export const FeatureFlagProvider: FCWithChildren = observer(({children}) => {
    const appContext = useAppContext();

    if (appContext.config.launchDarklyClientId) {
        const isAnonymous = !appContext.user.isLoggedIn;
        const context: LDMultiKindContext = {
            kind: "multi",
            branch: {
                key: isAnonymous || !appContext.user.supportBranchId ? 'anonymous' : appContext.user.supportBranchId.toString(),
                anonymous: isAnonymous,
            },
            user: {
                key: isAnonymous || !appContext.user.email ? 'anonymous' : appContext.user.email,
                name: isAnonymous || !appContext.user.name ? undefined : appContext.user.name,
                anonymous: isAnonymous,
            }
        };
        const ldpConfig: ProviderConfig = {
            clientSideID: appContext.config.launchDarklyClientId,
            context,
            options: {
                bootstrap: 'localStorage',
                diagnosticOptOut: true,
                sendEvents: true,
            },
        };

        const WithLDContext = withLDProvider(ldpConfig)(() => <Fragment>{children}</Fragment>);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <WithLDContext />;
    }

    return <Fragment>{children}</Fragment>;
})
