import {FC, useEffect} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {downloadAttachment} from "@app/Messaging/api/messagingApi";
import {appToast} from "@common/components/Toast/toastOpener";
import {useNumericQueryParam, useQueryParam} from "@common/hooks/useQueryParam";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

const MESSAGE_ID_QUERY_PARAM = 'downloadAttachmentMessageId';
const FILE_NAME_QUERY_PARAM = 'downloadAttachmentFileName';

export const AttachmentDownloader: FC = () => {
    const appContext = useAppContext();

    const [messageId] = useNumericQueryParam(MESSAGE_ID_QUERY_PARAM);
    const [fileName] = useQueryParam(FILE_NAME_QUERY_PARAM);

    const showAppErrorToast = useShowErrorToast(appToast);

    useEffect(() => {
        if (messageId && fileName) {
            appContext.api.download(downloadAttachment(messageId), fileName)
                .catch((error: Error) => {
                    showAppErrorToast(error);
                });
        }
    }, [appContext.api, fileName, messageId, showAppErrorToast]);

    return null;
}
