import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const cardTransactionListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/card/transaction/list/:branchId',
    routeParamNames: ['branchId'],
}

export const cardTransactionTypeListEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/card/transaction-type/list',
}

export const lastTransactionIdEndpoint: Endpoint<'barcode', 'notReturnable'> = {
    method: HttpMethod.GET,
    url: '/card/transaction-packet/:barcode',
    routeParamNames: ['barcode'],
    notFoundErrorResponse: true,
    knownErrorCodes: {
        notReturnable: 'notReturnable',
    },
}

export const partnerCardTransactionListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/card/transaction/list/:branchId/partner',
    routeParamNames: ['branchId'],
}
