import {
    Sorting,
    Sorter,
    SorterComparator,
} from "@common/components/grids/GridSorter/Sorter";
import {SorterConfig} from "@common/components/grids/GridSorter/SorterConfig";
import {useLocalStorageState} from "@common/hooks/useLocalStorageState";

export const useSorter = <ItemType, SortId extends string>(
    name: string,
    defaultSorting: Sorting<SortId>,
    config: SorterConfig<ItemType, SortId>,
): Sorter<ItemType, SortId> => {
    const [sorting, setSorting] = useLocalStorageState<Sorting<SortId>|null>(`ui-sorter-${name}`, defaultSorting);

    const sort: SorterComparator<ItemType> = (itemA: ItemType, itemB: ItemType) => {
        const sortingToUse = sorting || defaultSorting;

        const sortingConfig = config[sortingToUse.id];
        const direction = sortingToUse.desc ? -1 : 1;

        const valueA = sortingConfig(itemA);
        const valueB = sortingConfig(itemB);

        if (typeof valueA === 'string') {
            return valueA.localeCompare(valueB as string) * direction;
        }

        return (valueA - (valueB as number)) * direction;
    };

    return {
        sort,
        setSorting,
        sorting,
    }
}
