import { createRoot } from "react-dom/client";

// Object.fromEntries polyfill
import 'polyfill-object.fromentries';

import {App} from "@app/App/App";

const rootAppElement = document.getElementById('app-root');

if (rootAppElement) {
    const root = createRoot(rootAppElement);
    root.render(<App />);
}
