import {observer} from "mobx-react-lite";
import {FC, Fragment} from "react";
import {Button} from "react-bootstrap";
import {FormProvider} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {EditableOpeningHoursGrid} from "@app/OpeningHours/components/EditableOpeningHoursGrid/EditableOpeningHoursGrid";
import {useOpeningHoursLimits} from "@app/OpeningHours/components/ExceptionActions/useOpeningHoursLimits";
import {
    useRegularOpeningHoursEditModal
} from "@app/OpeningHours/components/RegularOpeningHours/RegularOpeningHoursEditModal/useRegularOpeningHoursEditModal";
import {useHeaderForDayOfWeek} from "@app/OpeningHours/components/RegularOpeningHours/useHeaderForDayOfWeek";
import {DatePicker} from "@common/components/forms/DatePicker/DatePicker";
import {BaseModal, BaseModalMandatoryProps} from "@common/components/modals/BaseModal/BaseModal";
import {DateFormatType} from "@common/hooks/useDateFormatter";

import './regularOpeningHoursEditModal.scss';

export type RegularOpeningHoursEditModalProps = BaseModalMandatoryProps & {
    changeDate?: Date;
};

export const RegularOpeningHoursEditModal: FC<RegularOpeningHoursEditModalProps> = observer(({changeDate, onHide, show}) => {
    const {t} = useTranslation();

    const {
        apiError,
        close,
        form,
        isLoaded,
        onSubmit,
        selectedDay,
        setSelectedDay,
    } = useRegularOpeningHoursEditModal(show, changeDate, onHide, t);

    const {firstSelectableDate, maxExceptionDate} = useOpeningHoursLimits();

    const headerForDayOfWeek = useHeaderForDayOfWeek();

    return <BaseModal
        show={show}
        onHide={close}
        height="auto"
        className="regular-opening-hours-edit-modal"
        loading={!isLoaded}
        header={t('openingHours:regularOpeningHoursEditModal.title')}
        footer={<Fragment>
            <Button onClick={close} variant="secondary">
                {t('openingHours:regularOpeningHoursEditModal.action.cancel')}
            </Button>
            <Button disabled={selectedDay === undefined} onClick={form.handleSubmit(onSubmit)} variant="success">
                {t('openingHours:regularOpeningHoursEditModal.action.save')}
            </Button>
        </Fragment>}
        disableOutsideClose={true}
    >
        <DatePicker
            dayPickerProps={{
                mode: 'single',
                selected: selectedDay,
                disabled: [{before: firstSelectableDate}, {after: maxExceptionDate}],
                defaultMonth: changeDate || firstSelectableDate,
                fromMonth: firstSelectableDate,
                toMonth: maxExceptionDate,
            }}
            dateFormat={DateFormatType.DATE_FORMAT_WITH_DOW}
            name="date"
            formId="oneDayExceptionModalForm"
            label={t('openingHours:regularOpeningHoursEditModal.date.label')}
            onDateSelect={setSelectedDay}
            open={changeDate === undefined}
            placeholder={t('openingHours:regularOpeningHoursEditModal.date.placeholder')}
            value={selectedDay}
        />
        <div className="regular-opening-hours-edit-modal-grid">
            <FormProvider {...form}>
                <EditableOpeningHoursGrid
                    apiError={apiError}
                    customRowHeader={headerForDayOfWeek}
                    highlightExceptions={true}
                />
            </FormProvider>
        </div>
    </BaseModal>

})
