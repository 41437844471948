import {ExportToCsv} from "export-to-csv-fix-source-map";
import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {cashRegisterTransactionList, managedCashRegisterList} from "@app/Transaction/api/cashRegisterApi";
import {TRANSACTION_TYPE_COD_ID} from "@app/Transaction/model/TransactionType";
import {actualDateRange, SelectedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {SearchModifier} from "@common/model/requests/SearchRequestProperty";
import {dateRangeToFilterRequestParameter} from "@common/utils/api/dateRangeToFilterRequestParameter";

export const useManagedCashRegisterExport = () => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const dateFormatter = useDateFormatter();

    return useCallback(async (dateRange: SelectedDateRange|undefined): Promise<void> => {
        const cashRegistersResponse = await managedCashRegisterList(appContext.api);

        const actualRange = dateRange ? actualDateRange(dateRange) : undefined;

        const transactionsPromises = cashRegistersResponse.items.map((cashRegister) => {
            return cashRegisterTransactionList({
                branchId: cashRegister.branch.id,
                typeId: {value: TRANSACTION_TYPE_COD_ID.toString(10), modifier: SearchModifier.EQ},
                createdAt: actualRange ? dateRangeToFilterRequestParameter(actualRange) : undefined,
            }, appContext.api);
        });

        const transactions = await Promise.all(transactionsPromises);

        const csvExporter = new ExportToCsv({
            fieldSeparator: ';',
            filename: t('viewTranslation:managedCashRegister.export.csvFilename'),
            showLabels: true,
            useKeysAsHeaders: true,
        });

        csvExporter.generateCsv(transactions.map((response, index): Record<string, string> => {
            const result = {
                [t('cashRegister:property.branchName')]: cashRegistersResponse.items[index].branch.name,
                [t('viewTransaction:managedCashRegister.export.COD')]: response.totalSum.amount,
                [t('core:money.currencyName')]: response.totalSum.currency,
            }

            if (actualRange) {
                result[t('core:dateFrom')] = actualRange.from ? dateFormatter(actualRange.from, DateFormatType.DATE_FORMAT) : '';
                result[t('core:dateTo')] = actualRange && actualRange.to ? dateFormatter(actualRange.to, DateFormatType.DATE_FORMAT) : '';
            }

            return result;
        }));
    }, [appContext.api, dateFormatter, t]);
}
