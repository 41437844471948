import {useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {jobAction, readerAnalyze} from "@app/Devices/api/devicesApi";
import {shouldUpdateJob} from "@app/Devices/components/ReaderModal/utils/shouldUpdateJob";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobAction} from "@app/Devices/model/ReaderJobAction";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";
import {ReaderVariables} from "@app/Devices/model/ReaderVariables";
import {appToast, dismissToasts, modalToast} from "@common/components/Toast/toastOpener";
import {useLogError} from "@common/hooks/useLogError";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {getFirstErrorItem} from "@common/utils/api/getFirstErrorItem";

export type RemoveJob = (job: ReaderJob, toastTranslationKey?: string) => void;

export type CallJobAction = (
    job: ReaderJob,
    action: ReaderJobAction,
    variables?: ReaderVariables,
    toastTranslationKey?: string,
) => Promise<void>;

export const useReaderJobs = (
    onLoaded: () => void,
    onHide: () => void,
)  => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const showAppErrorToast = useShowErrorToast(appToast, 'reader:error', 'reader:error.general');

    const [jobs, setJobs] = useState<ReaderJob[]|null>(null);
    const [selectedJob, setSelectedJob] = useState<ReaderJob|null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const closeModal = useCallback(() => {
        onHide();
        if (appContext.dashboardGrid) {
            void appContext.dashboardGrid.reload();
        }
        appContext.branchEvents.setReloadRequired(true);
        setJobs(null);
        setSelectedJob(null);
    }, [appContext.branchEvents, appContext.dashboardGrid, onHide])

    const load = useCallback(() => {
        setLoading(true);
        readerAnalyze(appContext.api)
            .then((response) => {
                if (response.jobs.length === 0) {
                    appToast.error(t('reader:error.noDataForProcessing'));
                    closeModal();
                    return;
                }

                setJobs(response.jobs);
                setSelectedJob(response.jobs.length === 1 ? response.jobs[0] : null);
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
                closeModal();
            })
            .finally(() => {
                onLoaded();
                setLoading(false);
            });

    }, [appContext.api, closeModal, onLoaded, showAppErrorToast, t]);

    const updateJobs = useCallback((newJobs: ReaderJob[], jobToSelect?: ReaderJob, toastTranslationKey?: string) => {
        if (newJobs.length === 0) {
            closeModal();
        } else if (jobToSelect && jobToSelect.type === ReaderJobType.UNKNOWN) {
                load();
        } else {
            if (newJobs.length === 1) {
                setSelectedJob(newJobs[0]);
            } else {
                setSelectedJob(jobToSelect || null);
            }

            setJobs(newJobs);
        }

        if (toastTranslationKey) {
            (newJobs.length === 0 ? appToast : modalToast).success(t(toastTranslationKey));
        }
    }, [load, closeModal, t]);

    const selectJob = useCallback((job: ReaderJob|null) => {
        dismissToasts();
        setSelectedJob(job);
    }, []);

    const removeJob = useCallback<RemoveJob>((job: ReaderJob, toastTranslationKey?: string) => {
        updateJobs((jobs || []).filter((originalJob) => originalJob !== job), undefined, toastTranslationKey);
    }, [jobs, updateJobs]);

    const logError = useLogError();
    const showJobActionError = useShowErrorToast(modalToast);

    const callJobAction = useCallback<CallJobAction>(async (
        job: ReaderJob,
        action: ReaderJobAction,
        variables?: ReaderVariables,
        toastTranslationKey?: string,
    ) => {
        setLoading(true);
        try {
            const response = await jobAction({
                action,
                type: job.type,
                hash: job.hash,
                variables,
            }, appContext.api);

            const responseJob = response.job;
            if (responseJob && shouldUpdateJob(responseJob)) {
                updateJobs(
                    (jobs || []).map((originalJob) => job === originalJob ? responseJob : originalJob),
                    responseJob,
                    toastTranslationKey,
                );
            } else {
                removeJob(job, toastTranslationKey);
            }
        } catch (error: unknown) {
            const firstError = getFirstErrorItem(error as ApiError);

            if (firstError && firstError.code === 'readerBusyProcessingJob') {
                modalToast.error(t('reader:error.readerBusyProcessingJob'))
                void logError(error as Error);
            } else {
                showJobActionError(error as Error);
            }
        } finally {
            setLoading(false);
        }
    }, [appContext.api, jobs, logError, removeJob, showJobActionError, t, updateJobs]);

    return {
        jobs,
        load,
        loading,
        selectedJob,
        selectJob,
        removeJob,
        callJobAction,
        closeModal,
    }
}
