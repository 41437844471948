import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC, Fragment, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {InventoryDetailRoute, ManagedInventoryRoute} from "@app/Inventory/InventoryRoutes";
import {InventoryStatusId} from "@app/Inventory/model/InventoryStatus";
import {
    BranchInventoryListFilter
} from "@app/Inventory/pages/BranchInventoryPage/BranchInventoryListFilter/BranchInventoryListFilter";
import {
    BranchInventoryListHeader
} from "@app/Inventory/pages/BranchInventoryPage/BranchInventoryListHeader/BranchInventoryListHeader";
import {
    BranchInventoryListManager
} from "@app/Inventory/pages/BranchInventoryPage/BranchInventoryListManager";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";
import {Img} from "@common/components/Img/Img";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './branchInventoryPage.scss';

export type BranchInventoryPageProps = {
    branchId: string;
}

export const BranchInventoryPage: FC<BranchInventoryPageProps> = observer(({branchId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const branchInventoryList = useMemo(
        () => new BranchInventoryListManager(branchId, appContext.api),
        [appContext.api, branchId]
    );

    return <PageContent className="branch-inventory-page">
        {appContext.dashboardInfo.hasManagedBranches && <ReturnLink
            label={t('viewInventory:managed.back')}
            to={ManagedInventoryRoute}
        />}
        <PageHeader
            subTitle={<Fragment>
                <strong>{t('viewInventory:subTitle.main')}</strong>{' '}
                {t('viewInventory:subTitle.additional')}
            </Fragment>}
        >{t('viewInventory:title')}</PageHeader>
        <h4>{t('viewInventory:subHeader.title')}</h4>
        <p>{t('viewInventory:subHeader.subTitle')}</p>

        <BranchInventoryListFilter manager={branchInventoryList} />
        <PaginatedGrid manager={branchInventoryList}>
            <BranchInventoryListHeader manager={branchInventoryList} />
            <tbody>
                {branchInventoryList.items.map((inventory) => {
                    const createdBy = branchInventoryList.users.get(inventory.createdBy);

                    return <tr
                        key={inventory.id}
                        className={classNames({
                            'branch-inventory-page__cancelled-row': inventory.status.id === InventoryStatusId.CANCELLED
                        })}
                    >
                        <td><DateFormatter date={inventory.createdAt} showTime={true} /></td>
                        <td>
                            {inventory.branchName}
                            {createdBy && createdBy.name && <div className="branch-inventory-page__branch-name-created-by">
                                {createdBy.name}
                            </div>}
                        </td>
                        <td className="branch-inventory-page__created-by">
                            {dashWhenEmpty(createdBy && createdBy.name)}
                        </td>
                        <td>{inventory.badItemsCount}</td>
                        <td>
                            {inventory.confirmed &&
                                <Img src="/images/icons/ico/ico-check.svg" alt={t('inventory:text.confirmed')} />
                            }
                        </td>
                        <td>
                            <AppLink to={{route: InventoryDetailRoute, params: {branchId, inventoryId: inventory.id}}}>
                                {t('inventory:action.viewDetail')}
                            </AppLink>
                        </td>
                    </tr>
                })}
            </tbody>
        </PaginatedGrid>
    </PageContent>
})
