import classNames from "classnames";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {GridSorter} from "@common/components/grids/GridSorter/GridSorter";
import {Sorter} from "@common/components/grids/GridSorter/Sorter";
import {
    PacketsListType
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/PacketsList/PacketsListType";
import {
    PacketSortingIds,
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/PacketsList/usePacketsListSorter";

export type PacketsListTheadProps = {
    className?: string;
    listType: PacketsListType;
    sorter: Sorter<DeliveryPacket, PacketSortingIds>;
    showCashColumn: boolean;
}

export const PacketsListThead: FC<PacketsListTheadProps> = ({
    className,
    listType,
    showCashColumn,
    sorter,
}) => {
    const {t} = useTranslation();

    return <thead className={classNames('packets-list__head', className)}>
        <tr>
            <th className="packets-list__action">
                <span className="packets-list__action-shelf">
                    <GridSorter sortId="shelf" sorter={sorter}>
                        {t(`packet:property.${listType === 'delivered' ? 'shelf.label' : 'storedUntil'}`)}
                    </GridSorter>
                    {' / '}
                </span>
                <span className="packets-list__action-barcode">
                    {t('packet:packet')}
                </span>
                <span className="packets-list__action-action">
                    {t('viewPacketDelivery:packetsList.action')}
                </span>
            </th>
            <th className="packets-list__barcode">
                {t('packet:packet')}
            </th>
            <th className="packets-list__shelf">
                <GridSorter sortId="shelf" sorter={sorter}>
                    {t(`packet:property.${listType === 'delivered' ? 'shelf.label' : 'storedUntil'}`)}
                </GridSorter>
            </th>
            <th className="packets-list__consignee">
                <GridSorter sortId="consignee" sorter={sorter}>
                    {t('packet:property.consignee')}
                </GridSorter>
                <span className="packets-list__consignee-sender">
                            {' / '}
                    <GridSorter sortId="sender" sorter={sorter}>
                                {t('packet:property.sender')}
                            </GridSorter>
                        </span>
            </th>
            <th className="packets-list__sender">
                <GridSorter sortId="sender" sorter={sorter}>
                    {t('packet:property.sender')}
                </GridSorter>
            </th>
            <th className="packets-list__cod">
                <GridSorter sortId="cod" sorter={sorter}>
                    {t('packet:property.hasCashOnDelivery.title')}
                </GridSorter>
            </th>
            {showCashColumn && <th className="packets-list__cod-cash">
                {t('packet:property.hasCashOnDelivery.inCash')}
            </th>}
        </tr>
    </thead>
}
