import {SimpleReactRoute} from "@common/model/ReactRoute";

export const MicroDepotOperationalInfoRoute: SimpleReactRoute = {
    path: 'operational-info',
}

export const MicroDepotRoutes = [
    MicroDepotOperationalInfoRoute,
];

