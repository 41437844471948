import classNames from "classnames";

import {FCWithChildren} from "@common/model/FCWithChildren";

import './centeredCell.scss';

export type CenteredCellProps = {
    className?: string;
    colSpan?: number;
    header?: boolean;
}

export const CenteredCell: FCWithChildren<CenteredCellProps> = ({children, className, colSpan, header = false}) => {
    if (header) {
        return <th className={classNames('centered-cell', className)} colSpan={colSpan}>
            <div className="centered-cell-content">{children}</div>
        </th>
    }

    return <td className={classNames('centered-cell', className)} colSpan={colSpan}>
        <div className="centered-cell-content">{children}</div>
    </td>
}
