
import {DateRange} from "react-day-picker";

import {DateFilterRequestParameter} from "@common/model/requests/DateFilterRequestParameter";
import {SearchModifier} from "@common/model/requests/SearchRequestProperty";

export const dateRangeToFilterRequestParameter = (range: DateRange): DateFilterRequestParameter => {
    const result: DateFilterRequestParameter = [];

    if (range.from) {
        result.push({value: range.from, modifier: SearchModifier.GTE});
    }
    if (range.to) {
        result.push({value: range.to, modifier: SearchModifier.LTE});
    }

    return result;
}
