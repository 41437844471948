import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {confirmPacketCapacity, getBranchAttributes} from "@app/Branch/api/branchApi";
import {Capacity} from "@app/Branch/model/Attribute/Capacity";
import {useBranchEventTranslationKey} from "@app/BranchEvent/hooks/useBranchEventTranslationKey";
import {PacketCapacityConfirmationRequiredEvent} from "@app/BranchEvent/model/BranchEvent";
import {ModalBranchEventComponent} from "@app/BranchEvent/model/ModalBranchEventComponent";
import {useManualPostTo} from "@app/Manuals/hooks/useManualPostTo";
import {Button} from "@common/components/Button/Button";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export const PacketCapacityConfirmationRequiredBranchEventModal: ModalBranchEventComponent<PacketCapacityConfirmationRequiredEvent> = ({event, onClose}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const manualPostTo = useManualPostTo();
    const to = manualPostTo("support:whatToDoArticle.packetCapacityConfirmationRequired");

    const translationKey = useBranchEventTranslationKey(event);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [packetCapacity, setPacketCapacity] = useState<Capacity|null>(null);

    const showModalErrorToast = useShowErrorToast(modalToast);
    const showAppErrorToast = useShowErrorToast(appToast);

    useEffect(() => {
        getBranchAttributes(appContext.api)
            .then((response) => {
                const {capacity} = response.attributes
                setPacketCapacity(capacity)
            })
            .catch((error: Error) => {
                setPacketCapacity(null);
                showAppErrorToast(error);
                onClose();
            });
    }, [appContext.api, onClose, showAppErrorToast, showModalErrorToast]);

    const onConfirmCapacity = useBlockingCallback((unblock, packetCapacityCorresponds: boolean) => {
        setIsSubmitting(true);
        confirmPacketCapacity({packetCapacityCorresponds}, appContext.api)
            .then((response) => {
                if (!response.success) {
                    modalToast.error(t(`viewPacketCapacityConfirm:error.capacity${packetCapacityCorresponds ? 'Confirm' : 'Decline'}`));
                } else {
                    onClose();
                }
            })
            .catch(() => {
                modalToast.error(t(`viewPacketCapacityConfirm:error.capacity${packetCapacityCorresponds ? 'Confirm' : 'Decline'}`));
            })
            .finally(() => {
                setIsSubmitting(false);
                unblock();
            });
    }, [appContext.api, onClose, t])
    
    return <BaseModal
        show={true}
        onHide={onClose}
        header={t(`${translationKey}Title`)}
        footer={<Fragment>
            <Button variant="secondary" onClick={onClose}>{t('viewPacketCapacityConfirm:action.postpone')}</Button>
            <Button variant="success" onClick={() => onConfirmCapacity(true)}>{t('viewPacketCapacityConfirm:action.confirm')}</Button>
            <Button variant="danger" onClick={() => onConfirmCapacity(false)}>{t('viewPacketCapacityConfirm:action.decline')}</Button>
        </Fragment>}
        loading={isSubmitting || !packetCapacity}
        size="lg"
    >
        {packetCapacity && <p>
            {t('viewPacketCapacityConfirm:currentCapacityIs')}{' '}
            <strong>{packetCapacity.packetCapacity}</strong>
        </p>}
        {to && <p>
            {t('viewPacketCapacityConfirm:linkToManual')}{' - '}
            <AppLink to={to}>{t('viewPacketCapacityConfirm:capacityArticleHere')}</AppLink>
        </p>}
        <p>
            {t('viewPacketCapacityConfirm:pleaseConfirm')}
        </p>
    </BaseModal>
}