import {observer} from "mobx-react-lite";
import {useCallback} from "react";

import {useAdminBeWebSocket} from "@app/App/components/AppWrapper/DataSourceProvider/hooks/useAdminBeWebSocket";
import {useBranchEventsDataSource} from "@app/App/components/AppWrapper/DataSourceProvider/hooks/useBranchEventsDataSource";
import {
    useDashboardMessagesDataSource
} from "@app/App/components/AppWrapper/DataSourceProvider/hooks/useDashboardMessagesDataSource";
import {WebSocketMessage} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketMessage";
import {FCWithChildren} from "@common/model/FCWithChildren";

export const DataSourceProvider: FCWithChildren = observer(({children}) => {
    const [
        branchEventsOnMessage,
        branchEventsOnConnectionFailed,
        branchEventsConnect,
    ] = useBranchEventsDataSource();

    const [
        dashboardMessagesOnMessage,
        dashboardMessagesOnConnectionFailed,
        dashboardMessagesConnect,
    ] = useDashboardMessagesDataSource();

    const onMessage = useCallback((message: WebSocketMessage) => {
        branchEventsOnMessage(message);
        dashboardMessagesOnMessage(message);
    }, [branchEventsOnMessage, dashboardMessagesOnMessage]);

    const onConnectionFailed = useCallback((isRecoverable: boolean) => {
        branchEventsOnConnectionFailed(isRecoverable);
        dashboardMessagesOnConnectionFailed(isRecoverable);
    }, [branchEventsOnConnectionFailed, dashboardMessagesOnConnectionFailed]);

    useAdminBeWebSocket(
        onMessage,
        onConnectionFailed,
        branchEventsConnect || dashboardMessagesConnect,
    );

    return children;
})
