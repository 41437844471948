import {i18n as I18next} from "i18next";
import {autorun} from "mobx";

import {SecuredAppModules} from "@app/App/SecuredAppModules";
import {Api} from "@app/AppContext/classes/Api/Api";
import {AppRouter} from "@app/AppContext/classes/AppRouter";
import {BranchEvents} from "@app/AppContext/classes/BranchEvents";
import {CaslManager} from "@app/AppContext/classes/Casl/CaslManager";
import {Catalogues} from "@app/AppContext/classes/Catalogues";
import {
    DashboardGridManager
} from "@app/AppContext/classes/DashboardGridManager";
import {DashboardInfoManager} from "@app/AppContext/classes/DashboardInfoManager";
import {DashboardMessages} from "@app/AppContext/classes/DashboardMessages";
import {DeviceGatewayResolver} from "@app/AppContext/classes/DeviceGatewayResolver";
import {Print} from "@app/AppContext/classes/Print";
import {Authorizator} from "@app/AppContext/classes/User/Authorizator";
import {User} from "@app/AppContext/classes/User/User";
import {UserChecker} from "@app/AppContext/classes/User/UserChecker";
import {UserSpecificUI} from "@app/AppContext/classes/User/UserSpecificUI";
import {BranchRoutes} from "@app/Branch/BranchRoutes";
import {CommissionRoutes} from "@app/Commission/CommissionRoutes";
import {Config} from "@app/config";
import {InventoryRoutes} from "@app/Inventory/InventoryRoutes";
import {ManualsRoutes} from "@app/Manuals/ManualsRoutes";
import {MessagingRoutes} from "@app/Messaging/MessagingRoutes";
import {MicroDepotRoutes} from "@app/MicroDepot/MicroDepotRoutes";
import {OpeningHoursRoutes} from "@app/OpeningHours/OpeningHoursRoutes";
import {PacketRoutes} from "@app/Packet/PacketRoutes";
import {ParcelRoutes} from "@app/Parcel/ParcelRoutes";
import {PrintRoutes} from "@app/Print/PrintRoutes";
import {TransactionModuleRoutes} from "@app/Transaction/TransactionModuleRoutes";
import {UserRoutes} from "@app/User/UserRoutes";

export class AppContext {
    public user: User;
    public userChecker: UserChecker;
    public authorizator: Authorizator;
    public deviceGatewayResolver: DeviceGatewayResolver;
    public config: Config;
    public api: Api;

    public router: AppRouter;
    public casl: CaslManager;

    public branchEvents: BranchEvents;
    public dashboardMessages: DashboardMessages;

    public dashboardInfo: DashboardInfoManager;
    public dashboardGrid?: DashboardGridManager;
    public userSpecificUI: UserSpecificUI;

    public print: Print;
    public catalogues: Catalogues;

    public constructor(config: Config, i18n: I18next) {
        this.config = config;
        this.user = new User();

        this.api = new Api(this.user, this.config, i18n);

        this.deviceGatewayResolver = new DeviceGatewayResolver(this.api);
        this.casl = new CaslManager(this.api);
        this.dashboardInfo = new DashboardInfoManager(this.api);
        this.print = new Print(this.api);
        this.catalogues = new Catalogues(this.api);

        this.userChecker = new UserChecker(this.user, this.api);
        this.authorizator = new Authorizator(this.user, this.api, this.deviceGatewayResolver);
        this.userSpecificUI = new UserSpecificUI();

        this.router = new AppRouter();
        this.configureRouter();

        this.branchEvents = new BranchEvents();
        this.dashboardMessages = new DashboardMessages();

        autorun(() => {
            if (this.user.isLoggedIn) {
                this.startRefreshingServices();
                this.initDashboardGrid();
            } else if (this.user.checked) {
                this.stopRefreshingServices();
                this.destroyDashboardGrid();
                this.print.clearCache();
                this.userSpecificUI.clear();
            }
        });
    }

    private startRefreshingServices(): void {
        this.casl.start();
        this.dashboardInfo.start();
        this.catalogues.start();
    }

    private stopRefreshingServices(): void {
        this.casl.stop();
        this.dashboardInfo.stop();
        this.catalogues.stop();
    }

    private initDashboardGrid(): void {
        if (this.user.branchId !== null) {
            if (this.dashboardGrid) {
                this.dashboardGrid.setBranchId(this.user.branchId);
            } else {
                this.dashboardGrid = new DashboardGridManager(this.user.branchId, this.api);
            }
        } else {
            this.dashboardGrid = undefined;
        }
    }

    private destroyDashboardGrid(): void {
        if (this.dashboardGrid) {
            this.dashboardGrid.invalidate();
        }
        this.dashboardGrid = undefined;
    }

    private configureRouter(): void {
        this.router.registerModule(SecuredAppModules.BRANCH, BranchRoutes);
        this.router.registerModule(SecuredAppModules.COMMISSION, CommissionRoutes);
        this.router.registerModule(SecuredAppModules.INVENTORY, InventoryRoutes);
        this.router.registerModule(SecuredAppModules.MANUALS, ManualsRoutes);
        this.router.registerModule(SecuredAppModules.MESSAGING, MessagingRoutes);
        this.router.registerModule(SecuredAppModules.MICRODEPOT, MicroDepotRoutes);
        this.router.registerModule(SecuredAppModules.OPENING_HOURS, OpeningHoursRoutes)
        this.router.registerModule(SecuredAppModules.PACKET, PacketRoutes);
        this.router.registerModule(SecuredAppModules.PARCEL, ParcelRoutes);
        this.router.registerModule(SecuredAppModules.PRINT, PrintRoutes);
        this.router.registerModule(SecuredAppModules.TRANSACTION, TransactionModuleRoutes);
        this.router.registerModule(SecuredAppModules.USER, UserRoutes);
    }
}
