import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {changePassword} from "@app/User/api/userApi";
import {ChangePasswordRequirements} from "@app/User/components/ChangePasswordRequirements/ChangePasswordRequirements";
import {useChangePasswordValidation} from "@app/User/hooks/useChangePasswordValidation";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './changePasswordPage.scss';

type PasswordChangeFormData = {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

export const ChangePasswordPage: FC = observer(() => {
    const {t} = useTranslation();
    const form = useForm<PasswordChangeFormData>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const {api, branchEvents} = useAppContext();
    const navigate = useAppNavigate();
    const showAppErrorToast = useShowErrorToast(appToast, 'viewChangePassword:error');

    const onSubmit = useCallback((data: PasswordChangeFormData) => {
        setIsSubmitting(true);
        changePassword({currentPassword: data.currentPassword, password: data.newPassword}, api)
            .then(() => {
                appToast.success(t('viewChangePassword:message.success'));
                branchEvents.setReloadRequired(true);
                navigate(DashboardRoute);
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, [api, branchEvents, navigate, showAppErrorToast, t]);

    useEffect(() => {
        form.setFocus('currentPassword');
    }, [form]);

    const {confirmPasswordOptions, newPasswordOptions} = useChangePasswordValidation(
        () => form.getValues('newPassword'),
        () => form.getValues('currentPassword'),
    );

    const formId = 'changePasswordForm';

    return <PageContent className="change-password-page">
        <PageHeader>
            {t('viewChangePassword:title')}
        </PageHeader>
        <Loading active={isSubmitting} className="change-password-page__content">
            <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
                <FormGroup
                    name="currentPassword"
                    label={t('viewChangePassword:currentPassword.label')}
                    type="password"
                    formId={formId}
                    registerOptions={{required: t<string>('viewChangePassword:currentPassword.required')}}
                    inputOptions={{
                        placeholder: t('viewChangePassword:currentPassword.placeholder'),
                    }}
                    form={form}
                />
                <FormGroup
                    name="newPassword"
                    label={t('viewChangePassword:newPassword.label')}
                    type="password"
                    formId={formId}
                    registerOptions={newPasswordOptions}
                    inputOptions={{
                        placeholder: t('viewChangePassword:newPassword.placeholder'),
                    }}
                    form={form}
                />
                <FormGroup
                    name="confirmPassword"
                    label={t('viewChangePassword:confirmPassword.label')}
                    type="password"
                    formId={formId}
                    registerOptions={confirmPasswordOptions}
                    inputOptions={{
                        placeholder: t('viewChangePassword:confirmPassword.placeholder'),
                    }}
                    form={form}
                />
                <SubmitFormGroup name="change" value={t('viewChangePassword:submit.label')} formId={formId} />
            </form>
            <ChangePasswordRequirements differFromCurrent={true} />
        </Loading>
    </PageContent>
})
