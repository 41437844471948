import {SimpleReactRoute} from "@common/model/ReactRoute";

export const UserIndexRoute: SimpleReactRoute = {
    // Reserved for future use
    path: '',
}

export const ChangePasswordRoute: SimpleReactRoute = {
    path: 'change-password',
}

export const UserRoutes = [
    UserIndexRoute,
    ChangePasswordRoute,
];
