import classNames from "classnames";
import {FC, Fragment} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {Img} from "@common/components/Img/Img";

import './paginator.scss';

export type PaginatorProps = {
    maxPage: number;
    onPageSelected: (page: number) => void;
    page: number;
}

export const Paginator: FC<PaginatorProps> = ({maxPage, onPageSelected, page}) => {
    const {t} = useTranslation();

    const paginatorSteps = [1, page - 2, page - 1, page, page + 1, page + 2, maxPage]
        .sort((valueA, valueB) =>  valueA - valueB)
        .filter((step, index, steps) => {
            return (index === 0 || steps[index - 1] !== step) && step >= 1 && step <= maxPage;
        })
        .filter((_step, _index, steps) => steps.length > 1);

    let lastRenderedStep = 0;

    return <div className="paginator">
        {paginatorSteps.length > 0 && <Button
            disabled={page === 1}
            onClick={() => onPageSelected(page - 1)}
            variant="secondary"
            className="paginator__step"
            title={t('global:paginator.previousPage')}
        >
            <Img
                src={`/images/icons/arr/arr-9-${page === 1 ? 'gray' : 'black'}-left.svg`}
                alt={t('global:paginator.previousPage')}
            />
        </Button>}
        {paginatorSteps.map((step) => {
            const showGap = lastRenderedStep !== step - 1;
            lastRenderedStep = step;
            return <Fragment key={step}>
                {showGap && <div>&hellip;</div>}
                <Button
                    onClick={() => onPageSelected(step)}
                    variant="secondary"
                    className={classNames('paginator__step', {'paginator__step-active': page === step})}
                    key={step}
                >{step}</Button>
            </Fragment>
        })}
        {paginatorSteps.length > 0 && <Button
            disabled={page === maxPage}
            onClick={() => onPageSelected(page + 1)}
            variant="secondary"
            className="paginator__step"
            title={t('global:paginator.nextPage')}
        >
            <Img
                src={`/images/icons/arr/arr-14-${page === maxPage ? 'gray' : 'black'}.svg`}
                alt={t('global:paginator.nextPage')}
            />
        </Button>}
    </div>
}
