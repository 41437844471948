import {useCallback, useContext} from "react";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {DeliveryPacket, isDeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {Packet} from "@app/Packet/model/Packet";
import {Sender} from "@app/Packet/model/Sender";

export const usePacketCardPaymentAllowed = () => {
    const ability = useContext(AbilityContext);

    return useCallback((packet: DeliveryPacket|Packet, sender?: Sender) => {
        if (isDeliveryPacket(packet)) {
            return ability.can(Action.MANAGE, Subject.CARD_TRANSACTION) && packet.isCod && packet.cardPaymentAllowed;
        } else {
            return ability.can(Action.MANAGE, Subject.CARD_TRANSACTION) && packet.hasCod && sender && sender.cardPaymentAllowed;
        }
    }, [ability]);
}
