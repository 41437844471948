import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {devicesList, pdcVersionLink} from "@app/Devices/api/devicesApi";
import {devicesListEndpointKnownErrorCodes} from "@app/Devices/api/devicesEndpoints";
import {
    DevicesTestModalDevice
} from "@app/Devices/components/DevicesTestModal/DevicesTestModalDevice/DevicesTestModalDevice";
import {DevicesListResponse} from "@app/Devices/model/DevicesRequestsAndResponses";
import {PdcVersionLink} from "@app/Devices/model/PdcVersionLink";
import {postByContextAction} from "@app/Manuals/api/manualsApi";
import {ManualContent} from "@app/Manuals/components/ManualContent/ManualContent";
import {ManualPost} from "@app/Manuals/model/ManualPost";
import {Button} from "@common/components/Button/Button";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {isKnownError} from "@common/utils/api/isKnownError";

import './devicesTestModal.scss';

export type DevicesTestModalProps = {
    show: boolean;
    onHide: () => void;
}

export const DevicesTestModal: FC<DevicesTestModalProps> = ({onHide, show}) => {
    const {t} = useTranslation();
    const {api, deviceGatewayResolver} = useAppContext();

    const [loading, setLoading] = useState<boolean>(true);
    const [devices, setDevices] = useState<DevicesListResponse|false|null>(null);
    const [contextManualPost, setContextManualPost] = useState<ManualPost|false|null>(null);
    const [pdcVersion, setPdcVersion] = useState<PdcVersionLink|null>(null);

    const showModalErrorToast = useShowErrorToast(modalToast);

    const clearAndHide = useCallback(() => {
        setDevices(null);
        setContextManualPost(null);
        onHide();
    }, [onHide]);

    useEffect(() => {
        if (!show || devices !== null) {
            return;
        }
        setLoading(true);
        devicesList(api)
            .then((response) => {
                setDevices(response);
            })
            .catch((error: ApiError) => {
                if (!isKnownError(error, devicesListEndpointKnownErrorCodes.connectionError.errorCode)) {
                    showModalErrorToast(error);
                }
                setDevices(false);
            })
            .finally(() => {
                setLoading(false);
            });

        pdcVersionLink(
            deviceGatewayResolver.deviceGateway && deviceGatewayResolver.deviceGateway.deviceGatewayId,
            api
        )
            .then((response) => {
                setPdcVersion(response);
            })
            .catch((error: Error) => {
                showModalErrorToast(error);
            });
    }, [api, deviceGatewayResolver.deviceGateway, devices, show, showModalErrorToast, t]);

    useEffect(() => {
        if (!show || contextManualPost || contextManualPost === false) {
            return;
        }

        postByContextAction('device.test', api, t)
            .then((post) => {
                setContextManualPost(post || false);
            })
            .catch(() => {
                setContextManualPost(false);
            });
    }, [api, contextManualPost, show, t]);

    if (!show) {
        return null;
    }

    return <BaseModal
        show={show}
        onHide={clearAndHide}
        header={t('viewDevicesTestPopup:title')}
        size="lg"
        loading={loading}
        className="devices-test-modal"
        footer={<Button onClick={clearAndHide}>{t('viewDevicesTestPopup:action.close')}</Button>}
    >
        {devices === false && <div className="devices-test-modal__not-connected">
            {t('viewDevicesTestPopup:error.zpiNotConnected')}
        </div>}

        <div className="devices-test-modal__devices">
            {devices && <Fragment>
                <DevicesTestModalDevice available={devices.readerAvailable} device="reader" />
                <DevicesTestModalDevice available={devices.printerAvailable} device="printer" />
            </Fragment>}
            {pdcVersion && <div className="devices-test-modal__device">
                <h4>{t(`viewDevicesTestPopup:pdcVersion.title`)}</h4>
                <a href={pdcVersion.Link} target="_blank">{t('viewDevicesTestPopup:pdcVersion.download')}</a>
            </div>}
        </div>

        <h2 className="devices-test-modal__manual-title">{t('viewDevicesTestPopup:manualTitle')}</h2>

        {contextManualPost && <div className="devices-test-modal__context-article">
            <h1>{contextManualPost.title.rendered}</h1>
            <ManualContent content={contextManualPost.content} />
        </div>}
    </BaseModal>
}
