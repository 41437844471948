import {observer} from "mobx-react-lite";
import {Fragment} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    PacketsNotReceivedYesterdayBranchEvent
} from "@app/BranchEvent/model/BranchEvent";
import {ModalBranchEventComponent} from "@app/BranchEvent/model/ModalBranchEventComponent";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {Button} from "@common/components/Button/Button";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

export const PacketsNotReceivedYesterdayBranchEventModal: ModalBranchEventComponent<PacketsNotReceivedYesterdayBranchEvent> = observer(({
    event,
    onClose,
}) => {
    const {t} = useTranslation();
    const {user} = useAppContext();

    const branchId = user.branchId;
    if (!branchId) {
        return null;
    }

    return <BaseModal
        show={true}
        onHide={onClose}
        header={t('branchEvent:packetsNotReceivedYesterdayTitle')}
        footer={<Button variant="primary" onClick={onClose}>{t('branchEvent:close')}</Button>}
    >
        {event.variables.notReceivedPackets && <Fragment>
            <p>{t('viewPacketsNotReceivedYesterday:header')}</p>
            <GridTable>
                <thead>
                <tr>
                    <th>
                        {t('viewPacketsNotReceivedYesterday:packetBarcodes')}
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>
                        {event.variables.notReceivedPackets.map((barcode, index) => (
                            <Fragment key={barcode}>
                                <FormattedPacketBarcode barcode={barcode}/>
                                {index < event.variables.notReceivedPacketCount - 1 && <span>, </span>}
                            </Fragment>
                        ))}
                    </td>
                </tr>
                </tbody>
            </GridTable>
        </Fragment>}
    </BaseModal>
})
