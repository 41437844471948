import {FC, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {cashRegisterBalance} from "@app/Transaction/api/cashRegisterApi";
import {CashRegisterBalance} from "@app/Transaction/model/CashRegister/CashRegisterBalance";
import {
    NewManualTransferModal
} from "@app/Transaction/pages/BranchCashRegisterPage/CashRegisterBalanceBox/NewManualTransferModal/NewManualTransferModal";
import {Button} from "@common/components/Button/Button";
import {DatePicker} from "@common/components/forms/DatePicker/DatePicker";
import {Loading} from "@common/components/Loading/Loading";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {appToast} from "@common/components/Toast/toastOpener";
import {DateFormatType} from "@common/hooks/useDateFormatter";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {SearchModifier} from "@common/model/requests/SearchRequestProperty";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './cashRegisterBalanceBox.scss';

export type CashRegisterBalanceBoxProps = {
    branchId: string;
}

export const CashRegisterBalanceBox: FC<CashRegisterBalanceBoxProps> = ({branchId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [balance, setBalance] = useState<CashRegisterBalance|null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [createdAt, setCreatedAt] = useState<Date>(new Date());
    const showAppErrorToast = useShowErrorToast(appToast);

    const loadBalance = useCallback(() => {
        setLoading(true);
        cashRegisterBalance({branchId, createdAt: [{value: createdAt, modifier: SearchModifier.LTE}]}, appContext.api)
            .then((response) => {
                setBalance(response.balance);
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
                setBalance(null);
            })
            .finally(() => {
                setLoading(false);
            });
    }, [branchId, appContext.api, createdAt, showAppErrorToast]);

    const reset = useCallback(() => {
        setCreatedAt(new Date());
    }, []);

    useEffect(() => {
        loadBalance();
    }, [loadBalance]);

    return <Loading active={loading} className="cash-register-balance-box">
        <div>
            <div>
                <div className="cash-register-balance-box__label">
                    {t('viewCashRegisterDetail:state')}
                </div>
                <div className="cash-register-balance-box__amount">
                    {dashWhenEmpty(balance && <MoneyFormatter
                        amount={balance.amount}
                        currencyCode={balance.currency}
                    />)}
                </div>
                {balance && <NewManualTransferModal balance={balance} onTransfer={() => setCreatedAt(new Date())} />}
            </div>
            <div className="cash-register-balance-box__controls">
                <DatePicker
                    label={t('viewCashRegisterDetail:date')}
                    dayPickerProps={{
                        mode: 'single',
                        selected: createdAt,
                        disabled: [{after: new Date()}],
                        toMonth: new Date(),
                    }}
                    dateFormat={DateFormatType.TIME_FORMAT}
                    name="date"
                    formId="cashRegisterBalanceBoxForm"
                    value={createdAt}
                    onDateSelect={setCreatedAt}
                    showTimeSelect={true}
                />
                <div>
                    <Button variant="secondary" onClick={reset} disabled={loading}>
                        {t('cashRegister:action.reset')}
                    </Button>
                </div>
            </div>
        </div>
    </Loading>
}
