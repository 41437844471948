import {DependencyList, useCallback, useRef} from "react";

type BlockingCallback<P extends Array<any>> = (unblock: () => void, ...params: P) => void;

export const useBlockingCallback = <P extends Array<any>>(callback: BlockingCallback<P>, deps: DependencyList): (...args: P) => void => {
    const blocked = useRef<boolean>(false);

    return useCallback((...args: P) => {
        if (blocked.current) {
            return;
        }

        blocked.current = true;
        callback(() => blocked.current = false, ...args);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
