import {Api} from "@app/AppContext/classes/Api/Api";
import {User} from "@app/AppContext/classes/User/model/User";
import {cardTransactionList} from "@app/Transaction/api/cardApi";
import {cashRegisterTransactionList} from "@app/Transaction/api/cashRegisterApi";
import {Transaction} from "@app/Transaction/model/Transaction";
import {
    TransactionListRequest,
    TransactionListResponse
} from "@app/Transaction/model/TransactionsRequestsAndResponses";
import {actualDateRange, SelectedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";
import {Money} from "@common/model/Money";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {SearchModifier} from "@common/model/requests/SearchRequestProperty";
import {ResponseWithEmbedded} from "@common/model/responses/ResponseWithEmbedded";
import {dateRangeToFilterRequestParameter} from "@common/utils/api/dateRangeToFilterRequestParameter";
import {extractEmbedded} from "@common/utils/extractEmbedded";

export type BranchCommissionListFilter = {
    typeId?: string;
    createdAt?: SelectedDateRange;
}

export enum TransactionListType {
    CASH_REGISTER = 'cash-register',
    CARD = 'card',
}

export class TransactionListManager extends SortablePaginatedDataManager<
    Transaction, TransactionListRequest, ResponseWithEmbedded & Pick<TransactionListResponse, 'totalSum'>
> {
    public totalSum?: Money;
    public users: Map<string, User> = new Map<string, User>();

    constructor(public branchId: string, public listType: TransactionListType, api: Api, pageSize?: number) {
        super(
            (request: TransactionListRequest) => listType === TransactionListType.CASH_REGISTER
                ? cashRegisterTransactionList(request, api)
                : cardTransactionList(request, api),
            {branchId, embed: listType === TransactionListType.CASH_REGISTER ? [Embeddable.USER] : undefined},
            pageSize,
        );

        this.processResponse.push((response) => {
            this.totalSum = response ? response.totalSum : undefined;
            this.users = extractEmbedded(response, Embeddable.USER);
        });
    }

    public setFilter = (filter: BranchCommissionListFilter): void => {
        const newFilteringRequest: Partial<TransactionListRequest> = {
            typeId: filter.typeId ? {value: filter.typeId, modifier: SearchModifier.EQ} : undefined,
        };

        if (filter.createdAt) {
            const actualRange = actualDateRange(filter.createdAt);

            if (actualRange.from && actualRange.to) {
                newFilteringRequest.createdAt = dateRangeToFilterRequestParameter(actualRange);
            }
        }

        this.setFilteringRequest(newFilteringRequest);
    }
}
