import {FC} from "react";
import {Link, LinkProps} from "react-router-dom";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {ButtonSize, ButtonVariant, buttonClassName} from "@common/components/Button/buttonClassName";
import {To} from "@common/model/ReactRoute";

export type AppLinkProps<ReactRouteParamNames extends string = string> = Omit<LinkProps, 'to'> & {
    to: To<ReactRouteParamNames>;
    variant?: ButtonVariant;
    size?: ButtonSize;
}

export const AppLink: FC<AppLinkProps> = ({
    children,
    className,
    size,
    to,
    variant,
    ...linkProps
}) => {
    const appContext = useAppContext();
    const linkClassName = variant || size ? buttonClassName(className, variant, size) : className;

    return <Link className={linkClassName} {...linkProps} to={appContext.router.path(to)}>{children}</Link>
}
