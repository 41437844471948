import {action, computed, makeObservable, observable} from "mobx";

import {Message, MessageType} from "@app/Messaging/model/Message";

export class DashboardMessages {
    public unreadCount = 0;
    public messages: Message[] = [];
    public loaded = false;
    public reloadRequired = false;

    public constructor() {
        makeObservable(this, {
            unreadCount: observable,
            messages: observable.shallow,
            loaded: observable,
            reloadRequired: observable,
            priorityMessages: computed,
            setUnreadCount: action,
            setMessages: action,
            setReloadRequired: action,
            reset: action,
        });
    }

    get priorityMessages(): Message[] {
        return this.messages.filter((message) => {
            return message.type === MessageType.URGENT_MESSAGE;
        });
    }

    public setUnreadCount(unreadCount: number) {
        this.unreadCount = unreadCount;
    }

    public setMessages(messages: Message[]) {
        this.messages = messages;
        this.loaded = true;
        this.reloadRequired = false;
    }

    public setReloadRequired(reloadRequired: boolean) {
        this.reloadRequired = reloadRequired;
    }

    public reset() {
        this.setUnreadCount(0);
        this.setMessages([]);
        this.loaded = false;
        this.reloadRequired = false;
    }
}
