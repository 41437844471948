import {WebSocketMessage} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketMessage";

export type WebSocketResultMessage = {
    timestamp: string;
    success: boolean;
    message: string;
}

export const isWebSocketResultMessage = (message: WebSocketMessage): message is WebSocketResultMessage => {
    return 'success' in message && 'message' in message;
}
