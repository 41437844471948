import {add} from "date-fns";
import {useCallback, useEffect, useRef} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";

const REFRESH_CHECK_INTERVAL = 60 * 1000; // 1 minute interval

export const useActiveDataRefresh = (
    refreshing: boolean,
    onRefresh: () => void,
    maxAge: Duration,
) => {
    const {user} = useAppContext();

    const timeoutRef = useRef<number|null>(null);
    const lastRefresh = useRef<Date|null>(null);

    const doRefresh = useCallback(() => {
        if (!lastRefresh.current || add(lastRefresh.current, maxAge) < new Date()) {
            onRefresh();
            lastRefresh.current = new Date();
        }

        timeoutRef.current = window.setTimeout(doRefresh, REFRESH_CHECK_INTERVAL);
    }, [lastRefresh, maxAge, onRefresh]);

    const stopRefreshing = useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }
    }, []);

    useEffect(() => {
        if (user.isLoggedIn && refreshing) {
            void doRefresh();
        } else {
            stopRefreshing();
        }

        return () => {
            stopRefreshing();
        }
    }, [doRefresh, refreshing, stopRefreshing, user.isLoggedIn]);
}
