import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC, Fragment, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    TransactionGridStateCell
} from "@app/Transaction/components/TransactionGrid/TransactionGridStateCell/TransactionGridStateCell";
import {isSalePartnerCardTransaction} from "@app/Transaction/model/PartnerCardTransaction";
import {TransactionAuthState} from "@app/Transaction/model/TransactionAuthState";
import {
    PartnerCardTransactionForm
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionGrid/PartnerCardTransactionForm/PartnerCardTransactionForm";
import {
    PartnerCardTransactionGridActions
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionGrid/PartnerCardTransactionGridActions/PartnerCardTransactionGridActions";
import {
    PartnerCardTransactionGridExport
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionGrid/PartnerCardTransactionGridExport/PartnerCardTransactionGridExport";
import {
    PartnerCardTransactionGridFilter
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionGrid/PartnerCardTransactionGridFilter/PartnerCardTransactionGridFilter";
import {
    PartnerCardTransactionGridHeader
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionGrid/PartnerCardTransactionGridHeader/PartnerCardTransactionGridHeader";
import {
    PartnerCardTransactionListManager
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionListManager";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export type PartnerCardTransactionGridProps = {
    branchId: string;
}

export const PartnerCardTransactionGrid: FC<PartnerCardTransactionGridProps> = observer(({branchId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const manager = useMemo<PartnerCardTransactionListManager>(
        () => new PartnerCardTransactionListManager(appContext.api, branchId),
        [branchId, appContext.api]
    );

    return <Fragment>
        <PartnerCardTransactionForm manager={manager} />
        <h4>{t('viewPartnerCardTransaction:gridTitle')}</h4>
        <PartnerCardTransactionGridFilter manager={manager} />
        <PaginatedGrid
            manager={manager}
            topCountContent={manager.hasItems
                ? <PartnerCardTransactionGridExport manager={manager} />
                : undefined
            }
        >
            <PartnerCardTransactionGridHeader manager={manager} />
            <tbody>
                {manager.items.map((transaction) => {
                    return <tr key={transaction.transactionId} className={classNames('transaction-grid__row', {
                        'transaction-grid__row-rejected': transaction.authState === TransactionAuthState.REJECTED,
                        'transaction-grid__row-waiting': transaction.authState === TransactionAuthState.WAITING,
                    })}>
                        <td>
                            {t(`viewPartnerCardTransaction:property.transactionType.${isSalePartnerCardTransaction(transaction) ? 'sale' : 'cancellation'}`)}
                        </td>
                        <td><DateFormatter date={transaction.createdAt} showTime={true} /></td>
                        <td className="transaction-grid__row-amount">
                            <MoneyFormatter amount={transaction.amount} currencyCode={transaction.currency} />
                        </td>
                        <td>{dashWhenEmpty(transaction.reference)}</td>
                        <TransactionGridStateCell transaction={transaction} />
                        <td><PartnerCardTransactionGridActions manager={manager} transaction={transaction} /></td>
                    </tr>
                })}
                {manager.totalSum !== undefined && <tr className="transaction-grid__sum-row">
                    <td colSpan={2}>{t('viewTransaction:transactionGrid.totalSum')}</td>
                    <td className="transaction-grid__row-amount">
                        <MoneyFormatter amount={manager.totalSum.amount} currencyCode={manager.totalSum.currency} />
                    </td>
                </tr>}
            </tbody>
        </PaginatedGrid>
    </Fragment>
})
