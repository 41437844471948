import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

const cancelPacketDeliveryKnownErrorCodes = {
    undeliveryTimeout: 'undeliveryTimeout' as const,
    notUndelivered: 'notUndelivered' as const,
    undeliveryLocked: 'undeliveryLocked' as const,
}

export const cancelPacketDeliveryEndpoint: Endpoint<string, keyof typeof cancelPacketDeliveryKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/packet/cancel-delivery',
    knownErrorCodes: cancelPacketDeliveryKnownErrorCodes,
}

export const codInfoEndpoint: Endpoint<'barcode'> = {
    method: HttpMethod.GET,
    url: '/packet/cod-info/:barcode',
    routeParamNames: ['barcode'],
}

export const confirmPresenceEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/packet/confirm-presence',
}

const detailEndpointKnownErrorCodes = {
    alreadyDelivered: 'alreadyDelivered' as const,
    passwordValidButReturnPacket: 'passwordValidButReturnPacket' as const,
    passwordValidForAnotherBranch: 'passwordValidForAnotherBranch' as const,
};

export const deliveryDetailEndpoint: Endpoint<'searchStr', keyof typeof detailEndpointKnownErrorCodes> = {
    method: HttpMethod.GET,
    url: '/packet/delivery-detail/:searchStr',
    routeParamNames: ['searchStr'],
    knownErrorCodes: detailEndpointKnownErrorCodes,
    notFoundErrorResponse: true,
}

export const detailEndpoint: Endpoint<'searchStr', keyof typeof detailEndpointKnownErrorCodes> = {
    method: HttpMethod.GET,
    url: '/packet/detail/:searchStr',
    routeParamNames: ['searchStr'],
    knownErrorCodes: detailEndpointKnownErrorCodes,
    notFoundErrorResponse: true,
}

const deliverEndpointKnownErrorCodes = {
    alreadyDelivered: 'alreadyDelivered' as const,
    passwordValidForAnotherBranch: 'passwordValidForAnotherBranch' as const,
    passwordValidButReturnPacket: 'passwordValidButReturnPacket' as const,
    deliveryLocked: 'deliveryLocked' as const,
    notDelivered: 'notDelivered' as const,
    canNotDeliverYet: 'canNotDeliverYet' as const,
    deliveryProhibited: 'deliveryProhibited' as const,
    notInPickupReadyState: 'notInPickupReadyState' as const,
};

export const deliverEndpoint: Endpoint<string, keyof typeof deliverEndpointKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/packet/deliver',
    knownErrorCodes: deliverEndpointKnownErrorCodes,
    notFoundErrorResponse: true,
}

export const getProlongInfoEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/packet/prolong',
    notFoundErrorResponse: true,
}

const getReturnShipmentKnownErrorCodes = {
    badEshopCodeOrPassword: 'badEshopCodeOrPassword' as const,
}

export const getReturnShipmentEndpoint: Endpoint<string, keyof typeof getReturnShipmentKnownErrorCodes> = {
    method: HttpMethod.GET,
    url: '/packet/return-shipment/receive',
    knownErrorCodes: getReturnShipmentKnownErrorCodes,
}

export const correctButUsedReceiveKnownErrorCodes = {
    alreadyReceived: 'alreadyReceived' as const,
    passwordAlreadyUsed: 'passwordAlreadyUsed' as const,
}

const notifyKnownErrorCodes = {
    notSent: 'notSent' as const,
    packetNotDeliverableYet: 'packetNotDeliverableYet' as const,
    packetAlreadyDelivered: 'packetAlreadyDelivered' as const,
    notificationAlreadySent: 'notificationAlreadySent' as const
}

export const notifyEndpoint: Endpoint<string, keyof typeof notifyKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/packet/notify',
    knownErrorCodes: notifyKnownErrorCodes,
}

export const prolongKnownErrorCodes = {
    notProlonged: 'notProlonged' as const,
}

export const prolongEndpoint: Endpoint<string, keyof typeof prolongKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/packet/prolong',
    knownErrorCodes: prolongKnownErrorCodes,
}

export const receiveEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/packet/receive',
}

const receiveClaimFromCustomerKnownErrorCodes = {
    alreadyReceived: correctButUsedReceiveKnownErrorCodes.alreadyReceived,
    invalidPassword: 'invalidPassword' as const,
}

export const receiveClaimFromCustomerEndpoint: Endpoint<string, keyof typeof receiveClaimFromCustomerKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/packet/claim-from-customer/receive',
    knownErrorCodes: receiveClaimFromCustomerKnownErrorCodes,
}

const receiveB2CEndpointKnownErrorCodes = {
    ...correctButUsedReceiveKnownErrorCodes,
    invalidPassword: 'invalidPassword' as const,
    nonConsignBranch: 'nonConsignBranch' as const,
    wrongConsignmentCountry: 'wrongConsignmentCountry' as const,
}

export const receiveB2CEndpoint: Endpoint<string, keyof typeof receiveB2CEndpointKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/packet/b2c/receive',
    knownErrorCodes: receiveB2CEndpointKnownErrorCodes,
}

const receiveC2CEndpointKnownErrorCodes = {
    ...receiveB2CEndpointKnownErrorCodes,
    unpaidPacket: 'unpaidPacket' as const,
    packetIsReturnAlready: 'packetIsReturnAlready' as const,
}

export const receiveC2CEndpoint: Endpoint<string, keyof typeof receiveB2CEndpointKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/packet/c2c/receive',
    knownErrorCodes: receiveC2CEndpointKnownErrorCodes,
}

const receiveReturnShipmentKnownErrorCodes = {
    notConsigned: 'notConsigned' as const,
    clientNotAllowedToPostPackets: 'clientNotAllowedToPostPackets' as const,
}

export const receiveReturnShipmentEndpoint: Endpoint<string, keyof typeof receiveReturnShipmentKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/packet/return-shipment/receive',
    knownErrorCodes: receiveReturnShipmentKnownErrorCodes,
}

export const reportIssueEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/packet/report-issue',
}

export const setPacketShelfEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/packet/set-shelf',
}

export const trackingEndpoint: Endpoint<'barcode'> = {
    method: HttpMethod.GET,
    url: '/packet/tracking/:barcode',
    routeParamNames: ['barcode'],
}

export const webPaymentLinkEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/packet/web-payment-link',
}
