import {useCallback, useRef} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {transactionDetail} from "@app/CardPayment/api/cardTerminalApi";
import {useSetTransactionStatus} from "@app/CardPayment/hooks/useSetTransactionStatus";
import {useTransactionStatusEffect} from "@app/CardPayment/hooks/useTransactionStatusEffect";
import {CardPaymentState, CardPaymentStatus, getTransaction} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";
import {CardTransactionStatusState} from "@app/CardPayment/model/CardTransactionStatus";
import {
    getTimeoutForCheck,
    MAX_AUTO_CHECKS_COUNT
} from "@app/CardPayment/utils/getTimeoutForCheck";

export type UseCheckTransactionReturn = () => void;

export const useCheckTransaction = (
    requiredPayButtonStatus: CardPaymentStatus.PAYING|CardPaymentStatus.CANCELLING,
    cardPaymentState: CardPaymentState,
    setCardPaymentState: (newState: CardPaymentState) => void,
    onTransactionFinished: (success: boolean, isTimeout?: boolean) => void,
): UseCheckTransactionReturn => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const lastCheck = useRef<number>(0);
    const timeoutRef = useRef<number|null>(null);

    const transaction = getTransaction(cardPaymentState);
    const setTransactionStatus = useSetTransactionStatus(cardPaymentState, setCardPaymentState);

    const checkTransaction = useCallback(async () => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }

        if (cardPaymentState.status !== requiredPayButtonStatus || !transaction) {
            setTransactionStatus(CardPaymentTransactionStatus.ERROR, t('cardPayment:error.payment'));
            return;
        }

        let runningTransactionMessage: string|undefined;

        ++lastCheck.current;
        if (lastCheck.current > 1) { // First check is just scheduled
            try {
                const {cardTransaction} = await transactionDetail(transaction.id, appContext.api);
                if (cardTransaction.status.finished) {
                    onTransactionFinished(cardTransaction.status.success);
                    lastCheck.current = 0;
                    return;
                }
                if (cardTransaction.status.state === CardTransactionStatusState.SIGNATURE_REQUESTED && !transaction.signatureVerified) {
                    setTransactionStatus(CardPaymentTransactionStatus.AWAITING_SIGNATURE_VERIFICATION);
                    return;
                }
                runningTransactionMessage = cardTransaction.status.message;
            } catch (error: unknown) {
                // Intentionally ignoring transaction detail errors,
                // cannot decide about the transaction
            }
        }

        if (lastCheck.current === MAX_AUTO_CHECKS_COUNT) {
            onTransactionFinished(false, true);
            lastCheck.current = 0;
        } else {
            setTransactionStatus(CardPaymentTransactionStatus.RUNNING, runningTransactionMessage);
            // Schedule next check
            timeoutRef.current = window.setTimeout(
                checkTransaction,
                getTimeoutForCheck(lastCheck.current, requiredPayButtonStatus)
            );
        }
    }, [appContext.api, cardPaymentState.status, onTransactionFinished, requiredPayButtonStatus, setTransactionStatus, t, transaction]);

    useTransactionStatusEffect(cardPaymentState, (transactionStatus) => {
        if (cardPaymentState.status === requiredPayButtonStatus
            && transactionStatus === CardPaymentTransactionStatus.CREATED) {
            void checkTransaction();
        }

        return window.clearTimeout;
    });

    return useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }
        lastCheck.current = 0;
    }, []);
}
