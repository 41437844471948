import {useCallback, useEffect, useRef} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {recipientPickupReady} from "@app/Packet/api/packetListApi";
import {Deliver} from "@app/Packet/components/PacketDelivery/hooks/useDeliver";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";
import {createDeliveryPacket} from "@app/Packet/components/PacketDelivery/utils/createDeliveryPacket";
import {PacketDeliveryType} from "@app/Packet/model/PacketDeliveryType";
import {
    OnPasswordHandlingError,
    OnPasswordHandlingVerified
} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {extractEmbedded} from "@common/utils/extractEmbedded";

export const useLoadPickupReadyPackets = (
    packets: DeliveryPacketsCollection,
    password: string,
    deliver: Deliver,
    onVerified: OnPasswordHandlingVerified,
    onError: OnPasswordHandlingError,
) => {
    const appContext = useAppContext();

    const showAppErrorToast = useShowErrorToast(appToast, 'packet:error', 'packet:error.deliveryError');
    const loadingStarted = useRef<boolean>(false);

    const load = useCallback(async (): Promise<void> => {
        const deliveryPackets: DeliveryPacket[] = [];

        try {
            const packetDetailResponse = await recipientPickupReady({password, embed: [Embeddable.SENDER]}, appContext.api);
            if (packetDetailResponse.items.length === 0) {
                onError(password);
                return;
            }

            packetDetailResponse.items.forEach((packet) => {
                deliveryPackets.push(createDeliveryPacket(
                    packet,
                    extractEmbedded(packetDetailResponse, Embeddable.SENDER),
                ));
            });

            await deliver(deliveryPackets[0], {type: PacketDeliveryType.PASSWORD, password});
            packets.load(deliveryPackets);
            onVerified(password);
        } catch (error: unknown) {
            onError(password, () => {
                showAppErrorToast(error as Error);
            });
        }
    }, [appContext.api, deliver, onError, onVerified, packets, password, showAppErrorToast]);

    useEffect(() => {
        if (!packets.loaded && !loadingStarted.current) {
            loadingStarted.current = true;
            void load();
        }
    }, [load, packets.loaded]);
}
