import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {BranchCommissionRoute, ManagedCommissionRoute} from "@app/Commission/CommissionRoutes";
import {
    BillingInformation
} from "@app/Commission/components/BillingInformation/BillingInformation";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";
import {useCaslGuard} from "@common/hooks/useCaslGuard";
import {useSingleBranchTo} from "@common/hooks/useSingleBranchTo";

export const BillingInformationPage: FC = () => {
    const {t} = useTranslation();

    const branchDetailTo = useSingleBranchTo();
    const canShow = useCaslGuard(Action.SHOW, Subject.BILLING_INFORMATION, branchDetailTo(BranchCommissionRoute, ManagedCommissionRoute));
    if (!canShow) {
        return null;
    }

    return <PageContent>
        <ReturnLink
            to={branchDetailTo(BranchCommissionRoute, ManagedCommissionRoute)}
            label={t('invoice:action.goBackToCommission')}
        />
        <PageHeader>{t('viewCommission:billingInformation.header')}</PageHeader>
        <BillingInformation />
    </PageContent>
}
