import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

const passwordRecoveryKnownErrorCodes = {
    unknownEmail: 'unknownEmail' as const,
    branchAccountNotFound: 'branchAccountNotFound' as const,
    passwordNotComplexEnough: 'passwordNotComplexEnough' as const,
}

export const changePasswordEndpoint: Endpoint<string, keyof typeof passwordRecoveryKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/password-recovery/change-password',
    authenticate: false,
    knownErrorCodes: passwordRecoveryKnownErrorCodes,
}

export const createTokenEndpoint: Endpoint<string, keyof typeof passwordRecoveryKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/password-recovery/create-token',
    authenticate: false,
    knownErrorCodes: passwordRecoveryKnownErrorCodes,
}

const sendLinkKnownErrorCodes = {
    contactEmailNotFound: 'contactEmailNotFound' as const,
    invalidEmailOrRecoverToken: 'invalidEmailOrRecoverToken' as const,
}

export const sendLinkEndpoint: Endpoint<string, keyof typeof sendLinkKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/password-recovery/send-link',
    authenticate: false,
    knownErrorCodes: sendLinkKnownErrorCodes,
}
