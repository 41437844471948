import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Branch} from "@app/Branch/model/Branch";
import {ParcelDetailed} from "@app/Parcel/model/ParcelDetailed";
import {ParcelRouteInfo} from "@app/Parcel/pages/ParcelDetailPage/ParcelRouteInfo/ParcelRouteInfo";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {DetailInfoBox, DetailInfoItem} from "@common/components/DetailInfoBox/DetailInfoBox";
import {Img} from "@common/components/Img/Img";
import {TranslationWithHtmlParam} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export type ParcelDetailInfoProps = {
    parcel: ParcelDetailed;
    branchesMap: Map<string, Branch>;
}

export const ParcelDetailInfo: FC<ParcelDetailInfoProps> = ({branchesMap, parcel}) => {
    const {t} = useTranslation();

    const info: DetailInfoItem[] = [
        {
            label: t('parcel:property.packetCount.title'),
            content: <div className="parcel-packet-count">
                <TranslationWithHtmlParam
                    paramName="receivedPacketCount"
                    translationKey="parcel:property.packetCount.received"
                    t={t}
                >
                    {parcel.receivedPacketCount}
                </TranslationWithHtmlParam>
                {parcel.receivedPacketCount !== parcel.packetCount && <TranslationWithHtmlParam
                    paramName="totalPacketCount"
                    translationKey="parcel:property.packetCount.total"
                    t={t}
                >
                    {parcel.packetCount}
                </TranslationWithHtmlParam>}
                {parcel.missingPacketCount > 0 && <span className="parcel-packet-count-missing">
                    {t('parcel:property.packetCount.missing', parcel)}{' '}
                    <Img
                        src="/images/icons/ico/ico-exclamation-red.svg"
                        alt={t('parcel:property.packetCount.missing', parcel)}
                    />
                </span>}
                {parcel.additionalPacketCount > 0 && <span className="parcel-packet-count-additional">
                        {t('parcel:property.packetCount.additional', parcel)}{' '}
                    <Img
                        src="/images/icons/ico/ico-exclamation-2.svg"
                        alt={t('parcel:property.packetCount.additional', parcel)}
                    />
                    </span>}
            </div>
        },
        {label: t('parcel:property.tracingCode'), content: parcel.trackingCode},
        {
            label: t('parcel:property.carrier'),
            content: dashWhenEmpty(parcel.carrier && <span>
                {`${parcel.carrier.name}, `}
                {`${parcel.carrier.address.street}, ${parcel.carrier.address.zip} ${parcel.carrier.address.city}, `}
                {`${t('carrier:property.companyId')}: ${parcel.carrier.tin} `}
                {`${t('carrier:property.vatId')}: ${parcel.carrier.vatId}`}
            </span>)
        },
        {
            label: t('parcel:property.createdAt'),
            content: <DateFormatter date={parcel.createdAt} showTime={true} />
        },
    ];

    (['sentAt', 'deliveredAt', 'receivedAt', 'contentConfirmedAt'] as const).forEach((date) => {
        const parcelDateString = parcel[date];
        if (parcelDateString) {
            info.push({
                label: t(`parcel:property.${date}`),
                content: <DateFormatter date={parcelDateString} showTime={true} />
            });
        }
    });

    return <div>
        <ParcelRouteInfo
            receivedByBranch={parcel.receivedByBranchId !== null ? branchesMap.get(parcel.receivedByBranchId) : undefined}
            recipientBranch={parcel.recipientBranchId !== null ? branchesMap.get(parcel.recipientBranchId) : undefined}
            senderBranch={parcel.senderBranchId !== null ? branchesMap.get(parcel.senderBranchId) : undefined}
        />
        <DetailInfoBox info={info} />
    </div>
}
