import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    BranchInventoryListManager
} from "@app/Inventory/pages/BranchInventoryPage/BranchInventoryListManager";
import {
    PaginatedGridSorter
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/PaginatedGridSorter";
import {
    usePaginatedGridSorter
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/usePaginatedGridSorter";

export const BRANCH_INVENTORY_LIST_SORTING_STORAGE_KEY = 'ui-branchInventoryList-sorting';

export type BranchInventoryListHeaderProps = {
    manager: BranchInventoryListManager;
}

export const BranchInventoryListHeader: FC<BranchInventoryListHeaderProps> = observer(({manager}) => {
    const {t} = useTranslation();

    const setStoredSorting = usePaginatedGridSorter(
        manager,
        BRANCH_INVENTORY_LIST_SORTING_STORAGE_KEY,
         {key: 'createdAt', desc: true},
    );

    if (!manager.hasItems) {
        return null;
    }

    return <thead>
        <tr>
            <th><PaginatedGridSorter manager={manager} sortBy="createdAt" onSort={setStoredSorting}>
                {t('inventory:property.createdAt')}
            </PaginatedGridSorter></th>
            <th>
                {t('inventory:property.branchName')}
                <div className="branch-inventory-page__branch-name-created-by">
                    {' / '}{t('inventory:property.createdBy')}
                </div>
            </th>
            <th className="branch-inventory-page__created-by">{t('inventory:property.createdBy')}</th>
            <th>{t('inventory:property.badItemsCount')}</th>
            <th>{t('viewInventory:branchInventoryList.grid.confirmed')}</th>
            <th />
        </tr>
    </thead>
})
