import {FC, Fragment, useState} from "react";
import {useTranslation} from "react-i18next";

import {PrintCardReceipt} from "@app/Packet/components/PacketActions/components/PacketPrints/PrintCardReceipt/PrintCardReceipt";
import {PrintCodReceipt} from "@app/Packet/components/PacketActions/components/PacketPrints/PrintCodReceipt/PrintCodReceipt";
import {PrintConsignmentReceipt} from "@app/Packet/components/PacketActions/components/PacketPrints/PrintConsignmentReceipt/PrintConsignmentReceipt";
import {PrintDeliveryReceipt} from "@app/Packet/components/PacketActions/components/PacketPrints/PrintDeliveryReceipt/PrintDeliveryReceipt";
import {PrintLabelForm} from "@app/Packet/components/PacketActions/components/PacketPrints/PrintLabelForm/PrintLabelForm";
import {Packet} from "@app/Packet/model/Packet";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {Button} from "@common/components/Button/Button";
import {Img} from "@common/components/Img/Img";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

import './packetPrints.scss';

export type PrintProps = {
    packet: Packet;
}

export const PacketPrints: FC<PrintProps> = ({packet}) => {
    const {t} = useTranslation();

    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    return <Fragment>
        <Button
            onClick={() => setModalOpened(true)}
            title={t('viewPacketPrint:title')}
            variant="secondary"
        >
            <Img src="/images/icons/ico/ico-print.svg" alt={t('viewPacketPrint:title')} />
        </Button>
        <BaseModal
            show={modalOpened}
            onHide={() => setModalOpened(false)}
            header={t('viewPacketPrint:title')}
            footer={<Button onClick={() => setModalOpened(false)}>{t('viewPacketReportIssue:action.close')}</Button>}
            loading={isSubmitting}
            size="lg"
            className="packet-prints"
        >
            {![
                PacketStatusId.RETURNED,
                PacketStatusId.CANCELLED,
                PacketStatusId.PROBLEM_FINISHED,
            ].includes(packet.status.id)
                ? <div className="packet-prints__controls">
                    <div>
                        <PrintLabelForm packet={packet} setIsSubmitting={setIsSubmitting} />
                        <PrintDeliveryReceipt packet={packet} setIsSubmitting={setIsSubmitting} />
                        <PrintCodReceipt packet={packet} setIsSubmitting={setIsSubmitting} />
                        <PrintConsignmentReceipt packet={packet} setIsSubmitting={setIsSubmitting} />
                        <PrintCardReceipt packet={packet} setIsSubmitting={setIsSubmitting} />
                    </div>
                </div>
                : <span>{t('viewPacketPrint:nothingToPrint')}</span>
            }
        </BaseModal>
    </Fragment>
}
