import {observer} from "mobx-react-lite";
import {FC, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {
    OpeningHoursGrid
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGrid";
import {
    OpeningHoursGridRow
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGridRow/OpeningHoursGridRow";
import {
    RegularOpeningHoursEditModal
} from "@app/OpeningHours/components/RegularOpeningHours/RegularOpeningHoursEditModal/RegularOpeningHoursEditModal";
import {useHeaderForDayOfWeek} from "@app/OpeningHours/components/RegularOpeningHours/useHeaderForDayOfWeek";
import {useOpeningHours} from "@app/OpeningHours/context/useOpeningHours";

import './regularOpeningHours.scss';

export const RegularOpeningHours: FC = observer(() => {
    const {t} = useTranslation();
    const [editModalOpened, setEditModalOpened] = useState<boolean>(false);

    const {regularHours} = useOpeningHours();

    const headerForDayOfWeek = useHeaderForDayOfWeek();

    return <div className="regular-opening-hours">
        <h4>{t('openingHours:page.regularOpeningHours.title')}</h4>
        <Can I={Action.MANAGE} a={Subject.BRANCH_HOURS}>
            <Button onClick={() => setEditModalOpened(true)} variant="link" data-xid="regular-hours-edit">
                {t('openingHours:page.regularOpeningHours.edit')}
            </Button>
            <RegularOpeningHoursEditModal
                show={editModalOpened}
                onHide={() => setEditModalOpened(false)}
            />
        </Can>
        {regularHours.asGridHours.length && <OpeningHoursGrid>
            {regularHours.asGridHours.map((gridHours) => {
                return <OpeningHoursGridRow
                    blocks={gridHours.hours}
                    header={headerForDayOfWeek(gridHours.date)}
                    key={gridHours.date}
                />
            })}
        </OpeningHoursGrid>}
    </div>
})
