import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {ParcelStatusId} from "@app/Parcel/model/ParcelStatus";
import {ParcelType} from "@app/Parcel/model/ParcelType";
import {
    IncomingParcelGridHeader
} from "@app/Parcel/pages/BranchParcelListPage/IncomingParcelGrid/IncomingParcelGridHeader/IncomingParcelGridHeader";
import {
    ParcelMissingInfo
} from "@app/Parcel/pages/BranchParcelListPage/IncomingParcelGrid/ParcelMissingInfo/ParcelMissingInfo";
import {IncomingParcelListManager} from "@app/Parcel/pages/BranchParcelListPage/IncomingParcelListManager";
import {ParcelStatusAndCount} from "@app/Parcel/pages/BranchParcelListPage/ParcelStatusAndCount/ParcelStatusAndCount";
import {ParcelDetailRoute} from "@app/Parcel/ParcelRoutes";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import '../branchParcelList.scss';

export type IncomingParcelGridProps = {
    manager: IncomingParcelListManager;
}

export const IncomingParcelGrid: FC<IncomingParcelGridProps> = observer(({manager}) => {
    const {t} = useTranslation();

    return <div>
        <h4>{t('viewParcel:incomingParcels')}</h4>
        <PaginatedGrid manager={manager}>
            <IncomingParcelGridHeader manager={manager} />
            <tbody>
                {manager.items.map((parcel) => {
                    const isSuccess = parcel.missingPacketCount === 0 && parcel.status && (
                        (parcel.type === ParcelType.UNDEFINED && parcel.status.id === ParcelStatusId.CONFIRMED)
                        || (parcel.type === ParcelType.MICRODEPOT && parcel.status.id === ParcelStatusId.DELIVERED)
                    );
                    const receivedOrConfirmedAt = parcel.receivedAt || parcel.contentConfirmedAt;

                    return <tr key={parcel.id} className={classNames('branch-parcel-list__row', {
                        'branch-parcel-list__row-missing': parcel.missingPacketCount > 0,
                        'branch-parcel-list__row-success': isSuccess,
                    })}>
                        <td className="branch-parcel-list__parcel-id">
                            <div>
                                <AppLink
                                    to={{route: ParcelDetailRoute, params: {parcelId: parcel.id}}}
                                    title={t('parcel:action.goToDetail')}
                                >
                                    {parcel.barcode}
                                </AppLink>
                                {parcel.type === ParcelType.MICRODEPOT && <span
                                    className="branch-parcel-list__parcel-id-microdepot"
                                >|MD|</span>}
                            </div>
                            <div className="branch-parcel-list__parcel-id-count-status">
                                <ParcelStatusAndCount parcel={parcel} />
                            </div>
                            <ParcelMissingInfo parcel={parcel}/>
                        </td>
                        <td className="branch-parcel-list__status">
                            <ParcelStatusAndCount parcel={parcel} />
                        </td>
                        <td className="branch-parcel-list__date">
                            {dashWhenEmpty(receivedOrConfirmedAt && <DateFormatter date={receivedOrConfirmedAt} showYear={false}  />)}
                        </td>
                        <td className="branch-parcel-list__date">
                            {dashWhenEmpty(parcel.sentAt && <DateFormatter date={parcel.sentAt} showYear={false} />)}
                        </td>
                        <td className="branch-parcel-list__count">
                            <strong>{parcel.packetCount}</strong>
                        </td>
                        <td>
                            <AppLink
                                className="btn btn-secondary"
                                to={{route: ParcelDetailRoute, params: {parcelId: parcel.id}}}
                                title={t('parcel:action.goToDetail')}
                            >
                                {t('parcel:action.viewDetail')}
                            </AppLink>
                        </td>
                    </tr>
                })}
            </tbody>
        </PaginatedGrid>
    </div>
})
