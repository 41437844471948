
import { useCallback, useState} from "react";

import {Button, ButtonProps} from "@common/components/Button/Button";
import {InlineLoading} from "@common/components/Loading/InlineLoading/InlineLoading";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './loadingButton.scss';

export type LoadingButtonOnClick = (stopLoading: () => void) => void;

export type LoadingButtonProps = ButtonProps & {
    onLoadingClick: LoadingButtonOnClick;
}

export const LoadingButton: FCWithChildren<LoadingButtonProps> = ({children, onLoadingClick, ...buttonProps}) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleClick = useCallback(() => {
        setLoading(true);
        onLoadingClick(() => setLoading(false));
    }, [onLoadingClick]);

    buttonProps.variant = buttonProps.variant || 'primary';

    return <Button {...buttonProps} onClick={handleClick} disabled={loading || buttonProps.disabled}>
        <InlineLoading active={loading}>{children}</InlineLoading>
    </Button>

}
