import {TFunction} from "i18next";
import {useCallback, useEffect, useState} from "react";
import {useForm, UseFormReturn} from "react-hook-form";

import {useCardPayment} from "@app/CardPayment/hooks/useCardPayment";
import {useTransactionStatusEffect} from "@app/CardPayment/hooks/useTransactionStatusEffect";
import {CardPaymentState, CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransaction, CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";
import {MerchantTransactionInfo} from "@app/CardPayment/model/CardTransaction";
import {TransactionMerchant} from "@app/CardPayment/model/TransactionMerchant";
import {
    PartnerCardTransactionListManager
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionListManager";
import {appToast} from "@common/components/Toast/toastOpener";
import {normalizeDecimalSeparator} from "@common/utils/normalizeDecimalSeparator";

type PartnerCardTransactionFormData = {
    amount: string;
    reference: string;
}

export type UsePartnerCardTransactionForm = {
    cancelPayment: () => Promise<void>;
    cardPaymentState: CardPaymentState;
    confirmSignature: () => void;
    finishedTransaction: CardPaymentTransaction|null;
    form: UseFormReturn<PartnerCardTransactionFormData>;
    isUnsuccessful: boolean;
    onSubmit: (data: PartnerCardTransactionFormData) => void;
    paymentRunning: boolean;
}

export const usePartnerCardTransactionForm = (
    t: TFunction,
    currencyCode: string|null,
    manager: PartnerCardTransactionListManager,
): UsePartnerCardTransactionForm => {
    const form = useForm<PartnerCardTransactionFormData>({
        defaultValues: {amount: '', reference: ''},
        mode: 'onSubmit',
    })
    const [paymentRunning, setPaymentRunning] = useState<boolean>(false);
    const [finishedTransaction, setFinishedTransaction] = useState<CardPaymentTransaction|null>(null);
    const [isUnsuccessful, setIsUnsuccessful] = useState<boolean>(false);

    useEffect(() => {
        if (!form.formState.isDirty) {
            window.setTimeout(() => {
                form.setFocus('amount');
            }, 0);
        }
    }, [form]);

    const onTransactionSuccess = useCallback((transaction: CardPaymentTransaction) => {
        setFinishedTransaction(transaction);
        setIsUnsuccessful(false);
        appToast.success(t('viewPartnerCardTransaction:message.successfulPayment'), {toastId: 'partner-card-transaction-paid'});
        form.reset();
        form.setFocus('amount');
        void manager.reload();
    }, [form, manager, t]);

    const onCancellationSuccess = useCallback(() => {
        setFinishedTransaction(null);
        setIsUnsuccessful(false);
        appToast.success(t('viewPartnerCardTransaction:message.successfulCancelling'), {toastId: 'partner-card-transaction-cancelled'});
        form.reset();
        form.setFocus('amount');
        void manager.reload();
    }, [form, manager, t]);

    const {
        cancelPayment,
        cardPaymentState,
        confirmSignature,
        pay,
    } = useCardPayment(
        onTransactionSuccess,
        onCancellationSuccess,
        setPaymentRunning,
        undefined,
        finishedTransaction ? finishedTransaction.id : undefined,
        false,
    );

    useTransactionStatusEffect(cardPaymentState, (transactionStatus) => {
        if (
            (cardPaymentState.status === CardPaymentStatus.CANCELLING && transactionStatus === CardPaymentTransactionStatus.SUCCESS)
            || (transactionStatus === CardPaymentTransactionStatus.ERROR)
        ) {
            setIsUnsuccessful(true);
            void manager.reload();
        }
    });

    const onSubmit = useCallback(async (values: PartnerCardTransactionFormData) => {
        if (!currencyCode) {
            return;
        }

        setFinishedTransaction(null);
        setIsUnsuccessful(false);
        setPaymentRunning(true);
        const transactionInfo: MerchantTransactionInfo = {
            merchant: TransactionMerchant.PARTNER,
        };
        const referenceNumber = parseInt(values.reference, 10);
        if (!isNaN(referenceNumber) && isFinite(referenceNumber)) {
            transactionInfo.reference = referenceNumber;
        }

        await pay(normalizeDecimalSeparator(values.amount), currencyCode, transactionInfo);
    }, [currencyCode, pay]);

    return {
        cancelPayment,
        cardPaymentState,
        confirmSignature,
        finishedTransaction,
        form,
        isUnsuccessful,
        onSubmit,
        paymentRunning,
    }
}
