import {useCallback} from "react";

import {isKnownError} from "@common/utils/api/isKnownError";

export const useErrorCodeToTranslationKey = (
    translationKeyPrefix?: string,
    unknownErrorTranslationKey?: string,
) => {

    return useCallback((errorCode: string|null, error: Error): string => {
        if (errorCode && isKnownError(error, errorCode)) {
            return translationKeyPrefix ? `${translationKeyPrefix}.${errorCode}` : error.message;
        } else {
            return unknownErrorTranslationKey || error.message;
        }
    }, [translationKeyPrefix, unknownErrorTranslationKey]);
}
