import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const atBranchPacketListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/packet/list/at-branch/:branchId',
    routeParamNames: ['branchId'],
}

export const branchPacketListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/packet/list/:branchId',
    routeParamNames: ['branchId'],
}

export const packetListByBarcodeEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/packet/list/by-barcode',
}

export const parcelPacketListEndpoint: Endpoint<'parcelId'> = {
    method: HttpMethod.GET,
    url: '/packet/list/by-parcel/:parcelId',
    routeParamNames: ['parcelId'],
}

export const presenceReviewPacketsListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/packet/list/presence-review/:branchId',
    routeParamNames: ['branchId'],
}

const recipientPickupReadyErrorCodes = {
    alreadyDelivered: 'alreadyDelivered' as const,
    notDeliveryPassword: 'notDeliveryPassword' as const,
    passwordValidButReturnPacket: 'passwordValidButReturnPacket' as const,
    passwordValidForAnotherBranch: 'passwordValidForAnotherBranch' as const,
};

export const recipientPickupReadyEndpoint: Endpoint<'password', keyof typeof recipientPickupReadyErrorCodes> = {
    method: HttpMethod.GET,
    url: '/packet/list/recipient-pickup-ready/:password',
    routeParamNames: ['password'],
    knownErrorCodes: recipientPickupReadyErrorCodes,
}

export const todaysIncomingPacketListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/packet/list/todays-incoming/:branchId',
    routeParamNames: ['branchId'],
}

export const toDispatchPacketListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/packet/list/to-dispatch/:branchId',
    routeParamNames: ['branchId'],
}

