import {useWindowWidth} from "@react-hook/window-size";
import {useCallback, useEffect, useRef, useState} from "react";

import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";

export const useDeliveryPacketsOverlay = (deliveredPackets: DeliveryPacket[], notDeliveredPackets: DeliveryPacket[]) => {
    const [offset, setOffset] = useState<number|null>(null);

    const containerRef = useRef<HTMLDivElement>(null);
    const singlePacketRef = useRef<HTMLDivElement>(null);
    const deliveredPacketsRef = useRef<HTMLTableSectionElement>(null);
    const notDeliveredPacketsRef = useRef<HTMLTableSectionElement>(null);

    const windowWidth = useWindowWidth();

    const recalculateOffset = useCallback(() => {
        const topRef = deliveredPackets.length === 1 ? singlePacketRef : deliveredPacketsRef;

        if (containerRef.current && topRef.current && notDeliveredPacketsRef.current) {
            setOffset(topRef.current.offsetTop + topRef.current.offsetHeight);
        } else {
            setOffset(null);
        }
    }, [deliveredPackets.length]);

    useEffect(() => {
        recalculateOffset();
    }, [recalculateOffset, windowWidth, notDeliveredPackets.length]);

    return {
        containerRef,
        singlePacketRef,
        deliveredPacketsRef,
        notDeliveredPacketsRef,
        offset,
        recalculateOffset,
    }
}
