import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect, useState} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {ModalBranchEvent} from "@app/BranchEvent/ModalBranchEvent";
import {
    PriorityMessageModal
} from "@common/layouts/SecuredLayout/PriorityEventsAndMessages/PriorityMessageModal/PriorityMessageModal";

export type PriorityEventsAndMessagesViewProps = {
    onFinish: () => void;
}

export const PriorityEventsAndMessagesView: FC<PriorityEventsAndMessagesViewProps> = observer(({onFinish}) => {
    const {branchEvents, dashboardMessages} = useAppContext();

    const [openedModalIndex, openModal] = useState<number>(0);

    const highSeverityBranchEvents = branchEvents.highSeverityEvents;
    const priorityDashboardMessages = dashboardMessages.priorityMessages;
    const listsLoaded = branchEvents.loaded && dashboardMessages.loaded;

    const finish = useCallback(() => {
        onFinish();
        openModal(-1);

        if (!priorityDashboardMessages.length && !highSeverityBranchEvents.length) {
            return;
        }

        branchEvents.setReloadRequired(true);
    }, [branchEvents, onFinish, highSeverityBranchEvents.length, priorityDashboardMessages.length])

    const openNext = useCallback(() => {
        if (openedModalIndex === highSeverityBranchEvents.length + priorityDashboardMessages.length - 1) {
            finish();
        } else {
            openModal(openedModalIndex + 1);
        }
    }, [finish, openedModalIndex, highSeverityBranchEvents.length, priorityDashboardMessages.length])

    useEffect(() => {
        if (listsLoaded && openedModalIndex === -1 && highSeverityBranchEvents.length + priorityDashboardMessages.length) {
            openNext();
        }
    }, [listsLoaded, openedModalIndex, openNext, highSeverityBranchEvents.length, priorityDashboardMessages.length])

    if (openedModalIndex === -1) {
        return null;
    }

    if (openedModalIndex < highSeverityBranchEvents.length) {
        return <ModalBranchEvent event={highSeverityBranchEvents[openedModalIndex]} onClose={openNext} />
    }

    const messageIndex = openedModalIndex - highSeverityBranchEvents.length;
    if (messageIndex < priorityDashboardMessages.length) {
        return <PriorityMessageModal message={priorityDashboardMessages[messageIndex]} onClose={openNext} />
    }
})
