import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const accountingDocumentsEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/commission/accounting-documents',
}

export const accountingDocumentsNettingAsPdfEndpoint: Endpoint<'nettingId'> = {
    method: HttpMethod.POST,
    url: '/commission/accounting-documents/netting/:nettingId/asPdf',
    routeParamNames: ['nettingId'],
}

export const commissionBranchListEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/commission/list/branches',
}

export const commissionInvoiceBillingInformationEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/commission/invoice/billing-information/:branchId',
    routeParamNames: ['branchId'],
    notFoundErrorResponse: true,
}

export const commissionInvoiceListEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/commission/invoice/list',
}

export const commissionListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/commission/list/:branchId',
    routeParamNames: ['branchId'],
}

export const commissionTariffListEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/commission/tariff/list',
}

export const commissionTypeListEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/commission/type/list',
}

export const createCommissionKnownErrorCodes = {
    commissionInvoiceAlreadyIssued: 'commissionInvoiceAlreadyIssued' as const,
    commissionInvoiceCreationInProgress: 'commissionInvoiceCreationInProgress' as const,
    issuedDateOutOfRange: 'issuedDateOutOfRange' as const,
    canNotCreateInvoice: 'canNotCreateInvoice' as const,
    invalidInvoicedCurrency: 'invalidInvoicedCurrency' as const,
    invalidInvoicedAmount: 'invalidInvoicedAmount' as const,
    canNotValidateInvoiceAddress: 'canNotValidateInvoiceAddress' as const,
    bankAccountNotSet: 'bankAccountNotSet' as const,
    invalidArgument: 'invalidArgument' as const,
    invalidType: 'invalidType' as const,
    duplicateInvoiceNumber: 'duplicateInvoiceNumber' as const,
}

export const removeCommissionKnownErrorCodes = {
    invoiceBeingRemoved: 'invoiceBeingRemoved' as const,
    invoiceNotRemovable: 'invoiceNotRemovable' as const,
}

export const createCommissionInvoiceEndpoint: Endpoint<string, keyof typeof createCommissionKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/commission/invoice',
    knownErrorCodes: createCommissionKnownErrorCodes,
    notFoundErrorResponse: true,
}

export const removeCommissionInvoiceEndpoint: Endpoint<'invoiceToken', keyof typeof removeCommissionKnownErrorCodes> = {
    method: HttpMethod.DELETE,
    url: '/commission/invoice/:invoiceToken',
    routeParamNames: ['invoiceToken'],
    knownErrorCodes: removeCommissionKnownErrorCodes,
    notFoundErrorResponse: true,
}

const editCommissionBillingInformationKnownErrorCodes = {
    accountNumberNotValid: 'accountNumberNotValid' as const,
    accountNumberAlreadySet: 'accountNumberAlreadySet' as const,
}

export const editCommissionBillingInformationEndpoint: Endpoint<string, keyof typeof editCommissionBillingInformationKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/commission/invoice/billing-information',
    knownErrorCodes: editCommissionBillingInformationKnownErrorCodes,
}

export const monthlyCommissionListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/commission/list/monthly/:branchId',
    routeParamNames: ['branchId'],
}

export const registryUpdateBillingInformationKnownErrorCodes = {
    branchNotFound: 'branchNotFound' as const,
}

export const registryUpdateBillingInformationEndpoint: Endpoint<'branchId', keyof typeof registryUpdateBillingInformationKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/commission/invoice/billing-information/:branchId',
    routeParamNames: ['branchId'],
    knownErrorCodes: registryUpdateBillingInformationKnownErrorCodes,
}

export const unpaidCommissionsSumEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/commission/unpaid/:branchId',
    routeParamNames: ['branchId'],
}
