import {useRef} from "react";

export const useSelection = (): [() => void, (focusElement: HTMLElement|null) => Range|null] => {
    const range  = useRef<Range|null>(null);

    const storeSelection = () => {
        const selection = document.getSelection();
        range.current = selection ? selection.getRangeAt(0) : null;
    }

    const restoreSelection = (focusElement: HTMLElement|null): Range|null  => {
        if (range.current) {
            const selection = document.getSelection();
            if (selection) {
                selection.removeAllRanges();
                selection.addRange(range.current);
            }
        }

        const activeElement = document.activeElement;
        if (activeElement && focusElement && activeElement !== focusElement) {
            focusElement.focus();
        }

        return range.current;
    }

    return [
        storeSelection,
        restoreSelection,
    ]
}
