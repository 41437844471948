import {Fragment} from "react";
import {useTranslation} from "react-i18next";

import {ManualPostLink} from "@app/BranchEvent/components/ManualPostLink/ManualPostLink";
import {useBranchEventMessage} from "@app/BranchEvent/hooks/useBranchEventMessage";
import {useBranchEventTranslationKey} from "@app/BranchEvent/hooks/useBranchEventTranslationKey";
import {ExpeditionParcelPacketsMissingBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventComponent} from "@app/BranchEvent/model/BranchEventComponent";

export const ExpeditionParcelPacketsMissingBranchEventContent: BranchEventComponent<ExpeditionParcelPacketsMissingBranchEvent> = ({event}) => {
    const {t} = useTranslation();

    const translationKey = useBranchEventTranslationKey(event);
    const message = useBranchEventMessage(event);

    return <Fragment>
        <p dangerouslySetInnerHTML={{__html: message}} />
        <ManualPostLink postIdTranslationKey="support:whatToDoArticle.expeditionParcelPacketsMissing">
            {t(`${translationKey}WhatToDo`)}
        </ManualPostLink>
    </Fragment>
}
