import {ParametricReactRoute, SimpleReactRoute} from "@common/model/ReactRoute";

export type PostDetailParams = {
    id: string;
}

export const ManualsIndexRoute: SimpleReactRoute = {
    path: '',
}

export const ManualPostRoute: ParametricReactRoute<'id'> = {
    path: 'post/:id',
    paramNames: ['id'],
}

export const TermsAndConditionsRoute: SimpleReactRoute = {
    path: 'terms-and-conditions',
}

export const BenefitsRoute: SimpleReactRoute = {
    path: 'benefits',
}

export const ManualsRoutes = [
    ManualsIndexRoute,
    ManualPostRoute,
    TermsAndConditionsRoute,
    BenefitsRoute,
];
