import {ApiErrorHandler} from "@app/AppContext/classes/Api/apiErrorHandlers/ApiErrorHandler";
import {ApiError} from "@common/model/errors/ApiError";
import {TranslatableError} from "@common/model/errors/TranslatableError";
import {HttpStatusCode} from "@common/model/HttpStatusCode";

// eslint-disable-next-line @typescript-eslint/require-await
export const serviceUnavailableApiErrorHandler: ApiErrorHandler = async (error) => {
    if (error instanceof ApiError) {
        if (error.code === HttpStatusCode.SERVICE_UNAVAILABLE) {
            throw new TranslatableError('errors:503.description');
        }
    }
}
