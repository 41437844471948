import {differenceInSeconds} from "date-fns";
import {autorun} from "mobx";

import {Api} from "@app/AppContext/classes/Api/Api";
import {ApiBackend} from "@app/AppContext/classes/Api/model/ApiBackend";
import {ApiRequestWatcher, RunningRequest} from "@app/AppContext/classes/Api/model/ApiRequestWatcher";
import {UserData, UserState} from "@app/AppContext/classes/User/model/UserData";
import {User} from "@app/AppContext/classes/User/User";
import {expiresAtFromResponse} from "@app/AppContext/utils/expiresAtFromResponse";
import {refresh} from "@app/Sign/api/oauthApi";
import {authenticateEndpoint} from "@app/Sign/api/oauthEndpoints";
import {ApiError} from "@common/model/errors/ApiError";
import {HttpStatusCode} from "@common/model/HttpStatusCode";
import {readLocalStorage, writeLocalStorage} from "@common/utils/localStorage";

export class UserChecker extends ApiRequestWatcher {
    protected static readonly DATA_CACHE_KEY = 'userChecker-data';
    protected static readonly ACCESS_TOKEN_CACHE_KEY = 'userChecker-accessToken';
    protected static readonly DATA_CHECK_INTERVAL = 500;
    protected static readonly LOCK_KEY = 'userChecker-lock';
    protected static readonly FIRST_REFRESH_TRY = 30; // 30 seconds before expiration
    protected static readonly SECOND_REFRESH_TRY = 10; // 10 seconds before expiration

    private timeoutRef: number|null = null;
    private refreshTried = false;

    public constructor(private user: User, private api: Api) {
        super();
        this.api.registerWatcher(this);

        autorun(() => {
            if (this.user.checked) {
                writeLocalStorage<UserData>(UserChecker.DATA_CACHE_KEY, this.user.user);
                writeLocalStorage<string>(UserChecker.ACCESS_TOKEN_CACHE_KEY, this.user.accessToken);
            }
        });

        this.runCheck();
    }

    public runCheck = (): void => {
        if (this.timeoutRef) {
            window.clearTimeout(this.timeoutRef);
        }

        if (navigator.locks) {
            void navigator.locks.request(UserChecker.LOCK_KEY, this.check);
        } else {
            // browsers without locks support won't use this feature
            void this.check();
        }
    }

    public check = async (): Promise<void> => {
        this.checkSharedUser();
        await this.checkFreshUser();

        if (!this.user.checked) {
            this.user.check();
        }

        this.timeoutRef = window.setTimeout(this.runCheck, UserChecker.DATA_CHECK_INTERVAL);
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    public async onRequestError(_request: RunningRequest, error: unknown): Promise<void> {
        if (error instanceof ApiError && error.code === HttpStatusCode.UNAUTHORIZED) {
            if (error.endpoint && error.endpoint !== authenticateEndpoint) {
                this.user.setData({state: UserState.ANONYMOUS, logoutReason: 'api:expired'});
            }
        }
    }

    public requiresWatch(request: RunningRequest): boolean {
        return request.endpoint.backend === undefined || request.endpoint.backend === ApiBackend.AZAPI;
    }

    public async onRequestStart(): Promise<void> {}
    public async onRequestSuccess(): Promise<void> {}
    public onUnregister() {}

    private checkSharedUser(): void {
        const cachedAccessToken = readLocalStorage<string>(UserChecker.ACCESS_TOKEN_CACHE_KEY);
        if (this.user.accessToken !== cachedAccessToken) {
            const cachedUserData = readLocalStorage<UserData>(UserChecker.DATA_CACHE_KEY);
            if (!cachedUserData || cachedUserData.state === UserState.ANONYMOUS || cachedUserData.data.identity.user.email !== this.user.email) {
                this.user.uncheck();
            }

            this.user.setData(cachedUserData || {state: UserState.ANONYMOUS});
        }
    }

    private async checkFreshUser(): Promise<void> {
        if (!this.user.expiresAt) {
            return;
        }

        const expiresIn = differenceInSeconds(this.user.expiresAt, new Date());
        if ((!this.refreshTried && expiresIn <= UserChecker.FIRST_REFRESH_TRY)
            || (this.refreshTried && expiresIn <= UserChecker.SECOND_REFRESH_TRY)) {
            const refreshToken = this.user.refreshToken();
            if (refreshToken) {
                try {
                    const response = await refresh({grantType: 'refresh_token', refreshToken}, this.api);
                    this.user.setData({
                        state: UserState.AUTHENTICATED,
                        data: {
                            identity: this.user.identity ? {...this.user.identity, ...response.identity} : response.identity,
                            token: response.token,
                        },
                        loggedInAt: this.user.loggedInAt || new Date(),
                        expiresAt: expiresAtFromResponse(response),
                    });
                    this.refreshTried = false;
                } catch (error: unknown) {
                    if (!this.refreshTried && expiresIn > UserChecker.SECOND_REFRESH_TRY) {
                        this.refreshTried = true;
                    } else {
                        this.user.setData({state: UserState.ANONYMOUS, logoutReason: 'api:expired'});
                        this.refreshTried = false;
                    }
                }
            } else {
                this.user.setData({state: UserState.ANONYMOUS});
            }
        }
    }
}
