import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {reprint} from "@app/Print/api/printApi";
import {Button} from "@common/components/Button/Button";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {appToast} from "@common/components/Toast/toastOpener";
import {PrintResultType} from "@common/model/PrintResult";
import {camelize} from "@common/utils/camelize";

import './reprintsPage.scss';

export const ReprintsPage: FC = observer(() => {
    const {t} = useTranslation();
    const {api, print} = useAppContext();

    const doReprint = useCallback((reprintId: string) => {
        reprint({reprintId}, api)
            .then(() => {
                appToast.success(t('reprint:message.successful'));
            })
            .catch(() => {
                appToast.error(t('reprint:message.failed'));
            })
    }, [api, t]);

    const requiredReprintsTypes: PrintResultType[] = [
        PrintResultType.CUSTOMER_CARD_TERMINAL_RECEIPT,
        PrintResultType.MERCHANT_CARD_TERMINAL_RECEIPT,
        PrintResultType.TRANSACTION_RECEIPT,
        PrintResultType.RETURN,
        PrintResultType.MICRO_DEPOT_ROUTE_FINALIZATION_REPORT,
        // more reprint types will be added in the future
    ];

    const titleForPrintType = (printType: PrintResultType): string => {
        const typeKey = camelize(printType);

        return t(`reprint:title.${typeKey}`);
    }

    return <PageContent className="reprints-page">
        <PageHeader>{t('reprint:title.general')}</PageHeader>
        {requiredReprintsTypes.map((printType) => {
            const reprints = print.reprintsByPrintType(printType);
            return <Fragment key={printType}>
                <h4>{titleForPrintType(printType)}</h4>
                <GridTable>
                    <tbody>
                        {reprints.map((reprintItem) => (<tr key={reprintItem.reprintId}>
                            <td className="align-middle">
                                <DateFormatter date={reprintItem.timestamp} showTime={true} />
                            </td>
                            <td>
                                <Button variant="secondary" onClick={() => doReprint(reprintItem.reprintId)}>
                                    {t('reprint:action.reprint')}
                                </Button>
                            </td>
                        </tr>))}
                    </tbody>
                </GridTable>
                {!reprints.length && <p>
                    {t('reprint:message.not_available')}
                </p>}
            </Fragment>
        })}
    </PageContent>
})
