import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {PacketListFilter} from "@app/Packet/model/PacketListFilter";
import {Button} from "@common/components/Button/Button";
import {SearchInputData} from "@common/components/forms/SearchInput/SearchInput";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {modalToast} from "@common/components/Toast/toastOpener";
import {isBarcode} from "@common/utils/isBarcode";

import './multiplePacketSearchFilter.scss';

const MULTIPLE_PACKET_SEPARATOR = ',';

export type MultiplePacketSearchFilterProps = {
    filter: PacketListFilter;
    onSearch: (query: string) => void;
}

export const MultiplePacketSearchFilter: FC<MultiplePacketSearchFilterProps> = ({filter, onSearch}) => {
    const {t} = useTranslation();

    const [modalOpened, setModalOpened] = useState<boolean>(false);

    const form = useForm<SearchInputData>({
        defaultValues: {query: filter.fulltext || ''},
        mode: 'onSubmit'
    });

    const openModal = useCallback(() => {
        setModalOpened(true);
    }, []);

    const closeModal = useCallback(() => {
        setModalOpened(false);
    }, []);

    const onSubmit = useCallback(({query}: SearchInputData) => {
        const barcodes = query
            .split(/\r?\n/)
            .map((barcode) => barcode.trim());

        let row = 1;
        let hasError = false;
        for (const barcode of barcodes) {
            if (!isBarcode(barcode)) {
                modalToast.error(t('viewPacketSearch:multiplePacketSearch.error.notABarcode', {row}));
                hasError = true;
            }
            ++row;
        }

        if (hasError) {
            return;
        }

        onSearch(barcodes.join(MULTIPLE_PACKET_SEPARATOR));
        setModalOpened(false);
    }, [onSearch, t]);

    useEffect(() => {
        if (!modalOpened) {
            return;
        }

        form.setValue('query',
            filter.fulltext
                ? filter.fulltext.split(MULTIPLE_PACKET_SEPARATOR)
                    .map((queryItem) => queryItem.trim())
                    .join("\n")
                : ''
        );
    }, [filter.fulltext, form, modalOpened]);

    useEffect(() => {
        if (modalOpened) {
            form.setFocus('query');
        }
    }, [form, modalOpened]);

    const inputId="multiplePacketSearchFilter-query";

    return <Fragment>
        <Button variant="dark" onClick={openModal}>
            {t('viewPacketSearch:multiplePacketSearch.title')}
        </Button>
        <BaseModal
            show={modalOpened}
            onHide={closeModal}
            size="sm"
            header={t('viewPacketSearch:multiplePacketSearch.title')}
            footer={<Fragment>
                <Button onClick={closeModal} variant="secondary">
                    {t('viewPacketSearch:multiplePacketSearch.cancel')}
                </Button>
                <Button onClick={form.handleSubmit(onSubmit)} variant="primary">
                    {t('viewPacketSearch:multiplePacketSearch.search')}
                </Button>
            </Fragment>}
            className="multiple-packet-search-filter"
        >
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <div className="form-group">
                    <div className="form-group-label">
                        <label htmlFor={inputId}>{t('viewPacketSearch:multiplePacketSearch.instructions')}</label>
                    </div>
                    <div className="form-group-input">
                        <textarea {...form.register('query', {
                            required: t<string>('viewPacketSearch:multiplePacketSearch.query.required'),
                        })} id={inputId} />
                    </div>
                </div>
            </form>
        </BaseModal>
    </Fragment>

}

