import {FC, Fragment, useCallback, useEffect, useRef, useState} from "react";
import {Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {receive} from "@app/Packet/api/packetApi";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {Packet} from "@app/Packet/model/Packet";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {printPacketLabels} from "@app/Print/api/printApi";
import {PrintableTypes} from "@app/Print/model/PrintRequestsAndResponses";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {Img} from "@common/components/Img/Img";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {
    TranslationWithHtmlParam
} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './receivePacket.scss';

type ReceivePacketFormData = {
    barcode: string;
    shelf: string;
}

export type ReceivePacketProps = {
    packet: Packet;
    onSuccess?: () => void;
}

export const ReceivePacket: FC<ReceivePacketProps> = ({onSuccess, packet}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const form = useForm<ReceivePacketFormData>();
    const printLabel = useRef<boolean>(false);

    const showModalErrorToast = useShowErrorToast(modalToast);

    const onSubmit = useCallback(async (formData: ReceivePacketFormData) => {
        try {
            setIsSubmitting(true);
            await receive({barcode: packet.barcode, shelf: formData.shelf}, appContext.api);

            if (printLabel.current) {
                await printPacketLabels({barcodes: [packet.barcode], type: PrintableTypes.DEPOT_LABEL}, appContext.api);
            }

            setModalOpened(false);
            appToast.success(t('viewReceivePacket:message.success'));
            if (onSuccess) {
                onSuccess();
            }
        } catch (error: unknown) {
            setIsSubmitting(false);
            showModalErrorToast(error as Error);
        }
    }, [appContext.api, onSuccess, packet.barcode, showModalErrorToast, t]);

    const receiveOnly = useCallback(() => {
        printLabel.current = false;
        void form.handleSubmit(onSubmit)();
    }, [form, onSubmit]);

    const receiveAndPrint = useCallback(() => {
        printLabel.current = true;
        void form.handleSubmit(onSubmit)();
    }, [form, onSubmit]);

    useEffect(() => {
        if (modalOpened && !form.formState.isDirty) {
            form.setFocus('shelf');
        }
    }, [form, modalOpened]);

    if (packet.isAtUsersBranch || [
        PacketStatusId.DELIVERED,
        PacketStatusId.CANCELLED,
        PacketStatusId.RETURNED
    ].includes(packet.status.id)) {
        return null;
    }

    return <Fragment>
        <Button
            onClick={() => setModalOpened(true)}
            title={t('viewDashboard:action.receivePacket')}
            variant="success"
        >
            <Img src="/images/icons/ico/ic_local_shipping_24px.svg" alt={t('viewDashboard:action.receivePacket')} />
        </Button>
        <BaseModal
            show={modalOpened}
            onHide={() => setModalOpened(false)}
            header={t('viewReceivePacket:title')}
            footer={<Fragment>
                <Button onClick={receiveOnly} variant="success">{t('viewReceivePacket:action.receive')}</Button>
                <Button onClick={receiveAndPrint} variant="success">{t('viewReceivePacket:action.receiveAndPrint')}</Button>
                <Button onClick={() => setModalOpened(false)}>{t('viewReceivePacket:action.cancel')}</Button>
            </Fragment>}
            loading={isSubmitting}
            size="lg"
            className="receive-packet-modal"
        >
            <form onSubmit={form.handleSubmit(onSubmit)}>
                <FormGroup
                    name="shelf"
                    label={t('viewReceivePacket:shelf.label')}
                    registerOptions={{required: t<string>('viewReceivePacket:shelf.error.required')}}
                    inputOptions={{
                        placeholder: t('viewReceivePacket:shelf.placeholder'),
                        autoComplete: 'off',
                    }}
                    form={form}
                />
                <FormGroup
                    name="barcode"
                    label={<TranslationWithHtmlParam paramName="lastThreeDigits" translationKey="viewReceivePacket:barcode.label" t={t}>
                        <strong>{t('viewReceivePacket:barcode.lastThreeDigits')}</strong>
                    </TranslationWithHtmlParam>}
                    registerOptions={{
                        required: t<string>('viewReceivePacket:barcode.error.required'),
                        pattern: {
                            value: new RegExp(`^${packet.barcode.substring(packet.barcode.length - 3)}$`),
                            message: t('viewReceivePacket:barcode.error.pattern'),
                        }
                    }}
                    inputOptions={{
                        placeholder: t('viewReceivePacket:barcode.placeholder'),
                        autoComplete: 'off',
                    }}
                    form={form}
                />
                <TranslationWithHtmlParam paramName="exampleBarcode" translationKey="viewReceivePacket:barcode.hint" t={t}>
                    <FormattedPacketBarcode barcode="1234567890" withHighlight={true} withoutLink={true} />
                </TranslationWithHtmlParam>
            </form>
        </BaseModal>
    </Fragment>
}
