import {ApiBackend} from "@app/AppContext/classes/Api/model/ApiBackend";
import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const pdcHeadEndpoint: Endpoint = {
    method: HttpMethod.HEAD,
    url: '/',
    authenticate: false,
    backend: ApiBackend.PDC,
}

export const pdcEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/',
    authenticate: false,
    backend: ApiBackend.PDC,
}
