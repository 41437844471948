import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC, Fragment, ReactElement, RefObject} from "react";

import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {
    PacketPaymentInfo
} from "@app/Packet/components/PacketDelivery/components/PacketPaymentInfo/PacketPaymentInfo";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";
import {packetWholeName} from "@common/utils/packetWholeName";
import {
    PacketsListShelfCell
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/PacketsList/PacketsListShelfCell/PacketsListShelfCell";
import {
    PacketsListThead
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/PacketsList/PacketsListThead/PacketsListThead";
import {
    PacketsListType
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/PacketsList/PacketsListType";
import {
    usePacketsListSorter
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/PacketsList/usePacketsListSorter";

import './packetsList.scss';

export type PacketsListProps = {
    packets: DeliveryPacket[];
    listType: PacketsListType;
    renderActionButton: (packet: DeliveryPacket) => ReactElement|null;
    renderPaymentButton?: (packet: DeliveryPacket) => ReactElement|null;
    showCashColumn: boolean;
    bodyRef?: RefObject<HTMLTableSectionElement>;
    className?: string;
}

export const PacketsList: FC<PacketsListProps> = observer(({
    bodyRef,
    className,
    listType,
    packets,
    renderActionButton,
    renderPaymentButton,
    showCashColumn,
}) => {
    const sorter = usePacketsListSorter(listType);

    if (packets.length === 0) {
        return null;
    }

    packets.sort(sorter.sort);

    return <Fragment>
        <PacketsListThead sorter={sorter} showCashColumn={showCashColumn} listType={listType} />
        <tbody className={classNames('packets-list__body', className)} ref={bodyRef} >
            {packets.map((packet) => {
                return <tr key={packet.info.barcode} data-xid={`${listType}-row-${packet.info.barcode}`}>
                    <td className="packets-list__action">
                        <div className="packets-list__action-shelf">
                            <PacketsListShelfCell listType={listType} packet={packet} />
                        </div>
                        <div className="packets-list__action-barcode">
                            <FormattedPacketBarcode barcode={packet.info.barcode} withHighlight={true} withoutLink={true} />
                        </div>
                        {renderActionButton(packet)}
                    </td>
                    <td className="packets-list__barcode">
                        <FormattedPacketBarcode barcode={packet.info.barcode} withHighlight={true} withoutLink={true} />
                    </td>
                    <td className="packets-list__shelf">
                        <PacketsListShelfCell listType={listType} packet={packet} />
                    </td>
                    <td className="packets-list__consignee">
                        {packetWholeName(packet.info)}
                        <div className="packets-list__consignee-sender">
                            {dashWhenEmpty(packet.sender && packet.sender.name)}
                        </div>
                    </td>
                    <td className="packets-list__sender">
                        {dashWhenEmpty(packet.sender && packet.sender.name)}
                    </td>
                    <td className="packets-list__cod">
                        <PacketPaymentInfo packet={packet} isDelivering={renderPaymentButton !== undefined} showLabel={false}>
                            {renderPaymentButton && packet.isDelivered ? renderPaymentButton(packet) : null}
                        </PacketPaymentInfo>
                    </td>
                    {showCashColumn && <td className="packets-list__cod-cash" data-xid="cod-cash">
                        {packet.isCod && !packet.paid && packet.codCashAmount !== undefined
                            ? <MoneyFormatter amount={packet.codCashAmount} currencyCode={packet.info.currency} />
                            : '-'
                        }
                    </td>}
                </tr>
            })}
        </tbody>
    </Fragment>
})
