import {FC, Fragment, useCallback} from "react";

import {Img} from "@common/components/Img/Img";
import {AppNavLink} from "@common/components/routing/AppNavLink/AppNavLink";
import {To} from "@common/model/ReactRoute";

export type MainMenuLinkProps = {
    label: string;
    to: To;
    image?: string;
    activeImage?: boolean;
    end?: boolean;
}

export const MainMenuLink: FC<MainMenuLinkProps> = ({activeImage = true, end, image, label, to}) => {
    const navLinkClassName = useCallback(({isActive}: {isActive: boolean}) => `nav-link${isActive ? ' active' : ''}`, []);

    return <AppNavLink to={to} className={navLinkClassName} title={label} end={end}>
        {({isActive}: {isActive: boolean}) => (<Fragment>
            {image && <Img src={`/images/icons/ico/${image}${isActive && activeImage ? '-active' : ''}.svg`} alt={label} />}
            {` ${label}`}
        </Fragment>)}
    </AppNavLink>

}
