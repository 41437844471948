import {observer} from "mobx-react-lite";
import {FC, Fragment, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {CardPaymentError} from "@app/CardPayment/components/CardPaymentError/CardPaymentError";
import {
    CardPaymentReceipts
} from "@app/CardPayment/components/CardPaymentReceipts/CardPaymentReceipts";
import {
    SignatureVerificationModal
} from "@app/CardPayment/components/SignatureVerificationModal/SignatureVerificationModal";
import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {
    PacketUndeliveryAlerts
} from "@app/Packet/components/PacketDelivery/components/PacketUndeliveryModal/PacketUndeliveryAlerts/PacketUndeliveryAlerts";
import {
    PacketUndeliveryForm
} from "@app/Packet/components/PacketDelivery/components/PacketUndeliveryModal/PacketUndeliveryForm/PacketUndeliveryForm";
import {
    PacketUndeliveryFormData
} from "@app/Packet/components/PacketDelivery/components/PacketUndeliveryModal/PacketUndeliveryForm/PacketUndeliveryFormData";
import {useUndeliver} from "@app/Packet/components/PacketDelivery/components/PacketUndeliveryModal/useUndeliver";
import {SinglePacketInfo} from "@app/Packet/components/PacketDelivery/components/SinglePacketInfo/SinglePacketInfo";
import {useLastTransactionId} from "@app/Packet/components/PacketDelivery/hooks/useLastTransactionId";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {PacketUndeliveryReturnToSender} from "@app/Packet/components/PacketDelivery/model/PacketUndelivery";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

import './packetUndeliveryModal.scss';

export type PacketUndeliveryProps = {
    packet: DeliveryPacket|undefined;
    onUndelivered: (undeliveredPacket: DeliveryPacket) => void;
    onUndeliveryCancelled: (error?: Error) => void;
}

export const PacketUndeliveryModal: FC<PacketUndeliveryProps> = observer(({onUndelivered, onUndeliveryCancelled, packet}) => {
    const {t} = useTranslation();

    const [lastTransactionId, setLastTransactionId] = useState<string|null|undefined>(undefined);
    const [running, setRunning] = useState<boolean>(false);
    const [cardPaymentCancelled, setCardPaymentCancelled] = useState<boolean>(false);
    const form = useForm<PacketUndeliveryFormData>({
        defaultValues: {
            returnToSender: PacketUndeliveryReturnToSender.NO,
        },
        mode: 'onChange'
    });

    useLastTransactionId(packet, setLastTransactionId, onUndeliveryCancelled, setRunning);

    useEffect(() => {
        if (!packet) {
            setCardPaymentCancelled(false);
            setRunning(false);
            setLastTransactionId(undefined);
        }
    }, [packet])

    const {
        cancelPayment,
        cardPaymentState,
        confirmSignature,
        onSuccess,
        submit,
    } = useUndeliver(lastTransactionId || null, onUndelivered, packet, setRunning, setCardPaymentCancelled);

    if (!packet) {
        return null;
    }

    return <BaseModal
        show={true}
        onHide={() => false}
        header={t('viewPacketDelivery:undelivery.header', {barcode: formatPacketBarcode(packet.info.barcode)})}
        footer={<Fragment>
            {cardPaymentCancelled && <Fragment>
                <CardPaymentReceipts cardPaymentState={cardPaymentState} checkInitialTransactionStatus={false} toastInModal={true} />
                <CardPaymentError
                    cardPaymentState={cardPaymentState}
                    cancelPayment={cancelPayment}
                    showCancelledPayment={false}
                    toastInModal={true}
                />
                <Button onClick={() => onSuccess(false)} variant="warning" data-xid="quit-undelivery">
                    {t('viewPacketDelivery:undelivery.close')}
                </Button>
            </Fragment>}
            {!cardPaymentCancelled && <Fragment>
                <Button onClick={() => onUndeliveryCancelled()} variant="secondary" data-xid="cancel-undelivery">
                    {t('viewPacketDelivery:undelivery.cancel')}
                </Button>
                {lastTransactionId !== undefined && <Button onClick={form.handleSubmit(submit)} variant="warning" data-xid="undeliver-packet">
                    {t('packet:action.cancel')}
                </Button>}
            </Fragment>}
        </Fragment>}
        loading={running}
        height="auto"
        disableOutsideClose={true}
        closeButton={false}
        className="packet-undelivery-modal"
        xid="packet-undelivery-modal"
    >
        <SinglePacketInfo packet={packet} isDelivering={false} />
        {!cardPaymentCancelled && <Fragment>
            <PacketUndeliveryAlerts packet={packet} />
            <PacketUndeliveryForm form={form} packet={packet} />
        </Fragment>}

        <SignatureVerificationModal
            cardPaymentState={cardPaymentState}
            onSignatureConfirmed={confirmSignature}
            onSignatureRejected={cancelPayment}
        />
    </BaseModal>
})

PacketUndeliveryModal.displayName = 'PacketUndeliveryModal';
