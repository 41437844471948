import {FormEvent, useCallback, useRef, useState} from "react";
import {useForm, UseFormReturn} from "react-hook-form";
import {useDebouncedCallback} from "use-debounce";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {zPointSearch} from "@app/Branch/api/branchApi";
import {ZPointBranch} from "@app/Branch/model/ZPointBranch";

type BranchSearchModalFormData = {
    region: string;
    searchStr: string;
}

type SearchState = {
    searching: boolean;
    branches: ZPointBranch[];
}

const MIN_SEARCH_STR_LENGTH = 3;
const BRANCH_SEARCH_DEBOUNCE_DELAY = 200;

export type UseBranchSearchModal = {
    form: UseFormReturn<BranchSearchModalFormData>;
    onSubmit: (event: FormEvent) => void;
    searchState: SearchState;
}

export const useBranchSearchModal = (): UseBranchSearchModal => {
    const appContext = useAppContext();

    const form = useForm<BranchSearchModalFormData>({
        defaultValues: {
            region: '',
            searchStr: '',
        },
        mode: 'onChange',
    });

    const [searchState, setSearchState] = useState<SearchState>({
        searching: false,
        branches: [],
    });

    const abortController = useRef<AbortController|null>(null);

    const doSearch = useDebouncedCallback(({region, searchStr}: BranchSearchModalFormData) => {
        if (abortController.current) {
            abortController.current.abort();
        }

        if (searchStr.length < MIN_SEARCH_STR_LENGTH) {
            setSearchState({
                ...searchState,
                searching: false,
            });
            return;
        }

        setSearchState({
            ...searchState,
            searching: true,
        })

        const newAbortController = new AbortController();
        abortController.current = newAbortController;

        zPointSearch(searchStr, newAbortController, appContext.api)
            .then((response) => {
                if (region === '') {
                    const branches: ZPointBranch[] = [];
                    for (const country in response.items) {
                        if (Object.prototype.hasOwnProperty.call(response.items, country)) {
                            branches.push(...response.items[country]);
                        }
                    }
                    setSearchState({
                        branches: branches.sort((branchA, branchB) => {
                            return (branchA.name.localeCompare(branchB.name));
                        }),
                        searching: false,
                    })
                } else if (region in response.items) {
                    setSearchState({
                        branches: response.items[region],
                        searching: false,
                    });
                } else {
                    setSearchState({
                        branches: [],
                        searching: false,
                    });
                }
            })
            .catch(() => {
                setSearchState({
                    branches: [],
                    searching: false,
                });
            })
    }, BRANCH_SEARCH_DEBOUNCE_DELAY);

    const onSubmit = useCallback((event: FormEvent) => {
        void form.handleSubmit((formData: BranchSearchModalFormData) => {
            doSearch(formData)
        })(event);
        event.stopPropagation();
    }, [doSearch, form]);

    return {
        form,
        onSubmit,
        searchState,
    };
}
