import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    CashRegisterBranchQualityDetailItem
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/CashRegisterBranchQualityDetail";
import {
    ProcessPenaltyDetailGridHeader
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/components/ProcessPenaltyDetailGridHeader/ProcessPenaltyDetailGridHeader";
import {
    useProcessPenaltyDetailGridSorter
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/hooks/useProcessPenaltyDetailGridSorter";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {camelize} from "@common/utils/camelize";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export type CashRegisterProcessPenaltyDetailGridProps = {
    items: CashRegisterBranchQualityDetailItem[];
}

export const CashRegisterProcessPenaltyDetailGrid: FC<CashRegisterProcessPenaltyDetailGridProps> = ({items}) => {
    const {t} = useTranslation();

    const sorter = useProcessPenaltyDetailGridSorter();
    items.sort(sorter.sort);

    return <GridTable>
        <ProcessPenaltyDetailGridHeader sorter={sorter}>
            <th>{t('branchQuality:processPenalty.detail.cashRegister.title')}</th>
        </ProcessPenaltyDetailGridHeader>
        <tbody>
            {items.map((item) => (<tr key={item.date}>
                <td><DateFormatter date={item.date}/></td>
                <td><strong>{item.penaltyPoints}</strong></td>
                <td>
                    {dashWhenEmpty(item.reason && t(`branchQuality:processPenalty.detail.cashRegister.reason.${camelize(item.reason)}`))}
                </td>
            </tr>))}
        </tbody>
    </GridTable>
}
