import {ErrorMessage} from "@hookform/error-message";
import {FC, useEffect} from "react";
import {UseFormReturn, useFormState} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    NewMessageFormFields,
    OutgoingMessageFormData
} from "@app/Messaging/components/NewMessage/NewMessageFormFields";

export type MessageSubjectProps = {
    form: UseFormReturn<OutgoingMessageFormData>;
    formId: string;
}
export const MessageSubject: FC<MessageSubjectProps> = ({form, formId}) => {
    const {t} = useTranslation();

    const formState = useFormState({control: form.control});

    useEffect(() => {
        form.setFocus(NewMessageFormFields.subject);
    }, [form]);

    const inputId = `${formId}-subject`;

    return <div className="form-group message-subject">
        <div><label htmlFor={inputId}>{t('message:tabs.content.new.subject')}</label></div>
        <div>
            <input
                {...form.register(NewMessageFormFields.subject, {
                    required: t<string>('message:tabs.content.new.topic.requiredSubjectMessage'),
                })}
                id={inputId}
                className="form-control"
            />
            <ErrorMessage name={NewMessageFormFields.subject} errors={formState.errors} as={<p className="alert alert-danger" />} />
        </div>
    </div>
}
