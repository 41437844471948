import {useCallback} from "react";
import {useForm} from "react-hook-form";

import {Deliver} from "@app/Packet/components/PacketDelivery/hooks/useDeliver";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {ReasonVerification} from "@app/Packet/components/PacketDelivery/model/ReasonVerification";
import {PacketDeliveryType} from "@app/Packet/model/PacketDeliveryType";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export const useReasonVerificationForm = (
    deliver: Deliver,
    packet?: DeliveryPacket
) => {

    const showDeliveryErrorToast = useShowErrorToast(modalToast, 'packet:error', 'packet:error.deliveryError')

    const form = useForm<ReasonVerification>({
        mode: 'onSubmit'
    });

    const onSubmit = useCallback(async (reasonVerification: ReasonVerification) => {
        if (!packet) {
            return;
        }

        try {
            await deliver(packet, {type: PacketDeliveryType.REASON, reasonVerification});
        } catch (error: unknown) {
            showDeliveryErrorToast(error as Error);
        }
    }, [deliver, packet, showDeliveryErrorToast]);

    return {
        form,
        onSubmit,
    }
}
