import {DashboardBranchCountry} from "@app/AppContext/model/DashboardBranchCountry";

export type Config = {
    environment: 'DEV' | 'PROD';
    release?: string;
    languages: string[];
    defaultLng: string;
    fallbackLng: string;
    sentryDSN?: string;
    launchDarklyClientId?: string;
    azApiBaseUri: string;
    adminVmBackendHost: string;
    adminVmBackendPort?: string;
    adminVmBackendNonSecure?: boolean;
    pdcUpdateServerUri: string;
    pdcHost: string;
    pdcPort?: string;
    pdcNonSecure?: boolean;
    remoteHelpUrlTemplate: string;
    isE2ETest: string;
    translationNamespaces: string[];
    systemToolsUrl: string;
    gtmId?: string;
}

export const appConfig: Config = {
    environment: process.env.ENVIRONMENT,
    release: process.env.RELEASE,
    languages: ['cs-CZ', 'en-GB', 'sk-SK', 'hu-HU', 'ro-RO', 'vi-VN'],
    defaultLng: process.env.DEFAULT_LANGUAGE,
    fallbackLng: process.env.FALLBACK_LANGUAGE,
    sentryDSN: process.env.SENTRY_DSN,
    launchDarklyClientId: process.env.LAUNCH_DARKLY_CLIENT_ID,
    azApiBaseUri: process.env.AZAPI_BASE_URI.replace(/\/$/, ''),
    adminVmBackendHost: process.env.ADMIN_VM_BACKEND_HOST,
    adminVmBackendPort: process.env.ADMIN_VM_BACKEND_PORT,
    adminVmBackendNonSecure: process.env.ADMIN_VM_BACKEND_NON_SECURE === 'admin-vm-backend-non-secure',
    pdcUpdateServerUri: process.env.PDC_UPDATE_SERVER_URI.replace(/\/$/, ''),
    pdcHost: process.env.PDC_HOST,
    pdcPort: process.env.PDC_PORT,
    pdcNonSecure: process.env.PDC_NON_SECURE === 'pdc-non-secure',
    remoteHelpUrlTemplate: process.env.REMOTE_HELP_URL_TEMPLATE,
    isE2ETest: process.env.IS_E2E_TEST,
    translationNamespaces: process.env.TRANSLATION_NAMESPACES || [],
    systemToolsUrl: process.env.SYSTEM_TOOLS_URL,
    gtmId: process.env.GTM_ID,
};

export const CLIENT_NAME = 'avm';
export const HEADER_CLIENT = 'X-Client';
export const HEADER_DEPRECATED_CLIENT_VERSION = 'X-Deprecated-Client-Version';

export const TERM_AND_CONDITIONS_MANUAL_PAGES: {[key: string]: string} = {
    [DashboardBranchCountry.CZ]: '516',
    [DashboardBranchCountry.SK]: '518',
    [DashboardBranchCountry.HU]: '520',
    [DashboardBranchCountry.RO]: '522',
    [DashboardBranchCountry.PL]: '507',
    [DashboardBranchCountry.VN]: '516',
};

export const NVZ_APP_URL = 'https://point.packeta.com/';
