import {TFunction} from "i18next";

import {getStringVariable, getVariable} from "@app/Devices/components/ReaderModal/utils/getVariable";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";
import {ReaderVariables} from "@app/Devices/model/ReaderVariables";

export const jobTitle = (job: ReaderJob, t: TFunction): string => {
    const title = getStringVariable(job.variables, 'title');
    const titleVariables: ReaderVariables = {};

    if (title) {
        if (title === 'packetsToParcels') {
            titleVariables.packetCount = getVariable(job.variables, 'packetCount') || 0;
            titleVariables.parcelCount = getVariable(job.variables, 'parcelCount') || 0;
        } else if (title === 'receiveParcels') {
            titleVariables.parcelCount = getVariable(job.variables, 'parcelCount') || 0;
        } else if (title === 'receivePackets') {
            titleVariables.billOfDeliveryCount = '';
            const billOfDeliveryCount = getVariable(job.variables, 'billOfDeliveryCount');
            if (billOfDeliveryCount !== undefined) {
                titleVariables.billOfDeliveryCount = t<string>(`readerTitle:receivePackets.variable.billOfDeliveryCount`, {billOfDeliveryCount});
            }
            titleVariables.packetCount = '';
            const packetCount = getVariable(job.variables, 'packetCount');
            if (packetCount !== undefined) {
                titleVariables.packetCount = t<string>(`readerTitle:receivePackets.variable.packetCount`, {packetCount});
            }
        } else if (title === 'unableToProcess') {
            const unknownBarcodes = getVariable(job.variables, 'unknownBarcodes');
            if (Array.isArray(unknownBarcodes)) {
                titleVariables.barcodes = unknownBarcodes.join(', ');
            }
        }

        if (job.type && job.type === ReaderJobType.UNKNOWN) {
            return t(`reader:unknown.jobTitle.${title}`, {...titleVariables});
        } else if (title !== 'receivePackets' || (titleVariables.billOfDeliveryCount !== undefined && titleVariables.packetCount !== undefined)) {
            return t(`readerTitle:${title}.title`, {...titleVariables});
        }
    }

    if (job.type && job.type === ReaderJobType.UNKNOWN) {
        return t('reader:genericTitles.manualBarcodeSubmission');
    }

    return t('reader:genericTitles.jobDetail');
}
