import {sub} from "date-fns";
import {useCallback, useEffect, useRef, useState} from "react";

import {useActiveDataRefresh} from "@app/App/components/AppWrapper/DataSourceProvider/hooks/useActiveDataRefresh";
import {DataSource} from "@app/App/components/AppWrapper/DataSourceProvider/model/DataSource";
import {DataSourceHook} from "@app/App/components/AppWrapper/DataSourceProvider/model/DataSourceHook";
import {
    WebSocketDataMessageType
} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketDataMessageType";
import {
    WebSocketMessage
} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketMessage";
import {isWebSocketResultMessage} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketResultMessage";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {received} from "@app/Messaging/api/messagingApi";
import {useFeatureFlag} from "@common/hooks/useFeatureFlag";
import {SearchModifier} from "@common/model/requests/SearchRequestProperty";

const DASHBOARD_MESSAGES_PAGE_SIZE = 40;
const DASHBOARD_MESSAGES_INTERNAL_USER_PAGE_SIZE = 5;
const DASHBOARD_MESSAGES_INTERVAL: Duration = {days: 5};

export const useDashboardMessagesDataSource = (): DataSourceHook => {
    const {api, dashboardMessages, user} = useAppContext();

    const dashboardMessagesSourceFlag = useFeatureFlag('dashboardMessagesSource', DataSource.AZAPI);

    const [dashboardMessagesDataSource, setDashboardMessagesDataSource] = useState<DataSource>(
        user.isInternalUser ? DataSource.AZAPI : dashboardMessagesSourceFlag
    );

    const recoverableWSFailure = useRef<boolean>(false);
    const restoreDataSource = useRef<boolean>(false);

    const onRefresh = useCallback(async () => {
        try {
            const response = await received({
                unreadOnly: {value: true, modifier: SearchModifier.EQ},
                createdAt: [{value: sub(new Date(), DASHBOARD_MESSAGES_INTERVAL), modifier: SearchModifier.GTE}],
                page: 0,
                perPage: user.isInternalUser ? DASHBOARD_MESSAGES_INTERNAL_USER_PAGE_SIZE : DASHBOARD_MESSAGES_PAGE_SIZE,
            }, api);

            dashboardMessages.setUnreadCount(response.unreadCount || 0);
            dashboardMessages.setMessages(response.items);
        } catch (error: unknown) {
            dashboardMessages.setUnreadCount(0);
            dashboardMessages.setMessages([]);
        }

        if (restoreDataSource.current) {
            setDashboardMessagesDataSource(DataSource.ADMIN_VM_BACKEND_WS);
            restoreDataSource.current = false;
        }

        if (recoverableWSFailure.current) {
            recoverableWSFailure.current = false;
            restoreDataSource.current = true;
        }
    }, [api, dashboardMessages, user.isInternalUser]);

    useActiveDataRefresh(
        dashboardMessagesDataSource === DataSource.AZAPI,
        onRefresh,
        {minutes: 10},
    );

    useEffect(() => {
        if (dashboardMessages.reloadRequired) {
            void onRefresh();
        }
    }, [dashboardMessages.reloadRequired, onRefresh]);

    useEffect(() => {
        setDashboardMessagesDataSource(user.isInternalUser ? DataSource.AZAPI : dashboardMessagesSourceFlag);
    }, [dashboardMessagesSourceFlag, user.isInternalUser]);

    useEffect(() => {
        if (!user.isLoggedIn) {
            dashboardMessages.reset();
        }

        return () => {
            dashboardMessages.reset();
        }
    }, [dashboardMessages, user.isLoggedIn]);

    const onMessage = useCallback((message: WebSocketMessage) => {
        if (
            dashboardMessagesDataSource !== DataSource.ADMIN_VM_BACKEND_WS
            || isWebSocketResultMessage(message)
            || message.type !== WebSocketDataMessageType.BRANCH_MESSAGE
        ) {
            return;
        }

        const previousUnreadCount = dashboardMessages.unreadCount;
        dashboardMessages.setUnreadCount(message.unreadCount || 0);
        if (dashboardMessages.unreadCount !== previousUnreadCount) {
            void onRefresh();
        }
    }, [dashboardMessages, dashboardMessagesDataSource, onRefresh]);

    const onConnectionFailed = useCallback((isRecoverable: boolean) => {
        if (isRecoverable) {
            recoverableWSFailure.current = true;
        }
        setDashboardMessagesDataSource(DataSource.AZAPI);
    }, []);

    return [
        onMessage,
        onConnectionFailed,
        dashboardMessagesDataSource === DataSource.ADMIN_VM_BACKEND_WS,
    ]
}
