export abstract class RefreshingDataManager {
    protected expiresAt: Date|null = null;
    protected timeoutRef: number|null = null;

    protected readonly REFRESH_INTERVAL = 60 * 1000; // check data every minute
    protected abstract fetchData: () => void;

    protected constructor() {}

    public load = (): void => {
        if (this.timeoutRef) {
            window.clearTimeout(this.timeoutRef);
        }

        if (this.expiresAt === null || this.expiresAt < new Date()) {
            this.fetchData();
        }

        this.timeoutRef = window.setTimeout(this.load, this.REFRESH_INTERVAL);
    }

    public start(): void {
        this.load();
    }

    public stop(): void {
        if (this.timeoutRef !== null) {
            window.clearTimeout(this.timeoutRef);
        }

        this.expiresAt = null;
    }
}
