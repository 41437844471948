import {action, computed, makeObservable, observable} from "mobx";

import {BranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";
import {BranchEventSeverity} from "@app/BranchEvent/model/BranchEventSeverity";

export class BranchEvents {
    public branchEvents: BranchEvent[] = [];
    public userEvents: BranchEvent[] = [];
    public loaded = false;
    public reloadRequired = false;

    public constructor() {
        makeObservable(this, {
            branchEvents: observable.shallow,
            userEvents: observable.shallow,
            loaded: observable,
            reloadRequired: observable,
            events: computed,
            highSeverityEvents: computed,
            hasPasswordChangeEvent: computed,
            setBranchEvents: action,
            setUserEvents: action,
            setReloadRequired: action,
            reset: action,
        });
    }

    get events(): BranchEvent[] {
        return [...this.branchEvents, ...this.userEvents];
    }

    get highSeverityEvents(): BranchEvent[] {
        return this.events.filter((event) => {
            return event.severity === BranchEventSeverity.HIGH;
        });
    }

    get hasPasswordChangeEvent(): boolean {
        return this.events.filter((event) => {
            return event.code === BranchEventCode.PASSWORD_CHANGE_REQUIRED;
        }).length > 0;
    }

    public setEvents(events: BranchEvent[]) {
        const branchEvents: BranchEvent[] = [];
        const userEvents: BranchEvent[] = [];
        // temporary hack to avoid event duplicities when switching from AZAPI to WS
        events.forEach((event) => {
            if ([
                BranchEventCode.UNFINISHED_RECEIVE_PACKETS_EVENT,
                BranchEventCode.PASSWORD_CHANGE_REQUIRED
            ].includes(event.code)) {
                userEvents.push(event);
            } else {
                branchEvents.push(event);
            }
        });
        this.setBranchEvents(branchEvents);
        this.setUserEvents(userEvents);
    }

    public setBranchEvents(branchEvents: BranchEvent[]) {
        this.branchEvents = branchEvents;
        this.reloadRequired = false;
        this.loaded = true;
    }

    public setUserEvents(userEvents: BranchEvent[]) {
        this.userEvents = userEvents;
        this.reloadRequired = false;
        this.loaded = true;
    }

    public setReloadRequired(reloadRequired: boolean) {
        this.reloadRequired = reloadRequired;
    }

    public reset() {
        this.setEvents([]);
        this.loaded = false;
        this.reloadRequired = false;
    }
}
