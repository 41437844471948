import {i18n as I18next} from "i18next";
import {parse} from "json-bigint";

import {ApiErrorHandler} from "@app/AppContext/classes/Api/apiErrorHandlers/ApiErrorHandler";
import {branchClosedAzApiErrorHandler} from "@app/AppContext/classes/Api/apiErrorHandlers/branchClosedAzApiErrorHandler";
import {fetchApiErrorHandler} from "@app/AppContext/classes/Api/apiErrorHandlers/fetchApiErrorHandler";
import {
    serviceUnavailableApiErrorHandler
} from "@app/AppContext/classes/Api/apiErrorHandlers/serviceUnavailableApiErrorHandler";
import {unauthorizedApiErrorHandler} from "@app/AppContext/classes/Api/apiErrorHandlers/unauthorizedApiErrorHandler";
import {FetchApi, FetchApiParameters} from "@app/AppContext/classes/Api/FetchApi";
import {ApiRequestWatcher, RunningRequest} from "@app/AppContext/classes/Api/model/ApiRequestWatcher";
import {Endpoint, isEndpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {FetchTimeoutError} from "@app/AppContext/classes/Api/model/FetchTimeoutError";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";
import {User} from "@app/AppContext/classes/User/User";
import {Config} from "@app/config";
import {ApiError} from "@common/model/errors/ApiError";
import {EndpointError} from "@common/model/errors/EndpointError";
import {isGeneralErrorResponseContent} from "@common/model/responses/GeneralErrorResponseContent";

export type ApiCallOptions = {
    skipErrorHandler?: ApiErrorHandler[];
    skipWatchers?: boolean;
}

export class Api {
    public watchers: ApiRequestWatcher[] = [];

    private fetchApi: FetchApi;
    private errorHandlers: ApiErrorHandler[];

    public constructor(
        user: User,
        config: Config,
        i18n: I18next,
    ) {
        this.fetchApi = new FetchApi(user, config, i18n);
        this.errorHandlers = [
            fetchApiErrorHandler,
            serviceUnavailableApiErrorHandler,
            unauthorizedApiErrorHandler,
            branchClosedAzApiErrorHandler
        ];
    }

    public async call<ResponseType, PayloadType, RouteParamNames extends string = string, ErrorCodes extends string = string>(
        parameters: Omit<FetchApiParameters<PayloadType, RouteParamNames, ErrorCodes>, 'baseUri'>|Endpoint<RouteParamNames, ErrorCodes>,
        options?: ApiCallOptions
    ) {
        const {
            abortController,
            customHeaders,
            endpoint,
            payload,
            queryParams,
        } = isEndpoint(parameters) ? {
            abortController: undefined,
            customHeaders: undefined,
            endpoint: parameters,
            payload: undefined,
            queryParams: undefined
        } : parameters;

        const runningRequest: RunningRequest = {date: new Date(), endpoint};
        const watchers = this.watchersForRequest(runningRequest);
        for (const watcher of watchers) {
            void watcher.onRequestStart(runningRequest);
        }

        try {
            const response = await this.fetchApi.fetch({
                endpoint,
                payload,
                queryParams,
                abortController,
                customHeaders,
            });

            if (response.ok) {
                for (const watcher of watchers) {
                    void watcher.onRequestSuccess(runningRequest, response);
                }

                if (endpoint.method === HttpMethod.HEAD) {
                    return true as ResponseType;
                }

                const responseText = await response.text();
                try {
                    return parse(responseText) as ResponseType;
                } catch (error: unknown) {
                    throw new EndpointError(
                        'Error parsing response',
                        endpoint,
                        true
                    );
                }
            }

            if (endpoint.method === HttpMethod.HEAD) {
                return false as ResponseType;
            }

            try {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const errorResponse = await response.json();
                throw new ApiError(
                    response.status,
                    isGeneralErrorResponseContent(errorResponse) ? errorResponse.errors : [],
                    endpoint
                );
            } catch (error: unknown) {
                if (error instanceof ApiError) {
                    throw error;
                }

                throw new ApiError(response.status, [], endpoint);
            }
        } catch (error: unknown) {
            for (const watcher of watchers) {
                void watcher.onRequestError(runningRequest, error);
            }

            for (const errorHandler of this.errorHandlers) {
                if (!options || !options.skipErrorHandler || !options.skipErrorHandler.includes(errorHandler)) {
                    await errorHandler(error);
                }
            }

            throw error;
        }
    }

    public async callWithTimeout<ResponseType, PayloadType, RouteParamNames extends string = string, ErrorCodes extends string = string>(
        parameters: Omit<FetchApiParameters<PayloadType, RouteParamNames, ErrorCodes>, 'baseUri'>|Endpoint<RouteParamNames, ErrorCodes>,
        timeout: number,
    ): Promise<ResponseType> {
        const {
            abortController,
            customHeaders,
            endpoint,
            payload,
            queryParams,
        } = isEndpoint(parameters) ? {
            abortController: new AbortController(),
            customHeaders: undefined,
            endpoint: parameters,
            payload: undefined,
            queryParams: undefined
        } : {
            ...parameters,
            abortController: parameters.abortController || new AbortController(),
        };

        return new Promise((resolve, reject) => {
            const timer = window.setTimeout(() => {
                abortController.abort();
                reject(new FetchTimeoutError());
            }, timeout);

            this.call({
                endpoint,
                payload,
                queryParams,
                abortController,
                customHeaders,
            })
                .then(value => {
                    window.clearTimeout(timer)
                    resolve(value as ResponseType);
                })
                .catch(reason => {
                    window.clearTimeout(timer)
                    reject(reason);
                })
        });
    }

    public async download(endpoint: Endpoint, fileName: string) {
        const response = await this.fetchApi.fetch({endpoint});
        const responseBlob = await response.blob();

        const url = window.URL.createObjectURL(
            new Blob([responseBlob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    public registerWatcher(watcher: ApiRequestWatcher): void {
        this.watchers.push(watcher);
    }

    public unregisterWatcher(watcher: ApiRequestWatcher): void {
        const index = this.watchers.indexOf(watcher);
        if (index > -1) {
            this.watchers.splice(index, 1);
        }
        watcher.onUnregister();
    }

    public watchersForRequest(request: RunningRequest): ApiRequestWatcher[] {
        return this.watchers.filter((watcher) => watcher.requiresWatch(request));
    }
}
