import {add} from "date-fns";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {PacketAlert} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/DeliveryAlert/PacketAlert/PacketAlert";
import {Img} from "@common/components/Img/Img";
import {
    TranslationWithHtmlParam
} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";

export enum DeliveryAlertType {
    NO_UNPACKED_RETURN = 'noUnpackedReturn',
    ADULT_CONTENT = 'adultContent',
    CARD_PAYMENT_FAILED = 'cardPaymentFailed',
}

export type DeliveryAlertProps = {
    type: DeliveryAlertType;
    iconOnly?: boolean;
}

export const DeliveryAlert: FC<DeliveryAlertProps> = ({iconOnly = false, type}) => {
    const {t} = useTranslation();

    const alertIconMap: Record<DeliveryAlertType, string> = {
        [DeliveryAlertType.NO_UNPACKED_RETURN]: '/images/icons/ico/ico-no-unpacked-return.svg',
        [DeliveryAlertType.ADULT_CONTENT]: '/images/icons/ico/ico-adult-content.svg',
        [DeliveryAlertType.CARD_PAYMENT_FAILED]: '/images/icons/ico/ico-exclamation-red.svg',
    };

    const dateFormatter = useDateFormatter();

    const title = t(`packet:alert.${type}.title`);

    if (iconOnly) {
        return <Img src={alertIconMap[type]} alt={type} title={title} data-xid={`delivery-alert-${type}`} />;
    }

    if (type === DeliveryAlertType.ADULT_CONTENT) {
        return <PacketAlert
            iconSrc={alertIconMap[type]}
            title={title}
            description={<TranslationWithHtmlParam paramName="minBirthDate" translationKey="packet:alert.adultContent.description" t={t} >
                <strong>{dateFormatter(add(new Date(), {years: -18}), DateFormatType.DATE_FORMAT)}</strong>
            </TranslationWithHtmlParam>}
            xid={type}
        />

    }

    return <PacketAlert iconSrc={alertIconMap[type]} title={title} description={t<string>(`packet:alert.${type}.description`)} xid={type} />
}
