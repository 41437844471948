import {SimpleReactRoute} from "@common/model/ReactRoute";

export const OpeningHoursIndexRoute: SimpleReactRoute = {
    path: '',
}

export const OpeningHoursRoutes = [
    OpeningHoursIndexRoute,
];

