import {sub} from "date-fns";
import {ChangeEventHandler, FC, FormEvent, useCallback, useEffect, useMemo} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {ParcelListFilter} from "@app/Parcel/pages/BranchParcelListPage/BaseParcelListManager";
import {IncomingParcelListManager} from "@app/Parcel/pages/BranchParcelListPage/IncomingParcelListManager";
import {OutgoingParcelListManager} from "@app/Parcel/pages/BranchParcelListPage/OutgoingParcelListManager";
import {Checkbox} from "@common/components/forms/Checkbox/Checkbox";
import {DateRangeOnChange, DateRangePicker} from "@common/components/forms/DateRangePicker/DateRangePicker";
import {
    GeneralDateRangePickerRanges,
    useGeneralDateRangePickerRanges
} from "@common/components/forms/DateRangePicker/useGeneralDateRangePickerRanges";
import {FormResetLink} from "@common/components/forms/FormResetLink/FormResetLink";
import {SearchInput, SearchInputData} from "@common/components/forms/SearchInput/SearchInput";
import {appToast} from "@common/components/Toast/toastOpener";
import {useFixFiltersDuration} from "@common/hooks/useFixFiltersDuration";
import {useLocalStorageState} from "@common/hooks/useLocalStorageState";

import './branchParcelListFilter.scss';

export type BranchParcelListFilterProps = {
    incomingParcelListManager: IncomingParcelListManager;
    outgoingParcelListManager: OutgoingParcelListManager;
}

export const BRANCH_PARCEL_LIST_FILTER_STORAGE_KEY = 'ui-branchParcelList-filter';

const PARCEL_ID_PATTERN = /^\d{7,10}$/;
const PARCEL_BARCODE_PATTERN = /^[BMbm]-\S{3}-\S{1,3}-\d{8,}$/;
const PARCEL_FILTER_MAX_RANGE: Duration = {months: 12};

export const BranchParcelListFilter: FC<BranchParcelListFilterProps> = ({
    incomingParcelListManager,
    outgoingParcelListManager,
}) => {
    const {t} = useTranslation();

    const namedRanges = useGeneralDateRangePickerRanges();
    const defaultFilter = useMemo<ParcelListFilter>(() => ({
        range: namedRanges[GeneralDateRangePickerRanges.LAST_WEEK],
        query: '',
        hasIssue: false,
    }), [namedRanges]);

    const [storedFilter, setStoredFilter] = useLocalStorageState<ParcelListFilter>(
        BRANCH_PARCEL_LIST_FILTER_STORAGE_KEY,
        defaultFilter
    );

    const updateFilter = useCallback((newFilter: ParcelListFilter) => {
        setStoredFilter({...newFilter, query: ''});
        incomingParcelListManager.setFilter(newFilter);
        outgoingParcelListManager.setFilter(newFilter);
    }, [incomingParcelListManager, outgoingParcelListManager, setStoredFilter]);

    const form = useForm<SearchInputData>();

    const onBarcodeSearchSubmit = useCallback(({query}: SearchInputData) => {
        if (query.length && PARCEL_BARCODE_PATTERN.exec(query) === null && PARCEL_ID_PATTERN.exec(query) === null) {
            appToast.error(t('viewParcel:branchParcelList.barcode.error'))
            return;
        }

        updateFilter({
            ...storedFilter,
            query,
        });
    }, [storedFilter, t, updateFilter]);

    const onBarcodeSearchFormSubmit = useCallback((event?: FormEvent) => {
        void form.handleSubmit(onBarcodeSearchSubmit)(event);

        if (event) {
            event.stopPropagation();
        }
    }, [form, onBarcodeSearchSubmit]);

    const onRangeChange = useCallback<DateRangeOnChange>((selectedRange) => {
        if (selectedRange) {
            updateFilter({
                ...storedFilter,
                range: selectedRange,
            });
        }
    }, [storedFilter, updateFilter]);

    const onCheckboxChange = useCallback<ChangeEventHandler<HTMLInputElement>>((event) => {
        updateFilter({
            ...storedFilter,
            hasIssue: event.target.checked,
        });
    }, [storedFilter, updateFilter]);

    const reset = useCallback(() => {
        updateFilter(defaultFilter);
        form.setValue('query', '');
    }, [defaultFilter, form, updateFilter]);

    // TODO: remove in release >70.2.0
    const fixFiltersDuration = useFixFiltersDuration();

    useEffect(() => {
        const fixedRange = fixFiltersDuration(storedFilter.range);
        if (fixedRange) {
            updateFilter({
                ...storedFilter,
                range: fixedRange,
            });
        } else {
            if (!incomingParcelListManager.hasFilter) {
                incomingParcelListManager.setFilter(storedFilter);
            }
            if (!outgoingParcelListManager.hasFilter) {
                outgoingParcelListManager.setFilter(storedFilter);
            }
        }
    }, [fixFiltersDuration, incomingParcelListManager, outgoingParcelListManager, storedFilter, updateFilter]);

    const formId = 'branchParcelListFilter';

    return <div className="branch-parcel-list-filter">
        <form id={formId} onSubmit={onBarcodeSearchFormSubmit}>
            <SearchInput
                label={t('parcel:property.number.helper')}
                formId={formId}
                form={form}
                onSubmit={onBarcodeSearchSubmit}
            />
        </form>
        <DateRangePicker
            selectedRange={storedFilter.range}
            label={t('viewParcel:branchParcelList.dateRange.label')}
            formId={formId}
            namedRanges={namedRanges}
            maxEndDate={new Date()}
            minStartDate={sub(new Date(), PARCEL_FILTER_MAX_RANGE)}
            onChange={onRangeChange}
        />
        <Checkbox
            name="hasIssue"
            formId={formId}
            label={t('parcel:property.hasIssue.filter')}
            inputOptions={{
                onChange: onCheckboxChange,
                checked: storedFilter.hasIssue,
            }}
        />
        <FormResetLink onReset={reset} />
    </div>

}
