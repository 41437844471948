import {FC, useCallback, useContext, useEffect, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {postByContextAction} from "@app/Manuals/api/manualsApi";
import {ManualContent} from "@app/Manuals/components/ManualContent/ManualContent";
import {ManualPost} from "@app/Manuals/model/ManualPost";
import {NewMessage} from "@app/Messaging/components/NewMessage/NewMessage";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {SearchTabContent} from "@common/layouts/SecuredLayout/Header/HeaderActions/SupportModal/SearchTabContent/SearchTabContent";
import {useContextAction} from "@common/layouts/SecuredLayout/Header/HeaderActions/SupportModal/useContextAction";

import './supportModal.scss';

export type SupportModalProps = {
    show: boolean;
    onHide: () => void;
}

export const SupportModal: FC<SupportModalProps> = ({onHide, show}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const contextAction = useContextAction();
    const ability = useContext(AbilityContext);
    const showMessageTab = contextAction !== 'message:newMessage'
        && ability.cannot(Action.SEND, Subject.OUTGOING_MESSAGE)
        && ability.can(Action.SEND, Subject.INCOMING_MESSAGE);

    const [contextWpPost, setContextWpPost] = useState<ManualPost|false|null>(null);
    const [selectedTab, setSelectedTab] = useState<string|null>('search');

    const selectMessageTab = useCallback(() => {
        setSelectedTab('message');
    }, []);

    useEffect(() => {
        if (!show || !contextAction) {
            setContextWpPost(false);
            return;
        }
        postByContextAction(contextAction, appContext.api, t)
            .then((post) => {
                setContextWpPost(post || false);
            })
            .catch(() => {
                setContextWpPost(false);
            });
    }, [appContext.api, contextAction, show, t]);

    return <BaseModal show={show} onHide={onHide} className="support-modal" header={t('supportPopup:title')} height="extended">
        {contextWpPost !== null && <Tabs activeKey={selectedTab || undefined} onSelect={(tab) => setSelectedTab(tab)}>
            <Tab eventKey="search" title={t('supportPopup:tabTitles.search')}>
                {selectedTab === 'search' && <SearchTabContent />}
            </Tab>
            {contextWpPost && <Tab eventKey="context" title={t('supportPopup:tabTitles.context')}>
                <h1>{contextWpPost.title.rendered}</h1>
                <ManualContent content={contextWpPost.content} />
            </Tab>}
            {showMessageTab && <Tab eventKey="message" title={t('supportPopup:tabTitles.message')}>
                <NewMessage isInsideModal={true} onFormStored={selectMessageTab} />
            </Tab>}
        </Tabs>}
    </BaseModal>
}
