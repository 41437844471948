import {useMemo} from "react";

import {NamedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";

export enum GeneralDateRangePickerRanges {
    TODAY = 0,
    LAST_WEEK = 1,
    LAST_MONTH = 2,
}

export const useGeneralDateRangePickerRanges = (): NamedDateRange[] => {
    return useMemo<NamedDateRange[]>(() => [
        {
            name: 'dateRangePicker:generalNamedRanges.today',
            duration: {days: 1},
        },
        {
            name: 'dateRangePicker:generalNamedRanges.lastWeek',
            duration: {weeks: 1},
        },
        {
            name: 'dateRangePicker:generalNamedRanges.lastMonth',
            duration: {months: 1},
        },
    ], []);
}
