import {add} from "date-fns";
import {FC, Fragment, useCallback, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {getProlongInfo, prolong} from "@app/Packet/api/packetApi";
import {Packet} from "@app/Packet/model/Packet";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {isProlongPacketApiErrorItem} from "@app/Packet/model/ProlongPacketApiErrorItem";
import {DatePicker} from "@common/components/forms/DatePicker/DatePicker";
import {Img} from "@common/components/Img/Img";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {getFirstErrorItem} from "@common/utils/api/getFirstErrorItem";
import {parseDate} from "@common/utils/parseDate";

type ProlongPacketInformation = {
    dateLimitSince: Date|null;
    dateLimitTill: Date|null;
}

export type ProlongPacketProps = {
    packet: Packet;
    onSuccess?: () => void;
}

export const ProlongPacket: FC<ProlongPacketProps> = ({onSuccess, packet}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [prolongInfo, setProlongInfo] = useState<ProlongPacketInformation>({dateLimitSince: null, dateLimitTill: null});
    const [prolongTo, setProlongTo] = useState<Date|undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const showModalErrorToast = useShowErrorToast(modalToast, 'viewProlongPacket:message.error', 'viewProlongPacket:message.error.wrongDate');
    const dateFormatter = useDateFormatter();

    const onProlongPacketModalOpen = useCallback(() => {
        setModalOpened(true);
        setIsLoading(true);
        const defaultProlongInterval = {days: 1};
        getProlongInfo({barcode: packet.barcode}, appContext.api)
            .then((response) => {
                if (response.prolongLimitTill) {
                    setProlongInfo({
                        dateLimitSince: add(parseDate(response.actualStorageDate || packet.storedUntil || new Date()), defaultProlongInterval),
                        dateLimitTill: parseDate(response.prolongLimitTill),
                    })
                } else {
                    setProlongInfo({dateLimitSince: null, dateLimitTill: null})
                }
            })
            .catch(() => {
                setProlongInfo({
                    dateLimitSince: add(parseDate(packet.storedUntil || new Date()), defaultProlongInterval),
                    dateLimitTill: add(parseDate(packet.storedUntil || new Date()), defaultProlongInterval),
                })
            })
            .finally(()=>{
                setIsLoading(false);
            })
    }, [appContext.api, packet.barcode, packet.storedUntil]);

    const onProlongPacketModalClose = useCallback(() => {
        setModalOpened(false);
    }, []);

    const onSubmit = useCallback(() => {
        if (!prolongTo) {
            modalToast.error(t('viewProlongPacket:prolongTo.error.required'));
            return;
        }

        setIsSubmitting(true);
        prolong({barcode: packet.barcode, prolongTo}, appContext.api)
            .then(() => {
                setModalOpened(false);
                appToast.success(t('viewProlongPacket:message.success'));
                if (onSuccess) {
                    onSuccess();
                }
            })
            .catch((error: ApiError) => {
                const firstError = getFirstErrorItem(error);
                if (firstError && isProlongPacketApiErrorItem(firstError)) {
                    if (firstError.variables.prolongLimitTill === null) {
                        modalToast.error(t('viewProlongPacket:message.error.wishNotProlong'));
                    } else {
                        modalToast.error(t(
                            'viewProlongPacket:message.error.wrongDatePrecise',
                            {
                                prolongTo: dateFormatter(firstError.variables.prolongTo, DateFormatType.DATE_FORMAT),
                                prolongLimitTill: dateFormatter(firstError.variables.prolongLimitTill, DateFormatType.DATE_FORMAT),
                            }
                        ));
                    }
                } else {
                    showModalErrorToast(error);
                }
            })
            .finally(() => {
                setIsSubmitting(false);
            })
    }, [appContext.api, dateFormatter, onSuccess, packet.barcode, prolongTo, showModalErrorToast, t]);

    if (packet.isClaimAssistant || packet.status.id !== PacketStatusId.PICKUP_READY || !packet.isAtUsersBranch) {
        return null;
    }

    const canProlongPacket = prolongInfo.dateLimitSince && prolongInfo.dateLimitTill && prolongInfo.dateLimitSince <= prolongInfo.dateLimitTill;

    return <Fragment>
        <Button
            onClick={onProlongPacketModalOpen}
            title={t('viewDashboard:action.prolong')}
            variant="success"
        >
            <Img src="/images/icons/ico/ico-table-time.svg" alt={t('viewDashboard:action.prolong')} />
        </Button>
        <BaseModal
            show={modalOpened}
            onHide={onProlongPacketModalClose}
            header={t('viewProlongPacket:title')}
            footer={<Fragment>
                <Button disabled={isLoading || !canProlongPacket} onClick={onSubmit} variant="success">{t('viewProlongPacket:action.apply')}</Button>
                <Button onClick={onProlongPacketModalClose}>{t('viewProlongPacket:action.cancel')}</Button>
            </Fragment>}
            loading={isLoading || isSubmitting}
            size="lg"
            height="auto"
        >
            {!isLoading && <Fragment>
                {canProlongPacket && <DatePicker
                    dayPickerProps={{
                        mode: 'single',
                        selected: prolongTo,
                        disabled: [
                            {before: prolongInfo.dateLimitSince || new Date()},
                            {after: prolongInfo.dateLimitTill || new Date()},
                        ],
                        defaultMonth: prolongTo || prolongInfo.dateLimitSince || undefined,
                        fromMonth: prolongInfo.dateLimitSince || undefined
                    }}
                    dateFormat={DateFormatType.DATE_FORMAT}
                    name="prolongTo"
                    formId="prolongPacketForm"
                    label={t('viewProlongPacket:prolongTo.label')}
                    onDateSelect={setProlongTo}
                    open={true}
                    placeholder={t('viewProlongPacket:prolongTo.placeholder')}
                    value={prolongTo}
                />}
                {!canProlongPacket && <span className="text-danger">{t('viewProlongPacket:message.error.canNotProlong')}</span>}
            </Fragment>}
        </BaseModal>
    </Fragment>

}
