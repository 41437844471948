import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {
    PartnerCardTransactionGrid
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionGrid/PartnerCardTransactionGrid";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {useCaslGuard} from "@common/hooks/useCaslGuard";

import './partnerCardTransactionPage.scss';

export const PartnerCardTransactionPage: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const canShow = useCaslGuard(Action.MANAGE, Subject.PARTNER_CARD_TRANSACTION, DashboardRoute);
    if (!canShow) {
        return null;
    }

    return <PageContent className="partner-card-transaction-page">
        <PageHeader>{t('viewPartnerCardTransaction:header')}</PageHeader>
        {appContext.user.branchId && <PartnerCardTransactionGrid branchId={appContext.user.branchId} />}
    </PageContent>

})
