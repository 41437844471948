import {observer} from "mobx-react-lite";
import {FC} from "react";

import {DashboardGridManager} from "@app/AppContext/classes/DashboardGridManager";
import {PacketGrid} from "@app/Packet/components/PacketGrid/PacketGrid";
import {
    DashboardGridExtraColumns
} from "@app/Packet/pages/DashboardPage/DashboardGrid/DashboardGridExtraColumns/DashboardGridExtraColumns";
import {
    DashboardGridExtraHeaders
} from "@app/Packet/pages/DashboardPage/DashboardGrid/DashboardGridExtraHeaders/DashboardGridExtraHeaders";
import {PacketListExport} from "@app/Packet/pages/DashboardPage/DashboardGrid/PacketListExport/PacketListExport";
import {useOnLanguageChanged} from "@common/hooks/useOnLanguageChanged";

import './dashboardGrid.scss';

export type DashboardGridProps = {
    manager: DashboardGridManager;
}

export const DashboardGrid: FC<DashboardGridProps> = observer(({manager}) => {
    useOnLanguageChanged(() => {
        void manager.reload();
    }, [manager]);

    return <div className="dashboard-grid">
        <PacketGrid
            gridName="dashboardGrid"
            manager={manager}
            showAddress={true}
            extraHeadersRender={(setStoredSorting) => (<DashboardGridExtraHeaders
                manager={manager}
                setStoredSorting={setStoredSorting}
            />)}
            extraColumnsRender={(packet) => (<DashboardGridExtraColumns manager={manager} packet={packet} />)}
            topCountContent={manager.hasItems ? <PacketListExport manager={manager} /> : undefined}
        />
    </div>;
})
