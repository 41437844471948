export enum ParcelStatusId {
    UNFINISHED = 1,
    SENT = 2,
    DELIVERED = 3,
    CONFIRMED = 4,
}

export type ParcelStatus = {
    id: ParcelStatusId;
    name: string;
}
