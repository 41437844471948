import {receiveC2C} from "@app/Packet/api/packetApi";
import {PasswordHandlingComponent} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {
    ReprintablePasswordModal
} from "@app/Packet/pages/DashboardPage/SearchForm/ReprintablePasswordModal/ReprintablePasswordModal";
import {SearchFormResult} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";

export const C2CConsignPassword: PasswordHandlingComponent = ({
    onError,
    onFinish,
    onVerified,
    password,
}) => {
    return <ReprintablePasswordModal
        apiMethod={receiveC2C}
        password={password}
        translationDomain="viewReceiveC2CPacket"
        onFinish={() => onFinish(SearchFormResult.C2C_CONSIGN_PASSWORD)}
        onError={onError}
        onVerified={onVerified}
    />
}
