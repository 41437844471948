import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC, Fragment} from "react";
import {Button} from "react-bootstrap";
import {FormProvider} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {EditableOpeningHoursGrid} from "@app/OpeningHours/components/EditableOpeningHoursGrid/EditableOpeningHoursGrid";
import {
    useOneDayExceptionModal
} from "@app/OpeningHours/components/ExceptionActions/OneDayExceptionModal/useOneDayExceptionModal";
import {useOpeningHoursLimits} from "@app/OpeningHours/components/ExceptionActions/useOpeningHoursLimits";
import {DatePicker} from "@common/components/forms/DatePicker/DatePicker";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {BaseModal, BaseModalMandatoryProps} from "@common/components/modals/BaseModal/BaseModal";
import {DateFormatType} from "@common/hooks/useDateFormatter";

import './oneDayExceptionModal.scss';

export type OneDayExceptionModalProps = BaseModalMandatoryProps & {
    editDate?: Date;
}

export const OneDayExceptionModal: FC<OneDayExceptionModalProps> = observer(({editDate, onHide, show}) => {
    const {t} = useTranslation();

    const {
        apiError,
        close,
        exceptionType,
        form,
        selectedDay,
        setSelectedDay,
        submit
    } = useOneDayExceptionModal(editDate, onHide, t);

    const {firstSelectableDate, maxExceptionDate} = useOpeningHoursLimits();

    return <BaseModal
        className="one-day-exception-modal"
        show={show}
        onHide={close}
        header={t('openingHours:oneDayExceptionModal.title')}
        footer={<Fragment>
            <Button onClick={close} variant="secondary">
                {t('openingHours:oneDayExceptionModal.action.cancel')}
            </Button>
            <Button disabled={selectedDay === undefined} onClick={form.handleSubmit(submit)} variant="success" data-xid="save-exception">
                {t('openingHours:oneDayExceptionModal.action.save')}
            </Button>
        </Fragment>}
        height="auto"
        disableOutsideClose={true}
        xid="one-day-exception-modal"
    >
        <DatePicker
            dayPickerProps={{
                mode: 'single',
                selected: selectedDay,
                disabled: [{before: firstSelectableDate}, {after: maxExceptionDate}],
                defaultMonth: selectedDay || firstSelectableDate,
                fromMonth: firstSelectableDate,
                toMonth: maxExceptionDate,
            }}
            dateFormat={DateFormatType.DATE_FORMAT_WITH_DOW}
            name="date"
            formId="oneDayExceptionModalForm"
            label={t('openingHours:oneDayExceptionModal.date.label')}
            onDateSelect={setSelectedDay}
            open={editDate === undefined}
            placeholder={t('openingHours:oneDayExceptionModal.date.placeholder')}
            value={selectedDay}
            xid="one-day-exception-date"
        />
        <div>
            <FormGroup
                name="type"
                type="radio"
                label={t('openingHours:oneDayExceptionModal.type.closed')}
                inputId="oneDayExceptionModalForm-closed"
                inputOptions={{value: 'closed'}}
                form={form}
            />
            <FormGroup
                name="type"
                type="radio"
                label={t('openingHours:oneDayExceptionModal.type.exception')}
                inputId="oneDayExceptionModalForm-exception"
                inputOptions={{value: 'exception'}}
                form={form}
            />
        </div>
        <div className={classNames('one-day-exception-modal-grid', {
            'one-day-exception-modal-grid__hidden': !selectedDay || exceptionType === 'closed',
        })}>
            <FormProvider {...form}>
                <EditableOpeningHoursGrid
                    apiError={apiError}
                    collapsibleMobile={false}
                    highlightExceptions={true}
                />
            </FormProvider>
        </div>
    </BaseModal>
})
