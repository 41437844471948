import {useEffect} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {lastTransactionId} from "@app/Transaction/api/cardApi";
import {TranslatableError} from "@common/model/errors/TranslatableError";

export const useLastTransactionId = (
    packet: DeliveryPacket|undefined,
    setTransactionId: (transactionId: string|null|undefined) => void,
    onError: (error?: Error) => void,
    onRunning: (running: boolean) => void,
) => {
    const appContext = useAppContext();

    useEffect(() => {
        if (packet) {
            onRunning(true);
            lastTransactionId(packet.info.barcode, appContext.api)
                .then((response) => {
                    if (packet.isCod && packet.paid) {
                        if (response.lastTransaction.transactionId !== null) {
                            setTransactionId(response.lastTransaction.transactionId);
                        } else {
                            onError(new TranslatableError('cardPayment:error.transactionForCancellingNotFound'));
                        }
                    } else {
                        setTransactionId(null);
                    }
                })
                .catch((error: Error) => {
                    onError(error);
                })
                .finally(() => {
                    onRunning(false);
                });
        }
    }, [appContext.api, onError, onRunning, packet, setTransactionId]);
}
