import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";

export type RunningRequest = {
    date: Date;
    endpoint: Endpoint;
}

export abstract class ApiRequestWatcher {
    public abstract onRequestStart(request: RunningRequest): Promise<void>;

    public abstract onRequestSuccess(request: RunningRequest, response: Response): Promise<void>;

    public abstract onRequestError(request: RunningRequest, error: unknown): Promise<void>;

    public abstract onUnregister(): void;

    public abstract requiresWatch(request: RunningRequest): boolean;
}
