import {FC} from "react";

import {
    CashRegisterBalanceConfirmationRequiredBranchEventContent
} from "@app/BranchEvent/components/CashRegisterBalanceConfirmationRequiredBranchEvent/CashRegisterBalanceConfirmationRequiredBranchEventContent";
import {
    ExpeditionParcelPacketsMissingBranchEventContent
} from "@app/BranchEvent/components/ExpeditionParcelPacketsMissingBranchEvent/ExpeditionParcelPacketsMissingBranchEventContent";
import {
    HolidayBranchHoursConfirmationRequiredBranchEventContent
} from "@app/BranchEvent/components/HolidayBranchHoursConfirmationRequiredBranchEvent/HolidayBranchHoursConfirmationRequiredBranchEventContent";
import {
    InventoryPacketsMissingBranchEventContent
} from "@app/BranchEvent/components/InventoryPacketsMissingBranchEvent/InventoryPacketsMissingBranchEventContent";
import {
    InventoryRequiredBranchEventContent
} from "@app/BranchEvent/components/InventoryRequiredBranchEvent/InventoryRequiredBranchEventContent";
import {
    PacketCapacityConfirmationRequiredBranchEventContent
} from "@app/BranchEvent/components/PacketCapacityConfirmationRequiredBranchEvent/PacketCapacityConfirmationRequiredBranchEventContent";
import {
    PacketExpeditionRequiredBranchEventContent
} from "@app/BranchEvent/components/PacketExpeditionRequiredBranchEvent/PacketExpeditionRequiredBranchEventContent";
import {
    PacketsNotReceivedYesterdayBranchEventContent
} from "@app/BranchEvent/components/PacketsNotReceivedYesterdayEvent/PacketsNotReceivedYesterdayBranchEventContent";
import {
    PacketsPresenceReviewRequiredBranchEventContent
} from "@app/BranchEvent/components/PacketsPresenceReviewRequiredBranchEvent/PacketsPresenceReviewRequiredBranchEventContent";
import {
    PasswordChangeRequiredBranchEventContent
} from "@app/BranchEvent/components/PasswordChangeRequiredBranchEvent/PasswordChangeRequiredBranchEventContent";
import {
    PciDssAuditRequiredBranchEventContent
} from "@app/BranchEvent/components/PciDssAuditRequiredBranchEvent/PciDssAuditRequiredBranchEventContent";
import {SimpleBranchEventContent} from "@app/BranchEvent/components/SimpleBranchEvent/SimpleBranchEventContent";
import {BaseBranchEvent} from "@app/BranchEvent/model/BaseBranchEvent";
import {BranchEvent as BranchEventModel} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";

export type BranchEventType = {
    event: BranchEventModel;
    onNotFound?: (event: BaseBranchEvent) => void;
}

export const BranchEvent: FC<BranchEventType> = ({event, onNotFound}) => {
    switch (event.code) {
        case BranchEventCode.PARCELS_RECEIVE_REQUIRED_COLLECTED:
        case BranchEventCode.PARCELS_RECEIVE_REQUIRED:
        case BranchEventCode.PACKETS_NOT_RECEIVED:
        case BranchEventCode.PACKETS_NOT_RECEIVED_BRANCH_CLOSING:
        case BranchEventCode.LONG_WAITING_DISPATCH:
        case BranchEventCode.UNFINISHED_RECEIVE_PACKETS_EVENT:
        case BranchEventCode.CASH_REGISTER_TODAY_TRANSFER:
        case BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_REQUIRED:
        case BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_MISSING:
        case BranchEventCode.COMMISSION_CREATE_INVOICE_NOTIFY:
        case BranchEventCode.PARCELS_NOT_RECEIVED:
        case BranchEventCode.PACKETS_RECEIVE_REQUIRED:
        case BranchEventCode.PARCEL_PACKETS_MISSING:
        case BranchEventCode.INVENTORY_MISSING:
            return <SimpleBranchEventContent event={event} />
        case BranchEventCode.CASH_REGISTER_BALANCE_CONFIRMATION_REQUIRED:
            return <CashRegisterBalanceConfirmationRequiredBranchEventContent event={event} />
        case BranchEventCode.PACKETS_NOT_RECEIVED_YESTERDAY:
            return <PacketsNotReceivedYesterdayBranchEventContent event={event} />
        case BranchEventCode.INVENTORY_REQUIRED:
            return <InventoryRequiredBranchEventContent event={event} />
        case BranchEventCode.INVENTORY_PACKETS_MISSING:
            return <InventoryPacketsMissingBranchEventContent event={event} />
        case BranchEventCode.PACKET_EXPEDITION_REQUIRED:
            return <PacketExpeditionRequiredBranchEventContent event={event} />
        case BranchEventCode.EXPEDITION_PARCEL_PACKETS_MISSING:
            return <ExpeditionParcelPacketsMissingBranchEventContent event={event} />
        case BranchEventCode.PACKETS_PRESENCE_REVIEW_REQUIRED:
            return <PacketsPresenceReviewRequiredBranchEventContent event={event} />
        case BranchEventCode.HOLIDAY_BRANCH_HOURS_CONFIRMATION_REQUIRED:
            return <HolidayBranchHoursConfirmationRequiredBranchEventContent event={event} />
        case BranchEventCode.PASSWORD_CHANGE_REQUIRED:
            return <PasswordChangeRequiredBranchEventContent event={event} />
        case BranchEventCode.PCI_DSS_AUDIT_REQUIRED:
            return <PciDssAuditRequiredBranchEventContent event={event} />
        case BranchEventCode.PACKET_CAPACITY_CONFIRMATION_REQUIRED:
            return <PacketCapacityConfirmationRequiredBranchEventContent event={event}/>
        default:
            if (onNotFound) {
                onNotFound(event);
            }
            return null;
    }
}
