import {FC, Fragment, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {
    CardPaymentReceipts
} from "@app/CardPayment/components/CardPaymentReceipts/CardPaymentReceipts";
import {
    SignatureVerificationModal
} from "@app/CardPayment/components/SignatureVerificationModal/SignatureVerificationModal";
import {useCardPayment} from "@app/CardPayment/hooks/useCardPayment";
import {isSalePartnerCardTransaction, PartnerCardTransaction} from "@app/Transaction/model/PartnerCardTransaction";
import {TransactionAuthState} from "@app/Transaction/model/TransactionAuthState";
import {TransactionFlag} from "@app/Transaction/model/TransactionFlag";
import {
    PartnerCardTransactionListManager
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionListManager";
import {Button} from "@common/components/Button/Button";
import {Loading} from "@common/components/Loading/Loading";
import {appToast} from "@common/components/Toast/toastOpener";

import './partnerCardTransactionGridActions.scss';

export type PartnerCardTransactionGridActionsProps = {
    manager: PartnerCardTransactionListManager;
    transaction: PartnerCardTransaction;
}

export const PartnerCardTransactionGridActions: FC<PartnerCardTransactionGridActionsProps> = ({manager, transaction}) => {
    const {t} = useTranslation();
    const [running, setRunning] = useState<boolean>(false);

    const onCancellingSuccess = useCallback(() => {
        appToast.success(t('viewPartnerCardTransaction:message.successfulCancelling'));
        void manager.reload();
    }, [manager, t]);

    const {
        cancelPayment,
        cardPaymentState,
        confirmSignature,
    } = useCardPayment(() => false, onCancellingSuccess, setRunning, undefined, transaction.transactionId);

    if (transaction.authState !== TransactionAuthState.APPROVED) {
        return null;
    }

    if (transaction.flag === TransactionFlag.REVERTED) {
        return <Fragment>{t('viewPartnerCardTransaction:message.reverted')}</Fragment>
    }

    return <div className="partner-card-transaction-grid-actions">
        <CardPaymentReceipts cardPaymentState={cardPaymentState} toastInModal={false} />
        {isSalePartnerCardTransaction(transaction) && <Loading active={running}>
            <Button
                onClick={cancelPayment}
                variant="danger"
            >
                {t('viewPartnerCardTransaction:action.cancelTransaction')}
            </Button>
            <SignatureVerificationModal
                cardPaymentState={cardPaymentState}
                onSignatureConfirmed={confirmSignature}
                onSignatureRejected={cancelPayment}
            />
        </Loading>}
    </div>
}
