import {Api} from "@app/AppContext/classes/Api/Api";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";
import {EncodedPdfResponse} from "@common/model/responses/EncodedPdfResponse";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";
import {ResponseWithPrintables} from "@common/model/responses/ResponseWithPrintables";

export const callReaderAction = async (
    url: string,
    payload: Record<string, any>,
    api: Api,
): Promise<ResponseWithPrintables|EncodedPdfResponse|GeneralSuccessResponseContent> => {

    // "Do, whatever they tell you to..."
    return api.call({endpoint: {method: HttpMethod.POST, url}, payload});
}
