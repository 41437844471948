import {ApiErrorHandler} from "@app/AppContext/classes/Api/apiErrorHandlers/ApiErrorHandler";
import {TranslatableError} from "@common/model/errors/TranslatableError";
import {checkConnection} from "@common/utils/api/checkConnection";

export const fetchApiErrorHandler: ApiErrorHandler = async (error) => {
    if (error instanceof TypeError) {
        if (await checkConnection()) {
            throw new TranslatableError('api:timeout');
        } else {
            throw new TranslatableError('api:offline');
        }
    }
}
