import {observer} from "mobx-react-lite";
import {FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {createToken, sendLink} from "@app/PasswordRecovery/api/passwordRecoveryApi";
import {
    NEW_PASSWORD_EMAIL_QUERY_PARAM,
    NEW_PASSWORD_TOKEN_QUERY_PARAM
} from "@app/PasswordRecovery/pages/NewPasswordPage/NewPasswordPage";
import {NewPasswordRoute} from "@app/PasswordRecovery/PasswordRecoveryRoutes";
import {SignInRoute} from "@app/Sign/SignRoutes";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {Loading} from "@common/components/Loading/Loading";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {CenteredLayout} from "@common/layouts/CenteredLayout/CenteredLayout";

import './passwordRecoveryPage.scss';

type PasswordRecoveryFormData = {
    email: string;
}

export const PasswordRecoveryPage: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const form = useForm<PasswordRecoveryFormData>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const navigate = useAppNavigate();

    useEffect(() => {
        if (appContext.user.isLoggedIn) {
            navigate(DashboardRoute);
        }
    }, [appContext.user.isLoggedIn, navigate]);

    useEffect(() => {
        form.setFocus('email');
    }, [form]);

    const showAppErrorToast = useShowErrorToast(appToast, 'viewPasswordRecoveryForm:error');

    const onSubmit = useBlockingCallback((unblock, {email}: PasswordRecoveryFormData) => {
        setIsSubmitting(true);
        void createToken({email}, appContext.api)
            .then(({token}) => {
                const link = window.location.href.replace(window.location.pathname, appContext.router.href({
                    route:  NewPasswordRoute,
                    search: {[NEW_PASSWORD_TOKEN_QUERY_PARAM]: token, [NEW_PASSWORD_EMAIL_QUERY_PARAM]: email},
                }));

                return sendLink({email, token, link}, appContext.api)
                    .then(() => {
                        appToast.success(t('viewPasswordRecoveryForm:message.emailSent'));
                    });

            })
            .catch((error: unknown) => {
                showAppErrorToast(error as Error);
            })
            .finally(() => {
                unblock();
                setIsSubmitting(false);
            });
    }, [appContext.api, appContext.router, showAppErrorToast, t]);

    const formId = 'passwordRecoveryForm';

    if (appContext.user.isLoggedIn) {
        return null;
    }

    return <CenteredLayout
        className="password-recovery-page"
        header={<span>{t('viewPasswordRecoveryForm:title')}</span>}
    >
        <Loading active={isSubmitting}>
            <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
                <FormGroup
                    name="email"
                    label={t('viewPasswordRecoveryForm:email')}
                    formId={formId}
                    registerOptions={{required: t<string>('viewPasswordRecoveryForm:error.email')}}
                    form={form}
                />
                <SubmitFormGroup name="send" value={t('viewPasswordRecoveryForm:send')} formId={formId} />
            </form>
            <div className="password-recovery-page__back-to-sign-in">
                <AppLink to={SignInRoute} title={t('viewPasswordRecoveryForm:action.backToSignIn')}>
                    {t('viewPasswordRecoveryForm:action.backToSignIn')}
                </AppLink>
            </div>
        </Loading>
    </CenteredLayout>
})
