import {FC, Fragment} from "react";
import DropdownItem from "react-bootstrap/DropdownItem";
import {useTranslation} from "react-i18next";

import {Img} from "@common/components/Img/Img";

export const EducationLink: FC = () => {
    const {t} = useTranslation();

    return <Fragment>
        <div className="dropdown-divider" />
        <DropdownItem href={t('global:menu.education.link')} className="nav-link" title={t('global:menu.education.label')}>
            <Img src="/images/icons/ico/ic_description_gray_24px.svg" alt={t('global:menu.education.label')} />{' '}
            {t('global:menu.education.label')}
        </DropdownItem>
    </Fragment>
}
