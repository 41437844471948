import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {BranchCommissionRoute, ManagedCommissionRoute} from "@app/Commission/CommissionRoutes";
import {CommissionNavigation} from "@app/Commission/components/CommissionNavigation/CommissionNavigation";
import {
    BranchCommissionGrid
} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionGrid/BranchCommissionGrid";
import {MonthlyCommissionGrid} from "@app/Commission/pages/BranchCommissionPage/MonthlyCommissionGrid/MonthlyCommissionGrid";
import {
    UnpaidCommissionBox
} from "@app/Commission/pages/BranchCommissionPage/UnpaidCommissionBox/UnpaidCommissionBox";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {BranchIdChecker} from "@common/components/BranchIdChecker/BranchIdChecker";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";

import './branchCommissionPage.scss';

export type BranchCommissionPageProps = {
    branchId: string;
}

export const BranchCommissionPage: FC<BranchCommissionPageProps> = observer(({branchId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    return <BranchIdChecker branchId={branchId} detailRoute={BranchCommissionRoute} fallbackRoute={DashboardRoute}>
        <PageContent className="branch-commission-page">
            {appContext.dashboardInfo.hasManagedBranches && <ReturnLink
                label={t('viewCommission:managed.back')}
                to={ManagedCommissionRoute}
            />}
            <PageHeader branchId={branchId}>{t('viewCommission:title')}</PageHeader>
            <UnpaidCommissionBox branchId={branchId} />
            {!appContext.dashboardInfo.hasManagedBranches && <CommissionNavigation />}
            <MonthlyCommissionGrid branchId={branchId} />
            <BranchCommissionGrid branchId={branchId} />
        </PageContent>
    </BranchIdChecker>
});
