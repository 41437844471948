import {useCallback} from "react";
import {useForm, UseFormReturn} from "react-hook-form";

import {CallJobAction} from "@app/Devices/components/ReaderModal/hooks/useReaderJobs";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobAction} from "@app/Devices/model/ReaderJobAction";

export enum UnknownActionFormServiceCode {
    SERVICE_CODE_PACKET_REMOVE_EXPEDITION = 'S000001',
    SERVICE_CODE_PACKET_RECEIVE = 'S000002',
    SERVICE_CODE_PACKET_CONSIGN = 'S000003',
    SERVICE_CODE_PACKET_RETURN = 'S000004',
    SERVICE_CODE_BRANCH_INVENTORY = 'S000005',
    SERVICE_CODE_NEW_EXPEDITION_PARCEL = 'S000009',
    SERVICE_CODE_RECEIVE_PARCELS_BETWEEN_DEPOTS = 'S000021',
    MANUAL = 'manual',
}

export type UnknownActionFormData =  {
    serviceCode: UnknownActionFormServiceCode;
    manualServiceCode: string;
}

export type UnknownActionForm = {
    form: UseFormReturn<UnknownActionFormData>;
    onSubmit: (data: UnknownActionFormData) => Promise<void>;
}

export const useUnknownActionForm = (callJobAction: CallJobAction, job: ReaderJob|null) : UnknownActionForm => {
    const form = useForm<UnknownActionFormData>({
        mode: 'onSubmit',
    });

    const onSubmit = useCallback(async (data: UnknownActionFormData) => {
        if (!job) {
            return;
        }

        await callJobAction(
            job,
            ReaderJobAction.INSERT,
            {barcode: data.serviceCode === UnknownActionFormServiceCode.MANUAL ? data.manualServiceCode : data.serviceCode},
            'reader:message.readerWasProcessed',
        );
    }, [callJobAction, job]);

    return {
        form,
        onSubmit,
    }
}
