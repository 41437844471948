import {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {token} from "@app/Tools/api/ssoApi";
import {useAppNavigate} from "@common/hooks/useAppNavigate";

const SYSTEM_TOOLS_BRANCH_CARD = 'branch-card';

export const BranchCardTool: FC = () => {
    const {i18n} = useTranslation();
    const appContext = useAppContext();
    const navigate = useAppNavigate();

    useEffect(() => {
        const {systemToolsUrl} = appContext.config;

        token(appContext.api)
            .then((response) => {
                const targetUrl = new URL(`${systemToolsUrl.replace(/\/$/, '')}/${SYSTEM_TOOLS_BRANCH_CARD}/`);
                targetUrl.searchParams.append('sso', response.ssoToken);
                targetUrl.searchParams.append('language', i18n.language.substring(0, 2));
                window.location.href = targetUrl.toString();
            })
            .catch(() => {
                navigate(DashboardRoute);
            });

    }, [appContext.api, appContext.config, i18n.language, navigate]);

    return null;
}
