import {CardPaymentState, CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";

export type UseSetTransactionStatusReturn = (newStatus: CardPaymentTransactionStatus, newTransactionMessage?: string) => void;

export const useSetTransactionStatus = (
    cardPaymentState: CardPaymentState,
    setCardPaymentState: (newState: CardPaymentState) => void,
): UseSetTransactionStatusReturn => {

    return (newStatus, newTransactionMessage) => {
        if (cardPaymentState.status === CardPaymentStatus.READY) {
            return;
        }

        setCardPaymentState({
            ...cardPaymentState,
            transaction: {
                ...cardPaymentState.transaction,
                status: newStatus,
                message: newTransactionMessage || undefined,
            },
        });
    }
}
