export const recordToArray = <KeysType extends string|number|symbol, ValuesType>(
    record: Record<KeysType, ValuesType>
): [key: KeysType, value: ValuesType][] => {
    const result: [key: KeysType, value: ValuesType][] = [];

    for (const key of Object.keys(record)) {
        result.push([
            key as KeysType,
            record[key as KeysType],
        ]);
    }

    return result;
}
