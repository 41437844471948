import {useCallback} from "react";

import {useSingleBranchId} from "@common/hooks/useSingleBranchId";
import {ParametricReactRoute, SimpleReactRoute, To} from "@common/model/ReactRoute";

export const useSingleBranchTo = () => {
    const branchId = useSingleBranchId();

    return useCallback((
        DetailRoute: ParametricReactRoute<'branchId'>,
        FallbackRoute: SimpleReactRoute
    ): To<'branchId'> => {
        if (branchId) {
            return {route: DetailRoute, params: {branchId}};
        }

        return FallbackRoute;
    }, [branchId]);
}

