
import {Api} from "@app/AppContext/classes/Api/Api";
import {
    cardTransactionListEndpoint,
    cardTransactionTypeListEndpoint,
    lastTransactionIdEndpoint,
    partnerCardTransactionListEndpoint
} from "@app/Transaction/api/cardEndpoints";
import {
    LastTransactionIdResponse,
    PartnerCardTransactionListRequest,
    PartnerCardTransactionListResponse,
    TransactionListRequest,
    TransactionListResponse,
    TransactionTypeListResponse
} from "@app/Transaction/model/TransactionsRequestsAndResponses";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const cardTransactionList = async (request: TransactionListRequest, api: Api): Promise<TransactionListResponse> => {
    const {branchId, ...cardTransactionListRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(cardTransactionListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(cardTransactionListRequest),
    });
}

export const cardTransactionTypeList = async (api: Api): Promise<TransactionTypeListResponse> => {
    return api.call(cardTransactionTypeListEndpoint);
}

export const lastTransactionId = async (barcode: string, api: Api): Promise<LastTransactionIdResponse> => {
    return api.call(endpointWithRouteParams(lastTransactionIdEndpoint, {barcode}));
}

export const partnerCardTransactionList = async (request: PartnerCardTransactionListRequest, api: Api): Promise<PartnerCardTransactionListResponse> => {
    const {branchId, ...partnerCardTransactionListRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(partnerCardTransactionListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(partnerCardTransactionListRequest),
    });
}

