import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {TypedPacketListRequestType} from "@app/Packet/components/PacketGrid/callPacketListApi";
import {PacketListFilter} from "@app/Packet/model/PacketListFilter";

export type PacketLocationFilterProps = {
    filter: PacketListFilter;
    updateFilter: (newFilter: PacketListFilter) => void;
}

export const PacketLocationFilter: FC<PacketLocationFilterProps> = observer(({filter, updateFilter}) => {
    const {t} = useTranslation();

    const toggleType = useCallback((type: TypedPacketListRequestType.AT_BRANCH|TypedPacketListRequestType.TODAYS_INCOMING) => {
        if (filter.type === type) {
            const {type: _, ...newFilter} = filter;
            updateFilter(newFilter);
        } else {
            const {dateRange: _dateRange, dateType: _dateType, ...newTypedFilter} = filter;
            updateFilter({...newTypedFilter, type});
        }
    }, [filter, updateFilter]);

    return <Fragment>
        <Button
            onClick={() => toggleType(TypedPacketListRequestType.AT_BRANCH)}
            variant={filter.type === TypedPacketListRequestType.AT_BRANCH ? 'danger' : 'dark'}
            title={t('viewDashboard:action.loadPresentPackets.title')}
        >
            {t('viewDashboard:action.loadPresentPackets.label')}
        </Button>
        <Button
            onClick={() => toggleType(TypedPacketListRequestType.TODAYS_INCOMING)}
            variant={filter.type === TypedPacketListRequestType.TODAYS_INCOMING ? 'danger' : 'dark'}
            title={t('viewDashboard:action.loadIncomingPackets.title')}
        >
            {t('viewDashboard:action.loadIncomingPackets.label')}
        </Button>
    </Fragment>
})
