import {sub} from "date-fns";
import {FC} from "react";
import {Dropdown} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {useTranslation} from "react-i18next";

import {PacketListFilter, PacketListFilterDateType} from "@app/Packet/model/PacketListFilter";
import {DateRangePicker} from "@common/components/forms/DateRangePicker/DateRangePicker";
import {
    useGeneralDateRangePickerRanges
} from "@common/components/forms/DateRangePicker/useGeneralDateRangePickerRanges";

export type PacketDateFilterProps = {
    filter: PacketListFilter;
    updateFilter: (newFilter: PacketListFilter) => void;
}

const PACKET_DATE_FILTER_MAX_RANGE: Duration = {months: 24};

export const PacketDateFilter: FC<PacketDateFilterProps> = ({filter, updateFilter}) => {
    const {t} = useTranslation();

    const namedRanges = useGeneralDateRangePickerRanges();

    return <div className="packet-date-filter">
        <Dropdown>
            <DropdownToggle variant={filter.dateRange ? 'danger' : 'dark'}>
                {t(`viewDashboard:filter.date.${filter.dateType || 'placeholder'}`)}
            </DropdownToggle>
            <DropdownMenu>
                {[PacketListFilterDateType.STORED_AT, PacketListFilterDateType.CONSIGNED_AT, PacketListFilterDateType.DELIVERED_AT].map((dateType) => (
                    <DropdownItem key={dateType} onClick={() => updateFilter({...filter, dateType})}>
                        {t(`viewDashboard:filter.date.${dateType}`)}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
        {filter.dateType && <DateRangePicker
            selectedRange={filter.dateRange}
            formId="packetDateFilter"
            namedRanges={namedRanges}
            minStartDate={sub(new Date(), PACKET_DATE_FILTER_MAX_RANGE)}
            maxEndDate={new Date()}
            onChange={(dateRange) => updateFilter({...filter, dateRange})}
        />}
    </div>
}
