import {FC, Fragment} from "react";
import {UseFormReturn, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    NewMessageFormFields,
    OutgoingMessageFormData,
    OutgoingMessageRecipientType
} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {CountrySelect} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageRecipients/CountrySelect/CountrySelect";
import {CSVRecipientsUpload} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageRecipients/CSVRecipientsUpload/CSVRecipientsUpload";
import {OneRecipientSelect} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageRecipients/OneRecipientSelect/OneRecipientSelect";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";

export type MessageRecipientsProps = {
    canSendBulk: boolean;
    form: UseFormReturn<OutgoingMessageFormData>;
    formId: string;
}

export const MessageRecipients: FC<MessageRecipientsProps> = ({canSendBulk, form, formId}) => {
    const {t} = useTranslation();

    const renderOptionInput = (option: OutgoingMessageRecipientType) => (<FormGroup
        name={NewMessageFormFields.recipientType}
        form={form}
        type="radio"
        formId={`${formId}-${option}`}
        inputOptions={{value: option}}
        label={<Fragment>{t(`message:tabs.content.new.recipientType.${option}`)}</Fragment>}
    />)

    const recipientType = useWatch({control: form.control, name: NewMessageFormFields.recipientType});

    return <div className="form-group message-recipients">
        <div><label>{t('message:tabs.content.new.recipient')}</label></div>
        {!canSendBulk && <OneRecipientSelect form={form} />}
        {canSendBulk && <div>
            {renderOptionInput('one')}
            {recipientType === 'one' && <OneRecipientSelect form={form} />}
            {renderOptionInput('upload')}
            {recipientType === 'upload' && <CSVRecipientsUpload form={form} />}
            {renderOptionInput('region')}
            {recipientType === 'region' && <CountrySelect
                name={NewMessageFormFields.recipientCountryCode}
                placeholder={{value: '', label: t('message:tabs.content.new.recipientCountry.prompt')}}
                formId={formId}
                registerOptions={{
                    required: t<string>('message:tabs.content.new.recipientCountry.required'),
                }}
                form={form}
            />}
        </div>}
    </div>

}
