import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {LoadingButton, LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {appToast} from "@common/components/Toast/toastOpener";
import {
    OfflineNoticeAzApiRequestWatcher
} from "@common/layouts/common/OfflineNotice/OfflineNoticeAzApiRequestWatcher";
import {checkConnection} from "@common/utils/api/checkConnection";

import './offlineNotice.scss';

export const OfflineNotice: FC = observer(() => {
    const {t} = useTranslation();
    const [probablyOffline, setProbablyOffline] = useState<boolean>(false);
    const appContext = useAppContext();

    useEffect(() => {
        const requestWatcher = new OfflineNoticeAzApiRequestWatcher(
            () => setProbablyOffline(true)
        );

        appContext.api.registerWatcher(requestWatcher)

        return () => {
            appContext.api.unregisterWatcher(requestWatcher)
        }
    }, [appContext.api]);

    const onCheckConnection = useCallback<LoadingButtonOnClick>((stopLoading) => {
        void checkConnection()
            .then((isOnline) => {
                setProbablyOffline(!isOnline);
                if (isOnline) {
                    appToast.success(t('global:connectionOk'));
                } else {
                    stopLoading();
                }
            });
    }, [t]);

    if (!probablyOffline) {
        return null;
    }

    return <div className="offline-notice" data-xid="offline-notice">
        {t('global:offlineNotice')}
        <LoadingButton onLoadingClick={onCheckConnection} variant="light">{t('global:checkConnection')}</LoadingButton>
    </div>
})
