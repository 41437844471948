import {FC} from "react";
import {useTranslation} from "react-i18next";

import {LoadingButton, LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";

import './paginatedGridExport.scss';

export type PaginatedGridExportProps = {
    doExport: LoadingButtonOnClick;
}

export const PaginatedGridExport: FC<PaginatedGridExportProps> = ({doExport}) => {
    const {t} = useTranslation();

    return <LoadingButton onLoadingClick={doExport} className="paginated-grid-export" variant="link">
        {t('global:grid.export.csv')}
    </LoadingButton>
}
