import {observer} from "mobx-react-lite";
import {Fragment, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {usePrintCardReceipt} from "@app/CardPayment/hooks/usePrintCardReceipt";
import {PrintCardReceiptRequestType} from "@app/CardPayment/model/CardTerminalRequestsAndResponses";
import {PrintComponent} from "@app/Packet/components/PacketActions/components/PacketPrints/PrintComponent";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {lastTransactionId} from "@app/Transaction/api/cardApi";
import {Button} from "@common/components/Button/Button";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export const PrintCardReceipt: PrintComponent = observer(({packet, setIsSubmitting}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const showErrorToast = useShowErrorToast(modalToast, 'viewPacketPrint:error');
    const print = usePrintCardReceipt(true);

    const printReceipt = useCallback(async (receiptType: PrintCardReceiptRequestType) => {
        setIsSubmitting(true);

        const {lastTransaction} = await lastTransactionId(packet.barcode, appContext.api);
        if (!lastTransaction.transactionId) {
            modalToast.error(t('viewPacketPrint:error.notFound'));
            return;
        }

        print({
            transactionId: lastTransaction.transactionId,
            receiptType,
            forcePrint: true,
        })
            .catch((error: Error) => {
                showErrorToast(error);
            })
            .finally(() => {
                setIsSubmitting(false);
            });
    }, [appContext.api, packet.barcode, print, setIsSubmitting, showErrorToast, t]);

    if (packet.status.id !== PacketStatusId.DELIVERED || !packet.isPaidByCard) {
        return null;
    }

    return <Fragment>
        <Button
            onClick={() => printReceipt(PrintCardReceiptRequestType.CUSTOMER_RECEIPT)}
            variant="secondary"
        >
            {t('viewPacketPrint:action.printCustomerCardReceipt')}
        </Button>
        <Button
            onClick={() => printReceipt(PrintCardReceiptRequestType.MERCHANT_RECEIPT)}
            variant="secondary"
        >
            {t('viewPacketPrint:action.printMerchantCardReceipt')}
        </Button>
    </Fragment>
})
