import {decode} from "html-entities";
import {useContext} from "react";
import {useTranslation} from "react-i18next";
import striptags from "striptags";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {useBranchEventTranslationKey} from "@app/BranchEvent/hooks/useBranchEventTranslationKey";
import {BranchEvent, isInventoryRequiredYearEndVariables} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";
import {InventoryDetailRoute} from "@app/Inventory/InventoryRoutes";
import {ParcelDetailRoute} from "@app/Parcel/ParcelRoutes";
import {moneyToString} from "@common/components/MoneyFormatter/moneyToString";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";

export const useBranchEventMessage = (event: BranchEvent, stripped = false): string => {
    const {t} = useTranslation();
    const {router, user} = useAppContext();
    const ability = useContext(AbilityContext);

    const dateFormatter = useDateFormatter();

    let translationKey = useBranchEventTranslationKey(event);
    let withHtml = false;
    const translationParameters: Record<string, string|number> = {};

    switch (event.code) {
        case BranchEventCode.CASH_REGISTER_TODAY_TRANSFER:
        case BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_REQUIRED:
            translationParameters.amount = moneyToString(event.variables, true);

            if (event.variables.lastDepositTransactionAt) {
                translationParameters.lastDepositTransactionAt = dateFormatter(
                    event.variables.lastDepositTransactionAt,
                    DateFormatType.DATE_FORMAT
                );

                translationKey += 'Date';
            }
            break;
        case BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_MISSING:
            if (event.variables.transactionExpired) {
                translationKey += 'Expired';
            }

            translationParameters.lastDepositTransactionAt = dateFormatter(
                event.variables.lastDepositTransactionAt,
                DateFormatType.DATE_FORMAT
            );
            break;
        case BranchEventCode.COMMISSION_CREATE_INVOICE_NOTIFY:
            translationParameters.amount = moneyToString(event.variables, true);
            translationParameters.limitDate = dateFormatter(event.variables.limitDate, DateFormatType.DATE_FORMAT);
            break;
        case BranchEventCode.PARCELS_NOT_RECEIVED:
            translationParameters.cashTransferTime = dateFormatter(
                event.variables.cashTransferTime,
                DateFormatType.TIME_NO_DATE_FORMAT
            );
            break;
        case BranchEventCode.PACKETS_RECEIVE_REQUIRED:
            translationParameters.time = dateFormatter(event.variables.time, DateFormatType.TIME_NO_DATE_FORMAT);
            break;
        case BranchEventCode.PARCEL_PACKETS_MISSING:
            translationParameters.missingPacketCount = event.variables.missingPacketCount;
            translationParameters.parcelBarcode = event.variables.parcelBarcode;
            translationParameters.link = router.href({
                route: ParcelDetailRoute,
                params: {parcelId: event.variables.parcelId},
            });

            withHtml = true;
            break;
        case BranchEventCode.INVENTORY_REQUIRED:
            if (isInventoryRequiredYearEndVariables(event.variables)) {
                translationParameters.limitDate = dateFormatter(event.variables.limitDate, DateFormatType.DATE_FORMAT);
            } else {
                if (event.variables.days) {
                    translationKey += 'Days';
                    translationParameters.days = event.variables.days;
                }
            }
            break;
        case BranchEventCode.INVENTORY_PACKETS_MISSING:
            translationParameters.missingPacketCount =  event.variables.missingPacketCount;
            translationParameters.createdAt = dateFormatter(event.variables.createdAt, DateFormatType.DATE_FORMAT);
            translationParameters.inventoryDetailLink = router.href({
                route: InventoryDetailRoute,
                params: {branchId: user.branchId, inventoryId: event.variables.inventoryId},
            });

            withHtml = true;
            break;
        case BranchEventCode.INVENTORY_MISSING:
            if (event.variables.days) {
                translationKey += 'Days';
                translationParameters.days = event.variables.days;
            }
            break;
        case BranchEventCode.PACKET_EXPEDITION_REQUIRED:
            translationParameters.packetToExpeditionCount = event.variables.packetToExpeditionCount;
            break;
        case BranchEventCode.EXPEDITION_PARCEL_PACKETS_MISSING:
            translationParameters.missingPacketCount = event.variables.missingPacketCount;
            translationParameters.parcelBarcode = event.variables.parcelBarcode;
            translationParameters.link = router.href({
                route: ParcelDetailRoute,
                params: {parcelId: event.variables.parcelId},
            });
            if (event.variables.parcelSentAt) {
                translationParameters.parcelSentAt = dateFormatter(event.variables.parcelSentAt, DateFormatType.TIME_DATE_IN_PARENTHESES_FORMAT);
            }
            withHtml = true;
            break;
        case BranchEventCode.HOLIDAY_BRANCH_HOURS_CONFIRMATION_REQUIRED:
            if (ability.cannot(Action.MANAGE, Subject.BRANCH_HOURS)) {
                translationKey += 'ReadOnly';
            } else {
                if (event.variables.holidayName) {
                    translationParameters.holidayName = event.variables.holidayName;
                    translationKey += 'WithName';
                } else {
                    translationParameters.holidayDate = dateFormatter(event.variables.holidayDate, DateFormatType.DATE_FORMAT);
                }
            }
            break;
    }

    const translation = t(translationKey, translationParameters);

    if (stripped && withHtml) {
        return decode(striptags(translation));
    }

    return translation;
}
