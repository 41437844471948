import {FC} from "react";
import {useTranslation} from "react-i18next";

import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";

export const NotFoundPage: FC = () => {
    const {t} = useTranslation();

    return <PageContent>
        <PageHeader>{t('errors:404.title')}</PageHeader>
        <p>{t('errors:404.description')}</p>
    </PageContent>
}
