import {FC, Fragment, ReactElement, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {postDetail} from "@app/Manuals/api/manualsApi";
import {ManualContent} from "@app/Manuals/components/ManualContent/ManualContent";
import {ManualPost} from "@app/Manuals/model/ManualPost";
import {Loading} from "@common/components/Loading/Loading";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {appToast} from "@common/components/Toast/toastOpener";

import './manualPostDetail.scss';

export type ManualPostDetailProps = {
    postId: number;
    backLink: ReactElement;
}

export const ManualPostDetail: FC<ManualPostDetailProps> = ({backLink, postId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [manualPost, setManualPost] = useState<ManualPost|null|false>(null);

    useEffect(() => {
        if (manualPost === null) {
            postDetail(postId, appContext.api)
                .then(setManualPost)
                .catch((error: Error) => {
                    appToast.error(t(error.message));
                    setManualPost(false);
                });
        }
    }, [appContext.api, postId, t, manualPost]);

    if (manualPost === false) {
        return null;
    }

    return <Loading active={manualPost === null}>
        <div className="manual-post-detail-content">
            {manualPost && <Fragment>
                {backLink}
                <PageHeader>{manualPost.title.rendered}</PageHeader>
                <ManualContent content={manualPost.content} />
                {backLink}
            </Fragment>}
        </div>
    </Loading>
}
