export enum BranchEventCode {
    CASH_REGISTER_TODAY_TRANSFER = 'cash-register-today-transfer',
    CASH_REGISTER_TODAY_TRANSFER_ISSUE = 'cash-register-today-transfer-issue',
    CASH_REGISTER_MANUAL_TRANSFER_REQUIRED = 'cash-register-manual-transfer-required',
    CASH_REGISTER_MANUAL_TRANSFER_MISSING = 'cash-register-manual-transfer-missing',
    CASH_REGISTER_BALANCE_CONFIRMATION_REQUIRED = 'cash-register-balance-confirmation-required',
    COMMISSION_CREATE_INVOICE_NOTIFY = 'commission-create-invoice-notify',
    PARCELS_RECEIVE_REQUIRED_COLLECTED = 'parcels-receive-required-cash',
    PARCELS_RECEIVE_REQUIRED = 'parcels-receive-required',
    PARCELS_NOT_RECEIVED = 'parcels-not-received',
    PACKETS_RECEIVE_REQUIRED = 'packets-receive-required',
    PACKETS_NOT_RECEIVED = 'packets-not-received',
    PACKETS_NOT_RECEIVED_BRANCH_CLOSING = 'packets-not-received-branch-closing',
    PACKETS_NOT_RECEIVED_YESTERDAY = 'packets-not-received-yesterday',
    PARCEL_PACKETS_MISSING = 'parcel-packets-missing',
    LONG_WAITING_DISPATCH = 'long-waiting-dispatch',
    INVENTORY_REQUIRED = 'inventory-required',
    INVENTORY_PACKETS_MISSING = 'inventory-packets-missing',
    INVENTORY_MISSING = 'inventory-missing',
    PACKET_EXPEDITION_REQUIRED = 'packet-expedition-required',
    PACKET_EXPEDITION_MISSING = 'packet-expedition-missing',
    BRANCH_CLOSED_UNANNOUNCED = 'branch-closed-unannounced',
    EXPEDITION_PARCEL_PACKETS_MISSING = 'expedition-parcel-packets-missing',
    PACKETS_PRESENCE_REVIEW_REQUIRED = 'packets-presence-review-required',
    HOLIDAY_BRANCH_HOURS_CONFIRMATION_REQUIRED = 'holiday-branch-hours-confirmation-required',
    UNFINISHED_RECEIVE_PACKETS_EVENT = 'unfinished-receive-packets',
    PASSWORD_CHANGE_REQUIRED = 'password-change-required',
    PCI_DSS_AUDIT_REQUIRED = 'pci-dss-audit-required',
    PACKET_CAPACITY_CONFIRMATION_REQUIRED = 'packet-capacity-confirmation-required'
}
