import {useCallback, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {useCardPayment} from "@app/CardPayment/hooks/useCardPayment";
import {cancelPacketDelivery} from "@app/Packet/api/packetApi";
import {
    PacketUndeliveryFormData
} from "@app/Packet/components/PacketDelivery/components/PacketUndeliveryModal/PacketUndeliveryForm/PacketUndeliveryFormData";
import {undeliveryReasonToString} from "@app/Packet/components/PacketDelivery/components/PacketUndeliveryModal/undeliveryReasonToString";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {PacketUndeliveryReturnToSender} from "@app/Packet/components/PacketDelivery/model/PacketUndelivery";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export const useUndeliver = (
    lastTransactionId: string|null,
    onUndelivered: (undeliveredPacket: DeliveryPacket) => void,
    packet: DeliveryPacket|undefined,
    setRunning: (running: boolean) => void,
    setCardPaymentCancelled: (isCardPaymentCancelled: boolean) => void,
) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const submittedFormData = useRef<PacketUndeliveryFormData|null>(null);

    const onSuccess = useCallback((isCardPaymentCancelled: boolean) => {
        if (!packet) {
            return;
        }

        if (!isCardPaymentCancelled) {
            packet.undeliver();
            onUndelivered(packet);
        } else {
            setCardPaymentCancelled(true);
            setRunning(false);
        }
    }, [onUndelivered, packet, setCardPaymentCancelled, setRunning]);

    const showModalErrorToast = useShowErrorToast(modalToast, 'viewPacketDelivery:undelivery.error', 'viewPacketDelivery:undelivery.error.general');

    const undeliver = useCallback((isCardPaymentCancelled: boolean = true) => {
        if (!packet || !submittedFormData.current) {
            return;
        }

        cancelPacketDelivery({
            barcode: packet.info.barcode,
            reason: undeliveryReasonToString(submittedFormData.current.reason, t, submittedFormData.current.otherReason),
            returnToSender: submittedFormData.current.returnToSender === PacketUndeliveryReturnToSender.YES,
        }, appContext.api)
            .then(() => {
                onSuccess(isCardPaymentCancelled);
            })
            .catch((error: Error) => {
                showModalErrorToast(error);
                setRunning(false);
            });
    }, [packet, t, appContext.api, onSuccess, showModalErrorToast, setRunning]);

    const onPaymentCancellingSuccess = useCallback(() => {
        if (packet && packet.isCod) {
            packet.unpay();
        }

        undeliver(true);
    }, [packet, undeliver]);

    const {
        cancelPayment,
        cardPaymentState,
        confirmSignature,
        resetPaymentState,
    } = useCardPayment(() => false, onPaymentCancellingSuccess, setRunning, packet, lastTransactionId);

    useEffect(() => {
        if (!packet) {
            resetPaymentState();
        }
    }, [packet, resetPaymentState]);

    const submit = useCallback((formData: PacketUndeliveryFormData) => {
        submittedFormData.current = formData;
        if (packet && packet.isCod && packet.paid) {
            void cancelPayment();
        } else {
            undeliver(false);
        }
    }, [cancelPayment, packet, undeliver]);

    return {
        cancelPayment,
        cardPaymentState,
        confirmSignature,
        onSuccess,
        submit,
    }

}
