import {BasePacket} from "@app/Packet/model/BasePacket";
import {PacketDeliveryMeta} from "@app/Packet/model/PacketDeliveryMeta";

export const EMPTY_COD = '0.00';

export type PacketDetailed = BasePacket & {
    cod: string;
    currency: string;
    deliveryMeta: PacketDeliveryMeta;
}

export const isPacketDetailed = (packet: BasePacket): packet is PacketDetailed => {
    return 'cod' in packet && 'currency' in packet;
}

export const packetHasCod = (packet: PacketDetailed): boolean => {
    return packet.cod !== EMPTY_COD;
}
