import {FC, Fragment, useCallback, useContext} from "react";
import {FormProvider} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {EditableOpeningHoursGrid} from "@app/OpeningHours/components/EditableOpeningHoursGrid/EditableOpeningHoursGrid";
import {
    usePublicHolidayConfirmModal
} from "@app/OpeningHours/components/PublicHolidayConfirmModal/usePublicHolidayConfirmModal";
import {Alert} from "@common/components/Alert/Alert";
import {Button} from "@common/components/Button/Button";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

import './publicHolidayConfirmModal.scss';

export type PublicHolidayConfirmModalProps = {
    startDate: string;
    endDate: string;
};

export const PublicHolidayConfirmModal: FC<PublicHolidayConfirmModalProps> = ({endDate, startDate}) => {
    const {
        apiError,
        apiErrorsIndexMap,
        close,
        form,
        isLoading,
        modalBodyRef,
        open,
        opened,
        submit
    } = usePublicHolidayConfirmModal(startDate, endDate);
    const {t} = useTranslation();

    const scrollUp = useCallback(() => {
        if (modalBodyRef.current) {
            modalBodyRef.current.scrollTo({top: 0});
        }
    }, [modalBodyRef]);

    const ability = useContext(AbilityContext);

    if (!ability.can(Action.MANAGE, Subject.BRANCH_HOURS)) {
        return null;
    }

    return <Fragment>
        <Button onClick={open} data-xid="public-holiday-confirm-button">{t('openingHours:event.confirm')}</Button>
        <BaseModal
            bodyRef={modalBodyRef}
            show={opened}
            loading={isLoading}
            onHide={close}
            height="extended"
            header={t('openingHours:publicHolidayConfirmModal.title')}
            className="public-holiday-confirm-modal"
            disableOutsideClose={true}
            xid="public-holiday-confirm-modal"
        >
            <Alert type="info" alertId="public-holiday-confirm">{t('openingHours:publicHolidayConfirmModal.message')}</Alert>
            <FormProvider {...form}>
                <EditableOpeningHoursGrid
                    apiError={apiError}
                    apiErrorsIndexMap={apiErrorsIndexMap}
                    onValidationError={scrollUp}
                />
            </FormProvider>
            <div className="public-holiday-confirm-modal-buttons">
                <Button onClick={close} data-xid="public-holiday-postpone">
                    {t('openingHours:publicHolidayConfirmModal.action.postpone')}
                </Button>
                <Button onClick={form.handleSubmit(submit)} variant="success" data-xid="public-holiday-confirm">
                    {t('openingHours:publicHolidayConfirmModal.action.confirm')}
                </Button>
            </div>
        </BaseModal>
    </Fragment>
}
