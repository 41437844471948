import {DefaultTFuncReturn} from "i18next";
import {observer} from "mobx-react-lite";
import {ReactElement, useEffect, useState} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {detail} from "@app/Branch/api/branchApi";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './pageHeader.scss';

export type PageHeaderProps = {
    branchId?: string|null;
    subTitle?: ReactElement|DefaultTFuncReturn;
}

export const PageHeader: FCWithChildren<PageHeaderProps> = observer(({branchId, children, subTitle}) => {
    const [realSubTitle, setRealSubTitle] = useState<ReactElement|DefaultTFuncReturn|string|undefined>(subTitle);
    const {api, dashboardInfo, user} = useAppContext();

    useEffect(() => {
        if (!realSubTitle && branchId) {
            if (dashboardInfo.loaded) {
                if (dashboardInfo.branch && branchId === user.branchId) {
                    setRealSubTitle(dashboardInfo.branch.name);
                } else {
                    detail(branchId, api)
                        .then((branchDetailResponse) => {
                            if (branchDetailResponse.items.length > 0) {
                                setRealSubTitle(branchDetailResponse.items[0].name);
                            }
                        })
                        .catch(() => {
                            // no subtitle on detail error
                        });
                }
            }
        }
    }, [api, branchId, dashboardInfo.branch, dashboardInfo.loaded, realSubTitle, user.branchId]);

   return <div className="page-header">
       <h3 className="page-header__heading">{children}</h3>
       {realSubTitle && typeof realSubTitle === 'string' && <p className="page-header__subtitle">{realSubTitle}</p>}
       {realSubTitle && typeof realSubTitle !== 'string' && realSubTitle}
   </div>
})
