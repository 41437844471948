import {Dispatch, SetStateAction, useCallback, useEffect, useState} from 'react';

import {useLogError} from "@common/hooks/useLogError";
import {readLocalStorage, writeLocalStorage} from "@common/utils/localStorage";
import {notEqual} from "@common/utils/notEqual";

export const useLocalStorageState = <T>(key: string, initialState: T, forceInitialValue: boolean = false): [T, Dispatch<SetStateAction<T>>] => {

    const [storedValue, setStoredValue] = useState<T>(() => {
        if (forceInitialValue) {
            return initialState;
        }

        try {
            return readLocalStorage<T>(key) || initialState;
        } catch (error) {
            return initialState;
        }
    });

    const logError = useLogError();

    const setValue = useCallback((value: SetStateAction<T>) => {
        try {
            setStoredValue(value);
        } catch (error: unknown) {
            void logError(error as Error);
        }
    }, [logError]);

    useEffect(() => {
        if (notEqual(storedValue, readLocalStorage<T>(key))) {
            writeLocalStorage(key, storedValue);
        }
    }, [key, storedValue]);

    return [storedValue, setValue];
}
