import {useContext} from "react";

import {AppContext} from "@app/AppContext/AppContext";
import {AppContextReactContext} from "@app/AppContext/AppContextProvider";

export const useAppContext = (): AppContext => {
    const appContext = useContext(AppContextReactContext);

    if (!appContext) {
        throw new Error('Trying to use an uninstantiated AppContext!');
    }

    return appContext;
}
