
import {action, computed, makeObservable, observable} from "mobx";

import {
    PaginationApiMethod, PaginationApiMethodReturnType,
    ProcessPaginationResponseCallback
} from "@app/common/model/PaginationApiMethod";
import {PaginatedListRequest} from "@common/model/requests/PaginatedListRequest";
import {RequestWithEmbedded} from "@common/model/requests/RequestWithEmbedded";
import {ResponseWithEmbedded} from "@common/model/responses/ResponseWithEmbedded";

export enum PaginatedDataManagerState {
    LOADING,
    LOADED,
    ERROR,
}

export abstract class PaginatedDataManager<
    ItemType,
    RequestType extends PaginatedListRequest & RequestWithEmbedded,
    ExtendedResponseType extends ResponseWithEmbedded = ResponseWithEmbedded
> {
    public static readonly DEFAULT_PAGE_SIZE = 40;
    public static readonly FIRST_PAGE = 1;

    public page = PaginatedDataManager.FIRST_PAGE;
    public readonly pageSize: number;
    public items: ItemType[] = [];
    public totalCount = 0;
    public error: Error|null = null;
    public state: PaginatedDataManagerState|undefined;
    public filteringRequest: Partial<RequestType>|undefined;

    protected apiMethod: PaginationApiMethod<ItemType, RequestType, ExtendedResponseType>;
    protected processResponse: ProcessPaginationResponseCallback<ItemType, ExtendedResponseType>[] = [];
    protected request: RequestType;

    protected constructor(
        apiMethod: PaginationApiMethod<ItemType, RequestType, ExtendedResponseType>,
        request: RequestType,
        pageSize?: number,
        deepObservableItems?: boolean,
    ) {
        this.apiMethod = apiMethod;
        this.pageSize = pageSize || PaginatedDataManager.DEFAULT_PAGE_SIZE;
        this.request = request;

        makeObservable(this, {
            items: deepObservableItems ? observable.deep : observable.shallow,
            totalCount: observable,
            error: observable,
            state: observable,
            filteringRequest: observable,
            hasError: computed,
            hasItems: computed,
            hasFilter: computed,
            isEmpty: computed,
            isLoading: computed,
            isSuccessful: computed,
            maxPage: computed,
            loadPage: action,
            fetchData: action,
            setData: action,
            setError: action,
            setFilteringRequest: action,
        });
    }

    public get hasError(): boolean {
        return this.state === PaginatedDataManagerState.ERROR;
    }

    public get hasItems(): boolean {
        return this.items.length > 0;
    }

    public get hasFilter(): boolean {
        return this.filteringRequest !== undefined;
    }

    public get isEmpty(): boolean {
        return this.isSuccessful && this.items.length === 0;
    }

    public get isLoading(): boolean {
        return this.state === PaginatedDataManagerState.LOADING;
    }

    public get isSuccessful(): boolean {
        return this.state === PaginatedDataManagerState.LOADED;
    }

    public get maxPage(): number {
        return Math.ceil(this.totalCount / this.pageSize);
    }

    public loadPage = async (page: number): Promise<void> => {
        this.page = page;
        return this.reload();
    }

    public async fetchData(): Promise<void> {
        if (this.state === PaginatedDataManagerState.LOADING) {
            return;
        }

        const request = this.assembleRequest();
        if (!request) {
            return;
        }

        try {
            this.state = PaginatedDataManagerState.LOADING;
            const response = await this.apiMethod(request);
            this.setData(response);

        } catch (error: unknown) {
            this.setError(error as Error);
        }
    }

    public setData(response: PaginationApiMethodReturnType<ItemType, ExtendedResponseType>|null): void {
        if (response) {
            this.items = response.items;
            this.totalCount = response.totalCount;
        } else {
            this.items = [];
            this.totalCount = 0;
        }

        this.state = PaginatedDataManagerState.LOADED;

        this.processResponse.forEach((processResponseItem) => {
            processResponseItem(response);
        });
    }

    public setError = (error: Error|null): void => {
        if (JSON.stringify(error) !== JSON.stringify(this.error)) {
            this.error = error;
        }
        this.items = [];
        this.totalCount = 0;
        this.state = PaginatedDataManagerState.ERROR;
    }

    public setFilteringRequest(filteringRequest: Partial<RequestType>|undefined): void {
        this.filteringRequest = filteringRequest;
        void this.loadPage(PaginatedDataManager.FIRST_PAGE);
    }

    public async reload(): Promise<void> {
        return this.fetchData();
    }

    protected assembleRequest(): RequestType|undefined {
        if (this.page === undefined || !this.filteringRequest) {
            return undefined;
        }

        return {
            ...this.request,
            ...this.filteringRequest,
            page: this.page - 1,
            perPage: this.pageSize,
        };
    }
}
