import classNames from "classnames";
import {FC, KeyboardEvent, MutableRefObject, useCallback, useEffect, useState} from "react";
import {UseFormReturn, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useDebouncedCallback} from "use-debounce";

import {
    PasswordVerificationForm
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PasswordVerificationForm/PasswordVerificationForm";
import {
    PasswordVerificationFormData,
    usePasswordVerificationForm
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PasswordVerificationForm/usePasswordVerificationForm";
import {Deliver} from "@app/Packet/components/PacketDelivery/hooks/useDeliver";
import {PASSWORD_SEARCH_DEBOUNCE_DELAY} from "@app/Packet/pages/DashboardPage/SearchForm/searchFormConstants";
import {InputFlag} from "@common/components/forms/FormGroup/InputFlag/InputFlag";
import {modalToast} from "@common/components/Toast/toastOpener";

import './addPackets.scss';

export type AddPacketProps = {
    deliver: Deliver;
    focus: boolean;
    focused: MutableRefObject<boolean>;
    hasNotDeliveredPackets: boolean;
    onRunning: (running: boolean) => void;
}

export const AddPacket: FC<AddPacketProps> = ({
    deliver,
    focus,
    focused,
    hasNotDeliveredPackets,
    onRunning,
}) => {
    const {t} = useTranslation();

    const [hasError, setHasError] = useState<boolean>(false);

    const onFinish = useCallback((success: boolean, passwordVerificationForm: UseFormReturn<PasswordVerificationFormData>) => {
        onRunning(false);

        if (success) {
            modalToast.success(t(`viewPacketDelivery:addPacket.success.found`));
            passwordVerificationForm.reset();
        }

        if (hasError === success) {
            setHasError(!success);
        }
    }, [hasError, onRunning, t]);

    const {
        form,
        onSubmit,
    } = usePasswordVerificationForm(deliver, undefined, onFinish, 'onChange');

    const deboucedSubmit = useDebouncedCallback(onSubmit, PASSWORD_SEARCH_DEBOUNCE_DELAY);

    const currentValue = useWatch({control: form.control, name: 'password'});

    useEffect(() => {
        if (currentValue === '') {
            setHasError(false);
        }
    }, [currentValue]);

    useEffect(() => {
        return () => {
            deboucedSubmit.cancel();
        }
    }, [deboucedSubmit]);

    const onKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            deboucedSubmit.flush();
        }
    }, [deboucedSubmit]);

    const onFocus = useCallback(() => {
        if (focused) {
            focused.current = true;
        }
    }, [focused]);

    const onBlur = useCallback(() => {
        if (focused) {
            focused.current = false;
        }
    }, [focused]);

    return <div className={classNames('add-packets', {'add-packets_with-not-delivered-packets': hasNotDeliveredPackets})}>
        <PasswordVerificationForm
            focus={focus && !focused.current}
            form={form}
            onSubmit={deboucedSubmit}
            label={
                t(`viewPacketDelivery:addPacket.password.label.deliver${hasNotDeliveredPackets ? 'These' : 'More'}Packets`)
            }
            inputOptions={{
                placeholder: t('viewPacketDelivery:addPacket.password.placeholder'),
                onKeyPress,
                onFocus,
                onBlur,
            }}
            submitOnChange={true}
        >
            {hasError && <InputFlag
                message={t('viewPacketDelivery:addPacket.error.invalidPassword')}
                type="error"
            />}
        </PasswordVerificationForm>
    </div>
}
