import {DefaultTFuncReturn} from "i18next";
import {FC, Fragment, ReactElement, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {Button, ButtonProps} from "@common/components/Button/Button";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

export type ConfirmProps = ButtonProps & {
    message: ReactElement|ReactElement[]|DefaultTFuncReturn|null;
    header: string|null;
    onSuccess: () => void;
    onClick?: () => Promise<boolean>;
    disableOutsideClose?: boolean;
    xid?: string;
}

export const Confirm: FC<ConfirmProps> = ({
    children,
    disableOutsideClose,
    header,
    message,
    onClick,
    onSuccess,
    xid,
    ...buttonProps
}) => {
    const {t} = useTranslation();
    const [confirmModalOpened, setConfirmModalOpened] = useState<boolean>(false);

    const close = useCallback(() => {
        setConfirmModalOpened(false);
    }, []);

    const proceed = useCallback(() => {
        setConfirmModalOpened(false);
        onSuccess();
    }, [onSuccess]);

    const open = useCallback(async () => {
        if (onClick && !await onClick()) {
            return;
        }

        setConfirmModalOpened(true);
    }, [onClick]);

    return <Fragment>
        <Button onClick={open} {...buttonProps} data-xid={xid}>{children}</Button>
        <BaseModal
            show={confirmModalOpened}
            onHide={close}
            header={header}
            footer={<Fragment>
                <Button variant="secondary" onClick={close} data-xid={`${xid}-no`}>{t('core:boolean.no')}</Button>
                <Button variant="success" onClick={proceed} data-xid={`${xid}-yes`}>{t('core:boolean.yes')}</Button>
            </Fragment>}
            size="lg"
            disableOutsideClose={disableOutsideClose}
            xid={`${xid}-confirm-modal`}
        >
            {message}
        </BaseModal>
    </Fragment>

}
