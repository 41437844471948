import {ErrorMessage} from "@hookform/error-message";
import {FC, Fragment, useCallback, useState} from "react";
import {UseFormReturn, useFormState} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {ZPointBranch} from "@app/Branch/model/ZPointBranch";
import {
    NewMessageFormFields,
    OutgoingMessageFormData
} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {BranchSearchModal} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageRecipients/OneRecipientSelect/BranchSearchModal/BranchSearchModal";

export type OneRecipientSelectProps = {
    form: UseFormReturn<OutgoingMessageFormData>;
}

export const OneRecipientSelect: FC<OneRecipientSelectProps> = ({form}) => {
    const {t} = useTranslation();

    const formState = useFormState({control: form.control});
    const [searchModalOpened, setSearchModalOpened] = useState<boolean>(false);

    const onBranchFound = useCallback((branch: ZPointBranch): void => {
        form.setValue(NewMessageFormFields.oneRecipientId, branch.id);
        form.setValue(NewMessageFormFields.oneRecipientName, branch.name);
        setSearchModalOpened(false);
    }, [form]);

    return <Fragment>
        <input
            {...form.register(NewMessageFormFields.oneRecipientName, {
                required: t<string>('message:tabs.content.new.oneRecipientId.required'),
            })}
            className="form-control"
            placeholder={t('message:tabs.content.new.oneRecipientId.label')}
            readOnly
            onClick={() => setSearchModalOpened(true)}
        />
        <ErrorMessage name={NewMessageFormFields.oneRecipientId} errors={formState.errors} as={<p className="alert alert-danger"/>} />
        <BranchSearchModal
            show={searchModalOpened}
            onHide={() => setSearchModalOpened(false)}
            onBranchFound={onBranchFound}
        />
    </Fragment>
}
