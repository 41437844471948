import {FC, Fragment, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {deleteMessage, read, unread} from "@app/Messaging/api/messagingApi";
import {MessageListType} from "@app/Messaging/components/MessagesList/MessageListType";
import {REPLIES_TO_QUERY_PARAM} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/OutgoingMessageForm";
import {ReceivedMessagesListManager} from "@app/Messaging/managers/ReceivedMessagesListManager";
import {SentMessagesListManager} from "@app/Messaging/managers/SentMessagesListManager";
import {NewMessageRoute} from "@app/Messaging/MessagingRoutes";
import {isIncoming, isRead, Message as MessageModel} from "@app/Messaging/model/Message";
import {Button} from "@common/components/Button/Button";
import {Confirm} from "@common/components/Confirm/Confirm";
import {Loading} from "@common/components/Loading/Loading";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";

export type MessageActionProps = {
    message: MessageModel;
    messagesManager: ReceivedMessagesListManager|SentMessagesListManager;
    type: MessageListType;
}

export const MessageAction: FC<MessageActionProps> = ({message, messagesManager, type}) => {
    const {t} = useTranslation();
    const {api, dashboardMessages} = useAppContext();

    const [actionRunning, setActionRunning] = useState<boolean>(false);

    const doAction = useCallback(async (responsePromise: Promise<GeneralSuccessResponseContent>) => {
        setActionRunning(true);
        try {
            await responsePromise;
            await messagesManager.reload();
            dashboardMessages.setReloadRequired(true);
        } catch (error: unknown) {
            appToast.error(t((error as Error).message));
        }
        setActionRunning(false);
    }, [dashboardMessages, messagesManager, t]);

    const navigate = useAppNavigate();
    const reply = useCallback(() => {
        navigate({route: NewMessageRoute, search: {[REPLIES_TO_QUERY_PARAM]: message.id}});
    }, [message.id, navigate]);

    const messageUnread = !isRead(message);

    return <div className="message-action">
        <Loading active={actionRunning} light={messageUnread} className="message-action d-inline-block">
            <Can I={Action.DELETE} a={isIncoming(message) ? Subject.INCOMING_MESSAGE : Subject.OUTGOING_MESSAGE}>
                <Confirm
                    message={t('message:tabs.card.action.delete.confirm.message')}
                    header={t('message:tabs.card.action.delete.confirm.header')}
                    onSuccess={() => doAction(deleteMessage(message.id, api))}
                    variant="secondary"
                >{t('message:tabs.card.action.delete.button')}</Confirm>
            </Can>
            {type === 'received' && <Fragment>
                <Can I={Action.SEND} a={Subject.OUTGOING_MESSAGE}>
                    <Button variant="success" onClick={reply}>
                        {t('message:tabs.card.action.reply')}
                    </Button>
                </Can>
                {messageUnread && <Button
                    onClick={() => doAction(read({messageId: message.id}, api))}
                >
                    {t('message:tabs.card.action.confirmReading')}
                </Button>}
                {!messageUnread && <Button
                    variant="secondary"
                    onClick={() => doAction(unread({messageId: message.id}, api))}
                >
                    {t('message:tabs.card.action.unread')}
                </Button>}
            </Fragment>}
        </Loading>
    </div>
}
