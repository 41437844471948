import {Children} from "react";

import {ManagedCashRegister} from "@app/Transaction/model/CashRegister/ManagedCashRegister";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {FCWithChildren} from "@common/model/FCWithChildren";

export type ManagedCashRegisterGridRowProps = {
    cashRegister: ManagedCashRegister;
    className?: string;
}

export const ManagedCashRegisterGridRow: FCWithChildren<ManagedCashRegisterGridRowProps> = ({cashRegister, children, className}) => {
    const [firstColumn, lastColumn] = Children.toArray(children);

    return <tr className={className}>
        {firstColumn}
        <td className="managed-cash-register-grid__sum managed-cash-register-grid__sum-total">
            <MoneyFormatter amount={cashRegister.sumTotal} currencyCode={cashRegister.branch.currency} />
        </td>
        <td className="managed-cash-register-grid__sum managed-cash-register-grid__last-week-left">
            <MoneyFormatter amount={cashRegister.lastWeekLeft} currencyCode={cashRegister.branch.currency} />
        </td>
        <td className="managed-cash-register-grid__sum managed-cash-register-grid__before-last-week-left">
            <MoneyFormatter amount={cashRegister.beforeLastWeekLeft} currencyCode={cashRegister.branch.currency} />
        </td>
        <td className="managed-cash-register-grid__sum managed-cash-register-grid__waiting-password-transfers">
            <MoneyFormatter amount={cashRegister.waitingPasswordTransfersSum} currencyCode={cashRegister.branch.currency} />
        </td>
        <td className="managed-cash-register-grid__sum managed-cash-register-grid__waiting-transfers">
            <MoneyFormatter amount={cashRegister.waitingTransfersSum} currencyCode={cashRegister.branch.currency} />
        </td>
        {lastColumn}
    </tr>
}
