import {FC} from "react";
import {NavLink, NavLinkProps} from "react-router-dom";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {To} from "@common/model/ReactRoute";

export type AppNavLinkProps<ReactRouteParamNames extends string = string> = Omit<NavLinkProps, 'to'> & {
    to: To<ReactRouteParamNames>;
}

export const AppNavLink: FC<AppNavLinkProps> = ({
  children,
  className,
  to,
  ...navLinkProps
}) => {
    const appContext = useAppContext();
    return <NavLink className={className} {...navLinkProps} to={appContext.router.path(to)}>{children}</NavLink>
}
