import {Fragment, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {
    PasswordChangeRequiredBranchEventContent
} from "@app/BranchEvent/components/PasswordChangeRequiredBranchEvent/PasswordChangeRequiredBranchEventContent";
import {useBranchEventTranslationKey} from "@app/BranchEvent/hooks/useBranchEventTranslationKey";
import {PasswordChangeRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {ModalBranchEventComponent} from "@app/BranchEvent/model/ModalBranchEventComponent";
import {ChangePasswordRoute} from "@app/User/UserRoutes";
import {Button} from "@common/components/Button/Button";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {useAppNavigate} from "@common/hooks/useAppNavigate";

export const PasswordChangeRequiredBranchEventModal: ModalBranchEventComponent<PasswordChangeRequiredBranchEvent>= ({
    event,
    onClose,
}) => {
    const {t} = useTranslation();
    const navigate = useAppNavigate();

    const translationKey = useBranchEventTranslationKey(event);

    const goToChange = useCallback(() => {
        onClose();
        navigate(ChangePasswordRoute);
    }, [navigate, onClose]);

    return <BaseModal
        show={true}
        onHide={onClose}
        header={t(`${translationKey}Title`)}
        footer={<Fragment>
            <Button onClick={onClose} variant="danger">{t('branchEvent:close')}</Button>
            <Button onClick={goToChange} variant="success">
                {t('branchEvent:passwordChangeRequiredChange')}
            </Button>
        </Fragment>}
    >
        <PasswordChangeRequiredBranchEventContent event={event} />{' '}
        <Button onClick={goToChange} variant="success">
            {t('branchEvent:passwordChangeRequiredChange')}
        </Button>
    </BaseModal>

}
