import {DefaultTFuncReturn} from "i18next";
import {ReactElement} from "react";

import {FCWithChildren} from "@common/model/FCWithChildren";

import './detailInfoBox.scss';

export type DetailInfoItem = {
    label: string;
    content: ReactElement|string|DefaultTFuncReturn;
}

export type DetailInfoBoxProps = {
    info: DetailInfoItem[];
}

export const DetailInfoBox: FCWithChildren<DetailInfoBoxProps> = ({children, info}) => {

    return <div className="detail-info-box">
        {children}
        {info.map((infoItem) => {
            return <div key={infoItem.label} className="detail-info-box__row">
                <div>{infoItem.label}</div>
                <div>{infoItem.content}</div>
            </div>
        })}
    </div>
}
