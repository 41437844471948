import {useCallback} from "react";
import {ToastOptions} from "react-toastify";

import {ToastOpener} from "@common/components/Toast/toastOpener";
import {useErrorToMessage} from "@common/hooks/useErrorToMessage";
import {useLogError} from "@common/hooks/useLogError";
import {StringMap, TranslatableError} from "@common/model/errors/TranslatableError";
import {isKnownError} from "@common/utils/api/isKnownError";

export const useShowErrorToast = (
    toastOpener: ToastOpener,
    translationKeyPrefix?: string,
    unknownErrorTranslationKey?: string,
    toastOptions?: ToastOptions
) => {
    const logError = useLogError();

    const errorToMessage = useErrorToMessage(translationKeyPrefix, unknownErrorTranslationKey);

    return useCallback((error: Error, translationParams: StringMap = {}, asHtml?: boolean, doNotLog = false) => {
        if (error instanceof TranslatableError) {
            asHtml = error.asHtml;
        }

        const errorMessage = errorToMessage(error, translationParams);

        if (doNotLog || error instanceof TranslatableError || isKnownError(error)) {
            toastOpener.error(errorMessage, toastOptions, asHtml);
        } else {
            void logError(error)
                .then((errorIdentifier) => {
                    toastOpener.error(errorMessage, toastOptions, asHtml, errorIdentifier);
                });
        }
    }, [errorToMessage, logError, toastOpener, toastOptions]);
}
