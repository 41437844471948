import classNames from "classnames";
import {FC} from "react";

import {PacketStatus, PacketStatusId} from "@app/Packet/model/PacketStatus";

import "./packetStatusBadge.scss";

export type PacketStatusBadgeProps = {
    status: PacketStatus
}

enum BadgeColor {
    YELLOW = 'yellow',
    GREEN = 'green',
    WHITE = 'white',
    GRAY = 'gray',
    BLUE = 'blue',
    LIGHT_GREEN = 'light-green',
}

const statusClassMap = new Map<PacketStatusId, BadgeColor>();
statusClassMap.set(PacketStatusId.DATA_RECEIVED, BadgeColor.YELLOW);
statusClassMap.set(PacketStatusId.PICKUP_READY, BadgeColor.GREEN);
statusClassMap.set(PacketStatusId.DELIVERED, BadgeColor.WHITE);
statusClassMap.set(PacketStatusId.CANCELLED, BadgeColor.GRAY);
statusClassMap.set(PacketStatusId.RETURNED, BadgeColor.GRAY);
statusClassMap.set(PacketStatusId.RETURN, BadgeColor.BLUE);
statusClassMap.set(PacketStatusId.PROBLEM, BadgeColor.WHITE);
statusClassMap.set(PacketStatusId.EN_ROUTE, BadgeColor.LIGHT_GREEN);

export const PacketStatusBadge: FC<PacketStatusBadgeProps> = ({status}) => {
    const badgeClass = statusClassMap.has(status.id) ? statusClassMap.get(status.id) : 'default'

    return <span className={classNames('packet-status-badge', badgeClass)}>
        {status.name}
    </span>
}
