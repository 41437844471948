import {useTranslation} from "react-i18next";

import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './openingHoursGrid.scss';

export type OpeningHoursGridProps = {
    editable?: boolean;
    xid?: string;
}

export const OpeningHoursGrid: FCWithChildren<OpeningHoursGridProps> = ({
    children,
    editable = false,
    xid,
}) => {
    const {t} = useTranslation();

    return <GridTable className="opening-hours-grid" xid={xid}>
        {editable && <thead>
            <tr>
                <th />
                <th className="mobile-edit-heading" colSpan={2} />
                <th>{t('openingHours:grid.block1')}</th>
                <th />
                <th>{t('openingHours:grid.block2')}</th>
                <th />
                <th>{t('openingHours:grid.block3')}</th>
            </tr>
        </thead>}
        <tbody>{children}</tbody>
    </GridTable>
}
