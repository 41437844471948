import {add} from "date-fns";
import {action, computed, makeObservable, observable} from "mobx";

import {catalogues as cataloguesApi} from "@app/AppContext/api/appContextApi";
import {Api} from "@app/AppContext/classes/Api/Api";
import {Catalogues as CataloguesModel} from "@app/AppContext/model/Catalogues";
import {RefreshingDataManager} from "@app/AppContext/model/RefreshingDataManager";
import {PacketStatus, PacketStatusId} from "@app/Packet/model/PacketStatus";

export class Catalogues extends RefreshingDataManager {
    public packetStatus: PacketStatus[] = [];

    protected readonly MAX_AGE = {hours: 24};

    public constructor(private api: Api) {
        super();

        makeObservable(this, {
            packetStatus: observable.ref,
            pickupReadyPacketStatus: computed,
            setResponse: action,
        });
    }

    public get pickupReadyPacketStatus(): PacketStatus|undefined {
        return this.packetStatus.find(
            (packetStatus) => packetStatus.id === PacketStatusId.PICKUP_READY
        );
    }

    public setResponse(response: CataloguesModel|null): void {
        this.packetStatus = response ? response.packetStatus : [];
    }

    public stop(): void {
        this.setResponse(null);
        super.stop();
    }

    public reload(): void {
        this.stop();
        this.start();
    }

    public fetchData = (): void => {
        cataloguesApi(this.api)
            .then(response => {
                this.setResponse(response);
                this.expiresAt = add(new Date(), this.MAX_AGE);
            })
            .catch(() => {
                this.setResponse(null);
            });
    }
}
