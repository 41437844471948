import {action, computed, makeObservable, observable, onBecomeObserved} from "mobx";

import {Api} from "@app/AppContext/classes/Api/Api";
import {monthlyCommissionList} from "@app/Commission/api/commissionApi";
import {MonthlyCommissionListResponse} from "@app/Commission/model/CommissionRequestsAndResponses";
import {CommissionsMonthly} from "@app/Commission/model/CommissionsMonthly";

const MONTHLY_COMMISSION_LIST_PAGE_SIZE = 6;

export class MonthlyCommissionListManager {
    public items: CommissionsMonthly[] = [];
    public totalCount = 0;
    public error: Error|null = null;
    public isLoaded = false;
    public isLoading = false;

    private pagesLoaded = 0;

    constructor(public branchId: string, private api: Api) {
        makeObservable(this, {
            items: observable.shallow,
            totalCount: observable,
            canLoadNextPage: computed,
            error: observable,
            isLoaded: observable,
            isLoading: observable,
            loaded: computed,
            loading: computed,
            setDataFromResponse: action,
            setError: action,
            startLoading: action,
            finishLoading: action,
        });

        onBecomeObserved(this, 'items', this.loadFirstPage);
    }

    public get canLoadNextPage(): boolean {
        return (this.isLoaded || this.items.length > 0) && this.items.length < this.totalCount;
    }

    public get loaded(): boolean {
        return this.isLoaded;
    }

    public get loading(): boolean {
        return this.isLoading;
    }

    public loadFirstPage = (): void => {
        if (this.isLoading || this.pagesLoaded !== 0) {
            return;
        }

        this.fetchData();
    }

    public loadNextPage = (): void => {
        if (this.canLoadNextPage) {
            this.fetchData();
        }
    }

    public fetchData = (): void => {
        this.startLoading();
        monthlyCommissionList({
            branchId: this.branchId,
            sort: [{key: 'year', desc: true}, {key: 'month', desc: true}],
            page: this.pagesLoaded,
            perPage: MONTHLY_COMMISSION_LIST_PAGE_SIZE,
        }, this.api)
            .then((response) => {
                this.setDataFromResponse(response);
            })
            .catch((error) => {
                this.setError(error as Error);
                this.setDataFromResponse(null);
            })
            .finally(() => {
                this.finishLoading();
            });
    }

    public setDataFromResponse = (response: MonthlyCommissionListResponse|null): void => {
        if (response) {
            this.items.push(...response.items);
            this.totalCount = response.totalCount;
            ++this.pagesLoaded;
            this.isLoaded = true;
        } else {
            this.items = [];
            this.totalCount = 0;
            this.pagesLoaded = 0;
            this.isLoaded = false;
        }

        this.setError(null);
    }

    public setError = (error: Error|null): void => {
        if (JSON.stringify(error) !== JSON.stringify(this.error)) {
            this.error = error;
        }
    }

    public startLoading = (): void => {
        this.isLoading = true;
    }

    public finishLoading = (): void => {
        this.isLoading = false;
    }

    public reload = (): void => {
        if (this.isLoading) {
            return;
        }

        this.clearCache();
        this.loadFirstPage();
    }

    protected clearCache = (): void => {
        this.setDataFromResponse(null);
    }
}
