import {FC, Fragment} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Route, Routes} from "react-router-dom";

import {AttachmentDownloader} from "@app/Messaging/components/AttachmentDownloader/AttachmentDownloader";
import {MessagesList} from "@app/Messaging/components/MessagesList/MessagesList";
import {MessagingNavigation} from "@app/Messaging/components/MessagingNavigation/MessagingNavigation";
import {NewMessage} from "@app/Messaging/components/NewMessage/NewMessage";
import {
    NewMessageRoute,
    ReceivedMessagesRoute,
    SentMessagesRoute
} from "@app/Messaging/MessagingRoutes";

import './messaging.scss';

export const Messaging: FC = () => {
    const {t} = useTranslation();

    return <Fragment>
        <Container className="messaging">
            <Row>
                <MessagingNavigation lg={3} md={4} sm={5} />
                <Col lg={9} md={8} sm={7} className="messaging--main">
                    <Routes>
                        <Route path={ReceivedMessagesRoute.path} element={<MessagesList type="received" />} />
                        <Route path={SentMessagesRoute.path} element={<MessagesList type="sent" />} />
                        <Route path={NewMessageRoute.path} element={<Fragment>
                            <h3>{t('message:tabs.new.title')}</h3>
                            <p>{t('message:tabs.content.new.helper')}</p>
                            <NewMessage />
                        </Fragment>} />
                    </Routes>
                </Col>
            </Row>
        </Container>
        <AttachmentDownloader />
    </Fragment>
}
