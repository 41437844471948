
import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {Fragment, PropsWithChildren, ReactElement} from "react";
import {useTranslation} from "react-i18next";

import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {PaginatedDataManager} from "@common/components/grids/PaginatedDataManager";
import {
    PaginatedGridReloadButton
} from "@common/components/grids/PaginatedGrid/PaginatedGridReloadButton/PaginatedGridReloadButton";
import {Paginator} from "@common/components/grids/Paginator/Paginator";
import {Loading} from "@common/components/Loading/Loading";

import './paginatedGrid.scss';

export type PaginatedGridProps<ItemType, RequestType, ExtendedResponseType> = {
    className?: string;
    manager: PaginatedDataManager<ItemType, RequestType, ExtendedResponseType>;
    topCountContent?: ReactElement;
    withoutTable?: boolean;
}

export const PaginatedGrid = observer(<ItemType, RequestType, ExtendedResponseType>({
    children,
    className,
    manager,
    topCountContent,
    withoutTable,
}: PropsWithChildren<PaginatedGridProps<ItemType, RequestType, ExtendedResponseType>>): ReactElement|null => {
    const {t} = useTranslation();

    const pagination = <Paginator
        maxPage={manager.maxPage}
        onPageSelected={manager.loadPage}
        page={manager.page}
    />

    return <div className={classNames('paginated-grid', className)}>
        <Loading active={manager.isLoading}>
            {manager.isEmpty && <p className="paginated-grid__empty">
                {t('global:ui.noItemsFound')}
            </p>}
            {manager.hasError && <p className="paginated-grid__error alert alert-danger">
                {t('global:grid.genericError')}
            </p>}
            {!manager.hasItems && <div className="paginated-grid__reload-empty">
                <PaginatedGridReloadButton manager={manager} hideOnLoading={true} />
            </div>}
            {withoutTable
                ? <Fragment>{children}{pagination}</Fragment>
                : <GridTable
                    aboveGridContent={topCountContent}
                    totalCount={manager.totalCount}
                    totalCountContent={<PaginatedGridReloadButton manager={manager} />}
                    belowGridContent={pagination}
                >{children}</GridTable>
        }
        </Loading>
    </div>
})
