import {observer} from "mobx-react-lite";
import {FC, FormEvent, useCallback, useEffect} from "react";
import {UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {PacketListFilter} from "@app/Packet/model/PacketListFilter";
import {SearchInput, SearchInputData} from "@common/components/forms/SearchInput/SearchInput";

export type PacketSearchFormProps = {
    filter: PacketListFilter;
    focus?: boolean;
    form: UseFormReturn<SearchInputData>;
    showLabel?: boolean;
    updateFilter: (newFilter: PacketListFilter) => void;
}

export const PacketSearchForm: FC<PacketSearchFormProps> = observer(({
    filter,
    focus,
    form,
    showLabel,
    updateFilter,
}) => {
    const {t} = useTranslation();

    useEffect(() => {
        if (focus) {
            window.setTimeout(() => form.setFocus('query'), 0);
        }
    }, [form, focus]);

    const onSearchSubmit = useCallback(({query}: SearchInputData) => {
        if (query === '') {
            const {fulltext: _, ...newFilter} = filter;
            updateFilter(newFilter);
        } else {
            updateFilter({...filter, fulltext: query});
        }
    }, [filter, updateFilter]);

    const onFormSubmit = useCallback((event?: FormEvent) => {
        void form.handleSubmit(onSearchSubmit)(event);
        if (event) {
            event.stopPropagation();
        }
    }, [form, onSearchSubmit]);

    const formId = 'packetSearchForm';

    return <form id={formId} onSubmit={onFormSubmit}>
        <SearchInput
            form={form}
            onSubmit={onSearchSubmit}
            formId={formId}
            label={showLabel ? t('viewPacketSearch:searchInput.label') : undefined}
            inputOptions={{
                placeholder: t('viewPacketSearch:searchInput.placeholder'),
            }}
        />
    </form>
})
