import {useCallback, useMemo} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {deliveryDetail} from "@app/Packet/api/packetApi";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";
import {createDeliveryPacket} from "@app/Packet/components/PacketDelivery/utils/createDeliveryPacket";
import {Packet} from "@app/Packet/model/Packet";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {TranslatableError} from "@common/model/errors/TranslatableError";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {extractEmbedded} from "@common/utils/extractEmbedded";

export const useLoadDeliveryPacket = (
    packet: Packet,
    onSuccess?: () => void,
    onDeliveryPacketLoaded?: (packet: DeliveryPacket) => void,
) => {
    const appContext = useAppContext();

    const packets = useMemo<DeliveryPacketsCollection>(() => new DeliveryPacketsCollection([], false), []);
    const showOpeningErrorToast = useShowErrorToast(appToast);

    const openModal = useCallback(async () => {
        try {
            const response = await deliveryDetail({searchStr: packet.barcode, embed: [Embeddable.SENDER]}, undefined, appContext.api);

            if (response.items.length === 0) {
                throw new TranslatableError('packet:error.notFoundBarcode', {barcode: packet.barcode});
            }

            const loadedDeliveryPacket = createDeliveryPacket(
                response.items[0],
                extractEmbedded(response, Embeddable.SENDER),
            );

            if (onDeliveryPacketLoaded) {
                onDeliveryPacketLoaded(loadedDeliveryPacket);
            }

            packets.load([loadedDeliveryPacket]);
        } catch (error: unknown) {
            showOpeningErrorToast(error as Error);
        }
    }, [appContext.api, onDeliveryPacketLoaded, packet.barcode, packets, showOpeningErrorToast]);

    const showClosingErrorToast = useShowErrorToast(appToast, 'cardPayment:error', 'cardPayment:error.cancelling');

    const closeModal = useCallback((error?: Error) => {
        if (error) {
            showClosingErrorToast(error);
        }

        packets.clear();

        if (onSuccess) {
            onSuccess();
        }
    }, [onSuccess, packets, showClosingErrorToast]);

    return {
        closeModal,
        openModal,
        packets,
    }
}
