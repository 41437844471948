import {FC, Fragment} from "react";
import {Row, Col, Table} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {MicroDepotRoutePacket} from "@app/MicroDepot/model/MicroDepotRoutePacket";
import {MicroDepotRouteSummary} from "@app/MicroDepot/model/MicroDepotRouteSummary";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";

export type PacketsTabProps = {
    routeSummary: MicroDepotRouteSummary;
    packets: MicroDepotRoutePacket[];
}

export const PacketsTab: FC<PacketsTabProps> = ({packets, routeSummary}) => {
    const {t} = useTranslation();

    return <Fragment>
        <Table className="table-grid table-condensed table-responsive">
            <thead>
                <tr>
                    <th className="text-left border-0">{t('viewRouteFinalization:packets.barcode')}</th>
                    <th className="text-left border-0">{t('viewRouteFinalization:packets.recipientName')}</th>
                    <th className="text-center border-0">{t('viewRouteFinalization:packets.deliveryPrediction')}</th>
                    <th className="text-center border-0">{t('viewRouteFinalization:packets.deliveredAt')}</th>
                    <th className="text-center border-0">{t('viewRouteFinalization:packets.depotReturnedAt')}</th>
                    <th className="text-center border-0">{t('viewRouteFinalization:packets.expectedAmount')}</th>
                    <th className="text-center border-0">{t('viewRouteFinalization:packets.cashCollectedAmount')}</th>
                    <th className="text-center border-0">{t('viewRouteFinalization:packets.cardCollectedAmount')}</th>
                </tr>
            </thead>
            <tbody>
            {packets.map((packet, index) => (
                <tr key={index} className={packet.isDepotMissing ? 'table-danger' : ''}>
                    <td className="text-left">
                        <FormattedPacketBarcode barcode={packet.barcode} />
                    </td>
                    <td className="text-left">{packet.recipientName}</td>
                    <td className="text-center"><DateFormatter date={packet.deliveryPrediction} showTime={true} /></td>
                    <td className="text-center">
                        {packet.deliveredAt &&<DateFormatter date={packet.deliveredAt} showTime={true} />}
                    </td>
                    <td className="text-center">
                        {packet.depotReturnedAt &&<DateFormatter date={packet.depotReturnedAt} showTime={true} />}
                    </td>
                    <td className="text-center">
                        <MoneyFormatter amount={packet.expectedAmount} currencyCode={packet.currency} />
                    </td>
                    <td className="text-center">
                        <MoneyFormatter amount={packet.cashCollectedAmount} currencyCode={packet.currency} />
                    </td>
                    <td className="text-center">
                        <MoneyFormatter amount={packet.cardCollectedAmount} currencyCode={packet.currency} />
                    </td>
                </tr>
            ))}
            </tbody>
        </Table>
        <Row>
            <Col xs={3}>
                {t('viewRouteFinalization:counts.total', {count: routeSummary.totalPacketCount})}
            </Col>
            <Col xs={3}>
                {t('viewRouteFinalization:counts.delivered', {count: routeSummary.deliveredPacketCount})}
            </Col>
            {routeSummary.depotMissingPacketCount !== 0 && <Col xs={3}>
                {t('viewRouteFinalization:counts.depotMissing', {count: routeSummary.depotMissingPacketCount})}
            </Col>}
        </Row>
    </Fragment>
}
