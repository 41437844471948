import {useEffect} from "react";
import {useLocation} from "react-router-dom";

import {GoogleTagManager} from "@app/App/components/AppWrapper/GtmProvider/GoogleTagManager";
import {SecuredAppModules} from "@app/App/SecuredAppModules";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";

export const useTrackPageView = (gtm: GoogleTagManager|undefined) => {
    const appContext = useAppContext();
    const location = useLocation();

    useEffect(() => {
        if (!gtm) {
            return;
        }

        let pageCategory: string|undefined;

        if (location.pathname === '/') {
            pageCategory = 'Výdej';
        } else {
            const moduleToCategoryMap: Record<SecuredAppModules, string> = {
                [SecuredAppModules.BRANCH]: 'Nastavení výdejního místa',
                [SecuredAppModules.COMMISSION]: 'Provize',
                [SecuredAppModules.INVENTORY]: 'Inventura',
                [SecuredAppModules.MANUALS]: 'Manuály',
                [SecuredAppModules.MESSAGING]: 'Zprávy',
                [SecuredAppModules.MICRODEPOT]: 'Mikrodepa',
                [SecuredAppModules.OPENING_HOURS]: 'Otevírací doba',
                [SecuredAppModules.PACKET]: 'Zásilka',
                [SecuredAppModules.PARCEL]: 'Balíky',
                [SecuredAppModules.PRINT]: 'Dotisky',
                [SecuredAppModules.TOOLS]: 'Ostatní',
                [SecuredAppModules.TRANSACTION]: 'Platby',
                [SecuredAppModules.USER]: 'Nastavení uživatele',
            };

            for (const moduleName in SecuredAppModules) {
                if (Object.prototype.hasOwnProperty.call(SecuredAppModules, moduleName)) {
                    const module = SecuredAppModules[moduleName as keyof typeof SecuredAppModules];
                    if (location.pathname.startsWith(`/${module}`)) {
                        pageCategory = moduleToCategoryMap[module];
                        break;
                    }
                }
            }
        }

        gtm.tag('customVars', {
            'page.Category': pageCategory || 'Ostatní',
            'user.ID': appContext.user.supportBranchId,
            'user.Type': 'Přihlášen',
        });
    }, [gtm, location, appContext.user.supportBranchId]);
}
