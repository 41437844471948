import {observer} from "mobx-react-lite";
import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardFilter} from "@app/Packet/pages/DashboardPage/DashboardFilter/DashboardFilter";
import {DashboardGrid} from "@app/Packet/pages/DashboardPage/DashboardGrid/DashboardGrid";
import {EventsAndMessages} from "@app/Packet/pages/DashboardPage/EventsAndMessages/EventsAndMessages";
import {SearchForm} from "@app/Packet/pages/DashboardPage/SearchForm/SearchForm";
import {Alert} from "@common/components/Alert/Alert";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {useFeatureFlag} from "@common/hooks/useFeatureFlag";

import './dashboardPage.scss';

export const DashboardPage: FC = observer(() => {
    const {t} = useTranslation();
    const {dashboardGrid, user} = useAppContext();

    const showPacketList = useFeatureFlag('packetParcelLists', true);

    return <PageContent className="dashboard">
        {user.isLoggedIn && !dashboardGrid && <p className="alert alert-danger">
            {t('viewDashboard:error.userWithoutBranchId')}
        </p>}
        {dashboardGrid && <Fragment>
            <div className="dashboard__password-and-events">
                <div>
                    <SearchForm />
                </div>
                <EventsAndMessages />
            </div>
            {showPacketList && <div>
                <DashboardFilter manager={dashboardGrid} />
                <DashboardGrid manager={dashboardGrid} />
            </div>}
            {!showPacketList && <Alert type="warning">
                {t('viewDashboard:error.packetsListDisabled')}
            </Alert>}
        </Fragment>}
    </PageContent>
})
