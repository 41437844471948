import {TFunction} from "i18next";
import {useCallback, useEffect, useRef, useState} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {Branch} from "@app/Branch/model/Branch";
import {parcelPacketList} from "@app/Packet/api/packetListApi";
import {ParcelPacket} from "@app/Packet/model/ParcelPacket";
import {Sender} from "@app/Packet/model/Sender";
import {ParcelDetailed} from "@app/Parcel/model/ParcelDetailed";
import {ParcelDirection} from "@app/Parcel/model/ParcelDirection";
import {
    printParcelBadPackets,
    printParcelBadPacketsPDF,
    printParcelDeliveryNote,
    printParcelDeliveryNotePDF
} from "@app/Print/api/printApi";
import {LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {appToast} from "@common/components/Toast/toastOpener";
import {usePrint} from "@common/hooks/usePrint";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {downloadEncodedPdf} from "@common/utils/api/downloadEncodedPdf";
import {extractEmbedded} from "@common/utils/extractEmbedded";

export const useParcelPacketList = (parcel: ParcelDetailed, t: TFunction) => {
    const appContext = useAppContext();

    const sendersMap = useRef<Map<string, Sender>>(new Map<string, Sender>());
    const branchesMap = useRef<Map<string, Branch>>(new Map<string, Branch>());
    const [parcelPackets, setParcelPackets] = useState<ParcelPacket[]|null|false>(null);
    const showAppErrorToast = useShowErrorToast(appToast);

    useEffect(() => {
        if (parcelPackets !== null) {
            return;
        }

        parcelPacketList({parcelId: parcel.id, embed: [Embeddable.BRANCH, Embeddable.SENDER]}, appContext.api)
            .then((response) => {
                sendersMap.current = extractEmbedded(response, Embeddable.SENDER);
                branchesMap.current = extractEmbedded(response, Embeddable.BRANCH);
                setParcelPackets(response.items);
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
                setParcelPackets(false);
            });
    }, [appContext.api, parcel.id, parcelPackets, showAppErrorToast]);

    const handlePrint = usePrint();
    const showPrintErrorToast = useShowErrorToast(appToast, 'global:print.error', 'global:print.error.general');

    const onPrintPdf = useCallback<LoadingButtonOnClick>((stopLoading) => {
        const printMethod = parcel.direction === ParcelDirection.INCOMING
            ? printParcelBadPacketsPDF
            : printParcelDeliveryNotePDF;

        printMethod({parcelId: parcel.id}, appContext.api)
            .then((response) => {
                downloadEncodedPdf(response, t('viewParcel:pdfFileName'));
            })
            .catch((error: Error) => {
                showPrintErrorToast(error);
            })
            .finally(stopLoading);
    }, [appContext.api, parcel.direction, parcel.id, showPrintErrorToast, t]);

    const onPrint = useCallback<LoadingButtonOnClick>((stopLoading) => {
        const printMethod = parcel.direction === ParcelDirection.INCOMING
            ? printParcelBadPackets
            : printParcelDeliveryNote;

        void handlePrint(printMethod({parcelId: parcel.id}, appContext.api))
            .finally(stopLoading);
    }, [parcel.direction, parcel.id, handlePrint, appContext.api]);

    return {
        branchesMap,
        onPrint,
        onPrintPdf,
        parcelPackets,
        sendersMap,
    };
}
