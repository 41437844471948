import {FC} from "react";
import {useTranslation} from "react-i18next";

import {ParcelPacket} from "@app/Packet/model/ParcelPacket";
import {GridSorter} from "@common/components/grids/GridSorter/GridSorter";
import {Sorter} from "@common/components/grids/GridSorter/Sorter";

export type ParcelPacketListHeaderProps = {
    sorter: Sorter<ParcelPacket, 'dateStored'>;
}

export const ParcelPacketListHeader: FC<ParcelPacketListHeaderProps> = ({sorter}) => {
    const {t} = useTranslation();

   return <thead>
   <tr>
       <th className="packet-grid__barcode">
           {t('packet:property.barcode')}
           <span className="packet-grid__barcode-status">{' / '}{t('packet:property.status')}</span>
       </th>
       <th className="packet-grid__date-stored">
           <GridSorter sortId="dateStored" sorter={sorter}>
               {t('packet:property.dateStored.label')}
           </GridSorter>
       </th>
       <th className="packet-grid__sender">{t('packet:property.sender')}</th>
       <th className="packet-grid__recipient">
           {t('packet:property.recipient')}
           <span className="packet-grid__recipient-sender">{' / '}{t('packet:property.sender')}</span>
       </th>
       <th className="packet-grid__address">{t('packet:property.addressAbbr')}</th>
       <th className="packet-grid__status">{t('packet:property.status')}</th>
       <th className="parcel-packet-list__note">{t('packet:property.note')}</th>
       <th className="parcel-packet-list__received">{t('packet:property.isReceived.title')}</th>
   </tr>
   </thead>
}
