import {useTranslation} from "react-i18next";

import {
    GeneralBranchQualityDetailItem
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/GeneralBranchQualityDetail";
import {GridSorter} from "@common/components/grids/GridSorter/GridSorter";
import {Sorter} from "@common/components/grids/GridSorter/Sorter";
import {FCWithChildren} from "@common/model/FCWithChildren";

export type ProcessPenaltyDetailGridHeaderProps = {
    sorter: Sorter<GeneralBranchQualityDetailItem, 'date'|'penaltyPoints'>;
}

export const ProcessPenaltyDetailGridHeader: FCWithChildren<ProcessPenaltyDetailGridHeaderProps> = ({children, sorter}) => {
    const {t} = useTranslation();

    return <thead>
        <tr>
            <th><GridSorter sortId="date" sorter={sorter}>
                {t('branchQuality:processPenalty.detail.date')}
            </GridSorter></th>
            <th><GridSorter sortId="penaltyPoints" sorter={sorter}>
                {t('branchQuality:processPenalty.detail.penaltyPoints')}
            </GridSorter></th>
            {children}
        </tr>
    </thead>

}
