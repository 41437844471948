import {add} from "date-fns";

import {OauthResponse} from "@app/Sign/api/oauthApi";

export const expiresAtFromResponse = (response: OauthResponse): Date => {
    return add(
        new Date(),
        {seconds: response.token.expiresIn},
    );
}
