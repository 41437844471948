
import {Api} from "@app/AppContext/classes/Api/Api";
import {inventoryPacketsList} from "@app/Inventory/api/inventoryApi";
import {InventoryPacket} from "@app/Inventory/model/InventoryPacket";
import {InventoryPacketsListRequest} from "@app/Inventory/model/InventoryRequestsAndResponses"
import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";

export class InventoryPacketsListManager extends SortablePaginatedDataManager<
    InventoryPacket,
    InventoryPacketsListRequest
> {
    constructor(inventoryId: string, api: Api) {
        super(
            (request) => inventoryPacketsList(request, api),
            {inventoryId},
        );

        this.setFilteringRequest({});
    }
}
