import {useContext} from "react";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";

export const useSecurity = (subject: Subject, action: Action = Action.SHOW): boolean => {
    const ability = useContext(AbilityContext);

    if (!ability.can(action, subject)) {
        window.location.href = '/';
        return false;
    }

    return true;
}
