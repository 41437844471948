import {ApiErrorItem} from "@common/model/errors/ApiErrorItem";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";

export type GeneralErrorResponseContent = GeneralSuccessResponseContent & {
    errors: ApiErrorItem[];
}

export const isGeneralErrorResponseContent = (response: any): response is GeneralErrorResponseContent => {
    return typeof response === 'object' && 'success' in response && 'errors' in response;
}
