export enum ReaderJobAction {
    CANCEL = 'cancel',
    CLOSE = 'close',
    CLOSE_ALL = 'close-all',
    SAVE = 'save',
    CONFIRM = 'confirm',
    INSERT = 'insert',
    INSERT_FINISH = 'insert_finish',
    REMOVE = 'remove',
}
