import {FC, useCallback} from "react";
import {UseFormReturn, useWatch} from "react-hook-form";

import {MessageAttachment} from "@app/Messaging/components/NewMessage/common/MessageAttachment/MessageAttachment";
import {
    IncomingMessageFormData, NewMessageFormFields,
} from "@app/Messaging/components/NewMessage/NewMessageFormFields";

export type MessageAttachmentProps = {
    form: UseFormReturn<IncomingMessageFormData>;
}

export const IncomingMessageAttachment: FC<MessageAttachmentProps> = ({form}) => {
    const {control, register, setValue} = form;
    const attachment = useWatch({control, name: NewMessageFormFields.attachment});
    const registeredField = register(NewMessageFormFields.attachment);

    const setAttachment = useCallback((value: FileList|null) => {
        setValue(NewMessageFormFields.attachment, value);
    }, [setValue]);

    return <MessageAttachment
        registeredField={registeredField}
        setAttachment={setAttachment}
        attachment={attachment}
    />
}
