import {ApiBackend} from "@app/AppContext/classes/Api/model/ApiBackend";
import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";
import {HttpStatusCode} from "@common/model/HttpStatusCode";

export const devicesListEndpointKnownErrorCodes = {
    connectionError: {errorCode: 'connectionError' as const, statusCode: HttpStatusCode.NOT_FOUND},
}

export const devicesListEndpoint: Endpoint<string, keyof typeof devicesListEndpointKnownErrorCodes> = {
    method: HttpMethod.GET,
    url: '/devices',
    knownErrorCodes: devicesListEndpointKnownErrorCodes,
}

export const jobActionEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/devices/reader/job-action',
}

const readerAnalyzeEndpointKnownErrorCodes = {
    readerDeviceUnavailable: {errorCode: 'readerDeviceUnavailable' as const, statusCode: HttpStatusCode.GONE},
    deviceGatewayInaccessible: {errorCode: 'deviceGatewayInaccessible' as const, statusCode: HttpStatusCode.GONE},
    noHardwareInfo: {errorCode: 'noHardwareInfo' as const, statusCode: HttpStatusCode.GONE},
    noReader: {errorCode: 'noReader' as const, statusCode: HttpStatusCode.GONE},
    readerDataRetrieveFailed: {errorCode: 'readerDataRetrieveFailed' as const, statusCode: HttpStatusCode.GONE},
}

export const readerAnalyzeEndpoint: Endpoint<string, keyof typeof readerAnalyzeEndpointKnownErrorCodes> = {
    method: HttpMethod.GET,
    url: '/devices/reader/analyze',
    knownErrorCodes: readerAnalyzeEndpointKnownErrorCodes,
}

export const pdcVersionLinkEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/version/link',
    authenticate: false,
    backend: ApiBackend.PDC_UPDATE_SERVER,
}

export const pdcDefaultVersionLinkEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/version/default',
    authenticate: false,
    backend: ApiBackend.PDC_UPDATE_SERVER,
}
