import {isSameDay} from "date-fns";
import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {parseDate} from "@common/utils/parseDate";

export type DateFormatterProps = {
    date?: Date|string;
    showTime?: boolean;
    showDateDescription?: boolean;
    showYear?: boolean;
}

export const DateFormatter: FC<DateFormatterProps> = ({date = new Date(), showDateDescription= false, showTime= false, showYear = true}) => {
    const {t} = useTranslation();
    const dateFormatter = useDateFormatter();

    let output: string;
    if (showDateDescription && isSameDay(parseDate(date), new Date())) {
        const prefix = t('core:dateIsToday');
        const formatted = dateFormatter(date, DateFormatType.TIME_NO_DATE_FORMAT);
        output = `${prefix} ${formatted}`;
    } else {
        output = dateFormatter(
            date,
            showTime
                ? DateFormatType.TIME_FORMAT
                : showYear
                    ? DateFormatType.DATE_FORMAT
                    : DateFormatType.DATE_FORMAT_WITHOUT_YEAR
        );
    }

    return <Fragment>{output}</Fragment>
}
