import {observable} from "mobx";

import {User} from "@app/AppContext/classes/User/model/User";
import {Branch} from "@app/Branch/model/Branch";
import {Message} from "@app/Messaging/model/Message";
import {MessagesListRequest, MessagesPaginationApiMethod} from "@app/Messaging/model/MessagingRequestsAndResponses";
import {PaginatedDataManager} from "@common/components/grids/PaginatedDataManager";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {ResponseWithEmbedded} from "@common/model/responses/ResponseWithEmbedded";
import {extractEmbedded} from "@common/utils/extractEmbedded";

const MESSAGES_PAGE_SIZE = 10;

export class BaseMessagesListManager<ExtendedResponseType extends ResponseWithEmbedded = ResponseWithEmbedded> extends PaginatedDataManager<Message, MessagesListRequest, ExtendedResponseType> {

    public branches: Map<string, Branch> = new Map<string, Branch>();
    public users: Map<string, User> = new Map<string, User>();

    constructor(apiMethod: MessagesPaginationApiMethod<ExtendedResponseType>) {
        super(apiMethod, {embed: [Embeddable.USER, Embeddable.BRANCH]}, MESSAGES_PAGE_SIZE);
        this.setFilteringRequest({});

        observable.map(this.branches, {deep: false});
        observable.map(this.users, {deep: false});

        this.processResponse.push((response) => {
            this.branches = extractEmbedded(response, Embeddable.BRANCH);
            this.users = extractEmbedded(response, Embeddable.USER);
        });
    }
}
