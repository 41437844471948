import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

const authenticateEndpointKnownErrorCodes = {
    unauthorized: 'unauthorized' as const,
};

export const authenticateEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/oauth/authenticate',
    authenticate: false,
    knownErrorCodes: authenticateEndpointKnownErrorCodes,
}

export const authenticateAsEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/oauth/authenticate-as',
    authenticate: false,
}

export const refreshEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/oauth/token',
    authenticate: false,
}
