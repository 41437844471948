import {ApiErrorItem, ApiErrorItemWithVariables, isApiErrorItemWithVariables} from "@common/model/errors/ApiErrorItem";

export type CreateMessageApiErrorItemVariables = {
    invalidRecipientIds: number[];
}

export type CreateMessageApiErrorItem = ApiErrorItemWithVariables<CreateMessageApiErrorItemVariables>;

export const isMessageApiErrorItem = (item: ApiErrorItem): item is CreateMessageApiErrorItem => {
    return isApiErrorItemWithVariables<CreateMessageApiErrorItemVariables>(item) && 'invalidRecipientIds' in item.variables;
}
