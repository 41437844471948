import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {PercentBranchQualityItem} from "@app/Branch/model/BranchQuality/BranchQualityItem";
import {NumberFormatter} from "@common/components/NumberFormatter/NumberFormatter";

export type PercentRatingProps = {
    qualityItem: PercentBranchQualityItem|null;
}

export const PercentRating: FC<PercentRatingProps> = ({qualityItem}) => {
    const {t} = useTranslation();

    return <div className="percent-rating">
        {qualityItem && <Fragment>
            <NumberFormatter numeric={qualityItem.value} />{' %'}
        </Fragment>}
        {!qualityItem && t('viewBranchQuality:customerFeedback.notAvailable')}
    </div>
}
