import {add} from "date-fns";
import {action, makeObservable, observable} from "mobx";

import {Api} from "@app/AppContext/classes/Api/Api";
import {reprint} from "@app/Print/api/printApi";
import {PrintResult, PrintResultType} from "@common/model/PrintResult";
import {ResponseWithPrintables} from "@common/model/responses/ResponseWithPrintables";
import {readLocalStorage, writeLocalStorage} from "@common/utils/localStorage";
import {parseDate} from "@common/utils/parseDate";

type PrintResultWithTimestamp = PrintResult & {
    timestamp: string;
}

export type Reprint = {
    timestamp: string;
    reprintId: string;
}

export class Print {
    public prints: PrintResultWithTimestamp[] = [];

    protected readonly MAX_AGE = 10 * 60; // keep response for 10 minutes
    protected readonly CACHE_KEY = 'prints';

    constructor(private api: Api) {
        this.setPrints(readLocalStorage<PrintResultWithTimestamp[]>(this.CACHE_KEY) || [], false);

        makeObservable(this, {
            prints: observable,
            setPrints: action,
        });
    }

    public reprintsByPrintType = (printType: PrintResultType): Reprint[]  => {
        const reprints: Reprint[] = [];

        this.prints.forEach((print) => {
            if (print.reprintId && print.type === printType) {
                reprints.push({
                    timestamp: print.timestamp,
                    reprintId: print.reprintId,
                })
            }
        });

        return reprints;
    }

    public reprint = async (reprintId: string): Promise<ResponseWithPrintables> => {
        return reprint({reprintId}, this.api);
    }

    public setPrints = (prints: PrintResultWithTimestamp[], save: boolean = true): void => {
        this.prints = prints.filter((print) =>
            add(parseDate(print.timestamp), {seconds: this.MAX_AGE}) > new Date()
        );

        if (save) {
            writeLocalStorage<PrintResultWithTimestamp[]>(this.CACHE_KEY, this.prints.length ? this.prints : null);
        }
    }

    public addPrints = (printResults: PrintResult[], timestamp: string): void => {
        this.setPrints(this.prints.concat(printResults.map((printResult) => {
            return {...printResult, timestamp};
        })));
    }

    public clearCache = (): void => {
        this.setPrints([]);
    }
}
