import {PropsWithChildren, ReactElement, useEffect, useState} from "react";
import {FieldValues, Path, RegisterOptions, UseFormReturn} from "react-hook-form";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {zPointCountries} from "@app/Branch/api/branchApi";
import {Select, SelectOption} from "@common/components/forms/Select/Select";

export type CountrySelectProps<FormDataType extends FieldValues> = {
    name: Path<FormDataType>;
    label?: string;
    form: UseFormReturn<FormDataType>,
    formId: string;
    placeholder?: SelectOption;
    placeholderDisabled?: boolean;
    registerOptions?: RegisterOptions;
}
export const CountrySelect = <FormDataType extends FieldValues, >({
    form,
    formId,
    label,
    name,
    placeholder,
    placeholderDisabled = true,
    registerOptions
}: PropsWithChildren<CountrySelectProps<FormDataType>>): ReactElement|null => {
    const appContext = useAppContext();

    const [options, setOptions] = useState<SelectOption[]|null>(null);

    useEffect(() => {
        if (options === null) {
            zPointCountries(appContext.api)
                .then((response) => {
                    setOptions(response.items.map((country) => ({
                       value: country.countryCode,
                       label: country.name,
                    })));
                })
                .catch(() => setOptions([]));
        }
    }, [appContext.api, options]);

    if (options === null) {
        return null;
    }

    return <Select
        name={name}
        label={label}
        formId={formId}
        options={options}
        placeholder={placeholder}
        placeholderDisabled={placeholderDisabled}
        registerOptions={registerOptions}
        form={form}
    />
}
