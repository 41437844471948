import classNames from "classnames";
import {FC} from "react";

export type DeliveryPacketsOverlayProps = {
    deliveredPacketsCount: number;
    offset: number|null;
}

export const DeliveryPacketsOverlay: FC<DeliveryPacketsOverlayProps> = ({
    deliveredPacketsCount,
    offset,
}) => {

    if (!offset) {
        return null;
    }

    return <div
        className={classNames('delivery-packets__overlay', {'delivery-packets__overlay_single': deliveredPacketsCount === 1})}
        style={{top: offset}}
    />

}
