import {TFunction} from "i18next";
import {useEffect, useState} from "react";
import {useForm} from "react-hook-form";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {manualDeposit} from "@app/Transaction/api/cashRegisterApi";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

type NewManualTransferModalFormData = {
    amount: string;
}

export type NewManualTransferModalConstraints = {
    min: number;
    max: number;
}

export const useNewManualTransferModal = (balanceAmount: number, onTransfer: () => void, t: TFunction) => {
    const appContext = useAppContext();

    const [modalOpened, setModalOpened] = useState<boolean>(false);
    const [constraints, setConstraints] = useState<NewManualTransferModalConstraints|null>(null);
    const [showButton, setShowButton] = useState<boolean>(false);

    const {cashRegister} = appContext.dashboardInfo;
    const form = useForm<NewManualTransferModalFormData>({
        defaultValues: {amount: ''}
    });

    useEffect(() => {
        if (!constraints) {
            if (!cashRegister) {
                setConstraints({min: 0, max: 0});
                return;
            }

            const keepCashAmount = parseFloat(cashRegister.keepCashAmount);
            if (!balanceAmount || isNaN(keepCashAmount) || !isFinite(keepCashAmount)) {
                setConstraints({min: 0, max: 0});
                return;
            }

            const roundCashBy = parseFloat(cashRegister.roundCashBy);
            const max = (isNaN(roundCashBy) || !isFinite(roundCashBy))
                ? Math.floor(balanceAmount - keepCashAmount)
                : Math.floor((balanceAmount - keepCashAmount) / roundCashBy) * roundCashBy;

            setConstraints({
                min: keepCashAmount,
                max: max > keepCashAmount ? max : keepCashAmount
            });

            form.reset({amount: max.toString()});
        }

    }, [balanceAmount, cashRegister, constraints, form, modalOpened]);

    useEffect(() => {
        if (constraints) {
            setShowButton(
                cashRegister !== null
                && !cashRegister.cashCollectionByUs
                && !cashRegister.cashCollectionToBranch
                && !cashRegister.cashCollectionByCarrier
                && constraints.max > 0
            );
        }

    }, [cashRegister, constraints])

    const showModalErrorToast = useShowErrorToast(modalToast);

    const createTransfer = useBlockingCallback((unblock, {amount}: NewManualTransferModalFormData) => {
        manualDeposit({
            amount,
            date: new Date(),
            note: '',
        }, appContext.api)
            .then(() => {
                setModalOpened(false);
                appToast.success(t('viewNewManualTransfer:success'))
                onTransfer();
            })
            .catch((error: Error) => {
                showModalErrorToast(error);
            })
            .finally(unblock);
    }, [appContext.api, onTransfer, showModalErrorToast, t]);

    return {
        constraints,
        createTransfer,
        form,
        modalOpened,
        setModalOpened,
        showButton,
    }
}
