import {Api} from "@app/AppContext/classes/Api/Api";
import {
    printInventoryBadPacketsEndpoint,
    printInventoryBadPacketsPDFEndpoint,
    printPacketExpeditionsEndpoint,
    printPacketLabelsEndpoint,
    printParcelBadPacketsEndpoint,
    printParcelBadPacketsPDFEndpoint,
    printParcelDeliveryNoteEndpoint,
    printParcelDeliveryNotePDFEndpoint,
    printParcelLabelEndpoint,
    printReturnPacketListEndpoint,
    printShelfBarcodesEndpoint,
    printShelfBarcodesPDFEndpoint,
    reprintEndpoint
} from "@app/Print/api/printEndpoints";
import {
    ParcelPrintRequest,
    PrintInventoryBadPacketsRequest, PrintPacketLabelsRequest,
    PrintReturnPacketListRequest, PrintShelfBarcodesRequest, ReprintRequest
} from "@app/Print/model/PrintRequestsAndResponses";
import {EncodedPdfResponse} from "@common/model/responses/EncodedPdfResponse";
import {ResponseWithPrintables} from "@common/model/responses/ResponseWithPrintables";

export const printInventoryBadPackets = async (request: PrintInventoryBadPacketsRequest, api: Api): Promise<ResponseWithPrintables> => {
    return api.call({endpoint: printInventoryBadPacketsEndpoint, payload: request});
}

export const printInventoryBadPacketsPDF = async (request: PrintInventoryBadPacketsRequest, api: Api): Promise<EncodedPdfResponse> => {
    return api.call({endpoint: printInventoryBadPacketsPDFEndpoint, payload: request});
}

export const printPacketExpeditions = async (api: Api): Promise<ResponseWithPrintables> => {
    return api.call(printPacketExpeditionsEndpoint);
}

export const printParcelBadPackets = async (request: ParcelPrintRequest, api: Api): Promise<ResponseWithPrintables> => {
    return api.call({endpoint: printParcelBadPacketsEndpoint, payload: request});
}

export const printParcelBadPacketsPDF = async (request: ParcelPrintRequest, api: Api): Promise<EncodedPdfResponse> => {
    return api.call({endpoint: printParcelBadPacketsPDFEndpoint, payload: request});
}

export const printParcelDeliveryNote = async (request: ParcelPrintRequest, api: Api): Promise<ResponseWithPrintables> => {
    return api.call({endpoint: printParcelDeliveryNoteEndpoint, payload: request});
}

export const printParcelDeliveryNotePDF = async (request: ParcelPrintRequest, api: Api): Promise<EncodedPdfResponse> => {
    return api.call({endpoint: printParcelDeliveryNotePDFEndpoint, payload: request});
}

export const printParcelLabel = async (api: Api): Promise<ResponseWithPrintables> => {
    return api.call(printParcelLabelEndpoint);
}

export const printReturnPacketList = async (request: PrintReturnPacketListRequest, api: Api): Promise<ResponseWithPrintables> => {
    return api.call({endpoint: printReturnPacketListEndpoint, payload: request});
}

export const printPacketLabels = async (request: PrintPacketLabelsRequest, api: Api): Promise<ResponseWithPrintables> => {
    return api.call({endpoint: printPacketLabelsEndpoint, payload: request});
}

export const printShelfBarcodes = async (request: PrintShelfBarcodesRequest, api: Api): Promise<ResponseWithPrintables> => {
    return api.call({endpoint: printShelfBarcodesEndpoint, payload: request});
}

export const printShelfBarcodesPDF = async (request: PrintShelfBarcodesRequest, api: Api): Promise<EncodedPdfResponse> => {
    return api.call({endpoint: printShelfBarcodesPDFEndpoint, payload: request})
}

export const reprint = async (request: ReprintRequest, api: Api): Promise<ResponseWithPrintables> => {
    return api.call({endpoint: reprintEndpoint, payload: request});
}

