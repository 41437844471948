export enum PacketUndeliveryReason {
    NO_MONEY = 'noMoney',
    PACKET_REFUSED = 'packetRefused',
    DELIVERY_ERROR = 'deliveryError',
    OTHER = 'other',
}

export enum PacketUndeliveryReturnToSender {
    YES = 'yes',
    NO = 'no',
}
