import {ReaderProcessResultAction} from "@app/Devices/model/ReaderProcessResultAction";
import {ReaderVariables} from "@app/Devices/model/ReaderVariables";

export enum ReaderProcessResultType {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
}

export type ReaderProcessResult = {
    type: ReaderProcessResultType;
    code: string|null;
    variables: ReaderVariables|null;
    actions: ReaderProcessResultAction[]|null;
    title?: string|null; // Probably defined on the FE side only...
}
