import {FC} from "react";
import {Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import {Route, Routes} from "react-router-dom";

import {ExceptionActions} from "@app/OpeningHours/components/ExceptionActions/ExceptionActions";
import {PlannedChanges} from "@app/OpeningHours/components/PlannedChanges/PlannedChanges";
import {
    PublicHolidayConfirmBanner
} from "@app/OpeningHours/components/PublicHolidayConfirmBanner/PublicHolidayConfirmBanner";
import {PublicHolidaysOverview} from "@app/OpeningHours/components/PublicHolidaysOverview/PublicHolidaysOverview";
import {RegularOpeningHours} from "@app/OpeningHours/components/RegularOpeningHours/RegularOpeningHours";
import {OpeningHoursContextProvider} from "@app/OpeningHours/context/OpeningHoursContextProvider";
import {OpeningHoursIndexRoute} from "@app/OpeningHours/OpeningHoursRoutes";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";

export const OpeningHours: FC = () => {
    const {t} = useTranslation();

    return <OpeningHoursContextProvider>
        <Routes>
            <Route path={OpeningHoursIndexRoute.path} element={<PageContent className="opening-hours">
                    <PageHeader>{t('openingHours:page.title')}</PageHeader>
                    <ExceptionActions />
                    <PublicHolidayConfirmBanner />
                    <PlannedChanges />
                    <Row>
                        <Col lg={5} md={6}>
                            <RegularOpeningHours />
                            <PublicHolidaysOverview />
                        </Col>
                    </Row>
                </PageContent>}
            />
        </Routes>
    </OpeningHoursContextProvider>
}
