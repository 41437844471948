
import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {CashRegisterBalance} from "@app/Transaction/model/CashRegister/CashRegisterBalance";
import {
    useNewManualTransferModal
} from "@app/Transaction/pages/BranchCashRegisterPage/CashRegisterBalanceBox/NewManualTransferModal/useNewManualTransferModal";
import {Button} from "@common/components/Button/Button";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {moneyToString} from "@common/components/MoneyFormatter/moneyToString";

export type NewManualTransferModalProps = {
    balance: CashRegisterBalance;
    onTransfer: () => void;
}

export const NewManualTransferModal: FC<NewManualTransferModalProps> = observer(({
    balance,
    onTransfer,
}) => {
    const {t} = useTranslation();
    const {
        constraints,
        createTransfer,
        form,
        modalOpened,
        setModalOpened,
        showButton
    } = useNewManualTransferModal(parseInt(balance.amount, 10), onTransfer, t);

    return <div className="new-manual-transfer-button">
        {showButton && <Button onClick={() => setModalOpened(true)}>
            +&nbsp;&nbsp;{t('cashRegister:action.newTransfer')}
        </Button>}
        {modalOpened && <BaseModal
            show={modalOpened}
            onHide={() => setModalOpened(false)}
            header={t('viewNewManualTransfer:title')}
            footer={<Button onClick={form.handleSubmit(createTransfer)}>
                {t('viewNewManualTransfer:action.confirm')}
            </Button>}
            size="lg"
        >
            {constraints && <FormGroup
                name="amount"
                formId='newTransferModalForm'
                form={form}
                label={<span dangerouslySetInnerHTML={{__html: t<string>('viewNewManualTransfer:label', {
                    min: moneyToString({amount: constraints.min.toString(10), currency: balance.currency}) || '0',
                    max: moneyToString({amount: constraints.max.toString(10), currency: balance.currency}) || '0',
                })}} />}
                type="number"
                registerOptions={{
                    min:  {value: constraints.min, message: t('viewNewManualTransfer:error.outOfBounds')},
                    max:  {value: constraints.max, message: t('viewNewManualTransfer:error.outOfBounds')},
                    required: t<string>('viewNewManualTransfer.error.required'),
                }}
            />}
        </BaseModal>}
    </div>
})
