import {useMemo} from "react";

import {isLocalStorageSupported} from "@common/utils/localStorage";

export const useIsBrowserSupported = (): boolean => {
    return useMemo<boolean>(() => {
        return isLocalStorageSupported()
            && typeof window.Promise === 'function'
            && typeof window.Symbol === 'function'
            && typeof window.Proxy === 'function';
    }, []);
}
