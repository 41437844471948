import {ErrorMessage} from "@hookform/error-message";
import {DefaultTFuncReturn} from "i18next";
import {
    DetailedHTMLProps,
    PropsWithChildren,
    ReactElement,
    SelectHTMLAttributes,
    useCallback
} from "react";
import {FieldErrors, FieldValues, Path, RegisterOptions, UseFormReturn, useFormState} from "react-hook-form";

export type SelectOption = {
    value: string;
    label: string;
}

export type SelectProps<FormDataType extends FieldValues> = {
    form: UseFormReturn<FormDataType>;
    options: SelectOption[];
    formId?: string;
    inputId?: string;
    label?: DefaultTFuncReturn|ReactElement|ReactElement[];
    name: Path<FormDataType>;
    placeholder?: SelectOption;
    placeholderDisabled?: boolean;
    onChange?: (event: any) => void;
    registerOptions?: RegisterOptions;
    selectOptions?: DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>;
    showErrors?: boolean;
}

export const Select = <FormDataType extends FieldValues, >({
    form,
    formId,
    inputId,
    label,
    name,
    onChange,
    options,
    placeholder,
    placeholderDisabled = true,
    registerOptions,
    selectOptions,
    showErrors = true,
}: PropsWithChildren<SelectProps<FormDataType>>): ReactElement|null => {
    const {onChange: formOnChange, ...rest} = form.register(name, registerOptions);
    inputId = inputId || (formId ? `${formId}-${name}` : name);

    const formState = useFormState({control: form.control});

    const selectOnChange = useCallback((event: any) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        void formOnChange(event)
            .then(() => {
                if (onChange) {
                    onChange(event);
                }
            });
    }, [formOnChange, onChange]);

    return <div className="form-group form-group--select">
            {label && <div className="form-group-label"><label htmlFor={inputId}>{label}</label></div>}
            <select onChange={selectOnChange} {...rest} id={inputId} className="form-control" {...selectOptions}>
            {placeholder && <option value={placeholder.value} disabled={placeholderDisabled}>{placeholder.label}</option>}
            {options.map((option) => <option key={option.value} value={option.value}>
                {option.label}
            </option>)}
        </select>
        {showErrors && <ErrorMessage name={name} errors={formState.errors as FieldErrors} as={<p className="alert alert-danger" />} />}
    </div>
}
