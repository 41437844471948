import classNames from "classnames";
import {FC, useCallback, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {updateBranchAttributes} from "@app/Branch/api/branchApi";
import {Directions} from "@app/Branch/model/Attribute/Directions";
import {DirectionEditor} from "@app/Branch/pages/BranchSettingsPage/DirectionsSettings/DirectionEditor/DirectionEditor";
import {Alert} from "@common/components/Alert/Alert";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {Loading} from "@common/components/Loading/Loading";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export type DirectionsSettingsProps = {
    directions: Directions;
    updatePending?: true;
    onUpdate?: () => void;
}

const GENERAL_MIN_LENGTH = 10;

export const DirectionsSettings: FC<DirectionsSettingsProps> = ({
    directions,
    onUpdate,
    updatePending,
}) => {
    const {t} = useTranslation();

    const {api} = useAppContext();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const form = useForm<Directions>({
        defaultValues: {...directions},
        mode: 'onSubmit',
    });

    const showAppErrorToast = useShowErrorToast(appToast);

    const onSubmit = useCallback((data: Directions) => {
        setSubmitting(true);
        updateBranchAttributes({directions: data}, api)
            .then(() => {
                appToast.success(t('viewBranchSettings:attributesForm.message.success'));
                if (onUpdate) {
                    onUpdate();
                }
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }, [api, onUpdate, showAppErrorToast, t]);

    const formId = 'directionsSettingsForm';

    return <Loading active={submitting}
                    className={classNames('branch-settings-page__settings', updatePending && 'branch-settings-page__settings_update-pending')}
                    title={updatePending ? t('viewBranchSettings:updatePending') : undefined}
    >
        {updatePending !== undefined && <Alert type="warning">
            {t('viewBranchSettings:updatePending')}
        </Alert>}
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <DirectionEditor
                form={form}
                name="general"
                defaultValue={directions.general}
                formId={formId}
                disabled={updatePending}
                required={true}
                minLength={GENERAL_MIN_LENGTH}
            />
            <DirectionEditor form={form} name="car" defaultValue={directions.car} formId={formId} disabled={updatePending} />
            <DirectionEditor form={form} name="publicTransport" defaultValue={directions.publicTransport} formId={formId} disabled={updatePending} />
            {!updatePending && <SubmitFormGroup
                name="save"
                value={t('viewBranchSettings:attributesForm.save')}
                formId={formId}
                inputOptions={{
                    disabled: submitting,
                }}
            />}
        </form>
    </Loading>
}
