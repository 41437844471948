import {FC, Fragment, KeyboardEvent, useCallback, useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useGtm} from "@app/App/hooks/useGtm";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {categoriesTreeNodes, searchPosts} from "@app/Manuals/api/manualsApi";
import {ManualsCategories} from "@app/Manuals/components/ManualsCategories/ManualsCategories";
import {ManualCategoryTreeNode} from "@app/Manuals/model/ManualCategoryTreeNode";
import {ManualPost} from "@app/Manuals/model/ManualPost";
import {SearchInput, SearchInputData} from "@common/components/forms/SearchInput/SearchInput";
import {Loading} from "@common/components/Loading/Loading";

export type ManualsIndexProps = {
    onPostSelect: (manualPost: ManualPost) => void;
}

export const ManualsIndex: FC<ManualsIndexProps> = ({onPostSelect}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [foundManualPosts, setFoundManualPosts] = useState<ManualPost[]>([]);
    const [searchFocused, setSearchFocused] = useState<boolean>(false);
    const [categoryTreeNodes, setCategoryTreeNodes] = useState<ManualCategoryTreeNode[]|null>(null);
    const gtm = useGtm();

    const form = useForm<SearchInputData>({
        defaultValues: {query: ''},
        mode: "all",
    })

    useEffect(() => {
        if (categoryTreeNodes === null) {
            categoriesTreeNodes(appContext.api, t)
                .then(setCategoryTreeNodes)
                .catch(() => {
                    setCategoryTreeNodes([]);
                });
        }
    }, [appContext.api, categoryTreeNodes, t]);

    useEffect(() => {
        form.setFocus('query');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSearch = useCallback(({query}: SearchInputData) => {
        if (query.length < 3) {
            setFoundManualPosts([]);
            return;
        }
        searchPosts(query, appContext.api, t)
            .then((response) => {
                setFoundManualPosts(response);
                gtm.tag('manualSearch', {
                    'manualSearch.Action': 'Result',
                    'manualSearch.Timestamp': Date.now(),
                    'manualSearch.SearchText': query,
                    'manualSearch.Results': response.length,
                });
            })
            .catch(() => {
                setFoundManualPosts([]);
            });
    }, [appContext.api, gtm, t]);

    const onSelect = useCallback((manualPost: ManualPost) => {
        gtm.tag('manualSearch', {
            'manualSearch.Action': 'Click',
            'manualSearch.Timestamp': Date.now(),
            'manualSearch.SearchText': form.getValues('query'),
            'manualSearch.URLText': manualPost.title.rendered,
            'manualSearch.URLID': manualPost.id,
        });
        setSearchFocused(false);
        onPostSelect(manualPost);
    }, [form, gtm, onPostSelect]);

    const onFocus = useCallback(() => {
        setSearchFocused(true);
        gtm.tag('manualSearch', {
            'manualSearch.Action': 'Start',
            'manualSearch.Timestamp': Date.now(),
        });

    }, [gtm]);

    const onKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            void form.handleSubmit(onSearch)(event);
        }
    }, [form, onSearch]);

    const formId="searchTabForm";

    return <Fragment>
        <Row className="manuals-index-search">
            <Col md={6}>
                <form id={formId} onChange={form.handleSubmit(onSearch)}>
                    <SearchInput
                        form={form}
                        onSubmit={onSearch}
                        formId={formId}
                        label={t('viewManual:searchPrompt')}
                        inputOptions={{onFocus, onKeyPress, autoComplete: 'off'}}
                    />
                    {searchFocused && foundManualPosts.length > 0 && <ul>
                        {foundManualPosts.map((manualPost) => <li key={manualPost.id}>
                            <a className="search-tab-search-link" onClick={() => onSelect(manualPost)} >{manualPost.title.rendered}</a>
                        </li>)}
                    </ul>}
                </form>
            </Col>
        </Row>
        <Row className="manuals-index-categories">
            <Col md={6}>
                <Loading active={categoryTreeNodes === null}>
                    <div className="manuals-index-categories-list">
                        {categoryTreeNodes !== null && <ManualsCategories
                            categoryTreeNodes={categoryTreeNodes}
                            onSelect={onSelect}
                        />}
                    </div>
                </Loading>
            </Col>
        </Row>
    </Fragment>
}
