import {FC, Fragment, useCallback, useRef} from "react";
import {UseFormRegisterReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Img} from "@common/components/Img/Img";
import {appToast} from "@common/components/Toast/toastOpener";

const FORBIDDEN_ATTACHMENT_SUFFIXES = [
    'ade', 'adp', 'apk', 'appx', 'appxbundle', 'bat', 'cab', 'chm', 'cmd', 'com', 'cpl', 'dll',
    'dmg', 'exe', 'hta', 'ins', 'isp', 'iso', 'jar', 'js', 'jse', 'lib', 'lnk', 'mde', 'msc', 'msi',
    'msix', 'msixbundle', 'msp', 'mst', 'nsh', 'pif', 'ps1', 'scr', 'sct', 'shb', 'sys', 'vb',
    'vbe', 'vbs', 'vxd', 'wsc', 'wsf', 'wsh',
];

export type MessageAttachmentProps = {
    attachment: FileList|null;
    registeredField: UseFormRegisterReturn;
    setAttachment: (value: FileList|null) => void;
}

export const MessageAttachment: FC<MessageAttachmentProps> = ({attachment, registeredField, setAttachment}) => {
    const {t} = useTranslation();
    const inputRef = useRef<HTMLInputElement|null>(null);

    const {ref, ...rest} = registeredField;

    const open = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, []);

    const clear = useCallback(() => {
        setAttachment(null);
    }, [setAttachment]);

    const uploadedFile = attachment && attachment.item && attachment.item(0);

    if (uploadedFile) {
        const suffix = uploadedFile.name.split('.').pop();
        if (suffix && FORBIDDEN_ATTACHMENT_SUFFIXES.includes(suffix)) {
            setAttachment(null);
            appToast.error(t('message:error.invalidAttachmentType'));
        }
    }

     return <div className="message-attachment">
        <Img
            className="message-attachment-icon"
            src="/images/icons/ico/ico-attachment.svg"
            alt={t('message:tabs.content.new.attachment.label')}
            title={t('message:tabs.content.new.attachment.label')}
            onClick={open}
        />
         {uploadedFile && <Fragment>
             <span className="message-attachment-filename">{uploadedFile.name}</span>
             <Img
                 className="message-attachment-icon"
                 src="/images/icons/ico/ico-delete.svg"
                 alt={t('message:tabs.content.new.attachment.clear')}
                 title={t('message:tabs.content.new.attachment.clear')}
                 onClick={clear}
             />
         </Fragment>}
        <input
            ref={(e) => {
                inputRef.current = e;
                ref(e);
            }}
            {...rest}
            type="file"
            name="attachment"
        />
    </div>
}
