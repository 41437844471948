import classNames from "classnames";
import {FC} from "react";

import {Img} from "@common/components/Img/Img";

import './inputFlag.scss';

export type InputFlagProps = {
    message: string;
    type: 'loading'|'error'|'success';
    xid?: string;
}

export const InputFlag: FC<InputFlagProps> = ({message, type, xid}) => {
    return <div className={classNames('input-flag', {[`input-flag_${type}`]: true})} data-xid={xid}>
        {type === 'loading' && <Img
            className="input-flag__icon input-flag__icon_spinner"
            src="/images/icons/ico/ico-verify.svg"
            alt={message}
        />}
        {type === 'error' && <Img className="input-flag__icon" src="/images/icons/ico/ico-exclamation.svg" alt={message} />}
        {type === 'success' && <Img className="input-flag__icon" src="/images/icons/ico/ico-check-light.svg" alt={message} />}
        {' '}{message}
    </div>
}
