import {PaginationApiMethod, PaginationApiMethodReturnType} from "@app/common/model/PaginationApiMethod";
import {Message} from "@app/Messaging/model/Message";
import {MessageTopic} from "@app/Messaging/model/MessageTopic";
import {DateFilterRequestParameter} from "@common/model/requests/DateFilterRequestParameter";
import {PaginatedListRequest} from "@common/model/requests/PaginatedListRequest";
import {Embeddable, RequestWithEmbedded,} from "@common/model/requests/RequestWithEmbedded";
import {SearchModifier, SearchRequestProperty} from "@common/model/requests/SearchRequestProperty";
import {GeneralListResponseContent} from "@common/model/responses/GeneralListResponseContent";
import {ResponseWithEmbedded} from "@common/model/responses/ResponseWithEmbedded";

// Requests

export enum MessageType {
    GENERAL = 0,
    SYSTEM = 1,
    URGENT = 2,
}

export enum MessageProcessMode {
    MESSAGING_ONLY = 0,
    MESSAGING_AND_EMAIL = 1,
}

export enum MessageAccessMode {
    ALL_USERS = 0,
    BRANCH_OWNER_ONLY = 1,
}

export type CreateBaseMessageRequest = {
    subject: string;
    content: string;
    attachmentFilename?: string;
    attachmentContent?: string;
    attachmentMimeType?: string;
}

export type CreateOutgoingMessageRequest = CreateBaseMessageRequest & {
    recipientIds: (string | number)[];
    recipientCountryCodes?: string[];
    repliesToMessageId?: number;
    type: MessageType;
    processMode: MessageProcessMode;
    accessMode: MessageAccessMode;
    publishAt?: Date;
    hideAt?: Date;
}

export type CreateIncomingMessageRequest = CreateBaseMessageRequest & {
    topic: MessageTopic;
}

export type CreateMessageRequest = CreateOutgoingMessageRequest | CreateIncomingMessageRequest;

export type MessageDetailRequest = RequestWithEmbedded<Embeddable.USER | Embeddable.BRANCH>;

export type MessagesListRequest = PaginatedListRequest & RequestWithEmbedded<Embeddable.USER | Embeddable.BRANCH>;

export type ReceivedMessagesListRequest = MessagesListRequest & {
    unreadOnly?: SearchRequestProperty<boolean, SearchModifier.EQ>;
    createdAt?: DateFilterRequestParameter;
}

export type ReadRequest = {
    messageId: number;
}

// Responses

export type MessagesListResponse<ExtendedResponseType = ResponseWithEmbedded>
    = PaginationApiMethodReturnType<Message, ExtendedResponseType>;

export type MessageDetailResponse = GeneralListResponseContent<Message> & ResponseWithEmbedded;

export type ReceivedMessagesExtendedResponse = ResponseWithEmbedded & {
    unreadCount: number|null;
}

export type MessagesPaginationApiMethod<ExtendedResponseType extends ResponseWithEmbedded = ResponseWithEmbedded>
    = PaginationApiMethod<Message, MessagesListRequest, ExtendedResponseType>;
