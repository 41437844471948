import {Api} from "@app/AppContext/classes/Api/Api";
import {
    branchPacketCountStatsEndpoint,
    branchQualityDetailEndpoint,
    branchQualityEndpoint,
    confirmPciDssAuditEndpoint,
    confirmPacketCapacityEndpoint,
    detailEndpoint,
    eventListEndpoint,
    getBranchAttributesEndpoint,
    managedBranchListEndpoint,
    updateBranchAttributesEndpoint,
    zPointCountriesEndpoint,
    zPointSearchEndpoint
} from "@app/Branch/api/branchEndpoints";
import {
    BranchDetailResponse,
    BranchEventListResponse,
    BranchPacketCountStatsRequest,
    BranchPacketCountStatsResponse,
    BranchQualityDetailRequest,
    BranchQualityDetailResponse,
    BranchQualityResponse,
    ConfirmPacketCapacityRequest,
    ConfirmPciDssAuditRequest,
    GetBranchAttributesResponse,
    ManagedBranchListResponse,
    UpdateBranchAttributesRequest,
    ZPointCountryResponse,
    ZPointSearchResponse
} from "@app/Branch/model/BranchRequestsAndResponses";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const branchPacketCountStats = async (request: BranchPacketCountStatsRequest, api: Api): Promise<BranchPacketCountStatsResponse> => {
    const {branchId, ...rest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(branchPacketCountStatsEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(rest),
    });
}

export const branchQuality = async (api: Api): Promise<BranchQualityResponse> => {
    return api.call(branchQualityEndpoint);
}

export const branchQualityDetail = async (request: BranchQualityDetailRequest, api: Api): Promise<BranchQualityDetailResponse> => {
    return api.call({
        endpoint: branchQualityDetailEndpoint,
        queryParams: createQueryParamsFromRequest(request),
    });
}

export const confirmPciDssAudit = async (request: ConfirmPciDssAuditRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: confirmPciDssAuditEndpoint,
        payload: request,
    });
}

export const confirmPacketCapacity = async (request: ConfirmPacketCapacityRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: confirmPacketCapacityEndpoint,
        payload: request
    })
}

export const detail = async (branchId: string, api: Api): Promise<BranchDetailResponse> => {
    return api.call(endpointWithRouteParams(detailEndpoint, {branchId}));
}

export const eventList = async (api: Api): Promise<BranchEventListResponse> => {
    return api.call(eventListEndpoint);
}

export const getBranchAttributes = async (api: Api): Promise<GetBranchAttributesResponse> => {
    return api.call(getBranchAttributesEndpoint);
}

export const managedBranchList = async (api: Api): Promise<ManagedBranchListResponse> => {
    return api.call(managedBranchListEndpoint);
}

export const updateBranchAttributes = async (request: UpdateBranchAttributesRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: updateBranchAttributesEndpoint,
        payload: request,
    });
}

export const zPointSearch = async (searchStr: string, abortController: AbortController, api: Api): Promise<ZPointSearchResponse> => {
    return api.call({
        endpoint: endpointWithRouteParams(zPointSearchEndpoint, {searchStr}),
        abortController,
    });
}
export const zPointCountries = async (api: Api): Promise<ZPointCountryResponse> => {
    return api.call(zPointCountriesEndpoint);
}
