import {formatRFC3339} from "date-fns";
import {i18n as I18next} from "i18next";

import {ApiBackend} from "@app/AppContext/classes/Api/model/ApiBackend";
import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {User} from "@app/AppContext/classes/User/User";
import {CLIENT_NAME, Config, HEADER_CLIENT} from "@app/config";
import {EndpointError} from "@common/model/errors/EndpointError";
import {QueryParam} from "@common/model/requests/QueryParam";
import {createURL} from "@common/utils/createURL";

export type FetchApiParameters<
    PayloadType = Record<string, unknown>,
    RouteParamNames extends string = string,
    ErrorCodes extends string = string
> = {
    endpoint: Endpoint<RouteParamNames, ErrorCodes>,
    queryParams?: QueryParam[],
    payload?: PayloadType;
    abortController?: AbortController;
    customHeaders?: HeadersInit;
}

export class FetchApi {
    public constructor(private user: User, private config: Config, private i18n: I18next) {}

    public async fetch<PayloadType, RouteParamNames extends string = string, ErrorCodes extends string = string>({
        abortController,
        customHeaders,
        endpoint,
        payload,
        queryParams,
    }: FetchApiParameters<PayloadType, RouteParamNames, ErrorCodes>): Promise<Response> {
        const headers = new Headers(customHeaders || {
            'Accept-Language': this.i18n.language,
            'Content-Type': 'application/json',
        });

        if (endpoint.backend === ApiBackend.AZAPI) {
            headers.append(HEADER_CLIENT, CLIENT_NAME);
        }

        if (endpoint.authenticate !== false) {
            if (!this.user.checked || !this.user.token) {
                throw new EndpointError('Trying to access an authenticated endpoint without a valid token!', endpoint);
            }

            headers.append('Authorization', `${this.user.token.tokenType} ${this.user.token.accessToken}`);
        }

        const fetchInit: RequestInit = {
            method: endpoint.method,
            headers,
            body: payload ? JSON.stringify(payload, (_key: string, value: any): any => {
                if (value instanceof Date) {
                    return formatRFC3339(value);
                }
                // eslint-disable-next-line @typescript-eslint/no-unsafe-return
                return value;
            }) : null,
            signal: abortController ? abortController.signal : undefined,
        };

        const url = new URL(`${this.baseUriForEndpoint(endpoint)}${endpoint.url}`);
        if (queryParams) {
            queryParams.forEach((queryParam) => {
                url.searchParams.append(queryParam.name, queryParam.value);
            });
        }

        return fetch(url.toString(), fetchInit);
    }

    private baseUriForEndpoint(endpoint: Endpoint): string {
        // AZAPI is the overall AVM default backend
        const backend = endpoint.backend || ApiBackend.AZAPI;

        switch (backend) {
            case ApiBackend.AZAPI:
                return this.config.azApiBaseUri;
            case ApiBackend.ADMIN_VM_BACKEND:
                return createURL(
                    'http',
                    this.config.adminVmBackendHost,
                    this.config.adminVmBackendPort,
                    this.config.adminVmBackendNonSecure
                ).toString().replace(/\/$/, '');
            case ApiBackend.PDC_UPDATE_SERVER:
                return this.config.pdcUpdateServerUri;
            case ApiBackend.PDC:
                return createURL(
                    'http',
                    this.config.pdcHost,
                    this.config.pdcPort,
                    this.config.pdcNonSecure
                ).toString().replace(/\/$/, '');
        }
    }
}
