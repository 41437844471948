import {startOfQuarter, sub} from "date-fns";
import {Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {useBranchEventTranslationKey} from "@app/BranchEvent/hooks/useBranchEventTranslationKey";
import {CashRegisterBalanceConfirmationRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {ModalBranchEventComponent} from "@app/BranchEvent/model/ModalBranchEventComponent";
import {cashRegisterBalance, confirmBalance} from "@app/Transaction/api/cashRegisterApi";
import {CashRegisterBalance} from "@app/Transaction/model/CashRegister/CashRegisterBalance";
import {Button} from "@common/components/Button/Button";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {SearchModifier} from "@common/model/requests/SearchRequestProperty";

export const CashRegisterBalanceConfirmationRequiredBranchEventModal: ModalBranchEventComponent<CashRegisterBalanceConfirmationRequiredBranchEvent> = ({
    event,
    onClose,
}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const dateFormatter = useDateFormatter();

    const translationKey = useBranchEventTranslationKey(event);

    const [expectedBalance, setExpectedBalance] = useState<CashRegisterBalance|null>(null);
    const [currentBalance, setCurrentBalance] = useState<CashRegisterBalance|null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const showModalErrorToast = useShowErrorToast(modalToast);

    const confirmationDate = sub(startOfQuarter(new Date()), {seconds: 1});

    useEffect(() => {
        if (appContext.user.branchId && confirmationDate && !expectedBalance) {
            cashRegisterBalance({
                branchId: appContext.user.branchId,
                createdAt: [{value: confirmationDate, modifier: SearchModifier.LTE}]
            }, appContext.api)
                .then(({balance}) => {
                    setExpectedBalance(balance);
                })
                .catch((error: Error) => {
                    showModalErrorToast(error);
                });
        }
    }, [confirmationDate, expectedBalance, appContext.user.branchId, appContext.api, showModalErrorToast]);

    useEffect(() => {
        if (appContext.user.branchId && !currentBalance) {
            cashRegisterBalance({branchId: appContext.user.branchId}, appContext.api)
                .then(({balance}) => {
                    setCurrentBalance(balance);
                })
                .catch((error: Error) => {
                    showModalErrorToast(error);
                });
        }
    }, [currentBalance, appContext.user.branchId, appContext.api, showModalErrorToast]);

    const onConfirmBalance = useBlockingCallback((unblock) => {
        if (expectedBalance) {
            setIsSubmitting(true);
            confirmBalance({balance: expectedBalance.amount, date: confirmationDate}, appContext.api)
                .then((response) => {
                    if (!response.success) {
                        modalToast.error(t('viewCashRegisterBalanceConfirm:error.balanceConfirm'));
                    } else {
                        onClose();
                    }
                })
                .catch(() => {
                    modalToast.error(t('viewCashRegisterBalanceConfirm:error.balanceConfirm'));
                })
                .finally(() => {
                    setIsSubmitting(false);
                    unblock();
                });
        }

    }, [appContext.api, confirmationDate, expectedBalance, onClose, t]);

    const hasBalances = expectedBalance && currentBalance;

    return <BaseModal
        show={true}
        onHide={onClose}
        header={t(`${translationKey}Title`)}
        footer={<Fragment>
            <Button variant="danger" onClick={onClose}>{t('viewCashRegisterBalanceConfirm:action.postpone')}</Button>
            <Button variant="success" onClick={onConfirmBalance}>{t('viewCashRegisterBalanceConfirm:action.confirm')}</Button>
        </Fragment>}
        loading={isSubmitting || !hasBalances}
        size="lg"
    >
        <p>
            {t('viewCashRegisterBalanceConfirm:weAskYouTo')}{' '}
            <strong>{t('viewCashRegisterBalanceConfirm:cashRegisterBalanceConfirmation')}</strong>{' '}
            {t('viewCashRegisterBalanceConfirm:byTheEndOfTheDay')}{' '}
            <strong><DateFormatter date={confirmationDate} /></strong>
        </p>
        <p>
            {t('viewCashRegisterBalanceConfirm:cashRegisterBalanceShouldHaveBeen')}{' '}
            <strong>{expectedBalance && <MoneyFormatter
                amount={expectedBalance.amount}
                currencyCode={expectedBalance.currency}
            />}</strong>{' '}
            ({t('viewCashRegisterBalanceConfirm:cashRegisterBalanceDetail', {payments: t('global:menu.payments'), cashRegister: t('global:menu.cashRegister'), dateTime: dateFormatter(confirmationDate, DateFormatType.TIME_FORMAT)})}).
        </p>
        <p>
            {t('viewCashRegisterBalanceConfirm:cashRegisterBalanceIsNow')}{' '}
            <strong>{currentBalance && <MoneyFormatter
                amount={currentBalance.amount}
                currencyCode={currentBalance.currency}
            />}</strong>. {t('viewCashRegisterBalanceConfirm:cashRegisterBalanceConfirmWhen')} &ldquo;<strong>{t('viewCashRegisterBalanceConfirm:action.confirm')}</strong>&rdquo;.
        </p>
        <p>
            {t('viewCashRegisterBalanceConfirm:postponeConfirmationIfIncorrect')}{' '}
            <span className="red">{t('viewCashRegisterBalanceConfirm:andProceed')}</span>
        </p>
        <ol>
            <li>{t('viewCashRegisterBalanceConfirm:takeAnInventory')}</li>
            <li>
                {t('viewCashRegisterBalanceConfirm:otherwiseContactUs')}{' '}
                <a href={'mailto:' + t('viewCashRegisterBalanceConfirm:contactEmailAddress')}
                   title={t('viewCashRegisterBalanceConfirm:action.writeAnEmail')}>
                    {t('viewCashRegisterBalanceConfirm:contactEmailAddress')}
                </a>
            </li>
        </ol>
    </BaseModal>
}
