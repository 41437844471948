import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {PrintComponent} from "@app/Packet/components/PacketActions/components/PacketPrints/PrintComponent";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {printPacketLabels} from "@app/Print/api/printApi";
import {PrintableTypes} from "@app/Print/model/PrintRequestsAndResponses";
import {Button} from "@common/components/Button/Button";
import {usePrint} from "@common/hooks/usePrint";

export const PrintCodReceipt: PrintComponent = ({packet, setIsSubmitting}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const handlePrint = usePrint(true);

    const onSubmit = useCallback(() => {
        setIsSubmitting(true);
        void handlePrint(printPacketLabels({barcodes: [packet.barcode], type: PrintableTypes.COD_RECEIPT}, appContext.api))
            .finally(() => {
                setIsSubmitting(false);
            })
    }, [setIsSubmitting, handlePrint, packet.barcode, appContext.api]);

    if (packet.status.id !== PacketStatusId.DELIVERED || !packet.hasCod) {
        return null;
    }

    return <Button onClick={onSubmit} variant="secondary">
        {t('viewPacketPrint:action.printCodReceipt')}
    </Button>
}
