
import {FC} from "react";

import {PacketDetailRoute} from "@app/Packet/PacketRoutes";
import {Button, ButtonProps} from "@common/components/Button/Button";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {isBarcode} from "@common/utils/isBarcode";

export type PacketDetailButtonProps = {
    barcode: string;
}

export const PacketDetailButton: FC<PacketDetailButtonProps & ButtonProps> = ({
    barcode,
    children,
    className,
    size,
    variant
}) => {

    if (!isBarcode(barcode)) {
        return <Button variant={variant} size={size} className={className} disabled>{children}</Button>
    }

    return <AppLink to={{route: PacketDetailRoute, params: {barcode}}} variant={variant} size={size} className={className}>
        {children}
    </AppLink>
}
