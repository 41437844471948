import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {branchQuality} from "@app/Branch/api/branchApi";
import {BranchQualityResponse} from "@app/Branch/model/BranchRequestsAndResponses";
import {CustomerFeedback} from "@app/Branch/pages/BranchQualityPage/CustomerFeedback/CustomerFeedback";
import {ProcessPenalty} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenalty";
import {useManualPostTo} from "@app/Manuals/hooks/useManualPostTo";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './branchQualityPage.scss';

export const BranchQualityPage: FC = () => {
    const {t} = useTranslation();
    const {api} = useAppContext();

    const [branchQualityResponse, setBranchQualityResponse] = useState<BranchQualityResponse|null|false>(null);
    const showAppErrorToast = useShowErrorToast(appToast);

    const manualPostTo = useManualPostTo();
    const to = manualPostTo("support:branchQualityPage");

    useEffect(() => {
        if (branchQualityResponse === null) {
            branchQuality(api)
                .then((response) => {
                    setBranchQualityResponse(response);
                })
                .catch((error: Error) => {
                    showAppErrorToast(error);
                    setBranchQualityResponse(false);
                });
        }
    }, [api, branchQualityResponse, showAppErrorToast]);

    return <PageContent className="branch-quality-page">
        <PageHeader>{t('viewBranchQuality:header')}</PageHeader>
        {to && <div className="branch-quality-page__manual">
            <AppLink to={to} variant={'secondary'}>{t('viewBranchQuality:manual.show')}</AppLink>
        </div>}
        <Can I={Action.SHOW} a={Subject.BRANCH_QUALITY_PROCESS_PENALTY}>
            {branchQualityResponse && branchQualityResponse.processPenalty
                && <ProcessPenalty processPenalty={branchQualityResponse.processPenalty} />
            }
        </Can>
        <Can I={Action.SHOW} a={Subject.BRANCH_QUALITY_CUSTOMER_FEEDBACK}>
            {branchQualityResponse && branchQualityResponse.customerFeedback
                && <CustomerFeedback customerFeedback={branchQualityResponse.customerFeedback} />
            }
        </Can>
    </PageContent>
}
