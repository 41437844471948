import {Fragment, ReactElement, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    NoAnswerModal
} from "@app/BranchEvent/components/PacketsPresenceReviewRequiredBranchEvent/NoAnswerModal/NoAnswerModal";
import {PacketsPresenceReviewRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {ModalBranchEventComponent} from "@app/BranchEvent/model/ModalBranchEventComponent";
import {confirmPresence} from "@app/Packet/api/packetApi";
import {presenceReviewPackets} from "@app/Packet/api/packetListApi";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {PacketWithPresenceMeta} from "@app/Packet/model/PacketWithPresenceMeta";
import {Button} from "@common/components/Button/Button";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {modalToast} from "@common/components/Toast/toastOpener";
import {packetWholeName} from "@common/utils/packetWholeName";

import './packetsPresenceReviewRequiredBranchEvent.scss';

export const PacketsPresenceReviewRequiredBranchEventModal: ModalBranchEventComponent<PacketsPresenceReviewRequiredBranchEvent> = ({
    onClose,
}) => {
    const {t} = useTranslation();
    const {api, branchEvents, user} = useAppContext();

    const [packets, setPackets] = useState<PacketWithPresenceMeta[]|null>(null);
    const [noAnswerModalPacket, setNoAnswerModalPacket] = useState<PacketWithPresenceMeta|null>(null);
    const [noAnswerPacketIds, setNoAnswerPacketsIds] = useState<string[]>([]);

    const loadPackets = useCallback(() => {
        if (!user.branchId) {
            modalToast.error(t('viewPacketPresenceReview:message.error'));
            return;
        }
        presenceReviewPackets(user.branchId, api)
            .then((response) => {
                setPackets(response.items);
            })
            .catch(() => {
                modalToast.error(t('viewPacketPresenceReview:message.error'));
                setPackets([]);
            });
    }, [api, user.branchId, t]);

    const onYes = useCallback((packet: PacketWithPresenceMeta): void => {
        confirmPresence({barcode: packet.barcode}, api)
            .then((response) => {
                if (response.success) {
                    modalToast.success(t('viewPacketPresenceReview:message.success'));
                } else {
                    modalToast.error(t('viewPacketPresenceReview:message.error'));
                }
            })
            .catch(() => {
                modalToast.error(t('viewPacketPresenceReview:message.error'));
            })
            .finally(() => {
                loadPackets();
            });
    }, [api, loadPackets, t]);

    const onNo = useCallback((packet: PacketWithPresenceMeta): void => {
        if (!noAnswerModalPacket) {
            setNoAnswerModalPacket(packet);
        }
        if (!noAnswerPacketIds.includes(packet.barcode)) {
            noAnswerPacketIds.push(packet.barcode);
            setNoAnswerPacketsIds(noAnswerPacketIds);
        }
    }, [noAnswerModalPacket, noAnswerPacketIds]);

    const onHide = useCallback(() => {
        branchEvents.setReloadRequired(true);
        onClose();
    }, [branchEvents, onClose]);

    useEffect(() => {
        if (packets === null) {
            loadPackets();
        }
    }, [loadPackets, packets]);

    const renderActions = (packet: PacketWithPresenceMeta): ReactElement => {
        if (noAnswerPacketIds.includes(packet.barcode)) {
            return <Button variant="secondary" onClick={() => onNo(packet)}>{t('viewPacketPresenceReview:action.noAgain')}</Button>
        } else {
            return <Fragment>
                <Button variant="success" onClick={() => onYes(packet)}>{t('viewPacketPresenceReview:action.yes')}</Button>{' '}
                <Button variant="danger" onClick={() => onNo(packet)}>{t('viewPacketPresenceReview:action.no')}</Button>
            </Fragment>
        }
    }

    return <Fragment>
        <BaseModal
            show={true}
            onHide={onHide}
            header={t('viewPacketPresenceReview:popup.title')}
            loading={packets === null}
            footer={<Button variant="secondary" onClick={onHide}>{t('viewPacketToDispatchPopup:action.cancel')}</Button>}
        >
            {packets && <Fragment>
                <p>{t('viewPacketPresenceReview:popup.text')}</p>
                <GridTable>
                    <thead>
                        <tr>
                            <th>
                                {t('packet:property.barcode')}
                                <div className="packets-presence-review-modal__barcode-number">
                                    {' / '}{t('packet:property.number')}
                                </div>
                            </th>
                            <th className="packets-presence-review-modal__number">{t('packet:property.number')}</th>
                            <th>{t('packet:property.recipient')}</th>
                            <th>{t('packet:property.shelf.label')}</th>
                            <th className="packets-presence-review-modal__delivery-note">
                                {t('viewPacketPresenceReview:table.billOfDelivery')}
                            </th>
                            <th>{t('viewPacketPresenceReview:table.action')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {packets.map((packet) => <tr key={packet.barcode}>
                            <td>
                                <FormattedPacketBarcode barcode={packet.barcode}/>
                                <div className="packets-presence-review-modal__barcode-number">
                                    {packet.number}
                                </div>
                            </td>
                            <td className="packets-presence-review-modal__number">{packet.number}</td>
                            <td>
                                {packetWholeName(packet)}
                            </td>
                            <td>{packet.shelf}</td>
                            <td className="packets-presence-review-modal__delivery-note">
                                {packet.presenceMeta.deliveryNote}
                            </td>
                            <td className="packets-presence-review-modal__actions">{renderActions(packet)}</td>
                        </tr>)}
                    </tbody>
                </GridTable>
            </Fragment>}
        </BaseModal>
        {noAnswerModalPacket && <NoAnswerModal
            packet={noAnswerModalPacket}
            onHide={() => setNoAnswerModalPacket(null)}
        />}
    </Fragment>
}
