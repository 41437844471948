import {FC} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {useTranslation} from "react-i18next";

export const OpeningHoursHelpLink: FC = () => {
    const {t} = useTranslation();

    return <div className="editable-opening-hours-grid-help">
        <OverlayTrigger placement="left" overlay={
            <Tooltip id="editable-opening-hours-grid-help-tooltip">
                <ul>
                    <li>{t('openingHours:grid.help.noBreakLine')}</li>
                    <li>{t('openingHours:grid.help.oneBreakLine')}</li>
                    <li>{t('openingHours:grid.help.closedLine')}</li>
                    <li>{t('openingHours:grid.help.exceptionLimitLine')}</li>
                </ul>
            </Tooltip>
        }>
            <div className="editable-opening-hours-grid-link">
                <span className="editable-opening-hours-grid-help-icon">?</span>
                <a>
                    {t('openingHours:grid.help.link')}
                </a>
            </div>
        </OverlayTrigger>
    </div>
}
