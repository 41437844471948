import {RefObject} from "react";
import {useTranslation} from "react-i18next";

import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {PacketFlags} from "@app/Packet/components/PacketDelivery/components/PacketFlags/PacketFlags";
import {
    PacketPaymentInfo
} from "@app/Packet/components/PacketDelivery/components/PacketPaymentInfo/PacketPaymentInfo";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {FCWithChildren} from "@common/model/FCWithChildren";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";
import {packetWholeName} from "@common/utils/packetWholeName";

import './singlePacketInfo.scss';

export type SinglePacketInfoProps = {
    allowNotDeliveredPayment?: boolean;
    packet: DeliveryPacket;
    divRef?: RefObject<HTMLDivElement>;
    isDelivering: boolean;
}

export const SinglePacketInfo: FCWithChildren<SinglePacketInfoProps> = ({
    allowNotDeliveredPayment = false,
    children,
    divRef,
    isDelivering,
    packet
}) => {
    const {t} = useTranslation();

    return <div className="single-packet-info" ref={divRef} data-xid={`single-packet-${packet.info.barcode}`}>
        <div>
            <div className="single-packet-info-row">
                <div>
                    <div className="single-packet-info-label">{t('packet:packet')}</div>
                    <div className="single-packet-info-value single-packet-info-value--barcode">
                        <FormattedPacketBarcode barcode={packet.info.barcode} withHighlight={true} withoutLink={true} />
                    </div>
                </div>
                <div>
                    <div className="single-packet-info-label">{t('packet:property.shelf.label')}</div>
                    <div className="single-packet-info-value single-packet-info-value--shelf">
                        {dashWhenEmpty(packet.info.shelf)}
                        {isDelivering && <PacketFlags packet={packet} />}
                    </div>
                </div>
            </div>
            <div className="single-packet-info-row single-packet-info-row--desktop">
                <div>
                    <div className="single-packet-info-label">{t('packet:property.consignee')}</div>
                    <div className="single-packet-info-value">
                        {packetWholeName(packet.info)}
                    </div>
                </div>
                <div>
                    <div className="single-packet-info-label">{t('packet:property.sender')}</div>
                    <div className="single-packet-info-value single-packet-info-value--sender">
                        {packet.sender ? packet.sender.name : '-'}
                    </div>
                </div>
            </div>
            <div className="single-packet-info-row--mobile">
                <div>
                    <div className="single-packet-info-label">
                        {t('packet:property.consignee')}{' / '}{t('packet:property.sender')}
                    </div>
                    <div className="single-packet-info-value">
                        {packetWholeName(packet.info)}
                    </div>
                    <div className="single-packet-info-value single-packet-info-value--sender">
                        {packet.sender ? packet.sender.name : '-'}
                    </div>
                </div>
            </div>
        </div>
        <PacketPaymentInfo
            packet={packet}
            isDelivering={isDelivering}
            showLabel={true}
            allowNotDeliveredPayment={allowNotDeliveredPayment}
        >
            {children}
        </PacketPaymentInfo>
    </div>
}
