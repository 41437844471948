import {FC} from "react";

import {
    DeliveryAlert,
    DeliveryAlertType
} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/DeliveryAlert/DeliveryAlert";
import {
    hasPacketThisAlert
} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/hasPacketThisAlert";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";

import './deliveryAlerts.scss';

export type DeliveryAlertProps = {
    packets: DeliveryPacketsCollection;
}

export const DeliveryAlerts: FC<DeliveryAlertProps> = ({packets}) => {
    let showNoUnpackedReturnAlert = false;
    let showAdultContentAlert = false;
    let showCardPaymentFailedAlert = false;

    packets.deliveryReady.forEach((packet) => {
        if (!showNoUnpackedReturnAlert && hasPacketThisAlert(packet, DeliveryAlertType.NO_UNPACKED_RETURN)) {
            showNoUnpackedReturnAlert = true;
        }
        if (!showAdultContentAlert && hasPacketThisAlert(packet, DeliveryAlertType.ADULT_CONTENT)) {
            showAdultContentAlert = true;
        }
        if (!showCardPaymentFailedAlert && hasPacketThisAlert(packet, DeliveryAlertType.CARD_PAYMENT_FAILED)) {
            showCardPaymentFailedAlert = true;
        }
    });

    if (!showNoUnpackedReturnAlert && !showAdultContentAlert && !showCardPaymentFailedAlert) {
        return null;
    }

    return <div className="delivery-alerts">
        {showNoUnpackedReturnAlert && <DeliveryAlert type={DeliveryAlertType.NO_UNPACKED_RETURN} />}
        {showAdultContentAlert && <DeliveryAlert type={DeliveryAlertType.ADULT_CONTENT} />}
        {showCardPaymentFailedAlert && <DeliveryAlert type={DeliveryAlertType.CARD_PAYMENT_FAILED} />}
    </div>
}
