import classNames from "classnames";
import {ReactElement} from "react";

import {FCWithChildren} from "@common/model/FCWithChildren";

export type RowHeaderCellProps = {
    colSpan?: number;
    hasError?: boolean;
    header: string|ReactElement|null;
    subHeader?: string|ReactElement|null;
    title?: string;
}

export const RowHeaderCell: FCWithChildren<RowHeaderCellProps> = ({
    children,
    colSpan,
    hasError = false,
    header,
    subHeader,
    title,
}) => {

    return <th
        className={classNames('centered-cell', 'row-header-cell', {'row-header-cell--with-error': hasError})}
        colSpan={colSpan}
        title={title}
    >
        <div className="row-header-cell-content">
            <div className="centered-cell-content">
                <span className="row-header-cell-header">
                    {header}
                </span>
                {subHeader && <span className="row-header-cell-subheader">{subHeader}</span>}
            </div>
            <div className="centered-cell-content">
                {children}
            </div>
        </div>
    </th>
}
