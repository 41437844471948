import {observer} from "mobx-react-lite";
import {FC, useCallback} from "react";
import {Dropdown} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {PacketListFilter} from "@app/Packet/model/PacketListFilter";
import {PacketStatus} from "@app/Packet/model/PacketStatus";
import {Img} from "@common/components/Img/Img";

export type PacketStatusFilterProps = {
    filter: PacketListFilter;
    updateFilter: (newFilter: PacketListFilter) => void;
}

export const PacketStatusFilter: FC<PacketStatusFilterProps> = observer(({filter, updateFilter}) => {
    const {t} = useTranslation();
    const {catalogues } = useAppContext();

    const toggleItem = useCallback((item: PacketStatus) => {
        const newValue = [...(filter.multiStatusId || [])];
        const index = newValue.indexOf(item.id);
        if (index > -1) {
            newValue.splice(index, 1);
        } else {
            newValue.push(item.id);
        }

        if (!newValue.length) {
            const {multiStatusId: _, ...newFilter} = filter;
            updateFilter(newFilter);
        } else {
            updateFilter({...filter, multiStatusId: newValue});
        }
    }, [filter, updateFilter]);

    const buttonText = (): string => {
        if (!filter.multiStatusId || filter.multiStatusId.length === 0) {
            return t('packet:property.status');
        } else if (filter.multiStatusId.length === 1) {
            const selectedStatusId = filter.multiStatusId[0];
            const foundPacketStatus = catalogues && catalogues.packetStatus.find((packetStatus) => packetStatus.id === selectedStatusId);
            if (foundPacketStatus) {
                return foundPacketStatus.name;
            }
        }

        return t('viewDashboard:filter.status.multiple');
    }

    if (!catalogues.packetStatus.length) {
        return null;
    }

    return <Dropdown className="packet-status-filter">
        <DropdownToggle variant={filter.multiStatusId && filter.multiStatusId.length ? 'danger' : 'dark'}>
            {buttonText()}
        </DropdownToggle>
        <DropdownMenu>
            {catalogues.packetStatus.map((packetStatus ) => {
                return <DropdownItem key={packetStatus.id} onClick={() => toggleItem(packetStatus)}>
                    {filter.multiStatusId && filter.multiStatusId.includes(packetStatus.id) && <Img
                        src="/images/icons/ico/ico-check-dark.svg"
                        alt={packetStatus.name}
                    />}{' '}
                    {packetStatus.name}
                </DropdownItem>
            })}
        </DropdownMenu>
    </Dropdown>
})
