import {Api} from "@app/AppContext/classes/Api/Api";
import {
    atBranchPacketList,
    branchPacketList,
    todaysIncomingPacketList,
    toDispatchPacketList
} from "@app/Packet/api/packetListApi";
import {
    FulltextPacketListRequest,
    GeneralBranchPacketListRequest,
    PacketListResponse,
    ToDispatchPacketListRequest,
} from "@app/Packet/model/PacketListRequestsAndResponses";

export enum TypedPacketListRequestType {
    BRANCH = 'branch',
    TODAYS_INCOMING = 'todaysIncoming',
    AT_BRANCH = 'atBranch',
    TO_DISPATCH = 'toDispatch',
}

export type TypedBranchPacketListRequest = GeneralBranchPacketListRequest & {
    type: TypedPacketListRequestType.BRANCH;
}

export type TypedTodaysIncomingPacketListRequest = FulltextPacketListRequest & {
    type: TypedPacketListRequestType.TODAYS_INCOMING;
}

export type TypedAtBranchPacketListRequest = FulltextPacketListRequest & {
    type: TypedPacketListRequestType.AT_BRANCH;
}

export type TypedToDispatchPacketListRequest = ToDispatchPacketListRequest & {
    type: TypedPacketListRequestType.TO_DISPATCH;
}

export type TypedPacketListRequest =
    TypedBranchPacketListRequest | TypedTodaysIncomingPacketListRequest | TypedAtBranchPacketListRequest | TypedToDispatchPacketListRequest;

export const callPacketListApi = async (request: TypedPacketListRequest, api: Api): Promise<PacketListResponse> => {
    // A little bit stupid because of the TypeScript discriminated union destructuring issue
    switch (request.type) {
        case TypedPacketListRequestType.BRANCH: {
            const {type: _, ...packetListRequest} = request;
            return branchPacketList(packetListRequest, api);
        }
        case TypedPacketListRequestType.TODAYS_INCOMING: {
            const {type: _, ...packetListRequest} = request;
            return todaysIncomingPacketList(packetListRequest, api);
        }
        case TypedPacketListRequestType.AT_BRANCH: {
            const {type: _, ...packetListRequest} = request;
            return atBranchPacketList(packetListRequest, api);
        }
        case TypedPacketListRequestType.TO_DISPATCH: {
            const {type: _, ...packetListRequest} = request;
            return toDispatchPacketList(packetListRequest, api);
        }
    }
}
