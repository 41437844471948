export enum PrintResultType {
    DEPOT_LABEL = 'depot-label',
    DEPOT_RETURN_LABEL = 'depot-return-label',
    DELIVERY_RECEIPT = 'delivery-receipt',
    CONSIGNMENT_RECEIPT = 'consignment-receipt',
    COD_RECEIPT = 'cod-receipt',
    SHELF = 'shelf',
    EXPEDITION = 'expedition',
    RETURN = 'return',
    SHELF_BARCODES = 'shelf-barcodes',
    INVENTORY_BAD_PACKETS = 'inventory-bad-packets',
    PARCEL_LABEL = 'parcel-label',
    PARCEL_BAD_PACKETS = 'parcel-bad-packets',
    PARCEL_DELIVERY_BILL = 'parcel-delivery-bill',
    PARCEL_DELIVERY_NOTE = 'parcel-delivery-note',
    TRANSACTION_RECEIPT = 'transaction-receipt',
    CUSTOMER_CARD_TERMINAL_RECEIPT = 'customer-card-terminal-receipt',
    MERCHANT_CARD_TERMINAL_RECEIPT = 'merchant-card-terminal-receipt',
    PACKETS_DELIVERY_BILL = 'packets-delivery-bill',
    MICRO_DEPOT_ROUTE_FINALIZATION_REPORT = 'micro-depot-route-finalization-report',
    UNKNOWN = 'unknown',
}

export enum PrintResultErrorCode {
    REASON_REQUIRED = 'reasonRequired',
}

export type PrintResult = {
    success: boolean;
    type: PrintResultType;
    reprintId: string|null;
    errorCode: PrintResultErrorCode|string|null;
}

export const translationKeyForPrintResult = (printResult: PrintResult): string => {
    switch (printResult.type) {
        case PrintResultType.DEPOT_LABEL: return 'global:print.success.depotLabel';
        case PrintResultType.DELIVERY_RECEIPT: return 'global:print.success.deliveryReceipt';
        case PrintResultType.CONSIGNMENT_RECEIPT: return 'global:print.success.consignmentReceipt';
        case PrintResultType.COD_RECEIPT: return 'global:print.success.codReceipt';
        case PrintResultType.PARCEL_LABEL: return 'global:print.success.parcelLabel';
        case PrintResultType.CUSTOMER_CARD_TERMINAL_RECEIPT: return 'global:print.success.customerCardTerminalReceipt';
        case PrintResultType.MERCHANT_CARD_TERMINAL_RECEIPT: return 'global:print.success.merchantCardTerminalReceipt';
        case PrintResultType.SHELF: return 'global:print.success.shelf';
    }

    return 'global:print.success.general';
}
