export type SimpleReactRoute = {
    readonly path: string;
}

export type RootReactRoute = SimpleReactRoute & {
    readonly path: '';
    readonly index: true;
}

export type ParametricReactRoute<ReactRouteParamNames extends string = string> = {
    readonly path: string;
    readonly paramNames: ReactRouteParamNames[];
}

export type ReactRoute<ReactRouteParamNames extends string = string> = SimpleReactRoute | RootReactRoute | ParametricReactRoute<ReactRouteParamNames>;

export type To<ReactRouteParamNames extends string = string> = SimpleReactRoute | {
    route: ReactRoute<ReactRouteParamNames>;
    params?: Record<ReactRouteParamNames, any>;
    search?: Record<string, any>;
}

export const isSimpleReactRoute = <ReactRouteParamNames extends string = string>(to: To<ReactRouteParamNames>): to is SimpleReactRoute => {
    return 'path' in to;
}

export const isRootReactRoute = <ReactRouteParamNames extends string = string>(to: To<ReactRouteParamNames>): to is RootReactRoute => {
    return isSimpleReactRoute(to) && 'index' in to;
}
