import {FC, Fragment} from "react";

export type TruncateProps = {
    text: string|null;
    length: number;
}

export const Truncate: FC<TruncateProps> = ({length, text}) => {
    if (!text) {
        return null;
    }

    if ([...text].length <= length) {
        return <Fragment>{text}</Fragment>;
    }

    const wholeWords = new RegExp(`^.{1,${length-1}}(?=[\\s\\x00-/:-@\\[-\`{-~])`, 'us');
    const match = wholeWords.exec(text);

    const truncatedText = (match && match[0]) ? match[0] : text.substring(0, length - 1);

    return <span title={text}>{truncatedText}&hellip;</span>
}
