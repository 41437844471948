import {FC, useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {
    BranchInventoryListManager
} from "@app/Inventory/pages/BranchInventoryPage/BranchInventoryListManager";
import {DateRangeOnChange, DateRangePicker} from "@common/components/forms/DateRangePicker/DateRangePicker";
import {NamedDateRange, SelectedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {FormResetLink} from "@common/components/forms/FormResetLink/FormResetLink";
import {useFixFiltersDuration} from "@common/hooks/useFixFiltersDuration";
import {useLocalStorageState} from "@common/hooks/useLocalStorageState";

import './branchInventoryListFilter.scss';

export type BranchInventoryListFilterProps = {
    manager: BranchInventoryListManager;
}

export const BRANCH_INVENTORY_LIST_FILTER_STORAGE_KEY = 'ui-branchInventoryList-filter';

export const BranchInventoryListFilter: FC<BranchInventoryListFilterProps> = ({manager}) => {
    const {t} = useTranslation();

    const namedRanges = useMemo<NamedDateRange[]>(() => [
        {
            name: 'viewInventory:branchInventoryList.dateRange.namedRanges.last2Months',
            duration: {months: 2},
        },
        {
            name: 'viewInventory:branchInventoryList.dateRange.namedRanges.lastHalfYear',
            duration: {months: 6},
        },
        {
            name: 'viewInventory:branchInventoryList.dateRange.namedRanges.lastYear',
            duration: {years: 1},
        },
    ], []);

    const [storedFilter, setStoredFilter] = useLocalStorageState<SelectedDateRange>(
        BRANCH_INVENTORY_LIST_FILTER_STORAGE_KEY,
        namedRanges[0]
    );
    // TODO: remove in release >70.2.0
    const fixFiltersDuration = useFixFiltersDuration();

    useEffect(() => {
        const fixedRange = fixFiltersDuration(storedFilter);
        if (fixedRange) {
            setStoredFilter(fixedRange);
            manager.setRange(fixedRange);
        } else {
            manager.setRange(storedFilter);
        }
    }, [storedFilter, manager, fixFiltersDuration, setStoredFilter]);

    const onChange = useCallback<DateRangeOnChange>((selectedRange) => {
        if (selectedRange) {
            setStoredFilter(selectedRange);
        }
    }, [setStoredFilter]);

    const reset = useCallback(() => {
            setStoredFilter(namedRanges[0]);
    }, [namedRanges, setStoredFilter]);

    return <div className="branch-inventory-list-filter">
        <DateRangePicker
            selectedRange={storedFilter}
            label={t('viewInventory:branchInventoryList.dateRange.label')}
            formId="branchInventoryListFilter"
            namedRanges={namedRanges}
            maxEndDate={new Date()}
            onChange={onChange}
        />
        <FormResetLink onReset={reset} />
    </div>
}
