import {FC, ReactElement} from "react";

import {
    RowHeaderCell
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGridRow/TitleCell/RowHeaderCell";
import {
    ViewCell
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGridRow/ViewCell/ViewCell";
import {OpeningHoursBlock} from "@app/OpeningHours/model/OpeningHoursBlock";

export type OpeningHoursGridRowProps = {
    blocks: OpeningHoursBlock[];
    header: string|ReactElement|null;
    subHeader?: string|ReactElement|null;
}

export const OpeningHoursGridRow: FC<OpeningHoursGridRowProps> = ({
    blocks,
    header,
    subHeader
}) => {
    return <tr className="opening-hours-grid-row">
        <RowHeaderCell header={header} subHeader={subHeader} />
        <ViewCell blocks={blocks} />
    </tr>
}
