import {action, computed, makeObservable, observable} from "mobx";

import {Api} from "@app/AppContext/classes/Api/Api";
import {regularBranchHours} from "@app/OpeningHours/api/branchHoursApi";
import {fillGridHoursBlocks} from "@app/OpeningHours/components/EditableOpeningHoursGrid/branchToGridOpeningHours";
import {GridOpeningHours} from "@app/OpeningHours/components/EditableOpeningHoursGrid/GridOpeningHours";
import {OpeningHoursBlock} from "@app/OpeningHours/model/OpeningHoursBlock";
import {RegularHoursResponse} from "@app/OpeningHours/model/OpeningHoursRequestsAndResponses";
import {RegularOpeningHours} from "@app/OpeningHours/model/RegularOpeningHours";

export class RegularHoursManager {
    public openingHours: RegularOpeningHours[] | null = null;
    public error: Error | null = null;

    constructor(private api: Api) {
        makeObservable(this,{
            error: observable,
            openingHours: observable.shallow,
            setError: action,
            setDataFromResponse: action,
            asGridHours: computed,
        });

        this.fetchData();
    }

    get asGridHours(): GridOpeningHours[] {
        const indexed = new Map<number, OpeningHoursBlock[]>();
        if (this.openingHours) {
            this.openingHours.forEach((openingHour) => {
                indexed.set(openingHour.dayOfWeek, openingHour.hours.map((openingHoursBlock) => ({...openingHoursBlock})));
            });
        }

        return [1,2,3,4,5,6,7,8].map((dayOfWeek): GridOpeningHours => {
            return {
                date: dayOfWeek.toString(),
                hours: fillGridHoursBlocks(indexed.get(dayOfWeek) || []),
                isPublicHoliday: false,
            }
        });
    }

    public setDataFromResponse = (response: RegularHoursResponse|null): void => {
        this.openingHours = response ? response.openingHours.regular : null;
    }

    public setError = (error: Error|null): void => {
        if (JSON.stringify(error) !== JSON.stringify(this.error)) {
            this.error = error;
        }
    }

    protected fetchData = (): void => {
        regularBranchHours(undefined, this.api)
            .then((response) => {
                this.setDataFromResponse(response);
                this.setError(null);
            })
            .catch((error) => {
                this.setDataFromResponse(null);
                this.setError(error as Error);
            });
    }
}
