import {Api} from "@app/AppContext/classes/Api/Api";
import {unauthorizedApiErrorHandler} from "@app/AppContext/classes/Api/apiErrorHandlers/unauthorizedApiErrorHandler";
import {AuthOptions} from "@app/AppContext/classes/User/model/AuthOptions";
import {OauthData} from "@app/AppContext/classes/User/model/OauthData";
import {authenticateAsEndpoint, authenticateEndpoint, refreshEndpoint} from "@app/Sign/api/oauthEndpoints";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";

export const authenticate = async (request: AuthenticateRequest, api: Api): Promise<OauthResponse> => {
    return api.call(
        {endpoint: authenticateEndpoint, payload: request},
        {skipErrorHandler: [unauthorizedApiErrorHandler]}
    );
}

export const authenticateAs = async (request: AuthenticateAsRequest, api: Api): Promise<OauthResponse> => {
    return api.call({endpoint: authenticateAsEndpoint, payload: request});
}

export const refresh = async (request: RefreshTokenRequest, api: Api): Promise<OauthResponse> => {
    return api.call({endpoint: refreshEndpoint, payload: request});
}

export type AuthenticateRequest = {
    email: string;
    password: string;
    options?: AuthOptions;
}

export type OauthResponse = GeneralSuccessResponseContent & OauthData;

export type AuthenticateAsRequest = {
    token: string;
}

export type RefreshTokenRequest = {
    grantType: 'refresh_token';
    refreshToken: string;
}

