import {Api} from "@app/AppContext/classes/Api/Api";
import {abortTransaction, getTransactionId, refundTransaction, revertTransaction} from "@app/CardPayment/api/cardTerminalApi";
import {CancellationStrategyStrategy} from "@app/CardPayment/model/CancellationStrategyStrategy";

export const cancelTransactionWithStrategy = async (api: Api, strategy: CancellationStrategyStrategy, cancelledTransactionId: string): Promise<string> => {
    switch (strategy) {
        case CancellationStrategyStrategy.ABORT:
            await abortTransaction(cancelledTransactionId, api);
            return cancelledTransactionId;
        case CancellationStrategyStrategy.REVERSAL: {
            const {cardTransaction} = await getTransactionId(api);
            await revertTransaction(cancelledTransactionId, {
                id: cardTransaction.id,
            }, api);
            return cardTransaction.id;
        }
        case CancellationStrategyStrategy.REFUND: {
            const {cardTransaction} = await getTransactionId(api);
            await refundTransaction(cancelledTransactionId, {
                id: cardTransaction.id,
            }, api);
            return cardTransaction.id;
        }
    }

}
