import {Fragment, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    PacketCapacityConfirmationRequiredBranchEventModal
} from "@app/BranchEvent/components/PacketCapacityConfirmationRequiredBranchEvent/PacketCapacityConfirmationRequiredBranchEventModal";
import {useBranchEventMessage} from "@app/BranchEvent/hooks/useBranchEventMessage";
import {useBranchEventTranslationKey} from "@app/BranchEvent/hooks/useBranchEventTranslationKey";
import {PacketCapacityConfirmationRequiredEvent} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventComponent} from "@app/BranchEvent/model/BranchEventComponent";
import {Button} from "@common/components/Button/Button";

export const PacketCapacityConfirmationRequiredBranchEventContent: BranchEventComponent<PacketCapacityConfirmationRequiredEvent> = ({event}) => {
    const {t} = useTranslation();
    const {branchEvents} = useAppContext();

    const translationKey = useBranchEventTranslationKey(event);
    const message = useBranchEventMessage(event);

    const [modalOpened, setModalOpened] = useState<boolean>(false);

    const onClose = useCallback(() => {
        setModalOpened(false);
        branchEvents.setReloadRequired(true);
    }, [branchEvents]);

    return <Fragment>
        {message}
        <Button onClick={() => setModalOpened(true)} variant="primary">
            {t(`${translationKey}Button`)}
        </Button>
        {modalOpened && <PacketCapacityConfirmationRequiredBranchEventModal
            event={event}
            onClose={onClose}
        />}
    </Fragment>
}