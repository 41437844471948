import {SimpleReactRoute} from "@common/model/ReactRoute";

export const ReceivedMessagesRoute: SimpleReactRoute = {
    path: '',
}

export const SentMessagesRoute: SimpleReactRoute = {
    path: 'sent',
}

export const NewMessageRoute: SimpleReactRoute = {
    path: 'new-message',
}

export const MessagingRoutes = [
    ReceivedMessagesRoute,
    SentMessagesRoute,
    NewMessageRoute,
];
