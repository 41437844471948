import classNames from "classnames";
import {FC, Fragment, useCallback} from "react";

import {
    PacketPaymentButton
} from "@app/CardPayment/components/PacketPaymentButton/PacketPaymentButton";
import {
    PacketNotificationButton
} from "@app/Packet/components/PacketDelivery/components/PacketNotificationButton/PacketNotificationButton";
import {SinglePacketInfo} from "@app/Packet/components/PacketDelivery/components/SinglePacketInfo/SinglePacketInfo";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";
import {
    DeliveryPacketsOverlay
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/DeliveredPacketsOverlay/DeliveryPacketsOverlay";
import {
    useDeliveryPacketsOverlay
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/DeliveredPacketsOverlay/useDeliveryPacketsOverlay";
import {
    NotDeliveredPacketsHeading
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/NotDeliveredPacketsHeading/NotDeliveredPacketsHeading";
import {PacketsList} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/PacketsList/PacketsList";
import {requiresCashColumn} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/requiresCashColumn";
import {
    UndeliveryButton
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/UndeliveryButton/UndeliveryButton";

import './deliveryPackets.scss';

export type DeliveryPacketsProps = {
    onRunning: (running: boolean) => void;
    packets: DeliveryPacketsCollection;
    running: boolean;
}

export const DeliveryPackets: FC<DeliveryPacketsProps> = ({
    onRunning,
    packets,
    running,
}) => {
    const notDeliveredPackets = packets.isPasswordDelivery ? packets.notDelivered : [];

    const overlay = useDeliveryPacketsOverlay(packets.deliveryReady, notDeliveredPackets);

    const renderUndeliverActionButton = useCallback((packet: DeliveryPacket) => (
        <UndeliveryButton packet={packet} disabled={running} />
    ), [running]);
    const renderNotifyActionButton = useCallback((packet: DeliveryPacket) => (<PacketNotificationButton packet={packet.info} size="sm" inModal={true} />), []);

    const paymentButtonOnRunning = useCallback((isRunning: boolean) => {
        if (!isRunning) {
            overlay.recalculateOffset();
        }
        onRunning(isRunning);
    }, [onRunning, overlay]);

    const renderPaymentButton = useCallback((packet: DeliveryPacket) => (
        <PacketPaymentButton packet={packet} onRunning={paymentButtonOnRunning} running={running} />
    ), [paymentButtonOnRunning, running]);

    if (packets.deliveryReady.length === 0) {
        return null;
    }

    const showCashColumn = requiresCashColumn(packets.deliveryReady, notDeliveredPackets);

    return <Fragment>
        <div className="delivery-packets" ref={overlay.containerRef}>
            {packets.deliveryReady.length === 1 && <SinglePacketInfo
                packet={packets.deliveryReady[0]}
                isDelivering={true}
                divRef={overlay.singlePacketRef}
                allowNotDeliveredPayment={!packets.isPasswordDelivery}
            >
                {renderPaymentButton(packets.deliveryReady[0])}
            </SinglePacketInfo>}
            <DeliveryPacketsOverlay deliveredPacketsCount={packets.deliveryReady.length} offset={overlay.offset} />
            {(packets.deliveryReady.length > 1 || notDeliveredPackets.length > 0) && <div className="delivery-packets__table-container">
                <table className={classNames(
                    'delivery-packets__table',
                    {'delivery-packets__table_delivered-only': packets.deliveryReady.length > 1 && notDeliveredPackets.length === 0})}
                >
                    {packets.deliveryReady.length > 1 && <PacketsList
                        packets={packets.deliveryReady}
                        renderActionButton={renderUndeliverActionButton}
                        renderPaymentButton={renderPaymentButton}
                        showCashColumn={showCashColumn}
                        bodyRef={overlay.deliveredPacketsRef}
                        listType="delivered"
                        className="delivery-packets__packets-list delivery-packets__packets-list_delivered"
                    />}
                    {notDeliveredPackets.length > 0 && <NotDeliveredPacketsHeading
                        followsTable={packets.deliveryReady.length > 1}
                        headRef={overlay.notDeliveredPacketsRef}
                    />}
                    <PacketsList
                        packets={notDeliveredPackets}
                        renderActionButton={renderNotifyActionButton}
                        showCashColumn={showCashColumn}
                        listType="notDelivered"
                        className="delivery-packets__packets-list"
                    />
                </table>
            </div>}
        </div>
    </Fragment>
}
