import classNames from "classnames";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Branch} from "@app/Branch/model/Branch";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {
    useAssertPacketCanBeDelivered
} from "@app/Packet/components/PacketDelivery/hooks/useAssertPacketCanBeDelivered";
import {
    PacketWithDelayedDeliveryError
} from "@app/Packet/components/PacketDelivery/model/PacketWithDelayedDeliveryError";
import {Packet} from "@app/Packet/model/Packet";
import {Sender} from "@app/Packet/model/Sender";
import {PacketStatusBadge} from "@common/components/Badges/PacketStatusBadge/PacketStatusBadge";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {Truncate} from "@common/components/Truncate/Truncate";
import {FCWithChildren} from "@common/model/FCWithChildren";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";
import {packetWholeName} from "@common/utils/packetWholeName";

import './packetGridRow.scss';

const SENDER_NAME_MAX_LENGTH = 50;

export type PacketGridRowProps = {
    className?: string;
    branch?: Branch;
    packet: Packet;
    sender?: Sender;
    showAddress?: boolean;
    showShelf?: boolean;
}

export const PacketGridRow: FCWithChildren<PacketGridRowProps> = ({
    branch,
    children,
    className,
    packet,
    sender,
    showAddress,
    showShelf,
}) => {
    const {t} = useTranslation();
    const [packetHasDelayedDelivery, setPacketHasDelayedDelivery] = useState<boolean>(false);
    const assertPacketCanBeDelivered = useAssertPacketCanBeDelivered();

    useEffect(() => {
        try {
            assertPacketCanBeDelivered(packet);
            setPacketHasDelayedDelivery(false);
        } catch (error: unknown) {
            if (error instanceof PacketWithDelayedDeliveryError) {
                setPacketHasDelayedDelivery(true);
            }
        }
    }, [assertPacketCanBeDelivered, packet]);

    return <tr
        className={classNames(
            'packet-grid-row',
            packetHasDelayedDelivery && 'packet-grid-row_with-delayed-delivery',
            className
        )}
        title={packetHasDelayedDelivery ? t('packet:error.canNotDeliverYet') : undefined}
        data-xid={`packet-grid-row-${packet.barcode}`}
    >
        <td className="packet-grid__barcode">
            <FormattedPacketBarcode barcode={packet.barcode} withHighlight={true} />
            <span className="packet-grid__barcode-status">
                <PacketStatusBadge status={packet.status} />
            </span>
        </td>
        <td className="packet-grid__date-stored">
            {dashWhenEmpty(packet.dateStored && <DateFormatter date={packet.dateStored} />)}
        </td>
        <td className="packet-grid__sender">
            {dashWhenEmpty(sender && <Truncate text={sender.name} length={SENDER_NAME_MAX_LENGTH} />)}
        </td>
        <td className="packet-grid__recipient">
            {packetWholeName(packet)}
            <div className="packet-grid__sender packet-grid__recipient-sender">
                {dashWhenEmpty(sender && <Truncate text={sender.name} length={SENDER_NAME_MAX_LENGTH} />)}
            </div>
        </td>
        {showAddress && <td className="packet-grid__address">{dashWhenEmpty(branch && branch.abbr)}</td>}
        {showShelf && <td>{dashWhenEmpty(packet.shelf)}</td>}
        <td className="packet-grid__status">
            <PacketStatusBadge status={packet.status} />
        </td>
        {children}
    </tr>
}
