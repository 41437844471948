import {FC} from "react";
import {useTranslation} from "react-i18next";

import {TopDaysStats} from "@app/Branch/model/Stats/TopDaysStats";
import {TopDaysDay} from "@app/Branch/pages/BranchStatsPage/TopDays/TopDaysDay/TopDaysDay";

export type TopDaysProps = {
    topDays: TopDaysStats;
}
export const TopDays: FC<TopDaysProps> = ({topDays}) => {
    const {t} = useTranslation();

    if (!topDays.pickupReady && !topDays.delivered) {
        return null
    }

    return <div className="branch-stats-page__top-days">
        <h4>{t('viewBranchStats:topDays.header')}</h4>
        {topDays.pickupReady && <TopDaysDay day={topDays.pickupReady} name="pickupReady" />}
        {topDays.delivered && <TopDaysDay day={topDays.delivered} name="delivered" />}
    </div>
}
