import {sub} from "date-fns";
import {observer} from "mobx-react-lite";
import {FC, FormEvent, useCallback, useEffect, useMemo} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    CommissionTypeSelect
} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionGrid/BranchCommissionGridFilter/CommissionTypeSelect/CommissionTypeSelect";
import {
    BranchCommissionListFilter,
    BranchCommissionListManager
} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionGrid/BranchCommissionListManager";
import {DateRangeOnChange, DateRangePicker} from "@common/components/forms/DateRangePicker/DateRangePicker";
import {isNamedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {
    GeneralDateRangePickerRanges,
    useGeneralDateRangePickerRanges
} from "@common/components/forms/DateRangePicker/useGeneralDateRangePickerRanges";
import {FormResetLink} from "@common/components/forms/FormResetLink/FormResetLink";
import {SearchInput, SearchInputData} from "@common/components/forms/SearchInput/SearchInput";
import {Loading} from "@common/components/Loading/Loading";
import {useFixFiltersDuration} from "@common/hooks/useFixFiltersDuration";
import {useLocalStorageState} from "@common/hooks/useLocalStorageState";

import './branchCommissionGridFilter.scss';

export type BranchCommissionGridFilterProps = {
    manager: BranchCommissionListManager;
}

export const BRANCH_COMMISSION_LIST_FILTER_STORAGE_KEY = 'ui-branchCommissionList-filter';

const COMMISSION_MAX_RANGE: Duration = {months: 24};

export const BranchCommissionGridFilter: FC<BranchCommissionGridFilterProps> = observer(({manager}) => {
    const {t} = useTranslation();
    const namedRanges = useGeneralDateRangePickerRanges();

    const defaultFilter = useMemo(() => ({
        createdAt: namedRanges[GeneralDateRangePickerRanges.LAST_MONTH]
    }), [namedRanges]);

    const [storedFilter, setStoredFilter] = useLocalStorageState<BranchCommissionListFilter>(
        BRANCH_COMMISSION_LIST_FILTER_STORAGE_KEY,
        defaultFilter,
    );

    const updateFilter = useCallback((newFilter: BranchCommissionListFilter) => {
        setStoredFilter({...newFilter, barcode: ''});
        manager.setFilter(newFilter);
    }, [manager, setStoredFilter]);

    const form = useForm<SearchInputData>();

    const onReset = useCallback(() => {
        updateFilter(defaultFilter);
        form.setValue('query', '');
    }, [defaultFilter, form, updateFilter]);

    const onTypeSelect = useCallback((typeId: string|null) => {
        if (typeId === null) {
            const {typeId: _, ...filter} = storedFilter;
            updateFilter(filter);
        } else {
            updateFilter({...storedFilter, typeId});
        }
    }, [storedFilter, updateFilter]);

    const onCreatedAtChange: DateRangeOnChange = useCallback((createdAt) => {
        const newFilter = {...storedFilter, createdAt};
        setStoredFilter(newFilter);

        if (createdAt && (isNamedDateRange(createdAt) || (createdAt.from && createdAt.to))) {
            updateFilter(newFilter);
        }
    }, [setStoredFilter, storedFilter, updateFilter]);

    const onPacketSearchSubmit = useCallback(({query}: SearchInputData) => {
        updateFilter({...storedFilter, barcode: query});
    }, [storedFilter, updateFilter]);

    const onPacketSearchFormSubmit = useCallback((event?: FormEvent) => {
        void form.handleSubmit(onPacketSearchSubmit)(event);

        if (event) {
            event.stopPropagation();
        }
    }, [form, onPacketSearchSubmit]);

    // TODO: remove in release >70.2.0
    const fixFiltersDuration = useFixFiltersDuration();

    useEffect(() => {
        if (!manager.hasFilter) {
            const fixedRange = fixFiltersDuration(storedFilter.createdAt);
            if (fixedRange) {
                updateFilter({
                    ...storedFilter,
                    createdAt: fixedRange,
                });
            } else {
                manager.setFilter(storedFilter);
            }
        }
    }, [fixFiltersDuration, manager, storedFilter, updateFilter]);

    const formId = 'branchCommissionGridFilter';

    return <Loading active={manager.isLoading} className="branch-commission-grid-filter">
        <CommissionTypeSelect onSelect={onTypeSelect} value={storedFilter.typeId} />
        <DateRangePicker
            label={t('viewCommission:branchCommissionGrid.filter.createdAt')}
            selectedRange={storedFilter.createdAt}
            formId={formId}
            namedRanges={namedRanges}
            minStartDate={sub(new Date(), COMMISSION_MAX_RANGE)}
            maxEndDate={new Date()}
            onChange={onCreatedAtChange}
        />
        <form id={formId} onSubmit={onPacketSearchFormSubmit}>
            <SearchInput
                label={t('viewCommission:branchCommissionGrid.filter.packetBarcode')}
                formId={formId}
                form={form}
                onSubmit={onPacketSearchSubmit}
            />
        </form>
        <FormResetLink onReset={onReset} />
    </Loading>
})
