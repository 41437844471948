import {observer} from "mobx-react-lite";
import {FC, useCallback, useContext} from "react";
import {useTranslation} from "react-i18next";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {deleteBranchHoursExceptions, deleteRegularHoursChange} from "@app/OpeningHours/api/branchHoursApi";
import {useOpeningHoursLimits} from "@app/OpeningHours/components/ExceptionActions/useOpeningHoursLimits";
import {useOpeningHours} from "@app/OpeningHours/context/useOpeningHours";
import {PlannedChange} from "@app/OpeningHours/model/PlannedChange";
import {Confirm} from "@common/components/Confirm/Confirm";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export type PlannedChangeDeleteProps = {
    plannedChange: PlannedChange;
}

export const PlannedChangeDelete: FC<PlannedChangeDeleteProps> = observer(({plannedChange}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const ability = useContext(AbilityContext);

    const {branchHours} = useOpeningHours();

    const showErrorToast = useShowErrorToast(appToast, 'openingHours:error', 'errors:4xx.description');

    const callDelete = useCallback(() => {
        switch (plannedChange.type) {
            case 'exception':
                deleteBranchHoursExceptions(plannedChange.date, undefined, appContext.api)
                    .then(() => {
                        branchHours.invalidate();
                        appToast.success(t('openingHours:page.plannedChanges.delete.exception.success'));
                    })
                    .catch((error: Error) => {
                        showErrorToast(error);
                    });
                break;
            case 'closed':
                deleteBranchHoursExceptions(plannedChange.date, plannedChange.endDate, appContext.api)
                    .then(() => {
                        branchHours.invalidate();
                        appToast.success(t('openingHours:page.plannedChanges.delete.closed.success'));
                    })
                    .catch((error: Error) => {
                        showErrorToast(error);
                    });
                break;
            case 'regularUpdate':
                deleteRegularHoursChange(plannedChange.date, appContext.api)
                    .then(() => {
                        branchHours.invalidate();
                        appToast.success(t('openingHours:page.plannedChanges.delete.regularUpdate.success'));
                    })
                    .catch((error: Error) => {
                        showErrorToast(error);
                    });
        }
    }, [branchHours, appContext.api, plannedChange, showErrorToast, t]);

    const {firstSelectableDate} = useOpeningHoursLimits();

    if (!ability.can(Action.MANAGE, Subject.BRANCH_HOURS)) {
        return null;
    }

    if (plannedChange.date < firstSelectableDate) {
        return <td>{t('openingHours:page.plannedChanges.delete.readOnly')}</td>
    }

    return <td>
        <Confirm
            message={t(`openingHours:page.plannedChanges.delete.${plannedChange.type}.message`)}
            header={t(`openingHours:page.plannedChanges.delete.${plannedChange.type}.header`)}
            onSuccess={callDelete}
            variant="link"
            disableOutsideClose={true}
            xid="planned-change-delete"
        >{t('openingHours:page.plannedChanges.delete.button')}</Confirm>
    </td>
})
