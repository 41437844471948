import {ApiErrorItem, ApiErrorItemWithVariables, isApiErrorItemWithVariables} from "@common/model/errors/ApiErrorItem";

export type ProlongPacketApiErrorItemVariables = {
    prolongTo: string;
    actualStorageDate: string|null;
    prolongLimitTill: string|null;
}

export type ProlongPacketApiErrorItem = ApiErrorItemWithVariables<ProlongPacketApiErrorItemVariables>;

export const isProlongPacketApiErrorItem = (item: ApiErrorItem): item is ProlongPacketApiErrorItem => {
    return isApiErrorItemWithVariables<ProlongPacketApiErrorItemVariables>(item)
        && 'prolongTo' in item.variables
        && 'actualStorageDate' in item.variables
        && 'prolongLimitTill' in item.variables;
}
