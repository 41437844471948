import {BackendModule, ReadCallback, Services} from "i18next";

import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

interface AvmI18NextBackendOptions<TranslationNamespaces extends string> {
    release: string;
    namespaces: TranslationNamespaces|TranslationNamespaces[];
}

type Translations<TranslationNamespaces extends string> = Record<TranslationNamespaces, Record<string, string>>;
type TranslationsForRelease<TranslationNamespaces extends string> = {
    translations: Translations<TranslationNamespaces>;
    release: string;
}

export class AvmI18NextBackend<Languages extends string, TranslationNamespaces extends string> implements BackendModule<AvmI18NextBackendOptions<TranslationNamespaces>> {
    public type = 'backend' as const;
    public services?: Services;
    public backendOptions: AvmI18NextBackendOptions<TranslationNamespaces>;

    private translations: Map<Languages, Promise<TranslationsForRelease<TranslationNamespaces>>> =
        new Map<Languages, Promise<TranslationsForRelease<TranslationNamespaces>>>();

    constructor(backendOptions: AvmI18NextBackendOptions<TranslationNamespaces>) {
        this.backendOptions = backendOptions;
    }

    init(services: Services, backendOptions: AvmI18NextBackendOptions<TranslationNamespaces>): void {
        this.services = services;
        this.backendOptions = backendOptions;
    }

    async read(language: Languages, namespace: TranslationNamespaces, callback: ReadCallback): Promise<void> {
        try {
            const translationsPromise = this.translations.get(language) || this.loadTranslations(language);

            if (!this.translations.has(language)) {
                this.translations.set(language, translationsPromise);
            }

            const translations = await translationsPromise;
            callback(null, translations.translations[namespace]);
        } catch (error: unknown) {
            callback(error as Error, null);
            return;
        }
    }

    private async loadTranslations(language: Languages, ): Promise<TranslationsForRelease<TranslationNamespaces>> {
        const response = await fetch(
            `/translations/${language}.json?release=${this.backendOptions.release}`,
            {method: HttpMethod.GET}
        );

        if (response.ok) {
            const fetchedTranslations: TranslationsForRelease<TranslationNamespaces> = {
                translations: await response.json() as Translations<TranslationNamespaces>,
                release: this.backendOptions.release,
            };

            return fetchedTranslations;
        } else {
            throw new Error('Translations fetch response not OK.');
        }
    }
}
