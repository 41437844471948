export enum CommissionBonusItemTrend {
    DECREASING = -1,
    STAGNATING = 0,
    INCREASING = 1,
}

export type CommissionBonusItem = {
    active: boolean;
    value: number|null;
    trend: CommissionBonusItemTrend|null;
}

export type CommissionBonus = {
    process: CommissionBonusItem|null;
    customer: CommissionBonusItem|null;
    seasonal: CommissionBonusItem|null;
}
