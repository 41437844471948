import {FC, Fragment, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {Branch} from "@app/Branch/model/Branch";
import {detail} from "@app/Packet/api/packetApi";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {PacketActions} from "@app/Packet/components/PacketActions/PacketActions";
import {PacketDetailed, packetHasCod} from "@app/Packet/model/PacketDetailed";
import {Sender} from "@app/Packet/model/Sender";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {PacketDetailInfo} from "@app/Packet/pages/PacketDetailPage/PacketDetailInfo/PacketDetailInfo";
import {PacketTracking} from "@app/Packet/pages/PacketDetailPage/PacketTracking/PacketTracking";
import {PacketStatusBadge} from "@common/components/Badges/PacketStatusBadge/PacketStatusBadge";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {HttpStatusCode} from "@common/model/HttpStatusCode";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {extractEmbedded} from "@common/utils/extractEmbedded";

import './packetDetailPage.scss';

export type PacketDetailPageProps = {
    barcode: string;
}

export const PacketDetailPage: FC<PacketDetailPageProps> = ({barcode}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const navigate = useAppNavigate();

    const [packet, setPacket] = useState<PacketDetailed|null|false>(null);
    const branchesMap = useRef<Map<string, Branch>>(new Map<string, Branch>());
    const sendersMap = useRef<Map<string, Sender>>(new Map<string, Sender>());
    const showAppErrorToast = useShowErrorToast(appToast);

    const onPacketActionSuccess = useCallback(() => {
        if (appContext.dashboardGrid) {
            appContext.dashboardGrid.invalidate();
        }
        setPacket(null);
    }, [appContext.dashboardGrid]);

    useEffect(() => {
        if (packet === null) {
            detail({searchStr: barcode, embed: [Embeddable.BRANCH, Embeddable.SENDER]}, appContext.api)
                .then((response) => {
                    if (response) {
                        branchesMap.current = extractEmbedded(response, Embeddable.BRANCH);
                        sendersMap.current = extractEmbedded(response, Embeddable.SENDER);
                        setPacket(response.items[0]);
                    }
                })
                .catch((error: ApiError) => {
                    if (error.code === HttpStatusCode.NOT_FOUND) {
                        appToast.error(t('packet:error.notFoundBarcode', {barcode}));
                    } else {
                        showAppErrorToast(error);
                    }
                    navigate(DashboardRoute);
                });
        }

    }, [appContext.api, barcode, navigate, packet, showAppErrorToast, t]);

    const sender = packet && packet.senderId ? sendersMap.current.get(packet.senderId) : undefined;
    const branch = packet && packet.addressId ? branchesMap.current.get(packet.addressId) : undefined;

    return <PageContent className="packet-detail-page">
        <ReturnLink
            to={DashboardRoute}
            label={t('packet:action.goBackToDashboard')}
        />
        <Loading active={packet === null}>
            {packet && <Fragment>
                <PageHeader subTitle={<PacketStatusBadge status={packet.status} />}>
                    {t('viewPacketDetail:packetCode')}{' '}
                    <FormattedPacketBarcode barcode={barcode} withoutLink={true} />
                </PageHeader>
                <PacketActions
                    packet={{...packet, hasCod: packetHasCod(packet)}}
                    sender={sender}
                    onSuccess={onPacketActionSuccess}
                />
                <PacketDetailInfo packet={packet} branch={branch} sender={sender} />
                <PacketTracking packet={packet} />
            </Fragment>}
        </Loading>
    </PageContent>
}
