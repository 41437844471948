import {observer} from "mobx-react-lite";
import {FC, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {branchPacketCountStats} from "@app/Branch/api/branchApi";
import {BranchStatsRoute, ManagedStatsRoute} from "@app/Branch/BranchRoutes";
import {BranchPacketCountStatsResponse} from "@app/Branch/model/BranchRequestsAndResponses";
import {PacketCount} from "@app/Branch/pages/BranchStatsPage/PacketCount/PacketCount";
import {TopDays} from "@app/Branch/pages/BranchStatsPage/TopDays/TopDays";
import {
    useBranchStatsPageDateRangePickerRanges
} from "@app/Branch/pages/BranchStatsPage/useBranchStatsPageDateRangePickerRanges";
import {BranchIdChecker} from "@common/components/BranchIdChecker/BranchIdChecker";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {DateRangeOnChange, DateRangePicker} from "@common/components/forms/DateRangePicker/DateRangePicker";
import {
    actualDateRange, SelectedDateRange
} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {dateRangeToFilterRequestParameter} from "@common/utils/api/dateRangeToFilterRequestParameter";

import './branchStatsPage.scss';

export type BranchStatsPageProps = {
    branchId: string;
}

export const BranchStatsPage: FC<BranchStatsPageProps> = observer(({branchId}) => {
    const {t} = useTranslation();
    const {api, dashboardInfo} = useAppContext();

    const [selectedRange, setSelectedRange] = useState<SelectedDateRange|undefined>(undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const [stats, setStats] = useState<BranchPacketCountStatsResponse|null>(null);
    const namedRanges = useBranchStatsPageDateRangePickerRanges();

    const showAppErrorToast = useShowErrorToast(appToast);

    const onRangeSelect = useCallback<DateRangeOnChange>((range) => {
        if (!range) {
            setStats(null);
            setSelectedRange(undefined);
            return;
        }

        setLoading(true);
        setSelectedRange(range);
        const currentRange = actualDateRange(range);

        if (currentRange.from && currentRange.to) {
            branchPacketCountStats({
                branchId,
                date: dateRangeToFilterRequestParameter(currentRange),
            }, api)
                .then(setStats)
                .catch((error: Error) => {
                    showAppErrorToast(error);
                    setSelectedRange(undefined);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [api, branchId, showAppErrorToast]);

    return <BranchIdChecker branchId={branchId} detailRoute={BranchStatsRoute} fallbackRoute={ManagedStatsRoute}>
        <PageContent className="branch-stats-page">
            {dashboardInfo.hasManagedBranches && <ReturnLink
                label={t('viewBranchStats:managedStats.back')}
                to={ManagedStatsRoute}
            />}
            <PageHeader>{t('viewBranchStats:header')}</PageHeader>
            <div className="branch-stats-page__range-select">
                <DateRangePicker
                    formId="branchStatsRange"
                    onChange={onRangeSelect}
                    namedRanges={namedRanges}
                    selectedRange={selectedRange}
                    disableCustomRange={true}
                />
                {stats && <h5>
                    <DateFormatter date={stats.date.from} showTime={true} />{' '}&ndash;{' '}
                    <DateFormatter date={stats.date.to} showTime={true} />
                </h5>}
            </div>
            <Loading active={loading}>
                {stats && <div className="branch-stats-page__stats">
                    <PacketCount packetCount={stats.packetCount} />
                    <TopDays topDays={stats.topDays} />
                </div>}
            </Loading>
        </PageContent>
    </BranchIdChecker>
})
