import {
    GeneralBranchQualityDetailItem
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/GeneralBranchQualityDetail";
import {useSorter} from "@common/components/grids/GridSorter/useSorter";

export const useProcessPenaltyDetailGridSorter = () => {
    return useSorter<GeneralBranchQualityDetailItem, 'date'|'penaltyPoints'>(
        'processPenaltyDetailGrid',
        {id: 'date'},
        {
            date: (item) => item.date,
            penaltyPoints: (item) => item.penaltyPoints,
        }
    );
}
