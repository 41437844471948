import {DefaultTFuncReturn} from "i18next";
import {Fragment, PropsWithChildren, ReactElement} from "react";
import {FieldValues, Path, RegisterOptions, UseFormReturn} from "react-hook-form";

import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";

export type RadioFormGroupItem = {
    value: string;
    label: DefaultTFuncReturn|ReactElement|ReactElement[];
}

export type RadioFormGroupProps<FormDataType extends FieldValues> = {
    form: UseFormReturn<FormDataType>;
    formId: string;
    items: RadioFormGroupItem[];
    name: Path<FormDataType>;
    registerOptions?: RegisterOptions;
}

export const RadioFormGroup = <FormDataType extends FieldValues, >({
    form,
    formId,
    items,
    name,
    registerOptions,
}: PropsWithChildren<RadioFormGroupProps<FormDataType>>): ReactElement|null => {
   return <Fragment>
       {items.map((item, index) => <FormGroup
            form={form}
            name={name}
            type="radio"
            label={item.label}
            inputId={`${formId}-${item.value}`}
            inputOptions={{value: item.value}}
            key={`${formId}-${item.value}`}
            registerOptions={index === 0 ? registerOptions : undefined}
            showErrors={index === items.length - 1}
        />)}
   </Fragment>
}
