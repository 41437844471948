import {FC} from "react";
import {Nav} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {ReceivedMessagesIcon} from "@app/Messaging/components/ReceivedMessagesIcon/ReceivedMessagesIcon";
import {ReceivedMessagesRoute} from "@app/Messaging/MessagingRoutes";
import {Img} from "@common/components/Img/Img";
import {AppLink} from "@common/components/routing/AppLink/AppLink";

import './headerActions.scss';

export type HeaderActionsProps = {
    openSupportModal: () => void;
}

export const HeaderActions: FC<HeaderActionsProps> = ({openSupportModal}) => {
    const {t} = useTranslation();

    return <Nav className="header-actions">
        <div className="header-actions-action">
            <a onClick={openSupportModal}>
                <Img src="/images/icons/ico/ico-poradna.svg" alt={t('viewPacketDetail:help.title')} />
            </a>
        </div>
        <div className="header-actions-action">
            <AppLink to={ReceivedMessagesRoute}>
                <ReceivedMessagesIcon />
            </AppLink>
        </div>
    </Nav>
}
