import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    InventoryPacketsListManager
} from "@app/Inventory/pages/InventoryDetailPage/InventoryPacketsList/InventoryPacketsListManager";
import {
    PaginatedGridSorter
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/PaginatedGridSorter";
import {
    usePaginatedGridSorter
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/usePaginatedGridSorter";

export const INVENTORY_PACKETS_LIST_SORTING_STORAGE_KEY = 'ui-inventoryPacketsList-sorting';

export type InventoryPacketsListHeaderProps = {
    manager: InventoryPacketsListManager;
}

export const InventoryPacketsListHeader: FC<InventoryPacketsListHeaderProps> = ({manager}) => {
    const {t} = useTranslation();

    const setStoredSorting = usePaginatedGridSorter(
        manager,
        INVENTORY_PACKETS_LIST_SORTING_STORAGE_KEY,
        null,
    );

    return <thead>
    <tr>
        <th className="packet-grid__barcode">
            <PaginatedGridSorter manager={manager} sortBy="barcode" onSort={setStoredSorting}>
                {t('inventory:property.barcode')}
            </PaginatedGridSorter>
            <span className="packet-grid__barcode-status">
                    {' / '}{t('packet:property.status')}
                </span>
        </th>
        <th className="inventory-packets-list__consigned-date">{t('inventory:property.consignedDate')}</th>
        <th className="packet-grid__sender">{t('packet:property.sender')}</th>
        <th className="packet-grid__recipient">
            {t('packet:property.recipient')}
            <span className="packet-grid__recipient-sender">
                    {' / '}{t('packet:property.sender')}
                </span>
        </th>
        <th className="packet-grid__address">{t('inventory:property.nameStreet')}</th>
        <th className="packet-grid__status">{t('inventory:property.status')}</th>
        <th className="inventory-packets-list__shelf">
            <PaginatedGridSorter manager={manager} sortBy="shelf" onSort={setStoredSorting}>
                {t('inventory:property.shelf')}
            </PaginatedGridSorter>
        </th>
        <th>{t('inventory:property.result')}</th>
    </tr>
    </thead>
}
