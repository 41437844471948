import {FC} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {PrintCardReceiptRequestType} from "@app/CardPayment/model/CardTerminalRequestsAndResponses";
import {Img} from "@common/components/Img/Img";
import {ReprintButton} from "@common/components/ReprintButton/ReprintButton";
import {PrintResult} from "@common/model/PrintResult";

export type CardPaymentReceiptsButtonProps = {
    callPrint: (requestType: PrintCardReceiptRequestType, forcePrint: boolean) => void;
    disabled: boolean;
    printResult: PrintResult|null;
    requestType: PrintCardReceiptRequestType;
    toastInModal: boolean;
}

export const CardPaymentReceiptsButton: FC<CardPaymentReceiptsButtonProps> = ({
    callPrint,
    disabled,
    printResult,
    requestType,
    toastInModal,
}) => {
    const {t} = useTranslation();

    const labelTranslationKey = `cardPayment:action.receipt.${requestType === PrintCardReceiptRequestType.CUSTOMER_RECEIPT ? 'customer' : 'merchant'}`;

    if (printResult) {
        return <ReprintButton
            variant="link"
            disabled={disabled}
            printResult={printResult}
            title={t(`${labelTranslationKey}.description`)}
            toastInModal={toastInModal}
        >
            <Img src="/images/icons/ico/ic_description_24px.svg" alt={t(`${labelTranslationKey}.short`)}/>{' '}
            <span className="card-payment-receipts-label--full">{t(`${labelTranslationKey}.full`)}</span>
            <span className="card-payment-receipts-label--short">{t(`${labelTranslationKey}.short`)}</span>
        </ReprintButton>
    }

    return <Button
        variant="link"
        disabled={disabled}
        onClick={() => callPrint(requestType, true)}
        title={t(`${labelTranslationKey}.description`)}
    >
        <Img src="/images/icons/ico/ic_description_24px.svg" alt={t(`${labelTranslationKey}.short`)} />{' '}
        <span className="card-payment-receipts-label--full">{t(`${labelTranslationKey}.full`)}</span>
        <span className="card-payment-receipts-label--short">{t(`${labelTranslationKey}.short`)}</span>
    </Button>
}
