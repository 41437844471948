
import {Api} from "@app/AppContext/classes/Api/Api";
import {commissionInvoiceList} from "@app/Commission/api/commissionApi";
import {CommissionInvoice} from "@app/Commission/model/CommissionInvoice";
import {CommissionInvoiceListRequest} from "@app/Commission/model/CommissionRequestsAndResponses";
import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";

export class InvoiceListManager extends SortablePaginatedDataManager<CommissionInvoice, CommissionInvoiceListRequest> {
    constructor(api: Api) {
        super((request) => commissionInvoiceList(request, api), {});
        this.filteringRequest = {};
    }
}
