import {FC, Fragment, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {Branch} from "@app/Branch/model/Branch";
import {parcelDetail} from "@app/Parcel/api/parcelApi";
import {ParcelDetailed} from "@app/Parcel/model/ParcelDetailed";
import {ParcelType} from "@app/Parcel/model/ParcelType";
import {
    ParcelDetailHeaderButtons
} from "@app/Parcel/pages/ParcelDetailPage/ParcelDetailHeaderButtons/ParcelDetailHeaderButtons";
import {ParcelDetailInfo} from "@app/Parcel/pages/ParcelDetailPage/ParcelDetailInfo/ParcelDetailInfo";
import {ParcelPacketList} from "@app/Parcel/pages/ParcelDetailPage/ParcelPacketList/ParcelPacketList";
import {ParcelListRoute} from "@app/Parcel/ParcelRoutes";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {extractEmbedded} from "@common/utils/extractEmbedded";

import './parcelDetailPage.scss';

export type ParcelDetailPageProps = {
    parcelId: string;
}

export const ParcelDetailPage: FC<ParcelDetailPageProps> = ({parcelId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const navigate = useAppNavigate();

    const [parcel, setParcel] = useState<ParcelDetailed|null|false>(null);
    const branchesMap = useRef<Map<string, Branch>>(new Map<string, Branch>());
    const showAppErrorToast = useShowErrorToast(appToast);

    useEffect(() => {
        if (parcel === null) {
            parcelDetail({parcelId, embed: [Embeddable.BRANCH]}, appContext.api)
                .then((response) => {
                    if (response.count === 0) {
                        appToast.error(t('parcel:error.parcelNotFound'));
                        navigate(ParcelListRoute);
                    } else {
                        branchesMap.current = extractEmbedded(response, Embeddable.BRANCH);
                        setParcel(response.items[0]);
                    }
                })
                .catch((error: Error) => {
                    showAppErrorToast(error);
                    setParcel(false);
                });
        }
    }, [appContext.api, navigate, parcel, parcelId, showAppErrorToast, t]);

    if (parcel === false) {
        return null;
    }

    return <PageContent className="parcel-detail-page">
        <ReturnLink label={t('parcel:action.goBackToParcels')} to={ParcelListRoute} />
        <Loading active={parcel === null}>
            {parcel !== null && <Fragment>
                <PageHeader
                    subTitle={<ParcelDetailHeaderButtons parcel={parcel} />}
                >
                    {parcel.type === ParcelType.MICRODEPOT
                        ? `MD ${t('parcel:property.id').toLowerCase()}`
                        : t('parcel:property.id')}&nbsp;{parcel.barcode}
                </PageHeader>
                <ParcelDetailInfo branchesMap={branchesMap.current} parcel={parcel} />
                <ParcelPacketList parcel={parcel} />
            </Fragment>}
        </Loading>
    </PageContent>
}
