import {TFunction} from "i18next";
import {useCallback, useEffect, useState} from "react";
import {useForm, UseFormReturn} from "react-hook-form";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {createRegularHoursChange, regularBranchHours} from "@app/OpeningHours/api/branchHoursApi";
import {fillGridHoursBlocks} from "@app/OpeningHours/components/EditableOpeningHoursGrid/branchToGridOpeningHours";
import {useOpeningHours} from "@app/OpeningHours/context/useOpeningHours";
import {RegularHoursChange} from "@app/OpeningHours/model/OpeningHoursRequestsAndResponses";
import {RegularOpeningHours} from "@app/OpeningHours/model/RegularOpeningHours";
import {BaseOpeningHoursGridForm} from "@app/OpeningHours/OpeningHoursFormTypes";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {ApiError} from "@common/model/errors/ApiError";

export type UseRegularOpeningHoursEditModal = {
    apiError?: ApiError;
    close: () => void;
    form: UseFormReturn<BaseOpeningHoursGridForm>;
    isLoaded: boolean;
    onSubmit: (values: BaseOpeningHoursGridForm) => void;
    selectedDay: Date|undefined;
    setSelectedDay: (date: Date|undefined) => void;
}

export const useRegularOpeningHoursEditModal = (modalOpened: boolean, changeDate: Date|undefined, onHide: () => void, t: TFunction): UseRegularOpeningHoursEditModal => {
    const appContext = useAppContext();

    const [selectedDay, setSelectedDay] = useState<Date|undefined>(changeDate);
    const form = useForm<BaseOpeningHoursGridForm>({
        defaultValues: {openingHours: []},
        shouldUnregister: true,
    })
    const [apiError, setApiError] = useState<ApiError|undefined>(undefined);
    const {branchHours, regularHours} = useOpeningHours();
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    useEffect(() => {
        if (!modalOpened || regularHours.asGridHours.length === 0 || isLoaded) {
            if (regularHours.error !== null) {
                modalToast.error(t(regularHours.error.message));
            }
            return;
        }

        if (changeDate) {
            regularBranchHours(changeDate, appContext.api)
                .then((response) => {
                    const indexedChangedRegularHours: Map<string, RegularOpeningHours> = new Map<string, RegularOpeningHours>();
                    response.openingHours.regular.forEach((changedRegular) => {
                        indexedChangedRegularHours.set(changedRegular.dayOfWeek.toString(), changedRegular);
                    });
                    setIsLoaded(true);
                    form.setValue('openingHours', regularHours.asGridHours.map((regular) => {
                        const changedRegular = indexedChangedRegularHours.get(regular.date);
                        return {
                            ...regular,
                            hours: fillGridHoursBlocks(changedRegular ? changedRegular.hours : []),
                            regularHours: regular.hours,
                        }
                    }));
                })
                .catch((error: Error) => {
                    setIsLoaded(true);
                    modalToast.error(t(error.message));
                });
        } else {
            setIsLoaded(true);
            form.setValue('openingHours', regularHours.asGridHours);
        }
    }, [appContext.api, changeDate, form, isLoaded, modalOpened, regularHours.asGridHours, regularHours.error, t]);

    const close = useCallback(() => {
        onHide();
        setIsLoaded(false);
        form.reset({openingHours: []});
        setApiError(undefined);
    }, [form, onHide]);

    const onSubmit = useCallback((values: BaseOpeningHoursGridForm) => {
        if (!selectedDay) {
            modalToast.error(t('openingHours:regularOpeningHoursEditModal.error.changeDateMandatory'));
            return;
        }

        const newRegularHours = values.openingHours.map((value): RegularHoursChange => ({
            date: selectedDay,
            dayOfWeek: parseInt(value.date, 10),
            action: 'create',
            hours: value.hours.filter((block) => {
                return block.open !== '' && block.close !== '';
            })
        }));
        if (changeDate) {
            newRegularHours.push({
                date: changeDate,
                action: 'delete',
            });
        }

        createRegularHoursChange({branchHours: newRegularHours}, appContext.api)
            .then(() => {
                close();
                branchHours.invalidate();
                appToast.success(t(`openingHours:regularOpeningHoursEditModal.success.${changeDate !== undefined ? 'edit' : 'create'}`));
            })
            .catch((error: Error) => {
                if (error instanceof ApiError && error.errors.length) {
                    setApiError(error);
                    return;
                }

                modalToast.error(t(error.message));
            });
    }, [selectedDay, changeDate, appContext.api, t, close, branchHours]);

    return {
        apiError,
        close,
        form,
        isLoaded,
        onSubmit,
        selectedDay,
        setSelectedDay,
    }
}
