import {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {callReaderAction} from "@app/Devices/api/callReaderAction";
import {ReaderProcessResult} from "@app/Devices/model/ReaderProcessResult";
import {ReaderProcessResultAction, ReaderProcessResultActionType} from "@app/Devices/model/ReaderProcessResultAction";
import {Button} from "@common/components/Button/Button";
import {modalToast} from "@common/components/Toast/toastOpener";
import {usePrint} from "@common/hooks/usePrint";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {isEncodedPdfResponse} from "@common/model/responses/EncodedPdfResponse";
import {isResponseWithPrintables} from "@common/model/responses/ResponseWithPrintables";
import {downloadEncodedPdf} from "@common/utils/api/downloadEncodedPdf";

export type ReaderJobResultActionsProps = {
    result: ReaderProcessResult;
}

export const ReaderJobResultActions: FC<ReaderJobResultActionsProps> = ({result}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const handlePrint = usePrint(true);
    const showModalErrorToast = useShowErrorToast(modalToast);

    const callAction = useCallback(async (action: ReaderProcessResultAction) => {
        switch (action.type) {
            case ReaderProcessResultActionType.REDIRECT: {
                const url = new URL(action.url);
                if (action.params) {
                    for (const paramName in action.params) {
                        if (Object.prototype.hasOwnProperty.call(action.params, paramName) && action.params[paramName]) {
                            url.searchParams.append(paramName, action.params[paramName] as string);
                        }
                    }
                }
                window.open(url.toString());
                break;
            }
            case ReaderProcessResultActionType.API: {
                try {
                    const response = await callReaderAction(
                        action.url,
                        action.params,
                        appContext.api
                    )
                    if (isResponseWithPrintables(response)) {
                        await handlePrint(Promise.resolve(response));
                    } else if (isEncodedPdfResponse(response)) {
                        downloadEncodedPdf(response, t(`reader:action.pdfFileName.${action.name}`));
                    } else {
                        // some unknown endpoint
                        modalToast.success(t('reader:message.jobActionSuccess'));
                    }
                } catch (error: unknown) {
                    if (error instanceof ApiError) {
                        modalToast.error(t(error.message));
                    } else {
                        showModalErrorToast(error as Error);
                    }
                }
            }
        }
    }, [appContext.api, handlePrint, showModalErrorToast, t]);

    if (!result.actions) {
        return null;
    }

    return <div className="reader-job-results__actions">
        {result.actions.map((action) => <Button
            variant="secondary"
            onClick={() => callAction(action)}
            key={action.name}
        >
            {t(`reader:action.${action.name}`)}
        </Button>)}
    </div>

}
