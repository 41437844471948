import {BranchQualityDateRange} from "@app/Branch/model/BranchQuality/BranchQualityDateRange";
import {
    GeneralBranchQualityDetailItem
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/GeneralBranchQualityDetail";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";

export type ParcelBranchQualityDetailItem = GeneralBranchQualityDetailItem & {
    parcelId: string|null;
    parcelBarcode: string|null;
    missingPackets: number|null;
}

export type ParcelBranchQualityDetail = {
    dateRange: BranchQualityDateRange;
    type: BranchEventCode.PARCEL_PACKETS_MISSING | BranchEventCode.EXPEDITION_PARCEL_PACKETS_MISSING;
    items: ParcelBranchQualityDetailItem[];
}

export const parcelBranchQualityDetailTypes = [
    BranchEventCode.PARCEL_PACKETS_MISSING,
    BranchEventCode.EXPEDITION_PARCEL_PACKETS_MISSING,
];
