import {FC, useEffect} from 'react';

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {SignInRoute} from "@app/Sign/SignRoutes";
import {Loading} from "@common/components/Loading/Loading";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useQueryParam} from "@common/hooks/useQueryParam";

import './signAsPage.scss';

const TOKEN_QUERY_PARAM = 'token';

export const SignAsPage: FC = () => {
    const [token] = useQueryParam(TOKEN_QUERY_PARAM);

    const appNavigate = useAppNavigate();
    const appContext = useAppContext();

    useEffect(() => {
        if (token === null || token === '') {
            appNavigate(SignInRoute);
        } else if (appContext.user.isImpersonated && appContext.user.impersonationToken === token) {
            window.setTimeout(() => {
                appNavigate(DashboardRoute);
            }, 2000);
        } else {
            appContext.authorizator.impersonate(token)
                .catch(() => {
                    appNavigate(SignInRoute);
                });
        }
    }, [appContext.authorizator, appContext.user.impersonationToken, appContext.user.isImpersonated, appNavigate, token]);

    return <div className="sign-as-page">
        <div className="sign-as-page__content">
            <Loading active={true} />
        </div>
    </div>
}
