import {observer} from "mobx-react-lite";
import {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {PacketListManager} from "@app/Packet/model/PacketListManager";
import {PaginatedGridExport} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/PaginatedGridExport";
import {
    usePaginatedGridExport
} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/usePaginatedGridExport";
import {LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";
import {packetWholeName} from "@common/utils/packetWholeName";

export type PacketListExportProps = {
    manager: PacketListManager;
}

const PACKET_LIST_EXPORT_PAGE_SIZE = 100;
const PACKET_LIST_MAX_EXPORT_ITEMS = 1000;

export const PacketListExport: FC<PacketListExportProps> = observer(({manager}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const paginatedGridExport = usePaginatedGridExport(manager, PACKET_LIST_MAX_EXPORT_ITEMS);

    const dateFormatter = useDateFormatter();

    const doExport: LoadingButtonOnClick = useCallback(async (stopLoading) => {
        const downloadManager = new PacketListManager(
            manager.branchId,
            appContext.api,
            true,
            PACKET_LIST_EXPORT_PAGE_SIZE
        );

        await paginatedGridExport(
            downloadManager,
            t('viewDashboard:csvFilename'),
            (packet) => {
                const sender = packet.senderId ? downloadManager.senders.get(packet.senderId) : undefined;
                const branch = packet.addressId ? downloadManager.branches.get(packet.addressId) : undefined;
                return {
                    [t('packet:property.barcode')]: formatPacketBarcode(packet.barcode),
                    [t('packet:property.dateStored.label')]: dashWhenEmpty(
                        packet.dateStored && dateFormatter(packet.dateStored, DateFormatType.DATE_FORMAT)
                    ),
                    [t('packet:property.sender')]: dashWhenEmpty(sender && sender.name),
                    [t('packet:property.recipient')]: packetWholeName(packet),
                    [t('packet:property.addressAbbr')]: dashWhenEmpty(branch && branch.abbr),
                    [t('packet:property.status')]: packet.status.name,
                    [t('packet:property.deliveryDate')]: dashWhenEmpty(
                        packet.deliveryDate && dateFormatter(packet.deliveryDate, DateFormatType.DATE_FORMAT)
                    ),
                    [t('packet:property.shelf.label')]: dashWhenEmpty(packet.shelf),
                    [t('packet:property.hasCashOnDelivery.canBePaidByCard')]: dashWhenEmpty(
                        packet.hasCod && (
                            sender && sender.cardPaymentAllowed ? t('core:boolean.yes') : t('core:boolean.no')
                        )
                    ),
                };
            }
        );

        stopLoading();
    }, [appContext.api, dateFormatter, manager.branchId, paginatedGridExport, t]);

    if (!manager.hasItems) {
        return null;
    }

    return <PaginatedGridExport doExport={doExport} />
})
