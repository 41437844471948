import {useCallback} from "react";
import {useForm} from "react-hook-form";

import {Deliver} from "@app/Packet/components/PacketDelivery/hooks/useDeliver";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {IdCardType, PacketIdVerification} from "@app/Packet/components/PacketDelivery/model/PacketIdVerification";
import {PacketDeliveryType} from "@app/Packet/model/PacketDeliveryType";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export const usePacketIdVerificationForm = (
    deliver: Deliver,
    packet?: DeliveryPacket,
    onSuccess?: (packetIdVerification: PacketIdVerification) => void,
) => {

    const showDeliveryErrorToast = useShowErrorToast(modalToast, 'packet:error', 'packet:error.deliveryError')

    const form = useForm<PacketIdVerification>({
        defaultValues: {
            idCardType: IdCardType.IDENTITY_CARD,
            name: '',
            idCardNumber: '',
        },
        mode: 'onSubmit',
        shouldUnregister: true,
    });

    const onSubmit = useCallback(async (packetIdVerification: PacketIdVerification) => {
        if (!packet) {
            return;
        }

        try {
            await deliver(packet, {type: PacketDeliveryType.PERSONAL_ID, packetIdVerification});
            if (onSuccess) {
                onSuccess(packetIdVerification);
            }
        } catch (error: unknown) {
            showDeliveryErrorToast(error as Error);
        }

    }, [deliver, onSuccess, packet, showDeliveryErrorToast]);

    return {
        form,
        onSubmit,
    }
}
