import {add} from "date-fns";
import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback, useEffect, useRef, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useLoadDeliveryPacket} from "@app/Packet/components/PacketActions/hooks/useLoadDeliveryPacket";
import {
    PacketUndeliveryModal
} from "@app/Packet/components/PacketDelivery/components/PacketUndeliveryModal/PacketUndeliveryModal";
import {Packet} from "@app/Packet/model/Packet";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {Img} from "@common/components/Img/Img";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {parseDate} from "@common/utils/parseDate";

export type UndeliverPacketProps = {
    packet: Packet;
    onSuccess?: () => void;
}

const UNDELIVERY_TIMEOUT_HOURS = 1; // packet undelivery is available for 1 hour
const UNDELIVERY_CHECK_INTERVAL = 60 * 1000; // check every minute

export const UndeliverPacket: FC<UndeliverPacketProps> = observer(({onSuccess, packet}) => {
    const {t} = useTranslation();

    const [canBeUndelivered, setCanBeUndelivered] = useState<boolean|null>(null);

    const {
        closeModal,
        openModal,
        packets,
    } = useLoadDeliveryPacket(packet, onSuccess);

    const timeoutRef = useRef<number|null>(null);
    const updateCanBeUndelivered = useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }

        const newCanBeUndelivered = packet.status.id === PacketStatusId.DELIVERED
            && packet.deliveryDate !== null
            && add(parseDate(packet.deliveryDate), {hours: UNDELIVERY_TIMEOUT_HOURS}) > new Date()

        setCanBeUndelivered(newCanBeUndelivered);
        if (newCanBeUndelivered) {
            timeoutRef.current = window.setTimeout(updateCanBeUndelivered, UNDELIVERY_CHECK_INTERVAL);
        }
    }, [packet.deliveryDate, packet.status.id]);

    useEffect(() => {
        updateCanBeUndelivered();

        return () => {
            if (timeoutRef.current) {
                window.clearTimeout(timeoutRef.current);
            }
        }
    }, [updateCanBeUndelivered]);

    const onUndelivered = useCallback(() => {
        appToast.success(t('viewPacketDelivery:undelivery.message.success'));
        updateCanBeUndelivered();
        closeModal();
    }, [closeModal, t, updateCanBeUndelivered]);

    const showUndeliveryCancellationError = useShowErrorToast(appToast, 'cardPayment:error', 'cardPayment:error.cancelling');
    const onUndeliveryCancelled = useCallback((error?: Error) => {
        if (error) {
            showUndeliveryCancellationError(error);
        }
        updateCanBeUndelivered();
        closeModal();
    }, [closeModal, showUndeliveryCancellationError, updateCanBeUndelivered]);

    if (!canBeUndelivered) {
        return null;
    }

    return <Fragment>
        <Button
            onClick={openModal}
            title={t('packet:action.cancel')}
            variant="success"
            data-xid={`undeliver-packet-${packet.barcode}`}
        >
            <Img src="/images/icons/ico/ico-table-man-out.svg" alt={t('packet:action.cancel')} />
        </Button>
        {packets.deliveryReady.length === 1 && <PacketUndeliveryModal
            packet={packets.deliveryReady[0]}
            onUndelivered={onUndelivered}
            onUndeliveryCancelled={onUndeliveryCancelled}
        />}
    </Fragment>
})
