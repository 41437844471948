import {observer} from "mobx-react-lite";
import {FC, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import {DeliveryAlerts} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/DeliveryAlerts";
import {
    PacketVerificationForms
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PacketVerificationForms";
import {Deliver} from "@app/Packet/components/PacketDelivery/hooks/useDeliver";
import {usePacketVerificationForms} from "@app/Packet/components/PacketDelivery/hooks/usePacketVerificationForms";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";
import {PacketIdVerification} from "@app/Packet/components/PacketDelivery/model/PacketIdVerification";
import {Alert} from "@common/components/Alert/Alert";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {modalToast} from "@common/components/Toast/toastOpener";
import {AddPacket} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/AddPackets/AddPacket";
import {DeliveryPackets} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/DeliveryPackets";
import {
    PacketDeliveryButtons
} from "@packetModal/components/PacketDeliveryModal/PacketDeliveryButtons/PacketDeliveryButtons";
import {
    PacketIdVerificationView
} from "@packetModal/components/PacketDeliveryModal/PacketIdVerificationView/PacketIdVerificationView";
import {TotalPayment} from "@packetModal/components/PacketDeliveryModal/TotalPayment/TotalPayment";

import './packetDeliveryModal.scss';

export type PacketDeliveryModalProps = {
    deliver: Deliver;
    onDeliveryComplete: () => void;
    packets: DeliveryPacketsCollection;
    packetIdVerification: PacketIdVerification|null;
    setShowToastMessage?: (newShowToastMessage: string|null) => void;
    showToastMessage?: string|null;
}

export const PacketDeliveryModal: FC<PacketDeliveryModalProps> = observer(({
    deliver,
    onDeliveryComplete,
    packetIdVerification,
    packets,
    setShowToastMessage,
    showToastMessage,
}) => {
    const {t} = useTranslation();
    const [running, setRunning] = useState<boolean>(false);
    const [savedAmountPaid, saveAmountPaid] = useState<string>('');
    const [webPaymentRunning, setWebPaymentRunning] = useState<boolean>(false);
    const focused = useRef<boolean>(false);

    const {
        submit: verificationFormsSubmit,
        ...packetVerificationForms
    } = usePacketVerificationForms(
        !packets.isPasswordDelivery && packets.all.length > 0 ? packets.all[0] : undefined, deliver, onDeliveryComplete
    );

    useEffect(() => {
        if (setShowToastMessage && showToastMessage && packets.deliveryReady.length > 0) {
            modalToast.success(showToastMessage);
            setShowToastMessage(null);
        }
    }, [packets.deliveryReady.length, setShowToastMessage, showToastMessage]);

    useEffect(() => {
        if (packets.deliveryReady.length === 0) {
            focused.current = false;
        }
    }, [packets.deliveryReady.length]);

    if (packets.deliveryReady.length === 0) {
        return null;
    }

    const focusAddPacket = packets.deliveryReadyCod.length === 0 || (packets.isPasswordDelivery && packets.notDelivered.length > 0);

    return <BaseModal
        show={true}
        onHide={() => onDeliveryComplete()}
        header={t(`viewPacketDelivery:packetDelivery.header.${packets.all.length === 1 ? 'single' : 'batch'}`)}
        footer={<PacketDeliveryButtons
            packets={packets}
            onDeliveryComplete={onDeliveryComplete}
            onRunning={setRunning}
            running={running}
            setWebPaymentRunning={setWebPaymentRunning}
            verificationFormsSubmit={verificationFormsSubmit}
        />}
        height="auto"
        disableOutsideClose={packets.isPasswordDelivery || running}
        closeButton={!packets.isPasswordDelivery && !running}
        className="packet-delivery-modal"
        xid="packet-delivery-modal"
    >
        <div className="packet-delivery-modal-content">
            {webPaymentRunning && <Alert type="info">{t('viewPacketDelivery:webPayment.message.running')}</Alert>}
            <DeliveryPackets
                packets={packets}
                onRunning={setRunning}
                running={running}
            />
            {packets.isPasswordDelivery && <AddPacket
                deliver={deliver}
                focus={!running && focusAddPacket}
                focused={focused}
                hasNotDeliveredPackets={packets.isPasswordDelivery && packets.notDelivered.length > 0}
                onRunning={setRunning}
            />}
            <DeliveryAlerts packets={packets} />
            {!packets.isPasswordDelivery && <PacketVerificationForms
                packets={packets}
                {...packetVerificationForms}
            />}
            <PacketIdVerificationView packetIdVerification={packetIdVerification} packets={packets} />
            <TotalPayment
                currency={packets.all[0].info.currency}
                codPackets={packets.deliveryReadyCod}
                focusCashCalculator={!running && !focusAddPacket && packets.isPasswordDelivery}
                focused={focused}
                saveAmountPaid={saveAmountPaid}
                savedAmountPaid={savedAmountPaid}
            />
        </div>
    </BaseModal>
})

PacketDeliveryModal.displayName = 'PacketDeliveryModal';
