import {observer} from "mobx-react-lite";
import {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {Message, sanitizedMessageContent} from "@app/Messaging/model/Message";
import {useSystemNotification} from "@app/PDC/hooks/useSystemNotification";
import {Button} from "@common/components/Button/Button";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {Truncate} from "@common/components/Truncate/Truncate";
import {renderComponentToString} from "@common/utils/renderComponentToString";

export type PriorityMessageModalProps = {
    message: Message;
    onClose: () => void;
}

export const PriorityMessageModal: FC<PriorityMessageModalProps> = observer(({
    message,
    onClose,
}) => {
    const {t} = useTranslation();
    const notify = useSystemNotification();

    useEffect(() => {
        const text = renderComponentToString(<Truncate text={sanitizedMessageContent(message.content, t, false)} length={150} />);
        if (text) {
            notify(text);
        }
    }, [message.content, notify, t]);

    return <BaseModal
        show={true}
        onHide={onClose}
        header={message.subject}
        footer={<Button onClick={onClose} variant="primary">{t('branchEvent:close')}</Button>}
        size="lg"
    >
        <div dangerouslySetInnerHTML={{__html: sanitizedMessageContent(message.content, t)}} />
    </BaseModal>
})
