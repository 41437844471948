import {add, lastDayOfMonth} from "date-fns";
import {observer} from "mobx-react-lite";
import {FC, Fragment, useEffect, useRef} from "react";
import {useController, useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    CommissionBranchesManager
} from "@app/Commission/components/ManagedCommissionGrid/CommissionBranchesManager";
import {
    ManagedCommissionGrid
} from "@app/Commission/components/ManagedCommissionGrid/ManagedCommissionGrid";
import {CommissionBranch} from "@app/Commission/model/CommissionBranch";
import {
    NewInvoiceFormData
} from "@app/Commission/pages/NewInvoicePage/NewInvoiceForm/NewInvoiceFormData";
import {
    useNewInvoiceFormRenderAction
} from "@app/Commission/pages/NewInvoicePage/NewInvoiceForm/useNewInvoiceFormRenderAction";
import {
    useNewInvoiceFormSubmit
} from "@app/Commission/pages/NewInvoicePage/NewInvoiceForm/useNewInvoiceFormSubmit";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {DatePickerField} from "@common/components/forms/DatePicker/DatePickerField/DatePickerField";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {LabelWithHelp} from "@common/components/forms/LabelWithHelp/LabelWithHelp";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {Loading} from "@common/components/Loading/Loading";
import {TranslationWithHtmlParam} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {denormalizeDecimalSeparator} from "@common/utils/normalizeDecimalSeparator";

import './newInvoiceForm.scss';

export type NewInvoiceFormProps = {
    commissionBranchesManager: CommissionBranchesManager;
    onInvoiced: () => void;
    vatTaxable: boolean;
}

export const NewInvoiceForm: FC<NewInvoiceFormProps> = observer(({commissionBranchesManager, onInvoiced, vatTaxable}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const showVariableSymbol = !appContext.dashboardInfo.isRoBranch;

    const commissionBranchesMap = useRef<Map<string, CommissionBranch>>(new Map<string, CommissionBranch>());
    const form = useForm<NewInvoiceFormData>({
        defaultValues: {invoiceNumber: '', variableSymbol: '', issuedDate: undefined, branchesCommissions: undefined},
        mode: 'onChange',
    });

    const issuedDateController = useController({
        control: form.control,
        name: 'issuedDate',
        shouldUnregister: true,
        rules: {
            required: t<string>('viewCommission:newInvoice.form.issueDate.required'),
        }
    });

    const renderAction = useNewInvoiceFormRenderAction(form, commissionBranchesMap, t);
    const {loading, submit} = useNewInvoiceFormSubmit(form, commissionBranchesMap, onInvoiced, t);

    useEffect(() => {
        if (commissionBranchesManager.loaded && commissionBranchesManager.items) {
            const branchesCommissions: Record<string, string> = {};
            commissionBranchesManager.items.forEach((commissionBranch) => {
                const sanitizedBranchId = `${commissionBranch.branchId.replace(/\./g, '-')}-${commissionBranch.commissionType}`;
                branchesCommissions[sanitizedBranchId] = denormalizeDecimalSeparator(commissionBranch.unpaidTillLastMonth);
            });

            form.setValue('branchesCommissions', branchesCommissions);
        }
    }, [commissionBranchesManager.items, commissionBranchesManager.loaded, form]);

    const formId = 'newInvoiceForm';

    return <Loading active={loading}>
        <form className="new-invoice-form" onSubmit={form.handleSubmit(submit)}>
            <h4>{t('invoice:property.billingCommission')}</h4>
            {vatTaxable && <p className="new-invoice-form__info">
                <TranslationWithHtmlParam
                    paramName="lastDayOfLastMonth"
                    translationKey="viewCommission:billingInformation.note.vatPayer"
                    asSpan={true}
                    t={t}
                >
                    <DateFormatter date={lastDayOfMonth(add(new Date(), {months: -1}))} />
                </TranslationWithHtmlParam>
            </p>}
            <p className="new-invoice-form__info">
                {t('viewCommission:billingInformation.note.previouslyIssued')}
            </p>
            <p className="new-invoice-form__info">{t('invoice:property.invoiceData.info')}</p>
            <ManagedCommissionGrid
                manager={commissionBranchesManager}
                renderAction={renderAction}
                showUnpaidTotalAndStatus={false}
            />
            {vatTaxable && <p className="new-invoice-form__vat-info">{t('viewCommission:newInvoice.vatWillBeAdded')}</p>}
            <div className="new-invoice-form__invoice-data">
                <h5>{t('invoice:property.invoiceData.title')}</h5>
                <div>
                    <div>
                        <FormGroup
                            form={form}
                            name="invoiceNumber"
                            label={<LabelWithHelp
                                label={t('invoice:property.invoiceData.invoiceNumber')}
                                help={t('invoice:help.invoiceNumber')}
                            />}
                            registerOptions={{
                                required: t<string>('viewCommission:newInvoice.form.invoiceNumber.required'),
                                pattern: {
                                    value: /^[a-zA-Z0-9._/-]+$/,
                                    message: t<string>('viewCommission:newInvoice.form.invoiceNumber.pattern'),
                                }
                            }}
                        />
                    </div>
                    {showVariableSymbol && <div>
                        <FormGroup
                            form={form}
                            name="variableSymbol"
                            label={<LabelWithHelp
                                label={t('invoice:property.invoiceData.variableSymbol')}
                                help={t('invoice:help.variableSymbol')}
                            />}
                            registerOptions={{
                                required: t<string>('viewCommission:newInvoice.form.variableSymbol.required'),
                                pattern: {
                                    value: /^[0-9]{1,10}$/,
                                    message: t<string>('viewCommission:newInvoice.form.variableSymbol.pattern'),
                                }
                            }}
                        />
                    </div>}
                    <div className="new-invoice-form__issued-date">
                        <DatePickerField
                            formController={issuedDateController}
                            formId={formId}
                            name="issuedDate"
                            label={<LabelWithHelp
                                label={t('invoice:property.invoiceData.issuedDate')}
                                help={t('invoice:help.issuedDate')}
                            />}
                            dayPickerProps={{
                                disabled: [{after: new Date()}, {before: lastDayOfMonth(add(new Date(), {months: -1}))}],
                                toMonth: new Date(),
                            }}
                        />
                    </div>
                    <div>
                        <SubmitFormGroup
                            value={t('invoice:action.makeInvoice')}
                            name="create"
                            formId={formId}
                            inputOptions={{
                                disabled: loading,
                            }}
                            label={<Fragment>&nbsp;</Fragment>}
                        />
                    </div>
                </div>
            </div>
        </form>
    </Loading>
})
