import {ReactElement} from "react";
import {Control, FieldPath, useController} from "react-hook-form";
import {useTranslation} from "react-i18next";
import striptags from "striptags";

import {
    InsertPacketBarcode
} from "@app/Messaging/components/NewMessage/common/MessageContent/InsertPacketBarcode/InsertPacketBarcode";
import {BaseMessageFormData} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {WysiwygInput} from "@common/components/forms/WysiwygInput/WysiwygInput";

export type MessageContentProps<FormDataType extends BaseMessageFormData> = {
    control: Control<FormDataType>;
    name: FieldPath<FormDataType>;
}

const MESSAGE_CONTENT_MIN_LENGTH = 10;

export const MessageContent = <FormDataType extends BaseMessageFormData>({
    control,
    name,
}: MessageContentProps<FormDataType>): ReactElement|null => {
    const {t} = useTranslation();

    const formController = useController({
        control,
        name,
        shouldUnregister: true,
        rules: {
            required: t<string>('message:error.noContent'),
            validate: (value: any) => {
                const sanitizedValue = striptags(value as string).replace(/&nbsp;/g, ' ').trim();
                return sanitizedValue.length >= MESSAGE_CONTENT_MIN_LENGTH
                    || t<string>('message:error.tooShortContent');
            }
        }
    });

    return <WysiwygInput
        name={name}
        formController={formController}
        customControls={[InsertPacketBarcode]}
        label={t('message:tabs.content.new.content.label')}
    />
}
