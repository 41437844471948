import {ErrorMessage} from '@hookform/error-message';
import {DefaultTFuncReturn} from "i18next";
import {
    Fragment,
    ReactElement,
} from 'react';
import {DayPickerSingleProps} from "react-day-picker";
import {
    FieldErrors, FieldPath,
    FieldValues,
    UseControllerReturn,
} from "react-hook-form";

import {DatePicker} from "@common/components/forms/DatePicker/DatePicker";
import {DateFormatType} from "@common/hooks/useDateFormatter";

export type DatePickerFieldProps<FormDataType extends FieldValues, NameType extends FieldPath<FormDataType> = FieldPath<FormDataType>> = {
    formController: UseControllerReturn<FormDataType, NameType>;
    formId: string;
    inputId?: string;
    label?: DefaultTFuncReturn|ReactElement|ReactElement[];
    name: NameType;
    showErrors?: boolean;
    className?: string;
    dateFormat?: DateFormatType;
    dayPickerProps?: Omit<DayPickerSingleProps, 'mode'|'selected'>;
    open?: boolean;
    placeholder?: string;
    showTimeSelect?: boolean;
}

export const DatePickerField = <FormDataType extends FieldValues, NameType extends FieldPath<FormDataType> = FieldPath<FormDataType>>({
    className,
    dateFormat,
    dayPickerProps,
    formController,
    formId,
    inputId,
    label,
    name,
    open,
    placeholder,
    showErrors = true,
    showTimeSelect,
}: DatePickerFieldProps<FormDataType, NameType>): ReactElement|null => {
    const {field, formState} = formController;

    return <Fragment>
        <DatePicker
            formId={formId}
            name={name}
            onDateSelect={field.onChange}
            onClear={field.onChange}
            value={field.value}
            placeholder={placeholder}
            label={label}
            dateFormat={dateFormat}
            dayPickerProps={{
                ...dayPickerProps,
                mode: 'single',
                selected: field.value,
            }}
            open={open}
            className={className}
            showTimeSelect={showTimeSelect}
        />
        {showErrors && <div className="form-group-errors">
            <ErrorMessage
                name={name}
                errors={formState.errors as FieldErrors}
                as={<div className="alert alert-danger" data-xid={`${inputId}-alert`} />}
            />
        </div>}
    </Fragment>
}
