
import {InventoryPacket} from "@app/Inventory/model/InventoryPacket";
import {BasePacket} from "@app/Packet/model/BasePacket";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export const packetWholeName = (packet: BasePacket|InventoryPacket|{name: string|null, surname: string|null}): string => {
    if (packet.name === null) {
        return dashWhenEmpty(packet.surname);
    } else if (packet.surname === null) {
        return dashWhenEmpty(packet.name);
    }

    return `${packet.name} ${packet.surname}`;
}
