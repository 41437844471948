import {BranchQualityDateRange} from "@app/Branch/model/BranchQuality/BranchQualityDateRange";
import {
    GeneralBranchQualityDetailItem
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/GeneralBranchQualityDetail";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";

export enum ReasonBranchQualityDetailItemReason {
    UNPAID_STATEMENT_NOT_SIGNED = 'unpaid_statement_not_signed',
    UNPAID_STATEMENT_SIGNED = 'unpaid_statement_signed',
    CLOSED = 'closed',
    INTERNET_NOT_WORKING = 'internet_not_working',
    REDUCTION_OF_PAYMENT = 'reduction_of_payment',
}

export type CashRegisterBranchQualityDetailItem = GeneralBranchQualityDetailItem & {
    reason: ReasonBranchQualityDetailItemReason|null;
}

export type CashRegisterBranchQualityDetail = {
    dateRange: BranchQualityDateRange;
    type: BranchEventCode.CASH_REGISTER_TODAY_TRANSFER_ISSUE;
    items: CashRegisterBranchQualityDetailItem[];
}

export const cashRegisterBranchQualityDetailTypes = [
    BranchEventCode.CASH_REGISTER_TODAY_TRANSFER_ISSUE,
];
