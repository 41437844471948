import {FC, useCallback, useEffect, useState} from "react";
import {Navbar, NavbarBrand} from "react-bootstrap";
import NavbarCollapse from "react-bootstrap/NavbarCollapse";
import NavbarToggle from "react-bootstrap/NavbarToggle";
import {useLocation} from "react-router-dom";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {useOnLanguageChanged} from "@common/hooks/useOnLanguageChanged";
import {Logo} from "@common/layouts/common/Logo/Logo";
import {OfflineNotice} from "@common/layouts/common/OfflineNotice/OfflineNotice";
import {ReloadNotice} from "@common/layouts/common/ReloadNotice/ReloadNotice";
import {HeaderActions} from "@common/layouts/SecuredLayout/Header/HeaderActions/HeaderActions";
import {SupportModal} from "@common/layouts/SecuredLayout/Header/HeaderActions/SupportModal/SupportModal";
import {MainMenu} from "@common/layouts/SecuredLayout/Header/MainMenu/MainMenu";
import {ReaderBox} from "@common/layouts/SecuredLayout/Header/ReaderBox/ReaderBox";
import {UserActions} from "@common/layouts/SecuredLayout/Header/UserActions/UserActions";

import './header.scss';

export const Header: FC = () => {
    const appContext = useAppContext();

    const [supportModalOpened, setSupportModalOpened] = useState<boolean>(false);
    const [navbarExpanded, setNavbarExpanded] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        // close on page change
        setNavbarExpanded(false);
    }, [location]);

    useOnLanguageChanged(() => {
        setNavbarExpanded(false);
    }, []);

    const openSupportModal = useCallback(() => {
        setNavbarExpanded(false);
        setSupportModalOpened(true);
    }, []);

    const onToggle = useCallback(() => {
        setNavbarExpanded(!navbarExpanded);
    }, [navbarExpanded]);

    return <div className="header">
        <NavbarBrand href={appContext.router.href(DashboardRoute)} className="header__mobile-logo">
            <Logo />
        </NavbarBrand>
        <Navbar expanded={navbarExpanded} expand="xl" className="header__desktop" onToggle={onToggle}>
            <NavbarBrand href={appContext.router.href(DashboardRoute)} className="header__desktop-logo">
                <Logo />
            </NavbarBrand>
            <NavbarToggle />
            <NavbarCollapse>
                <MainMenu />
                <HeaderActions openSupportModal={openSupportModal} />
                <UserActions openSupportModal={openSupportModal} />
            </NavbarCollapse>
        </Navbar>
        <ReloadNotice />
        <OfflineNotice />
        <SupportModal
            show={supportModalOpened}
            onHide={() => setSupportModalOpened(false)}
        />
        <ReaderBox />
    </div>
}
