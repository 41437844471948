import {getDate, getMonth, getYear} from "date-fns";
import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {NewInvoiceRoute} from "@app/Commission/CommissionRoutes";
import {CommissionsMonthly} from "@app/Commission/model/CommissionsMonthly";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";

export type InvoicedAmountCellProps = {
    commissionMonthly: CommissionsMonthly;
}

const INVOICE_FROM_DAY = 1;
const INVOICE_TO_DAY = 16;

export const InvoicedAmountCell: FC<InvoicedAmountCellProps> = ({commissionMonthly}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const today = new Date();
    const currentYear = getYear(today);
    const currentMonth = getMonth(today) + 1;

    if (commissionMonthly.year === currentYear && commissionMonthly.month === currentMonth) {
        return <td className="monthly-commission-grid__invoiced-amount">&ndash;</td>
    }

    const invoicedAmount = commissionMonthly.invoicedAmount ? parseFloat(commissionMonthly.invoicedAmount) : 0;
    const currentDay = getDate(today);

    if (!invoicedAmount) {
        if (commissionMonthly.year === currentYear
            && (commissionMonthly.month + 1) % 12 === currentMonth
            && currentDay >= INVOICE_FROM_DAY && currentDay <= INVOICE_TO_DAY
        ) {
            return <td className="monthly-commission-grid__invoiced-amount">
                <a href={appContext.router.href(NewInvoiceRoute)} title={t('commission:action.createInvoice')}>
                    {t('commission:action.invoice')}
                </a>
            </td>
        }

        return <td className="monthly-commission-grid__invoiced-amount">
            {t('viewCommission:monthlyCommissionGrid.notInvoiced')}
        </td>
    } else {
        return <td className="monthly-commission-grid__invoiced-amount">
            <MoneyFormatter amount={invoicedAmount} currencyCode={commissionMonthly.currency} />
            {commissionMonthly.billingDate && <Fragment>
                &nbsp;&nbsp;
                <small><DateFormatter date={commissionMonthly.billingDate} /></small>
            </Fragment>}
        </td>

    }
}
