import {Api} from "@app/AppContext/classes/Api/Api";
import {
    devicesListEndpoint, jobActionEndpoint, pdcDefaultVersionLinkEndpoint,
    pdcVersionLinkEndpoint, readerAnalyzeEndpoint
} from "@app/Devices/api/devicesEndpoints";
import {
    DevicesListResponse,
    JobActionRequest, JobActionResponse,
    ReaderAnalyzeResponse
} from "@app/Devices/model/DevicesRequestsAndResponses";
import {PdcVersionLink} from "@app/Devices/model/PdcVersionLink";

export const devicesList = async (api: Api): Promise<DevicesListResponse> => {
    return api.call(devicesListEndpoint);
}

export const jobAction = async (request: JobActionRequest, api: Api): Promise<JobActionResponse> => {
    return api.call({
        endpoint: jobActionEndpoint,
        payload: request,
    });
}

export const readerAnalyze = async (api: Api): Promise<ReaderAnalyzeResponse> => {
    return api.call(readerAnalyzeEndpoint);
}

export const pdcVersionLink = async (deviceId: string|null, api: Api): Promise<PdcVersionLink> => {
    if (deviceId === null) {
        return api.call(pdcDefaultVersionLinkEndpoint);
    }

    try {
        return await api.call({
            endpoint: pdcVersionLinkEndpoint,
            customHeaders: {
                'Content-Type': 'application/json',
                'X-Device-Id': deviceId,
            }
        });
    } catch (error: unknown) {
        return api.call(pdcDefaultVersionLinkEndpoint);
    }
}
