import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {NewMessageButton} from "@app/Messaging/components/NewMessageButton/NewMessageButton";
import {MessageTopic} from "@app/Messaging/model/MessageTopic";
import {ParcelDetailed} from "@app/Parcel/model/ParcelDetailed";
import {ParcelDirection} from "@app/Parcel/model/ParcelDirection";
import {ParcelStatusBadge} from "@common/components/Badges/ParcelStatusBadge/ParcelStatusBadge";

export type ParcelDetailHeaderButtonsProps = {
    parcel: ParcelDetailed;
}

export const ParcelDetailHeaderButtons: FC<ParcelDetailHeaderButtonsProps> = ({parcel}) => {
    const {t} = useTranslation();

    return <Fragment>
        <div>
            {parcel.status && <ParcelStatusBadge status={parcel.status} />}
        </div>
        {parcel.direction !== ParcelDirection.UNKNOWN && <div>
            ({t(`viewParcel:${parcel.direction}Parcel`)})
        </div>}
        <div className="parcel-detail-page__report-error">
            <NewMessageButton variant="danger" topic={MessageTopic.OTHER}>
                {t('parcel:action.reportError')}
            </NewMessageButton>
        </div>
    </Fragment>
}
