import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {UserAccountType} from "@app/AppContext/classes/User/model/UserIdentity";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {cancelInventory, confirmInventory} from "@app/Inventory/api/inventoryApi";
import {BranchInventoryListRoute} from "@app/Inventory/InventoryRoutes";
import {InventoryDetail} from "@app/Inventory/model/InventoryDetail";
import {InventoryStatusId} from "@app/Inventory/model/InventoryStatus";
import {NewMessageButton} from "@app/Messaging/components/NewMessageButton/NewMessageButton";
import {MessageTopic} from "@app/Messaging/model/MessageTopic";
import {Confirm} from "@common/components/Confirm/Confirm";
import {LoadingButton, LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export type InventoryDetailButtonsProps = {
    branchId?: string;
    detail: InventoryDetail;
    inventoryId?: string;
}

export const InventoryDetailButtons: FC<InventoryDetailButtonsProps> = observer(({
    branchId,
    detail,
    inventoryId,
}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const navigate = useAppNavigate();

    const showAppErrorToast = useShowErrorToast(appToast);

    const cancel = useCallback(() => {
        if (!inventoryId) {
            return;
        }

        cancelInventory({inventoryId}, appContext.api)
            .then(() => {
                appToast.success(t('inventory:flash.canceled'));
                navigate({route: BranchInventoryListRoute, params: {branchId}});
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
            });
    }, [appContext.api, branchId, inventoryId, navigate, showAppErrorToast, t]);

    const confirm = useCallback<LoadingButtonOnClick>((stopLoading) => {
        if (!inventoryId) {
            return;
        }

        confirmInventory({inventoryId}, appContext.api)
            .then(() => {
                appToast.success(t('inventory:flash.confirmed'));
                navigate({route: BranchInventoryListRoute, params: {branchId}});
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
            })
            .finally(stopLoading);
    }, [appContext.api, branchId, inventoryId, navigate, showAppErrorToast, t]);

    if (!branchId || !inventoryId || !detail || detail.status.id === InventoryStatusId.CANCELLED) {
        return null;
    }

    return <Fragment>
        <NewMessageButton variant="danger" topic={MessageTopic.INVENTORY}>
            {t('inventory:action.reportError')}
        </NewMessageButton>
        {appContext.user.identityUser && appContext.user.identityUser.accountType === UserAccountType.BRANCH && !detail.confirmed && <Fragment>
            <Confirm
                message={t<string>('viewInventory:detail.cancelConfirm.message')}
                header={t('viewInventory:detail.cancelConfirm.header')}
                onSuccess={cancel}
            >
               &times;&nbsp;&nbsp;{t('inventory:action.cancel')}
            </Confirm>
            <LoadingButton variant="success" onLoadingClick={confirm}>{t('inventory:action.confirm')}</LoadingButton>
        </Fragment>}
    </Fragment>
})
