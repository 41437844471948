import {Ability} from "@casl/ability";
import {createContextualCan} from "@casl/react";
import {createContext} from "react";

import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";

export const emptyAbility = new Ability([
    {
        action: Action.ALL,
        subject: Subject.ALL,
        inverted: true,
    }
]);

export const AbilityContext = createContext<Ability>(emptyAbility);

export const Can = createContextualCan(AbilityContext.Consumer);
