import {useContext, useEffect} from "react";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {To} from "@common/model/ReactRoute";

export const useCaslGuard = (action: Action, subject: Subject, toIfFailed: To): boolean => {
    const ability = useContext(AbilityContext);
    const navigate = useAppNavigate();

    useEffect(() => {
        if (!ability.can(action, subject)) {
            navigate(toIfFailed);
        }
    }, [ability, action, navigate, subject, toIfFailed]);

    return ability.can(action, subject);
}
