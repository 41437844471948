import {getYear, Locale} from "date-fns";
import {FC} from "react";
import {DayPicker as ReactDayPicker, DayPickerProps as ReactDayPickerProps} from "react-day-picker";

import {useDateFnsLocale} from "@common/hooks/useDateFnsLocale";

import 'react-day-picker/dist/style.css';
import './dayPicker.scss';

const DATE_PICKER_YEARS_AVAILABLE = 5;

export const DayPicker: FC<ReactDayPickerProps> = (dayPickerProps) => {
    const locale: Locale = useDateFnsLocale();

    return <ReactDayPicker
        className="day-picker rdp"
        captionLayout="dropdown-buttons"
        fromYear={getYear(new Date()) - DATE_PICKER_YEARS_AVAILABLE}
        {...dayPickerProps}
        locale={locale}
    />
}
