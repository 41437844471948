import {normalizeDecimalSeparator} from "@common/utils/normalizeDecimalSeparator";

export const addAmounts = (amountA: string, amountB: string): string => {
    const [amountAWholePart, amountAFraction] = `${normalizeDecimalSeparator(amountA)}.`.split('.');
    const [amountBWholePart, amountBFraction] = `${normalizeDecimalSeparator(amountB)}.`.split('.');

    const fractionLength = Math.max(amountAFraction.length, amountBFraction.length);
    const amountAIntRepresentation = parseInt(`${amountAWholePart}${amountAFraction.padEnd(fractionLength, '0')}`, 10);
    const amountBIntRepresentation = parseInt(`${amountBWholePart}${amountBFraction.padEnd(fractionLength, '0')}`, 10);

    const sumString = (amountAIntRepresentation + amountBIntRepresentation).toString(10);

    if (fractionLength === 0) {
        return sumString;
    }

    if (sumString.length > fractionLength) {
        const dotPosition = sumString.length - fractionLength;
        return `${sumString.slice(0, dotPosition)}.${sumString.slice(dotPosition)}`;
    }

    return `0.${sumString.padStart(fractionLength, '0')}`;
}
