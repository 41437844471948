import {TFunction} from "i18next";

import {createRequestFromData} from "@app/Messaging/components/NewMessage/common/MessageAttachment/createRequestFromData";
import {IncomingMessageFormData} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {MessageTopic as MessageTopicEnum} from "@app/Messaging/model/MessageTopic";
import {
    CreateIncomingMessageRequest
} from "@app/Messaging/model/MessagingRequestsAndResponses";

export const createIncomingMessageRequest = async (values: IncomingMessageFormData, t: TFunction): Promise<CreateIncomingMessageRequest> => {
    const baseRequest = await createRequestFromData(values);

    const request: CreateIncomingMessageRequest = {
        ...baseRequest,
        subject: t(`message:tabs.content.new.topic.items.${values.topic}`),
        topic: values.topic || MessageTopicEnum.OTHER,
    }

    // eslint-disable-next-line eqeqeq
    if (values.topic == MessageTopicEnum.PACKET_PROBLEM && values.packetBarcode) {
        request.subject += ' ' + values.packetBarcode;
    }

    // eslint-disable-next-line eqeqeq
    if (values.topic == MessageTopicEnum.OTHER && values.otherTopic) {
        request.subject = values.otherTopic;
    }

    return request;
}
