import {observer} from "mobx-react-lite";
import {FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {changePassword} from "@app/PasswordRecovery/api/passwordRecoveryApi";
import {SignInRoute} from "@app/Sign/SignRoutes";
import {ChangePasswordRequirements} from "@app/User/components/ChangePasswordRequirements/ChangePasswordRequirements";
import {useChangePasswordValidation} from "@app/User/hooks/useChangePasswordValidation";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {Loading} from "@common/components/Loading/Loading";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useQueryParam} from "@common/hooks/useQueryParam";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {CenteredLayout} from "@common/layouts/CenteredLayout/CenteredLayout";

export const NEW_PASSWORD_TOKEN_QUERY_PARAM = 'token';
export const NEW_PASSWORD_EMAIL_QUERY_PARAM = 'email';

type NewPasswordFormData = {
    newPassword: string;
    confirmPassword: string;
}

export const NewPasswordPage: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const form = useForm<NewPasswordFormData>();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const navigate = useAppNavigate();

    const [token] = useQueryParam(NEW_PASSWORD_TOKEN_QUERY_PARAM);
    const [email] = useQueryParam(NEW_PASSWORD_EMAIL_QUERY_PARAM);

    useEffect(() => {
        if (appContext.user.isLoggedIn) {
            navigate(DashboardRoute);
        }
        if (!token || !email) {
            navigate(SignInRoute);
        }
    }, [appContext.user.isLoggedIn, email, navigate, token]);

    useEffect(() => {
        if (email && token && !form.formState.isDirty) {
            form.setFocus('newPassword');
        }
    }, [email, form, token]);

    const showAppErrorToast = useShowErrorToast(appToast, 'viewPasswordRecoveryForm:error');

    const onSubmit = useBlockingCallback((unblock, {newPassword}: NewPasswordFormData) => {
        if (!email || !token) {
            return;
        }

        setIsSubmitting(true);
        changePassword({password: newPassword, email, token}, appContext.api)
            .then(() => {
                navigate(SignInRoute);
                appToast.success(t('viewPasswordRecoveryForm:message.passwordChanged'));
            })
            .catch((error: unknown) => {
                showAppErrorToast(error as Error);
                unblock();
                setIsSubmitting(false)
            });
    }, [appContext.api, email, navigate, showAppErrorToast, t, token]);

    const formId = 'newPasswordForm';

    const {confirmPasswordOptions, newPasswordOptions} = useChangePasswordValidation(
        () => form.getValues('newPassword'),
    );

    if (appContext.user.isLoggedIn || !token || !email) {
        return null;
    }

    return <CenteredLayout header={<span>{t('viewPasswordRecoveryForm:title')}</span>} >
        <Loading active={isSubmitting}>
            <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
                <FormGroup
                    name="newPassword"
                    label={t('viewPasswordRecoveryForm:password')}
                    type="password"
                    formId={formId}
                    registerOptions={newPasswordOptions}
                    form={form}
                />
                <FormGroup
                    name="confirmPassword"
                    label={t('viewPasswordRecoveryForm:passwordAgain')}
                    type="password"
                    formId={formId}
                    registerOptions={confirmPasswordOptions}
                    form={form}
                />
                <SubmitFormGroup name="send" value={t('viewPasswordRecoveryForm:apply')} formId={formId} />
            </form>
            <ChangePasswordRequirements differFromCurrent={false} />
        </Loading>
    </CenteredLayout>
})
