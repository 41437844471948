import {observer} from "mobx-react-lite";
import {FC, useEffect, useMemo} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {TypedPacketListRequestType} from "@app/Packet/components/PacketGrid/callPacketListApi";
import {PacketGrid} from "@app/Packet/components/PacketGrid/PacketGrid";
import {PacketListManager} from "@app/Packet/model/PacketListManager";

export type ToDispatchPacketsGridProps = {
    branchId: string;
}

const TO_DISPATCH_LIST_PAGE_SIZE = 400;

export const ToDispatchPacketsGrid: FC<ToDispatchPacketsGridProps> = observer(({
    branchId,
}) => {
    const appContext = useAppContext();

    const packetListManager = useMemo(() => new PacketListManager(
        branchId,
        appContext.api,
        false,
        TO_DISPATCH_LIST_PAGE_SIZE
    ), [appContext.api, branchId]);

    useEffect(() => {
        if (!packetListManager.hasFilter) {
            packetListManager.setFilter({type: TypedPacketListRequestType.TO_DISPATCH});
        }
    }, [packetListManager]);

    return <PacketGrid gridName="toDispatch" manager={packetListManager} showShelf={true} />
})
