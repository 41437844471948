import * as Sentry from "@sentry/react";
import {useCallback} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {ApiError} from "@common/model/errors/ApiError";
import {EndpointError} from "@common/model/errors/EndpointError";
import {getApiErrorCode} from "@common/utils/api/getApiErrorCode";
import {getAuthOptionsTech} from "@common/utils/api/getAuthOptionsTech";
import {getFirstErrorItem} from "@common/utils/api/getFirstErrorItem";

export const useLogError = () => {
    const appContext = useAppContext();

    return useCallback(async (error: Error): Promise<string> => {
        const errorIdentifier = ((new Date()).getTime() + Math.floor(Math.random() * 10000000)).toString(16).substring(5);
        let errorMessage = error.message;

        if (error instanceof ApiError) {
            const firstErrorItem = getFirstErrorItem(error as ApiError);
            if (firstErrorItem) {
                errorMessage = firstErrorItem.text || firstErrorItem.code;
            }
        }

        const captureContext: Exclude<Parameters<typeof Sentry.captureException>[1], undefined> = {};

        try {
            const user = appContext.user;
            const deviceServer = await appContext.deviceGatewayResolver.resolve();
            const authOptionsTech = await getAuthOptionsTech();

            captureContext.user = user.isLoggedIn ? {
                email: user.email || undefined,
                name: user.name || undefined,
                isImpersonated: user.isImpersonated,
                branchId: user.supportBranchId,
                deviceType: deviceServer.deviceGatewayType,
            } : undefined;
            captureContext.extra = {
                ...deviceServer,
                ...authOptionsTech,
                errorMessage,
            };

            captureContext.tags = {
                errorIdentifier,
                isBackendIssue: false,
            };

            if (error instanceof ApiError) {
                captureContext.tags = {
                    ...captureContext.tags,
                    responseCode: error.code,
                    errorCode: getApiErrorCode(error),
                };
            }

            if (error instanceof EndpointError && error.endpoint) {
                captureContext.tags = {
                    ...captureContext.tags,
                    endpointUrl: error.endpoint.url,
                    endpointMethod: error.endpoint.method,
                    isBackendIssue: error.isBackendIssue || false,
                };
            }
        } catch (logErrorError: unknown) {
            error = logErrorError as Error;
            captureContext.extra = {
                logErrorError: true,
            };
        }

        Sentry.captureException(error, captureContext);

        console.dir(error);

        return errorIdentifier;
    }, [appContext.deviceGatewayResolver, appContext.user]);
}
