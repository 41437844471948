import {useBranchEventMessage} from "@app/BranchEvent/hooks/useBranchEventMessage";
import {
    CashRegisterManualTransferMissingBranchEvent, CashRegisterTransferBranchEvent,
    CommissionCreateInvoiceNotifyBranchEvent, InventoryMissingBranchEvent,
    PacketsReceiveRequiredBranchEvent, ParcelPacketsMissingBranchEvent,
    ParcelsNotReceivedBranchEvent, SimpleBranchEvent
} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";
import {BranchEventComponent} from "@app/BranchEvent/model/BranchEventComponent";

export const SimpleBranchEventContent: BranchEventComponent<
    SimpleBranchEvent
    | CashRegisterTransferBranchEvent
    | CashRegisterManualTransferMissingBranchEvent
    | CommissionCreateInvoiceNotifyBranchEvent
    | ParcelsNotReceivedBranchEvent
    | PacketsReceiveRequiredBranchEvent
    | ParcelPacketsMissingBranchEvent
    | InventoryMissingBranchEvent
> = ({event}) => {
    const message = useBranchEventMessage(event);

    const htmlMessageEvents = [
        BranchEventCode.PARCEL_PACKETS_MISSING,
    ];

    if (htmlMessageEvents.includes(event.code)) {
        return <div dangerouslySetInnerHTML={{__html: message}} />
    }

    return message;
}
