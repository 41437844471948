import {useCallback, useState} from "react";

import {
    usePacketIdVerificationForm
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PacketIdVerificationForm/usePacketIdVerificationForm";
import {
    usePasswordVerificationForm
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PasswordVerificationForm/usePasswordVerificationForm";
import {
    useReasonVerificationForm
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/ReasonVerificationForm/useReasonVerificationForm";
import {Deliver} from "@app/Packet/components/PacketDelivery/hooks/useDeliver";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {PacketDeliveryType} from "@app/Packet/model/PacketDeliveryType";

export const usePacketVerificationForms = (
    packet: DeliveryPacket|undefined,
    deliver: Deliver,
    onFinish: (barcodes: string[]) => void,
) => {

    const [deliveryType, setDeliveryType] = useState<PacketDeliveryType>(PacketDeliveryType.PERSONAL_ID);

    const {
        form: packetIdVerificationForm,
        onSubmit: packetIdVerificationFormSubmit,
    } = usePacketIdVerificationForm(deliver, packet)

    const {
        form: passwordVerificationForm,
        onSubmit: passwordVerificationFormSubmit,
    } = usePasswordVerificationForm(deliver, packet);

    const {
        form: reasonVerificationForm,
        onSubmit: reasonVerificationFormSubmit,
    } = useReasonVerificationForm(deliver, packet);

    const submit = useCallback(async () => {
        if (!packet) {
            return;
        }

        if (packet.isDelivered) {
            // Packet should be delivered when the password verification was used.
            onFinish([packet.info.barcode]);
        } else {
            switch (deliveryType) {
                case PacketDeliveryType.PERSONAL_ID:
                    await packetIdVerificationForm.handleSubmit(packetIdVerificationFormSubmit)();
                    break;
                case PacketDeliveryType.PASSWORD:
                    await passwordVerificationForm.handleSubmit(passwordVerificationFormSubmit)();
                    break;
                case PacketDeliveryType.REASON:
                    await reasonVerificationForm.handleSubmit(reasonVerificationFormSubmit)();
                    break;
            }
        }
    }, [deliveryType, onFinish, packet, packetIdVerificationForm, packetIdVerificationFormSubmit, passwordVerificationForm, passwordVerificationFormSubmit, reasonVerificationForm, reasonVerificationFormSubmit]);

    return {
        deliveryType,
        packetIdVerificationForm,
        packetIdVerificationFormSubmit,
        passwordVerificationForm,
        passwordVerificationFormSubmit,
        reasonVerificationForm,
        reasonVerificationFormSubmit,
        setDeliveryType,
        submit,
    }
}
