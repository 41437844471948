import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect, useRef, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {getCodInfo, webPaymentLink} from "@app/Packet/api/packetApi";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";
import {Loading} from "@common/components/Loading/Loading";
import {modalToast} from "@common/components/Toast/toastOpener";

import './webPaymentButton.scss';

export type WebPaymentButtonProps = {
    packets: DeliveryPacketsCollection;
    disabled: boolean;
    onRunning: (running: boolean) => void;
}

const WEB_PAYMENT_CHECK_INTERVAL = 5000; // 5s
const WEB_PAYMENT_MAX_CHECKS_COUNT = 60; // 5 minutes

export const WebPaymentButton: FC<WebPaymentButtonProps> = observer(({disabled, onRunning, packets}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const [checking, setChecking] = useState<boolean>(false);
    const [alreadyChecked, setAlreadyChecked] = useState<boolean>(false);

    const lastCheck = useRef<number>(0);
    const timeoutRef = useRef<number|null>(null);

    const packetsRequiringPayment = packets.availableForCardPayment;

    const cancelCheck = useCallback(() => {
        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }
        lastCheck.current = 0;
        setChecking(false);
    }, []);

    const checkWebPayment = useCallback(() => {
        setAlreadyChecked(true);

        if (timeoutRef.current) {
            window.clearTimeout(timeoutRef.current);
        }

        ++lastCheck.current;
        if (lastCheck.current > 1) { // First check is just scheduled
            packetsRequiringPayment.forEach((packet) => {
                getCodInfo(packet.info.barcode, appContext.api)
                    .then((response) => {
                        if (response.paidByCard) {
                            packet.pay();
                        }
                    })
                    // Check errors intentionally ignored
                    .catch(() => {});
            });
        }

        if (lastCheck.current === WEB_PAYMENT_MAX_CHECKS_COUNT) {
            modalToast.error(t('viewPacketDelivery:webPayment.error.timeout'));
            cancelCheck();
        } else {
            timeoutRef.current = window.setTimeout(checkWebPayment, WEB_PAYMENT_CHECK_INTERVAL);
        }
    }, [appContext.api, cancelCheck, packetsRequiringPayment, t]);

    useEffect(() => {
        if (checking && packetsRequiringPayment.length === 0) {
            cancelCheck();
        }
        onRunning(checking);
    }, [cancelCheck, checking, onRunning, packetsRequiringPayment.length])

    const sendWebPayment = useCallback(() => {
        if (packetsRequiringPayment.length === 0) {
            return;
        }

        setChecking(true);
        const webPaymentLinksPromises = packetsRequiringPayment.map((packet) => {
            return webPaymentLink({barcode: packet.info.barcode}, appContext.api);
        });

        Promise.all(webPaymentLinksPromises)
            .then(() => {
                modalToast.success(t('viewPacketDelivery:webPayment.message.success'));
                checkWebPayment();
            })
            .catch(() => {
                modalToast.error(t('viewPacketDelivery:webPayment.error.unsent'));
                setChecking(false);
            });
    }, [appContext.api, checkWebPayment, packetsRequiringPayment, t]);

    if (packetsRequiringPayment.length === 0 ) {
        return null;
    }

    return <Can I={Action.MANAGE} a={Subject.CARD_TRANSACTION}>
        <Can I={Action.SEND} a={Subject.WEB_PAYMENT_LINK}>
            {!checking && <Button variant="dark" onClick={sendWebPayment} data-xid="send-web-payment-link" disabled={disabled}>
                {t(`viewPacketDelivery:webPayment.${alreadyChecked ? 'resendLink' : 'sendLink'}`)}
            </Button>}
            {checking && <div className="web-payment-button web-payment-button--waiting" data-xid="web-payment-waiting">
                <Loading active={true} />
                <Button variant="dark" onClick={cancelCheck} data-xid="cancel-waiting" disabled={disabled && !checking}>
                    {t('viewPacketDelivery:webPayment.cancelWaiting')}
                </Button>
            </div>}
        </Can>
    </Can>
})
