import {FC} from "react";
import {Route, Routes} from "react-router-dom";

import {NotFoundPage} from "@app/App/pages/NotFoundPage/NotFoundPage";
import {BranchCardTool} from "@app/Tools/components/BranchCardTool/BranchCardTool";
import {BranchCardToolRoute} from "@app/Tools/ToolsRoutes";

export const Tools: FC = () => {
    return <Routes>
        <Route path={BranchCardToolRoute.path} element={<BranchCardTool />} />
        <Route path="*" element={<NotFoundPage />} />
    </Routes>
}
