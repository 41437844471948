import {action, computed, IObservableArray, makeObservable, observable} from "mobx";

import {CodDeliveryPacket, DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";

export class DeliveryPacketsCollection {
    public all: IObservableArray<DeliveryPacket>;
    public loaded = false;
    public isPasswordDelivery: boolean;
    public touchedBarcodes: string[] = [];
    private lastDeliveredPacketIndex = 0;

    constructor(packets: DeliveryPacket[], isPasswordDelivery: boolean) {
        this.all = observable.array(packets, {deep: false});
        this.isPasswordDelivery = isPasswordDelivery;

        makeObservable(this, {
            loaded: observable,
            isPasswordDelivery: observable,
            delivered: computed,
            notDelivered: computed,
            packetPendingIdVerification: computed,
            packetPendingUndelivery: computed,
            deliveryReady: computed,
            deliveryReadyCod: computed,
            availableForCardPayment: computed,
            load: action,
            update: action,
            delete: action,
            clear: action,
        });
    }

    public get delivered(): DeliveryPacket[] {
        return this.all.filter((packet) => packet.isDelivered);
    }

    public get notDelivered(): DeliveryPacket[] {
        return this.all.filter((packet) => !packet.isDelivered);
    }

    public get packetPendingIdVerification(): DeliveryPacket|undefined {
        return this.all.find((packet) => packet.requiresIdVerification);
    }

    public get packetPendingUndelivery(): DeliveryPacket|undefined {
        return this.all.find((packet) => packet.requiresUndelivery);
    }

    public get deliveryReady(): DeliveryPacket[] {
        if (!this.loaded || (this.isPasswordDelivery && (this.packetPendingIdVerification || this.packetPendingUndelivery))) {
            return [];
        }

        if (!this.isPasswordDelivery) {
            return this.all;
        } else {
            return this.delivered;
        }
    }

    public get deliveryReadyCod(): CodDeliveryPacket[] {
        return this.deliveryReady.filter((packet): packet is CodDeliveryPacket => packet.isCod);
    }

    public get availableForCardPayment(): CodDeliveryPacket[] {
        return this.deliveryReadyCod.filter(
            (packet): packet is CodDeliveryPacket => packet.cardPaymentAllowed && !packet.paid
        );
    }

    public load(packets: DeliveryPacket[]) {
        this.all.replace(packets);
        this.delivered.forEach((deliveredPacket, index) => {
            this.addTouchedBarcode(deliveredPacket.info.barcode);
            deliveredPacket.deliveryOrder = index;
        });
        this.lastDeliveredPacketIndex = this.delivered.length - 1;
        this.loaded = true;
    }

    public update(packet: DeliveryPacket) {
        const existingPacket = this.all.find((_packet) => _packet.info.barcode === packet.info.barcode);
        if (existingPacket) {
            existingPacket.isDelivered = packet.isDelivered;
            existingPacket.verification = packet.verification;
            existingPacket.requiresIdVerification = packet.requiresIdVerification;
            existingPacket.requiresUndelivery = packet.requiresUndelivery;
            if (existingPacket.isCod && packet.isCod) {
                existingPacket.paid = packet.paid;
            }
            if (existingPacket.deliveryOrder === -1 && packet.isDelivered) {
                existingPacket.deliveryOrder = ++this.lastDeliveredPacketIndex;
            }
        } else {
            if (packet.isDelivered) {
                packet.deliveryOrder = ++this.lastDeliveredPacketIndex;
            }
            this.all.push(packet);
        }
        this.addTouchedBarcode(packet.info.barcode);
    }

    public delete(packet: DeliveryPacket) {
        this.all.remove(packet);
        this.addTouchedBarcode(packet.info.barcode);
    }

    public clear() {
        this.all.replace([]);
        this.loaded = false;
    }

    private addTouchedBarcode(barcode: string): void {
        if (!this.touchedBarcodes.includes(barcode)) {
            this.touchedBarcodes.push(barcode);
        }
    }
}
