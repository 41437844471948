import {createBrowserRouter, RouterProvider} from "react-router-dom";

import {CaslProvider} from "@app/App/components/AppWrapper/CaslProvider/CaslProvider";
import {DataSourceProvider} from "@app/App/components/AppWrapper/DataSourceProvider/DataSourceProvider";
import {FeatureFlagProvider} from "@app/App/components/AppWrapper/FeatureFlagProvider/FeatureFlagProvider";
import {GtmProvider} from "@app/App/components/AppWrapper/GtmProvider/GtmProvider";
import {I18Provider} from "@app/App/components/AppWrapper/I18Provider/I18Provider";
import {SentryProvider} from "@app/App/components/AppWrapper/SentryProvider/SentryProvider";
import {AppContextProvider} from "@app/AppContext/AppContextProvider";
import {FCWithChildren} from "@common/model/FCWithChildren";

export type AppWrapperProps = {
    unsupported?: boolean;
}

export const AppWrapper: FCWithChildren<AppWrapperProps> = ({children, unsupported = false}) => {
    if (unsupported) {
        return <SentryProvider>
            <I18Provider>
                {children}
            </I18Provider>
        </SentryProvider>
    }

    const router = createBrowserRouter([{
        path: "*",
        element: <I18Provider>
            <AppContextProvider>
                <CaslProvider>
                    <FeatureFlagProvider>
                        <GtmProvider>
                            <DataSourceProvider>
                                {children}
                            </DataSourceProvider>
                        </GtmProvider>
                    </FeatureFlagProvider>
                </CaslProvider>
            </AppContextProvider>
        </I18Provider>,
    }]);

    return <SentryProvider>
        <RouterProvider router={router} />
    </SentryProvider>
}
