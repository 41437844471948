export type ObjectWithDate = {
    date: string|Date;
};

export const dateObjectCompare = (objectA: ObjectWithDate, objectB: ObjectWithDate): number => {
    if (objectA.date < objectB.date) {
        return -1;
    }

    if (objectA.date > objectB.date) {
        return 1;
    }

    return 0;
}
