import {FC, useCallback, useEffect, useMemo} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {createMessage} from "@app/Messaging/api/messagingApi";
import {MessageContent} from "@app/Messaging/components/NewMessage/common/MessageContent/MessageContent";
import {MessageSubmit} from "@app/Messaging/components/NewMessage/common/MessageSubmit/MessageSubmit";
import {createIncomingMessageRequest} from "@app/Messaging/components/NewMessage/IncomingMessageForm/createIncomingMessageRequest";
import {IncomingMessageAttachment} from "@app/Messaging/components/NewMessage/IncomingMessageForm/IncomingMessageAttachment/IncomingMessageAttachment";
import {MessageTopic} from "@app/Messaging/components/NewMessage/IncomingMessageForm/MessageTopic/MessageTopic";
import {IncomingMessageFormData, NewMessageFormFields} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {
    DEFAULT_TOPIC_QUERY_PARAM
} from "@app/Messaging/components/NewMessageButton/NewMessageButton";
import {SentMessagesRoute} from "@app/Messaging/MessagingRoutes";
import {isMessageApiErrorItem} from "@app/Messaging/model/CreateMessageApiErrorItem";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useNumericQueryParam} from "@common/hooks/useQueryParam";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {getFirstErrorItem} from "@common/utils/api/getFirstErrorItem";

export type IncomingMessageFormProps = {
    isInsideModal?: boolean;
    onFormStored?: (data: Partial<IncomingMessageFormData>) => void;
}

export const INCOMING_MESSAGE_FORM_STORAGE_KEY = 'ui-incomingMessageForm';

export const IncomingMessageForm: FC<IncomingMessageFormProps> = ({isInsideModal, onFormStored}) => {
    const {t} = useTranslation();
    const {api, userSpecificUI} = useAppContext();

    const [defaultTopic] = useNumericQueryParam(DEFAULT_TOPIC_QUERY_PARAM);

    const formDefaults = useMemo(() => ({
        topic: defaultTopic !== null ? defaultTopic : -1,
        subject: '',
        content: '',
        attachment: null,
    }), [defaultTopic]);

    const defaultValues = userSpecificUI.create(
        INCOMING_MESSAGE_FORM_STORAGE_KEY,
        formDefaults,
        defaultTopic !== null
    );
    const form = useForm<IncomingMessageFormData>({
        defaultValues,
        mode: 'onSubmit',
    });

    useEffect(() => {
        if (onFormStored && defaultValues !== formDefaults) {
            onFormStored(defaultValues);
        }
    }, [defaultValues, formDefaults, onFormStored]);

    form.watch((data, {type}) => {
        if (type === 'change') {
            userSpecificUI.persist(INCOMING_MESSAGE_FORM_STORAGE_KEY, data);
            if (onFormStored) {
                onFormStored(data);
            }
        }
    });

    const navigate = useNavigate();

    const toaster = isInsideModal ? modalToast : appToast;
    const showErrorToast = useShowErrorToast(toaster, 'message:error', 'message:error.unknownError');

    const onSubmit = useBlockingCallback((unblock, values: IncomingMessageFormData) => {
        createIncomingMessageRequest(values, t)
            .then((request) => {
                createMessage(request, api)
                    .then(() => {
                        userSpecificUI.persist(INCOMING_MESSAGE_FORM_STORAGE_KEY, null);
                        form.reset(formDefaults);
                        if (!isInsideModal) {
                            navigate(SentMessagesRoute.path);
                        }
                        toaster.success(t('message:tabs.content.new.success'));
                    })
                    .catch((error: ApiError) => {
                        const firstError = getFirstErrorItem(error);
                        const invalidRecipientIds = firstError && isMessageApiErrorItem(firstError)
                            ? firstError.variables.invalidRecipientIds.join(', ') : null;

                        showErrorToast(error, {invalidRecipientIds});
                    })
                    .finally(unblock);
            })
            .catch(() => {
                toaster.error(t('message:error.unknownError'));
                unblock();
            });
    }, [t, api, userSpecificUI, form, formDefaults, isInsideModal, toaster, navigate, showErrorToast]);

    const reset = useCallback(() => {
        form.reset(formDefaults);
        userSpecificUI.persist(INCOMING_MESSAGE_FORM_STORAGE_KEY, null);
    }, [userSpecificUI, form, formDefaults]);

    const formId = 'incomingMessageForm';

    return <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
        <MessageTopic formId={formId} form={form} />
        <MessageContent control={form.control} name={NewMessageFormFields.content} />
        <MessageSubmit submit={form.handleSubmit(onSubmit)} submitting={form.formState.isSubmitting} reset={reset}>
            <IncomingMessageAttachment form={form} />
        </MessageSubmit>
    </form>
}
