import {getYear, isAfter} from "date-fns";
import {DefaultTFuncReturn} from "i18next";
import {FC, Fragment, ReactElement, useCallback, useEffect, useRef, useState} from "react";
import {Dropdown} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {DateRange, Matcher} from "react-day-picker";
import {useTranslation} from "react-i18next";

import {
    actualDateRange,
    isNamedDateRange,
    NamedDateRange,
    SelectedDateRange
} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {DayPicker} from "@common/components/forms/DayPicker/DayPicker";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";

import './dateRangePicker.scss';

export type DateRangeOnChange = (selectedRange?: SelectedDateRange) => void;

export type DateRangePickerProps = {
    clearOption?: boolean;
    formId: string;
    label?: DefaultTFuncReturn|ReactElement|ReactElement[];
    maxEndDate?: Date;
    minStartDate?: Date;
    name?: string;
    namedRanges?: NamedDateRange[];
    onChange: DateRangeOnChange;
    selectedRange?: SelectedDateRange;
    disableCustomRange?: boolean;
}

export const DateRangePicker: FC<DateRangePickerProps> = ({
    clearOption,
    disableCustomRange,
    formId,
    label,
    maxEndDate,
    minStartDate,
    name,
    namedRanges,
    onChange,
    selectedRange,
}) => {
    const {t} = useTranslation();

    const datePickerRef = useRef<HTMLDivElement>(null);
    const [showDropdown, setShowDropdown] = useState<boolean>(false);
    const [showDatePickers, setShowDatePickers] = useState<boolean>(
        selectedRange !== undefined && !isNamedDateRange(selectedRange)
    );
    const [newCustomDateRange, setNewCustomDateRange] = useState<DateRange|null>(null);
    const preventDropdownToggle = useRef<boolean>(false);

    const actualRange: DateRange|undefined = selectedRange ? actualDateRange(selectedRange) : undefined;

    const onNamedRangeSelect = useCallback((namedRange: NamedDateRange) => {
        setShowDatePickers(false);
        onChange(namedRange);
    }, [onChange]);

    const onCustomRangeSelect = useCallback(() => {
        setShowDatePickers(true);
        preventDropdownToggle.current = true;
    }, []);

    const toggleDropdown = useCallback((show: boolean) => {
        if (!preventDropdownToggle.current) {
            setShowDropdown(show);
        }
        preventDropdownToggle.current = false;
    }, []);

    const onDayClick = useCallback((date: Date) => {
        if (!newCustomDateRange || newCustomDateRange.from === undefined) {
            setNewCustomDateRange({from: date});
        } else {
            setNewCustomDateRange(null)
            toggleDropdown(false);
            if (isAfter(date, newCustomDateRange.from)) {
                onChange({...newCustomDateRange, to: date});
            } else {
                onChange({from: date, to: newCustomDateRange.from});
            }
        }
    }, [newCustomDateRange, onChange, toggleDropdown]);

    const onClear = useCallback(() => {
        if (showDatePickers) {
            setShowDatePickers(false);
        }
        if (newCustomDateRange) {
            setNewCustomDateRange(null);
        }
        onChange();
    }, [newCustomDateRange, onChange, showDatePickers]);

    useEffect(() => {
        setShowDatePickers(selectedRange !== undefined && !isNamedDateRange(selectedRange));
    }, [selectedRange]);

    const datePickerDisabledMatchers: Matcher[] = [];
    if (minStartDate) {
        datePickerDisabledMatchers.push({before: minStartDate});
    }
    if (maxEndDate) {
        datePickerDisabledMatchers.push({after: maxEndDate});
    }

    const dateFormatter = useDateFormatter();

    const dropdownLabel = () => {
        if (!selectedRange) {
            return t('dateRangePicker:chooseRange');
        } else if (selectedRange && isNamedDateRange(selectedRange)) {
            return t(selectedRange.name);
        } else if (selectedRange.from && selectedRange.to) {
            const fromDateFormat = getYear(selectedRange.from) !== getYear(selectedRange.to)
                ? DateFormatType.DATE_FORMAT : DateFormatType.DATE_FORMAT_WITHOUT_YEAR;
            return `${dateFormatter(selectedRange.from, fromDateFormat)} – ${dateFormatter(selectedRange.to, DateFormatType.DATE_FORMAT)}`;
        }
        return t('dateRangePicker:customRangeLabel');
    }

    const inputId = `${formId}-${name || 'dateRange'}`;

    return <div className="date-range-picker" ref={datePickerRef}>
        {label && <label className="date-range-picker__label" htmlFor={inputId}>{label}</label>}
        <div className="date-range-picker__controls">
            <Dropdown show={showDropdown} onToggle={toggleDropdown} className="date-range-picker">
                <DropdownToggle variant="secondary">{dropdownLabel()}</DropdownToggle>
                <DropdownMenu>
                    <div>
                        <div>
                            {namedRanges && namedRanges.map((namedRange) => {
                                return <DropdownItem key={namedRange.name} onSelect={() => onNamedRangeSelect(namedRange)}>
                                    {t(namedRange.name)}
                                </DropdownItem>
                            })}
                            {!disableCustomRange && <DropdownItem onClick={onCustomRangeSelect}>
                                {t('dateRangePicker:customRangeLabel')}
                            </DropdownItem>}
                            {clearOption && <DropdownItem onSelect={onClear}>
                                {t('dateRangePicker:clearDate')}
                            </DropdownItem>}
                        </div>
                        {showDatePickers && <Fragment>
                            <DayPicker
                                onDayClick={onDayClick}
                                mode="range"
                                selected={newCustomDateRange || actualRange}
                                disabled={datePickerDisabledMatchers}
                                defaultMonth={actualRange && actualRange.from || maxEndDate}
                                fromMonth={minStartDate}
                                toMonth={maxEndDate}
                            />
                            <DayPicker
                                onDayClick={onDayClick}
                                mode="range"
                                selected={newCustomDateRange || actualRange}
                                disabled={datePickerDisabledMatchers}
                                defaultMonth={actualRange && actualRange.to || maxEndDate}
                                fromMonth={minStartDate}
                                toMonth={maxEndDate}
                            />
                        </Fragment>}
                    </div>
                </DropdownMenu>
            </Dropdown>
        </div>
    </div>

}
