import {FC, Fragment, useState} from "react";
import {useTranslation} from "react-i18next";

import {ManualPostDetail} from "@app/Manuals/components/ManualPostDetail/ManualPostDetail";
import {ManualsIndex} from "@app/Manuals/components/ManualsIndex/ManualsIndex";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";

export const SearchTabContent: FC = () => {
    const {t} = useTranslation();

    const [postId, setPostId] = useState<number|null>(null);

    const backLink = <ReturnLink label={t('viewManual:action.goBackToManuals')} onClick={() => setPostId(null)} />

    return <Fragment>
        {!postId && <ManualsIndex onPostSelect={(post) => setPostId(post.id)} />}
        {postId && <Fragment>
            <ManualPostDetail postId={postId} backLink={backLink} />
        </Fragment>}
    </Fragment>
}
