import {Dispatch, FC, ReactElement, SetStateAction} from "react";
import {useTranslation} from "react-i18next";

import {TypedPacketListRequest} from "@app/Packet/components/PacketGrid/callPacketListApi";
import {PacketListManager} from "@app/Packet/model/PacketListManager";
import {PaginatedGridSorter} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/PaginatedGridSorter";
import {
    usePaginatedGridSorter,
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/usePaginatedGridSorter";
import {SortPropertyOfRequest} from "@common/model/requests/SortRequestProperty";

export type PacketGridSetStoredSorting = Dispatch<SetStateAction<SortPropertyOfRequest<TypedPacketListRequest> | null>>;

export type ExtraHeadersRender =
    (setStoredSorting: PacketGridSetStoredSorting) => ReactElement | null;

export type PacketGridHeaderProps = {
    extraHeadersRender?: ExtraHeadersRender;
    gridName: string;
    manager: PacketListManager;
    showAddress?: boolean;
    showShelf?: boolean;
}

export const PacketGridHeader: FC<PacketGridHeaderProps> = ({
    extraHeadersRender,
    gridName,
    manager,
    showAddress,
    showShelf,
}) => {
    const {t} = useTranslation();

    const setStoredSorting = usePaginatedGridSorter(
        manager,
        `ui-${gridName}-grid-sorting`,
        {key: 'dateStored', desc: true},
    );

    if (!manager.hasItems) {
        return null;
    }

    return <thead>
        <tr>
            <th className="packet-grid__barcode">
                {t('packet:property.barcode')}
                <span className="packet-grid__barcode-status">
                    {' / '}
                    <PaginatedGridSorter manager={manager} sortBy="status" onSort={setStoredSorting}>
                        {t('packet:property.status')}
                    </PaginatedGridSorter>
                </span>
            </th>
            <th className="packet-grid__date-stored">
                <PaginatedGridSorter manager={manager} sortBy="dateStored" onSort={setStoredSorting}>
                    {t('packet:property.dateStored.label')}
                </PaginatedGridSorter>
            </th>
            <th className="packet-grid__sender">{t('packet:property.sender')}</th>
            <th className="packet-grid__recipient">
                {t('packet:property.recipient')}
                <span className="packet-grid__recipient-sender">
                    {' / '}{t('packet:property.sender')}
                </span>
            </th>
            {showAddress && <th className="packet-grid__address">{t('packet:property.addressAbbr')}</th>}
            {showShelf && <th>{t('packet:property.shelf.label')}</th>}
            <th className="packet-grid__status">
                <PaginatedGridSorter manager={manager} sortBy="status" onSort={setStoredSorting}>
                    {t('packet:property.status')}
                </PaginatedGridSorter>
            </th>
            {extraHeadersRender ? extraHeadersRender(setStoredSorting) : null}
        </tr>
    </thead>

}
