import {FC, useEffect} from "react";
import {useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    UnknownActionForm as UnknownActionFormModel,
    UnknownActionFormServiceCode
} from "@app/Devices/components/ReaderModal/components/UnknownActionForm/useUnknownActionForm";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {RadioFormGroup} from "@common/components/forms/RadioFormGroup/RadioFormGroup";

export type UnknownActionFormProps = {
    unknownActionForm: UnknownActionFormModel;
}

export const UnknownActionForm: FC<UnknownActionFormProps> = ({unknownActionForm}) => {
    const {t} = useTranslation();
    const {form, onSubmit} = unknownActionForm;
    const serviceCode = useWatch({control: form.control, name: 'serviceCode'});

    useEffect(() => {
        if (serviceCode === UnknownActionFormServiceCode.MANUAL) {
            form.setFocus('manualServiceCode');
        }
    }, [form, serviceCode]);

    const formId = 'unknownActionForm';

    const radioItems: {value: UnknownActionFormServiceCode, label: string}[] = [
        {value: UnknownActionFormServiceCode.SERVICE_CODE_PACKET_RECEIVE, label: 'packetReceival'},
        {value: UnknownActionFormServiceCode.SERVICE_CODE_BRANCH_INVENTORY, label: 'inventory'},
        {value: UnknownActionFormServiceCode.SERVICE_CODE_NEW_EXPEDITION_PARCEL, label: 'parcelExpedition'},
        {value: UnknownActionFormServiceCode.MANUAL, label: 'enterCodeManually'},
    ];

    return <div className="unknown-action-form">
        <h4>{t('reader:serviceCodeSubmission.heading')}</h4>
        <p>{t('reader:serviceCodeSubmission.warning')}</p>
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <RadioFormGroup
                form={form}
                formId={formId}
                items={radioItems.map((item) => ({
                    value: item.value,
                    label: item.value === UnknownActionFormServiceCode.MANUAL
                        ? t(`reader:serviceCodeSubmission.${item.label}`)
                        : `${formatServiceCode(item.value)} ${t('reader:serviceCodeSubmission.serviceCodes.' + item.label)}`,
                }))}
                name="serviceCode"
                registerOptions={{
                    required: t<string>('reader:serviceCodeSubmission.error.required.select'),
                }}
            />
            {serviceCode ===  UnknownActionFormServiceCode.MANUAL && <FormGroup
                form={form}
                name="manualServiceCode"
                formId={formId}
                inputOptions={{
                    placeholder: t('reader:serviceCodeSubmission.enterCode'),
                }}
                registerOptions={{
                    required: t<string>('reader:serviceCodeSubmission.error.required.manual'),
                }}
            />}
        </form>
    </div>
}

const formatServiceCode = (serviceCode: UnknownActionFormServiceCode): string => {
    return serviceCode.replace(/(?=(?:.{3})*$)/g, ' ').trim();
}
