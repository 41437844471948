import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobAction} from "@app/Devices/model/ReaderJobAction";
import {ReaderJobProcessedState} from "@app/Devices/model/ReaderJobProcessedState";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";
import {ReaderProcessResult} from "@app/Devices/model/ReaderProcessResult";

export const shouldUpdateJob = (job: ReaderJob): boolean => {
    if (job.processedState === ReaderJobProcessedState.CANCELLED) {
        return false;
    }

    if (job.type === ReaderJobType.UNKNOWN) {
        return true;
    }

    if (job.actions && job.actions.filter((action) => action !== ReaderJobAction.CLOSE).length > 0) {
        return true;
    }

    if (job.results && hasActionsInResults(job.results)) {
        return true;
    }

    if (job.items) {
        for (const item of job.items) {
            if (item.results && hasActionsInResults(item.results)) {
                return true;
            }

            if (item.packets) {
                for (const packet of item.packets) {
                    if (packet.results && hasActionsInResults(packet.results)) {
                        return true;
                    }
                }
            }
        }
    }

    return false;
}

const hasActionsInResults = (results: ReaderProcessResult[]): boolean => {
    for (const result of results) {
        if (result.actions && result.actions.length > 0) {
            return true;
        }
    }

    return false;
}
