import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {Dropdown, Nav, NavItem} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {useTranslation} from "react-i18next";
import {useLocation} from "react-router-dom";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {ReceivedMessagesIcon} from "@app/Messaging/components/ReceivedMessagesIcon/ReceivedMessagesIcon";
import {ReceivedMessagesRoute} from "@app/Messaging/MessagingRoutes";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {ChangePasswordRoute} from "@app/User/UserRoutes";
import {Img} from "@common/components/Img/Img";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useOnLanguageChanged} from "@common/hooks/useOnLanguageChanged";
import {LanguageSelect} from "@common/layouts/common/LanguageSelect/LanguageSelect";

import './userActions.scss';

export type UserActionsProps = {
    openSupportModal: () => void;
}

export const UserActions: FC<UserActionsProps> = observer(({openSupportModal}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const appNavigate = useAppNavigate();
    const [show, setShow] = useState<boolean>(false);
    const location = useLocation();

    useEffect(() => {
        // close on page change
        setShow(false);
    }, [location]);

    useOnLanguageChanged(() => {
        setShow(false);
    }, []);

    const doStopImpersonating = useCallback(() => {
        void appContext.authorizator.stopImpersonating()
            .then((loggedIn) => {
                if (loggedIn) {
                    appNavigate(DashboardRoute);
                }
            });
    }, [appNavigate, appContext.authorizator]);

    const onHelpClick = useCallback(() => {
        setShow(false);
        openSupportModal();
    }, [openSupportModal]);

    if (!appContext.user.isLoggedIn) {
        return null;
    }

    return <Nav className="user-actions">
        <Dropdown show={show} as={NavItem} className="rounded">
            <DropdownToggle onClick={() => setShow(!show)} variant="secondary" className="user-actions__toggle" data-xid="user-actions">
                {appContext.user.isImpersonated && <Img className="mr-3" src="/images/icons/ico/ico-table-man.svg" alt="user" title={t('global:menu.isImpersonating')} />}
                {appContext.user.email}
            </DropdownToggle>
            <DropdownMenu alignRight={true}>
                <DropdownItem onClick={() => appContext.authorizator.logout(true)} className="nav-link"
                              data-xid="user-actions-logout">
                    <Img src="/images/icons/ico/ico-power-off.svg" alt="power-off"/>{' '}
                    {t('global:menu.signOut')}
                </DropdownItem>
                {appContext.user.isImpersonated && <Fragment>
                    <div className="dropdown-divider"/>
                    <DropdownItem onClick={doStopImpersonating} className="nav-link">
                        <Img src="/images/icons/ico/ico-power-off.svg" alt="power-off"/>{' '}
                        {t('global:menu.stopImpersonating')}
                    </DropdownItem>
                </Fragment>}
                <div className="dropdown-divider"/>
                <DropdownItem as="div">
                    <AppLink to={ReceivedMessagesRoute} className="nav-link">
                        <ReceivedMessagesIcon/>
                        {t('global:menu.messages')}
                    </AppLink>
                </DropdownItem>
                <div className="dropdown-divider"/>
                <DropdownItem onClick={onHelpClick} className="nav-link">
                    <Img src="/images/icons/ico/ico-poradna.svg" alt={t('viewPacketDetail:help.title')}/>{' '}
                    {t('viewPacketDetail:help.title')}
                </DropdownItem>
                <div className="dropdown-divider"/>
                <DropdownItem as="div">
                    <AppLink to={ChangePasswordRoute} className="nav-link">
                        <Img src="/images/icons/ico/ico-user.svg" alt={t('global:menu.changePassword')}/>
                        {t('global:menu.changePassword')}
                    </AppLink>
                </DropdownItem>
                <div className="dropdown-divider"/>
                <DropdownItem className="user-actions__language-toggle nav-link" as="div">
                    <LanguageSelect/>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    </Nav>
})
