import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {CardPaymentError} from "@app/CardPayment/components/CardPaymentError/CardPaymentError";
import {
    CardPaymentReceipts
} from "@app/CardPayment/components/CardPaymentReceipts/CardPaymentReceipts";
import {
    CardPaymentRunning
} from "@app/CardPayment/components/CardPaymentRunning/CardPaymentRunning";
import {
    SignatureVerificationModal
} from "@app/CardPayment/components/SignatureVerificationModal/SignatureVerificationModal";
import {
    usePartnerCardTransactionForm
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionGrid/PartnerCardTransactionForm/usePartnerCardTransactionForm";
import {
    PartnerCardTransactionListManager
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionListManager";
import {Alert} from "@common/components/Alert/Alert";
import {Button} from "@common/components/Button/Button";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";

import './partnerCardTransactionForm.scss';

export type PartnerCardTransactionFormProps = {
    manager: PartnerCardTransactionListManager;
}

export const PartnerCardTransactionForm: FC<PartnerCardTransactionFormProps> = observer(({manager}) => {
    const {t} = useTranslation();
    const {dashboardInfo} = useAppContext();

    const currencyCode = dashboardInfo.cashRegisterCurrency;
    const {
        cancelPayment,
        cardPaymentState,
        confirmSignature,
        finishedTransaction,
        form,
        isUnsuccessful,
        onSubmit,
        paymentRunning,
    } = usePartnerCardTransactionForm(t, currencyCode, manager);

    if (!currencyCode) {
        return null;
    }

    const formId = 'partnerCardTransactionForm';
    const positionSymbol = t(`core:money.parameters.${currencyCode}.position`);
    const currencySymbol = t(`core:money.parameters.${currencyCode}.symbol`);

    return <form className="partner-card-transaction-form" onSubmit={form.handleSubmit(onSubmit)}>
        {isUnsuccessful && <Alert type="error">
            <CardPaymentError cardPaymentState={cardPaymentState} toastInModal={false} />
        </Alert>}
        <div className="partner-card-transaction-form-inputs">
            <div className="partner-card-transaction-form-inputs-amount">
                <div>
                    <FormGroup
                        name="amount"
                        form={form}
                        formId={formId}
                        registerOptions={{
                        required: t<string>('viewPartnerCardTransaction:error.amountRequired'),
                        }}
                        inputOptions={{
                            autoComplete: 'off',
                            disabled: paymentRunning,
                        }}
                        label={t('viewPartnerCardTransaction:property.amount')}
                    />
                </div>
                <div className={`partner-card-transaction-form-inputs-amount-currency-${positionSymbol}`}>
                    {currencySymbol}
                </div>
            </div>
            <div>
                <FormGroup name="reference" form={form} formId={formId}
                   registerOptions={{
                       pattern: {
                           value: /^\d+$/,
                           message: t<string>('viewPartnerCardTransaction:error.referenceMustBeNumber'),
                       },
                   }}
                   inputOptions={{disabled: paymentRunning}}
                   label={t('viewPartnerCardTransaction:property.reference')}
                />
            </div>
        </div>
        <div className="partner-card-transaction-form-card-payment">
            <SubmitFormGroup
                name="submit"
                value={t(`viewPartnerCardTransaction:action.${isUnsuccessful ? 'tryAgain' : 'payByCard'}`)}
                formId={formId}
                inputOptions={{disabled: paymentRunning}}
            />
            {finishedTransaction && <Button onClick={cancelPayment} variant="danger" disabled={paymentRunning} data-xid="cancel-partner-transaction">
                {t('viewPartnerCardTransaction:action.cancelTransaction')}
            </Button>}
            <CardPaymentRunning cardPaymentState={cardPaymentState} cancelPayment={cancelPayment} />
            {finishedTransaction && <CardPaymentReceipts cardPaymentState={cardPaymentState} toastInModal={false} />}
        </div>
        <SignatureVerificationModal
            cardPaymentState={cardPaymentState}
            onSignatureConfirmed={confirmSignature}
            onSignatureRejected={cancelPayment}
        />
    </form>
})
