import {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {ready} from "@app/App/api/probeApi";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {appToast} from "@common/components/Toast/toastOpener";

// the client time can be max 30 seconds different from the API time
const MAX_TOLERABLE_TIME_DIFFERENCE = 30;

export const TimeChecker: FC = () => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    useEffect(() => {
        ready(appContext.api)
            .then((response) => {
                const apiTime = new Date(response.timestamp);
                const clientTime = new Date();
                if ((Math.abs(apiTime.getTime() - clientTime.getTime()) / 1000) > MAX_TOLERABLE_TIME_DIFFERENCE) {
                    appToast.error(t('global:timeSettingsError'));
                }
            })
            .catch(() => {/* do nothing */});
    }, [appContext.api, t])

    return null;
}
