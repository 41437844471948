import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    PartnerCardTransactionListManager
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionListManager";
import {PaginatedGridSorter} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/PaginatedGridSorter";
import {
    usePaginatedGridSorter
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/usePaginatedGridSorter";

export const PARTNER_CARD_TRANSACTION_LIST_SORTING_STORAGE_KEY = 'ui-partnerCardTransactionList-sorting';

export type PartnerCardTransactionGridHeaderProps = {
    manager: PartnerCardTransactionListManager;
}

export const PartnerCardTransactionGridHeader: FC<PartnerCardTransactionGridHeaderProps> = ({manager}) => {
    const {t} = useTranslation();

    const setStoredSorting = usePaginatedGridSorter(
        manager,
        PARTNER_CARD_TRANSACTION_LIST_SORTING_STORAGE_KEY,
        {key: 'createdAt', desc: true},
    );

    if (!manager.hasItems) {
        return null;
    }

    return <thead>
        <tr>
            <th>{t('transaction:property.transactionType')}</th>
            <th><PaginatedGridSorter manager={manager} sortBy="createdAt" onSort={setStoredSorting}>
                {t('transaction:property.createdAt')}
            </PaginatedGridSorter></th>
            <th>{t('transaction:property.amount')}</th>
            <th>{t('viewPartnerCardTransaction:property.reference')}</th>
            <th>{t('transaction:property.result')}</th>
            <th />
        </tr>
    </thead>
}
