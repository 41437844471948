import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";
import {Route, Routes} from "react-router-dom";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {BranchParcelListPage} from "@app/Parcel/pages/BranchParcelListPage/BranchParcelListPage";
import {ParcelDetailPage} from "@app/Parcel/pages/ParcelDetailPage/ParcelDetailPage";
import {ParcelDetailRoute, ParcelListRoute} from "@app/Parcel/ParcelRoutes";
import {RouteParamsProvider} from "@common/components/routing/RouteParamsProvider/RouteParamsProvider";
import {appToast} from "@common/components/Toast/toastOpener";

export const Parcel: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    if (!appContext.user.branchId) {
        appToast.error(t('viewParcel:missingBranchError'));
        return null;
    }

    return <Routes>
        <Route path={ParcelListRoute.path} element={<BranchParcelListPage branchId={appContext.user.branchId} />} />
        <Route path={ParcelDetailRoute.path} element={<RouteParamsProvider
            path={ParcelDetailRoute}
            render={({parcelId}) => (<ParcelDetailPage parcelId={parcelId} />)} />
        } />
    </Routes>
})
