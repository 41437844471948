import {observer} from "mobx-react-lite";
import {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    BranchCommissionListManager
} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionGrid/BranchCommissionListManager";
import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {moneyToExportString} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/moneyToExportString";
import {PaginatedGridExport} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/PaginatedGridExport";
import {
    usePaginatedGridExport
} from "@common/components/grids/PaginatedGrid/PaginatedGridExport/usePaginatedGridExport";
import {LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export type BranchCommissionGridExportProps = {
    manager: BranchCommissionListManager;
}

const COMMISSION_LIST_EXPORT_PAGE_SIZE = 100;
const COMMISSION_LIST_MAX_EXPORT_ITEMS = 1000;

export const BranchCommissionGridExport: FC<BranchCommissionGridExportProps> = observer(({manager}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const paginatedGridExport = usePaginatedGridExport(manager, COMMISSION_LIST_MAX_EXPORT_ITEMS);

    const dateFormatter = useDateFormatter();

    const doExport: LoadingButtonOnClick = useCallback(async (stopLoading) => {
        await paginatedGridExport(
            new BranchCommissionListManager(manager.branchId, appContext.api, COMMISSION_LIST_EXPORT_PAGE_SIZE),
            t('viewCommission:branchCommissionGrid.csvFilename'),
            (commission) => ({
                [t('commission:property.createdAt')]: dateFormatter(commission.createdAt, DateFormatType.TIME_FORMAT),
                [t('commission:property.barcode')]: dashWhenEmpty(
                    commission.barcode && formatPacketBarcode(commission.barcode)
                ),
                [t('commission:property.type')]: commission.type.name,
                [t('commission:property.amount')]: moneyToExportString(commission),
                [t('commission:property.currency')]: t(`core:money.parameters.${commission.currency}.symbol`),
                [t('commission:property.note')]: dashWhenEmpty(commission.note),
            }),
        );

        stopLoading();
    }, [appContext.api, dateFormatter, manager.branchId, paginatedGridExport, t]);

    if (!manager.hasItems) {
        return null;
    }

    return <PaginatedGridExport doExport={doExport} />
})
