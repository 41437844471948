import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {ReaderModal} from "@app/Devices/components/ReaderModal/ReaderModal";
import {useHwInfo} from "@app/PDC/hooks/useHwInfo";
import {InlineLoading} from "@common/components/Loading/InlineLoading/InlineLoading";
import {LoadingButton, LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {
    PrintParcelLabelButton
} from "@common/layouts/SecuredLayout/Header/ReaderBox/PrintParcelLabelButton/PrintParcelLabelButton";

import './readerBox.scss';

export const ReaderBox: FC = observer(() => {
    const {t} = useTranslation();
    const {deviceGatewayResolver} = useAppContext();

    const [readerModalOpened, setReaderModalOpened] = useState<boolean>(false);
    const readerModalStopLoading = useRef<() => void>(() => false);

    const onOpenReaderModal = useCallback<LoadingButtonOnClick>((stopLoading) => {
        setReaderModalOpened(true);
        readerModalStopLoading.current = stopLoading;
    }, []);

    const onHideReaderModal = useCallback(() => {
        setReaderModalOpened(false);
    }, []);

    useEffect(() => {
        if (!deviceGatewayResolver.deviceGateway) {
            void deviceGatewayResolver.resolve();
        }
    }, [deviceGatewayResolver]);

    const {deviceGateway} = deviceGatewayResolver;

    const hwInfo = useHwInfo();
    const readerNotConnected = hwInfo !== null && (!hwInfo.barcode_reader || !hwInfo.pdc_online);

    return <div className="reader-box">
        <div className="reader-box__content">
            <InlineLoading
                active={deviceGateway === null}
                className={classNames(
                    'reader-box__device-type',
                    hwInfo && `reader-box__device-type_pdc-${hwInfo.pdc_online ? 'online' : 'offline'}`
                )}
                title={hwInfo
                    ? t(`global:printerReader.device.${hwInfo.pdc_online ? 'online' : 'offline'}`)
                    : undefined
                }
                data-xid="reader-name"
            >
                {deviceGateway && deviceGateway.deviceGatewayType}
            </InlineLoading>
            <div className="reader-box__printer">
                <PrintParcelLabelButton />
            </div>
            <div className="reader-box__reader">
                <LoadingButton
                    onLoadingClick={onOpenReaderModal}
                    disabled={readerNotConnected}
                    title={hwInfo
                        ? t(`global:printerReader.title.${readerNotConnected ? 'disabled' : 'enabled'}`)
                        : undefined
                    }
                    data-xid="process-reader"
                >
                    {t('global:printerReader.reader')}
                </LoadingButton>
            </div>
        </div>
        <ReaderModal
            show={readerModalOpened}
            onHide={onHideReaderModal}
            onLoaded={readerModalStopLoading.current}
        />
    </div>
})
