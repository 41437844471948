import {observer} from "mobx-react-lite";
import {Fragment, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    PacketExpeditionRequiredBranchEventModal
} from "@app/BranchEvent/components/PacketExpeditionRequiredBranchEvent/PacketExpeditionRequiredBranchEventModal";
import {useBranchEventMessage} from "@app/BranchEvent/hooks/useBranchEventMessage";
import {PacketExpeditionRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventComponent} from "@app/BranchEvent/model/BranchEventComponent";
import {printPacketExpeditions} from "@app/Print/api/printApi";
import {Button} from "@common/components/Button/Button";
import {LoadingButton, LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {usePrint} from "@common/hooks/usePrint";

export const PacketExpeditionRequiredBranchEventContent: BranchEventComponent<PacketExpeditionRequiredBranchEvent> = observer(({event}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const handlePrint = usePrint();

    const [modalOpened, setModalOpened] = useState(false);
    const message = useBranchEventMessage(event);

    const printExpedition: LoadingButtonOnClick = useCallback((stopLoading) => {
        void handlePrint(printPacketExpeditions(appContext.api))
            .finally(() => {
                stopLoading();
            });
    }, [appContext.api, handlePrint]);

    const branchId = appContext.user.branchId;
    if (!branchId) {
        return null;
    }

    return <Fragment>
        <Button onClick={() => setModalOpened(true)} variant="link">
            {message}
        </Button>
        <LoadingButton onLoadingClick={printExpedition}>
            {t('global:print.action.expedition')}
        </LoadingButton>
        {modalOpened && <PacketExpeditionRequiredBranchEventModal
            event={event}
            onClose={() => setModalOpened(false)}
        />}
    </Fragment>
})
