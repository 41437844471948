import {TFunction} from "i18next";
import {MutableRefObject, useCallback, useState} from "react";
import {UseFormReturn} from "react-hook-form";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {createCommissionInvoice} from "@app/Commission/api/commissionApi";
import {CommissionBranch} from "@app/Commission/model/CommissionBranch";
import {CommissionInvoiceItem} from "@app/Commission/model/CommissionInvoiceItem";
import {
    NewInvoiceFormData
} from "@app/Commission/pages/NewInvoicePage/NewInvoiceForm/NewInvoiceFormData";
import {
    useHandleCreateCommissionInvoiceError
} from "@app/Commission/pages/NewInvoicePage/NewInvoiceForm/useHandleCreateCommissionInvoiceError";
import {appToast} from "@common/components/Toast/toastOpener";
import {compareAmounts} from "@common/utils/compareAmounts";
import {normalizeDecimalSeparator} from "@common/utils/normalizeDecimalSeparator";

export const useNewInvoiceFormSubmit = (
    form: UseFormReturn<NewInvoiceFormData>,
    commissionBranchesMap: MutableRefObject<Map<string, CommissionBranch>>,
    onInvoiced: () => void,
    t: TFunction,
) => {
    const appContext = useAppContext();
    const handleCreateCommissionInvoiceError = useHandleCreateCommissionInvoiceError();
    const [loading, setLoading] = useState<boolean>(false);

    const submit = useCallback((data: NewInvoiceFormData) => {
        const branchesCommissions: CommissionInvoiceItem[] = [];
        let firstBranchCommissionKey: string|undefined;

        for (const branchCommission in data.branchesCommissions) {
            if (Object.prototype.hasOwnProperty.call(data.branchesCommissions, branchCommission)) {
                if (firstBranchCommissionKey === undefined) {
                    firstBranchCommissionKey = branchCommission;
                }

                const normalizedAmount = normalizeDecimalSeparator(data.branchesCommissions[branchCommission]);

                const commissionBranch = commissionBranchesMap.current.get(branchCommission);
                if (commissionBranch && compareAmounts(normalizedAmount, '0') === 1) {
                    branchesCommissions.push({
                        amount: normalizedAmount,
                        currency: commissionBranch.currency,
                        branchId: commissionBranch.branchId,
                        commissionType: commissionBranch.commissionType,
                    });
                }
            }
        }

        if (branchesCommissions.length === 0) {
            appToast.error(t('viewCommission:newInvoice.form.branchesCommissions.required'));
            if (firstBranchCommissionKey) {
                form.setFocus(`branchesCommissions.${firstBranchCommissionKey}`);
            }
            return;
        }

        setLoading(true);
        createCommissionInvoice({
            variableSymbol: data.variableSymbol || (appContext.user.supportBranchId || 0).toString(10).padStart(10, '0'),
            issuedDate: data.issuedDate,
            invoiceNumber: data.invoiceNumber,
            branchesCommissions,
        }, appContext.api)
            .then(() => {
                appToast.success(t('viewCommission:newInvoice.success'));
                onInvoiced();
                commissionBranchesMap.current.clear();
                form.reset();
            })
            .catch(handleCreateCommissionInvoiceError)
            .finally(() => {
                setLoading(false);
            })
    }, [appContext.user.supportBranchId, appContext.api, handleCreateCommissionInvoiceError, commissionBranchesMap, t, form, onInvoiced]);

    return {
        submit,
        loading,
    }
}
