import {Api} from "@app/AppContext/classes/Api/Api";
import {User} from "@app/AppContext/classes/User/model/User";
import {branchInventoryList} from "@app/Inventory/api/inventoryApi";
import {Inventory} from "@app/Inventory/model/Inventory";
import {BranchInventoryListRequest} from "@app/Inventory/model/InventoryRequestsAndResponses";
import {actualDateRange, SelectedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {dateRangeToFilterRequestParameter} from "@common/utils/api/dateRangeToFilterRequestParameter";
import {extractEmbedded} from "@common/utils/extractEmbedded";

export class BranchInventoryListManager extends SortablePaginatedDataManager<
    Inventory,
    BranchInventoryListRequest
> {
    public users: Map<string, User> = new Map<string, User>();

    constructor(branchId: string, api: Api) {
        super(
            (request) => branchInventoryList(request, api),
            {branchId, embed: [Embeddable.USER]},
        );

        this.processResponse.push((response) => {
            this.users = extractEmbedded(response, Embeddable.USER);
        });
    }

    public setRange = (selectedRange: SelectedDateRange): void => {
        const actualRange = actualDateRange(selectedRange);

        this.setFilteringRequest({
            createdAt: dateRangeToFilterRequestParameter(actualRange),
        });
    }
}
