import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {deliver as deliverApi} from "@app/Packet/api/packetApi";
import {
    useAssertPacketCanBeDelivered
} from "@app/Packet/components/PacketDelivery/hooks/useAssertPacketCanBeDelivered";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {DeliveryPacketVerification} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketVerification";
import {PacketIdVerification} from "@app/Packet/components/PacketDelivery/model/PacketIdVerification";
import {
    PacketNeedsIdVerificationError
} from "@app/Packet/components/PacketDelivery/model/PacketNeedsIdVerificationError";
import {createDeliverRequest} from "@app/Packet/components/PacketDelivery/utils/createDeliverRequest";
import {PacketDeliveryType} from "@app/Packet/model/PacketDeliveryType";

export type Deliver = (packet: DeliveryPacket, verification: DeliveryPacketVerification) => Promise<void>;

export const useDeliver = (
    onDelivered: (packet: DeliveryPacket) => void,
    packetIdVerification?: PacketIdVerification|null,
): Deliver => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const assertPacketCanBeDelivered = useAssertPacketCanBeDelivered();

    return useCallback(async (packet: DeliveryPacket, verification: DeliveryPacketVerification): Promise<void> => {
        try {
            assertPacketCanBeDelivered(packet.info, verification);
        } catch (error: unknown) {
            if (error instanceof PacketNeedsIdVerificationError) {
                if (packetIdVerification) {
                    verification = {type: PacketDeliveryType.PERSONAL_ID, packetIdVerification};
                } else {
                    packet.requireIdVerification();
                    onDelivered(packet);
                    return;
                }
            } else {
                throw error;
            }
        }

        await deliverApi(createDeliverRequest(packet, verification, t), appContext.api);
        packet.deliver(verification);
        onDelivered(packet);
    }, [appContext.api, assertPacketCanBeDelivered, onDelivered, packetIdVerification, t]);
}
