import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {Transaction} from "@app/Transaction/model/Transaction";
import {TransactionAuthState} from "@app/Transaction/model/TransactionAuthState";
import {Img} from "@common/components/Img/Img";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './transactionGridStateCell.scss';

export type TransactionGridStateCellProps = {
    transaction: Transaction;
}

export const TransactionGridStateCell: FC<TransactionGridStateCellProps> = ({transaction}) => {
    const {t} = useTranslation();

    return <td className="transaction-grid-state-cell">
        {dashWhenEmpty(transaction.authState && <div className="transaction-grid-state-cell__state">
            {transaction.authState === TransactionAuthState.APPROVED && <Img
                src="/images/icons/ico/ico-check.svg"
                alt={t('transaction:value.result.approved')}
                title={t('transaction:value.result.approved')}
            />}
            {transaction.authState === TransactionAuthState.WAITING && <Img
                src="/images/icons/ico/ic_hourglass_empty_24px.svg"
                alt={t('transaction:value.result.waiting')}
                title={t('transaction:value.result.waiting')}
            />}
            {transaction.authState === TransactionAuthState.REJECTED && <Fragment>
                <Img
                    src="/images/icons/ico/ico-exclamation-red.svg"
                    alt={t('transaction:value.result.rejected')}
                    title={t('transaction:value.result.rejected')}
                />{' '}
                {t('transaction:value.result.rejected')}
            </Fragment>}
        </div>)}
        {transaction.note && <div className="transaction-grid-state-cell__note">{transaction.note}</div>}
    </td>
}
