import {Fragment} from "react";
import {useTranslation} from "react-i18next";

import {useBranchEventMessage} from "@app/BranchEvent/hooks/useBranchEventMessage";
import {useBranchEventTranslationKey} from "@app/BranchEvent/hooks/useBranchEventTranslationKey";
import {PasswordChangeRequiredBranchEvent} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventComponent} from "@app/BranchEvent/model/BranchEventComponent";
import {ChangePasswordRoute} from "@app/User/UserRoutes";
import {AppLink} from "@common/components/routing/AppLink/AppLink";

export const PasswordChangeRequiredBranchEventContent: BranchEventComponent<PasswordChangeRequiredBranchEvent> = ({event}) => {

    const {t} = useTranslation();

    const translationKey = useBranchEventTranslationKey(event);
    const message = useBranchEventMessage(event);

    return <Fragment>
        {message}{' '}
        <AppLink to={ChangePasswordRoute} variant="primary">
            {t(`${translationKey}Change`)}
        </AppLink>
    </Fragment>
}
