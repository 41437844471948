import {ParametricReactRoute, RootReactRoute} from "@common/model/ReactRoute";

export const DashboardRoute: RootReactRoute = {
    path: '',
    index: true,
}

export const PacketDetailRoute: ParametricReactRoute<'barcode'> = {
    path: ':barcode',
    paramNames: ['barcode'],
}

export const PacketRoutes = [
    DashboardRoute,
    PacketDetailRoute,
];
