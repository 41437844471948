import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Parcel} from "@app/Parcel/model/Parcel";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";

export type ParcelCountProps = {
    parcel: Parcel;
}

export const ParcelMissingInfo: FC<ParcelCountProps> = ({parcel}) => {
    const {t} = useTranslation();
    const dateFormatter = useDateFormatter();

    const receivedOrConfirmedAt = parcel.receivedAt || parcel.contentConfirmedAt;

    if (parcel.missingPacketCount === 0) {
        return null;
    }

    return <div className="branch-parcel-list__count-missing">(
        {receivedOrConfirmedAt !== null && t(
            'parcel:property.packetCount.missingOutOfWithDate',
            {
                date: dateFormatter(receivedOrConfirmedAt, DateFormatType.DATE_FORMAT_WITHOUT_YEAR),
                missingPacketCount: parcel.missingPacketCount,
                totalCount: parcel.packetCount,
            }
        )}
        {receivedOrConfirmedAt === null && t(
            'parcel:property.packetCount.missingOutOf',
            {
                missingPacketCount: parcel.missingPacketCount,
                totalCount: parcel.packetCount,
            }

        )}
    )</div>
}
