import {observer} from "mobx-react-lite";
import {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {
    ManagedCashRegisterExportForm
} from "@app/Transaction/pages/ManagedCashRegisterPage/ManagedCashRegisterExportForm/ManagedCashRegisterExportForm";
import {
    ManagedCashRegisterGrid
} from "@app/Transaction/pages/ManagedCashRegisterPage/ManagedCashRegisterGrid/ManagedCashRegisterGrid";
import {BranchCashRegisterRoute} from "@app/Transaction/TransactionModuleRoutes";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useSingleBranchId} from "@common/hooks/useSingleBranchId";

import './managedCashRegisterPage.scss';

export const ManagedCashRegisterPage: FC = observer(() => {
    const {t} = useTranslation();
    const branchId = useSingleBranchId();
    const navigate = useAppNavigate();

    useEffect(() => {
        if (branchId) {
            navigate({route: BranchCashRegisterRoute, params: {branchId}});
        }
    }, [branchId, navigate]);

    if (branchId) {
        return null;
    }

    return <PageContent className="managed-cash-register-page">
        <PageHeader subTitle={t('viewTransaction:managedCashRegister.subTitle')}>
            {t('viewTransaction:managedCashRegister.header')}
        </PageHeader>
        <ManagedCashRegisterGrid />
        <ManagedCashRegisterExportForm />
    </PageContent>
})
