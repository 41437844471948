import {FC} from "react";
import {useTranslation} from "react-i18next";

import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {PacketWithPresenceMeta} from "@app/Packet/model/PacketWithPresenceMeta";
import {Button} from "@common/components/Button/Button";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";

export type NoAnswerModalProps = {
    packet: PacketWithPresenceMeta;
    onHide: () => void;
}

export const NoAnswerModal: FC<NoAnswerModalProps> = ({onHide, packet}) => {
    const {t} = useTranslation();
    const dateFormatter = useDateFormatter();

    return <BaseModal
        show={true}
        onHide={onHide}
        header={t('viewPacketPresenceReview:noAnswerPopup.title')}
        footer={<Button variant="secondary" onClick={onHide}>{t('viewPacketToDispatchPopup:action.cancel')}</Button>}
    >
        <GridTable>
            <thead>
                <tr>
                    <th className="text-left border-0">{t('packet:property.barcode')}</th>
                    <th className="text-left border-0">{t('packet:property.number')}</th>
                    <th className="text-left border-0">{t('packet:property.recipientNameAlt')}</th>
                    <th className="text-left border-0">{t('packet:property.recipientSurnameAlt')}</th>
                    <th className="text-left border-0">{t('packet:property.shelf.label')}</th>
                    <th className="text-left border-0">{t('viewPacketPresenceReview:table.billOfDelivery')}</th>
                </tr>
            </thead>
            <tbody>
                <tr key={packet.barcode}>
                    <td><FormattedPacketBarcode barcode={packet.barcode} /></td>
                    <td>{packet.number}</td>
                    <td>{packet.name}</td>
                    <td>{packet.surname}</td>
                    <td>{packet.shelf}</td>
                    <td>{packet.presenceMeta.deliveryNote}</td>
                </tr>
            </tbody>
        </GridTable>
        <p><strong>{t('viewPacketPresenceReview:noAnswerPopup.content.pleaseCheck')}</strong></p>
        {packet.presenceMeta.viewedAt && <p>{t(
            'viewPacketPresenceReview:noAnswerPopup.content.windowOpenedInfo',
            {date: dateFormatter(packet.presenceMeta.viewedAt, DateFormatType.DATE_FORMAT)}
        )}</p>}
        <p>{t('viewPacketPresenceReview:noAnswerPopup.content.confirmationInfoText')}</p>
        <ul>
            <li>{t('viewPacketPresenceReview:noAnswerPopup.content.confirmationInfoPoint1')}</li>
            <li>{t('viewPacketPresenceReview:noAnswerPopup.content.confirmationInfoPoint2')}</li>
        </ul>
        <p>{t('viewPacketPresenceReview:noAnswerPopup.content.instructions')}</p>
    </BaseModal>
}
