import {FC, useEffect} from "react";
import {useParams, useSearchParams} from "react-router-dom";

import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {isSimpleReactRoute, To} from "@common/model/ReactRoute";

export type NavigateWithQueryParamsProps = {
    to: To;
}

export const NavigateWithQueryParams: FC<NavigateWithQueryParamsProps> = ({to}) => {
    const params = useParams();
    const [search] = useSearchParams();
    const navigate = useAppNavigate();

    useEffect(() => {
        navigate({
            route: isSimpleReactRoute(to) ? to : to.route,
            params,
            search,
        });
    }, [navigate, params, search, to]);

    return null;
}
