import {useCallback, useEffect, useState} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {route} from "@app/MicroDepot/api/microDepotApi";
import {MicroDepotRoute} from "@app/MicroDepot/model/MicroDepotRoute";
import {PasswordHandlingComponent} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {FinalizeTab} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/RouteFinalizationModal/FinalizeTab/FinalizeTab";
import {PacketsTab} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/RouteFinalizationModal/PacketsTab/PacketsTab";
import {SearchFormResult} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './routeFinalizationModal.scss';

type RouteFinalizationModalState = {
    modalOpened: boolean;
    route: MicroDepotRoute|null;
}

export const RouteFinalizationModal: PasswordHandlingComponent = ({
    onError,
    onFinish,
    onVerified,
    password,
}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [state, setState] = useState<RouteFinalizationModalState>({
        modalOpened: false,
        route: null,
    });
    const showAppErrorToast = useShowErrorToast(appToast, 'viewRouteFinalization:error');

    useEffect(() => {
        route(password, appContext.api)
            .then((response) => {
                onVerified(password);
                setState({
                    modalOpened: true,
                    route: response.route,
                });
            })
            .catch((error: Error) => {
                onError(password, () => {
                    showAppErrorToast(error);
                });
            })
    }, [appContext.api, onError, onVerified, password, showAppErrorToast]);

    const close = useCallback(() => {
        setState({
            modalOpened: false,
            route: null,
        });
        onFinish(SearchFormResult.ROUTE_FINALIZATION);
    }, [onFinish]);

    if (!state.route) {
        return null;
    }

    return <BaseModal
        show={state.modalOpened}
        onHide={close}
        header={t('viewRouteFinalization:title')}
        className="route-finalization-modal"
        height="auto"
    >
        <Tabs defaultActiveKey="finalize">
            <Tab eventKey="finalize" title={t('viewRouteFinalization:tab.finalize')}>
                <FinalizeTab password={password} routeSummary={state.route.summary} onFinish={close} />
            </Tab>
            <Tab eventKey="list" title={t('viewRouteFinalization:tab.routePacketList')}>
                <PacketsTab routeSummary={state.route.summary} packets={state.route.packets.items} />
            </Tab>
        </Tabs>
    </BaseModal>
}
