import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {StarBranchQualityItem} from "@app/Branch/model/BranchQuality/BranchQualityItem";
import {Img} from "@common/components/Img/Img";
import {NumberFormatter} from "@common/components/NumberFormatter/NumberFormatter";

import './starRating.scss';

export type StarRatingProps = {
    qualityItem: StarBranchQualityItem|null;
}

const STARS_COUNT = 5;

export const StarRating: FC<StarRatingProps> = ({qualityItem}) => {
    const {t} = useTranslation();

    const fillWidth = qualityItem ? Math.round(parseInt(qualityItem.value, 10) / STARS_COUNT * 100) : 0;
    const background = `linear-gradient(to right, #ffc107 ${fillWidth}%, #adb5bd 0%)`;

    return <div className="star-rating">
        {qualityItem && <Fragment>
            <div className="star-rating__value">
                <NumberFormatter numeric={qualityItem.value} decimalScale={1} fixedDecimalScale={true} />
            </div>
            <div className="star-rating__stars" style={{background}}>
                <Img src="/images/icons/ico/star-rating.svg" alt="star"/>
                <Img src="/images/icons/ico/star-rating.svg" alt="star"/>
                <Img src="/images/icons/ico/star-rating.svg" alt="star"/>
                <Img src="/images/icons/ico/star-rating.svg" alt="star"/>
                <Img src="/images/icons/ico/star-rating.svg" alt="star"/>
            </div>
        </Fragment>}
        {!qualityItem && t('viewBranchQuality:customerFeedback.notAvailable')}
    </div>
}
