// Intl polyfills
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/cs';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/hu';
import '@formatjs/intl-pluralrules/locale-data/ro';
import '@formatjs/intl-pluralrules/locale-data/sk';
import '@formatjs/intl-pluralrules/locale-data/vi';
import '@formatjs/intl-numberformat/polyfill';
import '@formatjs/intl-numberformat/locale-data/cs';
import '@formatjs/intl-numberformat/locale-data/en';
import '@formatjs/intl-numberformat/locale-data/hu';
import '@formatjs/intl-numberformat/locale-data/ro';
import '@formatjs/intl-numberformat/locale-data/sk';
import '@formatjs/intl-numberformat/locale-data/vi';

import i18next, {i18n} from "i18next";
import I18nextBrowserLanguageDetector from "i18next-browser-languagedetector";
import ICU from "i18next-icu";
import {initReactI18next} from "react-i18next";

import {AvmI18NextBackend} from "@app/App/components/AppWrapper/I18Provider/AvmI18NextBackend";
import {appConfig} from "@app/config";

const languageDetector = new I18nextBrowserLanguageDetector();
languageDetector.addDetector({
    name: 'defaultLanguage',
    lookup: () => appConfig.defaultLng,
});
languageDetector.addDetector({
    name: 'normalizedNavigator',
    lookup: () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
        const navigatorDetected = languageDetector.detectors.navigator.lookup() as string[] | undefined;
        if (!navigatorDetected) {
            return navigatorDetected;
        }

        const shortLanguageCodeMap = new Map<string, string>();
        shortLanguageCodeMap.set('en', 'en-GB');
        shortLanguageCodeMap.set('cs', 'cs-CZ');
        shortLanguageCodeMap.set('hu', 'hu-HU');
        shortLanguageCodeMap.set('ro', 'ro-RO');
        shortLanguageCodeMap.set('sk', 'sk-SK');
        shortLanguageCodeMap.set('vi', 'vi-VN');
        // E2E tests language detection workaround
        shortLanguageCodeMap.set('en-US', 'en-GB');

        return navigatorDetected.map((language) => {
            return shortLanguageCodeMap.get(language) || language;
        })
    }
})

export const LOCAL_STORAGE_LANGUAGE_KEY = 'language';
export const COOKIE_LANGUAGE_KEY = 'language';

let i18nInstance: i18n | null = null;

export const i18nFactory = (translationNamespaces: string|string[], release: string): i18n => {
    if (!i18nInstance) {
        i18nInstance = i18next.createInstance();

        void i18nInstance
            .use(ICU)
            .use(new AvmI18NextBackend({release, namespaces: translationNamespaces}))
            .use(languageDetector)
            .use(initReactI18next)
            .init({
                backend: {
                    release,
                },
                supportedLngs: appConfig.languages,
                detection: {
                    order: ['cookie', 'localStorage', 'normalizedNavigator', 'defaultLanguage'],
                    lookupCookie: COOKIE_LANGUAGE_KEY,
                    lookupLocalStorage: LOCAL_STORAGE_LANGUAGE_KEY,
                    caches: ['cookie', 'localStorage'],
                    cookieMinutes: 40320, // 28 days cookie expiration
                },
                react: {
                    useSuspense: true,
                },
                load: 'currentOnly',
                ns: translationNamespaces,
                defaultNS: Array.isArray(translationNamespaces) ? translationNamespaces[0] : translationNamespaces,
                keySeparator: false,
                debug: false,
                fallbackLng: appConfig.fallbackLng,
                interpolation: {
                    escapeValue: false,
                }
            });
    }

    return i18nInstance;
}
