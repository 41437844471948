import {TFunction} from "i18next";

import {getStringVariable} from "@app/Devices/components/ReaderModal/utils/getVariable";
import {ReaderItemResult} from "@app/Devices/model/ReaderItemResult";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";

export const jobItemParcelTitle = (item: ReaderItemResult, job: ReaderJob, t: TFunction): string|null => {
    const parcelBarcode = getStringVariable(item.parcelData, 'parcelBarcode');

    if (parcelBarcode) {
        return t('parcel:reader.parcelNumber', {parcelBarcode});
    }

    if ([ReaderJobType.PACKETS_TO_PARCELS, ReaderJobType.RECEIVE_PARCELS].includes(job.type)) {
        return t('parcel:reader.newParcel');
    }

    return null;
}
