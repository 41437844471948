import {useCallback} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {DeliveryPacketVerification} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketVerification";
import {
    PacketNeedsIdVerificationError
} from "@app/Packet/components/PacketDelivery/model/PacketNeedsIdVerificationError";
import {
    PacketWithDelayedDeliveryError
} from "@app/Packet/components/PacketDelivery/model/PacketWithDelayedDeliveryError";
import {BasePacket} from "@app/Packet/model/BasePacket";
import {PacketDeliveryType} from "@app/Packet/model/PacketDeliveryType";
import {isPacketDetailed} from "@app/Packet/model/PacketDetailed";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {useFeatureFlag} from "@common/hooks/useFeatureFlag";
import {TranslatableError} from "@common/model/errors/TranslatableError";
import {parseDate} from "@common/utils/parseDate";

export const useAssertPacketCanBeDelivered = () => {
    const {dashboardInfo} = useAppContext();
    const dateFormatter = useDateFormatter();
    const checkIsAtUsersBranch = useFeatureFlag('packetDeliveryIsAtUsersBranchCheck', true);

    return useCallback((packet: BasePacket, verification?: DeliveryPacketVerification) => {
        if (dashboardInfo.loaded && !dashboardInfo.hasCashRegister) {
            throw new Error('Trying to deliver a packet without a cash register!');
        }

        if (isPacketDetailed(packet) && dashboardInfo.loaded && dashboardInfo.cashRegisterCurrency !== packet.currency) {
            throw new Error('Packet and cash register currency mismatch!');
        }

        if (checkIsAtUsersBranch && !packet.isAtUsersBranch) {
            throw new Error('Trying to deliver packet that is not at users branch!');
        }

        if (packet.isClaimAssistant) {
            throw new Error('Trying to deliver packet that is a claim assistant');
        }

        if (packet.status.id === PacketStatusId.DELIVERED) {
            if (verification && verification.type === PacketDeliveryType.PASSWORD) {
                throw new TranslatableError('packet:error.alreadyDelivered', {password: verification.password});
            } else {
                throw new TranslatableError('packet:error.alreadyDeliveredBarcode', {barcode: formatPacketBarcode(packet.barcode)}, true);
            }
        } else if (packet.status.id !== PacketStatusId.PICKUP_READY) {
            throw new TranslatableError('packet:error.notInPickupReadyState');
        }

        if (packet.deliverOn && parseDate(packet.deliverOn) > new Date()) {
            throw new PacketWithDelayedDeliveryError(
                'packet:error.canNotDeliverYetWithDate',
                {date: dateFormatter(packet.deliverOn, DateFormatType.DATE_FORMAT)},
                true
            );
        }

        if (packet.adultContent && (!verification || verification.type !== PacketDeliveryType.PERSONAL_ID)) {
            throw new PacketNeedsIdVerificationError();
        }

    }, [checkIsAtUsersBranch, dashboardInfo.cashRegisterCurrency, dashboardInfo.hasCashRegister, dashboardInfo.loaded, dateFormatter]);
}
