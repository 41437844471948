import {sub} from "date-fns";
import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {
    BranchCommissionListFilter,
} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionGrid/BranchCommissionListManager";
import {
    TransactionTypeSelect
} from "@app/Transaction/components/TransactionGrid/TransactionGridFilter/CommissionTypeSelect/TransactionTypeSelect";
import {
    TransactionListManager, TransactionListType
} from "@app/Transaction/components/TransactionGrid/TransactionListManager";
import {DateRangePicker} from "@common/components/forms/DateRangePicker/DateRangePicker";
import {
    GeneralDateRangePickerRanges,
    useGeneralDateRangePickerRanges
} from "@common/components/forms/DateRangePicker/useGeneralDateRangePickerRanges";
import {FormResetLink} from "@common/components/forms/FormResetLink/FormResetLink";
import {Loading} from "@common/components/Loading/Loading";
import {useFixFiltersDuration} from "@common/hooks/useFixFiltersDuration";
import {useLocalStorageState} from "@common/hooks/useLocalStorageState";

import './transactionGridFilter.scss';

export type TransactionGridFilterProps = {
    listType: TransactionListType;
    manager: TransactionListManager;
}

export const TRANSACTION_GRID_FILTER_STORAGE_KEY = 'ui-transactionList-filter';
export const TRANSACTION_MAX_RANGE: Duration = {months: 24};

export const TransactionGridFilter: FC<TransactionGridFilterProps> = observer(({listType, manager}) => {
    const {t} = useTranslation();
    const namedRanges = useGeneralDateRangePickerRanges();

    const defaultFilter = useMemo(() => ({
        createdAt: namedRanges[GeneralDateRangePickerRanges.LAST_MONTH]
    }), [namedRanges]);

    const [storedFilter, setStoredFilter] = useLocalStorageState<BranchCommissionListFilter>(
        `${TRANSACTION_GRID_FILTER_STORAGE_KEY}-${manager.listType}`,
        defaultFilter,
    );

    const updateFilter = useCallback((newFilter: BranchCommissionListFilter) => {
        setStoredFilter(newFilter);
        manager.setFilter(newFilter);
    }, [manager, setStoredFilter]);

    const onReset = useCallback(() => {
        updateFilter(defaultFilter);
    }, [defaultFilter, updateFilter]);

    const onTypeSelect = useCallback((typeId: string|null) => {
        if (typeId === null) {
            const {typeId: _, ...filter} = storedFilter;
            updateFilter(filter);
        } else {
            updateFilter({...storedFilter, typeId});
        }
    }, [storedFilter, updateFilter]);

    // TODO: remove in release >70.2.0
    const fixFiltersDuration = useFixFiltersDuration();

    useEffect(() => {
        if (!manager.hasFilter) {
            const fixedRange = fixFiltersDuration(storedFilter.createdAt);
            if (fixedRange) {
                updateFilter({
                    ...storedFilter,
                    createdAt: fixedRange,
                });
            } else {
                manager.setFilter(storedFilter);
            }
        }
    }, [fixFiltersDuration, manager, storedFilter, updateFilter]);

    return <Loading active={manager.isLoading} className="transaction-grid-filter">
        <TransactionTypeSelect listType={listType} onSelect={onTypeSelect} value={storedFilter.typeId} />
        <DateRangePicker
            label={t('viewTransaction:transactionGrid.filter.createdAt')}
            selectedRange={storedFilter.createdAt}
            clearOption={true}
            formId="transactionGridFilter"
            namedRanges={namedRanges}
            minStartDate={sub(new Date(), TRANSACTION_MAX_RANGE)}
            maxEndDate={new Date()}
            onChange={(createdAt) => updateFilter({...storedFilter, createdAt})}
        />
        <FormResetLink onReset={onReset} />
    </Loading>
})
