import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {ApiErrorItem} from "@common/model/errors/ApiErrorItem";
import {EndpointError} from "@common/model/errors/EndpointError";
import {HttpStatusCode} from "@common/model/HttpStatusCode";

export class ApiError<ItemType extends ApiErrorItem = ApiErrorItem> extends EndpointError {
    constructor(public code: HttpStatusCode, public errors: ItemType[], public endpoint?: Endpoint) {
        let message: string;

        if (errors.length > 0) {
            message = errors[0].text;
        } else if ([
            HttpStatusCode.FORBIDDEN,
            HttpStatusCode.NOT_FOUND,
            HttpStatusCode.METHOD_NOT_ALLOWED,
            HttpStatusCode.CONFLICT,
            HttpStatusCode.GONE,
            HttpStatusCode.INTERNAL_SERVER_ERROR,
            HttpStatusCode.SERVICE_UNAVAILABLE,
        ].includes(code)) {
            message = `errors:${code}.description`;
        } else if (code < HttpStatusCode.INTERNAL_SERVER_ERROR) {
            message = 'errors:4xx.description';
        } else if (code === HttpStatusCode.SERVICE_UNAVAILABLE) {
            message = 'errors:503.description';
        } else {
            message = 'errors:500.description';
        }

        super(
            message,
            endpoint,
            [HttpStatusCode.INTERNAL_SERVER_ERROR, HttpStatusCode.SERVICE_UNAVAILABLE].includes(code)
        );
    }
}
