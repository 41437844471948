import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    TransactionListManager, TransactionListType
} from "@app/Transaction/components/TransactionGrid/TransactionListManager";
import {PaginatedGridSorter} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/PaginatedGridSorter";
import {
    usePaginatedGridSorter
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/usePaginatedGridSorter";

export const TRANSACTION_LIST_SORTING_STORAGE_KEY = 'ui-transactionList-sorting';

export type TransactionGridHeaderProps = {
    listType: TransactionListType;
    manager: TransactionListManager;
    showCreatedBy: boolean;
}

export const TransactionGridHeader: FC<TransactionGridHeaderProps> = ({listType, manager, showCreatedBy}) => {
    const {t} = useTranslation();

    const setStoredSorting = usePaginatedGridSorter(
        manager,
        `${TRANSACTION_LIST_SORTING_STORAGE_KEY}-${listType}`,
        {key: 'createdAt', desc: true},
    );

    if (!manager.hasItems) {
        return null;
    }

    return <thead>
        <tr>
            <th>
                <PaginatedGridSorter manager={manager} sortBy="createdAt" onSort={setStoredSorting}>
                    {t('transaction:property.createdAt')}
                </PaginatedGridSorter>
                <div className="transaction-grid__created-at-barcode">
                    {' / '}{t('transaction:property.packetBarcode')}
                </div>
            </th>
            <th className="transaction-grid__barcode">{t('transaction:property.packetBarcode')}</th>
            <th>
                {t('transaction:property.amount')}
                <div className="transaction-grid__amount-type">
                    <PaginatedGridSorter manager={manager} sortBy="typeId" onSort={setStoredSorting}>
                        {t('transaction:property.transactionType')}
                    </PaginatedGridSorter>
                </div>
            </th>
            <th className="transaction-grid__type">
                <PaginatedGridSorter manager={manager} sortBy="typeId" onSort={setStoredSorting}>
                    {t('transaction:property.transactionType')}
                </PaginatedGridSorter>
                {showCreatedBy && <div className="transaction-grid__type-created-by">
                    <PaginatedGridSorter manager={manager} sortBy="createdBy" onSort={setStoredSorting}>
                        {t('transaction:property.createdBy')}
                    </PaginatedGridSorter>
                </div>}
            </th>
            {showCreatedBy && <th className="transaction-grid__created-by">
                <PaginatedGridSorter manager={manager} sortBy="createdBy" onSort={setStoredSorting}>
                    {t('transaction:property.createdBy')}
                </PaginatedGridSorter>
            </th>}
            <th className="transaction-grid__note">{t('transaction:property.note')}</th>
            <th>{t('transaction:property.result')}</th>
        </tr>
    </thead>
}
