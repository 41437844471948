import {FC, useEffect} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {ZPointBranch} from "@app/Branch/model/ZPointBranch";
import {CountrySelect} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageRecipients/CountrySelect/CountrySelect";
import {useBranchSearchModal} from "@app/Messaging/components/NewMessage/OutgoingMessageForm/MessageRecipients/OneRecipientSelect/BranchSearchModal/useBranchSearchModal";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

import './branchSearchModal.scss';

export type BranchSearchModalProps = {
    show: boolean;
    onHide: () => void;
    onBranchFound: (branch: ZPointBranch) => void;
}

export const BranchSearchModal: FC<BranchSearchModalProps> = ({onBranchFound, onHide, show}) => {
    const {t} = useTranslation();

    const {form, onSubmit, searchState} = useBranchSearchModal();

    useEffect(() => {
        if (show) {
            form.setFocus('searchStr');
        }
    }, [form, show]);

    if (!show) {
        return null;
    }

    const formId = 'branchSearchModal';

    return <BaseModal
        className="branch-search-modal"
        show={show}
        onHide={onHide}
        loading={searchState.searching}
        header={t('message:branchSearch.title')}
        size="lg"
    >
        <form id={formId} onSubmit={onSubmit} onChange={onSubmit}>
            <CountrySelect
                name="region"
                label={t('message:branchSearch.region')}
                form={form}
                formId={formId}
                placeholder={{value: '', label: t('global:ui.selectPrompt')}}
                placeholderDisabled={false}
            />
            <div className="form-group">
                <input
                    {...form.register('searchStr')}
                    type="text"
                    className="form-control"
                    placeholder={t('message:branchSearch.searchStr')}
                />
            </div>
            <p className="small">{t('message:branchSearch.helpText')}</p>
        </form>
        {searchState.branches.length > 0 && <ul className="branches-list">
            {searchState.branches.map((branch) => {
                return <li key={branch.id}>
                    <Button onClick={() => onBranchFound(branch)}>{t('message:branchSearch.select')}</Button>
                    {branch.name}
                </li>
            })}
        </ul>}
    </BaseModal>
}
