import classNames from "classnames";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    ReaderJobResultActions
} from "@app/Devices/components/ReaderModal/components/ReaderJobResults/ReaderJobResultActions/ReaderJobResultActions";
import {jobResultTitle} from "@app/Devices/components/ReaderModal/utils/jobResultTitle";
import {ReaderJobTitleType} from "@app/Devices/components/ReaderModal/utils/resolveSubSection";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderProcessResult} from "@app/Devices/model/ReaderProcessResult";
import {useDateFormatter} from "@common/hooks/useDateFormatter";

import './readerJobResults.scss';

export type ReaderJobResultsProps = {
    results: ReaderProcessResult[]|null|undefined;
    job: ReaderJob;
    titleType?: ReaderJobTitleType;
    withActions?: boolean;
}

export const ReaderJobResults: FC<ReaderJobResultsProps> = ({
    job,
    results,
    titleType,
    withActions = true,
}) => {
    const {t} = useTranslation();
    const dateFormatter = useDateFormatter();

    if (!results) {
        return null;
    }

    return <div className="reader-job-results">
        {results.map((result, index) => <div
            className={classNames('reader-job-results__result', `reader-job-results__result-${result.type}`)}
            key={`${result.type}-${result.code || ''}-${index}`}
        >
            {jobResultTitle(result, job, titleType, dateFormatter, t)}
            {withActions && <ReaderJobResultActions result={result} />}
        </div>)}
    </div>

}
