import {add, isSameDay, max, min, startOfDay} from "date-fns";
import {useCallback, useMemo} from "react";

import {useOpeningHours} from "@app/OpeningHours/context/useOpeningHours";
import {parseDate} from "@common/utils/parseDate";

export type OpeningHoursLimits = {
    firstSelectableDate: Date;
    maxExceptionDate: Date;
    maxExceptionEndDate: (startDate: Date) => Date;
    minExceptionStartDate: (endDate: Date) => Date;
}

const MAX_EXCEPTION_DATE_MONTHS = 3;
const MAX_EXCEPTION_DURATION_DAYS = 40;
const FIRST_SELECTABLE_DATE_BUSINESS_DAYS_SHIFT = 3;

export const useOpeningHoursLimits = (): OpeningHoursLimits => {
    const today = new Date();

    const {branchHours} = useOpeningHours();

    const publicHolidays = useMemo<Array<Date>>(() => branchHours.publicHolidaysOnly
            ? branchHours.publicHolidaysOnly.effective.map((hours) => parseDate(hours.date))
            : [],
    [branchHours.publicHolidaysOnly]);

    const getNearestBusinessDay = useCallback((date: Date, shift: number = 1, step: -1|1 = 1): Date => {
        const isBusinessDay = ![0,6].includes(date.getDay()) && !publicHolidays.find((publicHoliday) => isSameDay(publicHoliday, date));
        if (shift === 0 && isBusinessDay) {
            return date;
        } else {
            return getNearestBusinessDay(
                add(date, {days: step}),
                isBusinessDay ? --shift : shift,
                step
            );
        }
    }, [publicHolidays]);

    const firstSelectableDate = useMemo<Date>(() => {
        return startOfDay(getNearestBusinessDay(new Date(), FIRST_SELECTABLE_DATE_BUSINESS_DAYS_SHIFT));
    }, [getNearestBusinessDay]);

    const maxExceptionDate = add(today, {months: MAX_EXCEPTION_DATE_MONTHS});

    const addBusinessDays = useCallback((date: Date, days: number, step: -1|1): Date => {
        if (days === 0) {
            return date;
        }
        return addBusinessDays(getNearestBusinessDay(date, 1, step), --days, step);
    }, [getNearestBusinessDay]);

    const maxExceptionEndDate = (startDate: Date) => min([
        add(addBusinessDays(startDate, MAX_EXCEPTION_DURATION_DAYS, 1), {days: -1}),
        maxExceptionDate,
    ]);

    const minExceptionStartDate = (endDate: Date) => max([
        add(addBusinessDays(endDate, MAX_EXCEPTION_DURATION_DAYS, -1), {days: 1}),
        firstSelectableDate,
    ]);

    return {
        firstSelectableDate,
        maxExceptionDate,
        maxExceptionEndDate,
        minExceptionStartDate,
    }
}
