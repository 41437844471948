import {SearchTypeRequest} from "@app/Packet/model/SearchRequestsAndResponses";
import {SearchTypeType} from "@app/Packet/model/SearchType";
import {isBarcode} from "@common/utils/isBarcode";
import {isEmail} from "@common/utils/isEmail";

export const offlineSearchType = (request: SearchTypeRequest): SearchTypeType | null => {
    const password = request.subject.toUpperCase();

    if (isBarcode(password)) {
        return SearchTypeType.BARCODE;
    }

    if (isEmail(password)) {
        return SearchTypeType.EMAIL;
    }

    switch (password.length) {
        case 5:
            return SearchTypeType.DELIVERY_PASSWORD;
        case 6:
            switch (password.slice(0, 1)) {
                case 'T':
                    return SearchTypeType.TRANSACTION;
                case 'R':
                    return SearchTypeType.RETURN_PASSWORD;
                case 'C':
                case 'B':
                    return null; // c2c and b2c passwords must be handled through the API
                case 'S':
                    return SearchTypeType.SURPRISE_CONSIGN_PASSWORD;
                case 'F':
                    return SearchTypeType.FINALIZATION_ROUTE_PASSWORD;
                case 'P':
                    return SearchTypeType.CLAIM_FROM_CUSTOMER_PASSWORD;
                case 'H':
                    return SearchTypeType.DELIVERY_PASSWORD;
                case 'U':
                    return SearchTypeType.RETURN_DESTINATION_TO_PICKUP_POINT_PASSWORD;
                default:
                    return null;
            }
        case 7:
            switch (password.slice(0, 1)) {
                case '1':
                    return SearchTypeType.DELIVERY_PASSWORD;
                case '9':
                    return SearchTypeType.SURPRISE_CODE;
                default:
                    return null;
            }
        case 8:
            switch (password.slice(0, 1)) {
                case '9':
                    return SearchTypeType.SURPRISE_CODE;
                default:
                    return null;
            }
        case 9:
            return SearchTypeType.TRANSACTION;
        default:
            return null;
    }

}
