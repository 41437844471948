import {add, differenceInSeconds} from "date-fns";
import {FC, useCallback, useEffect, useRef, useState} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {PriorityEventsAndMessagesView} from "@common/layouts/SecuredLayout/PriorityEventsAndMessages/PriorityEventsAndMessagesView/PriorityEventsAndMessagesView";

const SHOW_INTERVAL: Duration = {hours: 2};
const PRIORITY_EVENTS_NEXT_OPEN_STORAGE_KEY = 'ui-priorityEventsAndMessages-nextOpenAt';

export const PriorityEventsAndMessages: FC = () => {
    const {userSpecificUI} = useAppContext();

    const nextOpeningTimeoutRef = useRef<number|null>(null);

    const nextOpenAt = userSpecificUI.create<Date|null>(
        PRIORITY_EVENTS_NEXT_OPEN_STORAGE_KEY,
        null
    );

    const [isOpened, setIsOpened] = useState<boolean>(false);

    const scheduleNextOpening = useCallback((openAt: Date) => {
        nextOpeningTimeoutRef.current = window.setTimeout(() => {
            setIsOpened(true);
        }, differenceInSeconds(openAt, new Date()) * 1000);
    }, []);

    const finish = useCallback(() => {
        const nextOpenDate = add(new Date(), SHOW_INTERVAL);
        userSpecificUI.persist(
            PRIORITY_EVENTS_NEXT_OPEN_STORAGE_KEY,
            nextOpenDate,
        );
        setIsOpened(false);
        scheduleNextOpening(nextOpenDate);
    }, [scheduleNextOpening, userSpecificUI])

    useEffect(() => {
        if (!nextOpenAt || nextOpenAt <= new Date()) {
            setIsOpened(true);
        } else {
            scheduleNextOpening(nextOpenAt);
        }
    }, [nextOpenAt, scheduleNextOpening]);

    useEffect(() => {
        return () => {
            if (nextOpeningTimeoutRef.current) {
                window.clearTimeout(nextOpeningTimeoutRef.current);
            }
        }
    }, []);

    if (!isOpened) {
        return null;
    }

    return <PriorityEventsAndMessagesView onFinish={finish} />
}
