import {Api} from "@app/AppContext/classes/Api/Api";
import {pdcEndpoint, pdcHeadEndpoint} from "@app/PDC/api/pdcEndpoints";
import {
    PDCDeviceIdRequest,
    PDCDeviceIdResponse, PDCServerVersionRequest, PDCServerVersionResponse, SystemNotifyRequest, SystemNotifyResponse,
} from "@app/PDC/model/PDCServerRequestsAndResponses";
import {SystemNotifyMessageType} from "@app/PDC/model/SystemNotifyMessageType";
import {randomNumber} from "@common/utils/randomNumber";

const PDC_CONNECTION_TIMEOUT = 2000;

export const isPdcAvailable = async (api: Api): Promise<boolean> => {
    return api.callWithTimeout({
        endpoint: pdcHeadEndpoint,
        customHeaders: {},
    }, PDC_CONNECTION_TIMEOUT);
}

export const pdcDeviceId = async (api: Api): Promise<PDCDeviceIdResponse> => {
    return api.callWithTimeout<PDCDeviceIdResponse, PDCDeviceIdRequest>({
        endpoint: pdcEndpoint,
        payload: {service: 'ZpiConnector', action: 'getDeviceId'},
        customHeaders: {},
    }, PDC_CONNECTION_TIMEOUT);
}

export const pdcVersion = async (api: Api): Promise<PDCServerVersionResponse> => {
    return api.callWithTimeout<PDCServerVersionResponse, PDCServerVersionRequest>({
        endpoint: pdcEndpoint,
        payload: {service: 'ServerService', action: 'getVersion'},
        customHeaders: {},
    }, PDC_CONNECTION_TIMEOUT);
}

export const systemNotify = async (text: string, messageType: SystemNotifyMessageType, api: Api): Promise<SystemNotifyResponse> => {
    return api.callWithTimeout<SystemNotifyResponse, SystemNotifyRequest>({
        endpoint: pdcEndpoint,
        payload: {service: 'Notify', action: 'showNotify', messageType, text, id: randomNumber(1, 1000)},
        customHeaders: {},
    }, PDC_CONNECTION_TIMEOUT);
}
