import {ParametricReactRoute, SimpleReactRoute} from "@common/model/ReactRoute";

export const ParcelListRoute: SimpleReactRoute = {
    path: '',
}

export const ParcelDetailRoute: ParametricReactRoute<'parcelId'> = {
    path: ':parcelId',
    paramNames: ['parcelId'],
}

export const ParcelRoutes = [
    ParcelListRoute,
    ParcelDetailRoute,
];
