import {ApiBackend} from "@app/AppContext/classes/Api/model/ApiBackend";
import {ApiRequestWatcher, RunningRequest} from "@app/AppContext/classes/Api/model/ApiRequestWatcher";
import {checkConnection} from "@common/utils/api/checkConnection";

const OFFLINE_TIMEOUT = 60 * 1000; // 1 minute timeout

export class OfflineNoticeAzApiRequestWatcher extends ApiRequestWatcher {
    private requestTimeouts: Map<RunningRequest, number> = new Map();

    public constructor(private onOffline: () => void) {
        super();
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    public async onRequestStart(request: RunningRequest): Promise<void> {
        this.requestTimeouts.set(request, window.setTimeout(async () => {
            if (!await checkConnection()) {
                this.onOffline();
            }
        }, OFFLINE_TIMEOUT));
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    public async onRequestSuccess(request: RunningRequest): Promise<void> {
        this.clearTimeout(request);
    }

    public async onRequestError(request: RunningRequest, error: unknown): Promise<void> {
        this.clearTimeout(request);

        if (error instanceof TypeError && !await checkConnection()) {
            this.onOffline();
        }
    }

    public onUnregister() {
        for (const [_request, timeout] of this.requestTimeouts) {
            window.clearTimeout(timeout);
        }
    }

    public requiresWatch(request: RunningRequest): boolean {
        return request.endpoint.backend !== ApiBackend.PDC;
    }

    private clearTimeout(request: RunningRequest): void {
        const timeout = this.requestTimeouts.get(request);
        if (timeout) {
            window.clearTimeout(timeout);
            this.requestTimeouts.delete(request);
        }
    }
}
