import {FC, KeyboardEvent, useCallback, useEffect, useRef} from "react";
import {UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {IdCardType, PacketIdVerification} from "@app/Packet/components/PacketDelivery/model/PacketIdVerification";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {Select} from "@common/components/forms/Select/Select";
import {packetWholeName} from "@common/utils/packetWholeName";
import {idCardTypeLabel} from "@packetModal/components/PacketIdVerificationModal/idCardTypeLabel";

import './packetIdVerificationForm.scss';

export type PacketIdVerificationFormProps = {
    focus: boolean;
    form: UseFormReturn<PacketIdVerification>;
    onSubmit: (newPacketIdVerification: PacketIdVerification) => void;
    packet: DeliveryPacket;
}

const NAME_MIN_LENGTH = 5;
const CARD_NUMBER_REQUIRED_LENGTH = 4;

export const PacketIdVerificationForm: FC<PacketIdVerificationFormProps> = ({focus, form, onSubmit, packet}) => {
    const {t} = useTranslation();

    const initialized = useRef<boolean>(false);

    useEffect(() => {
        if (!initialized.current) {
            form.resetField('name', {defaultValue: packetWholeName(packet.info)});
            initialized.current = true;
        }
    }, [form, packet]);

    const focused = useRef<boolean>(false);
    useEffect(() => {
        if (focus && !focused.current) {
            if (form.getValues('name') === '') {
                form.setFocus('name');
            } else if (form.getValues('idCardNumber') === '') {
                form.setFocus('idCardNumber');
            }
        }
        focused.current = focus;
    }, [focus, form]);

    const onKeyPress = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            void form.handleSubmit(onSubmit)(event);
        }
    }, [form, onSubmit]);

    const nameInputRef = useRef<HTMLInputElement|null>(null);
    const onNameInputFocused = useCallback(() => {
        if (nameInputRef.current) {
            nameInputRef.current.select();
        }
    }, []);

    const formId = 'packetIdVerificationForm';

    return <form className="packet-id-verification-form" onSubmit={form.handleSubmit(onSubmit)} data-xid="packet-id-verification-form">
        <div className="packet-id-verification-form-inputs">
            <div>
                <Select
                    form={form}
                    name="idCardType"
                    label={t('viewPacketDelivery:packetIdVerification.form.idCardType.label')}
                    options={[
                        {value: IdCardType.IDENTITY_CARD, label: t(idCardTypeLabel(IdCardType.IDENTITY_CARD))},
                        {value: IdCardType.DRIVER_LICENSE, label: t(idCardTypeLabel(IdCardType.DRIVER_LICENSE))},
                        {value: IdCardType.PASSPORT, label: t(idCardTypeLabel(IdCardType.PASSPORT))},
                    ]}
                    registerOptions={{
                        required: t<string>('viewPacketDelivery:packetIdVerification.form.idCardType.required'),
                    }}
                    formId={formId}
                />
            </div>
            <div>
                <FormGroup
                    form={form}
                    name="name"
                    label={t('viewPacketDelivery:packetIdVerification.form.name.label')}
                    inputRef={nameInputRef}
                    registerOptions={{
                        validate: {
                            required: (value: string) => {
                                return value.trim().length >= NAME_MIN_LENGTH
                                    || t<string>('viewPacketDelivery:packetIdVerification.form.name.required');
                            }
                        },
                    }}
                    inputOptions={{
                        onKeyPress,
                        onFocus: onNameInputFocused,
                    }}
                    formId={formId}
                />
            </div>
            <div>
                <FormGroup
                    form={form}
                    name="idCardNumber"
                    label={t('viewPacketDelivery:packetIdVerification.form.idCardNumber.label')}
                    registerOptions={{
                        validate: {
                            required: (value: string) => {
                                return (value.trim().length === CARD_NUMBER_REQUIRED_LENGTH)
                                    || t<string>('viewPacketDelivery:packetIdVerification.form.idCardNumber.required');
                            }
                        },
                    }}
                    inputOptions={{
                        onKeyPress,
                        maxLength: CARD_NUMBER_REQUIRED_LENGTH,
                    }}
                    formId={formId}
                />
            </div>
        </div>
    </form>
}
