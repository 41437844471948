import useSize from "@react-hook/size";
import classNames from "classnames";
import {FC, Fragment, useCallback, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {Branch} from "@app/Branch/model/Branch";
import {downloadAttachment} from "@app/Messaging/api/messagingApi";
import {MessageAction} from "@app/Messaging/components/Message/MessageAction/MessageAction";
import {MessageListType} from "@app/Messaging/components/MessagesList/MessageListType";
import {ReceivedMessagesListManager} from "@app/Messaging/managers/ReceivedMessagesListManager";
import {SentMessagesListManager} from "@app/Messaging/managers/SentMessagesListManager";
import {isIncoming, isRead, Message as MessageModel, sanitizedMessageContent} from "@app/Messaging/model/Message";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {Img} from "@common/components/Img/Img";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {formatBytes} from "@common/utils/formatBytes";

export type MessageProps = {
    message: MessageModel;
    messagesManager: ReceivedMessagesListManager|SentMessagesListManager;
    branches: Map<string, Branch>;
    type: MessageListType;
}

const MAX_NOT_COLLAPSIBLE_HEIGHT = 90;

export const Message: FC<MessageProps> = ({branches, message, messagesManager, type}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [collapsible, setCollapsible] = useState<boolean>(false);

    const contentRef = useRef(null);
    const [,contentHeight] = useSize(contentRef);

    const messageUnread = !isRead(message);
    useEffect(() => {
        if (!collapsible && contentHeight > MAX_NOT_COLLAPSIBLE_HEIGHT) {
            if (type !== "received" || !messageUnread) {
                setCollapsible(true);
                setCollapsed(true);
            }
        }
    }, [collapsible, contentHeight, messageUnread, type]);

    const toggleCollapse = useCallback(() => {
        if (collapsible) {
            setCollapsed(!collapsed);
        }
    }, [collapsible, collapsed]);

    const showAppErrorToast = useShowErrorToast(appToast);

    const onDownload = useCallback(() => {
        if (message.attachment) {
            appContext.api.download(downloadAttachment(message.id), message.attachment.filename)
                .catch((error: Error) => {
                    showAppErrorToast(error);
                });
        }
    }, [appContext.api, message.attachment, message.id, showAppErrorToast]);

    const embeddedBranch = isIncoming(message) && branches.has(message.senderBranchId)
        ? branches.get(message.senderBranchId) : undefined;

    return <div className={classNames('message rounded', {
        'message--unread': messageUnread,
        'message--collapsed': collapsed,
    })}>
        <a className="message-subject" onClick={toggleCollapse}>
            {embeddedBranch && <Fragment>{embeddedBranch.name}{' '}&ndash;{' '}</Fragment>}
            {message.subject}
        </a>
        <div className="text-muted small"><DateFormatter date={message.createdAt} showTime showDateDescription /></div>
        <div className="message-content" ref={contentRef}>
            <div dangerouslySetInnerHTML={{__html: sanitizedMessageContent(message.content, t)}} />
            {message.attachment && <div
                className="message-attachment"
                onClick={onDownload}
                title={t('message:tabs.card.action.downloadAttachment')}
            >
                <Img
                    className="message-attachment-icon"
                    src="/images/icons/ico/ico-attachment.svg"
                    alt={t('message:tabs.card.action.downloadAttachment')}
                />{' '}
                {message.attachment.filename}{` (${formatBytes(message.attachment.size, 0)})`}
            </div>}
        </div>
        {collapsible && <p className="message-read-more"><a onClick={toggleCollapse} >
            {t(`message:tabs.card.action.read${collapsed ? 'More' : 'Less'}`)}
        </a></p>}
        <MessageAction message={message} messagesManager={messagesManager} type={type} />
    </div>
}
