import {DetailedHTMLProps, InputHTMLAttributes, useEffect, useRef} from "react";
import {UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    PasswordVerificationFormData
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PasswordVerificationForm/usePasswordVerificationForm";
import {MIN_PASSWORD_LENGTH} from "@app/Packet/pages/DashboardPage/SearchForm/searchFormConstants";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {FCWithChildren} from "@common/model/FCWithChildren";

export type PasswordVerificationFormProps = {
    focus: boolean;
    form: UseFormReturn<PasswordVerificationFormData>;
    inputOptions?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    label?: string;
    onSubmit: (data: PasswordVerificationFormData) => void;
    submitOnChange?: boolean;
}

export const PasswordVerificationForm: FCWithChildren<PasswordVerificationFormProps> = ({
    children,
    focus,
    form,
    inputOptions,
    label,
    onSubmit,
    submitOnChange,
}) => {
    const {t} = useTranslation();

    const focused = useRef<boolean>(false);
    useEffect(() => {
        if (focus && !focused.current) {
            window.setTimeout(() => {
                form.setFocus('password');
            }, 0);
        }
        focused.current = focus;
    }, [focus, form]);

    const formId = 'passwordVerificationForm';

    return <form
        onSubmit={form.handleSubmit(onSubmit)}
        onChange={submitOnChange ? form.handleSubmit(onSubmit) : undefined}
        className="password-verification-form"
        data-xid="password-verification-form"
    >
        <FormGroup
            form={form}
            name="password"
            formId={formId}
            label={label || t<string>('viewPacketDelivery:passwordVerificationForm.password.label')}
            inputOptions={{
                ...inputOptions,
            }}
            registerOptions={{
                required: t<string>('viewPacketDelivery:passwordVerificationForm.password.error.required'),
                minLength: !submitOnChange ? {
                    value: MIN_PASSWORD_LENGTH,
                    message: t<string>('viewPacketDelivery:passwordVerificationForm.password.error.minLength', {minLength: MIN_PASSWORD_LENGTH}),
                } : undefined,
            }}
        />
        {children}
    </form>
}
