import {FC, useEffect} from "react";
import {UseFormReturn, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {DeliveryReason, ReasonVerification} from "@app/Packet/components/PacketDelivery/model/ReasonVerification";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";

import './reasonVerificationForm.scss';

export type ReasonVerificationFormProps = {
    form: UseFormReturn<ReasonVerification>;
    onSubmit: (newReasonVerification: ReasonVerification) => void;
}

const OTHER_DELIVERY_REASON_MIN_LENGTH = 3;

export const ReasonVerificationForm: FC<ReasonVerificationFormProps> = ({form, onSubmit}) => {
    const {t} = useTranslation();

    const formId = 'reasonVerificationForm';

    const selectedReason = useWatch({control: form.control, name: 'reason'});

    useEffect(() => {
        if (selectedReason === DeliveryReason.OTHER) {
            form.setFocus('otherReasonDescription');
        }
    }, [form, selectedReason]);

    return <form className="reason-verification-form" onSubmit={form.handleSubmit(onSubmit)} data-xid="reason-verification-form">
        <div className="reason-verification-form__heading">{t('viewPacketDelivery:reasonVerificationForm.heading')}</div>
        <div className="reason-verification-form__subheading">{t('viewPacketDelivery:reasonVerificationForm.subheading')}</div>
        <div>
            <FormGroup
                form={form}
                name="reason"
                type="radio"
                label={t('viewPacketDelivery:reasonVerificationForm.options.manual')}
                inputId={`${formId}-${DeliveryReason.MANUAL}`}
                inputOptions={{value: DeliveryReason.MANUAL}}
                registerOptions={{
                    required: t<string>('viewPacketDelivery:reasonVerificationForm.error.required'),
                }}
                showErrors={false}
            />
            <FormGroup
                form={form}
                name="reason"
                type="radio"
                label={t('viewPacketDelivery:reasonVerificationForm.options.assistance')}
                inputId={`${formId}-${DeliveryReason.ASSISTANCE}`}
                inputOptions={{value: DeliveryReason.ASSISTANCE}}
                showErrors={false}
            />
            <FormGroup
                form={form}
                name="reason"
                type="radio"
                label={t('viewPacketDelivery:reasonVerificationForm.options.other.label')}
                inputId={`${formId}-${DeliveryReason.OTHER}`}
                inputOptions={{value: DeliveryReason.OTHER}}
            />
            {selectedReason === DeliveryReason.OTHER && <FormGroup
                form={form}
                name="otherReasonDescription"
                formId={formId}
                inputOptions={{
                    placeholder: t('viewPacketDelivery:reasonVerificationForm.options.other.placeholder'),
                }}
                registerOptions={{
                    validate: {
                        required: (value: string) => {
                            return value.trim().length >= OTHER_DELIVERY_REASON_MIN_LENGTH
                                || t<string>('viewPacketDelivery:reasonVerificationForm.options.other.error.required');
                        }
                    },
                }}
            />}
        </div>
    </form>
}
