import {FC, Fragment, useCallback, useState} from "react";
import {Button, Dropdown} from "react-bootstrap";
import DropdownItem from "react-bootstrap/DropdownItem";
import DropdownMenu from "react-bootstrap/DropdownMenu";
import DropdownToggle from "react-bootstrap/DropdownToggle";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DeliveryPacket, CodDeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {printPacketLabels} from "@app/Print/api/printApi";
import {PrintableTypes} from "@app/Print/model/PrintRequestsAndResponses";
import {InlineLoading} from "@common/components/Loading/InlineLoading/InlineLoading";
import {usePrint} from "@common/hooks/usePrint";

export type PrintButtonsProps = {
    packets: DeliveryPacket[];
    disabled: boolean;
}

export const PrintButtons: FC<PrintButtonsProps> = ({disabled, packets}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [confirmationPrintLoading, setConfirmationPrintLoading] = useState<boolean>(false);
    const [locationPrintLoading, setLocationPrintLoading] = useState<boolean>(false);
    const handlePrint = usePrint(true);

    const codPackets = packets.filter((packet): packet is CodDeliveryPacket => packet.isCod);

    const printLocation = useCallback(() => {
        if (packets.length === 0) {
            return;
        }

        setLocationPrintLoading(true);
        void handlePrint(printPacketLabels({
            barcodes: packets.map((packet) => packet.info.barcode),
            type: PrintableTypes.SHELF
        }, appContext.api))
            .finally(() => {
                setLocationPrintLoading(false);
            });
    }, [packets, handlePrint, appContext.api]);

    const printDeliveryReceipt = useCallback(() => {
        if (packets.length === 0) {
            return;
        }

        setConfirmationPrintLoading(true);
        void handlePrint(printPacketLabels({
            barcodes: packets.map((packet) => packet.info.barcode),
            type: PrintableTypes.DELIVERY_RECEIPT
        }, appContext.api))
            .finally(() => {
                setConfirmationPrintLoading(false);
            });
    }, [packets, handlePrint, appContext.api]);

    const printCodReceipt = useCallback(() => {
        if (codPackets.length === 0) {
            return;
        }

        setConfirmationPrintLoading(true);
        void handlePrint(printPacketLabels({
            barcodes: codPackets.map((packet) => packet.info.barcode),
            type: PrintableTypes.COD_RECEIPT
        }, appContext.api))
            .finally(() => {
                setConfirmationPrintLoading(false);
            });
    }, [appContext.api, codPackets, handlePrint]);

    return <Fragment>
        {codPackets.length > 0 && <Dropdown>
            <DropdownToggle variant="secondary" disabled={disabled} data-xid="confirmation-print">
                <InlineLoading active={confirmationPrintLoading}>{t('packet:action.confirmationPrint')}</InlineLoading>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem onClick={printDeliveryReceipt}>{t('packet:action.handoverPrint')}</DropdownItem>
                <DropdownItem onClick={printCodReceipt}>{t('packet:action.codPrint')}</DropdownItem>
            </DropdownMenu>
        </Dropdown>}
        {codPackets.length === 0 && <Button variant="secondary" onClick={printDeliveryReceipt} disabled={disabled} data-xid="handover-print">
            <InlineLoading active={confirmationPrintLoading}>{t('packet:action.handoverPrint')}</InlineLoading>
        </Button>}
        <Button variant="secondary" onClick={printLocation} disabled={disabled} data-xid="location-print">
            <InlineLoading active={locationPrintLoading}>{t('packet:action.locationPrint')}</InlineLoading>
        </Button>
    </Fragment>

}
