import {add, differenceInCalendarDays, formatRFC3339} from "date-fns";

import {Api} from "@app/AppContext/classes/Api/Api";
import {
    branchHoursRangeEndpoint, createBranchHoursExceptionsEndpoint,
    createRegularHoursChangeEndpoint, regularBranchHoursEndpoint
} from "@app/OpeningHours/api/branchHoursEndpoints";
import {
    BranchHoursRangeResponse,
    CreateBranchHoursExceptionsRequest,
    DeleteBranchHoursException,
    RegularHoursResponse, RegularHoursChangeRequest
} from "@app/OpeningHours/model/OpeningHoursRequestsAndResponses";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";

export const branchHoursRange = async (from: Date, to: Date, api: Api): Promise<BranchHoursRangeResponse> => {
    return api.call({
        endpoint: branchHoursRangeEndpoint,
        queryParams: [
            {name: 'from', value: formatRFC3339(from)},
            {name: 'to', value: formatRFC3339(to)},
        ],
    });
}

export const createBranchHoursExceptions = async (request: CreateBranchHoursExceptionsRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: createBranchHoursExceptionsEndpoint,
        payload: request,
    });
}

export const deleteBranchHoursExceptions = async (startDate: Date, endDate: Date|undefined, api: Api): Promise<GeneralSuccessResponseContent> => {
    let date: Date = startDate;
    const deletedExceptions: DeleteBranchHoursException[] = endDate ? [...Array(differenceInCalendarDays(endDate, startDate) + 1).keys()]
        .map(() => {
            const deletedClosedDay: DeleteBranchHoursException = {date, action: 'delete'};
            date = add(date, {days: 1});
            return deletedClosedDay;
        }) : [{date: startDate, action: 'delete'}];

    return createBranchHoursExceptions({branchHours: deletedExceptions}, api);
}

export const regularBranchHours = async (from: Date|undefined, api: Api): Promise<RegularHoursResponse> => {
    return api.call({
        endpoint: regularBranchHoursEndpoint,
        queryParams: from ? [{name: 'from', value: formatRFC3339(from)}] : undefined,
    });
}

export const createRegularHoursChange = async (request: RegularHoursChangeRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: createRegularHoursChangeEndpoint,
        payload: request,
    });
}

export const deleteRegularHoursChange = async (date: Date, api: Api): Promise<GeneralSuccessResponseContent> => {
    return createRegularHoursChange({branchHours: [{date, action: 'delete'}]}, api);
}
