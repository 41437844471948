import classNames from "classnames";
import {FC, useCallback, useEffect, useRef} from "react";
import {FieldError, get, useFormContext, useFormState} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {OpeningHoursBlock} from "@app/OpeningHours/model/OpeningHoursBlock";
import {BaseOpeningHoursGridForm} from "@app/OpeningHours/OpeningHoursFormTypes";
import {CenteredCell} from "@common/components/grids/CenteredCell/CenteredCell";

export type BlockEditCellProps = {
    blockIndex: number;
    className?: string;
    colSpan?: number;
    highlightExceptions?: boolean;
    hoursBlock: OpeningHoursBlock;
    regularHoursBlock?: OpeningHoursBlock;
    rowIndex: number;
}

export const BlockEditCell: FC<BlockEditCellProps> = ({
    blockIndex,
    className,
    colSpan,
    highlightExceptions = false,
    hoursBlock,
    regularHoursBlock,
    rowIndex
}) => {
    const {t} = useTranslation();
    const {control, getValues, register, trigger, watch} = useFormContext<BaseOpeningHoursGridForm>();
    const formState = useFormState({control});

    const openFieldName = `openingHours.${rowIndex}.hours.${blockIndex}.open` as const;
    const closeFieldName = `openingHours.${rowIndex}.hours.${blockIndex}.close` as const;

    const openError: FieldError|null|undefined = get(formState.errors, openFieldName) as FieldError;
    const closeError: FieldError|null|undefined = get(formState.errors, closeFieldName) as FieldError;

    const validateOpenEqualOrGreaterThanClose = useCallback((): boolean|string => {
        const open = getValues(openFieldName);
        const close = getValues(closeFieldName);
        return (open === '' && close === '')
            || (open !== '' && open < close)
            || t<string>('openingHours:error.openEqualOrGreaterThanClose');
    }, [closeFieldName, openFieldName, getValues, t]);

    const validateRequiredIfHigherBlockNotEmpty = useCallback((): boolean|string => {
        if (blockIndex === 2) {
            return true;
        }

        const higherBlockOpen = getValues(`openingHours.${rowIndex}.hours.${blockIndex + 1}.open` as const);
        const higherBlockClose = getValues(`openingHours.${rowIndex}.hours.${blockIndex + 1}.close` as const);
        const open = getValues(openFieldName);
        const close = getValues(closeFieldName);
        if (higherBlockOpen !== '' && higherBlockClose !== '' && open === '' && close === '') {
            return t<string>('openingHours:error.emptyPreviousBlock');
        } else {
            return true;
        }
    }, [blockIndex, closeFieldName, getValues, openFieldName, rowIndex, t]);

    const validateOpenInputNativeValidity = useCallback((): boolean|string => {
        const openInput = openInputRef.current;
        return (openInput && openInput.validity.valid)
            || t<string>('openingHours:error.invalidValue');
    }, [t]);

    const validateCloseInputNativeValidity = useCallback((): boolean|string => {
        const closeInput = closeInputRef.current;
        return (closeInput && closeInput.validity.valid)
            || t<string>('openingHours:error.invalidValue');
    }, [t]);

    const openInputRef = useRef<HTMLInputElement|null>(null);
    const closeInputRef = useRef<HTMLInputElement|null>(null);

    useEffect(() => {
        const watcher = watch((value, {name, type}) => {
            if (type === 'change') {
                if (name === openFieldName && get(value, closeFieldName, '') !== '') {
                    void trigger(openFieldName);
                }
                if (name === closeFieldName && get(value, openFieldName, '') !== '') {
                    void trigger(closeFieldName);
                }
            }
        });

        return () => watcher.unsubscribe();
    });

    const {ref: openRef, ...openRegister} = register(openFieldName, {validate: {
        openEqualOrGreaterThanClose: validateOpenEqualOrGreaterThanClose,
        requiredIfHigherBlockNotEmpty: validateRequiredIfHigherBlockNotEmpty,
        invalidValue: validateOpenInputNativeValidity,
    }});
    const {ref: closeRef, ...closeRegister} = register(closeFieldName, {validate: {
        openEqualOrGreaterThanClose: validateOpenEqualOrGreaterThanClose,
        requiredIfHigherBlockNotEmpty: validateRequiredIfHigherBlockNotEmpty,
        invalidValue: validateCloseInputNativeValidity,
    }});

    return <CenteredCell className={className} colSpan={colSpan} >
        <div className="block-edit-cell-content">
            <input
                className={classNames({
                    'with-error': openError,
                    'is-exception': highlightExceptions && regularHoursBlock && regularHoursBlock.open !== hoursBlock.open,
                })}
                title={openError ? openError.message : undefined}
                ref={(e) => {
                    openInputRef.current = e;
                    openRef(e);
                }}
                {...openRegister}
                defaultValue={hoursBlock.open}
                type="time"
                data-xid={`block-edit-cell-${rowIndex}-${blockIndex}-open`}
            />
            <span className="block-edit-cell-content-separator">&ndash;</span>
            <input
                className={classNames({
                    'with-error': closeError,
                    'is-exception': highlightExceptions && regularHoursBlock && regularHoursBlock.open !== hoursBlock.open,
                })}
                title={closeError ? closeError.message : undefined}
                ref={(e) => {
                    closeInputRef.current = e;
                    closeRef(e);
                }}
                {...closeRegister}
                defaultValue={hoursBlock.close}
                type="time"
                data-xid={`block-edit-cell-${rowIndex}-${blockIndex}-close`}
            />
        </div>
    </CenteredCell>
}
