import {Api} from "@app/AppContext/classes/Api/Api";
import {
    atBranchPacketListEndpoint, packetListByBarcodeEndpoint, branchPacketListEndpoint, parcelPacketListEndpoint,
    presenceReviewPacketsListEndpoint, recipientPickupReadyEndpoint, todaysIncomingPacketListEndpoint,
    toDispatchPacketListEndpoint
} from "@app/Packet/api/packetListEndpoints";
import {
    PacketListByBarcodeRequest, BarcodePacketListResponse, FulltextPacketListRequest, GeneralBranchPacketListRequest,
    ParcelPacketListRequest, ParcelPacketListResponse, PresenceReviewPacketsResponse,
    RecipientPickupReadyRequest, ToDispatchPacketListRequest, PacketListResponse
} from "@app/Packet/model/PacketListRequestsAndResponses";
import {PacketDetailResponse} from "@app/Packet/model/PacketRequestsAndResponses";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const atBranchPacketList = async (request: FulltextPacketListRequest, api: Api): Promise<PacketListResponse> => {
    const {branchId, ...packetListRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(atBranchPacketListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(packetListRequest),
    });
}

export const barcodePacketList = async (request: PacketListByBarcodeRequest, api: Api): Promise<BarcodePacketListResponse> => {
    return api.call({
        endpoint: packetListByBarcodeEndpoint,
        queryParams: createQueryParamsFromRequest(request),
    });
}

export const branchPacketList = async (request: GeneralBranchPacketListRequest, api: Api): Promise<PacketListResponse> => {
    const {branchId, ...packetRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(branchPacketListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(packetRequest),
    });
}

export const parcelPacketList = async (request: ParcelPacketListRequest, api: Api): Promise<ParcelPacketListResponse> => {
    const {parcelId, ...parcelPacketListRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(parcelPacketListEndpoint, {parcelId}),
        queryParams: createQueryParamsFromRequest(parcelPacketListRequest),
    });
}

export const presenceReviewPackets = async (branchId: string, api: Api): Promise<PresenceReviewPacketsResponse> => {
    return api.call(endpointWithRouteParams(presenceReviewPacketsListEndpoint, {branchId}));
}

export const recipientPickupReady = async (request: RecipientPickupReadyRequest, api: Api): Promise<PacketDetailResponse> => {
    const {password, ...packetRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(recipientPickupReadyEndpoint, {password}),
        queryParams: createQueryParamsFromRequest(packetRequest),
    })
}

export const todaysIncomingPacketList = async (request: FulltextPacketListRequest, api: Api): Promise<PacketListResponse> => {
    const {branchId, ...packetListRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(todaysIncomingPacketListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(packetListRequest),
    });
}

export const toDispatchPacketList = async (request: ToDispatchPacketListRequest, api: Api): Promise<PacketListResponse> => {
    const {branchId, ...packetRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(toDispatchPacketListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(packetRequest),
    });
}
