import {observer} from "mobx-react-lite";
import {FC, Fragment} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {
    MoneyRefundModal
} from "@app/Packet/components/PacketActions/components/MoneyRefund/MoneyRefundModal/MoneyRefundModal";
import {
    useLoadDeliveryPacket
} from "@app/Packet/components/PacketActions/hooks/useLoadDeliveryPacket";
import {usePacketCardPaymentAllowed} from "@app/Packet/components/PacketDelivery/hooks/usePacketCardPaymentAllowed";
import {Packet} from "@app/Packet/model/Packet";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {Sender} from "@app/Packet/model/Sender";
import {Img} from "@common/components/Img/Img";

export type MoneyRefundProps = {
    packet: Packet;
    sender?: Sender;
    onSuccess?: () => void;
}

export const MoneyRefund: FC<MoneyRefundProps> = observer(({onSuccess, packet, sender}) => {
    const {t} = useTranslation();

    const cardPaymentAllowed = usePacketCardPaymentAllowed()(packet, sender);

    const {
        closeModal,
        openModal,
        packets,
    } = useLoadDeliveryPacket(packet, onSuccess);

    if (!cardPaymentAllowed || !packet.isPaidByCard || packet.status.id !== PacketStatusId.PICKUP_READY) {
        return null;
    }

    return <Fragment>
        <Button
            onClick={openModal}
            title={t('viewPacketDelivery:moneyRefund.action.refund')}
            variant="success"
            data-xid={`money-refund-${packet.barcode}`}
        >
            <Img src="/images/icons/ico/ico-refund.svg" alt={t('viewPacketDelivery:moneyRefund.action.refund')} />
        </Button>
        {packets.deliveryReady.length === 1 && <MoneyRefundModal
            packet={packets.deliveryReady[0]}
            onClose={closeModal}
        />}
    </Fragment>
})
