import {FC, Fragment, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {UnknownActionForm} from "@app/Devices/components/ReaderModal/components/UnknownActionForm/useUnknownActionForm";
import {CallJobAction, RemoveJob} from "@app/Devices/components/ReaderModal/hooks/useReaderJobs";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobAction} from "@app/Devices/model/ReaderJobAction";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";
import {Button} from "@common/components/Button/Button";

export type ReaderJobActionsProps = {
    callJobAction: CallJobAction;
    closeModal: () => void;
    job: ReaderJob;
    removeJob: RemoveJob;
    setJobForCancelling: (job: ReaderJob) => void;
    unknownActionForm: UnknownActionForm;
}

export const ReaderJobActions: FC<ReaderJobActionsProps> = ({
    callJobAction,
    closeModal,
    job,
    removeJob,
    setJobForCancelling,
    unknownActionForm,
}) => {
    const {t} = useTranslation();

    const callAction = useCallback(async (action: ReaderJobAction) => {
        switch (action) {
            case ReaderJobAction.CLOSE:
                removeJob(job);
                return;
            case ReaderJobAction.CLOSE_ALL:
                closeModal();
                return;
            case ReaderJobAction.CANCEL:
                setJobForCancelling(job);
                return;
            case ReaderJobAction.INSERT:
                if (job.type === ReaderJobType.UNKNOWN) {
                    await unknownActionForm.form.handleSubmit(unknownActionForm.onSubmit)();
                } else {
                    await callJobAction(job, action, undefined, 'reader:message.readerWasProcessed');
                }
                return;
            default:
                await callJobAction(job, action, undefined, 'reader:message.readerWasProcessed');
        }
    }, [callJobAction, closeModal, job, removeJob, setJobForCancelling, unknownActionForm]);

    return <Fragment>
        {job.actions && job.actions.map((action) => {
            return <Button
                onClick={() => callAction(action)}
                variant={action === ReaderJobAction.CANCEL ? 'secondary' : 'success'}
                key={action}
                data-xid={`reader-job-action-${action}`}
            >{t(`reader:action.${action}`)}</Button>
        })}
    </Fragment>

}
