import {PaginationApiMethod} from "@app/common/model/PaginationApiMethod";
import {Parcel} from "@app/Parcel/model/Parcel";
import {ParcelListRequest} from "@app/Parcel/model/ParcelRequestsAndResponses";
import {actualDateRange, SelectedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";
import {SearchModifier} from "@common/model/requests/SearchRequestProperty";
import {dateRangeToFilterRequestParameter} from "@common/utils/api/dateRangeToFilterRequestParameter";

export type ParcelListFilter = {
    range: SelectedDateRange;
    query: string;
    hasIssue: boolean;
}

export abstract class BaseParcelListManager extends SortablePaginatedDataManager<Parcel, ParcelListRequest> {
    constructor(
        apiMethod: PaginationApiMethod<Parcel, ParcelListRequest>,
        branchId: string,
        private rangeFilterProperty: 'sentAt'|'createdAt',
    ) {
        super(apiMethod, {branchId});
    }

    public setFilter = (filter: ParcelListFilter): void => {
        const actualRange = actualDateRange(filter.range);

        if (actualRange.from && actualRange.to) {
            super.setFilteringRequest({
                barcode: filter.query !== '' ? {value: filter.query, modifier: SearchModifier.EQ} : undefined,
                [this.rangeFilterProperty]: dateRangeToFilterRequestParameter(actualRange),
                hasIssue: filter.hasIssue || undefined,
            });
        }
    }
}
