import {observer} from "mobx-react-lite";
import {Fragment, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    PciDssAuditRequiredBranchEventModal
} from "@app/BranchEvent/components/PciDssAuditRequiredBranchEvent/PciDssAuditRequiredBranchEventModal";
import {useBranchEventMessage} from "@app/BranchEvent/hooks/useBranchEventMessage";
import {
    PciDssAuditRequiredBranchEvent
} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventComponent} from "@app/BranchEvent/model/BranchEventComponent";
import {Button} from "@common/components/Button/Button";

export const PciDssAuditRequiredBranchEventContent: BranchEventComponent<PciDssAuditRequiredBranchEvent> = observer(({event}) => {
    const {t} = useTranslation();
    const {branchEvents} = useAppContext();

    const [modalOpened, setModalOpened] = useState(false);

    const onClose = useCallback(() => {
        branchEvents.setReloadRequired(true);
        setModalOpened(false);
    }, [branchEvents]);

    const message = useBranchEventMessage(event);

    return <Fragment>
        {message}
        <Button onClick={() => setModalOpened(true)}>{t('branchEvent:pciDssAuditRequired.check')}</Button>
        {modalOpened && <PciDssAuditRequiredBranchEventModal event={event} onClose={onClose} />}
    </Fragment>
})
