import {observer} from "mobx-react-lite";
import {Fragment, MouseEventHandler, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {PacketSearchModal} from "@app/Messaging/components/NewMessage/common/PacketSearchModal/PacketSearchModal";
import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {Button} from "@common/components/Button/Button";
import {WysiwygInputCustomControl} from "@common/components/forms/WysiwygInput/WysiwygInputCustomControl";

export const InsertPacketBarcode: WysiwygInputCustomControl = observer(({restoreSelection}) => {
    const {t} = useTranslation();
    const {user} = useAppContext();

    const [packetSearchModalOpened, setPacketSearchModalOpened] = useState<boolean>(false);

    const openPacketSearchModal: MouseEventHandler<HTMLElement> = useCallback((event) => {
        event.preventDefault();
        setPacketSearchModalOpened(true);
    }, []);

    const closePacketSearchModal = useCallback(() => {
        setPacketSearchModalOpened(false);
        restoreSelection();
    }, [restoreSelection]);

    const insertPacketBarcode = useCallback((barcode: string) => {
        setPacketSearchModalOpened(false);
        restoreSelection();
        document.execCommand('insertText', false, formatPacketBarcode(barcode));
    }, [restoreSelection]);

    if (user.branchId === null) {
        return null;
    }

    return <Fragment>
        <Button variant="secondary" onClick={openPacketSearchModal}>
            # {t('message:tabs.content.new.insertPacketId')}
        </Button>
        <PacketSearchModal
            branchId={user.branchId}
            show={packetSearchModalOpened}
            onHide={closePacketSearchModal}
            onPacketFound={insertPacketBarcode}
        />
    </Fragment>
});

InsertPacketBarcode.displayName = 'InsertPacketBarcode';
