import {DefaultTFuncReturn} from "i18next";
import {DetailedHTMLProps, FC, Fragment, InputHTMLAttributes, ReactElement} from "react";

/*
    Interim solution of the checkbox issue
    https://github.com/react-hook-form/react-hook-form/issues/4212

    Will be removed after the react-hook-form library upgrade
 */

export type CheckboxProps = {
    formId?: string;
    inputId?: string;
    inputOptions?: DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
    label?: DefaultTFuncReturn|ReactElement|ReactElement[];
    name: string;
}

export const Checkbox: FC<CheckboxProps> = ({
    formId,
    inputId,
    inputOptions,
    label,
    name,
}) => {
    inputId = inputId || (formId ? `${formId}-${name}` : name);

    return <Fragment>
        <div className="form-group form-group--checkbox">
            <div className="form-group-label">{label && <label htmlFor={inputId}>{label}</label>}</div>
            <div className="form-group-input">
                <input
                    {...inputOptions}
                    type="checkbox"
                    id={inputId}
                    className="form-control"
                />
            </div>
        </div>
    </Fragment>
}
