import classNames from "classnames";
import {FC, useState} from "react";

import {ManualsCategories} from "@app/Manuals/components/ManualsCategories/ManualsCategories";
import {ManualCategoryTreeNode} from "@app/Manuals/model/ManualCategoryTreeNode";
import {ManualPost} from "@app/Manuals/model/ManualPost";

export type ManualsCategoryProps = {
    categoryTreeNode: ManualCategoryTreeNode;
    onSelect: (post: ManualPost) => void;
}

export const ManualsCategory: FC<ManualsCategoryProps> = ({categoryTreeNode, onSelect}) => {
    const [collapsed, setCollapsed] = useState<boolean>(true);

    return <div className="manuals-category">
        <a onClick={() => setCollapsed(!collapsed)} className="manuals-category-title">
            {categoryTreeNode.category.name}
        </a>
        <div className={classNames('manuals-category-content', {
            'manuals-category-content-collapsed': collapsed,
        })}>
            {categoryTreeNode.posts && <div className="manuals-category-posts">
                {categoryTreeNode.posts.map((post) => <a
                    className="manuals-category-post"
                    onClick={() => onSelect(post)}
                    key={post.id}
                >{post.title.rendered}</a>)}
            </div>}
            <ManualsCategories categoryTreeNodes={categoryTreeNode.subcategories} onSelect={onSelect} />
        </div>
    </div>
}
