import {action, makeObservable, observable} from "mobx";

import {Api} from "@app/AppContext/classes/Api/Api";
import {commissionBranchesList} from "@app/Commission/api/commissionApi";
import {CommissionBranch} from "@app/Commission/model/CommissionBranch";

export class CommissionBranchesManager {
    public items: CommissionBranch[]|null = null;
    public loaded = false;

    public constructor(private api: Api) {
        makeObservable(this, {
            items: observable,
            loaded: observable,
            setItems: action,
        });
    }

    public async reload(): Promise<void> {
        try {
            const response = await commissionBranchesList({}, this.api)
            this.setItems(response.items);
        } catch (error: unknown) {
            this.setItems([]);
        }
    }

    public setItems(items: CommissionBranch[]|null): void {
        this.items = items;
        this.loaded = true;
    }
}
