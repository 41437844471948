export enum CardTransactionStatusState {
    WORKING = 'working',
    AWAITING_USER_INPUT = 'awaiting-user-input',
    SIGNATURE_REQUESTED = 'signature-requested',
    FINISHED = 'finished',
}

export type CardTransactionStatus = {
    success: boolean;
    finished: boolean;
    message?: string;
    state: CardTransactionStatusState;
    extendedState: string;
}
