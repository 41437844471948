import {FC} from "react";
import {useTranslation} from "react-i18next";

import {CommissionInvoiceBillingInformation} from "@app/Commission/model/CommissionInvoiceBillingInformation";
import {DetailInfoBox, DetailInfoItem} from "@common/components/DetailInfoBox/DetailInfoBox";
import {booleanToString} from "@common/utils/booleanToString";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export type BillingInformationBoxProps = {
    billingInfo: CommissionInvoiceBillingInformation;
}

export const BillingInformationBox: FC<BillingInformationBoxProps> = ({billingInfo}) => {
    const {t} = useTranslation();

    const info: DetailInfoItem[] = [
        {label: t('invoice:property.company'), content: billingInfo.name},
        {
            label: t('invoice:property.headQuarter'),
            content: `${billingInfo.address} ${billingInfo.zip} ${billingInfo.city}`,
        },
        {label: t('invoice:property.tin'), content: billingInfo.tin},
        {label: t('invoice:property.vatNumber'), content: billingInfo.vatNumber},
        {label: t('invoice:property.commercialRegister'), content: billingInfo.registry},
        {label: t('invoice:property.vatPayer'), content: booleanToString(billingInfo.vatTaxable, t)},
        {label: t('invoice:property.accountNumber'), content: dashWhenEmpty(billingInfo.accountNumber)},
    ];

    return <DetailInfoBox info={info} />
}
