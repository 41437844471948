import classNames from "classnames";
import {FC, RefObject} from "react";
import {useTranslation} from "react-i18next";

export type NotDeliveredPacketsHeadingProps = {
    followsTable: boolean;
    headRef: RefObject<HTMLTableSectionElement>;
}

export const NotDeliveredPacketsHeading: FC<NotDeliveredPacketsHeadingProps> = ({followsTable, headRef}) => {
    const {t} = useTranslation();

    return <thead
        className={classNames(
            'delivery-packets__not-delivered-packets-heading',
            {'delivery-packets__not-delivered-packets-heading_follows-table': followsTable}
        )}
        ref={headRef}
    >
        <tr>
            <th colSpan={6}>
                {t('viewPacketDelivery:addPacket.hasPackets')}
            </th>
        </tr>
    </thead>

}
