import {Api} from "@app/AppContext/classes/Api/Api";
import {
    accountingDocumentsEndpoint,
    accountingDocumentsNettingAsPdfEndpoint,
    commissionBranchListEndpoint,
    commissionInvoiceBillingInformationEndpoint,
    commissionInvoiceListEndpoint,
    commissionListEndpoint,
    commissionTariffListEndpoint,
    commissionTypeListEndpoint,
    createCommissionInvoiceEndpoint,
    editCommissionBillingInformationEndpoint,
    monthlyCommissionListEndpoint,
    registryUpdateBillingInformationEndpoint,
    removeCommissionInvoiceEndpoint,
    unpaidCommissionsSumEndpoint
} from "@app/Commission/api/commissionEndpoints";
import {
    AccountingDocumentsResponse,
    CommissionBranchListRequest,
    CommissionBranchListResponse,
    CommissionInvoiceBillingInformationRequest,
    CommissionInvoiceBillingInformationResponse,
    CommissionInvoiceListRequest,
    CommissionInvoiceListResponse,
    CommissionListRequest,
    CommissionListResponse,
    CommissionTariffResponse,
    CommissionTypeListResponse,
    CreateCommissionInvoiceRequest,
    EditCommissionInvoiceBillingInformationRequest,
    MonthlyCommissionListRequest,
    MonthlyCommissionListResponse,
    RegistryUpdateBillingInformationRequest,
    UnpaidCommissionsSumRequest,
    UnpaidCommissionsSumResponse
} from "@app/Commission/model/CommissionRequestsAndResponses";
import {EncodedPdfResponse} from "@common/model/responses/EncodedPdfResponse";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const accountingDocuments = async (api: Api): Promise<AccountingDocumentsResponse> => {
    return api.call({endpoint: accountingDocumentsEndpoint});
}

export const accountingDocumentsNettingAsPdf = (nettingId: number, api: Api): Promise<EncodedPdfResponse> => {
    return api.call({endpoint: endpointWithRouteParams(accountingDocumentsNettingAsPdfEndpoint, {nettingId: nettingId.toString(10)})});
}

export const billingInformation = async (request: CommissionInvoiceBillingInformationRequest, api: Api): Promise<CommissionInvoiceBillingInformationResponse> => {
    return api.call(endpointWithRouteParams(commissionInvoiceBillingInformationEndpoint, request));
}

export const commissionBranchesList = async (request: CommissionBranchListRequest, api: Api): Promise<CommissionBranchListResponse> => {
    return api.call({
        endpoint: commissionBranchListEndpoint,
        queryParams: createQueryParamsFromRequest(request),
    });
}

export const commissionInvoiceList = async (request: CommissionInvoiceListRequest, api: Api): Promise<CommissionInvoiceListResponse> => {
    return api.call({
        endpoint: commissionInvoiceListEndpoint,
        queryParams: createQueryParamsFromRequest(request),
    });
}

export const commissionList = async (request: CommissionListRequest, api: Api): Promise<CommissionListResponse> => {
    const {branchId, ...commissionListRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(commissionListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(commissionListRequest),
    });
}

export const commissionTariff = async (api: Api): Promise<CommissionTariffResponse> => {
    return api.call(commissionTariffListEndpoint);
}

export const commissionTypeList = async (api: Api): Promise<CommissionTypeListResponse> => {
    return api.call(commissionTypeListEndpoint);
}

export const createCommissionInvoice = async (request: CreateCommissionInvoiceRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: createCommissionInvoiceEndpoint,
        payload: request,
    });
}

export const removeCommissionInvoice = async (invoiceToken: string, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call(endpointWithRouteParams(removeCommissionInvoiceEndpoint, {invoiceToken}));
}

export const editBillingInformation = async (request: EditCommissionInvoiceBillingInformationRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: editCommissionBillingInformationEndpoint,
        payload: request,
    });
}

export const monthlyCommissionList = async (request: MonthlyCommissionListRequest, api: Api): Promise<MonthlyCommissionListResponse> => {
    const {branchId, ...monthlyCommissionListRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(monthlyCommissionListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(monthlyCommissionListRequest),
    });
}

export const registryUpdateBillingInformation = async (request: RegistryUpdateBillingInformationRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call(endpointWithRouteParams(registryUpdateBillingInformationEndpoint, request));
}

export const unpaidCommissionsSum = async (request: UnpaidCommissionsSumRequest, api: Api): Promise<UnpaidCommissionsSumResponse> => {
    const {branchId, ...unpaidCommissionsSumRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(unpaidCommissionsSumEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(unpaidCommissionsSumRequest),
    });
}

