import {observer} from "mobx-react-lite";
import {FC, Fragment, ReactElement, useEffect} from "react";
import {useTranslation} from "react-i18next";

import {BranchCommissionRoute} from "@app/Commission/CommissionRoutes";
import {
    CommissionBranchesManager
} from "@app/Commission/components/ManagedCommissionGrid/CommissionBranchesManager";
import {CommissionBranch} from "@app/Commission/model/CommissionBranch";
import {Button} from "@common/components/Button/Button";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridSorter} from "@common/components/grids/GridSorter/GridSorter";
import {useSorter} from "@common/components/grids/GridSorter/useSorter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {Loading} from "@common/components/Loading/Loading";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {TranslationWithHtmlParam} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {Money} from "@common/model/Money";
import {addAmounts} from "@common/utils/addAmounts";

import './managedCommissionGrid.scss';

export type ManagedCommissionGridAction = (params: {
    commissionBranch?: CommissionBranch,
    unpaidTillLastMonthSum?: Money,
}) => ReactElement|null;

export type ManagedCommissionGridProps = {
    manager: CommissionBranchesManager;
    renderAction: ManagedCommissionGridAction;
    showUnpaidTotalAndStatus?: boolean;
}

export const ManagedCommissionGrid: FC<ManagedCommissionGridProps> = observer(({
    manager,
    renderAction,
    showUnpaidTotalAndStatus = true,
}) => {
    const {t} = useTranslation();

    useEffect(() => {
        if (!manager.loaded) {
            void manager.reload();
        }
    }, [manager]);

    const sorter = useSorter<CommissionBranch, 'branchName'|'unpaidTotal'|'unpaidTillLastMonth'>(
        'managedCommissionList',
        {id: 'branchName'},
        {
            branchName: (commissionBranch) => commissionBranch.name,
            unpaidTotal: (commissionBranch) => commissionBranch.unpaid,
            unpaidTillLastMonth: (commissionBranch) => commissionBranch.unpaidTillLastMonth,
        }
    );

    const commissionBranches = manager.items ? manager.items.slice() : null;

    if (commissionBranches) {
        commissionBranches.sort(sorter.sort);
    }

    let unpaidSum = '0';
    let unpaidTillLastMonthSum = '0';
    const sumCurrencyCode = commissionBranches && commissionBranches.length > 0
        ? commissionBranches[0].currency
        : t('core:money.defaultCurrency');

    return <div className="managed-commission-grid">
        {showUnpaidTotalAndStatus && <p className="managed-commission-grid__status">
            <TranslationWithHtmlParam
                paramName="date"
                translationKey="viewCommission:managed.status"
                t={t}
                asSpan={true}
            >
                <DateFormatter date={new Date()} showTime={true} />
            </TranslationWithHtmlParam>
            <Button variant="dark" onClick={() => manager.reload()}>{t('commission:action.refresh')}</Button>
        </p>}
        <Loading active={!manager.loaded}>
            {commissionBranches && <Fragment>
                <GridTable totalCount={commissionBranches.length}>
                    <thead>
                        <tr>
                            <th>
                                <GridSorter sortId="branchName" sorter={sorter}>
                                    {t('commission:property.name')}
                                </GridSorter>
                                <div className="managed-commission-grid__name-unpaid-till-last-month">
                                    {' / '}
                                    <GridSorter sortId="unpaidTillLastMonth" sorter={sorter}>
                                        {t('commission:property.unpaidTillLastMonth')}
                                    </GridSorter>
                                </div>
                            </th>
                            {showUnpaidTotalAndStatus && <th className="managed-commission-grid__amount">
                                <GridSorter sortId="unpaidTotal" sorter={sorter}>
                                    {t('commission:property.unpaid')}
                                </GridSorter>
                            </th>}
                            <th className="managed-commission-grid__amount managed-commission-grid__unpaid-till-last-month">
                                <GridSorter sortId="unpaidTillLastMonth" sorter={sorter}>
                                    {t('commission:property.unpaidTillLastMonth')}
                                </GridSorter>
                            </th>
                            <th />
                        </tr>
                    </thead>
                    <tbody>
                    {commissionBranches.map((commissionBranch) => {
                        unpaidSum = addAmounts(unpaidSum, commissionBranch.unpaid);
                        unpaidTillLastMonthSum = addAmounts(unpaidTillLastMonthSum, commissionBranch.unpaidTillLastMonth);

                        return <tr key={`${commissionBranch.branchId}-${commissionBranch.commissionType}`}>
                            <td className="managed-commission-grid__branch-name">
                                <AppLink to={{route: BranchCommissionRoute, params: {branchId: commissionBranch.branchId}}}>
                                    {commissionBranch.name}
                                </AppLink>
                                <div className="managed-commission-grid__name-unpaid-till-last-month">
                                    <MoneyFormatter amount={commissionBranch.unpaidTillLastMonth} currencyCode={commissionBranch.currency} />
                                </div>
                            </td>
                            {showUnpaidTotalAndStatus && <td className="managed-commission-grid__amount">
                                <MoneyFormatter amount={commissionBranch.unpaid} currencyCode={commissionBranch.currency} />
                            </td>}
                            <td className="managed-commission-grid__amount managed-commission-grid__unpaid-till-last-month">
                                <MoneyFormatter amount={commissionBranch.unpaidTillLastMonth} currencyCode={commissionBranch.currency} />
                            </td>
                            <td className="managed-commission-grid__action">{renderAction({commissionBranch})}</td>
                        </tr>
                    })}
                        <tr className="managed-commission-grid__sum-row">
                            <td>
                                {t('commission:property.summary')}
                                <div className="managed-commission-grid__name-unpaid-till-last-month">
                                    <MoneyFormatter amount={unpaidTillLastMonthSum} currencyCode={sumCurrencyCode} />
                                </div>
                            </td>
                            {showUnpaidTotalAndStatus && <td className="managed-commission-grid__amount">
                                <MoneyFormatter amount={unpaidSum} currencyCode={sumCurrencyCode} />
                            </td>}
                            <td className="managed-commission-grid__amount managed-commission-grid__unpaid-till-last-month">
                                <MoneyFormatter amount={unpaidTillLastMonthSum} currencyCode={sumCurrencyCode} />
                            </td>
                            <td className="managed-commission-grid__action">
                                {renderAction({unpaidTillLastMonthSum: {
                                    amount: unpaidTillLastMonthSum,
                                    currency: sumCurrencyCode
                                }})}
                            </td>
                        </tr>
                    </tbody>
                </GridTable>
            </Fragment>}
        </Loading>
    </div>
})
