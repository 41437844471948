import {ApiErrorItem, ApiErrorItemWithVariables, isApiErrorItemWithVariables} from "@common/model/errors/ApiErrorItem";

export type AlreadyReceivedApiErrorItemVariables = {
    barcode: string;
}

export type AlreadyReceivedApiErrorItem = ApiErrorItemWithVariables<AlreadyReceivedApiErrorItemVariables>;

export const isAlreadyReceivedApiErrorItem = (item: ApiErrorItem): item is AlreadyReceivedApiErrorItem => {
    return isApiErrorItemWithVariables<AlreadyReceivedApiErrorItemVariables>(item) && 'barcode' in item.variables;
}
