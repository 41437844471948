import {ButtonHTMLAttributes, DetailedHTMLProps} from "react";

import {ButtonSize, ButtonVariant, buttonClassName} from "@common/components/Button/buttonClassName";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './button.scss';

export type ButtonProps = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    variant?: ButtonVariant;
    size?: ButtonSize;
}

export const Button: FCWithChildren<ButtonProps> = ({children, className, size, variant = 'primary', ...buttonProps}) => {
    return <button className={buttonClassName(className, variant, size)} {...buttonProps}>
        {children}
    </button>
}
