import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {useFeatureFlag} from "@common/hooks/useFeatureFlag";

export const useShowBenefitsPage = (): boolean => {
    const {t} = useTranslation();
    const {dashboardInfo} = useAppContext();
    const benefitsPageEnabled = useFeatureFlag('benefitsPage', false);

    const benefitsPageId = t('support:benefitsPageId');

    return dashboardInfo.isSkBranch && benefitsPageEnabled && !isNaN(parseInt(benefitsPageId, 10));
}
