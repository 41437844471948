import queryString from "query-string";
import {createPath, generatePath, Path} from "react-router-dom";

import {isRootReactRoute, isSimpleReactRoute, ReactRoute, To} from "@common/model/ReactRoute";

type RegisteredRoute = {
    route: ReactRoute,
    modulePath: string,
};

export class AppRouter {
    public registeredRoutes: RegisteredRoute[] = [];

    public registerModule = (modulePath: string, moduleRoutes: ReactRoute[]) => {
        moduleRoutes.forEach((route) => {
            this.registeredRoutes.push({route, modulePath: `/${modulePath}`});
        });
    }

    public path<ReactRouteParamNames extends string = string>(to: To<ReactRouteParamNames>, skipParams: boolean = false): Partial<Path> {
        const bareRoute = isSimpleReactRoute(to) ? to : to.route;

        const foundRoute = this.registeredRoutes.find((registeredRoute) => {
            return registeredRoute.route === bareRoute;
        });

        const barePath =  foundRoute ? this.fullRoute(foundRoute) : bareRoute.path;
        if (isSimpleReactRoute(to)) {
            return {pathname: barePath};
        }

        return {
            pathname: to.params && !skipParams ? generatePath(barePath, to.params) : barePath,
            search: to.search ? queryString.stringify(to.search) : undefined,
        }
    }

    public href<ReactRouteParamNames extends string = string>(to: To<ReactRouteParamNames>): string {
        return createPath(this.path(to));
    }

    private fullRoute(registeredRoute: RegisteredRoute): string {
        if (isRootReactRoute(registeredRoute.route)) {
            return `/${registeredRoute.route.path}`;
        }

        return registeredRoute.route.path === ''
            ? registeredRoute.modulePath
            : `${registeredRoute.modulePath}/${registeredRoute.route.path}`
    }
}
