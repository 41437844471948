import {BaseUser} from "@app/AppContext/classes/User/model/User";

export enum UserAccountType {
    BRANCH_SUPER_MANAGER = 'branch-super-manager',
    INTERNAL = 'internal',
    BRANCH = 'branch',
    STANDARD = 'standard',
}

export type IdentityUser = BaseUser & {
    accountType: UserAccountType;
    branchId: string|null;
}

export type NotImpersonatedUserIdentity = {
    user: IdentityUser;
    supportBranchId: number;
}

export type ImpersonatedUserIdentity = {
    user: IdentityUser;
    supportBranchId: number;
    impersonationToken: string;
    originalRefreshToken: string|null;
}

export type UserIdentity = NotImpersonatedUserIdentity | ImpersonatedUserIdentity;

export const isImpersonated = (identity: UserIdentity): identity is ImpersonatedUserIdentity => {
    return 'impersonationToken' in identity;
}
