import {Api} from "@app/AppContext/classes/Api/Api";
import {changePasswordEndpoint} from "@app/User/api/userEndpoints";
import {ChangeUserPasswordRequest} from "@app/User/model/UserRequestsAndResponses";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";

export const changePassword = async (request: ChangeUserPasswordRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: changePasswordEndpoint,
        payload: request,
    });
}
