import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const incomingParcelListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/parcel/list/incoming/:branchId',
    routeParamNames: ['branchId'],
}

export const outgoingParcelListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/parcel/list/outgoing/:branchId',
    routeParamNames: ['branchId'],
}

export const parcelDetailEndpoint: Endpoint<'parcelId'> = {
    method: HttpMethod.GET,
    url: '/parcel/detail/:parcelId',
    routeParamNames: ['parcelId'],
    notFoundErrorResponse: true,
}
