// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
import {isValid, parseISO} from "date-fns";

export const dateReviver = (_key: string, value: any): any => {
    if (typeof value === 'string') {
        if (/^\d{4}-([0][1-9]|1[0-2])-([0][1-9]|[1-2]\d|3[01])/.test(value)) {
            const parsed = parseISO(value);
            if (isValid(parsed)) {
                return parsed;
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return value;
}
