import {FC, useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {cardTransactionTypeList} from "@app/Transaction/api/cardApi";
import {cashRegisterTransactionTypeList} from "@app/Transaction/api/cashRegisterApi";
import {
    TransactionListType
} from "@app/Transaction/components/TransactionGrid/TransactionListManager";
import {Select, SelectOption} from "@common/components/forms/Select/Select";
import {Loading} from "@common/components/Loading/Loading";

export type TransactionTypeSelectProps = {
    listType: TransactionListType;
    onSelect: (type: string|null) => void;
    value?: string;
}

type TransactionTypeFormData = {
    type: string;
}

const EMPTY_TYPE_ID = '';

export const TransactionTypeSelect: FC<TransactionTypeSelectProps> = ({listType, onSelect, value}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const form = useForm<TransactionTypeFormData>({
        defaultValues: {type: value || EMPTY_TYPE_ID},
        mode: 'onChange',
    });

    const [transactionTypes, setTransactionTypes] = useState<SelectOption[]|null>(null);
    const [loading, setLoading] = useState<boolean>(false);

    const onChange = useCallback(() => {
        const {type} = form.getValues();
        onSelect(type === EMPTY_TYPE_ID ? null : type);
    }, [form, onSelect]);

    useEffect(() => {
        if (transactionTypes === null && !loading) {
            setLoading(true);
            const apiMethod = listType === TransactionListType.CASH_REGISTER
                ? cashRegisterTransactionTypeList
                : cardTransactionTypeList;

            apiMethod(appContext.api)
                .then((response) => {
                    setTransactionTypes(response.items.map((type) => ({
                        value: type.id.toString(10),
                        label: type.name,
                    })));
                })
                .catch(() => {
                    setTransactionTypes([]);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [appContext.api, listType, loading, transactionTypes]);

    useEffect(() => {
        const selectedType = form.getValues('type');
        if (!value && selectedType !== EMPTY_TYPE_ID) {
            form.setValue('type', EMPTY_TYPE_ID);
        }
        if (value && selectedType !== value) {
            form.setValue('type', value);
        }
    }, [form, value]);

    if (listType === TransactionListType.CARD || !transactionTypes || transactionTypes.length === 0) {
        return null;
    }

    return <Loading active={loading}>
        <Select
            form={form}
            name="type"
            label={t('transaction:property.transactionType')}
            options={transactionTypes}
            placeholder={{value: EMPTY_TYPE_ID, label: t('global:ui.selectPrompt')}}
            placeholderDisabled={false}
            onChange={onChange}
        />
    </Loading>
}
