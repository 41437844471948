import {readLocalStorage, writeLocalStorage} from "@common/utils/localStorage";

export class UserSpecificUI {
    private keys: string[] = [];
    private cleared = false;

    public create<T>(key: string, initialValue: T, forceInitialValue?: boolean): T {
        if (!this.keys.includes(key)) {
            this.keys.push(key);
        }
        this.cleared = false;

        if (forceInitialValue) {
            return initialValue;
        }

        try {
            return readLocalStorage<T>(key) || initialValue;
        } catch (error) {
            return initialValue;
        }
    }

    public persist<T>(key: string, value: T|null) {
        if (!this.keys.includes(key)) {
            throw new Error('Cannot persist UI config that was not created!');
        }

        if (this.cleared) {
            return;
        }

        writeLocalStorage(key, value);
    }

    public clear() {
        this.keys.forEach((key) => {
            writeLocalStorage(key, null);
        });
        this.cleared = true;
    }
}
