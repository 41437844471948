import {FC} from "react";

import {
    GeneralBranchQualityDetailItem
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/GeneralBranchQualityDetail";
import {
    ProcessPenaltyDetailGridHeader
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/components/ProcessPenaltyDetailGridHeader/ProcessPenaltyDetailGridHeader";
import {
    useProcessPenaltyDetailGridSorter
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/hooks/useProcessPenaltyDetailGridSorter";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";

export type GeneralProcessPenaltyDetailGridProps = {
    items: GeneralBranchQualityDetailItem[];
}

export const GeneralProcessPenaltyDetailGrid: FC<GeneralProcessPenaltyDetailGridProps> = ({items}) => {
    const sorter = useProcessPenaltyDetailGridSorter();
    items.sort(sorter.sort);

    return <GridTable>
        <ProcessPenaltyDetailGridHeader sorter={sorter} />
        <tbody>
        {items.map((item) => (<tr key={item.date}>
            <td><DateFormatter date={item.date}/></td>
            <td><strong>{item.penaltyPoints}</strong></td>
            </tr>))}
        </tbody>
    </GridTable>
}
