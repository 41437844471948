
import {caslEndpoint, cataloguesEndpoint, dashboardInfoEndpoint} from "@app/AppContext/api/appContextEndpoints";
import {Api} from "@app/AppContext/classes/Api/Api";
import {CaslRule} from "@app/AppContext/classes/Casl/model/CaslRule";
import {Catalogues} from "@app/AppContext/model/Catalogues";
import {DashboardInfo} from "@app/AppContext/model/DashboardInfo";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";

export const dashboardInfo = async (api: Api): Promise<DashboardInfoResponse> => {
    return api.call(dashboardInfoEndpoint);
}

export const casl = async (api: Api): Promise<CaslResponse> => {
    return api.call(caslEndpoint);
}

export const catalogues = async (api: Api): Promise<CataloguesResponse> => {
    return api.call(cataloguesEndpoint);
}

export type DashboardInfoResponse = GeneralSuccessResponseContent & DashboardInfo;

export type CaslResponse = GeneralSuccessResponseContent & {
    rules: CaslRule[];
}

export type CataloguesResponse = GeneralSuccessResponseContent & Catalogues;
