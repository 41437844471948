import {formatISO} from "date-fns";
import {observer} from "mobx-react-lite";
import {FC, Fragment} from "react";
import {renderToString} from "react-dom/server";
import {useTranslation} from "react-i18next";

import {useOpeningHoursLimits} from "@app/OpeningHours/components/ExceptionActions/useOpeningHoursLimits";
import {PlannedChangeDelete} from "@app/OpeningHours/components/PlannedChanges/PlannedChangeDelete/PlannedChangeDelete";
import {PlannedChangeEdit} from "@app/OpeningHours/components/PlannedChanges/PlannedChangeEdit/PlannedChangeEdit";
import {useOpeningHours} from "@app/OpeningHours/context/useOpeningHours";
import {OpeningHoursBlock} from "@app/OpeningHours/model/OpeningHoursBlock";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {appToast} from "@common/components/Toast/toastOpener";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";

import './plannedChanges.scss';

export const PlannedChanges: FC = observer(() => {
    const {t} = useTranslation();
    const {branchHours} = useOpeningHours();
    const dateFormatter = useDateFormatter();

    const {firstSelectableDate} = useOpeningHoursLimits();
    const plannedChanges = branchHours.plannedChanges(firstSelectableDate);

    if (branchHours.error) {
        appToast.error(t(branchHours.error.message));
    }

    if (plannedChanges.length === 0) {
        return null;
    }

    return <div className="planned-changes">
        <div className="planned-changes-header">
            <h4>{t('openingHours:page.plannedChanges.title')}</h4>
            <div className="planned-changes-header-today">
                {t('openingHours:page.plannedChanges.todayIs')}{' '}
                <strong><DateFormatter /></strong>
            </div>
        </div>
        <GridTable>
            <tbody>
                {plannedChanges.map((plannedChange) => {
                    const description = t(`openingHours:page.plannedChanges.${plannedChange.type}`, {
                        hours: plannedChange.type === 'exception' ? hoursToFormattedString(plannedChange.hours) : undefined
                    });
                    const endDate = plannedChange.type === 'closed' && plannedChange.endDate > plannedChange.date ? plannedChange.endDate : undefined;

                    return <tr key={`${plannedChange.date.toISOString()}-${plannedChange.type}`} data-xid={formatISO(plannedChange.date, {representation: 'date'})}>
                        <td>
                            <strong>
                                <DateFormatter date={plannedChange.date} />
                                {endDate && <Fragment>
                                    {' – '}<DateFormatter date={endDate} />
                                </Fragment>}
                            </strong>
                            {' '}(
                            {dateFormatter(plannedChange.date, DateFormatType.DAY_OF_WEEK)}
                            {endDate && <Fragment>
                                {' '}&ndash;{` ${dateFormatter(endDate, DateFormatType.DAY_OF_WEEK)}`}
                            </Fragment>}
                            )
                        </td>
                        <td dangerouslySetInnerHTML={{__html: description}} />
                        <PlannedChangeEdit plannedChange={plannedChange} />
                        <PlannedChangeDelete plannedChange={plannedChange} />
                    </tr>
                })}
            </tbody>
        </GridTable>
    </div>
});

const hoursToFormattedString = (hours: OpeningHoursBlock[]): string => {
    return renderToString(
        <strong>{hours.map((block): string => `${block.open} – ${block.close}`).join(', ')}</strong>
    );
}
