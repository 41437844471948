import classNames from "classnames";
import {ReactElement} from "react";

import {Img} from "@common/components/Img/Img";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './packetAlert.scss';

export type PacketAlertProps = {
    title: string;
    description: string|ReactElement|null;
    className?: string;
    iconSrc?: string;
    xid?: string
}

export const PacketAlert: FCWithChildren<PacketAlertProps> = ({children, className, description, iconSrc, title, xid}) => {
    return <div className={classNames( 'packet-alert alert-danger', className)} data-xid={xid ? `packet-alert-${xid}` : undefined}>
        <div className="packet-alert-icon">
            <Img src={iconSrc || '/images/icons/ico/ico-exclamation-red.svg'} alt={title} title={title} />
        </div>
        <div>
            <div className="packet-alert-title">
                {title}
                {children}
            </div>
            <div>{description}</div>
        </div>
    </div>
}
