import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {ManualPostRoute} from "@app/Manuals/ManualsRoutes";
import {To} from "@common/model/ReactRoute";

export const useManualPostTo = () => {
    const {t} = useTranslation();

    return useCallback((postIdTranslationKey: string): To<'id'>|undefined => {
        const postId = t<string>(postIdTranslationKey);
        if (postId === postIdTranslationKey || isNaN(parseInt(postId, 10))) {
            return undefined;
        }

        return {route:  ManualPostRoute, params: {id: postId}};
    }, [t])
}
