export enum Embeddable {
    BRANCH = 'branch',
    USER = 'user',
    SENDER = 'sender',
}

export type RequestWithEmbedded<AllowedEmbeddables extends Embeddable = Embeddable> = {
    embed?: AllowedEmbeddables[];
}

export const isRequestWithEmbedded = (request: any): request is RequestWithEmbedded => {
    return 'embed' in request;
}
