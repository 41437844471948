import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

const returnPacketListEndpointKnownErrorCodes = {
    invalidPassword: 'invalidPassword' as const,
};

export const returnPacketListEndpoint: Endpoint<'password', keyof typeof returnPacketListEndpointKnownErrorCodes> = {
    method: HttpMethod.GET,
    url: '/return-packet/list/:password',
    routeParamNames: ['password'],
    knownErrorCodes: returnPacketListEndpointKnownErrorCodes,
    notFoundErrorResponse: true,
}

export const deliverReturnPacketEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/return-packet/deliver',
}
