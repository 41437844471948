import {FC} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {ChangePasswordPage} from "@app/User/pages/ChangePasswordPage/ChangePasswordPage";
import {ChangePasswordRoute, UserIndexRoute} from "@app/User/UserRoutes";

export const User: FC = () => {
   return <Routes>
       <Route path={UserIndexRoute.path} element={<Navigate to={ChangePasswordRoute.path} />} />
       <Route path={ChangePasswordRoute.path} element={<ChangePasswordPage />} />
   </Routes>
}
