
import {Api} from "@app/AppContext/classes/Api/Api";
import {changePasswordEndpoint, createTokenEndpoint, sendLinkEndpoint} from "@app/PasswordRecovery/api/passwordRecoveryEndpoints";
import {
    ChangePasswordRequest,
    CreateTokenRequest,
    CreateTokenResponse, SendLinkRequest
} from "@app/PasswordRecovery/model/PasswordRecoveryRequestsAndResponses";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";

export const createToken = async (request: CreateTokenRequest, api: Api): Promise<CreateTokenResponse> => {
    return api.call({
        endpoint: createTokenEndpoint,
        payload: request,
    });
}

export const sendLink = async (request: SendLinkRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: sendLinkEndpoint,
        payload: request,
    });
}

export const changePassword = async (request: ChangePasswordRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: changePasswordEndpoint,
        payload: request,
    });
}
