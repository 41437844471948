import {observer} from "mobx-react-lite";
import {FC} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {CancelPayment} from "@app/CardPayment/hooks/useCancelPayment";
import {getTransaction, CardPaymentState, CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";
import {Loading} from "@common/components/Loading/Loading";

import './cardPaymentRunning.scss';

export type CardPaymentRunningProps = {
    cardPaymentState: CardPaymentState;
    cancelPayment: CancelPayment;
}

export const CardPaymentRunning: FC<CardPaymentRunningProps> = observer(({cancelPayment, cardPaymentState}) => {
    const {t} = useTranslation();

    const transaction = getTransaction(cardPaymentState);
    if (!transaction || transaction.status !== CardPaymentTransactionStatus.RUNNING) {
        return null;
    }

    const isPaying = cardPaymentState.status === CardPaymentStatus.PAYING;

    return <div className="card-payment-running" data-xid="card-payment-running">
        <div className="card-payment-running-label">
            <Loading active={true} />
            <div>
                {transaction.message || t(`cardPayment:${isPaying ? 'gettingPaymentReady' : 'cancellingPayment'}`)}</div>
            </div>
        {isPaying && <Can I={Action.CANCEL_UNFINISHED} a={Subject.CARD_TRANSACTION}>
            <div className="card-payment-running-cancel">
                <Button variant="danger" onClick={cancelPayment} data-xid="cancel-payment">
                    {t('cardPayment:action.cancel')}
                </Button>
            </div>
        </Can>}
    </div>
})
