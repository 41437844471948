import {FC, ImgHTMLAttributes} from "react";

export const Img: FC<ImgHTMLAttributes<HTMLImageElement>> = (props) => {

    if (props.src) {
        // image src preloading
        const img = new Image();
        img.src = props.src;
    }

    return <img {...props} />
}
