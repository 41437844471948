import {TFunction} from "i18next";

import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {DeliveryPacketVerification} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketVerification";
import {DeliveryReason} from "@app/Packet/components/PacketDelivery/model/ReasonVerification";
import {PacketDeliveryType} from "@app/Packet/model/PacketDeliveryType";
import {PacketDeliverRequest} from "@app/Packet/model/PacketRequestsAndResponses";

export const createDeliverRequest = (packet: DeliveryPacket, verification: DeliveryPacketVerification, t: TFunction): PacketDeliverRequest => {
    switch (verification.type) {
        case PacketDeliveryType.PASSWORD:
            return {
                barcode: packet.info.barcode,
                deliveryType: PacketDeliveryType.PASSWORD,
                password: verification.password
            };

        case PacketDeliveryType.PERSONAL_ID: {
            const idVerification = verification.packetIdVerification;
            return {
                barcode: packet.info.barcode,
                deliveryType: PacketDeliveryType.PERSONAL_ID,
                name: idVerification.name,
                personalId: `${idVerification.idCardType}-${idVerification.idCardNumber}`,
            };
        }

        case PacketDeliveryType.REASON:
            return {
                barcode: packet.info.barcode,
                deliveryType: PacketDeliveryType.REASON,
                reason: verification.reasonVerification.reason !== DeliveryReason.OTHER
                    ? t(`viewPacketDelivery:reasonVerificationForm.options.${verification.reasonVerification.reason}`)
                    : verification.reasonVerification.otherReasonDescription
            }
    }
}
