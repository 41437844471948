import {RegisterOptions} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";

export const PASSWORD_MIN_LENGTH = 8;

export const useChangePasswordValidation = (
    newPasswordGetter: () => string,
    currentPasswordGetter?: () => string,
): {newPasswordOptions: RegisterOptions, confirmPasswordOptions: RegisterOptions} => {
    const {t} = useTranslation();
    const {user} = useAppContext();

    const newPassword = {
        required: t<string>('viewChangePassword:newPassword.required'),
        minLength: {
            value: PASSWORD_MIN_LENGTH,
            message: t<string>('viewChangePassword:newPassword.length', {length: PASSWORD_MIN_LENGTH}),
        },
        validate: {
            hasNumber: (password: string) => /\d+/.test(password) || t<string>('viewChangePassword:newPassword.number'),
            uppercase: (password: string) => /[A-Z]+/.test(password) || t<string>('viewChangePassword:newPassword.uppercase'),
            hasToDifferFromEmailPart: (password: string) => {
                const emailChunks = user.email ? user.email.toLowerCase().split('@') : [];
                const lcPassword = password.toLowerCase();
                for (const chunk of emailChunks) {
                    if (chunk.includes(password) || lcPassword.includes(chunk)) {
                        return t<string>('viewChangePassword:newPassword.hasToDifferFromEmailPart');
                    }
                }
                return true;
            },
            hasToDifferFromCurrent: (password: string) => !currentPasswordGetter
                || password !== currentPasswordGetter()
                || t<string>('viewChangePassword:newPassword.hasToDifferFromCurrent'),
        },
    };

    const confirmPassword = {
        required: t<string>('viewChangePassword:confirmPassword.required'),
        validate: (password: string) =>
            password === newPasswordGetter() || t<string>('viewChangePassword:confirmPassword.passwordMismatch'),
    };

    return {
        newPasswordOptions: newPassword,
        confirmPasswordOptions: confirmPassword,
    }
}
