import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    OutgoingParcelGridHeader
} from "@app/Parcel/pages/BranchParcelListPage/OutgoingParcelGrid/OutgoingParcelGridHeader/OutgoingParcelGridHeader";
import {
    OutgoingParcelGridPrintButton
} from "@app/Parcel/pages/BranchParcelListPage/OutgoingParcelGrid/OutgoingParcelGridPrintButton/OutgoingParcelGridPrintButton";
import {OutgoingParcelListManager} from "@app/Parcel/pages/BranchParcelListPage/OutgoingParcelListManager";
import {ParcelStatusAndCount} from "@app/Parcel/pages/BranchParcelListPage/ParcelStatusAndCount/ParcelStatusAndCount";
import {ParcelDetailRoute} from "@app/Parcel/ParcelRoutes";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import '../branchParcelList.scss';

export type OutgoingParcelGridProps = {
    manager: OutgoingParcelListManager;
}

export const OutgoingParcelGrid: FC<OutgoingParcelGridProps> = observer(({manager}) => {
    const {t} = useTranslation();

    return <div>
        <h4>{t('viewParcel:outgoingParcels')}</h4>
        <PaginatedGrid manager={manager}>
            <OutgoingParcelGridHeader manager={manager} />
            <tbody>
                {manager.items.map((parcel) => {
                    return <tr key={parcel.id}>
                        <td className="branch-parcel-list__parcel-id">
                            <AppLink
                                to={{route: ParcelDetailRoute, params: {parcelId: parcel.id}}}
                                title={t('parcel:action.goToDetail')}
                            >
                                {parcel.barcode}
                            </AppLink>
                            <div className="branch-parcel-list__parcel-id-count-status">
                                <ParcelStatusAndCount parcel={parcel}/>
                            </div>
                        </td>
                        <td className="branch-parcel-list__status">
                            <ParcelStatusAndCount parcel={parcel} />
                        </td>
                        <td className="branch-parcel-list__date">
                            {dashWhenEmpty(parcel.createdAt && <DateFormatter date={parcel.createdAt} showYear={false} />)}
                        </td>
                        <td className="branch-parcel-list__date">
                            {dashWhenEmpty(parcel.modifiedAt && <DateFormatter date={parcel.modifiedAt} showYear={false} />)}
                        </td>
                        <td className="branch-parcel-list__count">
                            {parcel.packetCount}
                        </td>
                        <td className="branch-parcel-list__actions">
                            <div>
                                <AppLink
                                    className="btn btn-secondary"
                                    to={{route: ParcelDetailRoute, params: {parcelId: parcel.id}}}
                                    title={t('parcel:action.goToDetail')}
                                >
                                    {t('parcel:action.viewDetail')}
                                </AppLink>
                                <OutgoingParcelGridPrintButton parcel={parcel} />
                            </div>
                        </td>
                    </tr>
                })}
            </tbody>
        </PaginatedGrid>
    </div>
})
