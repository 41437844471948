import classNames from "classnames";
import {TypeOptions} from "react-toastify";

import {Img} from "@common/components/Img/Img";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './alert.scss';

export type AlertProps = {
    type: TypeOptions;
    alertId?: number|string;
    className?: string;
    close?: () => void;
}

export const Alert: FCWithChildren<AlertProps> = ({alertId, children, className, close, type}) => {

    const stateIcons: {[key in TypeOptions]: string} = {
        warning: '/images/icons/ico/ico-exclamation-2.svg',
        info: '/images/icons/ico/ico-i-gray.svg',
        success: '/images/icons/ico/ico-check.svg',
        error: '/images/icons/ico/ico-exclamation-red.svg',
        default: '/images/icons/ico/ico-i-gray.svg',
    };

    return <div
        data-xid={`${alertId}-${type}`}
        className={classNames(className, 'alert', {
            [`alert-${type}`]: type !== 'error',
            'alert-danger': type === 'error',
        })}
    >
        <div>
            <Img src={stateIcons[type]} alt={type} />
        </div>
        <div className="alert-content">
            {children}
        </div>
        {close && <div>
            <a onClick={close} className="alert-button">OK</a>
        </div>}
    </div>
}
