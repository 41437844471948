import {GridOpeningHours} from "@app/OpeningHours/components/EditableOpeningHoursGrid/GridOpeningHours";
import {BranchOpeningHours} from "@app/OpeningHours/model/BranchOpeningHours";
import {ExceptionOpeningHours} from "@app/OpeningHours/model/ExceptionOpeningHours";
import {OpeningHoursBlock} from "@app/OpeningHours/model/OpeningHoursBlock";
import {dateObjectCompare} from "@common/utils/dateObjectCompare";

export const branchToGridOpeningHours = (branchOpeningHours: BranchOpeningHours): GridOpeningHours[] => {
    const indexedExceptions = new Map<string, ExceptionOpeningHours>();
    if (branchOpeningHours.exceptions) {
        branchOpeningHours.exceptions.forEach((ohException) => {
            indexedExceptions.set(ohException.date, ohException);
        });
    }

    return branchOpeningHours.effective
        .map((regularHour): GridOpeningHours => {
            const openingHour = indexedExceptions.get(regularHour.date) || regularHour;
            return {
                date: openingHour.date,
                isPublicHoliday: regularHour.isPublicHoliday || false,
                regularHours: indexedExceptions.has(regularHour.date) ? fillGridHoursBlocks(regularHour.hours) : undefined,
                hours: fillGridHoursBlocks(openingHour.hours)
            };
        })
        .sort(dateObjectCompare);
}

export const fillGridHoursBlocks = (blocks: (OpeningHoursBlock | null)[]): OpeningHoursBlock[] => {
    return [0, 1, 2].map((index): OpeningHoursBlock => {
        return blocks[index] || {open: '', close: ''};
    });
}
