import {observer} from "mobx-react-lite";
import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {managedBranchInventoryList} from "@app/Inventory/api/inventoryApi";
import {BranchInventoryListRoute, InventoryDetailRoute} from "@app/Inventory/InventoryRoutes";
import {ManagedBranchInventory} from "@app/Inventory/model/ManagedBranchInventory";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridSorter} from "@common/components/grids/GridSorter/GridSorter";
import {useSorter} from "@common/components/grids/GridSorter/useSorter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {useSingleBranchId} from "@common/hooks/useSingleBranchId";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './managedInventoryPage.scss';

export const ManagedInventoryPage: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const branchId = useSingleBranchId();
    const navigate = useAppNavigate();

    const [managedBranches, setManagedBranches] = useState<ManagedBranchInventory[]|null|false>(null);
    const showAppErrorToast = useShowErrorToast(appToast);

    useEffect(() => {
        if (branchId) {
            navigate({route: BranchInventoryListRoute, params: {branchId}});
        } else if (managedBranches === null) {
            managedBranchInventoryList(appContext.api)
                .then((response) => {
                    setManagedBranches(response.items);
                })
                .catch((error: Error) => {
                    showAppErrorToast(error);
                    setManagedBranches(false);
                });
        }
    }, [appContext.api, branchId, managedBranches, navigate, showAppErrorToast]);

    const sorter = useSorter<ManagedBranchInventory, 'branchName'>(
        'managedInventoryList',
        {id: 'branchName'},
        {branchName: (managedBranch) => managedBranch.branch.name}
    );

    if (branchId) {
        return null;
    }

    if (managedBranches) {
        managedBranches.sort(sorter.sort);
    }

    return <PageContent className="managed-inventory-page">
        <PageHeader branchId={branchId}>{t('viewInventory:managed.header')}</PageHeader>
        <Loading active={managedBranches === null}>
            {managedBranches && <GridTable totalCount={managedBranches.length}>
                <thead>
                    <tr>
                        <th><GridSorter sortId="branchName" sorter={sorter}>
                            {t('viewInventory:managed.branchName')}
                        </GridSorter></th>
                        <th>{t('viewInventory:managed.lastConfirmedInventory')}</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {managedBranches.map((managedBranch) => {
                        return <tr key={managedBranch.branch.id}>
                            <td className="managed-inventory-page__branch-name">
                                <AppLink to={{route: BranchInventoryListRoute, params: {branchId: managedBranch.branch.id}}}>
                                    {managedBranch.branch.name}
                                </AppLink>
                            </td>
                            <td>
                                {dashWhenEmpty(managedBranch.lastConfirmedInventory && <AppLink to={{
                                    route: InventoryDetailRoute,
                                    params: {
                                        branchId: managedBranch.branch.id,
                                        inventoryId: managedBranch.lastConfirmedInventory.id
                                    }
                                }}>
                                    <DateFormatter
                                        date={managedBranch.lastConfirmedInventory.createdAt}
                                        showTime={true}
                                    />
                                </AppLink>)}
                            </td>
                            <td className="managed-inventory-page__show-detail">
                                <AppLink to={{route: BranchInventoryListRoute, params: {branchId: managedBranch.branch.id}}}>
                                    {t('viewInventory:managed.showDetail')}
                                </AppLink>
                            </td>
                        </tr>
                    })}
                </tbody>
            </GridTable>}
        </Loading>
    </PageContent>
})
