import {FC, useCallback, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import {
    CardPaymentReceiptsButton
} from "@app/CardPayment/components/CardPaymentReceipts/CardPaymentReceiptsButton/CardPaymentReceiptsButton";
import {usePrintCardReceipt} from "@app/CardPayment/hooks/usePrintCardReceipt";
import {useTransactionStatusEffect} from "@app/CardPayment/hooks/useTransactionStatusEffect";
import {CardPaymentState, CardPaymentStatus, getTransaction} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";
import {PrintCardReceiptRequestType} from "@app/CardPayment/model/CardTerminalRequestsAndResponses";
import {Loading} from "@common/components/Loading/Loading";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {PrintResult} from "@common/model/PrintResult";

import './cardPaymentReceipts.scss';

export type CardPaymentReceiptsProps = {
    cardPaymentState: CardPaymentState;
    checkInitialTransactionStatus?: boolean;
    suppressSuccessToastOnTransactionCancelled?: boolean;
    toastInModal: boolean;
}

export const CardPaymentReceipts: FC<CardPaymentReceiptsProps> = ({
    cardPaymentState,
    checkInitialTransactionStatus = true,
    suppressSuccessToastOnTransactionCancelled,
    toastInModal,
}) => {
    const {t} = useTranslation();
    const [customerReceiptPrintResult, setCustomerReceiptPrintResult] = useState<PrintResult|null>(null);
    const [merchantReceiptPrintResult, setMerchantReceiptPrintResult] = useState<PrintResult|null>(null);
    const [buttonsBlocked, setButtonsBlocked] = useState<boolean>(false);

    const toastOpener = toastInModal ? modalToast : appToast;

    const transaction = getTransaction(cardPaymentState);
    // stored to prevent repeated automatic receipt printing
    const initialTransactionStatus = useRef<CardPaymentTransactionStatus>(transaction ? transaction.status : null);

    const showErrorToast = useShowErrorToast(toastOpener, 'viewPacketPrint:error', 'global:print.error.general');
    const print = usePrintCardReceipt(toastInModal, {toastId: 'cardPaymentReceipt'});

    const callPrintCardReceipt = useCallback((receiptType: PrintCardReceiptRequestType, transactionCancelled: boolean) => {
        if (!transaction) {
            return;
        }

        setButtonsBlocked(true);
        print(
            {transactionId: transaction.id, receiptType, forcePrint: transactionCancelled},
            !transactionCancelled || !suppressSuccessToastOnTransactionCancelled,
        )
            .then((response) => {
                if (receiptType === PrintCardReceiptRequestType.CUSTOMER_RECEIPT) {
                    if (response.receipts.customerReceipt) {
                        if (response.receipts.customerReceipt.prints && response.receipts.customerReceipt.prints.length > 0) {
                            setCustomerReceiptPrintResult(response.receipts.customerReceipt.prints[0]);
                        }
                    } else {
                        toastOpener.error(t('global:print.error.general'));
                    }
                }

                if (receiptType === PrintCardReceiptRequestType.MERCHANT_RECEIPT) {
                    if (response.receipts.merchantReceipt) {
                        if (response.receipts.merchantReceipt.prints && response.receipts.merchantReceipt.prints.length > 0) {
                            setMerchantReceiptPrintResult(response.receipts.merchantReceipt.prints[0]);
                        }
                    } else {
                        toastOpener.error(t('global:print.error.general'));
                    }
                }

                setButtonsBlocked(false);
            })
            .catch((error: Error) => {
                showErrorToast(error);
            })
            .finally(() => {
                setButtonsBlocked(false);
            });
    }, [print, showErrorToast, suppressSuccessToastOnTransactionCancelled, t, toastOpener, transaction]);

    useTransactionStatusEffect(cardPaymentState, (transactionStatus) => {
        if ((!checkInitialTransactionStatus || initialTransactionStatus.current !== CardPaymentTransactionStatus.SUCCESS)
            && transactionStatus === CardPaymentTransactionStatus.SUCCESS
        ) {
            callPrintCardReceipt(
                PrintCardReceiptRequestType.CUSTOMER_RECEIPT,
                 cardPaymentState.status === CardPaymentStatus.CANCELLING,
            );
        }
    });

    if (!transaction || transaction.status !== CardPaymentTransactionStatus.SUCCESS) {
        return null;
    }

    return <Loading active={buttonsBlocked}>
        <div className="card-payment-receipts">
            <CardPaymentReceiptsButton
                callPrint={callPrintCardReceipt}
                disabled={buttonsBlocked}
                printResult={customerReceiptPrintResult}
                requestType={PrintCardReceiptRequestType.CUSTOMER_RECEIPT}
                toastInModal={toastInModal}
            />
            <CardPaymentReceiptsButton
                callPrint={callPrintCardReceipt}
                disabled={buttonsBlocked}
                printResult={merchantReceiptPrintResult}
                requestType={PrintCardReceiptRequestType.MERCHANT_RECEIPT}
                toastInModal={toastInModal}
            />
        </div>
    </Loading>
}
