import {Api} from "@app/AppContext/classes/Api/Api";
import {BranchHoursManager} from "@app/OpeningHours/context/BranchHoursManager";
import {RegularHoursManager} from "@app/OpeningHours/context/RegularHoursManager";

export class OpeningHoursManager {
    public _regularHours: RegularHoursManager|null = null;
    private _branchHours: BranchHoursManager|null = null;

    constructor(private api: Api) {}

    public get branchHours(): BranchHoursManager {
        if (this._branchHours === null) {
            this._branchHours = new BranchHoursManager(this.api);
        }

        return this._branchHours;
    }

    public get regularHours(): RegularHoursManager {
        if (this._regularHours === null) {
            this._regularHours = new RegularHoursManager(this.api);
        }

        return this._regularHours;
    }

}
