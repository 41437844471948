export const formatBytes = (bytes: number, decimals = 2): string => {
    if (bytes === 0) return '0 B';

    const k = 1024;
    const sizes = ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.max(Math.floor(Math.log(bytes) / Math.log(k)), 1);
    const value = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))

    return `${value} ${sizes[i-1]}`;
}
