import {CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {appConfig} from "@app/config";

// 31 checks in the first two minutes, followed by 6 checks every minute in the 3 following minutes
export const MAX_AUTO_CHECKS_COUNT = 49;

export const getTimeoutForCheck = (check: number, payButtonStatus: CardPaymentStatus.PAYING|CardPaymentStatus.CANCELLING): number => {
    if (appConfig.isE2ETest === '1') {
        return 100;
    }

    if (payButtonStatus === CardPaymentStatus.CANCELLING) {
        // cancelling transactions are checked every 3s
        return 3000;
    }

    if (check === 1) {
        return 7000; // first check after 7s
    }
    if (check <= 3) {
        return 3000; // 7s - 13s check every 3s
    }
    if (check <= 6) {
        return 2000; // 13s - 19s check every 2s
    }
    if (check <= 18) {
        return 3000; // 19s - 55s check every 3s
    }
    if (check <= 31) {
        return 5000; // 55s - 120s check every 5s
    }

    return 10000;
}
