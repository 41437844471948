
import {observer} from "mobx-react-lite";
import {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {microDepot} from "@app/MicroDepot/api/microDepotApi";
import {MicroDepotOperationalInfo} from "@app/MicroDepot/model/MicroDepotOperationalInfo";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {appToast} from "@common/components/Toast/toastOpener";
import {useSecurity} from "@common/hooks/useSecurity";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {camelize} from "@common/utils/camelize";

export const MicroDepotOperationalInfoPage: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [operationalInfo, setOperationalInfo] = useState<MicroDepotOperationalInfo|null|false>(null);
    const showAppErrorToast = useShowErrorToast(appToast);

    useEffect(() => {
        if (operationalInfo !== null) {
            return;
        }

        microDepot(appContext.api)
            .then((response) => {
                setOperationalInfo({packetCounts: response.packetCounts});
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
                setOperationalInfo(false);
            });
    }, [appContext.api, operationalInfo, showAppErrorToast]);

    const allowed = useSecurity(Subject.MICRO_DEPOT);
    if (!allowed) {
        return null;
    }

    return <PageContent>
        <PageHeader>
            {t('viewMicroDepot:operationalInfo.title')}
        </PageHeader>
        <h4>{t('viewMicroDepot:operationalInfo.operationalInfo')}</h4>
        <Loading active={operationalInfo === null}>
            {operationalInfo && <Fragment>
                {operationalInfo.packetCounts.length === 0 && <Fragment>
                    {t('global:ui.noItemsFound')}
                </Fragment>}
                {operationalInfo.packetCounts.length && <GridTable>
                    <thead>
                    <tr>
                        <th></th>
                        <th>{t('viewMicroDepot:operationalInfo.packetCount')}</th>
                        <th>{t('viewMicroDepot:operationalInfo.updatedAt')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {operationalInfo.packetCounts.map((packetCount, index) => (
                        <tr key={index}>
                            <th className="text-left">{t('packet:countType.' + camelize(packetCount.type))}</th>
                            <td className="text-right">{packetCount.count}</td>
                            <td className="text-left"><DateFormatter date={packetCount.updatedAt} showTime /></td>
                        </tr>
                    ))}
                    </tbody>
                </GridTable>}
            </Fragment>}
        </Loading>
    </PageContent>
})
