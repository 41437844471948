import {action, makeObservable} from "mobx";

import {Api} from "@app/AppContext/classes/Api/Api";
import {Branch} from "@app/Branch/model/Branch";
import {
    callPacketListApi,
    TypedPacketListRequest,
    TypedPacketListRequestType
} from "@app/Packet/components/PacketGrid/callPacketListApi";
import {Packet} from "@app/Packet/model/Packet";
import {PacketListFilter} from "@app/Packet/model/PacketListFilter";
import {Sender} from "@app/Packet/model/Sender";
import {actualDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {SearchModifier} from "@common/model/requests/SearchRequestProperty";
import {dateRangeToFilterRequestParameter} from "@common/utils/api/dateRangeToFilterRequestParameter";
import {extractEmbedded} from "@common/utils/extractEmbedded";

export class PacketListManager extends SortablePaginatedDataManager<Packet, TypedPacketListRequest> {
    public branches: Map<string, Branch> = new Map<string, Branch>();
    public senders: Map<string, Sender> = new Map<string, Sender>();

    constructor(
        public branchId: string,
        protected api: Api,
        withBranches?: boolean,
        pageSize?: number
    ) {
        super(
            (request) => callPacketListApi(request, api),
            {
                branchId,
                type: TypedPacketListRequestType.BRANCH,
                embed: [Embeddable.SENDER, ...(withBranches ? [Embeddable.BRANCH] : [])],
            },
            pageSize,
            true,
        );

        makeObservable(this, {
            updatePacket: action,
        })

        this.processResponse.push((response) => {
            this.branches = extractEmbedded(response, Embeddable.BRANCH);
            this.senders = extractEmbedded(response, Embeddable.SENDER);
        });
    }

    public setBranchId = (branchId: string): void => {
        this.branchId = branchId;
        this.request.branchId = branchId;
    }

    public setFilter = (filter: PacketListFilter): void => {
        const newFilteringRequest: TypedPacketListRequest = {
            ...this.request,
        }

        if (filter.type) {
            newFilteringRequest.type = filter.type;
        }

        if (newFilteringRequest.type !== TypedPacketListRequestType.TO_DISPATCH) {
            newFilteringRequest.fulltext = filter.fulltext;
        }

        if (newFilteringRequest.type === TypedPacketListRequestType.BRANCH) {
            if (filter.dateType && filter.dateRange) {
                const actualRange = actualDateRange(filter.dateRange);

                if (actualRange.from && actualRange.to) {
                    newFilteringRequest[filter.dateType] = dateRangeToFilterRequestParameter(actualRange);
                }
            }

            if (filter.destinationHere && this.branchId) {
                newFilteringRequest.addressId = {value: this.branchId, modifier: SearchModifier.EQ};
            }

            newFilteringRequest.multiStatusId = filter.multiStatusId;
        }

        super.setFilteringRequest(newFilteringRequest);
    }

    public updatePacket(freshPacket: Packet): void {
        const stalePacketIndex = this.items.findIndex((packet) => packet.barcode === freshPacket.barcode);
        if (stalePacketIndex !== -1) {
            this.items[stalePacketIndex] = freshPacket;
        }
    }
}
