import {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {tracking} from "@app/Packet/api/packetApi";
import {PacketDetailed} from "@app/Packet/model/PacketDetailed";
import {PacketTrackingResponse} from "@app/Packet/model/PacketRequestsAndResponses";
import {
    PacketTrackingGrid
} from "@app/Packet/pages/PacketDetailPage/PacketTracking/PacketTrackingGrid/PacketTrackingGrid";
import {Loading} from "@common/components/Loading/Loading";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './packetTracking.scss';

export type PacketTrackingProps = {
    packet: PacketDetailed;
}

export const PacketTracking: FC<PacketTrackingProps> = ({packet}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [packetTracking, setPacketTracking] = useState<PacketTrackingResponse|false|null>(null);
    const showAppErrorToast = useShowErrorToast(appToast);

    useEffect(() => {
        tracking({barcode: packet.barcode}, appContext.api)
            .then(setPacketTracking)
            .catch((error: Error) => {
                showAppErrorToast(error);
                setPacketTracking(false);
            });
    }, [appContext.api, packet, showAppErrorToast]);

    return <Loading active={packetTracking === null} className="packet-tracking">
        {packetTracking && <Fragment>
            <div className="packet-tracking__main">
                <h4>{t('viewPacketDetail:stateTrace.title')}</h4>
                <PacketTrackingGrid trackingItems={packetTracking.tracking} />
            </div>
            {packetTracking.extTracking !== null && <div className="packet-tracking__ext">
                <h6>{packetTracking.extTracking.carrierName}</h6>
                <PacketTrackingGrid trackingItems={packetTracking.extTracking.tracking} />
            </div>}
        </Fragment>}
    </Loading>
}
