import {useCallback} from "react";
import {useSearchParams} from "react-router-dom";

export const useQueryParam = (name: string): [string|null, (newValue: string|null) => void] => {
    const [searchParams, setSearchParams] = useSearchParams();

    const setValue = useCallback((newValue: string|null) => {
        if (newValue === null) {
            searchParams.delete(name);
        } else {
            searchParams.set(name, newValue);
        }
        setSearchParams(searchParams);
    }, [name, searchParams, setSearchParams]);

    return [searchParams.get(name), setValue];
}

export const useNumericQueryParam = (name: string): [number|null, (newValue: number|null) => void]  => {
    const [stringValue, setStringValue] = useQueryParam(name);

    const setValue = useCallback((newValue: number|null) => {
        setStringValue(newValue === null ? null : newValue.toString());
    }, [setStringValue]);

    return [stringValue !== null ? Number(stringValue) : null, setValue];
}
