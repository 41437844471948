import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {accountingDocuments, accountingDocumentsNettingAsPdf} from "@app/Commission/api/commissionApi";
import {AccountingDocumentsResponse} from "@app/Commission/model/CommissionRequestsAndResponses";
import {InvoiceNetting} from "@app/Commission/model/InvoiceNetting";
import {moneyToString} from "@common/components/MoneyFormatter/moneyToString";
import {appToast} from "@common/components/Toast/toastOpener";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {downloadEncodedPdf} from "@common/utils/api/downloadEncodedPdf";

import './accountingDocuments.scss';

export const AccountingDocuments: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const dateFormatter = useDateFormatter();
    const [documents, setDocuments] = useState<AccountingDocumentsResponse|null|false>(null);

    const showAppErrorToast = useShowErrorToast(appToast);

    const downloadNettingPdf = useCallback((invoiceNetting: InvoiceNetting) => {
        accountingDocumentsNettingAsPdf(invoiceNetting.id, appContext.api)
            .then((response) => {
                downloadEncodedPdf(response, `${invoiceNetting.number}.pdf`);
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
            });
    }, [appContext.api, showAppErrorToast]);

    useEffect(() => {
        if (documents === null) {
            accountingDocuments(appContext.api)
                .then(setDocuments)
                .catch((error: Error) => {
                    showAppErrorToast(error);
                    setDocuments(false);
                });
        }
    }, [appContext.api, documents, showAppErrorToast]);

    if (!documents || (documents.equipmentInvoice.items.length === 0 && documents.invoiceNetting.items.length === 0)) {
        return null;
    }

    return <div className="accounting-documents">
        <strong>{t('invoice:accountingDocuments.title')}</strong>
        <ul>
            {documents.equipmentInvoice.items.map((equipmentInvoice) => {
                return <li key={equipmentInvoice.number}>
                    {t(
                        'invoice:accountingDocuments.equipmentInvoice',
                        {
                            invoiceNumber: equipmentInvoice.number,
                            makeDate: dateFormatter(equipmentInvoice.makeDate, DateFormatType.DATE_FORMAT),
                            issuedBy: equipmentInvoice.issuedBy,
                            receivedBy: equipmentInvoice.receivedBy,
                            amount: moneyToString({amount: equipmentInvoice.totalIncludingVat, currency: equipmentInvoice.currency}, true),
                        }
                    )}
                    <a href={equipmentInvoice.urlPdf} target="_blank" rel="noreferrer" className="accounting-documents-download-link">
                        {t('invoice:accountingDocuments.downloadPdf')}
                    </a>
                </li>
            })}
            {documents.invoiceNetting.items.map((invoiceNetting) => {
                return <li key={invoiceNetting.id}>
                    {t('invoice:accountingDocuments.invoiceNetting', {
                        makeDate: dateFormatter(invoiceNetting.makeDate, DateFormatType.DATE_FORMAT),
                        invoiceNumber: invoiceNetting.number,
                    })}
                    <a onClick={() => downloadNettingPdf(invoiceNetting)} className="accounting-documents-download-link">
                        {t('invoice:accountingDocuments.downloadPdf')}
                    </a>
                </li>
            })}

        </ul>
    </div>
})
