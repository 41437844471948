
import {Api} from "@app/AppContext/classes/Api/Api";
import {incomingParcelListEndpoint, outgoingParcelListEndpoint, parcelDetailEndpoint} from "@app/Parcel/api/parcelEndpoints";
import {
    ParcelDetailRequest,
    ParcelDetailResponse,
    ParcelListRequest,
    ParcelListResponse
} from "@app/Parcel/model/ParcelRequestsAndResponses";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const incomingParcelList = async (request: ParcelListRequest, api: Api): Promise<ParcelListResponse> => {
    const {branchId, ...incomingParcelListRequest} = request;

    return api.call({
        endpoint: endpointWithRouteParams(incomingParcelListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(incomingParcelListRequest),
    });
}

export const outgoingParcelList = async (request: ParcelListRequest, api: Api): Promise<ParcelListResponse> => {
    const {branchId, ...outgoingParcelListRequest} = request;

    return api.call({
        endpoint: endpointWithRouteParams(outgoingParcelListEndpoint, {branchId}),
        queryParams: createQueryParamsFromRequest(outgoingParcelListRequest),
    });
}

export const parcelDetail = async (request: ParcelDetailRequest, api: Api): Promise<ParcelDetailResponse> => {
    const {parcelId, ...parcelDetailRequest} = request;

    return api.call({
        endpoint: endpointWithRouteParams(parcelDetailEndpoint, {parcelId}),
        queryParams: createQueryParamsFromRequest(parcelDetailRequest),
    });
}
