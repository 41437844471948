import classNames from "classnames";
import {FC} from "react";

import './sortingCaret.scss';

export type SortingCaretProps = {
    desc?: boolean;
}

export const SortingCaret: FC<SortingCaretProps> = ({desc}) => {
    return <i className={classNames('sorting-caret', desc && 'sorting-caret_desc')} />
}
