import {formatRFC3339} from "date-fns";

import {QueryParam} from "@common/model/requests/QueryParam";
import {isRequestWithEmbedded} from "@common/model/requests/RequestWithEmbedded";
import {isSearchRequestArrayProperty, isSearchRequestProperty} from "@common/model/requests/SearchRequestProperty";
import {isRequestWithSort} from "@common/model/requests/SortRequestProperty";
import {createEmbedParameter} from "@common/utils/api/createEmbedParameter";
import {createSortParameter} from "@common/utils/api/createSortParameter";

export const createQueryParamsFromRequest = <RequestType>(request: RequestType): QueryParam[] => {
    const params: QueryParam[] = [];

    for (const property in request) {
        if (Object.prototype.hasOwnProperty.call(request, property)) {
            if (isRequestWithEmbedded(request) && property === 'embed') {
                params.push(...createEmbedParameter(request));
            } else if (isRequestWithSort(request) && property === 'sort') {
                params.push(...createSortParameter(request));
            } else {
                const value = request[property];
                if (isSearchRequestProperty(value)) {
                    params.push({
                        name: `${property}[${value.modifier}]`,
                        value: paramValueToString(value.value),
                    })
                } else if (isSearchRequestArrayProperty(value)) {
                    value.forEach((searchProperty) => {
                        params.push({
                            name: `${property}[${searchProperty.modifier}]`,
                            value: paramValueToString(searchProperty.value),
                        });
                    })
                } else if (value instanceof Array) {
                    value.forEach((valueItem) => {
                        params.push({name: `${property}[]`, value: paramValueToString(valueItem)});
                    });
                } else if (value !== undefined) {
                    params.push({name: property, value: paramValueToString(value)});
                }
            }
        }
    }

    return params;
}

const paramValueToString = (value: any): string => {
    if (value instanceof Date) {
        return formatRFC3339(value);
    } else if (typeof value === 'boolean') {
        return value ? '1' : '0';
    } else {
        return value as string;
    }
}
