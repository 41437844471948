export type ApiErrorItem = {
    code: string;
    text: string;
    field?: string;
    parent?: string;
}

export type ApiErrorItemWithVariables<VariablesType> = ApiErrorItem & {
    variables: VariablesType;
}

export const isApiErrorItemWithVariables = <VariablesType>(item: ApiErrorItem): item is ApiErrorItemWithVariables<VariablesType> => {
    return 'variables' in item;
}
