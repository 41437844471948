import {observer} from "mobx-react-lite";
import {FC, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {commissionTariff} from "@app/Commission/api/commissionApi";
import {BranchCommissionRoute, ManagedCommissionRoute} from "@app/Commission/CommissionRoutes";
import {CommissionTariff} from "@app/Commission/model/CommissionTariff";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {useCaslGuard} from "@common/hooks/useCaslGuard";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {useSingleBranchTo} from "@common/hooks/useSingleBranchTo";

export const CommissionTariffPage: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [tariffList, setTariffList] = useState<CommissionTariff[]|null|false>(null);
    const showAppErrorToast = useShowErrorToast(appToast);
    const ability = useContext(AbilityContext);
    const navigate = useAppNavigate();

    useEffect(() => {
        if (!ability.can(Action.SHOW, Subject.COMMISSION_TARIFF)) {
            navigate(ManagedCommissionRoute);
            return;
        }

        if (tariffList === null) {
            commissionTariff(appContext.api)
                .then((response) => {
                    setTariffList(response.items);
                })
                .catch((error: Error) => {
                    showAppErrorToast(error);
                    setTariffList(false);
                });
        }
    }, [ability, appContext.api, navigate, showAppErrorToast, tariffList]);

    const branchDetailTo = useSingleBranchTo();
    const canShow = useCaslGuard(Action.SHOW, Subject.COMMISSION_TARIFF, branchDetailTo(BranchCommissionRoute, ManagedCommissionRoute));
    if (!canShow || !tariffList) {
        return null;
    }

    return <PageContent>
        <ReturnLink
            to={branchDetailTo(BranchCommissionRoute, ManagedCommissionRoute)}
            label={t('invoice:action.goBackToCommission')}
        />
        <PageHeader subTitle={<DateFormatter />}>
            {t('viewCommission:tariffs.title')}
        </PageHeader>
        <p>{t('viewCommission:tariffs.announcement')}</p>
        <GridTable>
            <thead>
                <tr>
                    <th>&nbsp;</th>
                    <th>{t('viewCommission:tariffs.amount')}</th>
                    <th>{t('viewCommission:tariffs.description')}</th>
                </tr>
            </thead>
            <tbody>
                {tariffList.map((tariff, index) => (
                    <tr key={index}>
                        <td>{tariff.name}</td>
                        <td><MoneyFormatter amount={tariff.value} currencyCode={tariff.currency} /></td>
                        <td>{tariff.description}</td>
                    </tr>
                ))}
            </tbody>

        </GridTable>
        <p>{t('viewCommission:tariffs.addendum')}</p>
    </PageContent>
})
