import {TFunction} from "i18next";

import {getStringVariable, getVariable} from "@app/Devices/components/ReaderModal/utils/getVariable";
import {ReaderJobTitleType, resolveSubSection} from "@app/Devices/components/ReaderModal/utils/resolveSubSection";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderProcessResult} from "@app/Devices/model/ReaderProcessResult";
import {ReaderVariables} from "@app/Devices/model/ReaderVariables";
import {DateFormatter, DateFormatType} from "@common/hooks/useDateFormatter";

export const jobResultTitle = (
    jobResult: ReaderProcessResult,
    job: ReaderJob,
    titleType: ReaderJobTitleType|undefined,
    dateFormatter: DateFormatter,
    t: TFunction
): string => {

    const processedVariables: ReaderVariables = !jobResult.variables || jobResult.variables instanceof Array
        ? {}
        : {...jobResult.variables};

    const receivedAt = getStringVariable(jobResult.variables, 'receivedAt');
    if (receivedAt) {
        processedVariables.receivedAt = dateFormatter(receivedAt, DateFormatType.TIME_FORMAT);
    }

    const isDepot = getVariable(jobResult.variables, 'isDepot');
    if (jobResult.code === 'parcelProcessedDirectionBranch' && isDepot) {
        jobResult.code = 'parcelProcessedDirectionDepot';
    }

    let title = '';
    const subSection = titleType !== undefined ? `.${resolveSubSection(titleType, job)}` : '';

    const jobTitle = getStringVariable(job.variables, 'title');
    if (jobTitle && jobResult.code) {
        title = t(`reader:${jobTitle}${subSection}.${jobResult.code}`, {...jobResult.variables});
    }

    const shelf = getStringVariable(jobResult.variables, 'shelf');
    if (jobResult.code !== 'packetShelf' && shelf) {
        title += "\x0a" + t('reader:receivePackets.packetResult.packetShelf', processedVariables);
    } else if (jobTitle && jobResult.code) {
        title = t(`reader:${jobTitle}${subSection}.${jobResult.code}`, processedVariables);
    } else {
        title = t(`reader:resultType.${jobResult.type}`)
    }

    const billOfDeliveryNumber = getStringVariable(jobResult.variables, 'billOfDeliveryNumber');
    if (billOfDeliveryNumber) {
        return `${title} ${billOfDeliveryNumber}:`
    }

    return title;
}

