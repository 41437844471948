import {ParametricReactRoute, SimpleReactRoute} from "@common/model/ReactRoute";

export const ManagedCashRegisterRoute: SimpleReactRoute = {
    path: 'cash-register',
}

export const BranchCashRegisterRoute: ParametricReactRoute<'branchId'> = {
    path: 'cash-register/:branchId',
    paramNames: ['branchId'],
}

export const ManagedCardTransactionRoute: SimpleReactRoute = {
    path: 'card',
}

export const PartnerCardTransactionRoute: SimpleReactRoute = {
    path: 'card/partner',
}

export const BranchCardTransactionRoute: ParametricReactRoute<'branchId'> = {
    path: 'card/:branchId',
    paramNames: ['branchId'],
}

export const TransactionModuleRoutes = [
    ManagedCashRegisterRoute,
    BranchCashRegisterRoute,
    ManagedCardTransactionRoute,
    PartnerCardTransactionRoute,
    BranchCardTransactionRoute,
];
