import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {useSorter} from "@common/components/grids/GridSorter/useSorter";
import {packetWholeName} from "@common/utils/packetWholeName";
import {parseAmount} from "@common/utils/parseAmount";
import {
    PacketsListType
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/PacketsList/PacketsListType";

export type PacketSortingIds = 'default'|'shelf'|'consignee'|'sender'|'cod';

export const usePacketsListSorter = (listType: PacketsListType) => {
    return useSorter<DeliveryPacket, PacketSortingIds>(
        listType,
        {id: 'default'},
        {
            default: (packet) => packet.deliveryOrder,
            shelf: (packet) => (listType === 'delivered' ? packet.info.shelf : packet.info.storedUntil) || '-',
            consignee: (packet) => packetWholeName(packet.info),
            sender: (packet) => packet.sender ? packet.sender.name : '-',
            cod: (packet) => !packet.isCod ? 0 : (packet.paid ? 1 : parseAmount(packet.info.cod) + 1),
        }
    );
}
