import classNames from "classnames";
import {FC, Fragment, useCallback, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {setPacketShelf} from "@app/Packet/api/packetApi";
import {Packet} from "@app/Packet/model/Packet";
import {Img} from "@common/components/Img/Img";
import {appToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './editableShelf.scss';

export type EditableShelfProps = {
    packet: Packet;
    onEdited?: () => void;
}

type EditableShelfFormData = {
    shelf: string;
}

export const EditableShelf: FC<EditableShelfProps> = ({onEdited, packet}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [editing, setEditing] = useState<boolean>(false);
    const [editedShelf, setEditedShelf] = useState<string|null>(null);

    const form = useForm<EditableShelfFormData>({
        defaultValues: {shelf: packet.shelf || ''}
    });
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const handleClick = useCallback(() => {
        if (packet.isAtUsersBranch && !editing) {
            setEditing(true);
        }
    }, [editing, packet.isAtUsersBranch]);

    const showAppErrorToast = useShowErrorToast(appToast);

    const onSubmit = useBlockingCallback((unblock, formData: EditableShelfFormData) => {
        setIsSubmitting(true);
        setPacketShelf({barcode: packet.barcode, shelf: formData.shelf}, appContext.api)
            .then(() => {
                appToast.success(t('viewEditShelf:message.success'))
                setEditedShelf(formData.shelf);
                setEditing(false);

                if (onEdited) {
                    onEdited();
                }
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
            })
            .finally(() => {
                setIsSubmitting(false);
                unblock();
            });
    }, [appContext.api, onEdited, packet.barcode, showAppErrorToast, t]);

    useEffect(() => {
        if (editing) {
            form.setFocus('shelf');
        }
    }, [editing, form]);

    const formId = `editableShelfForm-${packet.barcode}`;
    const submitButtonText = t('viewEditShelf:action.submit');
    const cancelButtonText = t('viewEditShelf:action.cancel');

    return <Fragment>
        <div
            className={classNames('editable-shelf', {'editable-shelf_editable': packet.isAtUsersBranch})}
            onClick={handleClick}
            title={t(`viewEditShelf:title.${packet.isAtUsersBranch ? 'canEdit' : 'canNotEdit'}`)}
        >
            {!editing && <Fragment>{editedShelf !== null ? editedShelf : packet.shelf}</Fragment>}
            {editing && <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
                <input {...form.register('shelf')} className="form-control" />
                <div className="editable-shelf__buttons">
                    <Button variant="success" disabled={isSubmitting} onClick={form.handleSubmit(onSubmit)}>
                        <Img src="/images/icons/ico/ico-check-dark.svg" alt={submitButtonText} title={submitButtonText} />
                    </Button>
                    <Button variant="secondary" disabled={isSubmitting} onClick={() => setEditing(false)}>
                        <Img src="/images/icons/ico/ico-delete.svg" alt={cancelButtonText} title={cancelButtonText} />
                    </Button>
                </div>
            </form>}
        </div>
    </Fragment>
}
