import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const cashRegisterTransactionListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/cash-register/transaction/list/:branchId',
    routeParamNames: ['branchId'],
}

export const cashRegisterTransactionTypeListEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/cash-register/transaction-type/list',
}

export const confirmBalanceEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/cash-register/balance/confirm',
}

export const confirmTransferEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/cash-register/transfer/confirm',
}

export const correctButUsedGetTransferKnownErrorCodes = {
    transferFoundButAlreadyUsed: 'transferFoundButAlreadyUsed' as const,
}

const getTransferKnownErrorCodes = {
    ...correctButUsedGetTransferKnownErrorCodes,
    transferNotFound: 'transferNotFound' as const,
}

export const getBalanceEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/cash-register/balance/:branchId',
    routeParamNames: ['branchId'],
}

export const getTransferEndpoint: Endpoint<'password', keyof typeof getTransferKnownErrorCodes> = {
    method: HttpMethod.GET,
    url: '/cash-register/transfer/:password',
    routeParamNames: ['password'],
    knownErrorCodes: getTransferKnownErrorCodes,
}

export const managedCashRegisterListEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/cash-register/managed/list',
}

export const manualDepositEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/cash-register/manual-deposit',
}
