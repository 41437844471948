import {FC} from "react";
import {Route, Routes} from "react-router-dom";

import {PacketDetailRoute} from "@app/Packet/PacketRoutes";
import {PacketDetailPage} from "@app/Packet/pages/PacketDetailPage/PacketDetailPage";
import {NavigateWithQueryParams} from "@common/components/routing/NavigateWithQueryParams/NavigateWithQueryParams";
import {RouteParamsProvider} from "@common/components/routing/RouteParamsProvider/RouteParamsProvider";

export const Packet: FC = () => {
    return <Routes>
        <Route path="detail/:barcode" element={<NavigateWithQueryParams to={PacketDetailRoute} />} />
        <Route path={PacketDetailRoute.path} element={<RouteParamsProvider
            path={PacketDetailRoute}
            render={({barcode}) => <PacketDetailPage barcode={barcode} />}
        />} />
    </Routes>
}
