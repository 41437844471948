import {FC} from "react";
import {UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    NewMessageFormFields,
    OutgoingMessageFormData
} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {MessageAccessMode as MessageAccessModeEnum} from "@app/Messaging/model/MessagingRequestsAndResponses";
import {Select, SelectOption} from "@common/components/forms/Select/Select";

export type MessageAccessModeProps = {
    form: UseFormReturn<OutgoingMessageFormData>;
    formId: string;
}

export const MessageAccessMode: FC<MessageAccessModeProps> = ({form, formId}) => {
    const {t} = useTranslation();

    const options: Array<SelectOption> = [];
    for (const accessMode in MessageAccessModeEnum) {
        if (Object.prototype.hasOwnProperty.call(MessageAccessModeEnum, accessMode) && isNaN(Number(accessMode))) {
            const value = MessageAccessModeEnum[accessMode] as any as string;
            options.push({value, label: t(`message:tabs.content.new.accessModeSelect.item-${value}`)});
        }
    }

    return <Select
        name={NewMessageFormFields.accessMode}
        label={t('message:tabs.content.new.accessMode')}
        formId={formId}
        options={options}
        form={form}
    />
}
