import {FC, ReactElement, useEffect, useRef, useState} from "react";
import {useFieldArray, useFormContext, useFormState} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    EditableOpeningHoursGridRow
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/EditableOpeningHoursGridRow/EditableOpeningHoursGridRow";
import {
    OpeningHoursGrid
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGrid";
import {
    OpeningHoursHelpLink
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursHelpLink/OpeningHoursHelpLink";
import {
    OPENING_HOURS_GRID_FIELD_NAME,
    BaseOpeningHoursGridForm
} from "@app/OpeningHours/OpeningHoursFormTypes";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useErrorCodeToTranslationKey} from "@common/hooks/useErrorCodeToTranslationKey";
import {ApiError} from "@common/model/errors/ApiError";
import {ApiErrorItem} from "@common/model/errors/ApiErrorItem";

import './editableOpeningHoursGrid.scss';

export type EditableOpeningHoursGridProps = {
    apiError?: ApiError;
    apiErrorsIndexMap?: Map<number, number>;
    collapsibleMobile?: boolean;
    customRowHeader?: (date: string) => string|ReactElement|null;
    highlightExceptions?: boolean;
    onValidationError?: () => void;
}

export const EditableOpeningHoursGrid: FC<EditableOpeningHoursGridProps> = ({
    apiError,
    apiErrorsIndexMap,
    collapsibleMobile,
    customRowHeader,
    highlightExceptions,
    onValidationError,
}) => {
    const {t} = useTranslation();
    const {control, setError, watch} = useFormContext<BaseOpeningHoursGridForm>();
    const formState = useFormState({control});
    const {fields} = useFieldArray({
        control,
        name: OPENING_HOURS_GRID_FIELD_NAME,
        shouldUnregister: true,
    });
    const [rowsWithErrors, setRowsWithErrors] = useState<Map<number, string|undefined>>(new Map<number, string|undefined>());
    const submitCount = useRef<number>(0);

    useEffect(() => {
        if (formState.isSubmitted && !formState.isSubmitSuccessful && formState.submitCount > submitCount.current) {
            ++submitCount.current;
            modalToast.error(t('openingHours:error.pleaseFixFieldErrors'), {toastId: 'openingHours:error.pleaseFixFieldErrors'})

            if (formState.errors.openingHours) {
                const newRowsWithErrors = new Map<number, string|undefined>();
                Object.keys(formState.errors.openingHours).forEach((index) => {
                    newRowsWithErrors.set(parseInt(index, 10), undefined);
                })
                setRowsWithErrors(newRowsWithErrors);
            }

            if (onValidationError) {
                onValidationError();
            }
        }
    }, [formState, onValidationError, t]);

    useEffect(() => {
        const watcher = watch((_value, {name, type}) => {
            if (name && type === 'change') {
                const match = name.match(/\d+/g);
                if (match !== null) {
                    const rowIndex = parseInt(match[0], 10);
                    if (!isNaN(rowIndex)) {
                        rowsWithErrors.delete(rowIndex);
                        setRowsWithErrors(rowsWithErrors);
                    }
                }
            }
        });

        return () => watcher.unsubscribe();
    });

    const errorCodeToTranslationKey = useErrorCodeToTranslationKey('openingHours:error');

    useEffect(() => {
        if (apiError) {
            let hasFieldErrors = false;
            const indexMap = apiErrorsIndexMap || new Map<number, number>();
            const newRowsWithErrors: Map<number, string|undefined> = new Map<number, string|undefined>();
            apiError.errors.forEach((errorItem: ApiErrorItem) => {
                const message = errorCodeToTranslationKey(errorItem.code, apiError);
                let showError = true;
                if (errorItem.parent && errorItem.field) {
                    const match = errorItem.parent.match(/\d+/g);
                    if (match !== null) {
                        const rowIndex = parseInt(match[0], 10);
                        if (!isNaN(rowIndex)) {
                            showError = false;
                            const mappedRowIndex = indexMap.get(rowIndex) || rowIndex;
                            newRowsWithErrors.set(mappedRowIndex, t(message));
                            hasFieldErrors = true;
                            const blockIndex = parseInt(match[1], 10);
                            if (!isNaN(blockIndex)) {
                                const fieldName = errorItem.field === 'close' ? 'close' : 'open';
                                setError(
                                    `openingHours.${mappedRowIndex}.hours.${blockIndex}.${fieldName}` as const,
                                    {type: "validate", message: t(message)},
                                    {shouldFocus: !hasFieldErrors}
                                );
                            }
                        }
                    }
                }
                if (showError) {
                    modalToast.error(t(message));
                }
            });
            setRowsWithErrors(newRowsWithErrors);

            if (hasFieldErrors) {
                modalToast.error(t('openingHours:error.pleaseFixRowErrors'), {toastId: 'openingHours:error.pleaseFixFieldErrors'})
            }
        }
    }, [apiError, apiErrorsIndexMap, errorCodeToTranslationKey, setError, t])

    return <div className="editable-opening-hours-grid" data-xid="editable-opening-hours-grid">
        <OpeningHoursHelpLink />
        <OpeningHoursGrid editable={true}>
            {fields.map((field, index) => {
                return <EditableOpeningHoursGridRow
                    collapsibleMobile={collapsibleMobile}
                    customHeader={customRowHeader}
                    hasError={rowsWithErrors.has(index)}
                    highlightExceptions={highlightExceptions}
                    key={field.id}
                    openingHours={field}
                    rowIndex={index}
                    title={rowsWithErrors.get(index)}
                />
            })}
        </OpeningHoursGrid>
    </div>
}
