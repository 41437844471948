import {TFunction} from "i18next";
import {useCallback, useEffect, useState} from "react";
import {useForm, UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {deliverReturnPacket, returnPacketList} from "@app/Packet/api/returnPacketApi";
import {Packet} from "@app/Packet/model/Packet";
import {Sender} from "@app/Packet/model/Sender";
import {
    OnPasswordHandlingError, OnPasswordHandlingFinish, OnPasswordHandlingVerified
} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {SearchFormResult} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";
import {printReturnPacketList} from "@app/Print/api/printApi";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";

type ReturnPacketModalState = {
    modalOpened: boolean;
    submitting: boolean;
    packets: Packet[];
    sender: Sender|null;
}

type ReturnPacketDeliverFormData = {
    [barcode: string]: boolean;
}

export type UseReturnPacketModalParams = {
    onError: OnPasswordHandlingError;
    onFinish: OnPasswordHandlingFinish;
    onVerified: OnPasswordHandlingVerified;
    password: string;
}

export const useReturnPacketModal = ({onError, onFinish, onVerified, password}: UseReturnPacketModalParams): {
    close: () => void;
    doDeliver: (formData: ReturnPacketDeliverFormData) => void;
    doPrintList: (formData: ReturnPacketDeliverFormData) => void;
    form: UseFormReturn<ReturnPacketDeliverFormData>;
    state: ReturnPacketModalState;
    t: TFunction;
} => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [state, setState] = useState<ReturnPacketModalState>({
        modalOpened: false,
        submitting: false,
        packets: [],
        sender: null,
    });

    const showAppErrorToast = useShowErrorToast(appToast, 'viewReturnPacket:error', 'viewReturnPacket:error.general');

    useEffect(() => {
        returnPacketList({password, embed: [Embeddable.USER]}, appContext.api)
            .then((response) => {
                onVerified(password);
                setState({
                    modalOpened: true,
                    submitting: false,
                    packets: response.items,
                    sender: (response.embedded && response.embedded.sender && response.embedded.sender.length > 0)
                         ? response.embedded.sender[0]
                         : null,
                });
            })
            .catch((error: Error) => {
                onError(password, () => {
                    showAppErrorToast(error);
                });
            })
    }, [password, onError, t, showAppErrorToast, onVerified, appContext.api])

    const form = useForm<ReturnPacketDeliverFormData>({
        defaultValues: {},
    });

    const prepareBarcodes = useCallback((formData: ReturnPacketDeliverFormData): string[] => {
        const barcodes: string[] = [];
        for (const barcode in formData) {
            if (Object.prototype.hasOwnProperty.call(formData, barcode) && formData[barcode]) {
                barcodes.push(barcode);
            }
        }
        if (barcodes.length === 0) {
            modalToast.error(t('viewReturnPacket:error.notFound'));
        }

        return barcodes;
    }, [t]);

    const doPrintList = useBlockingCallback((unblock, formData: ReturnPacketDeliverFormData) => {
        const barcodes = prepareBarcodes(formData);
        if (barcodes.length === 0) {
            return;
        }

        setState({...state, submitting: true});
        printReturnPacketList({password, barcodes}, appContext.api)
            .then((response) => {
                appContext.print.addPrints(response.prints, response.timestamp);
                setState({...state, submitting: false})
                modalToast.success(t('viewReturnPacket:message.printSuccess'));
            })
            .catch((error: Error) => {
                setState({...state, submitting: false});
                modalToast.error(t(error.message));
            })
            .finally(unblock);
    }, [appContext.api, appContext.print, password, prepareBarcodes, state, t]);

    const doDeliver = useBlockingCallback((unblock, formData: ReturnPacketDeliverFormData) => {
        const barcodes = prepareBarcodes(formData);
        if (barcodes.length === 0) {
            unblock();
            return;
        }

        setState({...state, submitting: true});
        deliverReturnPacket({password, barcodes}, appContext.api)
            .then((response) => {
                appContext.print.addPrints(response.prints, response.timestamp);
                setState({
                    modalOpened: false,
                    submitting: false,
                    packets: [],
                    sender: null,
                })
                appToast.success(t('viewReturnPacket:message.success'));
                onFinish(SearchFormResult.RETURN_PACKET);
            })
            .catch((error: Error) => {
                setState({...state, submitting: false});
                modalToast.error(t(error.message));
            })
            .finally(unblock);
    }, [appContext.api, appContext.print, onFinish, password, prepareBarcodes, state, t]);

    const close = useCallback(() => {
        setState({
            modalOpened: false,
            submitting: false,
            packets: [],
            sender: null,
        });
        onFinish(SearchFormResult.RETURN_PACKET);
    }, [onFinish]);

    return {
        close,
        doPrintList,
        doDeliver,
        form,
        state,
        t,
    };
}
