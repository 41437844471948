import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {Img} from "@common/components/Img/Img";
import {ResponsiveRender} from "@common/components/ResponsiveRender/ResponsiveRender";

import './logo.scss';

export const Logo: FC = () => {
    const {i18n, t} = useTranslation();

    return <Fragment>
        <ResponsiveRender down="lg">
            <Img
                className="logo logo-mobile"
                src="/images/icons/zasilkovna_logo_square.png"
                alt={t('global:title')}
                title={t('global:title')}
            />
        </ResponsiveRender>
        <ResponsiveRender up="lg">
            <Img
                className="logo logo-desktop"
                src={`/images/icons/${i18n.language === "cs-CZ" ? 'zasilkovna' : 'packeta'}_logo.svg`}
                alt={t('global:title')}
                title={t('global:title')}
            />
        </ResponsiveRender>
    </Fragment>
}
