import {MessageTopic} from "@app/Messaging/model/MessageTopic";
import {
    CreateBaseMessageRequest, MessageAccessMode,
    MessageProcessMode,
    MessageType
} from "@app/Messaging/model/MessagingRequestsAndResponses";

export type BaseMessageFormData = Omit<CreateBaseMessageRequest, 'attachmentFilename'|'attachmentContent'> & {
    attachment: FileList|null;
}

export type IncomingMessageFormData = BaseMessageFormData & {
    topic: MessageTopic|-1;
    packetBarcode: string;
    otherTopic: string;
}

export type OutgoingMessageRecipientType = 'one'|'upload'|'region';

export type OutgoingMessageFormData = BaseMessageFormData & {
    subject: string;
    recipientType: OutgoingMessageRecipientType|null;
    oneRecipientId: string;
    oneRecipientName: string;
    csvRecipients: FileList|null;
    recipientCountryCode: string;
    repliesToMessageId: number|null;
    type: MessageType;
    processMode: MessageProcessMode;
    accessMode: MessageAccessMode;
    publishAt: Date|undefined;
    hideAt: Date|undefined;
}

export const NewMessageFormFields: {[field in keyof (IncomingMessageFormData & OutgoingMessageFormData)]: field} = {
    content: "content",
    attachment: "attachment",
    topic: "topic",
    subject: "subject",
    processMode: "processMode",
    accessMode: "accessMode",
    publishAt: "publishAt",
    hideAt: "hideAt",
    repliesToMessageId: "repliesToMessageId",
    type: "type",
    recipientCountryCode: "recipientCountryCode",
    otherTopic: "otherTopic",
    packetBarcode: "packetBarcode",
    recipientType: "recipientType",
    oneRecipientId: "oneRecipientId",
    oneRecipientName: "oneRecipientName",
    csvRecipients: "csvRecipients",
}

