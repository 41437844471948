import {PropsWithChildren, ReactElement, useEffect, useState} from "react";
import {Params, useParams} from "react-router-dom";

import {ParametricReactRoute} from "@common/model/ReactRoute";

export type RouteParamsProviderProps<ReactRouteParamNames extends string = string> = {
    path: ParametricReactRoute<ReactRouteParamNames>;
    render: (params: Record<ReactRouteParamNames, string>) => ReactElement|null;
}

export const RouteParamsProvider = <ReactRouteParamNames extends string = string, >({
    path,
    render,
}: PropsWithChildren<RouteParamsProviderProps<ReactRouteParamNames>>): ReactElement|null => {
    const paramsInRoute = useParams<ReactRouteParamNames>() as Params<ReactRouteParamNames>;
    const [providedParams, setProvidedParams] = useState<CompleteParams<Params<ReactRouteParamNames>>|null>(null);

    useEffect(() => {
        if (isCompleteParams(paramsInRoute)) {
            setProvidedParams(paramsInRoute);
        } else {
            setProvidedParams(null);
        }
    }, [paramsInRoute, path, providedParams]);

    if (providedParams) {
        return render(providedParams);
    }

    return null;
}

type CompleteParams<PartialParams extends Params> = {
    [Property in keyof PartialParams]: string;
}

const isCompleteParams = <PartialParams extends Params>(variable: Params): variable is CompleteParams<PartialParams> => {
    for (const variableKey in variable) {
        if (variable[variableKey] === undefined) {
            return false;
        }
    }

    return true;
}
