import {FC, Fragment, useMemo} from "react";
import {Tab, Tabs} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {IncomingParcelGrid} from "@app/Parcel/pages/BranchParcelListPage/IncomingParcelGrid/IncomingParcelGrid";
import {IncomingParcelListManager} from "@app/Parcel/pages/BranchParcelListPage/IncomingParcelListManager";
import {OutgoingParcelGrid} from "@app/Parcel/pages/BranchParcelListPage/OutgoingParcelGrid/OutgoingParcelGrid";
import {OutgoingParcelListManager} from "@app/Parcel/pages/BranchParcelListPage/OutgoingParcelListManager";
import {BranchParcelListFilter} from "@app/Parcel/pages/BranchParcelListPage/ParcelListFilter/BranchParcelListFilter";
import {Alert} from "@common/components/Alert/Alert";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ResponsiveRender} from "@common/components/ResponsiveRender/ResponsiveRender";
import {useFeatureFlag} from "@common/hooks/useFeatureFlag";
import {useOnLanguageChanged} from "@common/hooks/useOnLanguageChanged";

import './branchParcelListPage.scss';

export type BranchParcelListPageProps = {
    branchId: string;
}

export const BranchParcelListPage: FC<BranchParcelListPageProps> = ({branchId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const showParcels = useFeatureFlag('packetParcelLists', true);

    const incomingParcelListManager = useMemo(() => new IncomingParcelListManager(branchId, appContext.api), [appContext.api, branchId]);
    const outgoingParcelListManager = useMemo(() => new OutgoingParcelListManager(branchId, appContext.api), [appContext.api, branchId]);

    useOnLanguageChanged(() => {
        void incomingParcelListManager.reload();
        void outgoingParcelListManager.reload();
    }, [incomingParcelListManager, outgoingParcelListManager]);

    return <PageContent className="branch-parcel-list-page">
        <PageHeader>{t('parcel:property.parcels')}</PageHeader>
        {showParcels && <Fragment>
            <BranchParcelListFilter
                incomingParcelListManager={incomingParcelListManager}
                outgoingParcelListManager={outgoingParcelListManager}
            />
            <ResponsiveRender up="xl">
                <div className="branch-parcel-list-page-desktop">
                    <IncomingParcelGrid manager={incomingParcelListManager} />
                    <OutgoingParcelGrid manager={outgoingParcelListManager} />
                </div>
            </ResponsiveRender>
            <ResponsiveRender down="xl">
                <div className="branch-parcel-list-page-mobile">
                    <Tabs defaultActiveKey="incoming" >
                        <Tab eventKey="incoming" title={t('viewParcel:incomingParcels')}>
                            <IncomingParcelGrid manager={incomingParcelListManager} />
                        </Tab>
                        <Tab eventKey="outgoing" title={t('viewParcel:outgoingParcels')}>
                            <OutgoingParcelGrid manager={outgoingParcelListManager} />
                        </Tab>
                    </Tabs>
                </div>
            </ResponsiveRender>
        </Fragment>}
        {!showParcels && <Alert type="warning">
            {t('viewParcel:error.parcelsDisabled')}
        </Alert>}
    </PageContent>
}
