import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {MessageListType} from "@app/Messaging/components/MessagesList/MessageListType";
import {
    ReceivedMessagesList
} from "@app/Messaging/components/MessagesList/ReceivedMessagesList/ReceivedMessagesList";
import {
    SentMessagesList
} from "@app/Messaging/components/MessagesList/SentMessagesList/SentMessagesList";

import './messagesList.scss';

export type MessagesListProps = {
    type: MessageListType;
}

export const MessagesList: FC<MessagesListProps> = observer(({type}) => {
    const {t} = useTranslation();

    return <div className={classNames('messages-list', {[`messages-list--${type}`]: true})}>
        <h3>{t(`message:tabs.${type}`)}</h3>
        {type === 'received' && <ReceivedMessagesList />}
        {type === 'sent' && <SentMessagesList />}
    </div>
})
