import {useTranslation} from "react-i18next";

import {BranchQualityCustomerFeedbackItems} from "@app/Branch/model/BranchQuality/BranchQualityCustomerFeedbackItems";
import {FCWithChildren} from "@common/model/FCWithChildren";
import {camelize} from "@common/utils/camelize";

export type CustomerFeedbackItemProps = {
    itemType: BranchQualityCustomerFeedbackItems;
}

export const CustomerFeedbackItem: FCWithChildren<CustomerFeedbackItemProps> = ({children, itemType}) => {
    const {t} = useTranslation();

    return <div className="branch-quality-page__item">
        <h6>{t(`branchQuality:customerFeedback.${camelize(itemType)}.title`)}</h6>
        {children}
    </div>

}
