import classNames from "classnames";
import {FC} from "react";

import {ParcelStatus, ParcelStatusId} from "@app/Parcel/model/ParcelStatus";

import "./parcelStatusBadge.scss";

export type ParcelStatusBadgeProps = {
    status: ParcelStatus;
}

enum BadgeColor {
    GREEN = 'green',
    BLUE = 'blue',
}

const statusClassMap = new Map<ParcelStatusId, BadgeColor>();
statusClassMap.set(ParcelStatusId.SENT, BadgeColor.BLUE);
statusClassMap.set(ParcelStatusId.CONFIRMED, BadgeColor.GREEN);

export const ParcelStatusBadge: FC<ParcelStatusBadgeProps> = ({status}) => {
    const badgeClass = statusClassMap.has(status.id) ? statusClassMap.get(status.id) : 'default'

    return <span className={classNames('parcel-status-badge', badgeClass)}>
        {status.name}
    </span>
}
