import {useCallback} from "react";

import {CardPaymentState, CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";

export const useConfirmSignature = (
    cardPaymentState: CardPaymentState,
    setCardPaymentState: (newState: CardPaymentState) => void,
) => {
    return useCallback(() => {
        if (cardPaymentState.status === CardPaymentStatus.READY) {
            return;
        }

        setCardPaymentState({
            ...cardPaymentState,
            transaction: {
                ...cardPaymentState.transaction,
                status: CardPaymentTransactionStatus.CREATED,
                signatureVerified: true,
            }
        })
    }, [cardPaymentState, setCardPaymentState]);
}
