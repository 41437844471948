import {format, getHours, getMinutes, setHours, setMinutes} from "date-fns";
import {DefaultTFuncReturn} from "i18next";
import {ChangeEvent, FC, ReactElement, useCallback, useEffect, useState} from "react";
import {Dropdown} from "react-bootstrap";
import {DayPickerProps} from "react-day-picker";
import {useTranslation} from "react-i18next";

import {Button} from "@common/components/Button/Button";
import {DatePickerInput} from "@common/components/forms/DatePicker/DatePickerInput/DatePickerInput";
import {DayPicker} from "@common/components/forms/DayPicker/DayPicker";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";

import './datePicker.scss';

export type DatePickerProps = {
    className?: string;
    dateFormat?: DateFormatType;
    dayPickerProps?: DayPickerProps;
    formId: string;
    label?: DefaultTFuncReturn|ReactElement|ReactElement[];
    name: string
    onClear?: () => void;
    onDateSelect: (date: Date) => void;
    open?: boolean;
    placeholder?: string;
    showTimeSelect?: boolean;
    value?: Date;
    xid?: string;
}

export const DatePicker: FC<DatePickerProps> = ({
    className,
    dateFormat,
    dayPickerProps,
    formId,
    label,
    name,
    onClear,
    onDateSelect,
    open,
    placeholder,
    showTimeSelect,
    value,
    xid,
}) => {
    const {t} = useTranslation();
    const [opened, setOpened] = useState<boolean>(open || false);
    const [newDate, setNewDate] = useState<Date|null>(null);

    const dateFormatter = useDateFormatter();

    const toggle = useCallback(() => {
        setOpened(!opened);
    }, [opened]);

    const onDayClick = useCallback((day: Date) => {
        const selectedDay = !value ? day : setHours(setMinutes(day, getMinutes(value)), getHours(value));
        if (showTimeSelect) {
            setNewDate(selectedDay);
        } else  {
            onDateSelect(selectedDay);
            toggle();
        }
    }, [onDateSelect, showTimeSelect, toggle, value]);

    const onHoursSelect = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        setNewDate(setHours(newDate || new Date(), parseInt(event.target.value, 10)));
    }, [newDate]);

    const onMinutesSelect = useCallback((event: ChangeEvent<HTMLSelectElement>) => {
        setNewDate(setMinutes(newDate || new Date(), parseInt(event.target.value, 10)));
    }, [newDate]);

    const confirmTime = useCallback(() => {
        if (!newDate) {
            toggle();
            return;
        }
        onDateSelect(newDate);
        setNewDate(null);
        toggle();
    }, [newDate, onDateSelect, toggle]);

    useEffect(() => {
        if (open) {
            setOpened(open);
        }
    }, [open]);

    const inputId = `${formId}-${name}`;

    return <div className="form-group date-picker" data-xid={xid}>
        <div className="form-group-label">{label && <label htmlFor={inputId}>{label}</label>}</div>
        <div className="form-group-input">
            <Dropdown show={opened} onToggle={toggle}>
                <Dropdown.Toggle
                    as={DatePickerInput}
                    value={value ? dateFormatter(value, dateFormat || DateFormatType.DATE_FORMAT) : ''}
                    placeholder={placeholder}
                    onClear={onClear}
                    className={className}
                    readOnly={true}
                    id={inputId}
                />
                <Dropdown.Menu>
                    {!newDate && <DayPicker {...dayPickerProps} onDayClick={onDayClick} />}
                    {showTimeSelect && newDate && <div>
                        <div className="date-picker__new-date">{dateFormatter(newDate, DateFormatType.DATE_FORMAT)}</div>
                        <div className="date-picker__time-select">
                            <select onChange={onHoursSelect} value={newDate ? format(newDate, 'H') : undefined}>
                                {[...Array(24).keys()].map((index) => {
                                    return <option key={index} value={index.toString()}>{index.toString().padStart(2, '0')}</option>
                                })}
                            </select>
                            {' : '}
                            <select onChange={onMinutesSelect} value={newDate ? format(newDate, 'm') : undefined}>
                                {[...Array(60).keys()].map((index) => {
                                    return <option key={index} value={index.toString()}>{index.toString().padStart(2, '0')}</option>
                                })}
                            </select>
                            <div>
                                <Button variant="dark" onClick={confirmTime}>{t('dateRangePicker:set')}</Button>
                            </div>
                        </div>
                    </div>}
                </Dropdown.Menu>
            </Dropdown>
        </div>
    </div>
}
