import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {WebPaymentButton} from "@app/CardPayment/components/WebPaymentButton/WebPaymentButton";
import {PrintButtons} from "@app/Packet/components/PacketDelivery/components/PrintButtons/PrintButtons";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";

export type PacketDeliveryButtonsProps = {
    packets: DeliveryPacketsCollection;
    onDeliveryComplete: () => void;
    onRunning: (running: boolean) => void;
    running: boolean;
    setWebPaymentRunning: (webPaymentRunning: boolean) => void;
    verificationFormsSubmit: () => Promise<void>;
}

export const PacketDeliveryButtons: FC<PacketDeliveryButtonsProps> = observer(({
    onDeliveryComplete,
    onRunning,
    packets,
    running,
    setWebPaymentRunning,
    verificationFormsSubmit,
}) => {
    const {t} = useTranslation();

    const undeliver = useCallback(() => {
        if (packets.deliveryReady.length > 0) {
            packets.deliveryReady[0].requireUndelivery();
        }
    }, [packets.deliveryReady]);

    const doDeliver = useCallback(async () => {
        if (packets.isPasswordDelivery) {
            onDeliveryComplete();
        } else {
            await verificationFormsSubmit();
        }
    }, [onDeliveryComplete, packets.isPasswordDelivery, verificationFormsSubmit]);

    const webPaymentOnRunning = useCallback((webPaymentRunning: boolean) => {
        onRunning(webPaymentRunning);
        setWebPaymentRunning(webPaymentRunning);
    }, [onRunning, setWebPaymentRunning]);

   return <Fragment>
        <WebPaymentButton packets={packets} disabled={running} onRunning={webPaymentOnRunning} />
        <PrintButtons packets={packets.deliveryReady} disabled={running} />
        {packets.isPasswordDelivery && packets.deliveryReady.length === 1 && <Button
            onClick={undeliver}
            variant="warning"
            disabled={running}
            data-xid="undeliver-packet"
        >
            {t('packet:action.cancel')}
        </Button>}
       {!packets.isPasswordDelivery && <Button
           onClick={() => onDeliveryComplete()}
           variant="outline-danger"
           disabled={running}
           data-xid="cancel-before-delivery"
       >
           {t('packet:action.cancelBeforeDeliver')}
       </Button>}
       <Button onClick={doDeliver} variant="success" disabled={running} data-xid="deliver-packet">
            {t(`packet:action.deliver.${packets.deliveryReady.length > 1 ? 'batch' : 'single'}`)}
        </Button>
    </Fragment>
})
