import {FC, Fragment, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {CancelJobModal} from "@app/Devices/components/ReaderModal/components/CancelJobModal/CancelJobModal";
import {ReaderJobActions} from "@app/Devices/components/ReaderModal/components/ReaderJobActions/ReaderJobActions";
import {ReaderJobDetail} from "@app/Devices/components/ReaderModal/components/ReaderJobDetail/ReaderJobDetail";
import {ReaderJobList} from "@app/Devices/components/ReaderModal/components/ReaderJobList/ReaderJobList";
import {UnknownActionForm} from "@app/Devices/components/ReaderModal/components/UnknownActionForm/UnknownActionForm";
import {
    useUnknownActionForm
} from "@app/Devices/components/ReaderModal/components/UnknownActionForm/useUnknownActionForm";
import {useReaderJobs} from "@app/Devices/components/ReaderModal/hooks/useReaderJobs";
import {jobTitle} from "@app/Devices/components/ReaderModal/utils/jobTitle";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";
import {Button} from "@common/components/Button/Button";
import {BaseModal, BaseModalMandatoryProps} from "@common/components/modals/BaseModal/BaseModal";

import './readerModal.scss';

export type ReaderModalProps = {
    onLoaded: () => void;
}

export const ReaderModal: FC<BaseModalMandatoryProps & ReaderModalProps> = ({onHide, onLoaded, show}) => {
    const {t} = useTranslation();
    const [jobForCancelling, setJobForCancelling] = useState<ReaderJob|null>(null);

    const {
        callJobAction,
        closeModal,
        jobs,
        load,
        loading,
        removeJob,
        selectJob,
        selectedJob,
    } = useReaderJobs(onLoaded, onHide);

    const unknownActionForm  = useUnknownActionForm(callJobAction, selectedJob);

    useEffect(() => {
        if (show && jobs === null) {
            load();
        }
    }, [jobs, load, show]);

    if (!show || jobs === null) {
        return null;
    }

    return <BaseModal
        show={show}
        onHide={closeModal}
        loading={loading}
        header={selectedJob ? jobTitle(selectedJob, t) : t('reader:genericTitles.moreJobs')}
        footer={<Fragment>
            {!selectedJob && <Button onClick={closeModal} variant="secondary">{t('reader:action.cancel')}</Button>}
            {selectedJob && <ReaderJobActions
                callJobAction={callJobAction}
                closeModal={closeModal}
                job={selectedJob}
                removeJob={removeJob}
                setJobForCancelling={setJobForCancelling}
                unknownActionForm={unknownActionForm}
            />}
        </Fragment>}
        height="auto"
        className="reader-modal"
        disableOutsideClose={true}
        closeButton={false}
        xid="reader-modal"
    >
        {selectedJob && <Fragment>
            {selectedJob.type === ReaderJobType.UNKNOWN
                ? <UnknownActionForm unknownActionForm={unknownActionForm} />
                : <ReaderJobDetail job={selectedJob} />
            }
        </Fragment>}
        {!selectedJob && <ReaderJobList jobs={jobs} selectJob={selectJob} setJobForCancelling={setJobForCancelling} />}
        {jobForCancelling && <CancelJobModal
            job={jobForCancelling}
            callJobAction={callJobAction}
            removeJob={removeJob}
            setJobForCancelling={setJobForCancelling}
        />}
    </BaseModal>
}
