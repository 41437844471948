import {NOT_FOUND_ERROR_CODE} from "@app/AppContext/classes/Api/model/Endpoint";
import {ApiError} from "@common/model/errors/ApiError";

import {getApiErrorCode} from "./getApiErrorCode";

export const isKnownError = (error: Error, errorCode: string|null = null): boolean => {
    const errorEndpoint = error instanceof ApiError ? error.endpoint : undefined;

    const errorCodes = errorEndpoint && errorEndpoint.knownErrorCodes ? Object.keys(errorEndpoint.knownErrorCodes) : [];
    if (errorEndpoint && errorEndpoint.notFoundErrorResponse) {
        errorCodes.push(NOT_FOUND_ERROR_CODE);
    }

    errorCode = errorCode || getApiErrorCode(error);
    return errorCode !== null && errorCodes.includes(errorCode);
}
