import {useEffect, useRef, useState} from "react";
import useWebSocket, {ReadyState} from "react-use-websocket";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {PDCServerHwInfoResponse} from "@app/PDC/model/PDCServerRequestsAndResponses";
import {useAppConfig} from "@common/hooks/useAppConfig";
import {createURL} from "@common/utils/createURL";

export const useHwInfo = () => {
    const {deviceGatewayResolver} = useAppContext();
    const {pdcHost, pdcNonSecure, pdcPort} = useAppConfig();
    const didUnmount = useRef<boolean>(false);
    const [connect, setConnect] = useState<boolean>(false);

    const {lastJsonMessage, readyState} = useWebSocket<PDCServerHwInfoResponse|null>(
    pdcHost ? createURL('ws', `${pdcHost}/hwinfochannel/`, pdcPort, pdcNonSecure).toString() : null,
        {
            retryOnError: true,
            shouldReconnect: () => !didUnmount.current,
            reconnectAttempts: 3,
            reconnectInterval: 10000,
        },
        connect,
    );

    useEffect(() => {
        setConnect(deviceGatewayResolver.isPdc);

        return () => {
            didUnmount.current = true;
        }
    }, [deviceGatewayResolver.isPdc]);

    return readyState === ReadyState.OPEN && lastJsonMessage && lastJsonMessage.payload ? lastJsonMessage.payload : null;
}
