import {observer} from "mobx-react-lite";
import {FC, ReactElement} from "react";

import {
    ExtraHeadersRender,
    PacketGridHeader
} from "@app/Packet/components/PacketGrid/PacketGridHeader/PacketGridHeader";
import {PacketGridRow} from "@app/Packet/components/PacketGrid/PacketGridRow/PacketGridRow";
import {Packet} from "@app/Packet/model/Packet";
import {PacketListManager} from "@app/Packet/model/PacketListManager";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";

import './packetGrid.scss';

export type ExtraColumnsRender = (packet: Packet) => ReactElement|null;

export type PacketGridProps = {
    extraColumnsRender?: ExtraColumnsRender;
    extraHeadersRender?: ExtraHeadersRender;
    gridName: string;
    manager: PacketListManager;
    showAddress?: boolean;
    showShelf?: boolean;
    topCountContent?: ReactElement;
}

export const PacketGrid: FC<PacketGridProps> = observer(({
    extraColumnsRender: extraColumnsRender,
    extraHeadersRender,
    gridName,
    manager,
    showAddress,
    showShelf,
    topCountContent,
}) => {
   return <PaginatedGrid
       manager={manager}
       className="packet-grid"
       topCountContent={topCountContent}
   >
       <PacketGridHeader
           gridName={gridName}
           manager={manager}
           showAddress={showAddress}
           showShelf={showShelf}
           extraHeadersRender={extraHeadersRender}
       />
       <tbody>
           {manager.items.map((packet) => (<PacketGridRow
               key={packet.barcode}
               packet={packet}
               sender={packet.senderId ? manager.senders.get(packet.senderId) : undefined}
               branch={showAddress && packet.addressId ? manager.branches.get(packet.addressId) : undefined}
               showAddress={showAddress}
               showShelf={showShelf}
           >
               {extraColumnsRender ? extraColumnsRender(packet) : null}
           </PacketGridRow>))}
       </tbody>
   </PaginatedGrid>
})
