import {action, computed, makeObservable, observable} from "mobx";

import {Api} from "@app/AppContext/classes/Api/Api";
import {DeviceGateway} from "@app/Devices/model/DeviceGateway";
import {DeviceGatewayType} from "@app/Devices/model/DeviceGatewayType";
import {isPdcAvailable, pdcDeviceId, pdcVersion} from "@app/PDC/api/pdcApi";

export class DeviceGatewayResolver {
    public deviceGateway: DeviceGateway|null = null;
    public deviceGatewayPromise: Promise<DeviceGateway>|null = null;

    public constructor(public api: Api) {
        makeObservable(this, {
            deviceGateway: observable,
            isPdc: computed,
            setDeviceGateway: action,
        });
    }

    public get isPdc(): boolean {
        return this.deviceGateway !== null && this.deviceGateway.deviceGatewayType === DeviceGatewayType.PDC;
    }

    public async resolve(): Promise<DeviceGateway> {
        if (this.deviceGatewayPromise) {
            return this.deviceGatewayPromise;
        }

        this.deviceGatewayPromise = this.fetchPDCDeviceGateway();
        const deviceGateway = await this.deviceGatewayPromise;
        this.setDeviceGateway(deviceGateway);
        this.deviceGatewayPromise = null;

        return deviceGateway;
    }

    public setDeviceGateway(deviceGateway: DeviceGateway) {
        this.deviceGateway = deviceGateway;
    }

    private async fetchPDCDeviceGateway() {
        const deviceGateway: DeviceGateway = {
            deviceGatewayId: null,
            deviceGatewayType: DeviceGatewayType.ZPI,
        }

        try {
            const pdcAvailable = await isPdcAvailable(this.api);
            if (!pdcAvailable) {
                return deviceGateway;
            }

            deviceGateway.deviceGatewayType = DeviceGatewayType.DS;
            const deviceIdResponse = await pdcDeviceId(this.api);
            if (typeof deviceIdResponse !== 'boolean' && deviceIdResponse.payload && deviceIdResponse.payload.deviceId) {
                deviceGateway.deviceGatewayId = deviceIdResponse.payload.deviceId;
            }

            const versionResponse = await pdcVersion(this.api);
            if (typeof versionResponse !== 'boolean'
                && versionResponse.payload
                && versionResponse.payload.version
                && versionResponse.payload.version.startsWith('PDC')
            ) {
                deviceGateway.deviceGatewayType = DeviceGatewayType.PDC;
            }
        } catch (error: unknown) {}

        return deviceGateway;
    }
}
