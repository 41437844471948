import {Transaction} from "@app/Transaction/model/Transaction";
import {compareAmounts} from "@common/utils/compareAmounts";

export type PartnerCardTransaction = Transaction & {
    transactionId: string;
    reference: string|null;
}

export const isSalePartnerCardTransaction = (transaction: PartnerCardTransaction): boolean => {
    return compareAmounts(transaction.amount, '0') !== -1;
}
