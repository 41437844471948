import * as Sentry from "@sentry/react";
import {action, computed, makeObservable, observable} from "mobx";

import {OauthToken} from "@app/AppContext/classes/User/model/OauthToken";
import {UserData, UserState} from "@app/AppContext/classes/User/model/UserData";
import {
    IdentityUser,
    isImpersonated,
    UserAccountType,
    UserIdentity
} from "@app/AppContext/classes/User/model/UserIdentity";

export class User {
    public user: UserData = {state: UserState.ANONYMOUS};
    public checked = false;

    public constructor() {
        makeObservable(this, {
            user: observable,
            checked: observable,
            isLoggedIn: computed,
            loggedInAt: computed,
            expiresAt: computed,
            token: computed,
            accessToken: computed,
            identity: computed,
            identityUser: computed,
            name: computed,
            email: computed,
            branchId: computed,
            supportBranchId: computed,
            isInternalUser: computed,
            setData: action,
            check: action,
            uncheck: action,
        });
    }

    public get isLoggedIn(): boolean {
        return this.user.state === UserState.AUTHENTICATED && this.checked;
    }

    public get loggedInAt(): Date|null {
        return this.user.state === UserState.AUTHENTICATED ? this.user.loggedInAt : null;
    }

    public get logoutReason(): string|null {
        return this.user.state === UserState.ANONYMOUS && this.user.logoutReason || null;
    }

    public get expiresAt(): Date|null {
        return this.user.state === UserState.AUTHENTICATED ? this.user.expiresAt : null;
    }

    public get token(): OauthToken|null {
        return this.user.state === UserState.AUTHENTICATED ? this.user.data.token : null;
    }

    public get accessToken(): string|null {
        return this.token ? this.token.accessToken : null;
    }

    public get identity(): UserIdentity|null {
        return this.user.state === UserState.AUTHENTICATED ? this.user.data.identity : null;
    }

    public get identityUser(): IdentityUser|null {
        if (!this.identity) {
            return null;
        }

        return this.identity.user;
    }

    public get name(): string|null {
        if (!this.identityUser) {
            return null;
        }

        return this.identityUser.name;
    }

    public get email(): string|null {
        if (!this.identityUser) {
            return null;
        }

        return this.identityUser.email;
    }

    public get branchId(): string|null {
        if (!this.identityUser) {
            return null;
        }

        return this.identityUser.branchId;
    }

    public get supportBranchId(): number|null {
        if (!this.identity) {
            return null;
        }

        return this.identity.supportBranchId;
    }

    public get isInternalUser(): boolean {
        if (!this.identityUser) {
            return false;
        }

        return [
            UserAccountType.INTERNAL,
            UserAccountType.BRANCH_SUPER_MANAGER,
        ].includes(this.identityUser.accountType);
    }

    public get isImpersonated(): boolean {
        return this.identity !== null && isImpersonated(this.identity);
    }

    public get impersonationToken(): string|null {
        return this.identity !== null && isImpersonated(this.identity) ? this.identity.impersonationToken : null;
    }

    public refreshToken(originalRefreshToken: boolean = false): string|null {
        if (!this.user) {
            return null;
        }

        if (originalRefreshToken && this.identity && isImpersonated(this.identity)) {
            return this.identity.originalRefreshToken;
        }

        return this.token ? this.token.refreshToken : null;
    }

    public setData(user: UserData): void {
        this.user = user;

        Sentry.setUser(this.isLoggedIn ? {
            authenticated: true,
            email: this.email || undefined,
            name: this.name || undefined,
            isImpersonated: this.isImpersonated,
            branchId: this.supportBranchId,
        } : {
            authenticated: false,
            logoutReason: this.logoutReason,
        });
    }

    public check(): void {
        this.checked = true;
    }

    public uncheck(): void {
        this.checked = false;
    }
}
