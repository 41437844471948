import {FC, Fragment, useEffect} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useForm, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {finalize} from "@app/MicroDepot/api/microDepotApi";
import {MicroDepotRouteSummary} from "@app/MicroDepot/model/MicroDepotRouteSummary";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {parseAmount} from "@common/utils/parseAmount";

export type FinalizeTabProps = {
    password: string;
    routeSummary: MicroDepotRouteSummary;
    onFinish: () => void;
}

type FinalizeFormData = {
    collectedCashAmount: string;
    collectedCashAmountIssueReason: string;
}

const MIN_REASON_LENGTH = 5;

export const FinalizeTab: FC<FinalizeTabProps> = ({onFinish, password, routeSummary}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const form = useForm<FinalizeFormData>({
        defaultValues: {collectedCashAmount: '', collectedCashAmountIssueReason: ''},
        mode: 'onSubmit',
    });

    const expectedCashAmount = parseAmount(routeSummary.expectedCash);
    const collectedCashAmount = useWatch({control: form.control, name: 'collectedCashAmount'});
    const reasonFieldNeeded = form.formState.isSubmitted && parseAmount(collectedCashAmount) < expectedCashAmount;

    const showModalErrorToast = useShowErrorToast(modalToast, 'viewRouteFinalization:error');

    const confirm = useBlockingCallback((unblock, formData: FinalizeFormData) => {
        if (parseAmount(formData.collectedCashAmount) < expectedCashAmount && !formData.collectedCashAmountIssueReason) {
            form.setError('collectedCashAmountIssueReason', {
                type: 'required',
                message: t<string>('viewRouteFinalization:error.reasonNeeded'),
            });
            setTimeout(() => {
                form.setFocus('collectedCashAmountIssueReason');
            }, 0);
            unblock();
            return;
        }

        finalize({
            password,
            collectedCashAmount: `${parseAmount(formData.collectedCashAmount)}`,
            collectedCashAmountIssueReason: formData.collectedCashAmountIssueReason || null,
        }, appContext.api)
            .then((response) => {
                appContext.print.addPrints(response.prints, response.timestamp);
                appToast.success(t('viewRouteFinalization:message.success'));
                onFinish();
            })
            .catch((error: ApiError) => {
                showModalErrorToast(error);
            })
            .finally(unblock);
    }, [appContext.api, appContext.print, expectedCashAmount, form, onFinish, password, showModalErrorToast, t]);

    useEffect(() => {
        if (!reasonFieldNeeded && !form.formState.isSubmitted) {
            setTimeout(() => {
                form.setFocus('collectedCashAmount');
            }, 0);
        }
    }, [form, reasonFieldNeeded]);

    const formId = 'routeFinalizationForm';

    return <Fragment>
        {routeSummary.depotMissingPacketCount !== 0 && <p className="text-danger">
            {t('viewRouteFinalization:finalize.depotMissingPackets')}
        </p>}
        <p>
            {t('viewRouteFinalization:finalize.expectedCollectedCash') + ': '}
            <strong><MoneyFormatter amount={routeSummary.expectedCash} currencyCode={routeSummary.currency} /></strong>
        </p>
        <form id={formId} onSubmit={form.handleSubmit(confirm)}>
            <FormGroup
                name="collectedCashAmount"
                label={t('viewRouteFinalization:form.collectedCashAmount')}
                formId={formId}
                registerOptions={{
                    required: t<string>('viewRouteFinalization:error.collectedCashAmount.required'),
                    pattern: {
                        value: /^\d+([.,]\d+)?$/,
                        message: t('viewRouteFinalization:error.collectedCashAmount.invalidFormat'),
                    },
                    validate: (value: string) => parseAmount(value) <= expectedCashAmount
                        || t<string>('viewRouteFinalization:error.collectedCashAmount.tooHigh'),
                }}
                form={form}
            />
            {reasonFieldNeeded && <FormGroup
                name="collectedCashAmountIssueReason"
                label={t('viewRouteFinalization:form.collectedCashAmountIssueReason')}
                formId={formId}
                registerOptions={{
                    validate: {
                        required: (value: string) => {
                            const formValues = form.getValues();
                            return parseAmount(formValues.collectedCashAmount) === expectedCashAmount
                                || value !== ''
                                || t<string>('viewRouteFinalization:error.collectedCashAmountIssueReason.required');
                        },
                    },
                    minLength: {
                        value: MIN_REASON_LENGTH,
                        message: t<string>('viewRouteFinalization:error.collectedCashAmountIssueReason.tooShort', {minLength: MIN_REASON_LENGTH}),
                    },
                }}
                form={form}
            />}
            <Row className="justify-content-end">
                <Col xl="auto" lg={4} sm={6} className="mt-xl-0 mt-2">
                    <Button variant="secondary" onClick={onFinish}>
                        {t('viewRouteFinalization:action.cancel')}
                    </Button>
                </Col>
                <Col xl={'auto'} lg={4} sm={6} className="mt-xl-0 mt-2">
                    <Button onClick={form.handleSubmit(confirm)}>
                        {t('viewRouteFinalization:action.confirm')}
                    </Button>
                </Col>
            </Row>
        </form>
    </Fragment>
}
