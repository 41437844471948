import {DashboardInfoManager} from "@app/AppContext/classes/DashboardInfoManager";
import {DashboardBranchCountry} from "@app/AppContext/model/DashboardBranchCountry";
import {SupportContactItem, SupportContacts} from "@app/AppContext/model/SupportContacts";

export const contactsByCountry = (dashboardInfo: DashboardInfoManager, full: boolean): [keyof SupportContacts, SupportContactItem][] => {
    const branchCountry = dashboardInfo.branchCountry;
    const supportContacts = dashboardInfo.supportContacts;

    if (branchCountry === null || supportContacts === null) {
        return [];
    }

    switch (branchCountry) {
        case DashboardBranchCountry.CZ: {
            const result: [keyof SupportContacts, SupportContactItem][] = [
                ['branchCustomerSupport', supportContacts.branchCustomerSupport],
                ['technicalSupport', supportContacts.technicalSupport],
                ['paymentTerminalSupport', supportContacts.paymentTerminalSupport],
            ];
            if (full) {
                result.push(['customerSupport', supportContacts.customerSupport]);
            }
            return result;
        }
        case DashboardBranchCountry.SK:
            return [
                ['branchCustomerSupport', supportContacts.branchCustomerSupport],
                ['technicalSupport', supportContacts.technicalSupport],
                ['customerSupport', supportContacts.customerSupport],
            ];
        default:
            return [
                ['customerSupport', supportContacts.customerSupport],
                ['technicalSupport', supportContacts.technicalSupport],
                ['paymentTerminalSupport', supportContacts.paymentTerminalSupport],
            ];
    }
}
