import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {BranchCareWorkerType} from "@app/AppContext/model/BranchCareWorker";
import {NewMessageButton} from "@app/Messaging/components/NewMessageButton/NewMessageButton";
import {contactsByCountry} from "@common/layouts/SecuredLayout/Footer/Contacts/contactsByCountry";
import {RemoteHelpLink} from "@common/layouts/SecuredLayout/Footer/Contacts/RemoteHelpLink/RemoteHelpLink";

import './contacts.scss';

export type ContactsProps = {
    full: boolean;
}

export const Contacts: FC<ContactsProps> = observer(({full}) => {
    const {t} = useTranslation();
    const {config, dashboardInfo} = useAppContext();
    const branchCareWorker = dashboardInfo.branchCareWorker;

    const contacts = contactsByCountry(dashboardInfo, full)
        .filter(([_, contact]) => contact !== undefined);

    return <div className="contacts">
        <div className={classNames('contacts__numbers', full && 'contacts__numbers-full')}>
            {full && <Fragment>
                <div>
                    <div className="contacts__title">{t('global:footer.contact.title')}</div>
                    {contacts.map(([contactType]) => (<div
                        key={contactType}
                        className="contacts__number"
                    >
                            {t(`global:footer.contact.${contactType}Full`)}
                    </div>))}
                </div>
                <div>
                    <NewMessageButton
                        variant="link"
                        className="footer__support-link"
                        title={t('global:footer.contact.support')}
                    >
                        {t('global:footer.contact.support')}
                    </NewMessageButton>
                    {contacts.map(([contactType, contact]) => (<div
                        key={contactType}
                        className="contacts__number"
                    >
                        {contact.phone}
                    </div>))}
                </div>
            </Fragment>}
            {!full && contacts.map(([contactType, contact]) => {
                return <div key={contactType} className="contacts__fixed-number">
                    <div>{t(`global:footer.contact.${contactType}${full ? 'Full' : ''}`)}</div>
                    <div>{contact.phone}</div>
                </div>
            })}
        </div>
        {full && <div className="contacts__help-info">
            {branchCareWorker && <div className="contacts__branch-care-worker">
                <div>
                    {t(`global:footer.contact.${branchCareWorker.type === BranchCareWorkerType.COORDINATOR ? 'businessSpecialist' : 'representative'}`)}
                </div>
                <div>{branchCareWorker.name}</div>
                {branchCareWorker.type !== BranchCareWorkerType.COORDINATOR && <div>
                    <a href={`mailto:${branchCareWorker.email}`}>{branchCareWorker.email}</a>
                </div>}
            </div>}
            <div className="text-left">
                <RemoteHelpLink />
            </div>
            {config.release && <div className="contacts__version">
                {t('global:version')}: {config.release.substring(0, 16)}
            </div>}
        </div>}
    </div>
});
