import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {DashboardGridManager} from "@app/AppContext/classes/DashboardGridManager";
import {PacketSearchForm} from "@app/Packet/components/PacketSearchForm/PacketSearchForm";
import {PacketListFilter} from "@app/Packet/model/PacketListFilter";
import {
    MultiplePacketSearchFilter
} from "@app/Packet/pages/DashboardPage/DashboardFilter/MultiplePacketSearchFilter/MultiplePacketSearchFilter";
import {PacketDateFilter} from "@app/Packet/pages/DashboardPage/DashboardFilter/PacketDateFilter/PacketDateFilter";
import {
    PacketLocationFilter
} from "@app/Packet/pages/DashboardPage/DashboardFilter/PacketLocationFilter/PacketLocationFilter";
import {
    PacketStatusFilter
} from "@app/Packet/pages/DashboardPage/DashboardFilter/PacketStatusFilter/PacketStatusFilter";
import {FormResetLink} from "@common/components/forms/FormResetLink/FormResetLink";
import {SearchInputData} from "@common/components/forms/SearchInput/SearchInput";
import {Img} from "@common/components/Img/Img";
import {Loading} from "@common/components/Loading/Loading";
import {useLocalStorageState} from "@common/hooks/useLocalStorageState";

import './dashboardFilter.scss';

export type DashboardFilterProps = {
    manager: DashboardGridManager;
}

export const DASHBOARD_FILTER_STORAGE_KEY = 'ui-dashboardGrid-filter';

export const DashboardFilter: FC<DashboardFilterProps> = observer(({manager}) => {
    const {t} = useTranslation();

    const [storedFilter, setStoredFilter] = useLocalStorageState<PacketListFilter>(
        DASHBOARD_FILTER_STORAGE_KEY,
        {}
    );
    const [showAdvancedFilters, setShowAdvancedFilters] = useState<boolean>(
        storedFilter.dateRange !== undefined
        || (storedFilter.multiStatusId !== undefined && storedFilter.multiStatusId.length > 0)
        || storedFilter.destinationHere === true
    )

    const packetSearchForm = useForm<SearchInputData>({
        defaultValues: {query: storedFilter.fulltext || ''},
        mode: 'onSubmit'
    });

    const updateFilter = useCallback((newFilter: PacketListFilter) => {
        const query = packetSearchForm.getValues('query');
        newFilter.fulltext = query;
        setStoredFilter(newFilter);
        manager.setFilter(newFilter);
    }, [manager, packetSearchForm, setStoredFilter]);

    const toggleExtendedFilter = useCallback(() => {
        if (!showAdvancedFilters) {
            setShowAdvancedFilters(true);
        } else {
            setShowAdvancedFilters(false);
            const {dateRange: _dateRange, dateType: _dateType, destinationHere: _destinationHere, multiStatusId: _multiStatusId, ...filter} = storedFilter;
            updateFilter(filter);
        }
    }, [showAdvancedFilters, storedFilter, updateFilter]);

    const toggleDestinationHere = useCallback(() => {
        if (storedFilter.destinationHere) {
            const {destinationHere: _, ...filter} = storedFilter;
            updateFilter(filter);
        } else {
            updateFilter({
                ...storedFilter,
                destinationHere: true,
            });
        }
    }, [storedFilter, updateFilter]);

    const onReset = useCallback(() => {
        updateFilter({});
        packetSearchForm.reset({query: ''});
        if (showAdvancedFilters) {
            setShowAdvancedFilters(false);
        }
    }, [packetSearchForm, showAdvancedFilters, updateFilter]);

    const onSearch = useCallback((query: string) => {
        packetSearchForm.setValue('query', query);
        updateFilter({...storedFilter});
    }, [packetSearchForm, storedFilter, updateFilter]);

    useEffect(() => {
        if (!manager.hasFilter) {
            manager.setFilter(storedFilter);
        }
    }, [manager, storedFilter]);

    useEffect(() => {
        if (storedFilter.type && showAdvancedFilters) {
            setShowAdvancedFilters(false);
        }
    }, [showAdvancedFilters, storedFilter.type]);

    return <div className="dashboard-filter">
        <h4>{t('viewPacketSearch:searchInput.label')}</h4>
        <Loading active={manager.isLoading}>
            <div className="dashboard-filter__row">
                <PacketSearchForm filter={storedFilter} form={packetSearchForm} updateFilter={updateFilter} />
                <PacketLocationFilter filter={storedFilter} updateFilter={updateFilter} />
                <FormResetLink onReset={onReset} />
                {!storedFilter.type && <Button
                    onClick={toggleExtendedFilter}
                    variant="link"
                    className={classNames('dashboard-filter__advanced', {'dashboard-filter__advanced_on': showAdvancedFilters})}
                >
                    <Img src={`/images/icons/ico/ico-filtrace${showAdvancedFilters ? '-red' : ''}.svg`} alt={t(`viewDashboard:action.advancedFilters.${showAdvancedFilters ? 'off' : 'on'}`)} />{' '}
                    <span>{t(`viewDashboard:action.advancedFilters.${showAdvancedFilters ? 'off' : 'on'}`)}</span>
                </Button>}
            </div>
            {showAdvancedFilters && <div className="dashboard-filter__row">
                <PacketDateFilter
                    filter={storedFilter}
                    updateFilter={updateFilter}
                />
                <Button onClick={toggleDestinationHere} variant={storedFilter.destinationHere ? 'danger' : 'dark'}>
                    {t('viewDashboard:filter.destinationHere')}
                </Button>
                <PacketStatusFilter filter={storedFilter} updateFilter={updateFilter} />
                <MultiplePacketSearchFilter filter={storedFilter} onSearch={onSearch} />
            </div>}
        </Loading>
    </div>
})
