import {useAppContext} from "@app/AppContext/hooks/useAppContext";

export const useSingleBranchId = (): string|null => {
    const appContext = useAppContext();

    if (!appContext.dashboardInfo.loaded || appContext.dashboardInfo.hasManagedBranches) {
        return null;
    }

    return appContext.user.branchId;
}
