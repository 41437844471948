import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {TopDayStatsDay} from "@app/Branch/model/Stats/TopDayStatsDay";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {NumberFormatter} from "@common/components/NumberFormatter/NumberFormatter";

export type TopDaysDayProps = {
    day: TopDayStatsDay;
    name: 'pickupReady'|'delivered';
}

export const TopDaysDay: FC<TopDaysDayProps> = ({day, name}) => {
    const {t} = useTranslation();

    return <Fragment>
        <h5>{t(`viewBranchStats:topDays.${name}.label`)}</h5>
        <GridTable>
            <thead>
                <tr>
                    <th>{t('viewBranchStats:topDays.date')}</th>
                    <th>{t('viewBranchStats:topDays.packetCount')}</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td><DateFormatter date={day.date} /></td>
                    <td><NumberFormatter numeric={day.packetCount} /></td>
                </tr>
            </tbody>
        </GridTable>
    </Fragment>

}
