import {observer} from "mobx-react-lite";
import {FC, Fragment, useState} from "react";
import {useTranslation} from "react-i18next";

import {branchToGridOpeningHours} from "@app/OpeningHours/components/EditableOpeningHoursGrid/branchToGridOpeningHours";
import {
    OpeningHoursGrid
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGrid";
import {
    OpeningHoursGridRow
} from "@app/OpeningHours/components/EditableOpeningHoursGrid/OpeningHoursGrid/OpeningHoursGridRow/OpeningHoursGridRow";
import {useOpeningHours} from "@app/OpeningHours/context/useOpeningHours";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {ucFirst} from "@common/utils/ucFirst";

import './publicHolidaysOverview.scss';

export const PublicHolidaysOverview: FC = observer(() => {
    const {t} = useTranslation();
    const [opened, setOpened] = useState<boolean>(false);
    const {branchHours} = useOpeningHours();
    const dateFormatter = useDateFormatter();

    const gridHours = branchHours.publicHolidaysOnly ? branchToGridOpeningHours(branchHours.publicHolidaysOnly) : null;

    return <div className="public-holidays-overview">
        <a className="public-holidays-overview-link" onClick={() => setOpened(!opened)} data-xid="public-holidays-overview-link">
            {t('openingHours:page.publicHolidaysOverview.link')}
        </a>
        {opened && gridHours && <Fragment>
            <h4>{t('openingHours:page.publicHolidaysOverview.title')}</h4>
            {gridHours.length !== 0 && <OpeningHoursGrid xid="public-holidays-overview-grid">
                {gridHours.map((processedHour) => {
                    return <OpeningHoursGridRow
                        key={processedHour.date}
                        blocks={processedHour.hours}
                        header={<DateFormatter date={processedHour.date} />}
                        subHeader={ucFirst(dateFormatter(processedHour.date, DateFormatType.DAY_OF_WEEK))}
                    />
                })}
            </OpeningHoursGrid>}
            {gridHours.length === 0 && <div>
                {t('openingHours:page.publicHolidaysOverview.empty')}
            </div>}
        </Fragment>}
    </div>;
});
