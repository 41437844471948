import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {
    DeliveryAlert, DeliveryAlertType
} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/DeliveryAlert/DeliveryAlert";
import {PacketIdVerificationForm} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PacketIdVerificationForm/PacketIdVerificationForm";
import {
    usePacketIdVerificationForm
} from "@app/Packet/components/PacketDelivery/components/PacketVerificationForms/PacketIdVerificationForm/usePacketIdVerificationForm";
import {Deliver} from "@app/Packet/components/PacketDelivery/hooks/useDeliver";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";
import {PacketIdVerification} from "@app/Packet/components/PacketDelivery/model/PacketIdVerification";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

export type PacketIdVerificationModalProps = {
    deliver: Deliver;
    onDeliveryCancelled: (packet: DeliveryPacket) => void;
    packetIdVerification: PacketIdVerification|null;
    packets: DeliveryPacketsCollection;
    setPacketIdVerification: (idVerification: PacketIdVerification|null) => void;
    setShowToastMessage: (newShowToastMessage: string|null) => void;
}

export const PacketIdVerificationModal: FC<PacketIdVerificationModalProps> = observer(({
    deliver,
    onDeliveryCancelled: onDeliveryCancelled,
    packetIdVerification,
    packets,
    setPacketIdVerification,
    setShowToastMessage,
}) => {
    const {t} = useTranslation();

    const packet = packets.packetPendingIdVerification;

    const onSuccess = useCallback((newPacketIdVerification: PacketIdVerification) => {
        setPacketIdVerification(newPacketIdVerification);
        setShowToastMessage(t(`viewPacketDelivery:packetIdVerification.message.success`));
    }, [setPacketIdVerification, setShowToastMessage, t]);

    const {form, onSubmit} = usePacketIdVerificationForm(deliver, packet, onSuccess);

    const cancelDelivery = useCallback(() => {
        if (!packet) {
            return;
        }

        packet.cancelIdVerificationRequest();
        onDeliveryCancelled(packet);
    }, [onDeliveryCancelled, packet]);

    if (packetIdVerification || !packet) {
        return null;
    }

    return <BaseModal
        show={true}
        onHide={() => false}
        header={t('viewPacketDelivery:packetIdVerification.header', {barcode: formatPacketBarcode(packet.info.barcode)})}
        footer={<Fragment>
            <Button
                onClick={cancelDelivery}
                variant="warning"
                data-xid="cancel-delivery"
            >
                {t('packet:action.cancel')}
            </Button>
            <Button variant="success" onClick={form.handleSubmit(onSubmit)} data-xid="packetIdVerificationForm-submit">
                {t('viewPacketDelivery:packetIdVerification.form.submit')}
            </Button>
        </Fragment>}
        height="auto"
        disableOutsideClose={true}
        closeButton={false}
        xid="packet-id-verification-modal"
    >
        <DeliveryAlert type={DeliveryAlertType.ADULT_CONTENT} />
        <PacketIdVerificationForm form={form} onSubmit={onSubmit} packet={packet} focus={true} />
    </BaseModal>
})
