import {formatRFC3339} from "date-fns";
import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect, useState} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    DeprecatedVersionAzApiRequestWatcher
} from "@common/layouts/common/ReloadNotice/DeprecatedVersionAzApiRequestWatcher";

import './reloadNotice.scss';

export const ReloadNotice: FC = observer(() => {
    const {t} = useTranslation();
    const [reloadNeeded, setReloadNeeded] = useState<boolean>(false);

    const appContext = useAppContext();

    useEffect(() => {
        const requestWatcher = new DeprecatedVersionAzApiRequestWatcher(
            setReloadNeeded,
            appContext.config.release,
        );
        appContext.api.registerWatcher(requestWatcher);

        return () => {
            appContext.api.unregisterWatcher(requestWatcher);
        }
    }, [appContext.api, appContext.config.release]);

    const forceReload = useCallback(() => {
        const currentUrl = new URL(window.location.href);
        currentUrl.searchParams.set('reload', formatRFC3339(new Date()));
        window.location.href = currentUrl.toString();
    }, []);

    if (!reloadNeeded) {
        return null;
    }

    return <div className="reload-notice" data-xid="reload-notice">
        {t('global:reloadNotice')}
        <Button onClick={forceReload} variant="light">{t('global:reload')}</Button>
    </div>
})
