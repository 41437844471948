import {TFunction} from "i18next";

import {Api} from "@app/AppContext/classes/Api/Api";
import {
    categoriesEndpoint,
    pageDetailEndpoint,
    postDetailEndpoint,
    postsEndpoint
} from "@app/Manuals/api/manualsEndpoints";
import {ManualCategory} from "@app/Manuals/model/ManualCategory";
import {ManualCategoryTreeNode} from "@app/Manuals/model/ManualCategoryTreeNode";
import {ManualPage} from "@app/Manuals/model/ManualPage";
import {ManualPost} from "@app/Manuals/model/ManualPost";
import {QueryParam} from "@common/model/requests/QueryParam";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

const MANUALS_PAGE_SIZE = 100;

export const postDetail = async (postId: number, api: Api): Promise<ManualPost> => {
    return api.call({
        endpoint: endpointWithRouteParams(postDetailEndpoint, {postId: postId.toString(10)}),
        queryParams: [{name: '_fields', value: 'id,title,content,categories'}],
    });
}

export const postByContextAction = async (contextAction: string, api: Api, t: TFunction): Promise<ManualPost|null> => {
    const postId = parseInt(t(`support:supportContextArticle.${contextAction}`), 10);
    if (isNaN(postId)) {
        return null;
    }

    return postDetail(postId, api);
}

export const searchPosts = async (query: string, api: Api, t: TFunction): Promise<ManualPost[]> => {
    const queryParams: QueryParam[] = [
        {name: 'search', value: query},
        {name: '_fields', value: 'id,title,content,categories'},
    ];

    const langTagId = parseInt(t('support:langTagId'), 10);
    if (!isNaN(langTagId)) {
        queryParams.push({name: 'tags', value: langTagId.toString()});
    }

    return api.call({
        endpoint: postsEndpoint,
        queryParams,
    });
}

export const postsByCategoryIds = async (categoryIds: number[], api: Api): Promise<ManualPost[]> => {
    const queryParams: QueryParam[] = [
        {name: 'categories', value: categoryIds.join(',')},
        {name: '_fields', value: 'id,title,categories'},
        {name: 'per_page', value: MANUALS_PAGE_SIZE.toString(10)},
    ];

    const loadedPosts: ManualPost[] = [];
    let posts: ManualPost[] = [];
    let page = 0;
    do {
        posts = await api.call({
            endpoint: postsEndpoint,
            queryParams: [...queryParams, {name: 'page', value: (++page).toString(10)}],
        });
        loadedPosts.push(...posts);
    } while (posts.length === MANUALS_PAGE_SIZE);

    return loadedPosts;
}

export const categories = async (rootCategoryId: number, api: Api): Promise<ManualCategory[]> => {
    const queryParams: QueryParam[] = [
        {name: '_fields', value: 'id,name,parent'},
        {name: 'per_page', value: MANUALS_PAGE_SIZE.toString(10)},
    ];

    const loadedCategories: ManualCategory[] = [];
    let manualCategories: ManualCategory[] = [];
    let page = 0;
    do {
        manualCategories = await api.call({
            endpoint: categoriesEndpoint,
            queryParams: [...queryParams, {name: 'page', value: (++page).toString(10)}],
        });
        loadedCategories.push(...manualCategories);
    } while (manualCategories.length === MANUALS_PAGE_SIZE);

    return loadedCategories.filter((category) => {
        return rootIdForCategory(category, loadedCategories) === rootCategoryId;
    })
}

export const categoriesTreeNodes = async (api: Api, t: TFunction): Promise<ManualCategoryTreeNode[]> => {
    const rootCategoryId = parseInt(t('support:manualRootCategoryId'), 10);

    const allCategories = await categories(rootCategoryId, api);
    if (!allCategories.length) {
        return [];
    }
    const allPosts = await postsByCategoryIds(allCategories.map((category) => category.id), api);

    return createCategoryTreeNodes(
        rootCategoryId,
        allCategories,
        allPosts
    );
}

export const pageDetail = async (pageId: string, api: Api): Promise<ManualPage> => {
    return api.call({
        endpoint: endpointWithRouteParams(pageDetailEndpoint, {pageId}),
        queryParams: [{name: '_fields', value: 'title,content'}],
    });
}

const createCategoryTreeNodes = (parentCategoryId: number, allCategories: ManualCategory[], allPosts: ManualPost[]): ManualCategoryTreeNode[] => {
    return allCategories
        .filter((allCategory) => allCategory.parent === parentCategoryId)
        .map((category): ManualCategoryTreeNode|null => {
            const subcategories = createCategoryTreeNodes(category.id, allCategories, allPosts);
            const posts = allPosts
                .filter((post) => post.categories.includes(category.id))
                .sort((postA, postB) => postA.title.rendered.localeCompare(postB.title.rendered))

            if (!subcategories.length && !posts.length) {
                return null;
            }

            return {
                category,
                subcategories,
                posts,
            };
        })
        .filter((categoryTreeNode): categoryTreeNode is ManualCategoryTreeNode => categoryTreeNode !== null);
}

const rootIdForCategory = (category: ManualCategory, allCategories: ManualCategory[]): number => {
    if (category.parent === 0) {
        return category.id;
    }

    const parentCategory = allCategories.find((allCategory) => allCategory.id === category.parent);
    if (parentCategory) {
        return rootIdForCategory(parentCategory, allCategories);
    }

    return category.id;
}
