import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {
    TransactionGridExport
} from "@app/Transaction/components/TransactionGrid/TransactionGridExport/TransactionGridExport";
import {
    TransactionGridFilter
} from "@app/Transaction/components/TransactionGrid/TransactionGridFilter/TransactionGridFilter";
import {
    TransactionGridHeader
} from "@app/Transaction/components/TransactionGrid/TransactionGridHeader/TransactionGridHeader";
import {
    TransactionGridStateCell
} from "@app/Transaction/components/TransactionGrid/TransactionGridStateCell/TransactionGridStateCell";
import {
    TransactionListManager,
    TransactionListType
} from "@app/Transaction/components/TransactionGrid/TransactionListManager";
import {TransactionAuthState} from "@app/Transaction/model/TransactionAuthState";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './transactionGrid.scss';

export type TransactionGridProps = {
    branchId: string;
    listType: TransactionListType;
}

export const TransactionGrid: FC<TransactionGridProps> = observer(({branchId, listType}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const transactionListManager = useMemo(
        () => new TransactionListManager(branchId, listType, appContext.api),
        [appContext.api, branchId, listType]
    );

    const showCreatedBy = listType === TransactionListType.CASH_REGISTER;

    return <div className="transaction-grid">
        <TransactionGridFilter listType={listType} manager={transactionListManager}/>
        <PaginatedGrid
            manager={transactionListManager}
            topCountContent={transactionListManager.hasItems
                ? <TransactionGridExport listType={listType} manager={transactionListManager} />
                : undefined
            }
        >
            <TransactionGridHeader listType={listType} manager={transactionListManager} showCreatedBy={showCreatedBy} />
            <tbody>
                {transactionListManager.items.map((transaction) => {
                    const createdBy = transactionListManager.users.get(transaction.createdByUserId);

                    return <tr
                        key={`${transaction.createdAt}-${transaction.packetBarcode || ''}`}
                        className={classNames('transaction-grid__row', {
                            'transaction-grid__row-rejected': transaction.authState === TransactionAuthState.REJECTED,
                            'transaction-grid__row-waiting': transaction.authState === TransactionAuthState.WAITING,
                        })}
                    >
                        <td>
                            <DateFormatter date={transaction.createdAt} showTime={true} />
                            <div className="transaction-grid__created-at-barcode">
                                {dashWhenEmpty(transaction.packetBarcode && <FormattedPacketBarcode
                                    barcode={transaction.packetBarcode}
                                    withHighlight={true}
                                />)}
                            </div>
                        </td>
                        <td className="transaction-grid__barcode">
                            {dashWhenEmpty(transaction.packetBarcode && <FormattedPacketBarcode
                                barcode={transaction.packetBarcode}
                                withHighlight={true}
                            />)}
                        </td>
                        <td className="transaction-grid__amount">
                            <MoneyFormatter amount={transaction.amount} currencyCode={transaction.currency}/>
                            <div className="transaction-grid__amount-type">{transaction.type.name}</div>
                        </td>
                        <td className="transaction-grid__type">
                            {transaction.type.name}
                            {showCreatedBy && createdBy && <div className="transaction-grid__type-created-by">
                                {createdBy.name}
                            </div>}
                        </td>
                        {showCreatedBy && <td className="transaction-grid__created-by">
                            {dashWhenEmpty(createdBy && createdBy.name)}
                        </td>}
                        <td className="transaction-grid__note">{dashWhenEmpty(transaction.note)}</td>
                        <TransactionGridStateCell transaction={transaction} />
                    </tr>
                })}
                {transactionListManager.totalSum !== undefined && <tr className="transaction-grid__sum-row">
                    <td colSpan={2}>{t('viewTransaction:transactionGrid.totalSum')}</td>
                    <td className="transaction-grid__row-amount">
                        <MoneyFormatter
                            amount={transactionListManager.totalSum.amount}
                            currencyCode={transactionListManager.totalSum.currency}
                        />
                    </td>
                </tr>}
            </tbody>
        </PaginatedGrid>
    </div>
})
