import {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {Parcel} from "@app/Parcel/model/Parcel";
import {printParcelDeliveryNote} from "@app/Print/api/printApi";
import {LoadingButton, LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {usePrint} from "@common/hooks/usePrint";

export type OutgoingParcelGridPrintButtonProps = {
    parcel: Parcel;
}

export const OutgoingParcelGridPrintButton: FC<OutgoingParcelGridPrintButtonProps> = ({parcel}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const handlePrint = usePrint();

    const onPrint = useCallback<LoadingButtonOnClick>((stopLoading) => {
        void handlePrint(printParcelDeliveryNote({parcelId: parcel.id}, appContext.api))
            .finally(stopLoading);
    }, [handlePrint, parcel.id, appContext.api]);

    return <LoadingButton variant="success" title={t('parcel:action.print')} onLoadingClick={onPrint}>
        {t('parcel:action.printShort')}
    </LoadingButton>

}
