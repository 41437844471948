import {BranchQualityDateRange} from "@app/Branch/model/BranchQuality/BranchQualityDateRange";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";

export type GeneralBranchQualityDetailItem = {
    date: string;
    penaltyPoints: number;
}

export type GeneralBranchQualityDetail = {
    dateRange: BranchQualityDateRange;
    type: BranchEventCode.BRANCH_CLOSED_UNANNOUNCED | BranchEventCode.INVENTORY_MISSING;
    items: GeneralBranchQualityDetailItem[];
}

export const generalBranchQualityDetailTypes = [
    BranchEventCode.BRANCH_CLOSED_UNANNOUNCED,
    BranchEventCode.INVENTORY_MISSING,
];
