import {add} from "date-fns";
import {FC, Fragment, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {printParcelLabel} from "@app/Print/api/printApi";
import {Img} from "@common/components/Img/Img";
import {LoadingButton, LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {ReprintButton} from "@common/components/ReprintButton/ReprintButton";
import {useLocalStorageRef} from "@common/hooks/useLocalStorageRef";
import {usePrint} from "@common/hooks/usePrint";
import {PrintResult} from "@common/model/PrintResult";

import './printParcelLabelButton.scss';

const LAST_PRINT_CACHE_KEY = 'ui-printParcelLabelButton-lastPrint'
const LAST_PRINT_CACHE_EXPIRATION = 10; // 10 minutes

type CachedPrintResult = {
    printResult: PrintResult;
    printedAt: Date;
}

export const PrintParcelLabelButton: FC = () => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [lastPrintResult, persistLastPrintResult]  = useLocalStorageRef<CachedPrintResult|null>(
        LAST_PRINT_CACHE_KEY,
        null
    );
    const [decisionModalOpened, setDecisionModalOpened] = useState<boolean>(false);
    const handlePrint = usePrint();

    const printNewLabel = useCallback<LoadingButtonOnClick>((stopLoading) => {
        void handlePrint(printParcelLabel(appContext.api))
            .then((response) => {
                if (response && response.prints && response.prints.length > 0) {
                    lastPrintResult.current = {
                        printResult: response.prints[0],
                        printedAt: new Date(),
                    };
                    persistLastPrintResult();
                }
            })
            .finally(() => {
                stopLoading();
                if (decisionModalOpened) {
                    setDecisionModalOpened(false);
                }
            });
    }, [handlePrint, appContext.api, lastPrintResult, persistLastPrintResult, decisionModalOpened])

    const doPrint = useCallback<LoadingButtonOnClick>((stopLoading) => {
        if (lastPrintResult.current !== null
            && lastPrintResult.current.printedAt < add(new Date(), {minutes: -1 * LAST_PRINT_CACHE_EXPIRATION})
        ) {
            lastPrintResult.current = null;
            persistLastPrintResult();
        }

        if (lastPrintResult.current !== null) {
            setDecisionModalOpened(true);
            stopLoading();
        } else {
            printNewLabel(stopLoading);
        }
    }, [lastPrintResult, persistLastPrintResult, printNewLabel]);

    const closeModal = useCallback(() => {
        setDecisionModalOpened(false);
    }, []);

    return <Fragment>
       <LoadingButton
           onLoadingClick={doPrint}
           variant="secondary"
           className="print-parcel-label-button"
           title={t('global:printerReader.printer')}
       >
           <span className="print-parcel-label-button__label">{t('global:printerReader.printer')}</span>
           <Img src="/images/icons/ico/ico-print.svg" alt={t('global:printerReader.printer')} />
       </LoadingButton>
        {lastPrintResult.current && <BaseModal
           show={decisionModalOpened}
           onHide={closeModal}
           header={t('viewPrintParcelLabel:reprintDecisionModal.header')}
           footer={<Fragment>
               <LoadingButton variant="secondary" onLoadingClick={printNewLabel}>
                   {t('viewPrintParcelLabel:reprintDecisionModal.action.printNew')}
               </LoadingButton>
               <ReprintButton printResult={lastPrintResult.current.printResult} onReprint={closeModal}>
                   {t('viewPrintParcelLabel:reprintDecisionModal.action.reprint')}
               </ReprintButton>
           </Fragment>}
           size="lg"
       >
           {t('viewPrintParcelLabel:reprintDecisionModal.message')}
       </BaseModal>}
   </Fragment>
}
