import {FC, useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {commissionTypeList} from "@app/Commission/api/commissionApi";
import {Select, SelectOption} from "@common/components/forms/Select/Select";

export type CommissionTypeSelectProps = {
    onSelect: (type: string|null) => void;
    value?: string;
}

type CommissionTypeFormData = {
    type: string;
}

const EMPTY_TYPE_ID = '';

export const CommissionTypeSelect: FC<CommissionTypeSelectProps> = ({onSelect, value}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const form = useForm<CommissionTypeFormData>({
        defaultValues: {type: value || EMPTY_TYPE_ID},
        mode: 'onChange',
    });

    const [commissionTypes, setCommissionTypes] = useState<SelectOption[]|null>(null);

    const onChange = useCallback(() => {
        const {type} = form.getValues();
        onSelect(type === EMPTY_TYPE_ID ? null : type);
    }, [form, onSelect]);

    useEffect(() => {
        if (commissionTypes === null) {
            commissionTypeList(appContext.api)
                .then((response) => {
                    setCommissionTypes(response.items.map((type) => ({
                        value: type.id.toString(10),
                        label: type.name,
                    })));
                })
                .catch(() => {
                    setCommissionTypes([]);
                });
        }
    }, [appContext.api, commissionTypes]);

    useEffect(() => {
        const selectedType = form.getValues('type');
        if (!value && selectedType !== EMPTY_TYPE_ID) {
            form.setValue('type', EMPTY_TYPE_ID);
        }
        if (value && selectedType !== value) {
            form.setValue('type', value);
        }
    }, [form, value]);

    if (!commissionTypes || commissionTypes.length === 0) {
        return null;
    }

    return <Select
        form={form}
        name="type"
        label={t('commission:property.type')}
        options={commissionTypes}
        placeholder={{value: EMPTY_TYPE_ID, label: t('global:ui.selectPrompt')}}
        placeholderDisabled={false}
        onChange={onChange}
    />
}
