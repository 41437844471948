export enum PacketDeliveryMetaReturnAcceptance {
    STANDARD = 'standard',
    UNPACKED = 'unpacked',
}

export type PacketDeliveryMeta = {
    codCashAmount?: string;
    returnAcceptance: PacketDeliveryMetaReturnAcceptance;
    isPaidByCard: boolean;
}
