import {FC, Fragment, useCallback, useEffect} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {CancelPayment} from "@app/CardPayment/hooks/useCancelPayment";
import {CardPaymentState, CardPaymentStatus, getTransaction} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";

import './cardPaymentError.scss';

export type CardPaymentErrorProps = {
    cardPaymentState: CardPaymentState;
    paid?: boolean;
    pay?: () => Promise<void>;
    cancelPayment?: CancelPayment;
    showCancelledPayment?: boolean;
    toastInModal?: boolean;
}

export const CardPaymentError: FC<CardPaymentErrorProps> = ({
    cancelPayment,
    cardPaymentState,
    paid,
    pay,
    showCancelledPayment = true,
    toastInModal = false
}) => {
    const {t} = useTranslation();

    const transaction = getTransaction(cardPaymentState);
    const isCancelledPayment = cardPaymentState.status === CardPaymentStatus.CANCELLING
        && transaction
        && transaction.status === CardPaymentTransactionStatus.SUCCESS;

    const repeat = useCallback(async () => {
        if (pay && (isCancelledPayment || cardPaymentState.status === CardPaymentStatus.PAYING)) {
            await pay();
        } else if (cancelPayment && transaction) {
            await cancelPayment();
        }
    }, [cancelPayment, cardPaymentState.status, isCancelledPayment, pay, transaction]);

    const showError = transaction
        && (transaction.status === CardPaymentTransactionStatus.ERROR || (isCancelledPayment && showCancelledPayment));

    useEffect(() => {
        if (showError) {
            (toastInModal ? modalToast : appToast).error(t(`cardPayment:error.${isCancelledPayment ? 'cancelled' : 'payment'}`));
        }
    }, [isCancelledPayment, showError, t, toastInModal]);

    if (!showError || paid) {
        return null;
    }

    const translationKey = isCancelledPayment
        ? 'cancelled'
        : (cardPaymentState.status === CardPaymentStatus.CANCELLING ? 'cancelling' : 'payment');

    return <div className="card-payment-error" data-xid={isCancelledPayment ? 'card-payment-cancelled' : 'card-payment-error'}>
        <div className="card-payment-error-message">
            {transaction.message && !transaction.errorIdentifier && <Fragment>
                <div>{t(`cardPayment:error.${translationKey}WithMessage`)}:</div>
                <div>{transaction.message}</div>
            </Fragment>}
            {(!transaction.message || transaction.errorIdentifier) && <div>{t(`cardPayment:error.${translationKey}`)}</div>}
            {transaction.errorIdentifier && <div>{t('global:flashMessage.errorIdentifier', {errorIdentifier: transaction.errorIdentifier})}</div>}
        </div>
        {(pay || cancelPayment) && <div className="card-payment-error-repeat">
            <Button
                variant="danger"
                onClick={repeat}
            >{t('cardPayment:action.repeat')}</Button>
        </div>}
    </div>
}
