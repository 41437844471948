import {ReactElement} from "react";
import {UseFormReturn} from "react-hook-form";
import {DebouncedState} from "use-debounce/lib/useDebouncedCallback";

import {SearchInputData} from "@common/components/forms/SearchInput/SearchInput";

export enum SearchFormState {
    DEFAULT = 'default',
    SEARCHING = 'searching',
    HANDLING = 'handling',
    PASSWORD_INVALID = 'password-invalid',
}

export type UseSearchFormType = {
    searchFormState: SearchFormState;
    isCorrectButUsed: boolean;
    handleElement: ReactElement | null;
    doSearch: DebouncedState<(query: string) => void>;
    form: UseFormReturn<SearchInputData>;
}

export enum SearchFormResult {
    PASSWORD_OK = 'Heslo je v pořádku',
    RETURN_SHIPMENT = 'Vrácení zásilky',
    CASH_PICKUP = 'Odvod hotovosti řidiči',
    B2C_CONSIGN_PASSWORD = 'Příjem B2C zásilky',
    C2C_CONSIGN_PASSWORD = 'Příjem C2C zásilky',
    RETURN_PACKET = 'Výdej vratek',
    ROUTE_FINALIZATION = 'Dokončení trasy mikrodepa',
    UNKNOWN = 'Neznámý stav',
}
