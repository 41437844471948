import {useTranslation} from "react-i18next";

import {OpeningHoursBlock} from "@app/OpeningHours/model/OpeningHoursBlock";
import {FCWithChildren} from "@common/model/FCWithChildren";

export type ViewCellProps = {
    blocks: OpeningHoursBlock[];
}

export const ViewCell: FCWithChildren<ViewCellProps> = ({blocks, children}) => {
    const {t} = useTranslation();

    let isClosed = true;

    return <td className="view-cell">
        <div className="view-cell-content">
            <div className="centered-cell-content">
                {blocks.map((block, index) => {
                    if (block.open === '' && block.close === '') {
                        return null;
                    }

                    isClosed = false;

                    return <div key={index} className="view-cell-record">
                        <span className="view-cell-record-item">{block.open}</span>
                        <span className="view-cell-record-separator">-</span>
                        <span className="view-cell-record-item">{block.close}</span>
                    </div>
                })}
                {isClosed && <div className="view-cell-record">{t('openingHours:grid.closed')}</div>}
            </div>
            <div className="centered-cell-content">
                {children}
            </div>
        </div>
    </td>
}
