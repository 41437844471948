export const normalizeDecimalSeparator = (value: string): string => {
    return value.replace(getDecimalSeparator(), '.');
}

export const denormalizeDecimalSeparator = (value: string): string => {
    if (value.endsWith('.00')) {
        return parseInt(value, 10).toString(10);
    } else {
        return value.replace('.', getDecimalSeparator());
    }
}

const getDecimalSeparator = () => {
    const numberWithDecimalSeparator = 1.1;
    const decimal = Intl.NumberFormat()
        .formatToParts(numberWithDecimalSeparator)
        .find(part => part.type === 'decimal');

    return decimal && decimal.value || '.';
}
