import {Button, Col, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {Img} from "@common/components/Img/Img";
import {FCWithChildren} from "@common/model/FCWithChildren";

export type MessageSubmitProps = {
    submit: () => void;
    submitting: boolean;
    reset: () => void;
}

export const MessageSubmit: FCWithChildren<MessageSubmitProps> = ({children, reset, submit, submitting}) => {
    const {t} = useTranslation();

    return <Row className="message-submit">
        <Col>{children}</Col>
        <Col className="message-submit-buttons">
            <Button variant="link" onClick={reset}>
                <Img src="/images/icons/ico/ico-delete.svg" alt={t('message:tabs.content.new.action.clear')} />{' '}
                {t('message:tabs.content.new.action.clear')}
            </Button>
            <Button variant="dark" onClick={submit} disabled={submitting}>
                {t('message:tabs.content.new.action.send')}
            </Button>
        </Col>
    </Row>

}
