import {useTranslation} from "react-i18next";
import {ToastContentProps, TypeOptions} from "react-toastify";

import {Alert} from "@common/components/Alert/Alert";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './toast.scss';

export type ToastProps = Partial<ToastContentProps> & {
    errorIdentifier?: string;
}

export const Toast: FCWithChildren<ToastProps> = ({children, closeToast, errorIdentifier, toastProps}) => {
    const {t} = useTranslation();

    const toastType: TypeOptions = toastProps ? (toastProps.type ? toastProps.type : 'info') : 'info';

    return <Alert
        alertId={toastProps ? toastProps.toastId : undefined}
        type={toastType}
        className="flash-message"
        close={closeToast}
    >
        <div>{children}</div>
        {errorIdentifier && <div className="flash-message__error-identifier">
            {t('global:flashMessage.errorIdentifier', {errorIdentifier})}
        </div>}
    </Alert>
}
