import {FC, useCallback, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {editBillingInformation} from "@app/Commission/api/commissionApi";
import {EditCommissionInvoiceBillingInformationRequest} from "@app/Commission/model/CommissionRequestsAndResponses";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export type AccountNumberFormProps = {
    onSuccess: () => void;
}

export const AccountNumberForm: FC<AccountNumberFormProps> = ({onSuccess}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const form = useForm<EditCommissionInvoiceBillingInformationRequest>({
        defaultValues: {accountNumber: ''},
    });

    const showAppErrorToast = useShowErrorToast(
        appToast,
        'viewCommission:accountNumberForm.error',
        'viewCommission:accountNumberForm.error.generic'
    );

    const submit = useCallback((request: EditCommissionInvoiceBillingInformationRequest) => {
        editBillingInformation(request, appContext.api)
            .then(() => {
                appToast.success('viewCommission:accountNumberForm.success');
                onSuccess();
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
            });
    }, [appContext.api, onSuccess, showAppErrorToast]);

    useEffect(() => {
        form.setFocus('accountNumber');
    }, [form]);

    const formId = 'accountNumberForm';

    return <form onSubmit={form.handleSubmit(submit)} className="account-number-form">
        <FormGroup
            form={form}
            name="accountNumber"
            label={t('viewCommission:accountNumberForm.accountNumber.label')}
            formId={formId}
            registerOptions={{
                required: t<string>('viewCommission:accountNumberForm.accountNumber.validation.required'),
            }}
            inputOptions={{
                placeholder: t('viewCommission:accountNumberForm.accountNumber.placeholder'),
            }}
        />
        <SubmitFormGroup name="submit" value={t('viewCommission:accountNumberForm.send')} formId={formId} />
    </form>
}
