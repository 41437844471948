import {FC} from "react";
import {useTranslation} from "react-i18next";

import {jobTitle} from "@app/Devices/components/ReaderModal/utils/jobTitle";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";
import {Button} from "@common/components/Button/Button";
import {GridTable} from "@common/components/grids/GridTable/GridTable";

export type ReaderJobListProps = {
    jobs: ReaderJob[];
    selectJob: (job: ReaderJob) => void;
    setJobForCancelling: (job: ReaderJob) => void;
}

export const ReaderJobList: FC<ReaderJobListProps> = ({jobs, selectJob, setJobForCancelling}) => {
    const {t} = useTranslation();

    return <GridTable>
        <thead>
            <tr>
                <th>{t('reader:jobsList.job')}</th>
                <th>{t('reader:jobsList.actions')}</th>
            </tr>
        </thead>
        <tbody>
            {jobs.map((job, index) => <tr key={job.hash}>
                <td>{jobTitle(job, t)}</td>
                <td>
                    <Button
                        onClick={() => selectJob(job)} variant="success"
                        data-xid={`job-action-${index}`}
                    >
                        {t(`reader:jobsList.${job.type === ReaderJobType.UNKNOWN ? 'enterForgottenBarcode' : 'process'}`)}
                    </Button>
                    <Button onClick={() => setJobForCancelling(job)} variant="secondary">
                        {t('reader:jobsList.cancel')}
                    </Button>
                </td>
            </tr>)}
        </tbody>
    </GridTable>

}
