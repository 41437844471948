import {startOfMonth, startOfQuarter, startOfYear, sub} from "date-fns";
import {useMemo} from "react";

import {NamedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";

export const useBranchStatsPageDateRangePickerRanges = () => {
    return useMemo<NamedDateRange[]>(() => [
        {
            name: 'viewBranchStats:dateRange.namedRanges.currentMonth',
            duration: {months: 1},
            startDate: startOfMonth,
        },
        {
            name: 'viewBranchStats:dateRange.namedRanges.previousMonth',
            duration: {months: 1},
            startDate: (date) => startOfMonth(sub(date, {months: 1})),
            endDateExcluded: true,
        },
        {
            name: 'viewBranchStats:dateRange.namedRanges.currentQuarter',
            duration: {months: 3},
            startDate: startOfQuarter,
        },
        {
            name: 'viewBranchStats:dateRange.namedRanges.previousQuarter',
            duration: {months: 3},
            startDate: (date) => startOfQuarter(sub(date, {months: 3})),
            endDateExcluded: true,
        },
        {
            name: 'viewBranchStats:dateRange.namedRanges.currentYear',
            duration: {years: 1},
            startDate: startOfYear,
        },
        {
            name: 'viewBranchStats:dateRange.namedRanges.previousYear',
            duration: {years: 1},
            startDate: (date) => startOfYear(sub(date, {years: 1})),
            endDateExcluded: true,
        },
    ], []);
}
