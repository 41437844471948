export enum SearchTypeType {
    BARCODE = 'barcode',
    EMAIL = 'email',
    C2C_CONSIGN_PASSWORD = 'c2c-consign-password',
    C2C_RETURN_PASSWORD = 'c2c-return-password',
    DELIVERY_PASSWORD = 'delivery-password',
    RETURN_PASSWORD = 'return-password',
    SURPRISE_CODE = 'surprise-code',
    SURPRISE_CONSIGN_PASSWORD = 'surprise-consign-password',
    CLAIM_FROM_CUSTOMER_PASSWORD = 'claim-from-customer-password',
    TRANSACTION = 'transaction',
    FINALIZATION_ROUTE_PASSWORD = 'finalization-route-password',
    B2C_CONSIGN_PASSWORD = 'b2c-consign-password',
    B2C_RETURN_PASSWORD = 'b2c-return-password',
    RETURN_DESTINATION_TO_PICKUP_POINT_PASSWORD = 'rd-to-pp',
    UNKNOWN = 'unknown',
}

export type SearchType = {
    type: SearchTypeType;
}
