import classNames from "classnames";
import {FC} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {CashRegister} from "@app/Branch/model/Attribute/CashRegister";
import {NewMessageButton} from "@app/Messaging/components/NewMessageButton/NewMessageButton";
import {MessageTopic} from "@app/Messaging/model/MessageTopic";
import {Alert} from "@common/components/Alert/Alert";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {moneyToString} from "@common/components/MoneyFormatter/moneyToString";

export type CashRegisterSettingsProps = {
    cashRegister: CashRegister;
    updatePending?: true;
}

export const CashRegisterSettings: FC<CashRegisterSettingsProps> = ({cashRegister, updatePending}) => {
    const {t} = useTranslation();

    const form = useForm<CashRegister>({
        defaultValues: {
            ...cashRegister,
            keepCashAmount: moneyToString({amount: cashRegister.keepCashAmount, currency: cashRegister.currency}, true),
            roundCashBy: moneyToString({amount: cashRegister.roundCashBy, currency: cashRegister.currency}, true),
        },
        mode: 'onSubmit',
    });

    const formId = 'cashRegisterSettingsForm';

    return <div
        className={classNames('branch-settings-page__settings', updatePending && 'branch-settings-page__settings_update-pending')}
        title={updatePending ? t('viewBranchSettings:updatePending') : t('viewBranchSettings:readOnly')}
    >
        <h5>{t('viewBranchSettings:cashRegisterSettings.title')}</h5>
        <p>{t('viewBranchSettings:cashRegisterSettings.description')}</p>
        <Alert type="warning">
            {t('viewBranchSettings:readOnly')}{' '}
            <NewMessageButton topic={MessageTopic.CASH_REGISTER_OR_TRANSFER} variant="primary">
                {t('viewBranchSettings:contactUs')}
            </NewMessageButton>
        </Alert>
        <FormGroup
            form={form}
            name="keepCashAmount"
            label={t('branchSettings:cashRegister.keepCashAmount')}
            formId={formId}
            inputOptions={{
                disabled: true,
                title: t('viewBranchSettings:readOnly'),
            }}
        />
        <FormGroup
            form={form}
            name="roundCashBy"
            label={t('branchSettings:cashRegister.roundCashBy')}
            formId={formId}
            inputOptions={{
                disabled: true,
                title: t('viewBranchSettings:readOnly'),
            }}
        />
        <FormGroup
            type="checkbox"
            form={form}
            name="exactAmountCash"
            label={t('branchSettings:cashRegister.exactAmountCash')}
            formId={formId}
            inputOptions={{
                disabled: true,
                title: t('viewBranchSettings:readOnly'),
            }}
        />
    </div>
}
