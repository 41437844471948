import {FC, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {setPacketShelf} from "@app/Packet/api/packetApi";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {Img} from "@common/components/Img/Img";
import {Loading} from "@common/components/Loading/Loading";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

export type PacketAtDestinationProps = {
    barcode: string;
}

type PacketAtDestinationFormData = {
    shelf: string;
}

export const PacketAtDestination: FC<PacketAtDestinationProps> = ({barcode}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [shelfSubmitted, setShelfSubmitted] = useState<string|null>(null);
    const form = useForm<PacketAtDestinationFormData>({
        defaultValues: {shelf: ''}
    });

    const showAppErrorToast = useShowErrorToast(modalToast);

    const onSubmit = useBlockingCallback((unblock, formData: PacketAtDestinationFormData) => {
        setIsSubmitting(true);
        setPacketShelf({barcode, shelf: formData.shelf}, appContext.api)
            .then(() => {
                modalToast.success(t('viewPacketIsAtDestination:message.success'))
                setShelfSubmitted(formData.shelf);
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
            })
            .finally(() => {
                setIsSubmitting(false);
                unblock();
            });
    }, [appContext.api, barcode, showAppErrorToast, t]);

    useEffect(() => {
        form.setFocus('shelf');
    }, [form]);

    const formId = 'packetAtDestinationForm';

    return <div data-xid="packet-at-destination">
        <p className="alert alert-danger">
            <Img src="/images/icons/ico/ico-exclamation-red.svg" alt="error" />{' '}
            {t('viewPacketIsAtDestination:message.isAtDestination')}{' '}
            <strong>{t('viewPacketIsAtDestination:message.doNotDispatch')}</strong>
        </p>
        {shelfSubmitted !== null && <div>
            {t('viewPacketIsAtDestination:message.packetStoredAtShelf', {shelf: shelfSubmitted})}
        </div>}
        {shelfSubmitted === null && <Loading active={isSubmitting}>
            <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
                <FormGroup
                    form={form}
                    name="shelf"
                    formId={formId}
                    label={t('viewPacketIsAtDestination:form.shelf.label')}
                    registerOptions={{
                        required: t<string>('viewPacketIsAtDestination:form.shelf.required')
                    }}
                    inputOptions={{
                        placeholder: t('viewPacketIsAtDestination:form.shelf.placeholder'),
                    }}
                />
                <SubmitFormGroup name="save" value={t('viewPacketIsAtDestination:form.save')} formId={formId} />
            </form>
        </Loading>}
    </div>
}
