import classNames from "classnames";
import {FC} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {Capacity} from "@app/Branch/model/Attribute/Capacity";
import {NewMessageButton} from "@app/Messaging/components/NewMessageButton/NewMessageButton";
import {MessageTopic} from "@app/Messaging/model/MessageTopic";
import {Alert} from "@common/components/Alert/Alert";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";

export type CapacitySettingsProps = {
    capacity: Capacity
    updatePending?: boolean;
}

export const CapacitySettings: FC<CapacitySettingsProps> = ({capacity, updatePending}) => {
    const {t} = useTranslation();

    const form = useForm<Capacity>({
        defaultValues: {
            ...capacity
        },
        mode: 'onSubmit',
    });

    const formId = 'cashRegisterSettingsForm';

    return <div
            className={classNames('branch-settings-page__settings', updatePending && 'branch-settings-page__settings_update-pending')}
            title={updatePending ? t('viewBranchSettings:updatePending') : t('viewBranchSettings:readOnly')}>
        <h5>{t('viewBranchSettings:capacitySettings.title')}</h5>
        <p>{t('viewBranchSettings:capacitySettings.description')}</p>
        <Alert type="warning">
            {t('viewBranchSettings:readOnly')}{' '}
            <NewMessageButton topic={MessageTopic.BRANCH_CAPACITY} variant="primary">
                {t('viewBranchSettings:contactUs')}
            </NewMessageButton>
        </Alert>
        <FormGroup
            form={form}
            name="packetCapacity"
            label={t('branchSettings:capacity.packetCapacity.label')}
            formId={formId}
            inputOptions={{
                disabled: true,
                title: t('viewBranchSettings:readOnly'),
            }}
        />
    </div>
}