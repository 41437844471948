import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const printInventoryBadPacketsEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/inventory/bad-packets',
}

export const printInventoryBadPacketsPDFEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/inventory/bad-packets/pdf',
}

export const printPacketExpeditionsEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/packet/expeditions',
}

const printPacketLabelsKnownErrorCodes = {
    reasonRequired: 'reasonRequired' as const,
    packetInFinalState: 'packetInFinalState' as const,
    notConsignedC2cPacket: 'notConsignedC2cPacket' as const,
    notConsignedMk2cPacket: 'notConsignedMk2cPacket' as const,
}

export const printPacketLabelsEndpoint: Endpoint<string, keyof typeof printPacketLabelsKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/print/packet/labels',
    knownErrorCodes: printPacketLabelsKnownErrorCodes,
}

export const printParcelBadPacketsEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/parcel/bad-packets',
}

export const printParcelBadPacketsPDFEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/parcel/bad-packets/pdf',
}

export const printParcelDeliveryBillEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/parcel/delivery-bill',
}

export const printParcelDeliveryNoteEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/parcel/delivery-note',
}

export const printParcelDeliveryNotePDFEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/parcel/delivery-note/pdf',
}

export const printParcelLabelEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/parcel/label',
}

export const printReturnPacketListEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/return-packet/list',
}

const printShelfBarcodesErrorCodes = {
    shelfBarcodeLengthExceeded: 'shelfBarcodeLengthExceeded' as const,
};

export const printShelfBarcodesEndpoint: Endpoint<string, keyof typeof printShelfBarcodesErrorCodes> = {
    method: HttpMethod.POST,
    url: '/print/branch/shelf-barcodes',
    knownErrorCodes: printShelfBarcodesErrorCodes,
}

export const printShelfBarcodesPDFEndpoint: Endpoint<string, keyof typeof printShelfBarcodesErrorCodes> = {
    method: HttpMethod.POST,
    url: '/print/branch/shelf-barcodes/pdf',
    knownErrorCodes: printShelfBarcodesErrorCodes,
}

export const reprintEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/print/reprint',
}
