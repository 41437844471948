import {ErrorMessage} from "@hookform/error-message";
import {observer} from "mobx-react-lite";
import {FC, Fragment, useCallback, useState} from "react";
import {Col, Row} from "react-bootstrap";
import {UseFormReturn, useWatch} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {PacketSearchModal} from "@app/Messaging/components/NewMessage/common/PacketSearchModal/PacketSearchModal";
import {
    IncomingMessageFormData,
    NewMessageFormFields
} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {MessageTopic as MessageTopicEnum} from "@app/Messaging/model/MessageTopic";
import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {Select, SelectOption} from "@common/components/forms/Select/Select";

export type MessageTopicProps = {
    form: UseFormReturn<IncomingMessageFormData>;
    formId: string;
}

export const MessageTopic: FC<MessageTopicProps> = observer(({form, formId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const {formState, register, setFocus, setValue} = form;
    const [packetSearchModalOpened, setPacketSearchModalOpened] = useState<boolean>(false);

    const onPacketFound = useCallback((barcode: string) => {
        setPacketSearchModalOpened(false);
        setValue(
            NewMessageFormFields.packetBarcode,
            formatPacketBarcode(barcode)
        );
        setFocus(NewMessageFormFields.content);
    }, [setFocus, setValue]);

    const onPacketSearchModalHide = useCallback(() => {
        setPacketSearchModalOpened(false);
    }, []);

    const watchTopic = useWatch({control: form.control, name: NewMessageFormFields.topic});
    const {dashboardInfo} = appContext;

    const options: Array<SelectOption> = [];
    for (const topic in MessageTopicEnum) {
        if (Object.prototype.hasOwnProperty.call(MessageTopicEnum, topic)) {
            const topicNumber = Number(topic) as MessageTopicEnum;
            if (
                isNaN(topicNumber)
                || (topicNumber === MessageTopicEnum.Z_POINT_MOBILE_APP && !dashboardInfo.isCzBranch && !dashboardInfo.isSkBranch && !dashboardInfo.isHuBranch)
            ) {
                continue;
            }
            options.push({value: topic, label: t(`message:tabs.content.new.topic.items.${topic}`)});
        }
    }

    const placeholder: SelectOption = {
        value: '-1',
        label: t('message:tabs.content.new.topic.prompt'),
    };

    return <div className="form-group message-topic">
        <Row>
            <Col xs={6}>
                <Select
                    name={NewMessageFormFields.topic}
                    label={t('message:tabs.content.new.topic.label')}
                    formId={formId}
                    options={options}
                    placeholder={placeholder}
                    registerOptions={{
                        min: {
                            value: 0,
                            message: t<string>('message:tabs.content.new.topic.requiredTopicMessage'),
                        },
                        valueAsNumber: true,
                    }}
                    form={form}
                />
            </Col>
            <Col xs={6}>
                {appContext.user.branchId !== null && watchTopic === MessageTopicEnum.PACKET_PROBLEM && <Fragment>
                    <input
                        {...register(NewMessageFormFields.packetBarcode, {
                            required: t<string>('message:tabs.content.new.topic.requiredPacketBarcodeMessage'),
                        })}
                        className="form-control"
                        placeholder={t('message:tabs.content.new.topic.packetLabel')}
                        readOnly
                        onClick={() => setPacketSearchModalOpened(true)}
                    />
                    <ErrorMessage name={NewMessageFormFields.packetBarcode} errors={formState.errors} as={<p className="alert alert-danger" />} />
                </Fragment>}
                {watchTopic !== -1 && watchTopic === MessageTopicEnum.OTHER && <input
                    {...register(NewMessageFormFields.otherTopic)}
                    className="form-control"
                    placeholder={t('message:tabs.content.new.topic.otherLabel')}
                />}
            </Col>
        </Row>
        {appContext.user.branchId !== null && <PacketSearchModal
            branchId={appContext.user.branchId}
            show={packetSearchModalOpened}
            onHide={onPacketSearchModalHide}
            onPacketFound={onPacketFound}
        />}
    </div>
})
