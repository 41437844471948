import {observer} from "mobx-react-lite";
import {PropsWithChildren, ReactElement, useCallback, useRef, useState} from "react";
import {useTranslation} from "react-i18next";

import {Button} from "@common/components/Button/Button";
import {PaginatedDataManager} from "@common/components/grids/PaginatedDataManager";

export type PaginatedGridReloadButtonProps<ItemType, RequestType, ExtendedResponseType> = {
    hideOnLoading?: boolean;
    manager: PaginatedDataManager<ItemType, RequestType, ExtendedResponseType>;
}

const REENABLE_INTERVAL = 7000; // 7s

export const PaginatedGridReloadButton = observer(<ItemType, RequestType, ExtendedResponseType>(
    {hideOnLoading = false, manager}: PropsWithChildren<PaginatedGridReloadButtonProps<ItemType, RequestType, ExtendedResponseType>>
): ReactElement|null => {

    const {t} = useTranslation();
    const [disabled, setDisabled] = useState<boolean>(false);
    const reenableTimeoutRef = useRef<number|null>(null);

    const onReload = useCallback(() => {
        if (disabled || manager.isLoading) {
            return;
        }
        setDisabled(true);
        void manager.reload()
            .finally(() => {
                reenableTimeoutRef.current = window.setTimeout(() => {
                    setDisabled(false);
                }, REENABLE_INTERVAL);
            })
    }, [disabled, manager]);

    if (hideOnLoading && manager.isLoading) {
        return null;
    }

    return <Button
        className="paginated-grid__reload-button"
        onClick={onReload}
        variant="secondary"
        size="sm"
        disabled={disabled}
        title={t(disabled ? 'global:grid.reload.disabled' : 'global:grid.reload.enabled')}
    >&#8635;</Button>
})
