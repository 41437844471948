
import {CancellationStrategy} from "@app/CardPayment/model/CancellationStrategy";
import {CardReceipt} from "@app/CardPayment/model/CardReceipt";
import {CardTerminalTransactionId} from "@app/CardPayment/model/CardTerminalTransactionId";
import {BarcodeTransaction, CardTransaction, MerchantTransaction} from "@app/CardPayment/model/CardTransaction";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";
import {ResponseWithEmbedded} from "@common/model/responses/ResponseWithEmbedded";

// Requests

export type CreateTransactionRequest = BarcodeTransaction | MerchantTransaction;

export const isBarcodeCreateTransactionRequest = (request: CreateTransactionRequest): request is BarcodeTransaction => {
    return 'barcode' in request;
}

export const isMerchantCreateTransactionRequest = (request: CreateTransactionRequest): request is MerchantTransaction => {
    return 'merchant' in request;
}

export type SignTransactionRequest = {
    isSignatureMatching: boolean;
}

export enum PrintCardReceiptRequestType {
    CUSTOMER_RECEIPT = 'customer-card-terminal-receipt',
    MERCHANT_RECEIPT = 'merchant-card-terminal-receipt',
}

export type PrintCardReceiptRequest = {
    transactionId: string;
    receiptType: PrintCardReceiptRequestType;
    forcePrint?: boolean,
}

export type ReverseOrRefundTransactionRequest = {
    id: string;
}

// Responses

export type CardTerminalTransactionIdResponse = GeneralSuccessResponseContent & {
    cardTransaction: CardTerminalTransactionId;
}

export type CardTerminalTransactionDetailResponse = GeneralSuccessResponseContent & ResponseWithEmbedded & {
    cardTransaction: CardTransaction;
}

export type CancellationStrategyResponse = GeneralSuccessResponseContent & {
    cancellationStrategy: CancellationStrategy;
}

export type PrintCardReceiptResponse = GeneralSuccessResponseContent & {
    receipts: {
        customerReceipt?: CardReceipt;
        merchantReceipt?: CardReceipt;
    }
}
