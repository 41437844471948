import {useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useErrorCodeToTranslationKey} from "@common/hooks/useErrorCodeToTranslationKey";
import {StringMap, TranslatableError} from "@common/model/errors/TranslatableError";
import {getApiErrorCode} from "@common/utils/api/getApiErrorCode";

export const useErrorToMessage = (
    translationKeyPrefix?: string,
    unknownErrorTranslationKey?: string,
) => {
    const {t} = useTranslation();
    const errorCodeToTranslationKey = useErrorCodeToTranslationKey(translationKeyPrefix, unknownErrorTranslationKey);

    return useCallback((error: Error, translationParams: StringMap = {}) => {
        if (error instanceof TranslatableError) {
            return t<string>(
                error.message,
                error.translationParams ? {...translationParams, ...error.translationParams} : undefined
            );
        }

        const errorCode = getApiErrorCode(error);
        return t<string>(errorCodeToTranslationKey(errorCode, error), translationParams);

    }, [errorCodeToTranslationKey, t]);
}
