import classNames from "classnames";
import {FC, useCallback} from "react";
import {UseFormReturn, useWatch} from "react-hook-form";

import {FormGroup, FormGroupProps} from "@common/components/forms/FormGroup/FormGroup";
import {Img} from "@common/components/Img/Img";

import './searchInput.scss';

export type SearchInputData = {
    query: string;
}

export type SearchInputProps = Partial<FormGroupProps<SearchInputData>> & {
    form: UseFormReturn<SearchInputData>;
    onSubmit: (data: SearchInputData) => void;
}

export const SearchInput: FC<SearchInputProps> = ({
    form, onSubmit, ...formGroupProps
}) => {
    const query = useWatch({control: form.control, name: 'query'});

    const search = useCallback(() => {
        if (query !== '') {
            void form.handleSubmit(onSubmit)();
        } else {
            form.setFocus('query');
        }
    }, [form, onSubmit, query]);

    const reset = useCallback(() => {
        form.reset({query: ''});
        onSubmit({query: ''});
    }, [form, onSubmit]);

    return <div className={classNames('search-input', {'search-input_with-value': query !== ''})}>
        <FormGroup {...formGroupProps} form={form} name="query">
            <div className="search-input__controls">
                {query && query !== '' && <Img
                    onClick={reset}
                    className="search-input__reset"
                    src="/images/icons/ico/ico-delete.svg"
                    alt="delete"
                />}
                <Img
                    onClick={search}
                    className="search-input__search"
                    src="/images/icons/ico/ico-search.svg"
                    alt="search"
                />
            </div>
        </FormGroup>
    </div>
}
