import {useEffect} from "react";

import {CardPaymentState, CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";

export const useTransactionStatusEffect = (
    cardPaymentState: CardPaymentState,
    effect: (transactionStatus: CardPaymentTransactionStatus) => void,
): void => {

    const transactionStatus = cardPaymentState.status !== CardPaymentStatus.READY
        ? cardPaymentState.transaction.status
        : null;

    useEffect(() => {
        if (transactionStatus !== null) {
            effect(transactionStatus);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [transactionStatus])

}
