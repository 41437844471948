import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Img} from "@common/components/Img/Img";

export type PacketCardPaymentIconProps = {
    cardPaymentAllowed: boolean;
}

export const PacketCardPaymentIcon: FC<PacketCardPaymentIconProps> = ({cardPaymentAllowed}) => {
    const {t} = useTranslation();

    const translationKey = cardPaymentAllowed
        ? 'packet:property.hasCashOnDelivery.canBePaidByCard'
        : 'packet:property.hasCashOnDelivery.canNotBePaidByCard';

    const title = t(translationKey);

    return <Img src={`/images/icons/ico/ico-card-${cardPaymentAllowed ? 'yes' : 'no'}.svg`} alt={title} title={title} />
}
