import {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {CancelPayment, useCancelPayment} from "@app/CardPayment/hooks/useCancelPayment";
import {useConfirmSignature} from "@app/CardPayment/hooks/useConfirmSignature";
import {Pay, usePay} from "@app/CardPayment/hooks/usePay";
import {CardPaymentState, CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransaction} from "@app/CardPayment/model/CardPaymentTransaction";
import {cardPaymentStateForCancelling} from "@app/CardPayment/utils/cardPaymentStateForCancelling";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";

export type UseCardPaymentReturn = {
    cardPaymentState: CardPaymentState;
    pay: Pay;
    cancelPayment: CancelPayment;
    confirmSignature: () => void;
    resetPaymentState: () => void;
}

export const useCardPayment = (
    onTransactionSuccess: (transaction: CardPaymentTransaction) => void,
    onCancellingSuccess: () => void,
    onRunning: (running: boolean) => void,
    packet?: DeliveryPacket,
    transactionIdForCancelling: string|null = null,
    toastsInModal: boolean = true,
): UseCardPaymentReturn => {
    const {t} = useTranslation();

    const [cardPaymentState, setCardPaymentState] = useState<CardPaymentState>(() => {
        if (packet && packet.isCod && packet.cardPaymentState) {
            return packet.cardPaymentState;
        }

        if (transactionIdForCancelling) {
            return cardPaymentStateForCancelling(transactionIdForCancelling);
        }

        return {status: CardPaymentStatus.READY};
    });

    useEffect(() => {
        if (transactionIdForCancelling) {
            const newCardPaymentState = cardPaymentStateForCancelling(transactionIdForCancelling);

            setCardPaymentState(newCardPaymentState);
            if (packet && packet.isCod) {
                packet.updateCardPaymentState(newCardPaymentState);
            }
        }
    }, [packet, transactionIdForCancelling]);

    const resetPaymentState = useCallback(() => {
        setCardPaymentState({status: CardPaymentStatus.READY});
    }, []);

    const {pay, stopPayment} = usePay(cardPaymentState, setCardPaymentState, onTransactionSuccess, onRunning, toastsInModal, t);

    const cancelPayment = useCancelPayment(
        cardPaymentState,
        setCardPaymentState,
        stopPayment,
        onCancellingSuccess,
        onRunning,
        transactionIdForCancelling,
        t,
    );

    const confirmSignature = useConfirmSignature(cardPaymentState, setCardPaymentState);

    return {
        cardPaymentState,
        pay,
        cancelPayment,
        confirmSignature,
        resetPaymentState,
    }
}
