import {FC, Fragment, useCallback} from "react";
import {FieldPath, useController, UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";
import striptags from "striptags";

import {Directions} from "@app/Branch/model/Attribute/Directions";
import {Button} from "@common/components/Button/Button";
import {WysiwygInput} from "@common/components/forms/WysiwygInput/WysiwygInput";
import {Img} from "@common/components/Img/Img";

export type DirectionEditorProps = {
    form: UseFormReturn<Directions>;
    name: FieldPath<Directions>;
    formId: string;
    defaultValue: string;
    disabled?: boolean;
    required?: boolean;
    minLength?: number;
}

export const DirectionEditor: FC<DirectionEditorProps> = ({
    defaultValue,
    disabled,
    form,
    formId,
    minLength,
    name,
    required,
}) => {
    const {t} = useTranslation();

    const formController = useController({
        control: form.control,
        name,
        shouldUnregister: true,
        rules: {
            required: required ? t<string>(`branchSettings:directions.${name}.error.required`) : undefined,
            validate: minLength !== undefined ? (value: any) => {
                const sanitizedValue = striptags(value as string).replace(/&nbsp;/g, ' ').trim();
                return sanitizedValue.length >= minLength
                    || t<string>(`branchSettings:directions.${name}.error.minLength`, {minLength});
            } : undefined,
        }
    });

    const reset = useCallback(() => {
        form.setValue(name, defaultValue);
    }, [defaultValue, form, name]);

    const currentValue = form.watch(name);

    return <Fragment>
        <WysiwygInput
            name={name}
            label={<Fragment>
                <h5>{t(`branchSettings:directions.${name}.label`)}</h5>
                <p>{t(`branchSettings:directions.${name}.help`)}</p>
            </Fragment>}
            formController={formController}
            allowLinks={false}
            disabled={disabled}
            formId={formId}
        />
        {currentValue !== defaultValue && <Button variant="link" onClick={reset}>
            <Img src="/images/icons/ico/ico-delete.svg" alt={t('message:tabs.content.new.action.clear')} />{' '}
            {t('viewBranchSettings:directionsSettings.reset')}
        </Button>}
    </Fragment>
}
