import {FC} from "react";

import {Img} from "@common/components/Img/Img";

import './labelWithHelp.scss';

export type LabelWithHelpProps = {
    label: string;
    help: string;
}

export const LabelWithHelp: FC<LabelWithHelpProps> = ({help, label}) => {
    return <span className="label-with-help">
        <span>{label}</span>
        <Img src="/images/icons/ico/ico-i.svg" alt={help} title={help} />
    </span>

}
