import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {
    ReaderJobItemInfo
} from "@app/Devices/components/ReaderModal/components/ReaderJobDetail/ReaderJobItemInfo/ReaderJobItemInfo";
import {
    ReaderJobItemPacketsGrid
} from "@app/Devices/components/ReaderModal/components/ReaderJobDetail/ReaderJobItemPacketsGrid/ReaderJobItemPacketsGrid";
import {ReaderJobResults} from "@app/Devices/components/ReaderModal/components/ReaderJobResults/ReaderJobResults";
import {jobItemParcelTitle} from "@app/Devices/components/ReaderModal/utils/jobItemParcelTitle";
import {jobItemTitle} from "@app/Devices/components/ReaderModal/utils/jobItemTitle";
import {ReaderJobTitleType} from "@app/Devices/components/ReaderModal/utils/resolveSubSection";
import {ReaderJob} from "@app/Devices/model/ReaderJob";
import {ReaderJobType} from "@app/Devices/model/ReaderJobType";

import './readerJobDetail.scss';

export type ReaderJobDetailProps = {
    job: ReaderJob;
}

export const ReaderJobDetail: FC<ReaderJobDetailProps> = ({job}) => {
    const {t} = useTranslation();

    return <div>
        <ReaderJobResults results={job.results} job={job} />
        {job.items.map((item, index) => {
            const itemTitle = jobItemTitle(item, job, t);
            const itemParcelTitle = jobItemParcelTitle(item, job, t);

            return <div key={index} className="reader-job-detail__item">
                {itemTitle && <h5>{itemTitle}</h5>}
                {itemParcelTitle && <h5>{itemParcelTitle}</h5>}
                {job.type !== ReaderJobType.RECEIVE_PARCELS && <Fragment>
                    <ReaderJobResults results={item.results} job={job} titleType={ReaderJobTitleType.PARCEL} />
                    {item.packets && <ReaderJobItemPacketsGrid job={job} packets={item.packets} />}
                </Fragment>}
                {job.type === ReaderJobType.RECEIVE_PARCELS && <ReaderJobItemInfo item={item} job={job} />}
            </div>
        })}
        {(!job.results || job.results.length === 0) && job.items.length === 0 && <div
            className="reader-job-detail__no-data"
        >
            <strong>{t('reader:error.noDataForProcessing')}</strong>
        </div>}
    </div>
}
