import {FC} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {
    BranchIndexRoute, BranchQualityRoute, BranchSettingsRoute, BranchStatsRoute, ManagedStatsRoute
} from "@app/Branch/BranchRoutes";
import {BranchQualityPage} from "@app/Branch/pages/BranchQualityPage/BranchQualityPage";
import {BranchSettingsPage} from "@app/Branch/pages/BranchSettingsPage/BranchSettingsPage";
import {BranchStatsPage} from "@app/Branch/pages/BranchStatsPage/BranchStatsPage";
import {ManagedStatsPage} from "@app/Branch/pages/ManagedStatsPage/ManagedStatsPage";
import {RouteParamsProvider} from "@common/components/routing/RouteParamsProvider/RouteParamsProvider";

export const Branch: FC = () => {
    return <Routes>
        <Route path={BranchIndexRoute.path} element={<Navigate to={BranchSettingsRoute.path} />} />
        <Route path={BranchSettingsRoute.path} element={<BranchSettingsPage />} />
        <Route path={BranchQualityRoute.path} element={<BranchQualityPage />} />
        <Route path={ManagedStatsRoute.path} element={<ManagedStatsPage />} />
        <Route path={BranchStatsRoute.path} element={<RouteParamsProvider
                path={BranchStatsRoute}
                render={({branchId}) => (<BranchStatsPage branchId={branchId} />)}
            />}
        />
    </Routes>
}
