import {FC} from "react";
import {useTranslation} from "react-i18next";

import {BranchQualityDetailType} from "@app/Branch/model/BranchQuality/BranchQualityDetail";
import {PenaltyBranchQualityItem} from "@app/Branch/model/BranchQuality/BranchQualityItem";
import {
    ProcessPenaltyDetailModal
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/ProcessPenaltyDetailModal";
import {camelize} from "@common/utils/camelize";

export type ProcessPenaltyItemProps = {
    penaltyType: BranchQualityDetailType;
    penaltyItem: PenaltyBranchQualityItem;
}

export const ProcessPenaltyItem: FC<ProcessPenaltyItemProps> = ({penaltyItem, penaltyType}) => {
    const {t} = useTranslation();

    return <div className="branch-quality-page__item">
        <h6>{t(`branchQuality:processPenalty.${camelize(penaltyType)}.title`)}</h6>
        <div>
            {penaltyItem.value}{' '}
            <ProcessPenaltyDetailModal penaltyType={penaltyType} />
        </div>
    </div>

}
