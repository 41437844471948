import {Fragment, PropsWithChildren, ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {NumericFormat} from "react-number-format";

export type NumberFormatterProps = {
    numeric: number|string;
    decimalScale?: number;
    fixedDecimalScale?: boolean;
}

export const NumberFormatter = ({decimalScale, fixedDecimalScale, numeric}: PropsWithChildren<NumberFormatterProps>): ReactElement<any, string>|null => {
    const {t} = useTranslation();

    const decimalSeparator = t('core:money.decimalSeparator');
    let thousandsSeparator = t('core:money.thousandsSeparator');
    if (thousandsSeparator === ' ') {
        thousandsSeparator = " ";
    }

    if (typeof numeric === 'string' && !/^[+-]?([0-9]*[.])?[0-9]+$/.test(numeric)) {
        return <Fragment>{numeric}</Fragment>;
    }

    return <NumericFormat
        value={typeof numeric === 'number' ? numeric : parseFloat(numeric)}
        thousandSeparator={thousandsSeparator}
        decimalSeparator={decimalSeparator}
        decimalScale={decimalScale}
        fixedDecimalScale={fixedDecimalScale}
        displayType="text"
    />
}
