import {FC, Fragment, useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {BranchCommissionRoute, ManagedCommissionRoute} from "@app/Commission/CommissionRoutes";
import {BillingInformation} from "@app/Commission/components/BillingInformation/BillingInformation";
import {InvoiceGrid} from "@app/Commission/components/InvoiceGrid/InvoiceGrid";
import {InvoiceListManager} from "@app/Commission/components/InvoiceGrid/InvoiceListManager";
import {
    CommissionBranchesManager
} from "@app/Commission/components/ManagedCommissionGrid/CommissionBranchesManager";
import {CommissionInvoiceBillingInformation} from "@app/Commission/model/CommissionInvoiceBillingInformation";
import {NewInvoiceForm} from "@app/Commission/pages/NewInvoicePage/NewInvoiceForm/NewInvoiceForm";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";
import {useCaslGuard} from "@common/hooks/useCaslGuard";
import {useSingleBranchTo} from "@common/hooks/useSingleBranchTo";

import './newInvoicePage.scss';

export const NewInvoicePage: FC = () => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [vatTaxable, setVatTaxable] = useState<boolean>(false);
    const [hasAccountNumber, setHasAccountNumber] = useState<boolean>(false);

    const invoiceListManager = useMemo<InvoiceListManager>(
        () => new InvoiceListManager(appContext.api),
        [appContext.api]
    );

    const commissionBranchesManager = useMemo<CommissionBranchesManager>(
        () => new CommissionBranchesManager(appContext.api),
        [appContext.api]
    );

    const onBillingInformationLoaded = useCallback((billingInformation: CommissionInvoiceBillingInformation) => {
        setVatTaxable(billingInformation.vatTaxable);
        setHasAccountNumber(billingInformation.accountNumber !== null && billingInformation.accountNumber !== '');
    }, []);

    const onInvoiced = useCallback(async () => {
        await invoiceListManager.reload();
        await commissionBranchesManager.reload();
    }, [commissionBranchesManager, invoiceListManager]);

    const branchDetailTo = useSingleBranchTo();
    const canShow = useCaslGuard(Action.CREATE, Subject.COMMISSION_INVOICE, branchDetailTo(BranchCommissionRoute, ManagedCommissionRoute));
    if (!canShow) {
        return null;
    }

    return <PageContent className="new-invoice-page">
        <ReturnLink
            to={branchDetailTo(BranchCommissionRoute, ManagedCommissionRoute)}
            label={t('invoice:action.goBackToCommission')}
        />
        <PageHeader>{t('viewCommission:newInvoice.header')}</PageHeader>
        <h4>{t('viewCommission:billingInformation.header')}</h4>
        <BillingInformation onBillingInformationLoaded={onBillingInformationLoaded} />
        {hasAccountNumber && <Fragment>
            <NewInvoiceForm
                commissionBranchesManager={commissionBranchesManager}
                vatTaxable={vatTaxable}
                onInvoiced={onInvoiced}
            />
            <div className="new-invoice-page__invoice-list">
                <h4>{t('viewCommission:invoiceList.header')}</h4>
                <InvoiceGrid manager={invoiceListManager} onRemove={onInvoiced} />
            </div>
        </Fragment>}
    </PageContent>
}
