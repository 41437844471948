import {QueryParam} from "@common/model/requests/QueryParam";
import {RequestWithEmbedded} from "@common/model/requests/RequestWithEmbedded";

export const createEmbedParameter = (request: RequestWithEmbedded): QueryParam[] => {
    const result: QueryParam[] = [];

    if (request.embed && request.embed.length) {
        result.push({name: 'embed', value: request.embed.join(',')});
    }

    return result;
}
