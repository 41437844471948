import {FC} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    BranchCardTransactionPage
} from "@app/Transaction/pages/BranchCardTransactionPage/BranchCardTransactionPage";
import {
    BranchCashRegisterPage
} from "@app/Transaction/pages/BranchCashRegisterPage/BranchCashRegisterPage";
import {
    ManagedCardTransactionPage
} from "@app/Transaction/pages/ManagedCardTransactionPage/ManagedCardTransactionPage";
import {
    ManagedCashRegisterPage
} from "@app/Transaction/pages/ManagedCashRegisterPage/ManagedCashRegisterPage";
import {
    PartnerCardTransactionPage
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionPage";
import {
    BranchCardTransactionRoute, BranchCashRegisterRoute, ManagedCardTransactionRoute,
    ManagedCashRegisterRoute, PartnerCardTransactionRoute
} from "@app/Transaction/TransactionModuleRoutes";
import {RouteParamsProvider} from "@common/components/routing/RouteParamsProvider/RouteParamsProvider";

export const TransactionModule: FC = () => {
    const appContext = useAppContext();

    return <Routes>
        <Route index element={<Navigate to={appContext.router.path(ManagedCashRegisterRoute)} />} />
        <Route path={ManagedCashRegisterRoute.path} element={<ManagedCashRegisterPage />} />
        <Route path={BranchCashRegisterRoute.path} element={<RouteParamsProvider
                path={BranchCashRegisterRoute}
                render={({branchId}) => (<BranchCashRegisterPage branchId={branchId} />)}
            />}
        />
        <Route path={ManagedCardTransactionRoute.path} element={<ManagedCardTransactionPage />} />
        <Route path={PartnerCardTransactionRoute.path} element={<PartnerCardTransactionPage />} />
        <Route path={BranchCardTransactionRoute.path} element={<RouteParamsProvider
                path={BranchCardTransactionRoute}
                render={({branchId}) => (<BranchCardTransactionPage branchId={branchId} />)}
            />}
        />
    </Routes>
}
