import classNames from "classnames";
import {FC, useCallback} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {BranchEvent} from "@app/BranchEvent/BranchEvent";
import {BaseBranchEvent} from "@app/BranchEvent/model/BaseBranchEvent";
import {BranchEvent as BranchEventModel} from "@app/BranchEvent/model/BranchEvent";
import {BranchEventCode} from "@app/BranchEvent/model/BranchEventCode";
import {BranchEventSeverity} from "@app/BranchEvent/model/BranchEventSeverity";
import {UnknownBranchEventCodeError} from "@app/BranchEvent/model/UnknownBranchEventCodeError";
import {Img} from "@common/components/Img/Img";
import {useLogError} from "@common/hooks/useLogError";

import './branchEventList.scss';

export type BranchEventListProps = {
    branchEvents: BranchEventModel[];
    hideEvent: (event: BranchEventModel) => void;
}

export const BranchEventList: FC<BranchEventListProps> = ({branchEvents, hideEvent}) => {
    const {t} = useTranslation();
    const logError = useLogError();

    const onNotFound = useCallback((event: BaseBranchEvent) => {
        void logError(new UnknownBranchEventCodeError(event.code));
    }, [logError]);

    const eventCanBeHidden = (event: BranchEventModel): boolean => {
        return event.severity !== BranchEventSeverity.HIGH && [
            BranchEventCode.CASH_REGISTER_TODAY_TRANSFER,
            BranchEventCode.CASH_REGISTER_MANUAL_TRANSFER_REQUIRED,
            BranchEventCode.COMMISSION_CREATE_INVOICE_NOTIFY,
        ].includes(event.code);
    }

    return <div className="branch-event-list" data-xid="branch-event-list">
        {branchEvents
            .map((branchEvent) => {
                const renderedEvent = BranchEvent({event: branchEvent, onNotFound})
                if (!renderedEvent) {
                    return null;
                }

                const canBeHidden = eventCanBeHidden(branchEvent);

                return <div className={classNames('branch-event-list__event', {
                    'branch-event-list__event_low-severity': branchEvent.severity === BranchEventSeverity.LOW,
                    'branch-event-list__event_can-be-hidden': canBeHidden,
                })} key={branchEvent.uniqId} data-xid={`branch-event-${branchEvent.uniqId}`} >
                    <Img src={`/images/icons/ico/ico-exclamation-${branchEvent.severity === BranchEventSeverity.LOW ? '2' : 'red'}.svg`} alt="exclamation" />
                    <div className="branch-event-list__event-content">
                        {renderedEvent}
                    </div>
                    {canBeHidden && <Button
                        onClick={() => hideEvent(branchEvent)}
                        variant="link"
                        className="branch-event-list__hide-link"
                        data-xid="hide-link"
                    >
                        {t('viewDashboard:branchEvent.hide')}
                    </Button>}
                </div>
            })}
    </div>
}
