import {useCallback} from "react";
import {useNavigate} from "react-router-dom";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {To} from "@common/model/ReactRoute";

export const useAppNavigate = () => {
    const navigate = useNavigate();
    const appContext = useAppContext();

    return useCallback(<ReactRouteParamNames extends string = string>(to: To<ReactRouteParamNames>) => {
        navigate(appContext.router.path(to));
    }, [appContext.router, navigate]);
}
