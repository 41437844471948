import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const branchInventoryListEndpoint: Endpoint<'branchId'> = {
    method: HttpMethod.GET,
    url: '/inventory/list/:branchId',
    routeParamNames: ['branchId'],
}

export const cancelInventoryEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/inventory/cancel',
}

export const confirmInventoryEndpoint: Endpoint = {
    method: HttpMethod.POST,
    url: '/inventory/confirm',
}

export const inventoryDetailEndpoint: Endpoint<'inventoryId'> = {
    method: HttpMethod.GET,
    url: '/inventory/detail/:inventoryId',
    routeParamNames: ['inventoryId'],
}

export const inventoryPacketsListEndpoint: Endpoint<'inventoryId'> = {
    method: HttpMethod.GET,
    url: '/inventory/packet/list/:inventoryId',
    routeParamNames: ['inventoryId'],
}

export const managedBranchInventoryListEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/inventory/managed/list',
}
