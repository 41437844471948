import {FC, Fragment} from "react";
import {useTranslation} from "react-i18next";

import {Branch} from "@app/Branch/model/Branch";
import {Img} from "@common/components/Img/Img";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './parcelRouteInfo.scss';

export type ParcelRouteInfoProps = {
    receivedByBranch?: Branch;
    recipientBranch?: Branch;
    senderBranch?: Branch;
}

export const ParcelRouteInfo: FC<ParcelRouteInfoProps> = ({receivedByBranch, recipientBranch, senderBranch}) => {
    const {t} = useTranslation();

    return <div className="parcel-route-info">
        <div className="parcel-route-info__sender">
            <div className="parcel-route-info__label">{t('parcel:property.sender')}</div>
            <strong>{dashWhenEmpty(senderBranch && senderBranch.name)}</strong>
        </div>
        <div className="parcel-route-info__arrow">
            <Img src="/images/icons/arr/arr-14-narrow-lightgray.svg" alt="arrow" />
        </div>
        <div className="parcel-route-info__recipient">
            <div className="parcel-route-info__label">{t('parcel:property.recipient')}</div>
            <div>{dashWhenEmpty(recipientBranch && recipientBranch.name)}</div>
            {receivedByBranch && <Fragment>
                <div className="parcel-route-info__label">{t('parcel:property.receivedBy')}</div>
                <strong>{receivedByBranch.name}</strong>
            </Fragment>}
        </div>
    </div>
}
