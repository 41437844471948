import {observer} from "mobx-react-lite";
import {Fragment} from "react";

import {AbilityContext} from "@app/AppContext/classes/Casl/Can";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {FCWithChildren} from "@common/model/FCWithChildren";

export const CaslProvider: FCWithChildren = observer(({children}) => {
    const appContext = useAppContext();

    if (!appContext.user.isLoggedIn) {
        return <Fragment>{children}</Fragment>
    }
    if (!appContext.casl.loaded) {
        return null;
    }

    return <AbilityContext.Provider value={appContext.casl.ability}>
        {children}
    </AbilityContext.Provider>
})
