export enum InventoryStatusId {
    NEW = 0,
    SOLVED = 1,
    IN_PROGRESS = 2,
    CANCELLED = 3,
}

export type InventoryStatus = {
   id: InventoryStatusId;
   name: string;
}
