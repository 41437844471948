import {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {managedCashRegisterList} from "@app/Transaction/api/cashRegisterApi";
import {ManagedCashRegister} from "@app/Transaction/model/CashRegister/ManagedCashRegister";
import {
    ManagedCashRegisterGridRow
} from "@app/Transaction/pages/ManagedCashRegisterPage/ManagedCashRegisterGrid/ManagedCashRegisterGridRow/ManagedCashRegisterGridRow";
import {BranchCashRegisterRoute} from "@app/Transaction/TransactionModuleRoutes";
import {GridSorter} from "@common/components/grids/GridSorter/GridSorter";
import {useSorter} from "@common/components/grids/GridSorter/useSorter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {Loading} from "@common/components/Loading/Loading";
import {AppLink} from "@common/components/routing/AppLink/AppLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {addAmounts} from "@common/utils/addAmounts";

import './managedCashRegisterGrid.scss';

export const ManagedCashRegisterGrid: FC = () => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [managedCashRegisters, setManagedCashRegisters] = useState<ManagedCashRegister[]|null|false>(null);
    const showAppErrorToast = useShowErrorToast(appToast);

    useEffect(() => {
        if (managedCashRegisters === null) {
            managedCashRegisterList(appContext.api)
                .then((response) => {
                    setManagedCashRegisters(response.items);
                })
                .catch((error: Error) => {
                    showAppErrorToast(error);
                    setManagedCashRegisters(false);
                });
        }
    }, [appContext.api, managedCashRegisters, showAppErrorToast]);

    const sorter = useSorter<ManagedCashRegister, 'branchName'|'sumTotal'>(
        'managedCashRegisterList',
        {id: 'branchName'},
        {
            branchName: (managedCashRegister) => managedCashRegister.branch.name,
            sumTotal: (managedCashRegister) => parseInt(managedCashRegister.sumTotal, 10),
        }
    );

    if (managedCashRegisters) {
        managedCashRegisters.sort(sorter.sort);
    }

    const sumCashRegister: ManagedCashRegister|null = managedCashRegisters ? managedCashRegisters.reduce((sum, current) => {
        sum.sumTotal = addAmounts(sum.sumTotal, current.sumTotal);
        sum.lastWeekLeft = addAmounts(sum.lastWeekLeft, current.lastWeekLeft);
        sum.beforeLastWeekLeft = addAmounts(sum.beforeLastWeekLeft, current.beforeLastWeekLeft);
        sum.waitingPasswordTransfersSum = addAmounts(sum.waitingPasswordTransfersSum, current.waitingPasswordTransfersSum);
        sum.waitingTransfersSum = addAmounts(sum.waitingTransfersSum, current.waitingTransfersSum);
        return sum;
    }, {...managedCashRegisters[0], sumTotal: '0', lastWeekLeft: '0', beforeLastWeekLeft: '0', waitingPasswordTransfersSum: '0', waitingTransfersSum: '0'}) : null;

    return <div className="managed-cash-register-grid">
        <h4>{t('viewTransaction:managedCashRegister.grid.header')}</h4>
        <Loading active={managedCashRegisters === null} />
        {managedCashRegisters && <GridTable totalCount={managedCashRegisters.length}>
            <thead>
            <tr className="managed-cash-register-grid__top-header">
                <th colSpan={2} />
                <th colSpan={2}>{t('viewTransaction:managedCashRegister.grid.cashRegisterState')}</th>
                <th colSpan={2}>{t('viewTransaction:managedCashRegister.grid.waitingTransfers')}</th>
            </tr>
            <tr>
                <th><GridSorter sortId="branchName" sorter={sorter}>
                    {t('cashRegister:property.branchName')}
                </GridSorter></th>
                <th><GridSorter sortId="sumTotal" sorter={sorter}>
                    {t('cashRegister:property.sumTotal')}
                </GridSorter></th>
                <th className="managed-cash-register-grid__last-week-left">{t('cashRegister:property.lastWeekLeft')}</th>
                <th className="managed-cash-register-grid__before-last-week-left">
                    {t('cashRegister:property.beforeLastWeekLeft')}
                </th>
                <th className="managed-cash-register-grid__waiting-password-transfers">
                    {t('cashRegister:property.waitingPasswordTransfersSum')}
                </th>
                <th className="managed-cash-register-grid__waiting-transfers">
                    {t('cashRegister:property.waitingTransfersSum')}
                </th>
                <th />
            </tr>
            </thead>
            <tbody>
            {managedCashRegisters.map((cashRegister) => (<ManagedCashRegisterGridRow
                key={cashRegister.branch.id}
                cashRegister={cashRegister}
            >
                <td className="managed-cash-register-grid__branch-name">
                    <AppLink to={{route: BranchCashRegisterRoute, params: {branchId: cashRegister.branch.id}}}>
                        {cashRegister.branch.name}
                    </AppLink>
                </td>
                <td className="managed-cash-register-grid__show-detail">
                    <AppLink to={{route: BranchCashRegisterRoute, params: {branchId: cashRegister.branch.id}}}>
                        {t('viewTransaction:managedCashRegister.grid.showDetail')}
                    </AppLink>
                </td>
            </ManagedCashRegisterGridRow>))}
            {sumCashRegister && <ManagedCashRegisterGridRow
                cashRegister={sumCashRegister}
                className="managed-cash-register-grid__summary"
            >
                <td>{t('viewTransaction:managedCashRegister.grid.summary')}</td>
                <td />
            </ManagedCashRegisterGridRow>}
            </tbody>
        </GridTable>}
    </div>
}
