import {observer} from "mobx-react-lite";
import {FC, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    BranchCommissionGridExport
} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionGrid/BranchCommissionGridExport/BranchCommissionGridExport";
import {
    BranchCommissionGridFilter
} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionGrid/BranchCommissionGridFilter/BranchCommissionGridFilter";
import {
    BranchCommissionGridHeader
} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionGrid/BranchCommissionGridHeader/BranchCommissionGridHeader";
import {
    BranchCommissionListManager
} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionGrid/BranchCommissionListManager";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {ResponsiveRender} from "@common/components/ResponsiveRender/ResponsiveRender";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

import './branchCommissionGrid.scss';

export type BranchCommissionGridProps = {
    branchId: string;
}

export const BranchCommissionGrid: FC<BranchCommissionGridProps> = observer(({branchId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();
    const branchCommissionList = useMemo(
        () => new BranchCommissionListManager(branchId, appContext.api),
        [appContext.api, branchId]
    );

    return <div className="branch-commission-grid">
        <h4>{t('viewCommission:branchCommissionGrid.header')}</h4>
        <BranchCommissionGridFilter manager={branchCommissionList} />
        <PaginatedGrid
            manager={branchCommissionList}
            topCountContent={branchCommissionList.hasItems
                ? <BranchCommissionGridExport manager={branchCommissionList} />
                : undefined
            }
        >
            <BranchCommissionGridHeader manager={branchCommissionList} />
            <tbody>
                {branchCommissionList.items.map((commission, index) => (<tr
                    key={`${commission.createdAt}-${index}`}
                >
                    <td><DateFormatter date={commission.createdAt} showTime={true} /></td>
                    <td>
                        {dashWhenEmpty(commission.barcode && <FormattedPacketBarcode
                            barcode={commission.barcode}
                            withHighlight={true}
                        />)}
                        <div className="branch-commission-grid__barcode-type">
                            {commission.type.name}
                        </div>
                    </td>
                    <td className="branch-commission-grid__type">{commission.type.name}</td>
                    <td>
                        <MoneyFormatter amount={commission.amount} currencyCode={commission.currency} />
                        {commission.note && <div className="branch-commission-grid__amount-note">
                            {commission.note}
                        </div>}
                    </td>
                    <td className="branch-commission-grid__note">{dashWhenEmpty(commission.note)}</td>
                </tr>))}
                {branchCommissionList.sum !== undefined && <tr className="branch-commission-grid__sum-row">
                    <ResponsiveRender up="sm">
                        <td colSpan={3}>
                            {t('viewCommission:branchCommissionGrid.sum')}
                        </td>
                    </ResponsiveRender>
                    <ResponsiveRender down="sm">
                        <td colSpan={2}>
                            {t('viewCommission:branchCommissionGrid.sum')}
                        </td>
                    </ResponsiveRender>
                    <td><MoneyFormatter
                        amount={branchCommissionList.sum}
                        currencyCode={branchCommissionList.items.length > 0
                            ? branchCommissionList.items[0].currency
                            : t('core:money.defaultCurrency')
                        }
                    /></td>
                </tr>}
            </tbody>
        </PaginatedGrid>
    </div>
})
