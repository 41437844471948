import classNames from "classnames";
import {FC, MutableRefObject} from "react";
import {useTranslation} from "react-i18next";

import {CodDeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {EMPTY_COD} from "@app/Packet/model/PacketDetailed";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {addAmounts} from "@common/utils/addAmounts";
import {CashCalculator} from "@packetModal/components/PacketDeliveryModal/TotalPayment/CashCalculator/CashCalculator";

import './totalPayment.scss';

export type TotalPaymentProps = {
    codPackets: CodDeliveryPacket[];
    currency: string;
    focusCashCalculator: boolean;
    focused: MutableRefObject<boolean>;
    saveAmountPaid: (newAmountPaid: string) => void;
    savedAmountPaid: string;
}

export const TotalPayment: FC<TotalPaymentProps> = ({
    codPackets,
    currency,
    focusCashCalculator,
    focused,
    saveAmountPaid,
    savedAmountPaid,
}) => {
    const {t} = useTranslation();

    const totalAmount = codPackets.reduce<string>((total, packet) => {
        if (!packet.paid) {
            return addAmounts(total, packet.info.deliveryMeta.codCashAmount || packet.info.cod);
        }
        return total;
    }, EMPTY_COD);

    const hasNonZeroTotalAmount = parseFloat(totalAmount) > 0;

    return <div className={classNames('total-payment', {'total-payment--all-paid': !hasNonZeroTotalAmount})}>
        {hasNonZeroTotalAmount && <CashCalculator
            amountToPay={totalAmount}
            currency={currency}
            focus={focusCashCalculator}
            focused={focused}
            saveAmountPaid={saveAmountPaid}
            savedAmountPaid={savedAmountPaid}
        />}
        <div>
            <div className="total-payment-label">{t('viewPacketDelivery:totalPayment.label')}</div>
            <div
                className={classNames('total-payment-value', {'total-payment-value--with-amount': hasNonZeroTotalAmount})}
                data-xid="total-payment-value"
            >
                {hasNonZeroTotalAmount
                    ? <MoneyFormatter amount={totalAmount} currencyCode={currency} />
                    : t('viewPacketDelivery:totalPayment.allPaid')
                }
            </div>
        </div>
    </div>;
}
