import {dateReviver} from "@common/utils/dateReviver";

export const readLocalStorage = <T>(key: string): T|null => {
    try {
        const item = window.localStorage.getItem(key);
        return item ? JSON.parse(item, dateReviver) as T : null;
    } catch (error) {
        writeLocalStorage(key, null);
        return null;
    }
}

export const writeLocalStorage = <T>(key: string, value: T|null) => {
    try {
        if (value === null) {
            window.localStorage.removeItem(key);
        } else {
            window.localStorage.setItem(key, JSON.stringify(value));
        }
    } catch (error) {
        if (isLocalStorageSupported()) {
            window.localStorage.clear();
            markLocalStorageAsProblematic();
        }
    }
}

const PROBLEMATIC_LOCAL_STORAGE_KEY = 'localStorage-problematic';

export const isLocalStorageProblematic = (): boolean => {
    return readLocalStorage(PROBLEMATIC_LOCAL_STORAGE_KEY) || false;
}

export const isLocalStorageSupported = (): boolean => {
    try {
        if (window.localStorage) {
            window.localStorage.setItem('test', 'test');
            window.localStorage.removeItem('test');
            return true;
        } else {
            return false;
        }
    } catch(e) {
        return false;
    }
}

const markLocalStorageAsProblematic = (): void => {
    try {
        window.localStorage.setItem(PROBLEMATIC_LOCAL_STORAGE_KEY, JSON.stringify(true));
    } catch (error) {
        // clear again and give up
        if (window.localStorage) {
            window.localStorage.clear();
        }
    }
}
