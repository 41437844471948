import {RefObject, useCallback, useEffect, useRef, useState} from "react";
import {useForm, UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {branchHoursRange, createBranchHoursExceptions} from "@app/OpeningHours/api/branchHoursApi";
import {branchToGridOpeningHours} from "@app/OpeningHours/components/EditableOpeningHoursGrid/branchToGridOpeningHours";
import {GridOpeningHours} from "@app/OpeningHours/components/EditableOpeningHoursGrid/GridOpeningHours";
import {BranchHoursException} from "@app/OpeningHours/model/OpeningHoursRequestsAndResponses";
import {BaseOpeningHoursGridForm} from "@app/OpeningHours/OpeningHoursFormTypes";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {ApiError} from "@common/model/errors/ApiError";
import {parseDate} from "@common/utils/parseDate";

export type UsePublicHolidayConfirmModal = {
    apiError?: ApiError;
    apiErrorsIndexMap?: Map<number, number>;
    close: () => void;
    form: UseFormReturn<BaseOpeningHoursGridForm>;
    isLoading: boolean;
    modalBodyRef: RefObject<HTMLDivElement>;
    open: () => void;
    opened: boolean;
    submit: (values: BaseOpeningHoursGridForm) => void;
};

export const usePublicHolidayConfirmModal = (startDate: string, endDate: string): UsePublicHolidayConfirmModal => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [opened, setOpened] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [gridOpeningHours, setGridOpeningHours] = useState<GridOpeningHours[]|null|false>(null);
    const [apiError, setApiError] = useState<ApiError|undefined>(undefined);
    const [apiErrorsIndexMap, setApiErrorsIndexMap] = useState<Map<number, number>|undefined>(undefined);
    const modalBodyRef = useRef<HTMLDivElement>(null);

    const form = useForm<BaseOpeningHoursGridForm>({
        defaultValues: {openingHours: []},
        shouldUnregister: true,
    });

    useEffect(() => {
        if (opened && gridOpeningHours === null && !isLoading) {
            setIsLoading(true);
            branchHoursRange(parseDate(startDate), parseDate(endDate), appContext.api)
                .then((response) => {
                    const gridHours = branchToGridOpeningHours(response.openingHours)
                    setGridOpeningHours(gridHours);
                    // deep clone
                    form.setValue('openingHours', gridHours.map((gridOpeningHour) => ({
                        ...gridOpeningHour,
                        hours: [...gridOpeningHour.hours.map((block) => ({...block}))],
                    })));
                })
                .catch((error: Error) => {
                    setGridOpeningHours(false);
                    modalToast.error(t(error.message));
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [appContext.api, endDate, form, gridOpeningHours, isLoading, opened, startDate, t]);

    const prepareRequestBranchHours = (originalHours: GridOpeningHours[], editedHours: GridOpeningHours[]): [BranchHoursException[], Map<number, number>] => {
        const indexMap = new Map<number, number>();
        let filteredIndex = 0;
        const requestBranchHours = editedHours
            .filter((editedHour, rowIndex) => {
                let different = false;
                editedHour.hours.forEach((editedHourBlock, blockIndex) => {
                    if (originalHours[rowIndex].hours[blockIndex].open !== editedHourBlock.open
                        || originalHours[rowIndex].hours[blockIndex].close !== editedHourBlock.close) {
                        different = true;
                    }
                });
                if (different) {
                    indexMap.set(filteredIndex, rowIndex);
                    ++filteredIndex;
                }
                return different;
            })
            .map((editedHour): BranchHoursException => {
                return {
                    date: editedHour.date,
                    action: 'create',
                    hours: editedHour.hours.filter((block) => {
                        return block.open !== '' && block.close !== '';
                    })
                }
            });

        return [requestBranchHours, indexMap];
    }

    const open = useCallback(() => {
        setOpened(true);
    }, []);

    const close = useCallback(() => {
        setOpened(false);
        form.reset({openingHours: []});
        setGridOpeningHours(null);
        setApiError(undefined);
        setApiErrorsIndexMap(undefined);
    }, [form]);

    const submit = useCallback((values: BaseOpeningHoursGridForm) => {
        if (!gridOpeningHours) {
            return;
        }

        const [branchHours, indexMap] = prepareRequestBranchHours(gridOpeningHours, values.openingHours);
        createBranchHoursExceptions({
            branchHours,
            holidayBranchHoursConfirmation: {start: startDate, end: endDate}
        }, appContext.api)
            .then(() => {
                close();
                appContext.branchEvents.setReloadRequired(true);
                appToast.success(t('openingHours:publicHolidayConfirmModal.success'));
            })
            .catch((error: Error) => {
                if (error instanceof ApiError && error.errors.length > 0) {
                    setApiError(error);
                    setApiErrorsIndexMap(indexMap);
                } else {
                    modalToast.error(t(error.message));
                }
                if (modalBodyRef.current) {
                    modalBodyRef.current.scrollTo({top: 0});
                }
            });
    }, [appContext.api, appContext.branchEvents, close, endDate, gridOpeningHours, startDate, t]);

    return {
        apiError,
        apiErrorsIndexMap,
        close,
        form,
        isLoading,
        modalBodyRef,
        open,
        opened,
        submit,
    };
}
