import {FC} from "react";
import {Route, Routes} from "react-router-dom";

import {AppWrapper} from "@app/App/components/AppWrapper/AppWrapper";
import {useAppRouteRedirections} from "@app/App/hooks/useAppRouteRedirections";
import {useIsBrowserSupported} from "@app/App/hooks/useIsBrowserSupported";
import {FailurePage} from "@app/App/pages/FailurePage/FailurePage";
import {FailurePageRoute} from "@app/App/pages/FailurePage/FailurePageRoute";
import {NotFoundPage} from "@app/App/pages/NotFoundPage/NotFoundPage";
import {SecuredAppModules} from "@app/App/SecuredAppModules";
import {UnsupportedBrowser} from "@app/App/UnsupportedBrowser/UnsupportedBrowser";
import {Branch} from "@app/Branch/Branch";
import {Commission} from "@app/Commission/Commission";
import {Inventory} from "@app/Inventory/Inventory";
import {Manuals} from "@app/Manuals/Manuals";
import {Messaging} from "@app/Messaging/Messaging";
import {MicroDepot} from "@app/MicroDepot/MicroDepot";
import {OpeningHours} from "@app/OpeningHours/OpeningHours";
import {Packet} from "@app/Packet/Packet";
import {DashboardPage} from "@app/Packet/pages/DashboardPage/DashboardPage";
import {Parcel} from "@app/Parcel/Parcel";
import {NewPasswordPage} from "@app/PasswordRecovery/pages/NewPasswordPage/NewPasswordPage";
import {PasswordRecoveryPage} from "@app/PasswordRecovery/pages/PasswordRecoveryPage/PasswordRecoveryPage";
import {
    NewPasswordRoute, PasswordRecoveryRoute
} from "@app/PasswordRecovery/PasswordRecoveryRoutes";
import {ReprintsPage} from "@app/Print/pages/ReprintsPage/ReprintsPage";
import {SignAsPage} from "@app/Sign/pages/SignAsPage/SignAsPage";
import {SignInPage} from "@app/Sign/pages/SignInPage/SignInPage";
import {SignAsRoute, SignInRoute} from "@app/Sign/SignRoutes";
import {Tools} from "@app/Tools/Tools";
import {TransactionModule} from "@app/Transaction/TransactionModule";
import {User} from "@app/User/User";
import {SecuredLayout} from "@common/layouts/SecuredLayout/SecuredLayout";

export const App: FC = () => {
    const isBrowserSupported = useIsBrowserSupported();
    const routeRedirections = useAppRouteRedirections();

    if (!isBrowserSupported) {
        return <AppWrapper unsupported><UnsupportedBrowser /></AppWrapper>
    }

    return <AppWrapper>
        <Routes>
            <Route path={SignInRoute.path} element={<SignInPage />} />
            <Route path={SignAsRoute.path} element={<SignAsPage />} />
            <Route path={PasswordRecoveryRoute.path} element={<PasswordRecoveryPage />} />
            <Route path={NewPasswordRoute.path} element={<NewPasswordPage />} />
            <Route path={FailurePageRoute.path} element={<FailurePage />} />
            <Route path="*" element={<SecuredLayout toWhenUnauthorized={SignInRoute} />}>
                <Route index element={<DashboardPage />} />
                <Route path={`${SecuredAppModules.BRANCH}/*`} element={<Branch />} />
                <Route path={`${SecuredAppModules.COMMISSION}/*`} element={<Commission />} />
                <Route path={`${SecuredAppModules.INVENTORY}/*`} element={<Inventory />} />
                <Route path={`${SecuredAppModules.MANUALS}/*`} element={<Manuals />} />
                <Route path={`${SecuredAppModules.MESSAGING}/*`} element={<Messaging />} />
                <Route path={`${SecuredAppModules.MICRODEPOT}/*`} element={<MicroDepot />} />
                <Route path={`${SecuredAppModules.OPENING_HOURS}/*`} element={<OpeningHours />} />
                <Route path={`${SecuredAppModules.PACKET}/*`} element={<Packet />} />
                <Route path={`${SecuredAppModules.PARCEL}/*`} element={<Parcel />} />
                <Route path={SecuredAppModules.PRINT} element={<ReprintsPage />} />
                <Route path={`${SecuredAppModules.TOOLS}/*`} element={<Tools />} />
                <Route path={`${SecuredAppModules.TRANSACTION}/*`} element={<TransactionModule />} />
                <Route path={`${SecuredAppModules.USER}/*`} element={<User />} />
                {routeRedirections}
                <Route path="*" element={<NotFoundPage />} />
            </Route>
        </Routes>
    </AppWrapper>
}
