import {add, Duration, endOfDay, min, startOfDay, sub} from "date-fns";
import {DateRange} from "react-day-picker";

export type NamedDateRange = {
    name: string;
    duration: Duration;
    startDate?: (date: Date) => Date;
    endDateExcluded?: boolean;
};

export type SelectedDateRange = NamedDateRange | DateRange;

export const isNamedDateRange = (selectedRange: SelectedDateRange): selectedRange is NamedDateRange => {
    return 'name' in selectedRange;
}

export const actualDateRange = (selectedRange: SelectedDateRange): DateRange => {
    if (isNamedDateRange(selectedRange)) {
        const today = new Date();
        const startDate = selectedRange.startDate
            ? selectedRange.startDate(today)
            : add(sub(today, selectedRange.duration), {days: 1});
        const endDate = min([today, add(startDate, selectedRange.duration)]);

        return {
            from: startOfDay(startDate),
            to: selectedRange.endDateExcluded ? sub(endOfDay(endDate), {days: 1}) : endOfDay(endDate),
        }
    }

    return {
        from: selectedRange.from ? startOfDay(selectedRange.from) : undefined,
        to: selectedRange.to ? endOfDay(selectedRange.to) : undefined,
    };
}
