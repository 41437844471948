import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export enum ReaderProcessResultActionType {
    API = 'api',
    REDIRECT = 'redirect'
}

export type ReaderProcessResultAction = {
    name: string;
    type: ReaderProcessResultActionType;
    method: HttpMethod.GET | HttpMethod.POST;
    url: string;
    params: Record<string, any>;
}

