export const compareAmounts = (amountA: string, amountB: string): -1|0|1 => {
    const [amountAWholePart, amountAFraction] = `${amountA}.`.split('.');
    const [amountBWholePart, amountBFraction] = `${amountB}.`.split('.');

    const fractionLength = Math.max(amountAFraction.length, amountBFraction.length);
    const amountAIntRepresentation = parseInt(`${amountAWholePart}${amountAFraction.padEnd(fractionLength, '0')}`, 10);
    const amountBIntRepresentation = parseInt(`${amountBWholePart}${amountBFraction.padEnd(fractionLength, '0')}`, 10);

    if (amountAIntRepresentation < amountBIntRepresentation) {
        return -1;
    } else if (amountAIntRepresentation > amountBIntRepresentation) {
        return 1;
    }

    return 0;
}
