import {FC, FormEvent, useCallback, useEffect} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

export type InsertLink = {
    url: string;
    text: string;
}

export type InsertLinkModalProps = {
    show: boolean;
    onInsert: (link: InsertLink) => void;
    onClose: () => void;
}

export const InsertLinkModal: FC<InsertLinkModalProps> = ({onClose, onInsert, show}) => {
    const {t} = useTranslation();

    const form = useForm<InsertLink>({
        defaultValues: {
            url: '',
            text: '',
        },
        mode: "onSubmit",
    });

    const onSubmit = useCallback((event: FormEvent) => {
        void form.handleSubmit(onInsert)(event);
        event.stopPropagation();
    }, [form, onInsert]);

    useEffect(() => {
        if (show) {
            form.setFocus('url');
        }
    }, [form, show]);

    const formId = 'insertLinkForm';

    return <BaseModal
        show={show}
        onHide={onClose}
        header={t('message:new.insertLink.title')}
        size="lg"
    >
        <form id={formId} onSubmit={onSubmit}>
            <FormGroup
                name="url"
                form={form}
                formId={formId}
                label={t('message:new.insertLink.url')}
                inputOptions={{autoComplete: 'off'}}
            />
            <FormGroup
                name="text"
                form={form}
                formId={formId}
                label={t('message:new.insertLink.text')}
                inputOptions={{autoComplete: 'off'}}
            />
            <SubmitFormGroup name="insert" value={t('message:new.insertLink.insert')} formId={formId} />
        </form>
    </BaseModal>
}
