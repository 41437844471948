import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {InvoiceListManager} from "@app/Commission/components/InvoiceGrid/InvoiceListManager";
import {PaginatedGridSorter} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/PaginatedGridSorter";
import {
    usePaginatedGridSorter
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/usePaginatedGridSorter";

export const INVOICE_GRID_SORTING_CACHE_KEY = 'ui-invoiceGrid-sorting';

export type InvoiceGridHeaderProps = {
    manager: InvoiceListManager;
    showDownloadColumn: boolean;
    showVariableSymbol: boolean;
}

export const InvoiceGridHeader: FC<InvoiceGridHeaderProps> = observer(({
    manager,
    showDownloadColumn,
    showVariableSymbol,
}) => {
    const {t} = useTranslation();

    const setStoredSorting = usePaginatedGridSorter(
        manager,
        INVOICE_GRID_SORTING_CACHE_KEY,
        {key: 'makeDate', desc: true},
    );

    if (!manager.hasItems) {
        return null;
    }

    return <thead>
        <tr>
            <th>
                <PaginatedGridSorter manager={manager} sortBy="makeDate" onSort={setStoredSorting}>
                    {t('invoice:property.makeDate')}
                </PaginatedGridSorter>
                <div className="invoice-grid__make-date-due-date">
                    {t('invoice:property.dueDate')}
                </div>
            </th>
            <th className="invoice-grid__type">{t('invoice:property.type')}</th>
            <th>
                <PaginatedGridSorter manager={manager} sortBy="invoiceNumber" onSort={setStoredSorting}>
                    {t('invoice:property.invoiceData.invoiceNumber')}
                </PaginatedGridSorter>
                {showVariableSymbol && <div className="invoice-grid__number-variable-symbol">
                    {t('invoice:property.variableSymbol')}
                </div>}
            </th>
            {showVariableSymbol && <th className="invoice-grid__variable-symbol">
                {t('invoice:property.variableSymbol')}
            </th>}
            <th>
                {t('invoice:property.total')}
                <div className="invoice-grid__total-payment-status">
                    {t('invoice:property.paymentStatus')}
                </div>
            </th>
            <th className="invoice-grid__payment-status">{t('invoice:property.paymentStatus')}</th>
            <th className="invoice-grid__due-date">{t('invoice:property.dueDate')}</th>
            {showDownloadColumn && <th />}
        </tr>
    </thead>
})
