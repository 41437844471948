import classNames from "classnames";
import {DetailedHTMLProps, forwardRef, InputHTMLAttributes, useCallback, useRef} from "react";
import {useTranslation} from "react-i18next";

import {Img} from "@common/components/Img/Img";

import './datePickerInput.scss';

export type DatePickerInputProps = DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> & {
    onClear?: () => void;
}

export const DatePickerInput = forwardRef<HTMLInputElement, DatePickerInputProps>(({onClear, ...inputProps}, ref) => {
    const {t} = useTranslation();

    const {className, value} = inputProps;
    const inputRef = useRef<HTMLInputElement|null>(null);

    const imgOpen = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, [inputRef]);

    return <div className="date-picker-input">
        <input
            {...inputProps}
            ref={(e) => {
                inputRef.current = e;
                if (ref) {
                    if (typeof ref === 'function') {
                        ref(e);
                    } else {
                        ref.current = e;
                    }
                }
            }}
            className={classNames('form-control', className)}
        />
        {onClear && value && <Img
            onClick={onClear}
            className="date-picker-input-icon date-picker-input-icon--delete"
            src="/images/icons/ico/ico-delete.svg"
            alt={t('dateRangePicker:clear')}
        />}
        <Img
            onClick={imgOpen}
            className="date-picker-input-icon"
            src="/images/icons/ico/ico-calendar-2.svg"
            alt={t('dateRangePicker:pickDate')}
        />
    </div>
});

DatePickerInput.displayName = 'DatePickerInput';
