import {Fragment} from "react";

import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {PasswordHandlingComponent} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {useReturnPacketModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/ReturnPacketModal/useReturnPacketModal";
import {Alert} from "@common/components/Alert/Alert";
import {Button} from "@common/components/Button/Button";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {packetWholeName} from "@common/utils/packetWholeName";

export const ReturnPacketModal: PasswordHandlingComponent = ({onError, onFinish, onVerified, password}) => {
    const {
        close,
        doDeliver,
        doPrintList,
        form: {getValues, handleSubmit, register, setValue},
        state: {modalOpened, packets, sender},
        t,
    } = useReturnPacketModal({onError, onFinish, onVerified, password});

    return <BaseModal
        show={modalOpened}
        onHide={close}
        size="lg"
        disableOutsideClose={true}
        header={t('viewReturnPacket:title') + (sender ? ` - ${sender.name}` : '')}
        footer={<Fragment>
            {packets.length !== 0 && <Button variant="secondary" onClick={handleSubmit(doPrintList)}>
                {t('viewReturnPacket:action.printList')}
            </Button>}
            <Button onClick={close} variant="danger">{t('viewReturnPacket:action.close')}</Button>
            {packets.length !== 0 && <Button variant="success" onClick={handleSubmit(doDeliver)}>
                {t('viewReturnPacket:action.receive')}
            </Button>}
        </Fragment>}
    >
        {sender && <h3>{t('viewReturnPacket:subtitle')}: <strong>{sender.name}</strong></h3>}
        {packets.length === 0 && <Alert type="info">
            {t('viewReturnPacket:message.noPacketsToReturn')}
        </Alert>}
        {packets.length !== 0 && <Fragment>
            <div>{t('viewReturnPacket:listInfo')}:</div>
            <GridTable>
                <thead>
                    <tr>
                        <th>{t('viewReturnPacket:action.selectReturnPackets')}</th>
                        <th>{t('packet:property.barcode')}</th>
                        <th>{t('packet:property.recipientName')}</th>
                        <th>{t('packet:property.shelf.label')}</th>
                    </tr>
                </thead>
                <tbody>
                    {packets.map((packet) => <tr key={packet.barcode} onClick={() => setValue(packet.barcode, !getValues(packet.barcode))}>
                        <td><input {...register(packet.barcode)} type="checkbox" defaultChecked={true} /></td>
                        <td><FormattedPacketBarcode barcode={packet.barcode} /></td>
                        <td>{packetWholeName(packet)}</td>
                        <td>{packet.shelf}</td>
                    </tr>)}
                </tbody>
            </GridTable>
        </Fragment>}
    </BaseModal>
}
