import {Packet} from "@app/Packet/model/Packet";

export enum ParcelPacketDifference {
    MISSING = 'missing',
    ADDITIONAL = 'additional',
}

export type ParcelPacket = Packet & {
    isReceived: boolean;
    differenceFlag: ParcelPacketDifference|null;
    badBranch: boolean;
}
