import {FC, useMemo} from "react";
import {Button} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {PacketGrid} from "@app/Packet/components/PacketGrid/PacketGrid";
import {PacketSearchForm} from "@app/Packet/components/PacketSearchForm/PacketSearchForm";
import {PacketListManager} from "@app/Packet/model/PacketListManager";
import {SearchInputData} from "@common/components/forms/SearchInput/SearchInput";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";

import "./packetSearchModal.scss";

export type PacketSearchModalProps = {
    branchId: string;
    show: boolean;
    onHide: () => void;
    onPacketFound: (barcode: string) => void;
}

export const PacketSearchModal: FC<PacketSearchModalProps> = ({branchId, onHide, onPacketFound, show}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const packetListManager = useMemo<PacketListManager>(() => new PacketListManager(
        branchId,
        appContext.api,
        false
    ), [appContext.api, branchId]);

    const packetSearchForm = useForm<SearchInputData>({mode: 'onSubmit'});

    if (!show) {
        return null;
    }

    return <BaseModal
        className="packet-search-modal"
        show={show}
        onHide={onHide}
        header={t('viewPacketSearch:title')}
        size="xl"
    >
        <PacketSearchForm
            filter={{}}
            focus={show}
            form={packetSearchForm}
            showLabel={true}
            updateFilter={packetListManager.setFilter}
        />
        <PacketGrid
            gridName="packetSearch"
            manager={packetListManager}
            extraHeadersRender={() => (<th>{t('viewPacketSearch:action.choose')}</th>)}
            extraColumnsRender={(packet) => (<td>
                <Button onClick={() => onPacketFound(packet.barcode)}>
                    {t('viewPacketSearch:action.choose')}
                </Button>
            </td>)}
        />
    </BaseModal>
}
