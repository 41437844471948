import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    PacketsBranchQualityDetailItem
} from "@app/Branch/model/BranchQuality/BranchQualityDetail/PacketsBranchQualityDetail";
import {
    ProcessPenaltyDetailGridHeader
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/components/ProcessPenaltyDetailGridHeader/ProcessPenaltyDetailGridHeader";
import {
    useProcessPenaltyDetailGridSorter
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/hooks/useProcessPenaltyDetailGridSorter";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {GridTable} from "@common/components/grids/GridTable/GridTable";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";

export type PacketsProcessPenaltyDetailGridProps = {
    items: PacketsBranchQualityDetailItem[];
}

export const PacketsProcessPenaltyDetailGrid: FC<PacketsProcessPenaltyDetailGridProps> = ({items}) => {
    const {t} = useTranslation();

    const sorter = useProcessPenaltyDetailGridSorter();
    items.sort(sorter.sort);

    return <GridTable>
        <ProcessPenaltyDetailGridHeader sorter={sorter}>
            <th>{t('branchQuality:processPenalty.detail.packets')}</th>
        </ProcessPenaltyDetailGridHeader>
        <tbody>
            {items.map((item) => (<tr key={item.date}>
                <td><DateFormatter date={item.date}/></td>
                <td><strong>{item.penaltyPoints}</strong></td>
                <td>
                    {dashWhenEmpty(item.packetBarcodes && item.packetBarcodes.map((barcode) => (
                        <FormattedPacketBarcode barcode={barcode} key={barcode} />
                    )))}
                </td>
            </tr>))}
        </tbody>
    </GridTable>
}
