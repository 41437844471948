import {CaptureConsole} from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import {Fragment, useEffect} from 'react';

import {useAppConfig} from "@common/hooks/useAppConfig";
import {FCWithChildren} from "@common/model/FCWithChildren";

export const SentryProvider: FCWithChildren = ({children}) => {
    const {environment, release, sentryDSN} = useAppConfig();

    useEffect(() => {
        if (sentryDSN) {
            Sentry.init({
                dsn: sentryDSN,
                environment,
                release,
                integrations: [
                    new CaptureConsole({
                        levels: ['error']
                    }),
                    new Sentry.Replay({
                        maskAllInputs: false,
                        maskAllText: false,
                        blockAllMedia: false,
                        networkDetailAllowUrls: [''],
                        networkRequestHeaders: ['Authorization'],
                    }),
                ],
                ignoreErrors: [
                    '[LaunchDarkly] network error',
                    '[LaunchDarkly] Error fetching flag settings: network error',
                ],
                replaysSessionSampleRate: environment === 'DEV' ? 1 : 0,
                replaysOnErrorSampleRate: environment === 'DEV' ? 1 : 0.1,
            });
        }
    }, [environment, release, sentryDSN]);

    if (sentryDSN) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return <Sentry.ErrorBoundary>{children}</Sentry.ErrorBoundary>
    }

    return <Fragment>{children}</Fragment>
}
