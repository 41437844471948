
import {BaseMessageFormData} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {sanitizedMessageContent} from "@app/Messaging/model/Message";
import {CreateBaseMessageRequest} from "@app/Messaging/model/MessagingRequestsAndResponses";
import {fileToBase64String} from "@common/utils/fileToBase64String";

export const createRequestFromData = async (formData: BaseMessageFormData): Promise<CreateBaseMessageRequest> => {
    if (formData.attachment) {
        const file = formData.attachment.item(0);
        if (file) {
            const attachmentContent = await fileToBase64String(file);
            return {
                subject: formData.subject,
                content: sanitizedMessageContent(formData.content),
                attachmentFilename: file.name,
                attachmentMimeType: file.type,
                attachmentContent,
            }
        }
    }

    return  {
        subject: formData.subject,
        content: formData.content,
    }
}
