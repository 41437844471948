import {FC, Fragment} from "react";
import {UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {CashPickupModalFormData} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/CashPickupModal/CashPickupModalTypes";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";

export type CashPickupModalChoiceProps = {
    type: 'yesterday'|'today'|'partToday';
    value: number;
    currency: string;
    form: UseFormReturn<CashPickupModalFormData>;
}

export const CashPickupModalChoice: FC<CashPickupModalChoiceProps> = ({currency, form, type, value}) => {
    const {t} = useTranslation();

    if (value <= 0) {
        return null;
    }

    const formId = `cashPickupModal-${type}`;

    return <FormGroup
        name="amount"
        type="radio"
        inputId={formId}
        inputOptions={{value}}
        label={<Fragment>
            <strong><MoneyFormatter amount={value} currencyCode={currency} /></strong>{' - '}
            {t(`viewCashPickup:choice.${type}`)}
        </Fragment>}
        form={form}
    />
}
