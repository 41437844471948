import {useCallback} from "react";

import {isNamedDateRange, SelectedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";

// TODO: remove in release >70.2.0
export const useFixFiltersDuration = () => {
    return useCallback((range: SelectedDateRange|undefined): SelectedDateRange|undefined => {
        if (range && isNamedDateRange(range) && range.duration) {
            let fixed = false;
            const newDuration: Duration = {...range.duration};
            if (range.duration.days && range.duration.days < 0) {
                newDuration.days = -1 * range.duration.days;
                fixed = true;
            }
            if (range.duration.weeks && range.duration.weeks < 0) {
                newDuration.weeks = -1 * range.duration.weeks;
                fixed = true;
            }
            if (range.duration.months && range.duration.months < 0) {
                newDuration.months = -1 * range.duration.months;
                fixed = true;
            }
            if (range.duration.years && range.duration.years < 0) {
                newDuration.years = -1 * range.duration.years;
                fixed = true;
            }

            if (fixed) {
                return {
                    ...range,
                    duration: newDuration,
                }
            }
        }

        return undefined;
    }, []);
}
