import {FC} from "react";

import {Parcel} from "@app/Parcel/model/Parcel";
import {ParcelStatusBadge} from "@common/components/Badges/ParcelStatusBadge/ParcelStatusBadge";

import './parcelStatusAndCount.scss';

export type ParcelStatusAndCountProps = {
    parcel: Parcel;
}

export const ParcelStatusAndCount: FC<ParcelStatusAndCountProps> = ({parcel}) => {
    return <div className="parcel-status-and-count">
        <strong className="parcel-status-and-count__count">{parcel.packetCount}</strong>
        {parcel.status && <ParcelStatusBadge status={parcel.status} />}
    </div>
}
