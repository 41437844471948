import classNames from "classnames";
import {ReactElement} from "react";

import {GridTableTotalCount} from "@common/components/grids/GridTable/GridTableTotalCount/GridTableTotalCount";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './gridTable.scss';

export type GridTableProps = {
    aboveGridContent?: ReactElement;
    belowGridContent?: ReactElement;
    className?: string;
    totalCount?: number;
    totalCountContent?: ReactElement;
    xid?: string;
}

export const GridTable: FCWithChildren<GridTableProps> = ({
    aboveGridContent,
    belowGridContent,
    children,
    className,
    totalCount,
    totalCountContent,
    xid,
}) => {
    return <div className={classNames('grid-table', className)} data-xid={xid}>
        <div className="grid-table__above">
            {totalCount !== undefined && <GridTableTotalCount count={totalCount}>
                {totalCountContent}
            </GridTableTotalCount>}
            {totalCount === undefined && totalCountContent}
            {aboveGridContent}
        </div>
        <table>
            {children}
        </table>
        <div className="grid-table__below">
            {totalCount !== undefined && <GridTableTotalCount count={totalCount}>
                {totalCountContent}
            </GridTableTotalCount>}
            {totalCount === undefined && totalCountContent}
            {belowGridContent}
        </div>
    </div>
}
