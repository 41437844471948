import {Api} from "@app/AppContext/classes/Api/Api";
import {
    abortTransactionEndpoint,
    cancellationStrategyEndpoint,
    createTransactionEndpoint,
    printCardReceiptEndpoint,
    refundTransactionEndpoint,
    revertTransactionEndpoint,
    signTransactionEndpoint,
    transactionDetailEndpoint,
    transactionIdEndpoint
} from "@app/CardPayment/api/cardTerminalEndpoints";
import {
    CancellationStrategyResponse,
    CardTerminalTransactionDetailResponse,
    CardTerminalTransactionIdResponse, CreateTransactionRequest, PrintCardReceiptRequest,
    PrintCardReceiptResponse, ReverseOrRefundTransactionRequest
} from "@app/CardPayment/model/CardTerminalRequestsAndResponses";
import {GeneralSuccessResponseContent} from "@common/model/responses/GeneralSuccessResponseContent";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const abortTransaction = async (transactionId: string, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call(endpointWithRouteParams(abortTransactionEndpoint, {transactionId}));
}

export const cancellationStrategy = async (transactionId: string, api: Api): Promise<CancellationStrategyResponse> => {
    return api.call(endpointWithRouteParams(cancellationStrategyEndpoint, {transactionId}));
}

export const createTransaction = async (request: CreateTransactionRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: createTransactionEndpoint,
        payload: request,
    });
}

export const getTransactionId = async (api: Api): Promise<CardTerminalTransactionIdResponse> => {
    return api.call(transactionIdEndpoint);
}

export const printCardReceipt = async (request: PrintCardReceiptRequest, api: Api): Promise<PrintCardReceiptResponse> => {
    const {transactionId, ...queryParams} = request;
    return api.call({
        endpoint: endpointWithRouteParams(printCardReceiptEndpoint, {transactionId}),
        queryParams: createQueryParamsFromRequest(queryParams),
    });
}

export const refundTransaction = async (transactionId: string, request: ReverseOrRefundTransactionRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: endpointWithRouteParams(refundTransactionEndpoint, {transactionId}),
        payload: request,
    });
}

export const revertTransaction = async (transactionId: string, request: ReverseOrRefundTransactionRequest, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: endpointWithRouteParams(revertTransactionEndpoint, {transactionId}),
        payload: request,
    });
}

export const signTransaction = async (transactionId: string, api: Api): Promise<GeneralSuccessResponseContent> => {
    return api.call({
        endpoint: endpointWithRouteParams(signTransactionEndpoint, {transactionId}),
        payload: {isSignatureMatching: true},
    });
}

export const transactionDetail = async (transactionId: string, api: Api): Promise<CardTerminalTransactionDetailResponse> => {
    return api.call(endpointWithRouteParams(transactionDetailEndpoint, {transactionId}));
}
