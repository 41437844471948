import {CardPaymentTransaction} from "@app/CardPayment/model/CardPaymentTransaction";

export enum CardPaymentStatus {
    READY,
    PAYING,
    CANCELLING,
}

export type CardPaymentReadyState = {
    status: CardPaymentStatus.READY;
}

export type CardPaymentTransactionState = {
    status: Exclude<CardPaymentStatus, CardPaymentStatus.READY>;
    transaction: CardPaymentTransaction;
}

export type CardPaymentState = CardPaymentReadyState | CardPaymentTransactionState;

export const getTransaction = (cardPaymentState: CardPaymentState): CardPaymentTransaction|null => {
    if (cardPaymentState.status === CardPaymentStatus.READY) {
        return null;
    }

    return cardPaymentState.transaction;
}
