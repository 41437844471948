import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Branch} from "@app/Branch/model/Branch";
import {FormattedPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/FormattedPacketBarcode";
import {PacketDetailed} from "@app/Packet/model/PacketDetailed";
import {Sender} from "@app/Packet/model/Sender";
import {
    PacketDetailInfoCod
} from "@app/Packet/pages/PacketDetailPage/PacketDetailInfo/PacketDetailInfoCod/PacketDetailInfoCod";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {DetailInfoBox, DetailInfoItem} from "@common/components/DetailInfoBox/DetailInfoBox";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";
import {packetWholeName} from "@common/utils/packetWholeName";

export type PacketDetailInfoProps = {
    packet: PacketDetailed;
    branch?: Branch;
    sender?: Sender;
}

export const PacketDetailInfo: FC<PacketDetailInfoProps> = ({branch, packet, sender}) => {
    const {t} = useTranslation();

    const info: DetailInfoItem[] = [
        {
            label: t('packet:property.barcode'),
            content: <FormattedPacketBarcode barcode={packet.barcode} withoutLink={true} />,
        },
        {
            label: t('packet:property.sender'),
            content: dashWhenEmpty(sender && sender.name),
        },
        {
            label: t('packet:property.recipient'),
            content: packetWholeName(packet),
        },
        {
            label: t('packet:property.hasCashOnDelivery.title'),
            content: <PacketDetailInfoCod packet={packet} sender={sender} />,
        }
];

    if (branch) {
        info.push({
            label: t('packet:property.address'),
            content: branch.name,
        });
    }

    if (packet.storedUntil) {
        info.push({
            label: t('packet:property.storedUntil'),
            content: <DateFormatter date={packet.storedUntil} />,
        });
    }

    return <DetailInfoBox info={info} />
}
