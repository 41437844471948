import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Logo} from "@common/layouts/common/Logo/Logo";

export const UnsupportedBrowser: FC = () => {
    const {t} = useTranslation();

    return <div className="centered-layout">
        <div className="centered-layout__content">
            <div>
                <div className="centered-layout__header">
                    <Logo />
                </div>
                <div className="centered-layout__body">
                    <h2>{t('viewUnsupportedBrowser:title')}</h2>
                    <p>{t('viewUnsupportedBrowser:message')}</p>
                </div>
            </div>
        </div>
    </div>
}
