import {FC} from "react";

import {PacketFlags} from "@app/Packet/components/PacketDelivery/components/PacketFlags/PacketFlags";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {DateFormatter} from "@common/components/DateFormatter/DateFormatter";
import {dashWhenEmpty} from "@common/utils/dashWhenEmpty";
import {
    PacketsListType
} from "@packetModal/components/PacketDeliveryModal/DeliveredPackets/PacketsList/PacketsListType";

export type PacketsListShelfCellProps = {
    listType: PacketsListType;
    packet: DeliveryPacket;
}

export const PacketsListShelfCell: FC<PacketsListShelfCellProps> = ({listType, packet}) => {
    return <div>
        {listType === 'delivered' && <div>{dashWhenEmpty(packet.info.shelf)}</div>}
        {listType === 'notDelivered' && <div>
            {dashWhenEmpty(packet.info.storedUntil && <DateFormatter date={packet.info.storedUntil} />)}
        </div>}
        <PacketFlags packet={packet} />
    </div>

}
