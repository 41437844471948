import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {FC,  useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {billingInformation, registryUpdateBillingInformation} from "@app/Commission/api/commissionApi";
import {
    AccountNumberForm
} from "@app/Commission/components/BillingInformation/AccountNumberForm/AccountNumberForm";
import {
    BillingInformationBox
} from "@app/Commission/components/BillingInformation/BillingInformationBox/BillingInformationBox";
import {CommissionInvoiceBillingInformation} from "@app/Commission/model/CommissionInvoiceBillingInformation";
import {NewMessageButton} from "@app/Messaging/components/NewMessageButton/NewMessageButton";
import {NewMessageRoute} from "@app/Messaging/MessagingRoutes";
import {MessageTopic} from "@app/Messaging/model/MessageTopic";
import {Button} from "@common/components/Button/Button";
import {Img} from "@common/components/Img/Img";
import {Loading} from "@common/components/Loading/Loading";
import {appToast} from "@common/components/Toast/toastOpener";
import {TranslationWithHtmlParam} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";

import './billingInformation.scss';

export type BillingInformationProps = {
    onBillingInformationLoaded?: (billingInformation: CommissionInvoiceBillingInformation) => void;
}

export const BillingInformation: FC<BillingInformationProps> = observer(({onBillingInformationLoaded}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const [billingInfo, setBillingInfo] = useState<CommissionInvoiceBillingInformation|null|false>(null);

    useEffect(() => {
        if (billingInfo === null && appContext.user.branchId) {
            billingInformation({branchId: appContext.user.branchId}, appContext.api)
                .then((response) => {
                    setBillingInfo(response.items);
                    if (onBillingInformationLoaded) {
                        onBillingInformationLoaded(response.items);
                    }
                })
                .catch(() => {
                    setBillingInfo(false);
                });
        }

    }, [appContext.api, appContext.user.branchId, billingInfo, onBillingInformationLoaded]);

    const showAppErrorToast = useShowErrorToast(appToast);

    const updateFromRegistry = useCallback(() => {
        if (!appContext.user.branchId) {
            return;
        }

        registryUpdateBillingInformation({branchId: appContext.user.branchId}, appContext.api)
            .then(() => {
                setBillingInfo(null);
            })
            .catch((error: Error) => {
                showAppErrorToast(error)
            });
    }, [appContext.api, appContext.user.branchId, showAppErrorToast]);

    if (!appContext.user.branchId) {
        return null;
    }

    return <Loading active={billingInfo === null} className={classNames('billing-information', billingInfo === false && 'billing-information_not-set')}>
        {billingInfo && <div className="billing-information__info">
            {(billingInfo.accountNumber === null || billingInfo.accountNumber === '') && <AccountNumberForm
                onSuccess={() => setBillingInfo(null)}
            />}
            <BillingInformationBox billingInfo={billingInfo} />
        </div>}
        <div className="billing-information__description">
            {billingInfo !== null && <Img src={`/images/icons/ico/${billingInfo ? 'ico-i' : 'ico-exclamation-red'}.svg`} alt="info" />}
            <div>
                {billingInfo && <p>
                    <TranslationWithHtmlParam
                        paramName="messageLink"
                        translationKey="viewCommission:billingInformation.note.checkBillingInfo"
                        asSpan={true}
                        t={t}
                    >
                        {appContext.router.href(NewMessageRoute)}
                    </TranslationWithHtmlParam>
                </p>}
                {billingInfo === false && <p className="billing-information__not-set">
                    {t('commission:error.billingInfoNotSet')}
                </p>}
                {billingInfo !== null && <p className="billing-information__action">
                    <Can I={Action.REGISTRY_UPDATE} a={Subject.BILLING_INFORMATION}>
                        <Button onClick={updateFromRegistry} variant="dark">
                            {t('viewCommission:billingInformation.registryUpdateBillingInfo')}
                        </Button>
                    </Can>
                    <NewMessageButton variant="dark" topic={MessageTopic.COMMISSIONS_OR_BILLING_INFORMATION}>
                        {t('viewCommission:billingInformation.editData')}
                    </NewMessageButton>
                </p>}
            </div>
        </div>
    </Loading>
})
