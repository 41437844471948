import {observer} from "mobx-react-lite";
import {Fragment} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {CashPickupModalChoice} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/CashPickupModal/CashPickupModalChoice/CashPickupModalChoice";
import {useCashPickupModal} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/CashPickupModal/useCashPickupModal";
import {PasswordHandlingComponent} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {ReprintButton} from "@common/components/ReprintButton/ReprintButton";

import './cashPickupModal.scss';

export const CashPickupModal: PasswordHandlingComponent = observer(({onError, onFinish, onVerified, password}) => {
    const {t} = useTranslation();

    const {close, confirm, form, modalState, passwordConfirmed} = useCashPickupModal(password, onFinish, onError, onVerified, t);

    if (!passwordConfirmed) {
        return null;
    }

    if (modalState && modalState.withChoice) {
        if (modalState.partToday <= modalState.yesterday && modalState.today <= modalState.yesterday) {
            form.setValue("amount", modalState.yesterday);
        }
        if (!modalState.partToday && !modalState.yesterday) {
            form.setValue("amount", modalState.today);
        }
    }

    return <BaseModal
        show={passwordConfirmed}
        onHide={close}
        header={t('viewCashPickup:title')}
        loading={!modalState || modalState.submitting}
        closeButton={modalState && modalState.submitted || false}
        size="lg"
        className="cash-pickup-modal"
        footer={<Fragment>
            {modalState && modalState.printResult && <ReprintButton printResult={modalState.printResult} toastInModal={true}>
                    {t('viewCashPickup:action.reprint')}
            </ReprintButton>}
            {modalState && modalState.submitted && <Button onClick={close}>
                    {t('viewCashPickup:action.cancel')}
            </Button>}
            {modalState && !modalState.submitted && <Button onClick={form.handleSubmit(confirm)}>
                {t('viewCashPickup:action.confirm')}
            </Button>}
        </Fragment>}
    >
        {modalState && !modalState.submitted && <Fragment>
            <strong>{t(`viewCashPickup:subtitle.${modalState.withChoice ? 'with' : 'without'}Choice`)}</strong>
            <div className="cash-pickup-modal-main">
                {!modalState.withChoice && <strong>
                    <MoneyFormatter amount={modalState.yesterday} currencyCode={modalState.currency} />
                </strong>}
                {modalState.withChoice && <form>
                    <CashPickupModalChoice
                        type="yesterday"
                        value={modalState.yesterday}
                        currency={modalState.currency}
                        form={form}
                    />
                    {modalState.partToday > modalState.yesterday && <CashPickupModalChoice
                        type="partToday"
                        value={modalState.partToday}
                        currency={modalState.currency}
                        form={form}
                    />}
                    {modalState.today > modalState.yesterday && <CashPickupModalChoice
                        type="today"
                        value={modalState.today}
                        currency={modalState.currency}
                        form={form}
                    />}
                </form>}
            </div>
        </Fragment>}
    </BaseModal>
})
