import {FC, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

import {ManualPostDetail} from "@app/Manuals/components/ManualPostDetail/ManualPostDetail";
import {ManualsIndexRoute, PostDetailParams} from "@app/Manuals/ManualsRoutes";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";
import {useAppNavigate} from "@common/hooks/useAppNavigate";

export const ManualsPostDetailPage: FC = () => {
    const {t} = useTranslation();

    const navigate = useAppNavigate();
    const {id} = useParams<PostDetailParams>();

    useEffect(() => {
        if (!id) {
            navigate(ManualsIndexRoute);
        }
    }, [id, navigate]);

    if (!id) {
        return null;
    }

    return <PageContent className="manuals-post-detail">
        <ManualPostDetail
            postId={parseInt(id, 10)}
            backLink={<ReturnLink label={t('viewManual:action.goBackToManuals')} to={ManualsIndexRoute} />}
        />
    </PageContent>
}
