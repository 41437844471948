import {observer} from "mobx-react-lite";
import {FC} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";

export type UndeliveryButtonProps = {
    disabled: boolean;
    packet: DeliveryPacket;
}

export const UndeliveryButton: FC<UndeliveryButtonProps> = observer(({disabled, packet}) => {
    const {t} = useTranslation();

    if (!packet.isDelivered) {
        return null;
    }

    return <Button
        onClick={() => packet.requireUndelivery()}
        disabled={disabled}
        variant="warning"
        size="sm"
        data-xid="undelivery-button"
    >
        {t('packet:action.cancelShort')}
    </Button>
})
