import classNames from "classnames";
import {FC, Fragment, useCallback, useMemo, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {updateBranchAttributes} from "@app/Branch/api/branchApi";
import {WheelchairAccessibility} from "@app/Branch/model/Attribute/WheelchairAccessibility";
import {BranchAttributes} from "@app/Branch/model/BranchAttributes";
import {Alert} from "@common/components/Alert/Alert";
import {Select, SelectOption} from "@common/components/forms/Select/Select";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {Loading} from "@common/components/Loading/Loading";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {enumOptions} from "@common/utils/enumOptions";

export type WheelchairAccessibilitySettingsProps = {
    wheelchairAccessibility: WheelchairAccessibility;
    updatePending?: true;
    onUpdate?: () => void;
}

type WheelchairAccessibilityFormData = Pick<BranchAttributes, 'wheelchairAccessibility'>;

export const WheelchairAccessibilitySettings: FC<WheelchairAccessibilitySettingsProps> = ({
    onUpdate,
    updatePending,
    wheelchairAccessibility
}) => {
    const {t} = useTranslation();

    const {api} = useAppContext();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const form = useForm<WheelchairAccessibilityFormData>({
        defaultValues: {wheelchairAccessibility},
        mode: 'onSubmit',
    });

    const selectOptions = useMemo<SelectOption[]>(() => enumOptions(WheelchairAccessibility).map((option) => ({
        value: option,
        label: t(`viewBranchSettings:attributesForm.wheelchairAccessible.options.${option}`),
    })), [t]);

    const showAppErrorToast = useShowErrorToast(appToast);

    const onSubmit = useCallback((data: WheelchairAccessibilityFormData) => {
        setSubmitting(true);
        updateBranchAttributes(data, api)
            .then(() => {
                appToast.success(t('viewBranchSettings:attributesForm.message.success'));
                if (onUpdate) {
                    onUpdate();
                }
            })
            .catch((error: Error) => {
                showAppErrorToast(error);
            })
            .finally(() => {
                setSubmitting(false);
            });
    }, [api, onUpdate, showAppErrorToast, t]);

    const formId = 'wheelchairAccessibilitySettingsForm';

    return <Loading active={submitting}
                    className={classNames('branch-settings-page__settings', updatePending && 'branch-settings-page__settings_update-pending')}
                    title={updatePending ? t('viewBranchSettings:updatePending') : undefined}
    >
        {updatePending !== undefined && <Alert type="warning">
            {t('viewBranchSettings:updatePending')}
        </Alert>}
        <form onSubmit={form.handleSubmit(onSubmit)}>
            <Select
                form={form}
                formId={formId}
                options={selectOptions}
                name="wheelchairAccessibility"
                label={<Fragment>
                    <h5>{t('viewBranchSettings:attributesForm.wheelchairAccessible.label')}</h5>
                    <p>{t('viewBranchSettings:attributesForm.wheelchairAccessible.description')}</p>
                </Fragment>}
                selectOptions={{
                    placeholder: t('viewBranchSettings:attributesForm.wheelchairAccessible.placeholder'),
                    disabled: updatePending,
                }}
            />
            {!updatePending && <SubmitFormGroup
                name="save"
                value={t('viewBranchSettings:attributesForm.save')}
                formId={formId}
                inputOptions={{
                    disabled: submitting,
                }}
            />}
        </form>
    </Loading>

}
