import {ReaderVariables} from "@app/Devices/model/ReaderVariables";

export const getVariable = (variables: ReaderVariables | null, variableName: string): boolean | string | string[] | number | undefined => {
    if (variables === null || Array.isArray(variables)) {
        return undefined;
    }

    return variables[variableName];
}

export const getStringVariable = (variables: ReaderVariables | null, variableName: string): string | undefined => {
    const variable = getVariable(variables, variableName);

    if (typeof variable === 'string') {
        return variable;
    }

    return undefined;
}
