import {PaginationApiMethod} from "@app/common/model/PaginationApiMethod";
import {PaginatedDataManager} from "@common/components/grids/PaginatedDataManager";
import {RequestWithSort, SortPropertyOfRequest} from "@common/model/requests/SortRequestProperty";
import {ResponseWithEmbedded} from "@common/model/responses/ResponseWithEmbedded";

export class SortablePaginatedDataManager<
    ItemType,
    RequestType extends RequestWithSort,
    ExtendedResponseType = ResponseWithEmbedded
> extends PaginatedDataManager<ItemType, RequestType, ExtendedResponseType> {

    public sorting: SortPropertyOfRequest<RequestType>|null|undefined;

    protected constructor(
        apiMethod: PaginationApiMethod<ItemType, RequestType, ExtendedResponseType>,
        request: RequestType,
        pageSize?: number,
        deepObservableItems?: boolean,
    ) {
        super(apiMethod, request, pageSize, deepObservableItems);
    }

    public get hasSorting(): boolean {
        return this.sorting !== undefined;
    }

    public sort = (newSort: SortPropertyOfRequest<RequestType>|null): void => {
        this.sorting = newSort;
        void this.reload();
    }

    protected assembleRequest(): RequestType|undefined {
        if (this.sorting === undefined) {
            return undefined;
        }

        const superRequest = super.assembleRequest();
        if (!superRequest) {
            return undefined;
        }

        if (this.sorting === null) {
            return superRequest;
        }

        return {
            ...superRequest,
            sort: [this.sorting],
        };
    }
}
