import {observer} from "mobx-react-lite";
import {FC, Fragment, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {Message} from "@app/Messaging/components/Message/Message";
import {ReceivedMessagesListManager} from "@app/Messaging/managers/ReceivedMessagesListManager";
import {isIncoming, isOutgoing, isRead, Message as MessageModel} from "@app/Messaging/model/Message";
import {PaginatedGrid} from "@common/components/grids/PaginatedGrid/PaginatedGrid";

export const ReceivedMessagesList: FC = observer(() => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const messagesManager = useMemo<ReceivedMessagesListManager>(
        () => new ReceivedMessagesListManager(appContext.api),
        [appContext.api]
    );

    const messages = messagesManager.items;

    let currentGroupUnread = true;
    const incomingMessagesGroupTitle = (message: MessageModel, index: number): string|null => {
        if (isRead(message) && currentGroupUnread) {
            currentGroupUnread = false;
            return t('message:tabs.content.received.message.old');
        }
        if (index === 0) {
            return t('message:tabs.content.received.message.new');
        }

        return null;
    }

    return <PaginatedGrid manager={messagesManager} withoutTable={true}>
        {messages.map((message, index) => (<Fragment key={message.id}>
            <div className="messages-list--group-title text-muted small">
                {incomingMessagesGroupTitle(message, index)}
            </div>
            {isIncoming(message) && <Can I={Action.READ} a={Subject.INCOMING_MESSAGE}>
                <Message message={message} messagesManager={messagesManager} branches={messagesManager.branches} type="received" />
            </Can>}
            {isOutgoing(message) && <Can I={Action.READ} a={Subject.OUTGOING_MESSAGE}>
                <Message message={message} messagesManager={messagesManager} branches={messagesManager.branches} type="received" />
            </Can>}
        </Fragment>))}
    </PaginatedGrid>
})
