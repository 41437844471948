import {useCallback, useRef} from "react";
import {useForm, UseFormReturn} from "react-hook-form";
import {VALIDATION_MODE} from "react-hook-form/dist/constants";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {deliveryDetail} from "@app/Packet/api/packetApi";
import {searchType} from "@app/Packet/api/searchTypeApi";
import {formatPacketBarcode} from "@app/Packet/components/FormattedPacketBarcode/formatPacketBarcode";
import {Deliver} from "@app/Packet/components/PacketDelivery/hooks/useDeliver";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {createDeliveryPacket} from "@app/Packet/components/PacketDelivery/utils/createDeliveryPacket";
import {PacketDeliveryType} from "@app/Packet/model/PacketDeliveryType";
import {SearchTypeType} from "@app/Packet/model/SearchType";
import {MIN_PASSWORD_LENGTH} from "@app/Packet/pages/DashboardPage/SearchForm/searchFormConstants";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {HttpStatusCode} from "@common/model/HttpStatusCode";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {extractEmbedded} from "@common/utils/extractEmbedded";

export type PasswordVerificationFormData = {
    password: string;
}

export const usePasswordVerificationForm = (
    deliver: Deliver,
    packet?: DeliveryPacket,
    onFinish?: (success: boolean, form: UseFormReturn<PasswordVerificationFormData>) => void,
    formMode?: keyof typeof VALIDATION_MODE,
) => {
    const {t} = useTranslation();
    const {api} = useAppContext();

    const abortController = useRef<AbortController|null>(null);
    const showModalErrorToast = useShowErrorToast(modalToast, 'packet:error', 'packet:error.deliveryError');

    const form = useForm<PasswordVerificationFormData>({
        mode: formMode || 'onSubmit',
        defaultValues: {password: ''},
    });

    const callFinish = useCallback((success: boolean) => {
        abortController.current = null;
        if (onFinish) {
            onFinish(success, form);
        }
    }, [form, onFinish]);

    const onSubmit = useCallback(async ({password}: PasswordVerificationFormData) => {
        if (abortController.current) {
            abortController.current.abort();
        }

        if (password.length < MIN_PASSWORD_LENGTH) {
            callFinish(false);
            return;
        }

        try {
            abortController.current = new AbortController();
            const querySearchType = await searchType({subject: password}, abortController.current, api)

            if (querySearchType !== SearchTypeType.DELIVERY_PASSWORD) {
                modalToast.error(t('packet:error.notDeliveryPassword'));
                callFinish(false);
                return;
            }

            abortController.current = new AbortController();
            const response = await deliveryDetail({searchStr: password, embed: [Embeddable.SENDER]}, abortController.current, api);

            if (response.items.length === 0) {
                modalToast.error(t('packet:error.notFoundPassword', {password}));
                callFinish(false);
                return;
            }

            const foundPacket = response.items[0];
            if (packet && packet.info.barcode !== foundPacket.barcode) {
                modalToast.error(t('viewPacketDelivery:packetDelivery.error.passwordValidForDifferentPacket', {
                    barcode: formatPacketBarcode(foundPacket.barcode),
                }));
                callFinish(false);
                return;
            }

            const deliveryPacket = createDeliveryPacket(
                response.items[0],
                extractEmbedded(response, Embeddable.SENDER),
            );
            await deliver(deliveryPacket, {type: PacketDeliveryType.PASSWORD, password});
            callFinish(true);
        } catch (error: unknown) {
            if (error instanceof ApiError && error.code === HttpStatusCode.NOT_FOUND) {
                modalToast.error(t('packet:error.notFoundPassword', {password}));
            } else if (error instanceof Error && error.name !== 'AbortError') {
                showModalErrorToast(error);
            }

            callFinish(false);
        }
    }, [api, callFinish, deliver, packet, showModalErrorToast, t]);

    return {
        form,
        onSubmit,
    }
}
