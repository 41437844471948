import {Api} from "@app/AppContext/classes/Api/Api";
import {deliverReturnPacketEndpoint, returnPacketListEndpoint} from "@app/Packet/api/returnPacketEndpoints";
import {ReturnPacketListResponse} from "@app/Packet/model/PacketListRequestsAndResponses";
import {Embeddable, RequestWithEmbedded} from "@common/model/requests/RequestWithEmbedded";
import {ResponseWithPrintables} from "@common/model/responses/ResponseWithPrintables";
import {createQueryParamsFromRequest} from "@common/utils/api/createQueryParamsFromRequest";
import {endpointWithRouteParams} from "@common/utils/api/endpointWithRouteParams";

export const returnPacketList = async (request: ReturnPacketListRequest, api: Api): Promise<ReturnPacketListResponse> => {
    const {password, ...returnPacketListRequest} = request;
    return api.call({
        endpoint: endpointWithRouteParams(returnPacketListEndpoint, {password}),
        queryParams: createQueryParamsFromRequest(returnPacketListRequest),
    });
}

export const deliverReturnPacket = async (request: ReturnPacketDeliverRequest, api: Api): Promise<ResponseWithPrintables> => {
    return api.call({
        endpoint: deliverReturnPacketEndpoint,
        payload: request,
    });
}

export type ReturnPacketListRequest = RequestWithEmbedded<Embeddable.BRANCH | Embeddable.USER | Embeddable.SENDER> & {
    password: string;
}

export type ReturnPacketDeliverRequest = {
    password: string;
    barcodes: string[];
}
