import {FC, useCallback} from "react";
import {Button, Col, Row} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {printShelfBarcodes, printShelfBarcodesPDF} from "@app/Print/api/printApi";
import {PrintShelfBarcodesRequest} from "@app/Print/model/PrintRequestsAndResponses";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {Img} from "@common/components/Img/Img";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {downloadEncodedPdf} from "@common/utils/api/downloadEncodedPdf";

export type ShelfBarcodesPrintModalProps = {
    show: boolean;
    onHide: () => void;
    supportBranchId: number|null
}

const BARCODE_MAXLENGTH = 12;

export const ShelfBarcodesPrintModal: FC<ShelfBarcodesPrintModalProps> = ({onHide, show, supportBranchId}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const form = useForm<PrintShelfBarcodesRequest>({
        defaultValues: {prefix: 'A', startNumber: 1, endNumber: 5}
    });
    const showModalErrorToast = useShowErrorToast(modalToast, 'global:print.error', 'global:print.error.general');

    const doPrint = useCallback((data: PrintShelfBarcodesRequest) => {
        printShelfBarcodes(data, appContext.api)
            .then(() => {
                modalToast.success(t('global:print.success.general'));
            })
            .catch((error: Error) => {
                showModalErrorToast(error);
            });
    }, [appContext.api, showModalErrorToast, t]);

    const doPdfPrint = useCallback((data: PrintShelfBarcodesRequest) => {
        printShelfBarcodesPDF(data, appContext.api)
            .then((response) => {
                downloadEncodedPdf(response, t('viewShelfBarcodesPrint:pdfFileName'));
            })
            .catch((error: Error) => {
                showModalErrorToast(error);
            });
    }, [appContext.api, showModalErrorToast, t]);

    if (!show) {
        return null;
    }

    const formId = 'shelfBarcodesPrintModalForm';
    const maxLength = BARCODE_MAXLENGTH - (supportBranchId !== null ? supportBranchId.toString().length : 0) - 2;

    return <BaseModal
        show={show}
        onHide={onHide}
        size="lg"
        header={t('viewShelfBarcodesPrint:title')}
        footer={<Row>
            <Col xl={'auto'} sm={6} className="mt-xl-0 mt-2">
                <Button variant="danger" onClick={onHide} className="bg-transparent text-danger">
                    <Img src="/images/icons/ico/ico-delete-red.svg" alt={t('viewShelfBarcodesPrint:action.cancel')} />{' '}
                    {t('viewShelfBarcodesPrint:action.cancel')}
                </Button>
            </Col>
            <Col xl={'auto'} sm={6} className="mt-xl-0 mt-2">
                <Button variant="success" onClick={form.handleSubmit(doPdfPrint)}>
                    {t('viewShelfBarcodesPrint:action.printPdf')}
                </Button>
            </Col>
            <Col xl={'auto'} sm={6} className="mt-xl-0 mt-2">
                <Button variant="success" onClick={form.handleSubmit(doPrint)}>
                    {t('viewShelfBarcodesPrint:action.print')}
                </Button>
            </Col>
        </Row>}
    >
        <FormGroup
            name="prefix"
            form={form}
            label={t('viewShelfBarcodesPrint:input.prefixHelper', {maxLength})}
            formId={formId}
        />
        <Row>
            <Col xs={6}>
                <FormGroup
                    name="startNumber"
                    form={form}
                    type="number"
                    label={t('viewShelfBarcodesPrint:input.fromHelper')}
                    registerOptions={{
                        required: t<string>('viewShelfBarcodesPrint:errors.from.required')
                    }}
                    formId={formId}
                />
            </Col>
            <Col xs={6}>
                <FormGroup
                    name="endNumber"
                    form={form}
                    type="number"
                    label={t('viewShelfBarcodesPrint:input.toHelper')}
                    registerOptions={{
                        required: t<string>('viewShelfBarcodesPrint:errors.to.required')
                    }}
                    formId={formId}
                />
            </Col>
        </Row>
    </BaseModal>
}
