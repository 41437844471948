import {FC} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppConfig} from "@common/hooks/useAppConfig";

export const RemoteHelpLink: FC = () => {
    const {i18n, t} = useTranslation();
    const appConfig = useAppConfig();

    const remoteHelpUrl = appConfig.remoteHelpUrlTemplate.replace('%locale%', i18n.language.substr(0, 2));

    return <Button href={remoteHelpUrl} target="_blank" className="btn-dark remote-help">
        {t('global:footer.remoteHelp')}
    </Button>
}
