import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {RouteParams} from "@app/AppContext/classes/Api/model/RouteParams";

export const endpointWithRouteParams = <RouteParamsNames extends string, ErrorCodes extends string>(endpoint: Endpoint<RouteParamsNames, ErrorCodes>, parameters: RouteParams<RouteParamsNames>): Endpoint<RouteParamsNames, ErrorCodes> => {
    if (!endpoint.routeParamNames) {
        return endpoint;
    }

    let url = endpoint.url;
    endpoint.routeParamNames.forEach((paramName) => {
        url = url.replace(`:${paramName}`, parameters[paramName]);
    });

    return {
        ...endpoint,
        url
    };
}
