export enum BranchCareWorkerType {
    MANAGER = 'manager',
    COORDINATOR = 'coordinator',
}

export type BranchCareWorker = {
    id: string;
    name: string;
    email: string;
}

export type BranchManager = BranchCareWorker & {
    type: BranchCareWorkerType.MANAGER;
}

export type BranchCoordinator = BranchCareWorker & {
    type: BranchCareWorkerType.COORDINATOR;
}
