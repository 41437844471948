import striptags from "striptags";

import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {Money} from "@common/model/Money";
import {renderComponentToString} from "@common/utils/renderComponentToString";

export const moneyToExportString = (money: Money): string => {
    const rendered = renderComponentToString(
        <MoneyFormatter amount={money.amount} currencyCode={money.currency} dressed={false} />
    );

    return striptags(rendered);
}
