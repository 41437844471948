import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

const changePasswordKnownErrorCodes = {
    invalidCurrentPassword: 'invalidCurrentPassword' as const,
    passwordNotComplexEnough: 'passwordNotComplexEnough' as const,
    passwordMustNotMatchCurrentPassword: 'passwordMustNotMatchCurrentPassword' as const,
}

export const changePasswordEndpoint: Endpoint<string, keyof typeof changePasswordKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/user/change-password',
    knownErrorCodes: changePasswordKnownErrorCodes,
}
