import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Img} from "@common/components/Img/Img";

import './formResetLink.scss';

export type FormResetLinkProps = {
    onReset: () => void;
}

export const FormResetLink: FC<FormResetLinkProps> = ({onReset}) => {
    const {t} = useTranslation();

    return <div className="form-reset-link">
        <a onClick={onReset}>
            <Img src="/images/icons/ico/ico-delete.svg" alt={t('global:ui.renew')} />
            {t('global:ui.renew')}
        </a>
    </div>

}
