import {DeliverPacket} from "@app/Packet/components/PacketActions/components/DeliverPacket/DeliverPacket";
import {MoneyRefund} from "@app/Packet/components/PacketActions/components/MoneyRefund/MoneyRefund";
import {PacketPrints} from "@app/Packet/components/PacketActions/components/PacketPrints/PacketPrints";
import {ProlongPacket} from "@app/Packet/components/PacketActions/components/ProlongPacket/ProlongPacket";
import {ReceivePacket} from "@app/Packet/components/PacketActions/components/ReceivePacket/ReceivePacket";
import {ReportIssue} from "@app/Packet/components/PacketActions/components/ReportIssue/ReportIssue";
import {UndeliverPacket} from "@app/Packet/components/PacketActions/components/UndeliverPacket/UndeliverPacket";
import {
    PacketNotificationButton
} from "@app/Packet/components/PacketDelivery/components/PacketNotificationButton/PacketNotificationButton";
import {Packet} from "@app/Packet/model/Packet";
import {Sender} from "@app/Packet/model/Sender";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './packetActions.scss';

export type PacketActionsProps = {
    packet: Packet;
    sender?: Sender;
    onSuccess?: () => void;
}

export const PacketActions: FCWithChildren<PacketActionsProps> = ({children, onSuccess, packet, sender}) => {
    return <span className="packet-actions">
        {children}
        <DeliverPacket packet={packet} onSuccess={onSuccess} />
        <UndeliverPacket packet={packet} onSuccess={onSuccess} />
        <MoneyRefund packet={packet} sender={sender} onSuccess={onSuccess} />
        <ReceivePacket packet={packet} onSuccess={onSuccess} />
        <ProlongPacket packet={packet} onSuccess={onSuccess} />
        <PacketNotificationButton packet={packet} />
        <PacketPrints packet={packet} />{' '}
        <ReportIssue barcode={packet.barcode} />
    </span>
}
