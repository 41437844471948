import {Fragment, useMemo} from "react";
import {Route} from "react-router-dom";

import {NewMessageRoute, ReceivedMessagesRoute} from "@app/Messaging/MessagingRoutes";
import {NavigateWithQueryParams} from "@common/components/routing/NavigateWithQueryParams/NavigateWithQueryParams";
import {To} from "@common/model/ReactRoute";

type AppRouteRedirection = {
    fromPath: string,
    to: To;
}

export const useAppRouteRedirections = () => {
    const redirections = useMemo<AppRouteRedirection[]>(() => ([
        {fromPath: 'message', to: ReceivedMessagesRoute},
        {fromPath: 'message/new-message', to: NewMessageRoute},
    ]), []);

    return <Fragment>
        {redirections.map((redirection) => (
            <Route path={redirection.fromPath} element={<NavigateWithQueryParams to={redirection.to} />} key={redirection.fromPath} />
        ))}
    </Fragment>

}
