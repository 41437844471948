import {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {ManualsIndex} from "@app/Manuals/components/ManualsIndex/ManualsIndex";
import {ManualPostRoute} from "@app/Manuals/ManualsRoutes";
import {ManualPost} from "@app/Manuals/model/ManualPost";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {useAppNavigate} from "@common/hooks/useAppNavigate";

export const ManualsIndexPage: FC = () => {
    const {t} = useTranslation();
    const navigate = useAppNavigate();

    const onPostSelect = useCallback((manualPost: ManualPost) => {
        navigate({route: ManualPostRoute, params: {id: manualPost.id.toString(10)}});
    }, [navigate]);

    return <PageContent className="manuals-index">
        <PageHeader>{t('global:menu.manuals')}</PageHeader>
        <ManualsIndex onPostSelect={onPostSelect} />
    </PageContent>
}
