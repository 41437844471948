import {FC, Fragment, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

import {User} from "@app/AppContext/classes/User/model/User";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {inventoryDetail} from "@app/Inventory/api/inventoryApi";
import {BranchInventoryListRoute, ManagedInventoryRoute} from "@app/Inventory/InventoryRoutes";
import {InventoryDetail as InventoryDetailModel} from "@app/Inventory/model/InventoryDetail";
import {
    InventoryDetailButtons
} from "@app/Inventory/pages/InventoryDetailPage/InventoryDetailButtons/InventoryDetailButtons";
import {
    InventoryDetailInfo
} from "@app/Inventory/pages/InventoryDetailPage/InventoryDetailInfo/InventoryDetailInfo";
import {
    InventoryPacketsList
} from "@app/Inventory/pages/InventoryDetailPage/InventoryPacketsList/InventoryPacketsList";
import {Loading} from "@common/components/Loading/Loading";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";
import {appToast} from "@common/components/Toast/toastOpener";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {DateFormatType, useDateFormatter} from "@common/hooks/useDateFormatter";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {Embeddable} from "@common/model/requests/RequestWithEmbedded";
import {extractEmbedded} from "@common/utils/extractEmbedded";

import './inventoryDetailPage.scss';

export const InventoryDetailPage: FC = () => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const {branchId, inventoryId} = useParams<'branchId'|'inventoryId'>();
    const [detail, setDetail] = useState<InventoryDetailModel|null>(null);
    const usersMap = useRef<Map<string, User>>(new Map<string, User>());
    const navigate = useAppNavigate();

    const showAppErrorToast = useShowErrorToast(appToast);

    const dateFormatter = useDateFormatter();

    useEffect(() => {
        if (detail === null && inventoryId) {
            inventoryDetail({inventoryId, embed: [Embeddable.USER]}, appContext.api)
                .then((response) => {
                    if (response.items.length === 0) {
                        throw new Error('Inventory detail is empty');
                    }
                    setDetail(response.items[0]);

                    usersMap.current = extractEmbedded(response, Embeddable.USER);
                })
                .catch((error: Error) => {
                    showAppErrorToast(error);
                    if (branchId) {
                        navigate({route: BranchInventoryListRoute, params: {branchId}});
                    } else {
                        navigate(ManagedInventoryRoute);
                    }
                });
        }
    }, [appContext.api, branchId, detail, inventoryId, navigate, showAppErrorToast]);

    return <PageContent className="inventory-detail-page">
        <ReturnLink label={t('inventory:action.goBack')} to={{route: BranchInventoryListRoute, params: {branchId}}} />
        <Loading active={detail === null}>
            {detail !== null && <Fragment>
                <PageHeader
                    subTitle={<InventoryDetailButtons
                        branchId={branchId}
                        detail={detail}
                        inventoryId={inventoryId}
                    />}
                >
                    {t('viewInventory:detail.header', {date: dateFormatter(detail.createdAt, DateFormatType.DATE_FORMAT)})}
                </PageHeader>
                <InventoryDetailInfo detail={detail} usersMap={usersMap} />
                {inventoryId && <InventoryPacketsList
                    inventoryId={inventoryId}
                />}
            </Fragment>}
        </Loading>
    </PageContent>
}
