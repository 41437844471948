import {useCallback, useEffect, useRef, useState} from "react";
import useWebSocket from "react-use-websocket";

import {wsToken} from "@app/App/components/AppWrapper/DataSourceProvider/api/webSocketApi";
import {
    isWebSocketErrorMessage,
    RecoverableWebSocketErrorMessageCodes
} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketErrorMessage";
import {
    WebSocketMessage
} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketMessage";
import {
    WebSocketOutgoingMessage
} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketOutgoingMessage";
import {WebSocketResultMessage} from "@app/App/components/AppWrapper/DataSourceProvider/model/WebSocketResultMessage";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {useLogError} from "@common/hooks/useLogError";
import {createURL} from "@common/utils/createURL";
import {notEqual} from "@common/utils/notEqual";
import {randomNumber} from "@common/utils/randomNumber";
import {wait} from "@common/utils/wait";

const WS_TOKEN_MAX_RETRIES = 3;

export const useAdminBeWebSocket = (
    onMessage: (message: WebSocketMessage) => void,
    onConnectionFailed: (isRecoverable: boolean) => void,
    connect: boolean,
) => {
    const {api, config, user} = useAppContext();
    const {adminVmBackendHost, adminVmBackendNonSecure, adminVmBackendPort} = config;
    const [previousJsonMessage, setPreviousJsonMessage] = useState<WebSocketMessage|null>(null);

    const wsTokenRetries = useRef<number>(0);
    const logError = useLogError();

    const adminVmWebSocketUrl = useCallback(async (): Promise<string> => {
        try {
            const {wsAccessToken} = await wsToken(api);
            const url = createURL('ws', `${adminVmBackendHost}/ws`, adminVmBackendPort, adminVmBackendNonSecure);
            url.searchParams.append('token', wsAccessToken);
            wsTokenRetries.current = 0;

            return url.toString();
        } catch (error: unknown) {
            if (wsTokenRetries.current <= WS_TOKEN_MAX_RETRIES) {
                ++wsTokenRetries.current;
                await wait(randomNumber(2000, 10000));
                return adminVmWebSocketUrl();
            }

            onConnectionFailed(false);
            await logError(error as Error);
            throw error;
        }
    }, [adminVmBackendHost, adminVmBackendNonSecure, adminVmBackendPort, api, logError, onConnectionFailed]);

    const {lastJsonMessage, sendJsonMessage} = useWebSocket<WebSocketMessage>(
        adminVmWebSocketUrl,
        {
            retryOnError: true,
            shouldReconnect: () => true,
            reconnectInterval: () => {
                return randomNumber(2000, 10000);
            },
            onReconnectStop: () => onConnectionFailed(false),
            heartbeat: {
                message: JSON.stringify({
                    subject: 'keepAlive',
                    value: 'ping',
                } as WebSocketOutgoingMessage),
                returnMessage: JSON.stringify({
                    success: true,
                    message: 'pong'
                } as WebSocketResultMessage),
                interval: 50000,
            },
        },
        user.isLoggedIn && connect,
    );

    useEffect(() => {
        if (lastJsonMessage && (previousJsonMessage === null || notEqual(lastJsonMessage, previousJsonMessage))) {
            if (isWebSocketErrorMessage(lastJsonMessage)) {
                onConnectionFailed(
                    RecoverableWebSocketErrorMessageCodes.includes(lastJsonMessage.errorCode)
                );
                return;
            }

            onMessage(lastJsonMessage);
            setPreviousJsonMessage(lastJsonMessage);
        }
    }, [lastJsonMessage, onConnectionFailed, onMessage, previousJsonMessage]);

    useEffect(() => {
        if (connect && user.accessToken) {
            sendJsonMessage({
                subject: 'accessToken',
                value: user.accessToken,
            } as WebSocketOutgoingMessage);
        }
    }, [connect, sendJsonMessage, user.accessToken]);
}
