import {TFunction} from "i18next";
import {MutableRefObject, useCallback} from "react";
import {UseFormReturn} from "react-hook-form";
import striptags from "striptags";

import {
    ManagedCommissionGridAction
} from "@app/Commission/components/ManagedCommissionGrid/ManagedCommissionGrid";
import {CommissionBranch} from "@app/Commission/model/CommissionBranch";
import {
    NewInvoiceFormData
} from "@app/Commission/pages/NewInvoicePage/NewInvoiceForm/NewInvoiceFormData";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {NumberFormatter} from "@common/components/NumberFormatter/NumberFormatter";
import {addAmounts} from "@common/utils/addAmounts";
import {compareAmounts} from "@common/utils/compareAmounts";
import {denormalizeDecimalSeparator, normalizeDecimalSeparator} from "@common/utils/normalizeDecimalSeparator";
import {renderComponentToString} from "@common/utils/renderComponentToString";

export const useNewInvoiceFormRenderAction = (
    form: UseFormReturn<NewInvoiceFormData>,
    commissionBranchesMap: MutableRefObject<Map<string, CommissionBranch>>,
    t: TFunction,
) => {
    return useCallback<ManagedCommissionGridAction>(({commissionBranch, unpaidTillLastMonthSum}) => {
        if (commissionBranch === undefined) {
            if (!unpaidTillLastMonthSum) {
                return null;
            }

            const currentBranchesCommissions = form.getValues('branchesCommissions');
            if (currentBranchesCommissions === undefined) {
                return <MoneyFormatter amount={unpaidTillLastMonthSum.amount} currencyCode={unpaidTillLastMonthSum.currency} />
            }

            let commissionsSum = '0';
            for (const branchCommission in currentBranchesCommissions) {
                if (Object.prototype.hasOwnProperty.call(currentBranchesCommissions, branchCommission)) {
                    if (currentBranchesCommissions[branchCommission]) {
                        commissionsSum = addAmounts(commissionsSum, currentBranchesCommissions[branchCommission]);
                    }
                }
            }

            return <MoneyFormatter amount={commissionsSum} currencyCode={unpaidTillLastMonthSum.currency} />
        }

        const sanitizedBranchId = `${commissionBranch.branchId.replace(/\./g, '-')}-${commissionBranch.commissionType}`;
        commissionBranchesMap.current.set(sanitizedBranchId, commissionBranch);

        return <FormGroup
            form={form}
            name={`branchesCommissions.${sanitizedBranchId}`}
            inputOptions={{defaultValue: denormalizeDecimalSeparator(commissionBranch.unpaidTillLastMonth)}}
            registerOptions={{
                validate: (value: string) => {
                    return compareAmounts(normalizeDecimalSeparator(value), commissionBranch.unpaidTillLastMonth) !== 1
                        || t<string>('viewCommission:newInvoice.form.amount.max', {
                            maxAmount: striptags(renderComponentToString(<NumberFormatter
                                numeric={commissionBranch.unpaidTillLastMonth}
                            />)),
                            branch: commissionBranch.name,
                        });
                },
                min: {
                    value: 0,
                    message: t<string>('viewCommission:newInvoice.form.amount.min'),
                },
                shouldUnregister: true,
            }}
        />
    }, [commissionBranchesMap, form, t]);
}
