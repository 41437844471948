import {useCallback} from "react";
import {Button, ButtonProps} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {reprint} from "@app/Print/api/printApi";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {FCWithChildren} from "@common/model/FCWithChildren";
import {PrintResult, translationKeyForPrintResult} from "@common/model/PrintResult";

export type ReprintButtonProps = ButtonProps & {
    printResult: PrintResult;
    toastInModal?: boolean;
    onReprint?: () => void;
}

export const ReprintButton: FCWithChildren<ReprintButtonProps> = ({children, onReprint, printResult, toastInModal, ...buttonProps}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const toastOpener = toastInModal ? modalToast : appToast;

    const doReprint = useCallback(() => {
        if (!printResult.reprintId) {
            return;
        }

        reprint({reprintId: printResult.reprintId}, appContext.api)
            .then((response) => {
                if (response.success) {
                    if (response.prints.length) {
                        response.prints.forEach((print) => {
                            if (print.success) {
                                toastOpener.success(t(translationKeyForPrintResult(printResult)));
                            } else {
                                toastOpener.error(t('global:print.error.general'));
                            }
                        })
                    } else {
                        toastOpener.success(t('global:print.success.general'));
                    }
                } else {
                    toastOpener.error(t('global:print.error.general'));
                }
            })
            .catch(() => {
                toastOpener.error(t('global:print.error.general'));
            })
            .finally(() => {
                if (onReprint) {
                    onReprint();
                }
            });
    }, [appContext.api, onReprint, printResult, t, toastOpener]);

    if (!printResult.reprintId) {
        return null;
    }

    return <Button variant="secondary" onClick={() => doReprint()} {...buttonProps}>{children}</Button>

}
