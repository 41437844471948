import {MutableRefObject, useCallback, useRef} from 'react';

import {useLogError} from "@common/hooks/useLogError";
import {readLocalStorage, writeLocalStorage} from "@common/utils/localStorage";

export const useLocalStorageRef = <T>(key: string, initialValue: T): [MutableRefObject<T>, () => void] => {

    const storedValue = useRef<T>(initialValue);
    const logError = useLogError();

    storedValue.current = readLocalStorage(key) || initialValue;

    const persistValue = useCallback(() => {
        try {
            writeLocalStorage(key, storedValue.current);
        } catch (error: unknown) {
            void logError(error as Error);
        }
    }, [key, logError]);

    return [storedValue, persistValue];
}
