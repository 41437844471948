import {makeObservable, observable} from "mobx";

import {Api} from "@app/AppContext/classes/Api/Api";
import {received} from "@app/Messaging/api/messagingApi";
import {BaseMessagesListManager} from "@app/Messaging/managers/BaseMessagesListManager";
import {
    MessagesListRequest,
    ReceivedMessagesExtendedResponse
} from "@app/Messaging/model/MessagingRequestsAndResponses";

export class ReceivedMessagesListManager extends BaseMessagesListManager<ReceivedMessagesExtendedResponse> {
    public unreadCount: number|null = null;

    constructor(api: Api) {
        super((request: MessagesListRequest) => received(request, api));

        makeObservable(this, {
            unreadCount: observable,
        });

        this.processResponse.push((response) => {
            this.unreadCount = response ? response.unreadCount : null;
        });
    }
}
