import {FC, Fragment, useCallback, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {branchQualityDetail} from "@app/Branch/api/branchApi";
import {
    BranchQualityDetail, BranchQualityDetailType,
    isCashRegisterBranchQualityDetail,
    isGeneralBranchQualityDetail, isPacketsBranchQualityDetail, isParcelBranchQualityDetail
} from "@app/Branch/model/BranchQuality/BranchQualityDetail";
import {
    CashRegisterProcessPenaltyDetailGrid
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/components/CashRegisterProcessPenaltyDetailGrid/CashRegisterProcessPenaltyDetailGrid";
import {
    GeneralProcessPenaltyDetailGrid
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/components/GeneralProcesPenaltyDetailGrid/GeneralProcessPenaltyDetailGrid";
import {
    PacketsProcessPenaltyDetailGrid
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/components/PacketsProcessPenaltyDetailGrid/PacketsProcessPenaltyDetailGrid";
import {
    ParcelProcessPenaltyDetailGrid
} from "@app/Branch/pages/BranchQualityPage/ProcessPenalty/ProcessPenaltyItem/ProcessPenaltyDetailModal/components/ParcelProcessPenaltyDetailGrid/ParcelProcessPenaltyDetailGrid";
import {Button} from "@common/components/Button/Button";
import {LoadingButton, LoadingButtonOnClick} from "@common/components/Loading/LoadingButton/LoadingButton";
import {BaseModal} from "@common/components/modals/BaseModal/BaseModal";
import {appToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {camelize} from "@common/utils/camelize";

import './processPenaltyDetailModal.scss';

export type ProcessPenaltyDetailModalProps = {
    penaltyType: BranchQualityDetailType;
}

export const ProcessPenaltyDetailModal: FC<ProcessPenaltyDetailModalProps> = ({penaltyType}) => {
    const {t} = useTranslation();
    const {api} = useAppContext();

    const [detail, setDetail] = useState<BranchQualityDetail|null>(null);

    const showAppErrorToast = useShowErrorToast(appToast);

    const load: LoadingButtonOnClick = useCallback((stopLoading) => {
        branchQualityDetail({type: penaltyType}, api)
            .then((response) => {
                setDetail(response);
            })
            .catch((error: Error) => {
                setDetail(null);
                showAppErrorToast(error);
            })
            .finally(stopLoading);
    }, [api, penaltyType, showAppErrorToast]);

    const close = useCallback(() => {
        setDetail(null);
    }, []);

    return <Fragment>
        <LoadingButton onLoadingClick={load} variant="warning">
            {t('viewBranchQuality:processPenalty.detail.show')}
        </LoadingButton>
        <BaseModal
            show={detail !== null}
            onHide={close}
            header={t(`branchQuality:processPenalty.${camelize(penaltyType)}.title`)}
            footer={<Button onClick={close}>{t('viewBranchQuality:processPenalty.detail.close')}</Button>}
            height="auto"
            className="process-penalty-detail-modal"
        >
            {detail && <Fragment>
                {isPacketsBranchQualityDetail(detail) && <PacketsProcessPenaltyDetailGrid
                    items={detail.items}
                />}
                {isCashRegisterBranchQualityDetail(detail) && <CashRegisterProcessPenaltyDetailGrid
                    items={detail.items}
                />}
                {isParcelBranchQualityDetail(detail) && <ParcelProcessPenaltyDetailGrid
                    items={detail.items}
                />}
                {isGeneralBranchQualityDetail(detail) && <GeneralProcessPenaltyDetailGrid
                    items={detail.items}
                />}
            </Fragment>}
        </BaseModal>
    </Fragment>
}
