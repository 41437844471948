import classNames from "classnames";
import {observer} from "mobx-react-lite";
import {Fragment} from "react";
import {Badge} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {usePacketCardPaymentAllowed} from "@app/Packet/components/PacketDelivery/hooks/usePacketCardPaymentAllowed";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './packetPaymentInfo.scss';

export type PacketPaymentInfoProps = {
    packet: DeliveryPacket;
    showLabel: boolean;
    isDelivering: boolean;
    allowNotDeliveredPayment?: boolean;
}

export const PacketPaymentInfo: FCWithChildren<PacketPaymentInfoProps> = observer(({
    allowNotDeliveredPayment = false,
    children,
    isDelivering,
    packet,
    showLabel
}) => {
    const {t} = useTranslation();

    const cardPaymentAllowed = usePacketCardPaymentAllowed()(packet);

    const isUnpaidCodPacket = packet.isCod && !packet.paid;

    return <div className={classNames('packet-payment-info', {'packet-payment-info_unpaid': isDelivering && isUnpaidCodPacket})}>

        {showLabel && <div className="packet-packet-info__label">
            <div>{t('packet:property.hasCashOnDelivery.title')}</div>
            {isUnpaidCodPacket && !cardPaymentAllowed && <Badge variant="danger" data-xid="cannot-be-paid-with-card">
                {t('cardPayment:error.notAllowedToPayByCard')}
            </Badge>}
        </div>}
        {!isUnpaidCodPacket && <Fragment>
            {packet.isCod && <div className="packet-payment-info_without-cod" data-xid="card-payment-successful">
                {t('cardPayment:paid')}
            </div>}
            {!packet.isCod && <div className="packet-payment-info_without-cod" data-xid="no-cash-on-delivery">
                {t('packet:property.hasCashOnDelivery.noCashOnDelivery')}
            </div>}
        </Fragment>}
        {isUnpaidCodPacket && <div className="packet-payment-info__amount" data-xid="with-cash-on-delivery">
            <MoneyFormatter amount={packet.info.cod} currencyCode={packet.info.currency} />
        </div>}
        {!showLabel && packet.isDelivered && isUnpaidCodPacket && !cardPaymentAllowed && <Badge variant="danger" data-xid="cannot-be-paid-with-card">
            {t('cardPayment:error.notAllowedToPayByCard')}
        </Badge>}

        {(allowNotDeliveredPayment || packet.isDelivered) && cardPaymentAllowed && children}
        {isUnpaidCodPacket && packet.codCashAmount !== undefined && <div className="packet-payment-info__cash" data-xid="packet-payment-cash">
            <div className="packet-payment-info__label">{t('packet:property.hasCashOnDelivery.inCash')}</div>
            <div className="packet-payment-info__cash-amount">
                <MoneyFormatter amount={packet.codCashAmount} currencyCode={packet.info.currency} />
            </div>
        </div>}
    </div>
})
