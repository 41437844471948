import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {TransactionGrid} from "@app/Transaction/components/TransactionGrid/TransactionGrid";
import {
    TransactionListType
} from "@app/Transaction/components/TransactionGrid/TransactionListManager";
import {
    CashRegisterBalanceBox
} from "@app/Transaction/pages/BranchCashRegisterPage/CashRegisterBalanceBox/CashRegisterBalanceBox";
import {
    BranchCashRegisterRoute,
    ManagedCashRegisterRoute
} from "@app/Transaction/TransactionModuleRoutes";
import {BranchIdChecker} from "@common/components/BranchIdChecker/BranchIdChecker";
import {PageContent} from "@common/components/Page/PageContent/PageContent";
import {PageHeader} from "@common/components/Page/PageHeader/PageHeader";
import {ReturnLink} from "@common/components/Page/ReturnLink/ReturnLink";

import './branchCashRegisterPage.scss';

export type BranchCashRegisterPageProps = {
    branchId: string;
}

export const BranchCashRegisterPage: FC<BranchCashRegisterPageProps> = observer(({branchId}) => {
    const {t} = useTranslation();
    const {dashboardInfo} = useAppContext();

    return <BranchIdChecker branchId={branchId} detailRoute={BranchCashRegisterRoute} fallbackRoute={DashboardRoute}>
        <PageContent className="branch-cash-register-page">
            {dashboardInfo.hasManagedBranches && <ReturnLink
                label={t('viewTransaction:managedCashRegister.back')}
                to={ManagedCashRegisterRoute}
            />}
            <PageHeader branchId={branchId}>{t('viewTransaction:branchCashRegister.header')}</PageHeader>
            <CashRegisterBalanceBox branchId={branchId} />
            <TransactionGrid branchId={branchId} listType={TransactionListType.CASH_REGISTER} />
        </PageContent>
    </BranchIdChecker>
})
