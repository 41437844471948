import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {PacketAlert} from "@app/Packet/components/PacketDelivery/components/DeliveryAlerts/DeliveryAlert/PacketAlert/PacketAlert";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {PacketStatusBadge} from "@common/components/Badges/PacketStatusBadge/PacketStatusBadge";

export type PacketUndeliveryAlertsProps = {
    packet: DeliveryPacket;
}

export const PacketUndeliveryAlerts: FC<PacketUndeliveryAlertsProps> = observer(({packet}) => {
    const {t} = useTranslation();
    const {catalogues} = useAppContext();

    return <div className="packet-undelivery-modal-alerts">
        {packet.isCod && packet.paid && <PacketAlert
            title={t('viewPacketDelivery:undelivery.alert.cardPayment.title')}
            description={t<string>('viewPacketDelivery:undelivery.alert.cardPayment.description')}
            className="packet-undelivery-modal-alerts-card-payment"
            xid="card-payment-undelivery"
        />}
        <PacketAlert
            title={t('viewPacketDelivery:undelivery.alert.undelivery.title')}
            description={t<string>('viewPacketDelivery:undelivery.alert.undelivery.description')}
            xid="undelivery"
        >
            {catalogues.pickupReadyPacketStatus && <PacketStatusBadge status={catalogues.pickupReadyPacketStatus} />}
        </PacketAlert>
    </div>
})
