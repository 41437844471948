import {Fragment, ReactElement} from "react";

import {ToastContainer} from "@common/components/Toast/ToastContainer/ToastContainer";
import {LanguageSelect} from "@common/layouts/common/LanguageSelect/LanguageSelect";
import {Logo} from "@common/layouts/common/Logo/Logo";
import {OfflineNotice} from "@common/layouts/common/OfflineNotice/OfflineNotice";
import {ReloadNotice} from "@common/layouts/common/ReloadNotice/ReloadNotice";
import {FCWithChildren} from "@common/model/FCWithChildren";

import './centeredLayout.scss';

export type CenteredLayoutProps = {
    className?: string;
    header?: ReactElement|ReactElement[];
}

export const CenteredLayout: FCWithChildren<CenteredLayoutProps> = ({children, className, header}) => {
    return <div className="centered-layout">
        <OfflineNotice />
        <ReloadNotice />
        <ToastContainer />
        <div className="centered-layout__content">
            <div className={className}>
                <div className="centered-layout__header">
                    {header || <Fragment>
                        <Logo />
                        <LanguageSelect />
                    </Fragment>}
                </div>
                <div className="centered-layout__body">{children}</div>
            </div>
        </div>
    </div>
}
