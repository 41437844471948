import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {PrintComponent} from "@app/Packet/components/PacketActions/components/PacketPrints/PrintComponent";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {printPacketLabels} from "@app/Print/api/printApi";
import {PrintableTypes} from "@app/Print/model/PrintRequestsAndResponses";
import {FormGroup} from "@common/components/forms/FormGroup/FormGroup";
import {SubmitFormGroup} from "@common/components/forms/SubmitFormGroup/SubmitFormGroup";
import {modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {PrintResultErrorCode} from "@common/model/PrintResult";

type PrintLabelFormData = {
    reason: string;
}

export const PrintLabelForm: PrintComponent = ({packet, setIsSubmitting}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const form = useForm<PrintLabelFormData>({defaultValues: {reason: ''}});
    const [needsReasonByError, setNeedsReasonByError] = useState<boolean>(false);
    const showModalErrorToast = useShowErrorToast(modalToast);

    const onSubmit = useCallback(({reason}: PrintLabelFormData) => {
        setIsSubmitting(true);
        printPacketLabels({barcodes: [packet.barcode], type: PrintableTypes.DEPOT_LABEL, reason}, appContext.api)
            .then((response) => {
                if (response.success) {
                    const firstPrintResponse = response.prints[0] || null;
                    if (firstPrintResponse === null || firstPrintResponse.success) {
                        modalToast.success(t('global:print.success.depotLabel'));
                        form.reset({reason: ''});
                    } else if (firstPrintResponse.errorCode !== null) {
                        modalToast.error(t(`global:print.error.${firstPrintResponse.errorCode}`));
                        if ((firstPrintResponse.errorCode as PrintResultErrorCode) === PrintResultErrorCode.REASON_REQUIRED) {
                            setNeedsReasonByError(true);
                            form.setFocus('reason');
                        }
                    } else {
                        modalToast.error(t('global:print.error.general'));
                    }
                } else {
                    modalToast.error(t('global:print.error.general'));
                }
            })
            .catch((error: Error) => {
                showModalErrorToast(error);
            })
            .finally(() => {
                setIsSubmitting(false);
            })
    }, [appContext.api, form, packet.barcode, setIsSubmitting, showModalErrorToast, t]);

    useEffect(() => {
        form.setFocus('reason');
    }, [form]);

    if (![
        PacketStatusId.DATA_RECEIVED,
        PacketStatusId.EN_ROUTE,
        PacketStatusId.COURIER_SENT,
        PacketStatusId.PICKUP_READY,
        PacketStatusId.DELIVERED,
        PacketStatusId.RETURN,
        PacketStatusId.RETURN_IN_STORAGE,
        PacketStatusId.PROBLEM,
        PacketStatusId.PROBLEM_CAPTURE,
        PacketStatusId.TO_DISPOSE,
    ].includes(packet.status.id)) {
        return null;
    }

    const needsReason = needsReasonByError || [
        PacketStatusId.COURIER_SENT,
        PacketStatusId.PICKUP_READY,
        PacketStatusId.DELIVERED,
        PacketStatusId.RETURN,
        PacketStatusId.RETURN_IN_STORAGE,
        PacketStatusId.PROBLEM,
        PacketStatusId.PROBLEM_CAPTURE,
        PacketStatusId.TO_DISPOSE,
    ].includes(packet.status.id);

    const formId = 'printLabelForm';

    return <form id={formId} onSubmit={form.handleSubmit(onSubmit)}>
        {needsReason && <FormGroup
            form={form}
            name="reason"
            label={t('viewPacketPrint:input.label')}
            registerOptions={{required: t<string>('viewPacketPrint:error.reasonNeeded')}}
            inputOptions={{
                placeholder: t('viewPacketPrint:input.placeholder')
            }}
            formId={formId}
        />}
        <SubmitFormGroup name="send" value={t('viewPacketPrint:action.printLabels')} variant="secondary" formId={formId} />
    </form>
}
