import {ApiBackend} from "@app/AppContext/classes/Api/model/ApiBackend";
import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";

export const postDetailEndpoint: Endpoint<'postId'> = {
    backend: ApiBackend.ADMIN_VM_BACKEND,
    method: HttpMethod.GET,
    url: '/manuals/posts/:postId',
    routeParamNames: ['postId'],
    notFoundErrorResponse: true,
}

export const postsEndpoint: Endpoint = {
    backend: ApiBackend.ADMIN_VM_BACKEND,
    method: HttpMethod.GET,
    url: '/manuals/posts',
}

export const categoriesEndpoint: Endpoint = {
    backend: ApiBackend.ADMIN_VM_BACKEND,
    method: HttpMethod.GET,
    url: '/manuals/categories',
}

export const pageDetailEndpoint: Endpoint<'pageId'> = {
    backend: ApiBackend.ADMIN_VM_BACKEND,
    method: HttpMethod.GET,
    url: '/manuals/pages/:pageId',
    routeParamNames: ['pageId'],
    notFoundErrorResponse: true,
}
