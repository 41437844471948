import {ParametricReactRoute, SimpleReactRoute} from "@common/model/ReactRoute";

export const ManagedCommissionRoute: SimpleReactRoute = {
    path: '',
}

export const CommissionTariffRoute: SimpleReactRoute = {
    path: 'tariff',
}

export const InvoiceListRoute: SimpleReactRoute = {
    path: 'invoice',
}

export const NewInvoiceRoute: SimpleReactRoute = {
    path: 'invoice/new',
}

export const BillingInfoRoute: SimpleReactRoute = {
    path: 'invoice/billing-info',
}

export const BranchCommissionRoute: ParametricReactRoute<'branchId'> = {
    path: ':branchId',
    paramNames: ['branchId'],
}

export const CommissionRoutes = [
    ManagedCommissionRoute,
    CommissionTariffRoute,
    InvoiceListRoute,
    NewInvoiceRoute,
    BillingInfoRoute,
    BranchCommissionRoute,
];
