import {TypedPacketListRequestType} from "@app/Packet/components/PacketGrid/callPacketListApi";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {SelectedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";

export enum PacketListFilterDateType {
    CONSIGNED_AT = 'consignedAt',
    DELIVERED_AT = 'deliveredAt',
    STORED_AT = 'storedAt',
}

export type PacketListFilter = {
    type?: TypedPacketListRequestType.TODAYS_INCOMING | TypedPacketListRequestType.AT_BRANCH | TypedPacketListRequestType.TO_DISPATCH,
    fulltext?: string;
    multiStatusId?: PacketStatusId[];
    destinationHere?: boolean;
    dateType?: PacketListFilterDateType;
    dateRange?: SelectedDateRange;
}
