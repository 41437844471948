import {useCallback} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {systemNotify} from "@app/PDC/api/pdcApi";
import {SystemNotifyMessageType} from "@app/PDC/model/SystemNotifyMessageType";
import {useLogError} from "@common/hooks/useLogError";

export const useSystemNotification = () => {
    const {api, deviceGatewayResolver} = useAppContext();
    const logError = useLogError();

    return useCallback((text: string, messageType: SystemNotifyMessageType = SystemNotifyMessageType.INFO) => {
        if (!document.hidden) {
            // do not notify when the app window is actually visible
            return;
        }

        if (!deviceGatewayResolver.isPdc) {
            // do not notify when it is not possible
            return;
        }

        systemNotify(text, messageType, api)
            .catch((error: Error) => {
                // TODO: restrict error logging based on what kinds of errors we get in Sentry
                void logError(error);
            });

    }, [api, deviceGatewayResolver, logError]);
}
