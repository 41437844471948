import {ParametricReactRoute, SimpleReactRoute} from "@common/model/ReactRoute";

export const ManagedInventoryRoute: SimpleReactRoute = {
    path: '',
}

export const BranchInventoryListRoute: ParametricReactRoute<'branchId'> = {
    path: ':branchId',
    paramNames: ['branchId'],
}

export const InventoryDetailRoute: ParametricReactRoute<'branchId'|'inventoryId'> = {
    path: ':branchId/:inventoryId',
    paramNames: ['branchId', 'inventoryId'],
}

export const InventoryRoutes = [
    ManagedInventoryRoute,
    BranchInventoryListRoute,
    InventoryDetailRoute,
];
