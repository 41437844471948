import {TFunction} from "i18next";
import {useCallback, useRef} from "react";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {cancellationStrategy} from "@app/CardPayment/api/cardTerminalApi";
import {useCheckTransaction} from "@app/CardPayment/hooks/useCheckTransaction";
import {useSetTransactionStatus} from "@app/CardPayment/hooks/useSetTransactionStatus";
import {CancellationStrategyReason} from "@app/CardPayment/model/CancellationStrategyReason";
import {CancellationStrategyStrategy} from "@app/CardPayment/model/CancellationStrategyStrategy";
import {CardPaymentState, CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {CardPaymentTransaction, CardPaymentTransactionStatus} from "@app/CardPayment/model/CardPaymentTransaction";
import {cancelTransactionWithStrategy} from "@app/CardPayment/utils/cancelTransactionWithStrategy";

export type CancelPayment = () => Promise<void>;

const MAX_USED_CANCELLATION_STRATEGIES_COUNT = 3;

export const useCancelPayment = (
    cardPaymentState: CardPaymentState,
    setCardPaymentState: (newState: CardPaymentState) => void,
    stopPayment: () => void,
    onCancellingSuccess: () => void,
    onRunning: (running: boolean) => void,
    transactionId: string|null,
    t: TFunction,
): CancelPayment => {
    const appContext = useAppContext();
    const cancelledTransactionId = useRef<string|null>(null);
    const currentCancellationStrategy = useRef<CancellationStrategyStrategy|null>();
    const usedStrategiesCount = useRef<number>(0);

    const setTransactionStatus = useSetTransactionStatus(cardPaymentState, setCardPaymentState);

    const stopCancelling = useCallback(() => {
        onRunning(false);
        cancelledTransactionId.current = null;
        usedStrategiesCount.current = 0;
    }, [onRunning]);

    const cancelPayment = useCallback(async () => {
        onRunning(true);
        stopPayment();

        if (!cancelledTransactionId.current) {
            if (cardPaymentState.status === CardPaymentStatus.PAYING) {
                cancelledTransactionId.current = cardPaymentState.transaction.id;
            } else if (transactionId !== null) {
                cancelledTransactionId.current = transactionId;
            } else {
                stopCancelling();
                setTransactionStatus(CardPaymentTransactionStatus.ERROR, t('cardPayment:error.transactionForCancellingNotFound'));
                return;
            }
        }

        let cancellingTransaction: CardPaymentTransaction;
        try {
            const {cancellationStrategy: strategy} = await cancellationStrategy(cancelledTransactionId.current, appContext.api);
            if (!strategy.isCancellable) {
                cancellingTransaction = {
                    id: cancelledTransactionId.current,
                    status: CardPaymentTransactionStatus.ERROR,
                    message: strategy.reason === CancellationStrategyReason.NOTHING_TO_REFUND
                        ? t('cardPayment:error.nothingToRefund')
                        : t('cardPayment:error.uncancelableTransaction'),
                };
            } else {
                cancellingTransaction = {
                    id: await cancelTransactionWithStrategy(appContext.api, strategy.strategy, cancelledTransactionId.current),
                    status: CardPaymentTransactionStatus.CREATED,
                };
                usedStrategiesCount.current += 1;
                currentCancellationStrategy.current = strategy.strategy;
            }
        } catch (error: unknown) {
            stopCancelling();
            cancellingTransaction = {
                id: cancelledTransactionId.current,
                status: CardPaymentTransactionStatus.ERROR,
            };
        }

        setCardPaymentState({status: CardPaymentStatus.CANCELLING, transaction: cancellingTransaction});
    }, [onRunning, stopPayment, setCardPaymentState, cardPaymentState, transactionId, stopCancelling, setTransactionStatus, t, appContext.api]);

    const onCancellingTransactionFinished = useCallback(async (success: boolean, isTimeout?: boolean) => {
        const successRequired = currentCancellationStrategy.current !== CancellationStrategyStrategy.ABORT;

        if (!isTimeout && success === successRequired) {
            setTransactionStatus(CardPaymentTransactionStatus.SUCCESS);
            stopCancelling();
            onCancellingSuccess();
        } else {
            if (usedStrategiesCount.current >= MAX_USED_CANCELLATION_STRATEGIES_COUNT) {
                // Max number of strategies used - giving up
                setTransactionStatus(CardPaymentTransactionStatus.ERROR, isTimeout ? t('cardPayment:error.transactionTimeout') : undefined);
                stopCancelling();
            } else {
                // Otherwise, try again
                await cancelPayment();
            }
        }
    }, [cancelPayment, onCancellingSuccess, setTransactionStatus, stopCancelling, t]);

    useCheckTransaction(
        CardPaymentStatus.CANCELLING,
        cardPaymentState,
        setCardPaymentState,
        onCancellingTransactionFinished,
    );

    return cancelPayment;
}
