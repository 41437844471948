import striptags from "striptags";

import {MoneyFormatter} from "@common/components/MoneyFormatter/MoneyFormatter";
import {Money} from "@common/model/Money";
import {renderComponentToString} from "@common/utils/renderComponentToString";

export const moneyToString = (money: Money, stripped: boolean = false): string => {
    const rendered = renderComponentToString(
        <MoneyFormatter amount={money.amount} currencyCode={money.currency} />
    );

    return stripped ? striptags(rendered) : rendered;
}
