import {Api} from "@app/AppContext/classes/Api/Api";
import {searchTypeEndpoint} from "@app/Packet/api/searchTypeEndpoints";
import {SearchTypeRequest, SearchTypeResponse} from "@app/Packet/model/SearchRequestsAndResponses";
import {SearchTypeType} from "@app/Packet/model/SearchType";
import {offlineSearchType} from "@common/utils/api/offlineSearchType";

export const searchType = async (request: SearchTypeRequest, abortController: AbortController, api: Api): Promise<SearchTypeType> => {
    return offlineSearchType(request) ||
        api.call<SearchTypeResponse, SearchTypeRequest>({
            endpoint: searchTypeEndpoint,
            payload: request,
            abortController,
        })
            .then((response) => {
                return response.type;
            });
}

