import {observer} from "mobx-react-lite";
import {Fragment, useCallback, useMemo, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    PacketUndeliveryModal
} from "@app/Packet/components/PacketDelivery/components/PacketUndeliveryModal/PacketUndeliveryModal";
import {useDeliver} from "@app/Packet/components/PacketDelivery/hooks/useDeliver";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";
import {DeliveryPacketsCollection} from "@app/Packet/components/PacketDelivery/model/DeliveryPacketsCollection";
import {PacketIdVerification} from "@app/Packet/components/PacketDelivery/model/PacketIdVerification";
import {
    PasswordHandlingComponent
} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {SearchFormResult} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {PacketDeliveryModal} from "@packetModal/components/PacketDeliveryModal/PacketDeliveryModal";
import {PacketIdVerificationModal} from "@packetModal/components/PacketIdVerificationModal/PacketIdVerificationModal";
import {useLoadPickupReadyPackets} from "@packetModal/hooks/useLoadPickupReadyPackets";

export const PasswordPacketDeliveryModal: PasswordHandlingComponent = observer(({
    onError,
    onFinish,
    onVerified,
    password,
}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const packets = useMemo<DeliveryPacketsCollection>(() => new DeliveryPacketsCollection([], true), []);
    const [packetIdVerification, setPacketIdVerification] = useState<PacketIdVerification|null>(null);
    const [showToastMessage, setShowToastMessage] = useState<string|null>(null);

    const finish = useCallback(() => {
        onFinish(SearchFormResult.PASSWORD_OK);
        if (appContext.dashboardGrid) {
            void appContext.dashboardGrid.refreshPackets(packets.touchedBarcodes);
        }
    }, [appContext.dashboardGrid, onFinish, packets.touchedBarcodes]);

    const onDeliveryComplete = useCallback(() => {
        if (packets.delivered.length > 0) {
            appToast.success(t(`viewPacketDelivery:packetDelivery.message.packet${packets.delivered.length > 1 ? 's' : ''}Delivered`));
        }
        finish();
    }, [finish, packets.delivered.length, t]);

    const onUndelivered = useCallback((packet: DeliveryPacket) => {
        packets.update(packet);
        if (packets.delivered.length === 0) {
            // the last packet, closing modal
            appToast.success(t('viewPacketDelivery:undelivery.message.success'));
            finish();
        } else {
            setShowToastMessage(t<string>('viewPacketDelivery:undelivery.message.success'));
            packets.delete(packet);
        }
    }, [finish, packets, t]);

    const onIdVerificationCancelled = useCallback((packet: DeliveryPacket) => {
        packets.delete(packet);
        if (packets.delivered.length === 0) {
            // the last packet, closing modal
            appToast.success(t('viewPacketDelivery:packetIdVerification.message.deliveryCancelled'));
            finish();
        } else {
            setShowToastMessage(t<string>('viewPacketDelivery:packetIdVerification.message.deliveryCancelled'));
        }
    }, [finish, packets, t]);

    const showUndeliveryCancellationError = useShowErrorToast(modalToast, 'cardPayment:error', 'cardPayment:error.cancelling');
    const onUndeliveryCancelled = useCallback((error?: Error) => {
        if (packets.packetPendingUndelivery) {
            packets.packetPendingUndelivery.cancelUndeliveryRequest();
        }
        if (error) {
            window.setTimeout(() => showUndeliveryCancellationError(error), 0);
        }
    }, [packets.packetPendingUndelivery, showUndeliveryCancellationError]);

    const onDelivered = useCallback((packet: DeliveryPacket) => {
        packets.update(packet);
    }, [packets]);

    const deliver = useDeliver(onDelivered, packetIdVerification);

    useLoadPickupReadyPackets(packets, password, deliver, onVerified, onError);

    if (!packets.loaded) {
        return null;
    }

    return <Fragment>
        <PacketDeliveryModal
            deliver={deliver}
            packets={packets}
            packetIdVerification={packetIdVerification}
            onDeliveryComplete={onDeliveryComplete}
            setShowToastMessage={setShowToastMessage}
            showToastMessage={showToastMessage}
        />
        <PacketIdVerificationModal
            deliver={deliver}
            onDeliveryCancelled={onIdVerificationCancelled}
            packets={packets}
            packetIdVerification={packetIdVerification}
            setPacketIdVerification={setPacketIdVerification}
            setShowToastMessage={setShowToastMessage}
        />
        <PacketUndeliveryModal
            packet={packets.packetPendingUndelivery}
            onUndelivered={onUndelivered}
            onUndeliveryCancelled={onUndeliveryCancelled}
        />
    </Fragment>
})

PasswordPacketDeliveryModal.displayName = 'PasswordPacketDeliveryModal';
