import {Api} from "@app/AppContext/classes/Api/Api";
import {sent} from "@app/Messaging/api/messagingApi";
import {BaseMessagesListManager} from "@app/Messaging/managers/BaseMessagesListManager";
import {MessagesListRequest} from "@app/Messaging/model/MessagingRequestsAndResponses";

export class SentMessagesListManager extends BaseMessagesListManager {
    constructor(api: Api) {
        super((request: MessagesListRequest) => sent(request, api));
    }
}
