
import {Endpoint} from "@app/AppContext/classes/Api/model/Endpoint";
import {HttpMethod} from "@app/AppContext/classes/Api/model/HttpMethod";
import {HttpStatusCode} from "@common/model/HttpStatusCode";

export const microDepotEndpoint: Endpoint = {
    method: HttpMethod.GET,
    url: '/micro-depot',
}

const microDepotRouteSummaryKnownErrorCodes = {
    routeNotClosedByDriver: 'routeNotClosedByDriver' as const,
    passwordValidForAnotherBranch: 'passwordValidForAnotherBranch' as const,
    permissionDenied: {errorCode: 'permissionDenied' as const, statusCode: HttpStatusCode.FORBIDDEN},
}

export const microDepotRouteSummaryEndpoint: Endpoint<'password', keyof typeof microDepotRouteSummaryKnownErrorCodes> = {
    method: HttpMethod.GET,
    url: '/micro-depot/route/:password',
    routeParamNames: ['password'],
    knownErrorCodes: microDepotRouteSummaryKnownErrorCodes,
    notFoundErrorResponse: true,
}

const microDepotRouteFinalizationKnownErrorCodes = microDepotRouteSummaryKnownErrorCodes && {
    routeAlreadyFinalized: 'routeAlreadyFinalized' as const,
    collectedAmountDoesNotMatchDriverAmount: 'collectedAmountDoesNotMatchDriverAmount' as const,
    settlementBalanceNotZero: 'settlementBalanceNotZero' as const,
    settlementMultiCurrencyNetting: 'settlementMultiCurrencyNetting' as const,
}

export const microDepotRouteFinalizationEndpoint: Endpoint<string, keyof typeof microDepotRouteFinalizationKnownErrorCodes> = {
    method: HttpMethod.POST,
    url: '/micro-depot/route/finalize',
    knownErrorCodes: microDepotRouteFinalizationKnownErrorCodes,
    notFoundErrorResponse: true,
}
