import {FC, useCallback, useEffect} from "react";
import {Button} from "react-bootstrap";
import {useTranslation} from "react-i18next";

import {CardPaymentError} from "@app/CardPayment/components/CardPaymentError/CardPaymentError";
import {
    CardPaymentReceipts
} from "@app/CardPayment/components/CardPaymentReceipts/CardPaymentReceipts";
import {
    CardPaymentRunning
} from "@app/CardPayment/components/CardPaymentRunning/CardPaymentRunning";
import {
    SignatureVerificationModal
} from "@app/CardPayment/components/SignatureVerificationModal/SignatureVerificationModal";
import {useCardPayment} from "@app/CardPayment/hooks/useCardPayment";
import {CardPaymentStatus} from "@app/CardPayment/model/CardPaymentState";
import {DeliveryPacket} from "@app/Packet/components/PacketDelivery/model/DeliveryPacket";

export type PacketPaymentButtonProps = {
    packet: DeliveryPacket;
    onRunning: (running: boolean) => void;
    running: boolean;
}

export const PacketPaymentButton: FC<PacketPaymentButtonProps> = ({onRunning,  packet, running}) => {
    const {t} = useTranslation();

    const onTransactionSuccess = useCallback(() => {
        if (packet.isCod) {
            packet.pay();
        }
    }, [packet]);

    const onCancellingSuccess = useCallback(() => {
        if (packet.isCod) {
            packet.unpay();
        }
    }, [packet]);

    const {
        cancelPayment,
        cardPaymentState,
        confirmSignature,
        pay,
    } = useCardPayment(onTransactionSuccess, onCancellingSuccess, onRunning, packet);

    const payPacket = useCallback(async () => {
        await pay(packet.info.cod, packet.info.currency, {barcode: packet.info.barcode});
    }, [packet.info.barcode, packet.info.cod, packet.info.currency, pay]);

    useEffect(() => {
        if (packet.isCod && JSON.stringify(packet.cardPaymentState) !== JSON.stringify(cardPaymentState)) {
            packet.updateCardPaymentState(cardPaymentState);
        }
    }, [cardPaymentState, packet]);

    const showPayButton = packet.isCod && packet.cardPaymentAllowed && !packet.paid && cardPaymentState.status === CardPaymentStatus.READY;

    if (!packet.isCod) {
        return null;
    }

    return <div className="packet-payment-button">
        {showPayButton && <Button onClick={payPacket} variant="success" disabled={running} data-xid="card-pay-button">
            {t('cardPayment:action.pay')}
        </Button>}
        <CardPaymentRunning cardPaymentState={cardPaymentState} cancelPayment={cancelPayment} />
        <CardPaymentError
            cardPaymentState={cardPaymentState}
            paid={packet.paid}
            pay={payPacket}
            cancelPayment={cancelPayment}
            toastInModal={true}
        />
        <CardPaymentReceipts
            cardPaymentState={cardPaymentState}
            toastInModal={true}
            suppressSuccessToastOnTransactionCancelled={true}
        />
        <SignatureVerificationModal
            cardPaymentState={cardPaymentState}
            onSignatureConfirmed={confirmSignature}
            onSignatureRejected={cancelPayment}
        />
    </div>
}
