import {observer} from "mobx-react-lite";
import {FC, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {RecoveryChecker} from "@app/App/pages/FailurePage/RecoveryChecker";
import {ApiRequestFailureWatcher} from "@app/AppContext/classes/Api/ApiRequestFailureWatcher";
import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {NVZ_APP_URL} from "@app/config";
import {DashboardRoute} from "@app/Packet/PacketRoutes";
import {Img} from "@common/components/Img/Img";
import {TranslationWithHtmlParam} from "@common/components/TranslationWithHtmlParam/TranslationWithHtmlParam";
import {useAppNavigate} from "@common/hooks/useAppNavigate";
import {CenteredLayout} from "@common/layouts/CenteredLayout/CenteredLayout";

import './failurePage.scss';

export const FailurePage: FC = observer(() => {
    const {i18n, t} = useTranslation();
    const appContext = useAppContext();
    const appNavigate = useAppNavigate();
    const recoveryChecker = useMemo<RecoveryChecker>(
        () => new RecoveryChecker(appContext.api),
        [appContext.api]
    );

    useEffect(() => {
        if (recoveryChecker.recovered) {
            appContext.api.watchers.forEach((watcher) => {
                if (watcher instanceof ApiRequestFailureWatcher) {
                    watcher.recover();
                }
            })
            appNavigate(DashboardRoute);
        }
    }, [appContext.api.watchers, appNavigate, recoveryChecker.recovered]);

    if (recoveryChecker.recovered) {
        return null;
    }

    return <CenteredLayout className="failure-page">
        <div className="failure-page__content" data-xid="failure-page">
            <p>{t('viewFailurePage:content.greeting')},</p>
            <p><strong>{t('viewFailurePage:content.outageExperienced')}
            </strong> ({t('viewFailurePage:content.systemName')}).</p>
            <div className="failure-page__please-use-nvz">
                <TranslationWithHtmlParam
                    paramName="nvzApp"
                    translationKey="viewFailurePage:content.pleaseUseNvz" t={t}
                >
                    <a href={NVZ_APP_URL} target="_blank">{t('viewFailurePage:content.nvzAppName')}</a>
                </TranslationWithHtmlParam>
            </div>
            <p>{t('viewFailurePage:content.description')}</p>
            <p><strong>{t('viewFailurePage:content.willBeRedirected')}</strong></p>
            <p>{t('viewFailurePage:content.thankYou')}</p>
            <p>{t('viewFailurePage:content.packetaTeam')}</p>
            <div className="failure-page__van"><Img src={`/images/icons/error_van${i18n.language !== 'cs-CZ' ? '_packeta' : ''}.svg`} alt="error van" /></div>
        </div>
    </CenteredLayout>
})
