import {QueryParam} from "@common/model/requests/QueryParam";
import {RequestWithSort} from "@common/model/requests/SortRequestProperty";

export const createSortParameter = (request: RequestWithSort): QueryParam[] => {
    const result: QueryParam[] = [];

    if (request.sort && request.sort.length) {
        result.push({
            name: 'sort',
            value: request.sort.map(({desc, key}) => `${desc ? '-' : ''}${key}`).join(','),
        });
    }

    return result;
}
