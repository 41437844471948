import {useCallback, useEffect, useState} from "react";
import {useForm} from "react-hook-form";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    CashPickupModalFormData,
    CashPickupModalState, UseCashPickupModalType
} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/CashPickupModal/CashPickupModalTypes";
import {subtractAndRound} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/CashPickupModal/subtractAndRound";
import {SearchFormResult} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";
import {cashRegisterBalance, confirmTransfer, getTransfer} from "@app/Transaction/api/cashRegisterApi";
import {correctButUsedGetTransferKnownErrorCodes} from "@app/Transaction/api/cashRegisterEndpoints";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useBlockingCallback} from "@common/hooks/useBlockingCallback";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {ApiError} from "@common/model/errors/ApiError";
import {TranslatableError} from "@common/model/errors/TranslatableError";
import {PrintResultType} from "@common/model/PrintResult";
import {getFirstErrorItem} from "@common/utils/api/getFirstErrorItem";
import {getPrintResultByType} from "@common/utils/api/getPrintResultByType";

export const useCashPickupModal: UseCashPickupModalType = (password, onFinish, onError, onVerified, t) => {
    const appContext = useAppContext();

    const [modalState, setModalState] = useState<CashPickupModalState|null>(null);
    const [passwordConfirmed, setPasswordConfirmed] = useState<boolean>(false);

    const dashboardCashRegister = appContext.dashboardInfo.cashRegister;

    const showAppErrorToast = useShowErrorToast(appToast, 'viewCashPickup:error');

    useEffect(() => {
        if (!dashboardCashRegister) {
            onError(password, () => {
                showAppErrorToast(new TranslatableError('viewCashPickup:error.nothingToCollect'));
            });
            return;
        }
        getTransfer(password, appContext.api)
            .then((cashTransferResponse) => {
                onVerified(password);
                setPasswordConfirmed(true);
                const yesterday = parseFloat(cashTransferResponse.transfer.amount);
                const currency = cashTransferResponse.transfer.currency;
                const branchId = appContext.user.branchId;
                if (!branchId) {
                    setModalState({withChoice: false, currency, yesterday});
                    return;
                }
                if (!dashboardCashRegister.cashCollectionByCarrier) {
                    cashRegisterBalance({branchId}, appContext.api)
                        .then(({balance}) => {
                            if (balance.currency === dashboardCashRegister.currency) {
                                const roundCashByNumber = parseFloat(dashboardCashRegister.roundCashBy);
                                const today = subtractAndRound(
                                    parseFloat(balance.amount),
                                    parseFloat(dashboardCashRegister.keepCashAmount),
                                    roundCashByNumber
                                );
                                const partToday = subtractAndRound(
                                    today,
                                    dashboardCashRegister.staticKeepCashAmount ? parseFloat(dashboardCashRegister.staticKeepCashAmount) : 0,
                                    roundCashByNumber
                                );

                                if ((!today || today <= yesterday) && (!partToday || partToday <= yesterday)) {
                                    setModalState({withChoice: false, currency, yesterday});
                                } else {
                                    setModalState({withChoice: true, currency, yesterday, today, partToday});
                                }
                            } else {
                                setModalState({withChoice: false, currency, yesterday});
                            }
                        })
                        .catch((error: Error) => {
                            modalToast.error(t(error.message));
                        });
                } else {
                    setModalState({withChoice: false, currency, yesterday});
                }
            })
            .catch((error: Error) => {
                const firstError = error instanceof ApiError ? getFirstErrorItem(error as ApiError) : undefined;
                onError(
                    password,
                    () => {
                        showAppErrorToast(error);
                    },
                    firstError ? Object.keys(correctButUsedGetTransferKnownErrorCodes).includes(firstError.code) : false
                );
            });
    }, [dashboardCashRegister, t, onError, password, showAppErrorToast, onVerified, appContext.api, appContext.user.branchId]);

    const form = useForm<CashPickupModalFormData>({defaultValues: {amount: 0}});

    const confirm = useBlockingCallback((unblock, {amount}: CashPickupModalFormData) => {
        if (!modalState || modalState.submitting) {
            return;
        }
        if (modalState.withChoice && !amount) {
            modalToast.error(t('viewCashPickup:error.chooseMethod'));
            return;
        }

        setModalState({
            ...modalState,
            submitting: true,
        });

        confirmTransfer({
            password,
            amount: String(modalState.withChoice ? amount : modalState.yesterday),
        }, appContext.api)
            .then((response) => {
                appContext.print.addPrints(response.prints, response.timestamp);
                setModalState({
                    ...modalState,
                    submitted: true,
                    submitting: false,
                    printResult: getPrintResultByType(response, PrintResultType.TRANSACTION_RECEIPT),
                });
                modalToast.success(t('viewCashPickup:success'));
            })
            .catch(() => {
                setModalState({
                    ...modalState,
                    submitting: false,
                });
                modalToast.error(t('viewCashPickup:error.general'));
            })
            .finally(unblock);
    }, [appContext.api, appContext.print, modalState, password, t]);

    const close = useCallback(() => {
        if (!modalState || !modalState.submitted) {
            modalToast.error(t('viewCashPickup:error.pleaseConfirm'));
        } else {
            setModalState(null);
            onFinish(SearchFormResult.CASH_PICKUP);
        }
    }, [modalState, onFinish, t]);

    return {
        modalState,
        passwordConfirmed,
        form,
        confirm,
        close,
    };
}
