import {receiveB2C} from "@app/Packet/api/packetApi";
import {PasswordHandlingComponent} from "@app/Packet/pages/DashboardPage/SearchForm/PasswordHandlingComponents/PasswordHandlingComponent";
import {
    ReprintablePasswordModal
} from "@app/Packet/pages/DashboardPage/SearchForm/ReprintablePasswordModal/ReprintablePasswordModal";
import {SearchFormResult} from "@app/Packet/pages/DashboardPage/SearchForm/SearchFormTypes";

export const B2CConsignPasswordModal: PasswordHandlingComponent = ({
    onError,
    onFinish,
    onVerified,
    password,
}) => {
    return <ReprintablePasswordModal
        apiMethod={receiveB2C}
        password={password}
        translationDomain="viewReceiveB2CPacket"
        onFinish={() => onFinish(SearchFormResult.B2C_CONSIGN_PASSWORD)}
        onError={onError}
        onVerified={onVerified}
    />
}
