import {clean as semverClean, satisfies as semverSatisfies, validRange as semverValidRange} from "semver";

import {ApiBackend} from "@app/AppContext/classes/Api/model/ApiBackend";
import {ApiRequestWatcher, RunningRequest} from "@app/AppContext/classes/Api/model/ApiRequestWatcher";
import {HEADER_DEPRECATED_CLIENT_VERSION} from "@app/config";

export class DeprecatedVersionAzApiRequestWatcher extends ApiRequestWatcher {
    constructor(private onRequest: (deprecated: boolean) => void, private release?: string) {
        super();
    }

    // eslint-disable-next-line @typescript-eslint/require-await
    async onRequestSuccess(_request: RunningRequest, response: Response): Promise<void> {
        if (this.release === undefined) {
            return;
        }

        const releaseVersion = semverClean(this.release);
        const deprecatedClientVersion = response.headers.get(HEADER_DEPRECATED_CLIENT_VERSION);
        if (!releaseVersion || deprecatedClientVersion === null || !semverValidRange(deprecatedClientVersion)) {
            return;
        }

        this.onRequest(
            semverSatisfies(releaseVersion, deprecatedClientVersion)
        );
    }

    async onRequestStart(): Promise<void> {}

    async onRequestError(): Promise<void> {}

    public onUnregister() {}

    public requiresWatch(request: RunningRequest): boolean {
        return request.endpoint.backend === undefined || request.endpoint.backend === ApiBackend.AZAPI;
    }
}
