import {matchPath, useLocation} from "react-router-dom";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {
    BranchCommissionRoute, InvoiceListRoute, ManagedCommissionRoute, NewInvoiceRoute
} from "@app/Commission/CommissionRoutes";
import {
    BranchInventoryListRoute, InventoryDetailRoute, ManagedInventoryRoute
} from "@app/Inventory/InventoryRoutes";
import {ManualsIndexRoute, TermsAndConditionsRoute} from "@app/Manuals/ManualsRoutes";
import {
    NewMessageRoute, ReceivedMessagesRoute, SentMessagesRoute
} from "@app/Messaging/MessagingRoutes";
import {DashboardRoute, PacketDetailRoute} from "@app/Packet/PacketRoutes";
import {ParcelDetailRoute, ParcelListRoute} from "@app/Parcel/ParcelRoutes";
import {
    BranchCardTransactionRoute, BranchCashRegisterRoute, ManagedCardTransactionRoute, ManagedCashRegisterRoute
} from "@app/Transaction/TransactionModuleRoutes";
import {ReactRoute} from "@common/model/ReactRoute";

export const useContextAction = (): string|null => {
    const location = useLocation();
    const appContext = useAppContext();

    const contextActions: {route: ReactRoute, action: string}[] = [
        {route: DashboardRoute, action: 'dashboard.default'},
        {route: ReceivedMessagesRoute, action: 'message.received'},
        {route: SentMessagesRoute, action: 'message.sent'},
        {route: NewMessageRoute, action: 'message.newMessage'},
        {route: ParcelListRoute, action: 'parcel.default'},
        {route: ParcelDetailRoute, action: 'parcel.detail'},
        {route: ManagedCashRegisterRoute, action: 'cashRegister.default'},
        {route: BranchCashRegisterRoute, action: 'cashRegister.detail'},
        {route: ManagedCardTransactionRoute, action: 'card.default'},
        {route: BranchCardTransactionRoute, action: 'card.detail'},
        {route: ManagedCommissionRoute, action: 'commission.default'},
        {route: BranchCommissionRoute, action: 'commission.detail'},
        {route: NewInvoiceRoute, action: 'invoice.new'},
        {route: InvoiceListRoute, action: 'invoice.list'},
        {route: ManagedInventoryRoute, action: 'inventory.default'},
        {route: BranchInventoryListRoute, action: 'inventory.default'},
        {route: InventoryDetailRoute, action: 'inventory.detail'},
        {route: PacketDetailRoute, action: 'packet.detail'},
        {route: ManualsIndexRoute, action: 'manuals.default'},
        {route: TermsAndConditionsRoute, action: 'manuals.termsAndConditions'},
    ];

    const contextAction = contextActions.find(({route}) => {
        const routePath = appContext.router.path(route, true).pathname;
        return routePath && matchPath(routePath, location.pathname) !== null;
    });

    return contextAction ? contextAction.action : null;
}
