import {observer} from "mobx-react-lite";
import {FC} from "react";
import {useTranslation} from "react-i18next";

import {
    BranchCommissionListManager
} from "@app/Commission/pages/BranchCommissionPage/BranchCommissionGrid/BranchCommissionListManager";
import {PaginatedGridSorter} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/PaginatedGridSorter";
import {
    usePaginatedGridSorter
} from "@common/components/grids/PaginatedGrid/PaginatedGridSorter/usePaginatedGridSorter";

export const BRANCH_COMMISSION_LIST_SORTING_STORAGE_KEY = 'ui-branchCommissionList-sorting';

export type BranchCommissionGridHeaderProps = {
    manager: BranchCommissionListManager;
}

export const BranchCommissionGridHeader: FC<BranchCommissionGridHeaderProps> = observer(({manager}) => {
    const {t} = useTranslation();

    const setStoredSorting = usePaginatedGridSorter(
        manager,
        BRANCH_COMMISSION_LIST_SORTING_STORAGE_KEY,
        {key: 'createdAt', desc: true},
    );

    if (!manager.hasItems) {
        return null;
    }

    return <thead>
        <tr>
            <th><PaginatedGridSorter manager={manager} sortBy="createdAt" onSort={setStoredSorting}>
                {t('commission:property.createdAt')}
            </PaginatedGridSorter></th>
            <th>
                <PaginatedGridSorter manager={manager} sortBy="barcode" onSort={setStoredSorting}>
                    {t('commission:property.barcode')}
                </PaginatedGridSorter>
                <div className="branch-commission-grid__barcode-type">
                    {' / '}{t('commission:property.type')}
                </div>
            </th>
            <th className="branch-commission-grid__type">{t('commission:property.type')}</th>
            <th>{t('commission:property.amount')}</th>
            <th className="branch-commission-grid__note">{t('commission:property.note')}</th>
        </tr>
    </thead>
})
