import {FC} from "react";
import {useTranslation} from "react-i18next";

import {Can} from "@app/AppContext/classes/Casl/Can";
import {Action, Subject} from "@app/AppContext/classes/Casl/model/Casl";
import {
    BillingInfoRoute, CommissionTariffRoute, InvoiceListRoute, NewInvoiceRoute
} from "@app/Commission/CommissionRoutes";
import {Img} from "@common/components/Img/Img";
import {AppLink} from "@common/components/routing/AppLink/AppLink";

import './commissionNavigation.scss';

export const CommissionNavigation: FC = () => {
    const {t} = useTranslation();

    return <div className="commission-navigation">
        <Can I={Action.CREATE} a={Subject.COMMISSION_INVOICE}>
            <AppLink variant="secondary" to={NewInvoiceRoute}>
                <Img
                    src="/images/icons/ico/ic_note_add_24px.svg"
                    alt={t('commission:action.createInvoice')}
                    title={t('commission:action.createInvoice')}
                />
                {t('commission:action.createInvoice')}
            </AppLink>
        </Can>
        <Can not I={Action.CREATE} a={Subject.COMMISSION_INVOICE}>
            <Can I={Action.SHOW} a={Subject.BILLING_INFORMATION}>
                <AppLink variant="secondary" to={BillingInfoRoute}>
                    <Img
                        src="/images/icons/ico/ico-i-gray.svg"
                        alt={t('commission:action.billingInfo')}
                        title={t('commission:action.billingInfo')}
                    />
                    {t('commission:action.billingInfo')}
                </AppLink>
            </Can>
        </Can>
        <Can I={Action.SHOW} a={Subject.COMMISSION_INVOICE}>
            <AppLink variant="secondary" to={InvoiceListRoute}>
                <Img
                    src="/images/icons/ico/ic_description_24px.svg"
                    alt={t('commission:action.invoiceList')}
                    title={t('commission:action.invoiceList')}
                />
                {t('commission:action.invoiceList')}
            </AppLink>
        </Can>
        <Can I={Action.SHOW} a={Subject.COMMISSION_TARIFF}>
            <AppLink className="tariff" variant="secondary" to={CommissionTariffRoute}>
                {t('viewCommission:commissionNavigation.tariffs')}
            </AppLink>
        </Can>
    </div>
}
