import {FC} from "react";
import {UseFormReturn} from "react-hook-form";
import {useTranslation} from "react-i18next";

import {
    NewMessageFormFields,
    OutgoingMessageFormData
} from "@app/Messaging/components/NewMessage/NewMessageFormFields";
import {MessageType as MessageTypeEnum} from "@app/Messaging/model/MessagingRequestsAndResponses";
import {Select, SelectOption} from "@common/components/forms/Select/Select";

export type MessageTypeProps = {
    form: UseFormReturn<OutgoingMessageFormData>;
    formId: string;
}

export const MessageType: FC<MessageTypeProps> = ({form, formId}) => {
    const {t} = useTranslation();

    const options: Array<SelectOption> = [];
    for (const type in MessageTypeEnum) {
        if (Object.prototype.hasOwnProperty.call(MessageTypeEnum, type) && isNaN(Number(type))) {
            const value = MessageTypeEnum[type] as any as string;
            options.push({value, label: t(`message:tabs.content.new.typeSelect.item-${value}`)});
        }
    }

    return <Select
        name={NewMessageFormFields.type}
        label={t('message:tabs.content.new.type')}
        formId={formId}
        options={options}
        form={form}
    />
}
