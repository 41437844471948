
import {observer} from "mobx-react-lite";
import {FC, useCallback, useEffect, useMemo} from "react";
import {useTranslation} from "react-i18next";

import {
    PartnerCardTransactionListFilter,
    PartnerCardTransactionListManager
} from "@app/Transaction/pages/PartnerCardTransactionPage/PartnerCardTransactionListManager";
import {DateRangePicker} from "@common/components/forms/DateRangePicker/DateRangePicker";
import {
    GeneralDateRangePickerRanges,
    useGeneralDateRangePickerRanges
} from "@common/components/forms/DateRangePicker/useGeneralDateRangePickerRanges";
import {FormResetLink} from "@common/components/forms/FormResetLink/FormResetLink";
import {Loading} from "@common/components/Loading/Loading";
import {useLocalStorageState} from "@common/hooks/useLocalStorageState";

import './partnerCardTransactionGridFilter.scss';

export type PartnerCardTransactionGridFilterProps = {
    manager: PartnerCardTransactionListManager;
}

export const PARTNER_CARD_TRANSACTION_GRID_FILTER_STORAGE_KEY = 'ui-partnerCardTransactionList-filter';

export const PartnerCardTransactionGridFilter: FC<PartnerCardTransactionGridFilterProps> = observer(({manager}) => {
    const {t} = useTranslation();
    const namedRanges = useGeneralDateRangePickerRanges();

    const defaultFilter = useMemo(() => ({
        createdAt: namedRanges[GeneralDateRangePickerRanges.LAST_MONTH]
    }), [namedRanges]);

    const [storedFilter, setStoredFilter] = useLocalStorageState<PartnerCardTransactionListFilter>(
        PARTNER_CARD_TRANSACTION_GRID_FILTER_STORAGE_KEY,
        defaultFilter,
    );

    const updateFilter = useCallback((newFilter: PartnerCardTransactionListFilter) => {
        setStoredFilter(newFilter);
        manager.setFilter(newFilter);
    }, [manager, setStoredFilter]);

    const onReset = useCallback(() => {
        updateFilter(defaultFilter);
    }, [defaultFilter, updateFilter]);

    useEffect(() => {
        if (!manager.hasFilter) {
            manager.setFilter(storedFilter);
        }
    }, [manager, storedFilter]);

    return <Loading active={manager.isLoading} className="partner-card-transaction-grid-filter">
        <DateRangePicker
            label={t('viewTransaction:transactionGrid.filter.createdAt')}
            selectedRange={storedFilter.createdAt}
            clearOption={true}
            formId="partnerCardTransactionGridFilter"
            namedRanges={namedRanges}
            maxEndDate={new Date()}
            onChange={(createdAt) => updateFilter({...storedFilter, createdAt})}
        />
        <FormResetLink onReset={onReset} />
    </Loading>
})
