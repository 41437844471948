import {Api} from "@app/AppContext/classes/Api/Api";
import {partnerCardTransactionList} from "@app/Transaction/api/cardApi";
import {PartnerCardTransaction} from "@app/Transaction/model/PartnerCardTransaction";
import {
    PartnerCardTransactionListRequest, PartnerCardTransactionListResponse,
} from "@app/Transaction/model/TransactionsRequestsAndResponses";
import {actualDateRange, SelectedDateRange} from "@common/components/forms/DateRangePicker/NamedDateRange";
import {SortablePaginatedDataManager} from "@common/components/grids/SortablePaginatedDataManager";
import {Money} from "@common/model/Money";
import {dateRangeToFilterRequestParameter} from "@common/utils/api/dateRangeToFilterRequestParameter";

export type PartnerCardTransactionListFilter = {
    createdAt?: SelectedDateRange;
}

export class PartnerCardTransactionListManager extends SortablePaginatedDataManager<
    PartnerCardTransaction, PartnerCardTransactionListRequest, Pick<PartnerCardTransactionListResponse, 'totalSum'>
> {
    public totalSum?: Money;

    constructor(private api: Api, public branchId: string, pageSize?: number) {
        super(
            (request: PartnerCardTransactionListRequest) => partnerCardTransactionList(request, api),
            {branchId},
            pageSize,
        );

        this.processResponse.push((response) => {
            this.totalSum = response ? response.totalSum : undefined;
        });
    }

    public setFilter = (filter: PartnerCardTransactionListFilter): void => {
        const newFilteringRequest: Partial<PartnerCardTransactionListRequest> = {};

        if (filter.createdAt) {
            const actualRange = actualDateRange(filter.createdAt);

            if (actualRange.from && actualRange.to) {
                newFilteringRequest.createdAt = dateRangeToFilterRequestParameter(actualRange);
            }
        }

        this.setFilteringRequest(newFilteringRequest);
    }
}
