import {FC, useCallback} from "react";
import {useTranslation} from "react-i18next";

import {useAppContext} from "@app/AppContext/hooks/useAppContext";
import {notify} from "@app/Packet/api/packetApi";
import {BasePacket} from "@app/Packet/model/BasePacket";
import {PacketStatusId} from "@app/Packet/model/PacketStatus";
import {Confirm} from "@common/components/Confirm/Confirm";
import {appToast, modalToast} from "@common/components/Toast/toastOpener";
import {useShowErrorToast} from "@common/hooks/useShowErrorToast";
import {parseDate} from "@common/utils/parseDate";

export type PacketNotificationButtonProps = {
    packet: BasePacket;
    size?: 'sm'|'lg';
    inModal?: boolean;
}

export const PacketNotificationButton: FC<PacketNotificationButtonProps> = ({inModal = false, packet, size}) => {
    const {t} = useTranslation();
    const appContext = useAppContext();

    const toastOpener = inModal ? modalToast : appToast;
    const showErrorToast = useShowErrorToast(toastOpener, 'packet:error');

    const sendSms = useCallback(async () => {
        try {
            await notify({barcode: packet.barcode}, appContext.api);
            toastOpener.success(t('packet:action.notify.success'));
        } catch (error: unknown) {
            showErrorToast(error as Error);
        }
    }, [appContext.api, packet.barcode, showErrorToast, t, toastOpener]);

    const onClick = useCallback(async () => {
        try {
            await notify({barcode: packet.barcode, dryRun: true}, appContext.api);
            return true;
        } catch (error: unknown) {
            showErrorToast(error as Error);
            return false;
        }
    }, [appContext.api, packet.barcode, showErrorToast]);

    if (packet.isClaimAssistant
        || packet.status.id !== PacketStatusId.PICKUP_READY
        || (packet.deliverOn && parseDate(packet.deliverOn) > new Date())
        || !packet.isAtUsersBranch
    ) {
        return null;
    }

    return <Confirm
        message={t('packet:action.notify.confirm.message')}
        header={t('packet:action.notify.confirm.header')}
        onSuccess={sendSms}
        onClick={onClick}
        variant="secondary"
        size={size}
        className="packet-notification-button"
    >
        {t('packet:action.notify.label')}
    </Confirm>
}
