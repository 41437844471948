import {add, compareAsc, startOfDay, sub} from "date-fns";
import {action, computed, makeObservable, observable} from "mobx";

import {Api} from "@app/AppContext/classes/Api/Api";
import {branchHoursRange} from "@app/OpeningHours/api/branchHoursApi";
import {BranchOpeningHours} from "@app/OpeningHours/model/BranchOpeningHours";
import {BranchHoursRangeResponse} from "@app/OpeningHours/model/OpeningHoursRequestsAndResponses";
import {PlannedChange} from "@app/OpeningHours/model/PlannedChange";
import {dateObjectCompare} from "@common/utils/dateObjectCompare";
import {parseDate} from "@common/utils/parseDate";

const BRANCH_HOURS_MONTHS_RANGE = 3;

export class BranchHoursManager {
    public openingHours: BranchOpeningHours|null = null;
    public error: Error|null = null;

    constructor(private api: Api) {
        makeObservable(this,{
            error: observable,
            openingHours: observable.shallow,
            setError: action,
            setDataFromResponse: action,
            publicHolidaysOnly: computed,
        });

        this.fetchData();
    }

    get publicHolidaysOnly(): BranchOpeningHours|null {
        if (!this.openingHours) {
            return null;
        }
        return {
            effective: this.openingHours.effective.filter((openingHour) => openingHour.isPublicHoliday),
            exceptions: this.openingHours.exceptions,
        }
    }

    public plannedChanges = (firstSelectableDate: Date): PlannedChange[] => {
        if (!this.openingHours) {
            return [];
        }

        const plannedChanges: PlannedChange[] = [];
        if (this.openingHours.exceptions) {
            let readOnlyClosing = false;
            this.openingHours.exceptions
                .slice()
                .sort(dateObjectCompare)
                .forEach((exception) => {
                    if (exception.hours.length === 0) {
                        const closedDate = parseDate(exception.date);
                        const previousPlannedChange = plannedChanges.length > 0 ? plannedChanges.slice(-1)[0] : undefined;
                        if (previousPlannedChange
                            && previousPlannedChange.type === 'closed'
                            && previousPlannedChange.endDate >= startOfDay(sub(closedDate, {days: 1}))
                            && closedDate < firstSelectableDate === readOnlyClosing
                        ) {
                            // extend the previous planned change
                            previousPlannedChange.endDate = closedDate;
                        } else {
                            plannedChanges.push({type: 'closed', date: closedDate, endDate: closedDate});
                        }
                        readOnlyClosing = closedDate < firstSelectableDate;
                    } else {
                        plannedChanges.push({
                            type: 'exception',
                            date: parseDate(exception.date),
                            hours: exception.hours,
                        });
                    }
                });
        }

        if (this.openingHours.regularUpdates) {
            this.openingHours.regularUpdates.forEach((regularUpdate) => {
                plannedChanges.push({
                    type: 'regularUpdate',
                    date: parseDate(regularUpdate.date),
                });
            });
        }

        return plannedChanges.sort(
            (changeA, changeB) => compareAsc(changeA.date, changeB.date)
        );
    }

    public invalidate = () => {
        this.fetchData();
    }

    public setDataFromResponse = (response: BranchHoursRangeResponse|null): void => {
        this.openingHours = response ? response.openingHours : null;
    }

    public setError = (error: Error|null): void => {
        if (JSON.stringify(error) !== JSON.stringify(this.error)) {
            this.error = error;
        }
    }

    protected fetchData = (): void => {
        branchHoursRange(new Date(), add(new Date(), {months: BRANCH_HOURS_MONTHS_RANGE}), this.api)
            .then((response) => {
                this.setDataFromResponse(response);
                this.setError(null);
            })
            .catch((error) => {
                this.setDataFromResponse(null);
                this.setError(error as Error);
            });
    }
}
